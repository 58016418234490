<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="amountDueColumn">
    <div class="amountDueContainer">
      <div *ngIf="purchaseTypeSupportsPromoCode">
        <div class="promoCodeContainer flex-row" *ngIf="!promoCodeApplied; else alreadyApplied">
            <input type="text" class="form-control" id="promoCode" name="promoCode" placeholder="Have a promo code? Enter it here" [(ngModel)]="promoString" />
            <button (click)="onApply(promoString)" [disabled]="!promoCodeButtonEnabled">Apply</button> 
            <div class="processing-wrapper" *ngIf="processingPromoCode">
              <p-progressSpinner [style]="{ width: '2em', height: '2em' }"></p-progressSpinner>
            </div>
        </div>
        <ng-template #alreadyApplied>
          <div class="promoCodeContainer flex-row">
            <div style="padding-right:1em;">
              <div><span class="bold-text">{{promoCodeValue}}</span> promo entered!</div>
              <div class="small-text">
                <div>{{promoCodeDescription}}</div>
                <div *ngIf="(paymentPlan.billingSummary.discountedDueToday !== undefined && !bypassPayment); else appliedOnApproval">
                  <em>Will be applied when you submit payment.</em>
                </div>
                <ng-template #appliedOnApproval>
                  <div *ngIf="!bypassPayment"><em>Will be applied if your certification is issued.</em></div>
                </ng-template>
              </div>
            </div>
            <div class="center">
              <i class="fa fa-check-circle success"></i>
              <a style="display:block;" class="small-text" (click)="removePromoCode()">Remove</a>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="amountDueSummaryPanel form-container" *ngIf="paymentPlan">
        <ng-template ngFor let-billingItem [ngForOf]="paymentPlan.billingSummary.billingItems">
          <span class="span1 small-text">
            {{ billingItem.description }} 
            <span *ngIf="billingItem.prorated && billingItem.amount !== billingItem.recurringAmount">(prorated)</span>
          </span>
          <div class="span1 inner-grid small-text">
            <ng-container>
              <span class="{{billingItem.discountedAmount !== undefined ? 'strikethrough' : ''}}">
                {{ billingItem.amount | currency:'USD':'symbol':'1.2-4' }}
              </span>
              <span *ngIf="billingItem.discountedAmount !== undefined">
                {{ billingItem.discountedAmount | currency:'USD':'symbol':'1.2-4' }}
              </span>
            </ng-container>
          </div>
          <ng-template [ngIf]="billingItem.recurringAmount">
            <span class="span1 small-text" *ngIf="billingItem.recurringDescription">
              {{ billingItem.recurringDescription }}
            </span>
            <div class="span1 inner-grid small-text" *ngIf="billingItem.recurringAmount !== null">    
                <ng-container>
                  <span class="{{billingItem.discountedRecurringAmount !== undefined ? 'strikethrough' : ''}}">
                  {{ billingItem.recurringAmount | currency:'USD':'symbol':'1.2-4' }}</span>
                  <span *ngIf="billingItem.discountedRecurringAmount !== undefined">
                    {{ billingItem.discountedRecurringAmount | currency:'USD':'symbol':'1.2-4' }}</span>
                </ng-container>
                <br />
                <span>due {{billingItem.recurringPeriod}}</span>
            </div>
          </ng-template>
          <div class="span2 last">
            <hr class="inside-hr">
          </div>
        </ng-template>

        <span class="span1 bold-text">
          DUE TODAY
        </span>
        <div class="span1 inner-grid">
          <label class="{{paymentPlan.billingSummary.discountedDueToday !== undefined ? 'strikethrough' : ''}}">
            {{ paymentPlan.billingSummary.dueToday | currency:'USD':'symbol':'1.2-4' }}
          </label>
          <label *ngIf="paymentPlan.billingSummary.discountedDueToday !== undefined">
            {{ paymentPlan.billingSummary.discountedDueToday | currency:'USD':'symbol':'1.2-4' }}
          </label>
        </div>

        <ng-container *ngIf="paymentPlan.billingSummary.dueOnApproval > 0">
          <span class="span1 bold-text">
            DUE ON APPROVAL*
          </span>
          <div class="span1 inner-grid">
            <label class="{{paymentPlan.billingSummary.discountedDueOnApproval !== undefined ? 'strikethrough' : ''}}">
              {{ paymentPlan.billingSummary.dueOnApproval | currency:'USD':'symbol':'1.2-4' }}
            </label>
            <label *ngIf="paymentPlan.billingSummary.discountedDueOnApproval !== undefined">
              {{ paymentPlan.billingSummary.discountedDueOnApproval | currency:'USD':'symbol':'1.2-4' }}
            </label>
          </div>

          <div class="span2 small-text">
            <span *ngIf="!paymentPlan.billingSummary.invoiceCustomer && !invoiceAccount; else invoiceNotice">
              * Your account will be charged this amount if your certification is issued.
            </span>
            <ng-template #invoiceNotice>
              <span>
                * You will receive an invoice for this amount if your certification is issued.
              </span>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>

  </div>