import { createAction, createReducer, on, props } from '@ngrx/store';
import { TransactionSearchResponse } from '../angular-client';
import { TableFilter } from '@ls/common-ng-components';
import { Pagination } from '@ls/common-ts-models';

export interface TransactionsPaginatedRequest extends TransactionOrderFilter {
  page: number;
  size: number;
}

export interface TransactionOrderFilter {
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
  filters?: TableFilter[];
}

export const PaginatedTestTransactionGetAction = createAction(
  '[Test Transactions] Get - Paged',
  props<TransactionsPaginatedRequest>(),
);
export const PaginatedTestTransactionsGetSuccessAction = createAction(
  '[Test Transactions] Get Success',
  props<{ transactions: TransactionSearchResponse[]; pagination: Pagination }>(),
);
export const PaginatedTestTransactionsGetErrorAction = createAction(
  '[Test Transactions] Get Error',
  props<{ errorText: string; errorType: number }>(),
);

export interface PaginatedTransactionsState {
  pending: boolean;
  paginatedTransactions: {
    transactions: TransactionSearchResponse[];
    pagination: Pagination;
  };
  error?: {
    errorText;
    errorType;
  };
}

const initialState: PaginatedTransactionsState = {
  pending: false,
  paginatedTransactions: {
    pagination: {
      totalRecords: 0,
      totalPages: 0,
      pageNumber: 0,
      startPosition: 0,
      endPosition: 0,
    },
    transactions: [],
  },
};

export const TestTransactionsListReducer = createReducer(
  initialState,

  on(PaginatedTestTransactionGetAction, (state) => ({
    ...state,
    pending: true,
  })),

  on(PaginatedTestTransactionsGetSuccessAction, (state, props) => ({
    ...state,
    paginatedTransactions: props,
    pending: false,
  })),

  on(PaginatedTestTransactionsGetErrorAction, (state, props) => ({
    ...state,
    error: props,
    pending: false,
  })),
);
