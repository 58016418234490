import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from '@ls/common-ng-components';
import { Observable } from 'rxjs';
import { User, ConfirmPassword } from '@ls/common-ts-models';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsersService {
  private readonly portalApiHost = environment.CONFIG.portalApiHost as string;
  constructor(
    private http: HttpClient,
    private localStorageSvc: LocalStorageService,
  ) {}

  public getUsersForAccount() {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<User[]>(`${this.portalApiHost}/v1/account/users`, options);
  }

  public upsertUser(user: User, action: string, originalUser?: User): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    if (action === 'invite') {
      // adding new user
      return this.http.post(`${this.portalApiHost}/v1/account/user`, user, options);
    } else {
      const updateRequest = {
        updatedUser: user,
        originalUser,
      };
      return this.http.put(`${this.portalApiHost}/v1/account/user`, updateRequest, options);
    }
  }

  public resendInvite(user: User, accountName: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(`${this.portalApiHost}/v1/account/user/resend`, { user, accountName }, options);
  }

  public checkConfirmationToken(token: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(`${this.portalApiHost}/v1/account/user/${token}`, options);
  }

  public confirmAndSetPassword(token: string, confirmedPassword: ConfirmPassword): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(
      `${this.portalApiHost}/v1/account/user/confirm`,
      { user: { token, password: confirmedPassword } },
      options,
    );
  }

  public getSFContactInfo(lsUserId: string): Observable<any> {
    // all the info we want a newly invited user to update is available in Salesforce (first name, last name, phone, title/position)
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(`${this.portalApiHost}/v1/account/contact/${lsUserId}`, options);
  }

  public toggleActivation(userId: number, active: boolean, sfAccountId: string, sfContactId: string) {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(
      `${this.portalApiHost}/v1/account/user/active/${userId}`,
      { active, sfAccountId, sfContactId },
      options,
    );
  }

  public requestEmailUpdate(email: string) {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put(`${this.portalApiHost}/v1/account/user/email`, { email }, options);
  }

  public confirmEmailUpdate(token: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(`${this.portalApiHost}/v1/account/user/email/confirm`, { token }, options);
  }

  public changeAccountOwner(previousOwner: User, newOwner: User, accountName: string) {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(
      `${this.portalApiHost}/v1/account/user/updateOwner`,
      { previousOwner, newOwner, accountName },
      options,
    );
  }
}
