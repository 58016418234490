import { of as observableOf, Observable, combineLatest, of } from 'rxjs';

import { mergeMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  EntitlementsGetAction,
  EntitlementsGetSuccessAction,
  EntitlementsGetErrorAction,
  EntitlementsRemoveRecentlyPurchasedAction,
  EntitlementsAddRecentlyPurchasedAction,
  EntitlementsService,
  GenericNotificationAction,
  SeverityOptions,
  LocalStorageService,
} from '@ls/common-ng-components';
import { AppState } from '../reducers';
import { ProductFamily, Roles } from '@ls/common-ts-models';
import { MMEnrollService } from '../modules/merchant-monitoring/services';
import { environment } from 'src/environments/environment';
const RECENT_PURCHASE_TIMEOUT = 10000;

@Injectable()
export class EntitlementsEffect {
  public entitlementsGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntitlementsGetAction),
      mergeMap(() => {
        return combineLatest(
          this.entitlementService.getEntitlements(),
          this.evaluateMMOportunityRequest(),
          (entitlements, mmOpportunity) => {
            if (mmOpportunity) {
              const externalReferenceId =
                entitlements[ProductFamily.MERCHANT_MONITORING] &&
                entitlements[ProductFamily.MERCHANT_MONITORING].externalReferenceId;
              entitlements[ProductFamily.MERCHANT_MONITORING] = {
                ...entitlements[ProductFamily.MERCHANT_MONITORING], // we may have received mm entitlement information from both requests
                name: ProductFamily.MERCHANT_MONITORING,
                opportunity: mmOpportunity,
                externalReferenceId,
              };
            }

            return entitlements;
          },
        ).pipe(
          map((response) => EntitlementsGetSuccessAction({ products: response })),
          catchError((e) => {
            if (e.error.statusCode !== 401) {
              // If the user has an expired or invalid jwt, we don't show this notice.
              this.store.dispatch(
                GenericNotificationAction({
                  severity: SeverityOptions.WARN,
                  summary: 'Problem Loading Access Info',
                  detail:
                    'An issue occured while loading your account information.  Some areas you have purchased access to may currently be unavailable.  If this problem persists, please contact support',
                  sticky: true,
                  blocking: true,
                }),
              );
            }
            return observableOf(EntitlementsGetErrorAction(e.message));
          }),
        );
      }),
    ),
  );

  // when short timeout has passed after user purchases new entitlement, it should be available: we can get updated from customers-api
  public processingComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EntitlementsRemoveRecentlyPurchasedAction),
        map((action) => {
          this.localStorageService.removeRecentlyPurchasedEntitlement(action.productFamily);
          this.store.dispatch(EntitlementsGetAction());
        }),
      ),
    { dispatch: false },
  );

  // and we set a timer to remove the item from the store so they can access their search bar
  public fireRemoveRecentlyPurchased$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EntitlementsAddRecentlyPurchasedAction),
        map((action) => {
          setTimeout(
            () =>
              this.store.dispatch(
                EntitlementsRemoveRecentlyPurchasedAction({
                  productFamily: action.productFamily,
                }),
              ),
            RECENT_PURCHASE_TIMEOUT,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private entitlementService: EntitlementsService,
    private localStorageService: LocalStorageService,
    private mmEnrollService: MMEnrollService,
  ) {}

  private evaluateMMOportunityRequest(): Observable<any> {
    const authUser = this.localStorageService.getAuthenticatedUser();
    const isMMEnabled: boolean = environment.CONFIG.merchantMonitoringEnabled || false;
    if (isMMEnabled || authUser.roles.includes(Roles.cpv2MMUser)) {
      return this.mmEnrollService.checkForOpportunity();
    } else {
      return of(null);
    }
  }
}
