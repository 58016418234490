import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService, AccountService } from '../../services';
import { User } from '@ls/common-ts-models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  public token: string;
  public success: boolean;
  public pending = true;
  public changedEmail: string;
  public user: User;
  public errMsg: string;

  private emailUpdate$: Subscription;

  constructor(
    private userService: UsersService,
    private router: Router,
    private accountService: AccountService,
  ) {}

  public ngOnInit() {
    this.token = this.accountService.parseTokenFromUrl(this.router.url, 'verification_token');

    this.emailUpdate$ = this.userService.confirmEmailUpdate(this.token).subscribe(
      (user: User) => {
        this.user = user;
        this.changedEmail = this.user.email;
        this.success = true;
        this.pending = false;
      },
      (err) => {
        this.errMsg = 'You may be using an old verification link or have encountered another problem.';
        if (err.error && err.error.message) {
          if (err.error.message.includes('Email address in use')) {
            this.errMsg =
              'The email address you have chosen is no longer available. Please login with your previous email address and choose a new email.';
          } else if (err.error.message.includes('jwt expired')) {
            this.errMsg =
              'The confirmation token has expired. Please login with your previous email address and request your email change again.';
          }
        }
        this.success = false;
        this.pending = false;
      },
    );
  }

  public ngOnDestroy() {
    if (this.emailUpdate$) {
      this.emailUpdate$.unsubscribe();
    }
  }

  public navigateHome() {
    this.router.navigate(['/']);
  }
}
