<div class="login-container" id="verify-container">
  <div class="entry-main">
      <div class="entry-container entry-container-small" *ngIf="pending">
          <i class="fa fa-hourglass waiting"></i>
          <h1 class="mb40">Email Verification In Progress</h1>
          <div class="subtext mb40">
              We are currently checking your account info.<br />
              <br />
              This should only take a moment.
          </div>
          <div>Thank you for your patience.</div>
      </div>
      <div class="entry-container entry-container-small" *ngIf="!pending && success">
          <i class="fa fa-check-circle success"></i>
          <h1 class="mb40">Email Verified</h1>
          <div class="subtext mb40">
              Thank you!<br />
              <br />
              You have now changed your email address to {{changedEmail}}
          </div>
          <button class="btn btn-primary" routerLink="/login">Sign In</button>
      </div>
      <div class="entry-container entry-container-small" *ngIf="!pending && !success">
          <i class="fa fa-times-circle danger"></i>
          <h1 class="mb40">Email Verification Failure</h1>
          <div>
            <p>We don't seem to be able to verify your email.</p>
            <p>{{errMsg}}</p>
          </div>

          <button class="btn btn-warning" (click)="navigateHome()">Login</button>
      </div>
  </div>
</div>
  