<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="home-container">
  <div *ngIf="isNotCertificationUser; else showCertifications">
    <section class="main">
      <h1>Welcome to My LegitScript!</h1>
      <div class="main">
        <div class="card" *ngFor="let product of products">
          <div class="card-body">
            <div class="circle-bg">
              <img class="certification-logo" [src]="product.logo">
            </div>
            <h4 class="certication-header">
              {{product.title}}
            </h4>
            <p class="certification-description">
              {{product.description}}
            </p>
          </div>
          <div class="card-action-row">
            <div *ngIf="product.isLoaded else loading" [ngClass]="{purchased: product.isPurchased}" (click)="product.click()">
              <div *ngIf="product.isPurchased; else notPurchased">
                <p-button styleClass="p-button-outlined">
                    {{product.buttonText ?? 'View Information'}}
                </p-button>
              </div>
              <ng-template #notPurchased>
                <p-button>
                  {{product.title === 'Merchant Onboarding' ? 'Learn more' : 'Apply now'}}
                </p-button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <ng-template #loading>
        <p-button [disabled]="true">
          Loading...
        </p-button>
      </ng-template>
    </section>
  </div>
  <ng-template #showCertifications>
    <div class="header">
      <h2>Certification</h2>
      <p *ngIf="showLegacyText; else noLegacyText">
        MyLegitScript Certification portal is now on Salesforce Experience Cloud! You may continue your pending applications or renewals here in your Legacy MyLegitScript portal. Apply for any new certifications in your new <a [href]=newMyLSPortalURL target="_blank">MyLegitScript Certification portal</a>.
      </p>
      <ng-template #noLegacyText>
        <p>
          MyLegitScript Certification portal is now on Salesforce Experience Cloud! Renew or apply for certification in your new <a [href]=newMyLSPortalURL target="_blank">MyLegitScript Certification portal</a>. Access your legacy certification questionnaires, user details, and billing history here in the Legacy MyLegitScript portal.
        </p>
      </ng-template>
    </div>
      
      <div>
          <cert-list></cert-list>
      </div>
  
      <div>
          <user-list [manageUsers]="isNotCertificationUser"></user-list>
      </div>
  
      <div>
          <billing-history></billing-history>
      </div>
  </ng-template>
</div>
