import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { LocalStorageService } from '@ls/common-ng-components';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { WBFilterSortDirection, MyLSMerchantConstants } from '@ls/common-ts-models';
import { environment } from 'src/environments/environment';
@Injectable()
export class MMUploadService {
  private mmApiHost = environment.CONFIG.mmApiHost;

  constructor(
    private http: HttpClient,
    private localStorageSvc: LocalStorageService,
  ) {}

  public uploadSpreadsheet(file: File): Observable<HttpEvent<any>> {
    return this.http
      .get(
        `${this.mmApiHost}/spreadsheets/getUploadURL?filename=${file.name}`,
        this.localStorageSvc.getJWTAndSetOptions(),
      )
      .pipe(
        mergeMap(({ fields, url }: any) => {
          const formData = new FormData();
          Object.keys(fields).forEach((field) => formData.append(field, fields[field]));
          formData.append('file', file);

          const headers = {
            Acl: 'bucket-owner-full-control',
            'X-Amz-Server-Side-Encryption': 'aws:kms',
            'X-Amz-Server-Side-Encryption-aws-kms-key-id': fields['X-Amz-Server-Side-Encryption-aws-kms-key-id'],
          };

          return this.http.post(url, formData, {
            headers,
            reportProgress: true,
            observe: 'events',
          });
        }),
      );
  }

  public listUploadHistory(
    sortDirection: WBFilterSortDirection,
    pageNumber: number,
    pageSize: number,
  ): Observable<any> {
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '';
    pageSize = pageSize ? pageSize : MyLSMerchantConstants.MAX_MERCHANTS_PER_PAGE;
    pageNumber = pageNumber ? pageNumber : 1;
    return this.http.get(
      `${this.mmApiHost}/uploads?pageSize=${pageSize}&pageNumber=${pageNumber}${sortDirectionParam}`,
      this.localStorageSvc.getJWTAndSetOptions(),
    );
  }

  public getMerchantsById(customerUploadId: number): Observable<any> {
    return this.http.get(
      `${this.mmApiHost}/uploads/merchants/${customerUploadId}`,
      this.localStorageSvc.getJWTAndSetOptions(),
    );
  }
}
