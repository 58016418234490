import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Auth0Service, LocalStorageService } from '@ls/common-ng-components';
import { Observable } from 'rxjs';

@Injectable()
export class Auth0RedirectGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private auth0: Auth0Service,
    private localStorage: LocalStorageService,
    @Inject('Window') private window: Window,
  ) {}

  /**
   * Attempts to handle URI parameters from Auth0 to setup a user in appliation state.
   * Afterwards, the user will be redirected.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.localStorage.setAuth0Enabled(route)) {
      return true;
    }

    if (this.window.location.search.includes('code=')) {
      await this.auth0.handleCallback();
    }

    this.auth0.navigateToTargetUrl(this.router, '/account/home');

    return false;
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(route, state);
  }
}
