/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type WebsiteMonitoringStatus = 'Weekly' | 'Bi-Weekly' | 'Monthly' | 'Bi-Monthly' | 'Quarterly' | 'None';

export const WebsiteMonitoringStatus = {
    Weekly: 'Weekly' as WebsiteMonitoringStatus,
    Bi_Weekly: 'Bi-Weekly' as WebsiteMonitoringStatus,
    Monthly: 'Monthly' as WebsiteMonitoringStatus,
    Bi_Monthly: 'Bi-Monthly' as WebsiteMonitoringStatus,
    Quarterly: 'Quarterly' as WebsiteMonitoringStatus,
    None: 'None' as WebsiteMonitoringStatus
};

