import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { User, Roles } from '@ls/common-ts-models';

import {
  AccountState,
  AppState,
  AuthenticationState,
  AccountLoadAction,
  fnAuthenticationState,
  fnAccountState,
} from '../../../reducers';
import { EditUserComponent } from '../../users/edit-user/edit-user.component';
import { EditAccountDetailsComponent } from '../edit-account-details/edit-account-details.component';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { UpdateEmailComponent } from '../../users/update-email/update-email.component';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'account-details',
  styleUrls: ['./account-details.component.scss'],
  templateUrl: './account-details.component.html',
})
export class AccountDetailsComponent implements OnDestroy, OnInit {
  public contact: User;
  public account: AccountState;
  public items: MenuItem[];
  public isOwner = false;
  public editUserModel: User = {};
  public error: any;
  @ViewChild(EditUserComponent) public manageUserModal: EditUserComponent;
  @ViewChild(EditAccountDetailsComponent) public editAccountComponent: EditAccountDetailsComponent;
  @ViewChild(UpdateEmailComponent) public updateEmailComponent: UpdateEmailComponent;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private store: Store<AppState>) {}

  public ngOnInit() {
    this.store
      .select(fnAuthenticationState)
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe((authentication: AuthenticationState) => {
        this.isOwner = authentication.roles.includes(Roles.cpv2AccountOwner);
      });

    this.store
      .select(fnAccountState)
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe((account) => {
        if (account.err) {
          this.error = account.err;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Failed to load account details',
              detail: 'An error occured attempting to load account details. Please try again.',
              sticky: false,
              blocking: false,
            }),
          );
          return;
        }
        this.contact = account.user;
        this.account = account;

        if (!this.account.pending && Object.keys(this.contact).length > 0) {
          if (this.contact.lsUserId !== this.account.primaryContact.lsUserId) {
            this.editUserModel = this.mapContact(this.contact);
          } else {
            this.editUserModel = this.mapContact(this.account.primaryContact);
          }
        }
      });

    this.items = [
      {
        label: 'Edit Account Details',
        command: () => {
          this.editAccountDetails();
        },
        visible: this.isOwner,
      },
      {
        label: 'Edit Current User',
        command: () => {
          this.editCurrentUser();
        },
      },
      {
        label: 'Change Current User Email',
        command: () => {
          this.updateCurrentUserEmail();
        },
      },
    ];
  }

  public editAccountDetails() {
    this.editAccountComponent.showModal();
  }

  public editCurrentUser() {
    this.manageUserModal.showModal('edit');
  }

  public updateCurrentUserEmail() {
    this.updateEmailComponent.showModal();
  }

  public submitUser() {
    this.store.dispatch(AccountLoadAction());
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private mapContact(user: User) {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      name: user.name,
      position: user.position,
      email: user.email,
      lsUserId: user.lsUserId,
      phone: user.phone,
      lsAccountId: user.lsAccountId,
    };
  }
}
