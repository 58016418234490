import { empty as observableEmpty, Subject } from 'rxjs';

import { takeUntil, catchError } from 'rxjs/operators';
import { Component, ViewChild, Output, OnDestroy, Input, EventEmitter } from '@angular/core';
import {
  LegitScriptModalComponent,
  LocalStorageService,
  GenericNotificationAction,
  SeverityOptions,
} from '@ls/common-ng-components';
import { Router } from '@angular/router';
import { AccountService } from '../../../services/account.service';
import { User } from '@ls/common-ts-models';
import { Store } from '@ngrx/store';
import { AppState, UserSignOutAction } from '../../../reducers';
import { NgxScrollEvent } from 'src/app/modules/ngx-scroll-event/ngx-scroll-event.directive';

@Component({
  selector: 'consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnDestroy {
  @Input() public user: User;
  @Output() public agreeToTerms: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(LegitScriptModalComponent) public termsModal: LegitScriptModalComponent;

  public model: { gdpr?: 'yes' | 'no' } = {};
  public hasScrolledToBottom = false;
  public error;
  public destroyed$: Subject<boolean> = new Subject();
  public gdprSmallScreen = false;
  public continued = false;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private localStorageSvc: LocalStorageService,
    private store: Store<AppState>,
  ) {}

  public ngOnDestroy() {
    // if user hasn't accepted terms yet, don't let them navigate away
    if (this.user && (!this.user.gdprConsentDate || !this.user.portalTermsConsentDate)) {
      this.router.navigate(['/loading']);
    }

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public showModal() {
    this.termsModal.showDialog();
  }

  // Fires when the div.dialog-body is scrolling through the T/Cs.
  public onDialogScroll(event: NgxScrollEvent) {
    if (event.isWindowEvent === false && event.isReachingBottom === true) {
      this.hasScrolledToBottom = true;
    }
  }

  // Fires when someone clicks "Agree" on the T/Cs modal.
  public onAgreeToTerms() {
    this.termsModal.hideDialog();
    this.user.gdprConsentDate = new Date();
    this.user.portalTermsConsentDate = new Date();
    this.accountService
      .updateContact(this.user)
      .pipe(
        catchError((err) => {
          console.error(err);
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Update Failed',
              detail: 'An error occurred while agreeing to updated terms. Please try again or contact support.',
              sticky: false,
              blocking: false,
            }),
          );

          // do not let user proceed if we are unable to process their agreement
          this.store.dispatch(UserSignOutAction({ location: '/login' }));

          return observableEmpty();
        }),
        takeUntil(this.destroyed$.asObservable()),
      )
      .subscribe(() => {
        this.agreeToTerms.emit(true);
      });
    this.localStorageSvc.setUser(this.user);
  }

  public continue() {
    this.gdprSmallScreen = true;
    this.continued = true;
  }

  public gdprUpdate() {
    if (this.model.gdpr === 'no') {
      this.error = 'gdpr';
    } else {
      if (this.error === 'gdpr') {
        this.error = undefined;
      }
    }
  }
}
