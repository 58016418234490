import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merchantListActions, notificationsActions } from '../../state';
import { MyLSIoTTopicSuffix } from '@ls/common-ts-models';
import { MessageService } from 'primeng/api';
import { MerchantMonitoringBulkUploadValidationErrorAction } from 'src/app/modules/merchant-monitoring/reducers/bulk-upload.reducer';

@Injectable()
export class SnackbarEffects {
  constructor(
    private actions$: Actions,
    private messageService: MessageService,
  ) {}

  $exportMerchantNotificationReceived = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.setNotifications),
      map((notifications) => notifications.notifications),
      filter((notifications) => notifications[0]?.scopedTopic.includes(`${MyLSIoTTopicSuffix.SPREADSHEET_DOWNLOAD}`)),
      map((notifications) => {
        if (notifications[0].showToast && notifications[0].linkInfo) {
          this.messageService.add({
            severity: 'info',
            life: 10000, //10 seconds
            summary: notifications[0].title,
            detail: `<span>Your merchant export is ready. You can download it now or find it later in the notifications tab. <a href="${notifications[0].linkInfo.link}" target="_blank">Download</a></span>`,
          });
        } else if (notifications[0].showToast) {
          this.messageService.add({
            severity: 'info',
            life: 5000,
            summary: notifications[0].title,
            detail: notifications[0].description ?? notifications[0].title,
            sticky: true,
          });
        }
        return notificationsActions.noop();
      }),
    ),
  );

  $bulkUpdateNotificationReceived = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.setNotifications),
      map((notifications) => notifications.notifications),
      filter((notifications) => notifications[0]?.scopedTopic.includes(`${MyLSIoTTopicSuffix.BULK_ACTION}`)),
      map((notifications) => {
        if (notifications[0].showToast) {
          this.messageService.add({
            severity: 'success',
            summary: notifications[0].title,
            life: 5000,
            detail: `${notifications[0].description ?? notifications[0].title}`,
          });
        }
        return notificationsActions.noop();
      }),
    ),
  );

  $bulkActionApplied = createEffect(() =>
    this.actions$.pipe(
      ofType(merchantListActions.applyBulkMerchantActionComplete),
      map(() => {
        this.messageService.add({
          life: 5000,
          severity: 'info',
          summary: '',
          detail: 'A recent action is currentely processing and may not be reflected in listed results yet.',
        });
        return merchantListActions.noop();
      }),
    ),
  );

  $uploadValidationErrorOccured = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantMonitoringBulkUploadValidationErrorAction),
      map(() => {
        this.messageService.add({
          life: 3000,
          severity: 'error',
          detail:
            'An error occurred while parsing your file.  Please try again.  If this problem persists, please contact support',
        });
        return merchantListActions.noop();
      }),
    ),
  );
}
