import { takeUntil, catchError } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccountService, AuthenticationService } from '../../services';
import { AppState, UserAuthenticateSuccessAction } from '../../reducers';
import { User, LoginResponse } from '@ls/common-ts-models';
import { Subject, of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public password = '';
  public passwordConfirm = '';
  public loading = false;
  public error: number | undefined = undefined;
  public errMsg: string;
  private token = '';
  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    public router: Router,
    public accountSvc: AccountService,
    public authSvc: AuthenticationService,
    public store: Store<AppState>,
  ) {}

  public ngOnInit() {
    this.token = this.accountSvc.parseTokenFromUrl(this.router.url, 'token');
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public submit(): void {
    this.loading = true;
    this.error = undefined;
    this.accountSvc
      .resetPassword({
        resetToken: this.token,
        password: this.password,
      })
      .pipe(
        catchError((err: any) => {
          this.loading = false;
          this.error = err.status;
          this.errMsg = err.errorMsg || err.error;
          return of();
        }),
        takeUntil(this.destroyed$.asObservable()),
      )
      .subscribe((response: LoginResponse) => {
        const user: User = this.authSvc.decodeAndStoreFromJwt(response.authorization);
        this.store.dispatch(UserAuthenticateSuccessAction({ user, roles: [] }));
        this.router.navigate(['/loading']);
      });
  }
}
