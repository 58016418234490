<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="notification-bounds">
    <p-card>
        <div class="notification-body" [ngClass]="{'last-child': last}">
            <div class="notification-exit notification-color-{{notification.processStatus}} fa fa-times"
                (click)=" close()">
            </div>
            <div class="notification-information">
                <div class="notification-title">{{notification.title}}</div>

                <div *ngIf="notification.description" class="notification-description">{{notification.description}}
                </div>

                <a *ngIf="notification.linkInfo" href="{{notification.linkInfo.link}}" target="_blank">
                    <div class="notification-link" (click)="close()">{{notification.linkInfo.label}}</div>
                </a>
                <a *ngIf="hasCurrentStatus()" href="notification.currentStatus" target="_blank">
                    <p-progressBar [value]="progressBarValue"></p-progressBar>
                </a>
            </div>
        </div>
    </p-card>
</div>