import { FILE_VALIDATION_CONSTANTS } from '@ls/common-ts-models';
import XLSX from 'xlsx';

import { UPLOAD_VALIDATION_ENUMS } from '../../../../enums';
import { ValidationResult } from '../../SharedCustomTypes/validation-result';
import { WorksheetRow, worksheetColumnNames } from './upload-worksheet-format';
import { CustomerTagsValidator } from './customer-tags-validator';

export class UploadWorksheetValidator {
  public static readonly ErrorInvalidHeader = new ValidationResult(
    false,
    UPLOAD_VALIDATION_ENUMS.fileUploadHeadersError
  );
  public static readonly ErrorTooManyRows = new ValidationResult(
    false,
    UPLOAD_VALIDATION_ENUMS.fileUploadRowCountError
  );

  public static readonly ErrorNoRows = new ValidationResult(false, UPLOAD_VALIDATION_ENUMS.fileUploadMinRowCountError);
  public static readonly ErrorMissingColumn = new ValidationResult(
    false,
    'A row is missing a necessary column or the value within the column is invalid'
  );

  public static readonly RequiredColumns: worksheetColumnNames[] = ['Merchant ID', 'Merchant Country'];
  public static readonly ColumnType: Record<string, string> = { 'Merchant ID': 'number', 'Merchant Country': 'string' };

  public static isWorksheetFilledOutWithRequiredColumns(rows: WorksheetRow[]): ValidationResult {
    const isDataPresent = UploadWorksheetValidator.isRowCountHighEnough(rows);

    if (isDataPresent.success) {
      const isValid = UploadWorksheetValidator.isEveryRowValid(rows);
      return isValid ? ValidationResult.Success : this.ErrorMissingColumn;
    }

    return isDataPresent;
  }

  public static validate(sheet: XLSX.WorkSheet): ValidationResult {
    const validationResults = [];

    const options: XLSX.Sheet2JSONOpts = {
      defval: ''
    };

    const rows = XLSX.utils.sheet_to_json<WorksheetRow>(sheet, options);
    const isWithinAcceptableRange = UploadWorksheetValidator.isRowCountWithinAcceptableRange(rows);
    validationResults.push(isWithinAcceptableRange);

    if (isWithinAcceptableRange.success) {
      const header = this.getHeaderFromJson(rows);
      const isValidHeader = UploadWorksheetValidator.isValidHeader(header);
      validationResults.push(isValidHeader);

      if (isValidHeader.success) {
        validationResults.push(UploadWorksheetValidator.isWorksheetFilledOutWithRequiredColumns(rows));
      }
    }

    const summaryResult = ValidationResult.getPassOrFirstFailure(validationResults);
    return summaryResult;
  }

  public static isValidHeader(header: string[]): ValidationResult {
    return this.isEveryNecessaryColumnPresent(header)
      ? ValidationResult.Success
      : UploadWorksheetValidator.ErrorInvalidHeader;
  }

  public static isRowCountWithinAcceptableRange(rowData: WorksheetRow[]): ValidationResult {
    const rangeValidationResults = [];
    rangeValidationResults.push(this.isRowCountHighEnough(rowData));
    rangeValidationResults.push(this.isRowCountLowEnough(rowData));

    return ValidationResult.getPassOrFirstFailure(rangeValidationResults);
  }

  public static isRowCountLowEnough(rowData: WorksheetRow[]) {
    return rowData.length <= FILE_VALIDATION_CONSTANTS.MAX_ROWS
      ? ValidationResult.Success
      : UploadWorksheetValidator.ErrorTooManyRows;
  }

  public static isRowCountHighEnough(rowData: WorksheetRow[]) {
    return UploadWorksheetValidator.isEmptyData(rowData)
      ? UploadWorksheetValidator.ErrorNoRows
      : ValidationResult.Success;
  }

  private static necessaryHeaders: string[] = FILE_VALIDATION_CONSTANTS.HEADERS;

  private static isEveryRowValid(rows: WorksheetRow[]): boolean {
    return rows.every(row => this.isValidRow(row));
  }

  private static isValidRow(row: WorksheetRow) {
    const hasRequiredFields = UploadWorksheetValidator.hasRequiredFields(row);
    if (hasRequiredFields) {
      const hasEmptyOrValidTags = CustomerTagsValidator.validate(row.Tags);
      return hasEmptyOrValidTags;
    }

    return hasRequiredFields;
  }

  private static hasRequiredFields(row: WorksheetRow): boolean {
    const hasRequiredFields = UploadWorksheetValidator.RequiredColumns.every(
      column => !UploadWorksheetValidator.isEmptyField(row[column])
    );

    return hasRequiredFields;
  }

  private static isEmptyField(field: any): boolean {
    return field === null || field === undefined || field === '';
  }

  private static isEmptyData(rowData: any[]) {
    return !rowData || !Array.isArray(rowData) || rowData.length <= 0;
  }

  private static isEveryNecessaryColumnPresent(header: string[]): boolean {
    return header && header.length > 0 && this.necessaryHeaders.every(column => header.includes(column));
  }

  private static getHeaderFromJson(rows: any[]): string[] {
    return UploadWorksheetValidator.isEmptyData(rows) ? [] : Object.keys(rows[0]);
  }
}
