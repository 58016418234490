<!-- eslint-disable @angular-eslint/template/alt-text -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<generic-enroll [inputFormGroup]="healthcareFormGroup" [pricing]="pricing" [pricingInfo]="pricingInfo" (calculatePricing)="calculatePricing()" [questionnaireInfo]="questionnaireInfo" (validate)="validate(false)">
  <ng-container class="questions">
    <ng-container *ngIf="questions | async as q; else loadingQuestions">
        <div class="question-number">
          <span class="question-number">2</span>
        </div>
        <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.categoryA"></div>
            <div class="radio-group">
                <input value="yes" name="smallPharma" id="smallPharmaYesAnswer" type="radio" [formControl]="categoryA" required>
                <label for="smallPharmaYesAnswer">Yes</label>
                <input value="no" name="smallPharma" id="smallPharmaNoAnswer" type="radio" [formControl]="categoryA">
                <label for="smallPharmaNoAnswer">No</label>
                <span *ngIf="!categoryA.valid && !firstTry" class="error">* Required</span>
            </div>
        </fieldset>
          <!-- row4 -->
  
        <div class="question-number">
          <span class="question-number">3</span>
        </div>
        <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.categoryC"></div>
            <div class="radio-group">
              <input value="yes" name="complexPharma" id="complexPharmaYesAnswer" type="radio" [formControl]="categoryC">
              <label for="complexPharmaYesAnswer">Yes</label>
              <input value="no" name="complexPharma" id="complexPharmaNoAnswer" type="radio" [formControl]="categoryC">
              <label for="complexPharmaNoAnswer">No</label>
              <span *ngIf="!categoryC.valid && !firstTry" class="error">* Required</span>
            </div>
        </fieldset>

        <div class="question-number">
          <span class="question-number">4</span>
        </div>
        <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.pharmacyBroker"></div>
            <div class="radio-group">
              <input value="yes" name="pharmacyBroker" id="pharmacyBrokerYesAnswer" type="radio" [formControl]="pharmacyBroker">
              <label for="pharmacyBrokerYesAnswer">Yes</label>
              <input value="no" name="pharmacyBroker" id="pharmacyBrokerNoAnswer" type="radio" [formControl]="pharmacyBroker">
              <label for="pharmacyBrokerNoAnswer">No</label>
              <span *ngIf="!pharmacyBroker.valid && !firstTry" class="error">* Required</span>
            </div>
        </fieldset>

        <div class="question-number">
          <span class="question-number">5</span>
        </div>
        <fieldset class="span2">
          <div class="question-text" [innerHtml]="q.websiteSubmissionNumber"></div>
          <input type="number" style="margin-top: 0" [formControl]="websiteSubmissionNumber">
          <span *ngIf="!websiteSubmissionNumber.valid && !firstTry" class="error">
            <span *ngIf="websiteSubmissionNumber.hasError('required')">* Required</span>
            <span *ngIf="websiteSubmissionNumber.hasError('max')">* Number of websites must not be greater than 1000.</span>
            <span *ngIf="websiteSubmissionNumber.hasError('min')">* Number of websites must be greater than 0.</span>
            <span *ngIf="websiteSubmissionNumber.hasError('pattern')">* Please enter only digits 0-9.</span>
          </span>
        </fieldset>
  
  
    </ng-container>
    <ng-template #loadingQuestions>
      <div *ngIf="!apiError" class="row4 span2 mb30" style="margin-top: 4em; text-align: center">
        Loading questionnaire...
        <span class="floating-spinner-container">
          <p-progressSpinner [style]="{ width: '1.5em', height: '1.5em' }"></p-progressSpinner>
        </span>
      </div>
    </ng-template>
    <div class="api-error span3" *ngIf="apiError">
      An unknown error occurred loading the tier selection questionnaire. Please try again later or contact support.
    </div>
  </ng-container>

  <ng-container class="pricing-info">
      <a class="price-faq-link mb30" (click)="pricingModal.showDialog()">How is my pricing category determined?</a>
      <legitscript-modal #pricingModal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=800 height=auto>
        <div class="dialog-body" style="overflow:auto; height: 75vh; text-align: left;">
          <healthcare-pricing-info [pricing]="pricing"></healthcare-pricing-info>
        </div>
        <div class="dialog-footer">
          <button type="button" (click)="pricingModal.hideDialog()" label="Yes">Dismiss</button>
        </div>
      </legitscript-modal>
  </ng-container>

  <ng-container class="terms">
    <healthcare-terms></healthcare-terms>
  </ng-container>

  <ng-container class="intro">
      <h1>You’re one step closer to unlocking the benefits of Healthcare Merchant Certification</h1>
      <section class="intro">
        <picture>
          <img class="product-logo" src="../assets/img/certification.png" />
        </picture>
        <div>
          <div class="list-title">Applying for Healthcare Merchant Certification is straightforward:</div>
          <ul>
            <li>Complete the application questionnaire</li>
            <li>We’ll review your application against certification standards and get in touch if we have questions or need clarification.</li>
            <li>We’ll inform you of the outcome based on the information provided</li>
            <li>Approved merchants receive a seal of approval and will appear in LegitScript’s searchable public directory of legitimate providers</li>
          </ul>
        </div>
      </section>
  </ng-container>

</generic-enroll>