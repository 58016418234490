import { AuthenticationReducer } from './authentication.reducer';
import { AccountReducer } from './account.reducer';
import { NotificationReducer, EntitlementsReducer, UsageReducer } from '@ls/common-ng-components';
import { PushNotificationReducer } from './push-notification.reducer';
import { QuestionnaireReducer } from '../modules/certifications/reducers/questionnaire.reducer';
import { CertificationsReducer } from '../modules/certifications/reducers/certifications.reducer';
import { SelectableAccountReducer } from './selectable-account.reducer';

export const APP_REDUCERS = {
  authentication: AuthenticationReducer,
  account: AccountReducer,
  selectableAccount: SelectableAccountReducer,
  notifications: NotificationReducer,
  entitlements: EntitlementsReducer,
  pushNotifications: PushNotificationReducer,
  usage: UsageReducer,
  questionnaire: QuestionnaireReducer,
  certifications: CertificationsReducer
};

export * from '../modules/certifications/reducers/questionnaire.reducer';
export * from '../modules/certifications/reducers/certifications.reducer';
export * from './authentication.reducer';
export * from './account.reducer';
export * from './selectable-account.reducer';
export * from './push-notification.reducer';
export * from './app.state';
