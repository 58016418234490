import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, catchError, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { MyLSListMerchantResponse, Pagination } from '@ls/common-ts-models';

import {
  MerchantListAction,
  MerchantListErrorAction,
  MerchantListSuccessAction,
  MerchantListCheckboxAction,
  MerchantListRequestDownloadAction,
  MerchantListRequestDownloadCompleteAction,
  MerchantListRequestDownloadErrorAction,
  fnMerchantListTableState,
} from '../reducers';
import { MMMerchantService, S3DownloadService } from '../services';
import { MMAppState } from '../reducers/app.state';

@Injectable()
export class MerchantListEffect {
  public list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantListAction),
      switchMap((action) => {
        return this.merchantService
          .listMerchants(
            action.sortField,
            action.sortDirection,
            action.pageUpdateOptions.pageNumber,
            action.pageUpdateOptions.pageSize,
            action.filters,
          )
          .pipe(
            map((res: MyLSListMerchantResponse) => {
              this.store.dispatch(
                MerchantListCheckboxAction({
                  sortField: action.sortField,
                  sortDirection: action.sortDirection,
                  filters: action.filters,
                  actionPositions: action.actionPositions,
                  globalCheckbox: action.globalCheckbox,
                  totalRecords: res.pagination.totalRecords,
                }),
              );
              // This should really be driven purely by the back end response. Backend is currently not returning correct
              // pagination information
              const calculatedEndPosition =
                action.pageUpdateOptions.pageNumber * action.pageUpdateOptions.pageSize <= res.pagination.totalRecords
                  ? action.pageUpdateOptions.pageNumber * action.pageUpdateOptions.pageSize
                  : res.pagination.totalRecords;
              const adjustedPagination: Pagination = {
                ...res.pagination,
                pageNumber: action.pageUpdateOptions.pageNumber,
                startPosition: 1 + action.pageUpdateOptions.pageSize * (action.pageUpdateOptions.pageNumber - 1),
                endPosition: calculatedEndPosition,
              };
              //Seems like there may be a backend concern to address here, given that "" values should not be coming back as tags
              const merchantsWithCleanedTags = res.merchants.map((merch) => {
                return {
                  ...merch,
                  ls_tags: merch.ls_tags.filter((tag) => tag !== ''),
                  customer_tags: merch.customer_tags.filter((tag) => tag !== ''),
                };
              });
              return MerchantListSuccessAction({ merchants: merchantsWithCleanedTags, pagination: adjustedPagination });
            }),
            catchError((err) => of(MerchantListErrorAction({ err }))),
          );
      }),
    ),
  );

  public requestMerchantListDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantListRequestDownloadAction),
      withLatestFrom(this.store.select(fnMerchantListTableState)),
      switchMap(([action, state]) => {
        return this.s3DownloadService.requestDownloadUrl(state.filters).pipe(
          map((res: any) => {
            return MerchantListRequestDownloadCompleteAction({ response: res });
          }),
          catchError((err) => of(MerchantListRequestDownloadErrorAction({ err }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<MMAppState>,
    private merchantService: MMMerchantService,
    private s3DownloadService: S3DownloadService,
  ) {}
}
