import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ASAccount, isASAccountPropertyBoolean, ConfirmPassword, passwordPattern } from '@ls/common-ts-models';
import { GenericNotificationAction, SeverityOptions, confirmPasswordMatchValidator } from '@ls/common-ng-components';
import { UsersService, AuthenticationService, AccountService } from '../../../services';
import { AppState } from '../../../reducers';
import { get } from 'lodash';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'create-password',
  templateUrl: 'create-password.component.html',
  styleUrls: ['../invited-user-login.component.scss'],
})
export class CreatePasswordComponent implements OnInit, OnDestroy {
  public loading = true;
  public expiredToken = false;
  public model: ConfirmPassword = {} as ConfirmPassword;
  public passwordForm: FormGroup;
  public pwdPattern = passwordPattern;

  private token: string;
  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    public router: Router,
    public usersService: UsersService,
    public authService: AuthenticationService,
    private store: Store<AppState>,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
  ) {}

  public isExternalIdp(accounts: ASAccount[] = []): boolean {
    return accounts
      .map((account) => account.account_properties || [])
      .filter((accountProperties) => accountProperties.length > 0)
      .some((accountProperties) => {
        return accountProperties
          .filter(isASAccountPropertyBoolean)
          .some((accountProperty) => accountProperty.key === 'is_external_idp' && accountProperty.value);
      });
  }

  public updateInfo() {
    this.router.navigate(['/invited-user-login/update-info']);
  }

  public ngOnInit() {
    this.token = this.accountService.parseTokenFromUrl(this.router.url, 'token');

    // request to ensure confirmation token hasn't expired
    this.loading = true;
    this.usersService
      .checkConfirmationToken(this.token)
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe(
        (response) => {
          if (response.status === 409) {
            this.store.dispatch(
              GenericNotificationAction({
                severity: SeverityOptions.WARN,
                summary: 'Confirmation already completed',
                detail:
                  "It looks like you've already confirmed your user account.  Please log in.  If you no longer remember your username or password, please use the Forgot Password link",
                sticky: false,
                blocking: false,
              }),
            );
            this.router.navigate(['/login']);
          } else if (response.status === 410) {
            this.expiredToken = true;
          } else if (this.isExternalIdp(get<ASAccount[]>(response, 'data.user.accounts', []))) {
            this.accountService
              .confirmAccount(this.token)
              .pipe(takeUntil(this.destroyed$.asObservable()))
              .subscribe(
                () => this.updateInfo(),
                () => {
                  this.store.dispatch(
                    GenericNotificationAction({
                      severity: SeverityOptions.ERROR,
                      summary: 'Error confirming user account',
                      detail:
                        'There was an error confirming your user account.  Please close this window and try clicking the confirmation link in your email again.',
                      blocking: false,
                      sticky: false,
                    }),
                  );
                },
              );
          } else {
            this.loading = false;
          }
        },
        () => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Error checking token',
              detail:
                'There was an error checking your confirmation token.  Please close this window and try clicking the confirmation link in your email again.',
              sticky: false,
              blocking: false,
            }),
          );
        },
      );
    this.passwordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(255), Validators.pattern(this.pwdPattern)]],
        confirmPassword: ['', [Validators.required, this.passwordsMatch]],
      },
      { validators: confirmPasswordMatchValidator },
    );
  }

  passwordsMatch: ValidatorFn = (): ValidationErrors | null => {
    const pass = this.passwordForm?.get('password')?.value;
    const confirm = this.passwordForm?.get('confirmPassword')?.value;
    if (!pass || !confirm) {
      return { notSet: true };
    }
    return pass === confirm ? null : { notSame: true };
  };

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public submit() {
    if (this.passwordForm.invalid) {
      return;
    }
    const formValue: ConfirmPassword = {
      password: this.passwordForm.get('password').value,
      confirmPassword: this.passwordForm.get('confirmPassword').value,
    };

    this.loading = true;
    this.usersService
      .confirmAndSetPassword(this.token, formValue)
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe(
        () => this.updateInfo(),
        () => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Error saving info',
              detail: 'There was an error saving your password.  Please try again.',
              sticky: false,
              blocking: false,
            }),
          );
          this.loading = false;
        },
      );
  }
}
