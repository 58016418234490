<div class="widget">
  <div class="widget-header">
    <div *ngIf="loading; else resultsHeader">
      <h1>Loading Information</h1>
    </div>
    <ng-template #resultsHeader>
      <h1 class="error" *ngIf="error; else noErrorHeader">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{error}}
      </h1>
      <ng-template #noErrorHeader>
        <h1 *ngIf="!website">No website found</h1>
        <h1 *ngIf="website">
          {{website.url}}
          <span *ngIf="website.approval_status === 'certified'"> is <span class="status certified">certified</span> by LegitScript</span>
          <span *ngIf="website.approval_status === 'legitimate' || website.approval_status === 'rogue' || website.approval_status === 'monitored'"> is a <span class="status {{website.approval_status}}">{{website.approval_status}}</span> {{website.website_type.split("_").join(" ") }} website</span>
          <span *ngIf="website.approval_status === 'unapproved' || website.approval_status === 'unverified' || website.approval_status === 'inapplicable'"> is an <span class="status {{website.approval_status}}">{{website.approval_status}}</span> {{website.website_type.split("_").join(" ") }} website</span>
          <span *ngIf="website.approval_status === 'probationary_certified'"> has been granted <span class="status probationary_certified">probationary certification</span> status by LegitScript</span>
        </h1>
      </ng-template>
    </ng-template>
  </div>
  <div class="widget-body centered">
    <div class="entry-container entry-container-large">
      <loading-spinner [loadingText]="'Loading'" *ngIf="loading; else resultsBody"></loading-spinner>
      <ng-template #resultsBody>
        <error-notice *ngIf="error; else noErrorBody" [type]="error"></error-notice>
        <ng-template #noErrorBody>
          <div *ngIf="!website">
            <h2>Sorry, we don't have any info on “{{searchTerm}}” in our database.</h2>
          </div>
          <div class="website-summary-table-container" *ngIf="website">
            <table class="website-summary-table">
              <tbody>
              <tr>
                <td>URL:</td>
                <td>{{website.url}}
                </td>
              </tr>
              <tr>
                <td>Website type:</td>
                <td>{{website.website_type.split("_").join(" ") | titlecase }}</td>
              </tr>
              <tr>
                <td>Approval Status:</td>
                <td *ngIf="website.approval_status === 'certified'">
                  <span class="caps color-certified">certified</span>
                  <p>LegitScript has reviewed this website or merchant as part of our certification program, and has determined that it meets our standards for legality, safety and transparency! That means that we monitor this merchant regularly. If you have any concerns or problems with this website or merchant, please make sure to drop us a note and let us know.</p><p>More information about the standards we use to certify and monitor merchants can be found <a href="https://www.legitscript.com/service/certification/" target="_blank">here</a>.</p>
                </td>
                <td *ngIf="website.approval_status === 'legitimate'">
                  <span class="caps color-certified">legitimate</span>
                  <p>LegitScript classifies this website or merchant as legitimate. However, the merchant has not been certified by LegitScript as part of our <a href="https://www.legitscript.com/service/certification/" target="_blank">Healthcare Merchant Certification Program</a>.</p>
                  <p>The most likely reason this website or merchant is rated "legitimate” is that it has been certified by another similar certification program that LegitScript recognizes as credible. If you have any concerns or problems with this website or merchant, please make sure to drop us a note and let us know.</p>
                </td>
                <td *ngIf="website.approval_status === 'rogue'">
                  <span class="caps color-rogue">rogue</span>
                  <p>LegitScript has reviewed this Internet pharmacy and determined that it does not meet LegitScript Internet pharmacy verification standards.</p>
                  <p>Additionally, LegitScript has determined that this pharmacy website meets our definition of a Rogue Internet Pharmacy.</p>
                </td>
                <td *ngIf="website.approval_status === 'unapproved'">
                  <span class="caps color-unapproved">unapproved</span>
                  <p>LegitScript has reviewed this Internet pharmacy and determined that it does not meet LegitScript Internet pharmacy verification standards.</p>
                </td>
                <td *ngIf="website.approval_status === 'monitored'">
                  <span class="caps color-monitored">monitored</span>
                  <p>This website has been successfully enrolled in LegitScript’s Voluntary Compliance Program and has agreed to be monitored on an ongoing basis for regulatory compliance.</p>
                </td>
                <td *ngIf="website.approval_status === 'unverified'">
                  <span class="caps color-unverified">unverified</span>
                  <p>LegitScript has not determined whether {{website.url}} meets LegitScript standards for safety and legitimacy.</p>
                  <p>An Unverified listing does not mean that {{website.url}} is an unsafe or illegal Internet Pharmacy. It only means that LegitScript has not had an opportunity to determine whether {{website.url}} meets our standards.</p>
                </td>
                <td *ngIf="website.approval_status === 'inapplicable'">
                  <span class="caps color-inapplicable">inapplicable</span>
                </td>
                <td *ngIf="website.approval_status === 'probationary_certified'">
                  <span class="caps color-probationary_certified">probationary certified</span>
                  <p>LegitScript has reviewed this website or merchant and granted it probationary certification status. Typically, this status is granted when the website’s or merchant’s current business practices appear to meet LegitScript’s certification criteria for legality, safety and transparency, but there have been previous instances of non-compliance with LegitScript standards.</p>
                  <p>For more information on this status, please see our <a href="https://www.legitscript.com/blog/2017/08/legitscript-pharmacy-certification-to-include-probationary-status/" target="_blank">blog post</a> about probationary certification</p>
                  <p>We only grant probationary certification status if the website or merchant has demonstrated good faith, successful efforts to fix past problems and remain in compliance. We do monitor this merchant regularly, and want to hear about any problems or concerns internet users may have. If you have any concerns or problems with this website or merchant, please make sure to drop us a note and let us know.</p>
                  <p>More information about the standards we use to certify and monitor merchants can be found <a href="https://www.legitscript.com/service/certification/" target="_blank">here</a>.</p>
                </td>
              </tr>
              <tr *ngIf="website.approval_status === 'rogue'">
                <td>Rogue Definition:</td>
                <td>
                  An Internet pharmacy is rogue if it qualifies as unapproved and:
                  <p>The sale, prescribing or dispensing of prescription or other drugs reasonably appears to intentionally or knowingly violate, facilitate the violation of, or offer to facilitate the violation of applicable laws or regulations, defined as the laws and regulations where the drugs are dispensed from or where they are offered to be shipped to;</p>
                  <p>Does not adhere to accepted standards of medical and/or pharmacy practice, including standards of safety; and/or</p>
                  <p>Engages in fraudulent or deceptive business practices.</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
