<div class="cert-progress-wrapper">
    <div class="progress-element">
        <div class="progress-bar-status-wrapper">
            <div class="title flex">
                <div>Overall Progress</div>
                <div>{{complete}} / {{totalQuestions}}</div>
            </div>
            <p-progressBar [value]="progressValue" [showValue]=false></p-progressBar>
        </div>
        <div class="progress-status">
            <div class="details" *ngIf="pending; else showProgress">
                <i class="fa fa-hourglass"></i><span>Saving Progress</span>
            </div>
            <ng-template #showProgress>
                <div class="details" *ngIf="(complete < totalQuestions) && !serverError">
                    <i class="fa fa-check-circle"></i><span>Progress Saved</span>
                </div>
                <div *ngIf="(complete === totalQuestions) && !serverError">
                    <div class="details" *ngIf="!editing; else showEditing">
                        <i class="fa fa-check-circle"></i><span>Questionnaire Complete!</span>
                    </div>
                    <ng-template #showEditing>
                        <div class="details">
                            <i class="fa fa-pencil-square-o"></i><span>Editing Answer</span>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </div>
    <hr />
    <div class="progress-element">
        <div class="title">Don't know the answer?</div>
        Just hit "Answer Later" and we'll move it to the end of the list.
    </div>
    <hr />
    <div class="progress-element">
        <div class="title">Need to do some research?</div>
        Your progress is always saved so you can leave, come back later, and pick up right where you left off.
    </div>
</div>