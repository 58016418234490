import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MIME_TYPES } from '../../../constants';
import { UPLOAD_COMPONENT_ENUMS } from '../../../enums';
import { AppState } from '../../../reducers';
import { MMUploadService } from '../services';
import { SpreadSheetFileReader } from '../services/spreadsheet-file-reader';
import { UploadWorkbookValidator } from '../services/validators/upload-workbook-validator';
import { ValidationResult } from '../SharedCustomTypes/validation-result';
import { FileUpload } from 'primeng/fileupload';
import { isProdBuild } from 'config/helpers';

@Component({
  selector: 'mm-upload',
  styleUrls: ['./upload.component.scss'],
  templateUrl: './upload.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class MMUploadComponent implements OnDestroy, OnInit {
  @ViewChild('file') public fileList: FileUpload;
  @ViewChild('downloadMcCodes') public mmcCodesAnchor: ElementRef;
  @ViewChild('downloadTemplate') public uploadTemplateAnchor: ElementRef;
  public isLoading = false;
  public preparingUpload = false;
  public uploadProgress = 0;
  public success = true;
  public fileName: string;
  public acceptedFileTypes = '.xlsx,.csv,text/csv';
  public destroyed$: Subject<boolean> = new Subject();
  public fileUploadRestrictions: string = UPLOAD_COMPONENT_ENUMS.fileUploadRestriction;
  public isCSVInvalid = false;
  public isXlSXInvalid = false;
  public validationMessage = '';
  public downloadURLs: {
    mcCodes: string;
    template: string;
  } = {
    mcCodes: '',
    template: '',
  };
  private mimeTypes = MIME_TYPES;

  constructor(
    private store: Store<AppState>,
    private uploadService: MMUploadService,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.setDownloadURLs();
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public async startFileUploadProcess(evt: { files: File[] }) {
    this.validationMessage = '';
    this.isCSVInvalid = false;
    this.isXlSXInvalid = false;
    this.isLoading = this.preparingUpload = true;
    const [file] = evt.files;

    try {
      switch (file.type) {
        case this.mimeTypes.CSV:
        case this.mimeTypes.XLSX: {
          const workBook = await SpreadSheetFileReader.getWorkbook(file);
          const validationResult: ValidationResult = UploadWorkbookValidator.validate(workBook);
          this.isCSVInvalid = !validationResult.success;
          this.validationMessage = validationResult.validationError;
          break;
        }
        default: {
          this.isCSVInvalid = true;
          this.isXlSXInvalid = true;
          this.validationMessage = 'Invalid file type.';
          break;
        }
      }
    } catch (error) {
      this.store.dispatch(
        GenericNotificationAction({
          severity: SeverityOptions.ERROR,
          summary: 'File parsing error',
          detail:
            'An error occurred while parsing your file.  Please try again.  If this problem persists, please contact support',
          sticky: true,
          blocking: true,
        }),
      );
      this.uploadProgress = 0;
      this.isLoading = this.preparingUpload = false;
      return;
    }

    if (this.isCSVInvalid || this.isXlSXInvalid) {
      this.uploadProgress = 0;
      this.isLoading = this.preparingUpload = false;

      return;
    }
    return this.uploadFile(evt);
  }

  public uploadFile(evt: { files: File[] }) {
    this.isLoading = this.preparingUpload = true;
    const [file] = evt.files;
    this.fileName = file.name;

    this.uploadService
      .uploadSpreadsheet(file)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (event) => {
          this.preparingUpload = false;
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadProgress = Math.floor((event.loaded / event.total) * 100);
          }
          if (event.type === HttpEventType.Response) {
            this.success = true;
          }
        },
        () => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Upload Error',
              detail:
                'An error occurred while uploading your file.  Please try again.  If this problem persists, please contact support',
              sticky: true,
              blocking: true,
            }),
          );
          this.uploadProgress = 0;
          this.isLoading = this.preparingUpload = false;
        },
      );
  }

  public resetValidationMessage() {
    this.validationMessage = '';
  }

  public continue() {
    this.validationMessage = '';
    this.uploadProgress = 0;
    this.fileName = '';
    this.fileList.clear();
    this.isLoading = false;
    this.router.navigate(['/services/merchant-monitoring/list']);
  }

  public triggerDownload(domRef: string) {
    if (domRef === 'downloadTemplate') {
      this.uploadTemplateAnchor.nativeElement.click();
    } else {
      this.mmcCodesAnchor.nativeElement.click();
    }
  }

  public setDownloadURLs() {
    if (isProdBuild()) {
      this.downloadURLs.mcCodes = UPLOAD_COMPONENT_ENUMS.mcCodesDownloadURLProd;
      this.downloadURLs.template = UPLOAD_COMPONENT_ENUMS.templateDownloadURLProd;
    } else {
      this.downloadURLs.mcCodes = UPLOAD_COMPONENT_ENUMS.mcCodesDownloadURLDev;
      this.downloadURLs.template = UPLOAD_COMPONENT_ENUMS.templateDownloadURLDev;
    }
  }
}
