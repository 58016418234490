import { MyLSPushNotificationMessage } from '@ls/common-ts-models';

export enum NotificationStatus {
  read,
  unread,
}

export interface Notification extends MyLSPushNotificationMessage {
  id: string;
  date: Date;
  icon?: string;
  status?: NotificationStatus;
  isFinalJobMessage?: boolean;
  showToast?: boolean;
}
