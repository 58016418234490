<legitscript-modal [draggable]=false [closable]=false [focusOnShow]=false [resizable]=false [responsive]=true [modal]=true autoAlign=true [width]=800>
    <div class="dialog-body" *ngIf="showLegacyText; else showECRenewalInstructions">
        <div>
            <h1>Renewal Instructions</h1>
            <div>
                In order to renew your certification, you will need to:
                <ol>
                    <li>Answer any questions that may have been added or updated since you last applied (these will be displayed first, if applicable)</li>
                    <li>Review your answers to existing questions to ensure any information you provided is up to date.</li>
                </ol>
            </div>
        </div>
    </div>
    <ng-template #showECRenewalInstructions>
        <div class="dialog-body">
            <div>
                <h1>Renewal Instructions</h1>
                <div>
                    Your certification renewal will take place in the new <a [href]=experienceCloudUrl target="_blank">MyLegitScript Certification portal</a>. You may find it helpful to view your previous responses here in order to facilitate your renewal process. After this renewal year, your previous year's renewal questionnaire responses will be available in the new MyLegitScript Certification portal.
                    <ol>
                        <li>Please note that given the enhancement to this year’s renewal questionnaire, the questions may not be a 1:1 match in number and order to what you see here. Ctrl+F or Cmd+F can help you find the responses you are looking for.</li>
                        <li>Review your answers to existing questions to ensure all information you provided is up to date.</li>
                    </ol>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="dialog-footer" style="display:flex; justify-content: flex-end; align-items: center;">
        <button (click)="hideInstructions()">Got it!</button>
    </div>
</legitscript-modal>