import { empty as observableEmpty, Subject, Subscription } from 'rxjs';

import { catchError, tap, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { User, UserStatus, Roles, Account } from '@ls/common-ts-models/';

import {
  AppState,
  AccountState,
  UserSignOutAction,
  UserAuthenticateSuccessAction,
  AccountLoadSuccessAction,
} from '../../../reducers';
import {
  LocalStorageService,
  GenericNotificationAction,
  SeverityOptions,
  LegitScriptModalComponent,
} from '@ls/common-ng-components';
import { UsersService } from '../../../services/users.service';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { UpdateEmailComponent } from '../update-email/update-email.component';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'user-list',
  styleUrls: ['./user-list.component.scss'],
  templateUrl: './user-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class UserListComponent implements OnInit, OnDestroy {
  @Input() public manageUsers = false;
  log($event: any) {
    console.log($event);
  }
  public closable = false;
  public model: User = {} as User;
  public processing = false;
  public loading = false;
  public users$: Subscription;
  public newOwner: User;
  public users: User[];
  public error = false;
  public items: MenuItem[];

  private localStorageSvc: LocalStorageService = new LocalStorageService();

  @ViewChild(EditUserComponent) private manageUserModal: EditUserComponent;
  @ViewChild(UpdateEmailComponent) private updateEmailModal: UpdateEmailComponent;
  @ViewChild(LegitScriptModalComponent) private confirmNewOwnerModal: LegitScriptModalComponent;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private usersService: UsersService,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.users$ = this.getUsers();
  }

  public ngOnDestroy() {
    if (this.users$) {
      this.users$.unsubscribe();
    }

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getUsers(): Subscription {
    this.loading = true;
    return this.usersService
      .getUsersForAccount()
      .pipe(
        catchError(() => {
          this.error = true;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Failed to retrieve users',
              detail: 'An error occured attempting to fetch users. Please try again.',
              sticky: false,
              blocking: false,
            }),
          );
          return observableEmpty();
        }),
        tap(() => (this.loading = false)),
        takeUntil(this.destroyed$.asObservable()),
      )
      .subscribe((users: User[]) => (this.users = users));
  }

  public getItems(user: User) {
    this.items = [
      { label: 'Edit', command: () => this.showEditUser(user) },
      // Disabling this to fix an issue reported on https://legitscript.atlassian.net/browse/LS-27349
      // We'll work on a future story to fix the issue and re enable this if it's needed, a workaround was handled
      // with John Rad to update the email via Salesforce and Account Service if it's needed in the meantime
      // Story to fix this: https://legitscript.atlassian.net/browse/LS-27358
      // {
      //   label: 'Update Email',
      //   command: () => this.showUpdateEmail(user),
      //   visible: user.role === 'Account Owner', // is there another way to check that this is the current user?
      // },
      {
        label: 'Deactivate',
        visible: user.status === UserStatus.active && user.role !== 'Account Owner',
        command: () => this.toggleActivation(user, false),
      },
      {
        label: 'Activate',
        visible: user.status === UserStatus.deactivated && user.role !== 'Account Owner',
        command: () => this.toggleActivation(user, true),
      },
      {
        label: 'Re-send Invitation',
        visible: user.status === UserStatus.pending,
        command: () => this.resendInvite(user),
      },
      {
        label: 'Promote to Account Owner',
        visible: user.status === UserStatus.active && user.role !== 'Account Owner',
        command: () => this.confirmNewOwner(user),
      },
    ];
    return this.items;
  }

  public toggleActivation(user: User, active: boolean) {
    this.usersService
      .toggleActivation(user.lsUserId, active, user.sfAccountId, user.sfContactId)
      .pipe(
        catchError((err) => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Failed to change user status.',
              detail: err,
              sticky: false,
              blocking: false,
            }),
          );
          return observableEmpty();
        }),
        takeUntil(this.destroyed$.asObservable()),
      )
      .subscribe(() => {
        const successMessage = `Successfully ${active ? 'Activated' : 'Deactivated'} user ${user.firstName} ${
          user.lastName
        }`;
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.SUCCESS,
            summary: 'Update Complete',
            detail: successMessage,
            sticky: false,
            blocking: false,
          }),
        );
        this.getUsers();
      });
  }

  public showInviteUser() {
    this.model = {} as User;
    this.manageUserModal.showModal('invite');
  }

  public showEditUser(user: User) {
    for (const prop in user) {
      // eslint-disable-next-line no-prototype-builtins
      if (user.hasOwnProperty(prop)) {
        this.model[prop] = user[prop];
      }
    }

    this.manageUserModal.showModal('edit');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public showUpdateEmail(_user: User) {
    this.updateEmailModal.showModal();
  }

  public submit() {
    this.users$ = this.getUsers();
    this.manageUserModal.hideModal();
  }

  public resendInvite(user) {
    const account = this.localStorageSvc.getAccount();
    this.usersService.resendInvite(user, account.name).subscribe(
      (response) => {
        // if token was updated (because it was expired), be sure to refresh user list
        if (response.updated_token) {
          this.users$ = this.getUsers();
        }

        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.SUCCESS,
            summary: 'Invitation Resent',
            detail: `Invitation email successfully resent to ${response.email}`,
            sticky: false,
            blocking: false,
          }),
        );
      },
      () => {
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Failed to Resend',
            detail: 'An error occured attempting to resend the invitation. Please try again.',
            sticky: false,
            blocking: false,
          }),
        );
      },
    );
  }

  public confirmNewOwner(user: User) {
    this.newOwner = user;
    this.confirmNewOwnerModal.showDialog();
  }

  public changeAccountOwner() {
    const currentOwner: User = this.localStorageSvc.getUser();
    const account: Account = this.localStorageSvc.getAccount();
    const { firstName, lastName } = this.newOwner;

    this.confirmNewOwnerModal.hideDialog();
    this.processing = true;
    this.usersService.changeAccountOwner(currentOwner, this.newOwner, account.name).subscribe(
      (response: any) => {
        if (!response.jwt || !response.newPrimaryContact) {
          // the actual updates succeeded, but something went wrong updating the logged in user's jwt or fetching the new primary contact info- just log them out and have them log in again: their permissions and the primary contact info should be good at that point
          this.store.dispatch(UserSignOutAction({ location: '/account/home' }));
        } else {
          this.localStorageSvc.setToken(response.jwt);
          const currentAuthUser = this.localStorageSvc.getAuthenticatedUser();
          currentAuthUser.roles = [Roles.cpv2User];
          this.localStorageSvc.setAuthenticatedUser(currentAuthUser);
          this.store.dispatch(UserAuthenticateSuccessAction({ user: currentAuthUser, roles: currentAuthUser.roles }));

          const account: AccountState = this.localStorageSvc.getAccount();
          account.primaryContact = response.newPrimaryContact;
          this.localStorageSvc.setAccount(account);
          this.store.dispatch(AccountLoadSuccessAction({ payload: account }));
        }

        this.processing = false;
        this.newOwner = null;
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.SUCCESS,
            summary: 'Owner Change Successful',
            detail: `You have successfully made ${firstName} ${lastName} the account owner.`,
            sticky: false,
            blocking: false,
          }),
        );
        this.router.navigate(['/account/home']);
      },
      () => {
        this.processing = false;
        this.newOwner = null;
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Failed to update owner',
            detail: 'An error occured attempting to update the account owner. Please try again.',
            sticky: false,
            blocking: false,
          }),
        );
      },
    );
  }

  public closeNewOwnerModal() {
    this.confirmNewOwnerModal.hideDialog();
    this.newOwner = null;
  }
}
