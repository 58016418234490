import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PaymentPlan,
  PromoCode,
  PromoCodeType,
  BillingItem,
  PricingInfo,
  MAX_CC_AMOUNT_CERTIFICATION,
  PromoCodeProduct,
  ExpeditedProcessingProductGuid,
  MerchantMonitoringProductGuid,
} from '@ls/common-ts-models';
import { BillingService, GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { AppState } from '../../../reducers';
import { PaymentService } from '../../../services';
import { Store } from '@ngrx/store';

@Component({
  selector: 'amount-due-panel',
  templateUrl: './amount-due.component.html',
  styleUrls: ['./amount-due.component.scss'],
})
export class AmountDueComponent implements OnInit {
  @Input() public paymentPlan: PaymentPlan;
  @Input() public pricingInfo: PricingInfo;
  @Input() public invoiceAccount: boolean;
  @Output() public promoCodeConfirmed = new EventEmitter<any>();
  @Output() public bypassPaymentEmitter = new EventEmitter<boolean>();
  public promoCodeButtonEnabled = true;
  public processingPromoCode = false;
  public promoCodeApplied = false;
  public promoCodeValue = '';
  public promoCodeDescription: string;
  public promoPricing: any = {};
  public purchaseTypeSupportsPromoCode = true;
  public bypassPayment = false;
  public promoString: string;
  private NO_PRODUCT_MATCH = 'The promo code you provided cannot be used for this purchase';

  constructor(
    private billingSvc: BillingService,
    private store: Store<AppState>,
    private paymentSvc: PaymentService,
    private router: Router,
  ) {}

  public ngOnInit() {
    // TODO: update when promo codes supported for data lookup purchases (LS-13030)
    this.purchaseTypeSupportsPromoCode = !this.paymentPlan.billingSummary.billingItems
      .map((item: BillingItem) => item.description.toLowerCase())
      .find((itemDescription) => itemDescription === 'database search');

    const { promoCode } = this.billingSvc.getPricingInfo();
    if (promoCode && promoCode.value) {
      this.onApply(promoCode.value);
    }
  }

  public async onApply(inputValue: string) {
    if (!inputValue) {
      return;
    }

    this.promoCodeButtonEnabled = false;
    this.processingPromoCode = true;

    this.billingSvc.confirmPromoCode(inputValue).subscribe(
      (promoCode: PromoCode) => {
        switch (promoCode.type) {
          // there will be other types (like "Whole Purchase"), but they are not yet implemented
          case PromoCodeType.PRODUCT:
            if (this.updatePricingProducts(promoCode) === this.NO_PRODUCT_MATCH) {
              return;
            }
            break;
          default:
            this.store.dispatch(
              GenericNotificationAction({
                severity: SeverityOptions.ERROR,
                summary: 'Invalid Promo Code Type',
                detail: 'Looks like there is a problem with this promo code- please contact support.',
                sticky: true,
                blocking: true,
              }),
            );
            this.processingPromoCode = false;
            return;
        }

        this.promoCodeValue = promoCode.value;
        this.promoCodeDescription = promoCode.description;
        this.processingPromoCode = false;
        this.promoCodeApplied = true;
        this.pricingInfo.promoCode = promoCode;
        this.billingSvc.setPricingInfo(this.pricingInfo);

        this.paymentPlan = this.paymentSvc.buildPaymentPlan(this.pricingInfo);
        if (
          this.paymentPlan.billingSummary.dueToday > MAX_CC_AMOUNT_CERTIFICATION &&
          this.paymentPlan.billingSummary.discountedDueToday !== undefined &&
          this.paymentPlan.billingSummary.discountedDueToday <= MAX_CC_AMOUNT_CERTIFICATION
        ) {
          this.router.navigate(['/services/merchant-certification/payment']);
        }
      },
      (err) => {
        const { error } = err;
        let detail =
          'An unknown error occurred processing your promo code.  Please double check your code and try again.';
        if (error && error.message) {
          detail = error.message;
        }

        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Promo Code Error',
            detail,
            sticky: true,
            blocking: true,
          }),
        );
        this.processingPromoCode = false;
        this.promoCodeButtonEnabled = true;
      },
    );
  }

  public removePromoCode() {
    this.processingPromoCode = true;
    this.promoCodeValue = this.promoCodeDescription = undefined;
    const storedPricingInfo = this.billingSvc.getPricingInfo();
    delete storedPricingInfo.promoCode;
    storedPricingInfo.billingItems.forEach((p) => {
      if (p.discountedAmount || p.discountedAmount === 0) {
        delete p.discountedAmount;
      }
      if (p.discountedRecurringAmount || p.discountedRecurringAmount === 0) {
        delete p.discountedRecurringAmount;
      }
    });
    this.paymentPlan = this.paymentSvc.buildPaymentPlan(storedPricingInfo);
    this.billingSvc.setPricingInfo(storedPricingInfo);
    this.processingPromoCode = this.promoCodeApplied = false;
    this.promoCodeButtonEnabled = true;
    this.bypassPayment = false;
    this.bypassPaymentEmitter.emit(this.bypassPayment);
    if (
      this.paymentPlan.billingSummary.dueToday > MAX_CC_AMOUNT_CERTIFICATION &&
      this.paymentPlan.billingSummary.discountedDueToday === undefined
    ) {
      this.router.navigate(['/services/merchant-certification/payment-invoice']);
    }
  }

  private updatePricingProducts(promoCode: PromoCode) {
    // if NO billingItem productId matches a promoCode productId, this code is invalid for this checkout
    const foundProductMatch = this.paymentPlan.billingSummary.billingItems.some(
      (billingItem: BillingItem) =>
        !!promoCode.products.find((product: PromoCodeProduct) => {
          return (
            product.product_external_id === billingItem.productId ||
            product.product_external_id === billingItem.subscriptionProductId
          );
        }),
    );
    if (!foundProductMatch) {
      this.store.dispatch(
        GenericNotificationAction({
          severity: SeverityOptions.ERROR,
          summary: 'Promo Code Error',
          detail: this.NO_PRODUCT_MATCH,
          sticky: true,
          blocking: true,
        }),
      );
      this.processingPromoCode = false;
      this.promoCodeButtonEnabled = true;
      return this.NO_PRODUCT_MATCH;
    }

    for (const billingItem of this.paymentPlan.billingSummary.billingItems) {
      for (const product of promoCode.products) {
        if (billingItem.productId === product.product_external_id) {
          // product being discounted is application fee or add on service
          if (product.discount_amount) {
            const amountToDiscount = billingItem['amount'] - parseFloat(product.discount_amount);
            billingItem['discountedAmount'] = amountToDiscount >= 0 ? amountToDiscount : 0;
          } else {
            billingItem['discountedAmount'] =
              billingItem['amount'] - billingItem['amount'] * (parseInt(product.discount_percentage, 10) / 100);
          }
        }
        if (
          billingItem.subscriptionProductId === product.product_external_id ||
          billingItem.productId === MerchantMonitoringProductGuid.LOW_TIER
        ) {
          // MM Low_Tier is a subscription.
          // product being discounted is subscription fee
          if (product.discount_amount) {
            const amountToDiscount = billingItem['recurringAmount'] - parseFloat(product.discount_amount);
            billingItem['discountedRecurringAmount'] = amountToDiscount >= 0 ? amountToDiscount : 0;
          } else {
            billingItem['discountedRecurringAmount'] =
              billingItem['recurringAmount'] -
              billingItem['recurringAmount'] * (parseInt(product.discount_percentage, 10) / 100);
          }
        }
      }
    }

    this.checkForPayment();
    return true;
  }

  private checkForPayment() {
    const totalDue = this.paymentPlan.billingSummary.billingItems.reduce((acc, cur) => {
      if (cur.productId === ExpeditedProcessingProductGuid) {
        // expedited is a special case: there is no recurring amount
        return acc + cur.discountedAmount;
      }
      // otherwise, we only allow continue without payment if both "due today" and "due on approval" are 0
      return acc + cur.discountedAmount + cur.discountedRecurringAmount;
    }, 0);
    this.bypassPayment = totalDue <= 0;
    this.bypassPaymentEmitter.emit(this.bypassPayment);
  }
}
