
<p-dialog 
  [draggable]="false"
  [resizable]="false"
  [responsive]="true"
  [closable]="true"
  [modal]="true"
  [style]="{width: '548px', height: '591px'}"
  [visible]="true"
  (visibleChange)="cancel()"
  
  header="Request Test Transactions">

  <div class="new-test-transaction-body dialog-body">
    <div class="new-test-transaction-description">
      Enter the website URLs for test transactions. Choose their category, tags, persistence, and monitoring schedule. Add any related comments. Optionally, upload supplementary documents. 
    </div>
    <form [formGroup]="newTransactionForm" #form>
      <div>
        <label for="urls">Use a line break to separate multiple entries, not commas.</label>
        <div class="grow-wrap">
          <textarea pInputTextarea [autoResize]="true" id="urls" formControlName="urls" placeholder="Enter one or multiple URLs" onInput="this.parentNode.dataset.replicatedValue = this.value"></textarea>
        </div>
      </div>
      <div>
        <ls-upload accept=".xls,.xlsx,.csv" label="Upload" (filesChanged)="updateFiles($event)"></ls-upload>
      </div>
      <div>
        <label for="category">Category</label>
        <p-dropdown id="category" placeholder="None" formControlName="category" [options]="categories$|async" [style]="{width: '100%'}"></p-dropdown>
      </div>
      <div>
        <label for="recordTag">Record Tag</label>
        <p-dropdown id="recordTag" placeholder="None" formControlName="recordTag" [options]="tags$|async" [style]="{width: '100%'}"></p-dropdown>
      </div>
      <div>
        <label for="persistentMonitoring">Persistent Monitoring</label>
        <p-dropdown id="persistentMonitoring" placeholder="None" formControlName="persistentMonitoring" [options]="persistentMonitoring$|async" [style]="{width: '100%'}"></p-dropdown>
      </div>
      <div>
        <label for="comment">Comment</label>
        <textarea pInputTextarea [autoResize]="true" id="comment" placeholder="Add Comment" formControlName="comment" type="text"></textarea>
      </div>
      
    </form>
  </div>

  <div class="dialog-footer">
    <div class="form-actions">
      <p-button [text]="true" styleClass="p-button-secondary" (click)="cancel()" class="cancel">Cancel</p-button>
      <p-button type="submit" (click)="save()" [disabled]="!newTransactionForm.valid || (requesting$ | async) > 0" form="form" class="primary-button">Request Testing</p-button>
    </div>
  </div>

</p-dialog>
