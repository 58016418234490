import { createAction, createReducer, on, props } from '@ngrx/store';

export const GET_ICAS = '[Icas] Get';
export const GET_ICAS_SUCCESS = '[Icas] Get Success';
export const ICAS_ERROR = '[Icas] Icas Error';

export const GetIcasAction = createAction(GET_ICAS);

export const GetIcasSuccessAction = createAction(GET_ICAS_SUCCESS, props<{ icas: string[] }>());

export const IcasErrorAction = createAction(ICAS_ERROR, props<{ err?: Error }>());

export interface IcasState {
  err?: Error;
  icas: string[];
  pending: boolean;
}

export const initialIcasState: IcasState = {
  err: null,
  icas: [],
  pending: true,
};

export const IcasReducer = createReducer(
  initialIcasState,

  on(GetIcasSuccessAction, (state) => ({
    ...state,
    tags: [],
    err: null,
    pending: true,
  })),

  on(GetIcasSuccessAction, (state, { icas }) => ({
    ...state,
    err: null,
    icas: icas,
    pending: false,
  })),

  on(IcasErrorAction, (state, { err }) => ({
    ...state,
    err,
    pending: false,
  })),
);
