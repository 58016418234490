import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';
import { whitespaceValidator } from '@ls/common-ng-components';

@Directive({
  selector: '[checkWhitespace]',
  providers: [{ provide: NG_VALIDATORS, useExisting: WhitespaceValidatorDirective, multi: true }],
})
export class WhitespaceValidatorDirective implements Validator {
  public validator: ValidatorFn;

  constructor() {
    this.validator = whitespaceValidator();
  }

  public validate(control: AbstractControl): { [key: string]: any } | null {
    return this.validator(control);
  }
}
