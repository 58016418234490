import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '@ls/common-ng-components';
import { MyLSCustomerUpsert, Opportunity } from '@ls/common-ts-models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class MMEnrollService {
  private mmApiHost = environment.CONFIG.mmApiHost;
  private billingApiHost = environment.CONFIG.paymentServiceHost;

  constructor(private http: HttpClient, private localStorageSvc: LocalStorageService) {}

  public upsertCustomer(customer: MyLSCustomerUpsert): Observable<any> {
    return this.http.post(
      `${this.mmApiHost}/customer/upsert`,
      { customer },
      this.localStorageSvc.getJWTAndSetOptions()
    );
  }

  public checkForOpportunity() {
    return this.http.get(`${this.billingApiHost}/v1/mmOpportunity`, this.localStorageSvc.getJWTAndSetOptions());
  }

  public createOpportunity() {
    return this.http.post<Opportunity>(
      `${this.billingApiHost}/v1/mmOpportunity`,
      {},
      this.localStorageSvc.getJWTAndSetOptions()
    );
  }
}
