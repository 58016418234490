import { createAction, createReducer, on, props } from '@ngrx/store';
import { Questionnaire, Question, AnsweredQuestion } from '@ls/common-ts-models';

export interface Answers {
  id: string;
  questionnaireId: string;
  certificationId: string;
  answerLater: Question[];
  answeredQuestions: AnsweredQuestion[];
  showRenewalInstructions?: boolean;
}

//
// CONSTANTS
//
export enum QuestionnaireActionTypes {
  Submit = '[Questionnaire] Submit',
  SubmitSuccess = '[Questionnaire] SubmitSuccess',
  SubmitError = '[Questionnaire] SubmitError',
  Save = '[Questionnaire] Save',
  SaveSuccess = '[Questionnaire] SaveSuccess',
  SaveError = '[Questionnaire] SaveError',
  EditCompletedQuestion = '[Questionnaire] EditCompleted',
  CancelEditCompletedQuestion = '[Questionnaire] CancelEditCompleted',
  Previous = '[Questionnaire] Previous',
  PreviousSuccess = '[Questionnaire] PreviousSuccess',
  PreviousError = '[Questionnaire] PreviousError',
  AnswerLater = '[Questionnaire] AnswerLater',
  AnswerLaterSuccess = '[Questionnaire] AnswerLaterSuccess',
  AnswerLaterError = '[Questionnaire] AnswerLaterError',
  Load = '[Questionnaire] Load',
  LoadAndSetQuestion = '[Questionnaire] LoadAndSetQuestion',
  LoadSuccess = '[Questionnaire] LoadSuccess',
  LoadError = '[Questionnaire] LoadError',
  LoadCompletedQuestionnaire = '[Questionnaire] LoadCompleted',
  LoadCompletedQuestionnaireSuccess = '[Questionnaire] LoadCompletedSuccess',
  LoadCompletedQuestionnaireError = '[Questionnaire] LoadCompletedError',
  LoadQuestion = '[Questionnaire] LoadQuestion',
  LoadQuestionSuccess = '[Questionnaire] LoadQuestionSuccess',
  LoadQuestionError = '[Questionnaire] LoadQuestionError',
  UploadFiles = '[Questionnaire] UploadFiles',
  UploadFilesAndSave = '[Questionnaire] UploadFilesAndSave',
  UpdateShowInstructions = '[Questionnaire] UpdateShowInstructions',
}

//
// ACTIONS
//
export const QuestionnaireSubmitAction = createAction(
  QuestionnaireActionTypes.Submit,
  props<{ certificationId: string }>(),
);

export const QuestionnaireSubmitSuccessAction = createAction(QuestionnaireActionTypes.SubmitSuccess);

export const QuestionnaireSubmitErrorAction = createAction(
  QuestionnaireActionTypes.SubmitError,
  props<{ errorText: string }>(),
);

export const QuestionnaireSaveAction = createAction(QuestionnaireActionTypes.Save, props<{ updatedInfo: any }>());

export const QuestionnaireSaveSuccessAction = createAction(
  QuestionnaireActionTypes.SaveSuccess,
  props<{
    answers: Answers;
    currentQuestionId: string;
    isComplete: boolean;
    inAnswerLater: boolean;
    currentAnswer: AnsweredQuestion;
    currentQuestion: Question;
  }>(),
);

export const QuestionnaireSaveErrorAction = createAction(
  QuestionnaireActionTypes.SaveError,
  props<{ errorText: string }>(),
);

export const EditCompletedQuestionAction = createAction(QuestionnaireActionTypes.EditCompletedQuestion);

export const CancelEditCompletedQuestionAction = createAction(QuestionnaireActionTypes.CancelEditCompletedQuestion);

export const QuestionnairePreviousAction = createAction(QuestionnaireActionTypes.Previous);

export const QuestionnairePreviousSuccessAction = createAction(
  QuestionnaireActionTypes.PreviousSuccess,
  props<{
    answers: Answers;
    currentQuestionId: string;
    onFirstQuestion: boolean;
    inAnswerLater: boolean;
    currentQuestion: Question;
    currentAnswer;
  }>(),
);

export const QuestionnairePreviousErrorAction = createAction(
  QuestionnaireActionTypes.PreviousError,
  props<{ errorText: string }>(),
);

export const QuestionnaireAnswerLaterAction = createAction(QuestionnaireActionTypes.AnswerLater);

export const QuestionnaireAnswerLaterSuccessAction = createAction(
  QuestionnaireActionTypes.AnswerLaterSuccess,
  props<{
    answers: Answers;
    currentQuestionId: string;
    currentAnswer: AnsweredQuestion;
    answerLater: Question[];
    inAnswerLater: boolean;
    currentQuestion: Question;
  }>(),
);

export const QuestionnaireAnswerLaterErrorAction = createAction(
  QuestionnaireActionTypes.AnswerLaterError,
  props<{ errorText: string; answers?: Answers; currentAnswer?: AnsweredQuestion }>(),
);

// load when question id is in url params
export const QuestionnaireLoadAction = createAction(
  QuestionnaireActionTypes.Load,
  props<{
    certificationId: string;
    currentQuestionId: string;
    inAnswerLater?: boolean;
    renewing?: boolean;
  }>(),
);

// load when only certification id is available
export const QuestionnaireLoadAndSetQuestionAction = createAction(
  QuestionnaireActionTypes.LoadAndSetQuestion,
  props<{ certificationId: string; renewing?: boolean }>(),
);

export const QuestionnaireLoadSuccessAction = createAction(
  QuestionnaireActionTypes.LoadSuccess,
  props<{
    questionnaire: Questionnaire;
    currentQuestionId: string;
    answers: Answers;
    currentAnswer: AnsweredQuestion;
    answerLater: Question[];
    onFirstQuestion: boolean;
    inAnswerLater: boolean;
    isComplete?: boolean;
  }>(),
);

export const QuestionnaireLoadErrorAction = createAction(
  QuestionnaireActionTypes.LoadError,
  props<{ errorText: string }>(),
);

// load when question id is in url params
export const LoadCompletedQuestionnaireAction = createAction(
  QuestionnaireActionTypes.LoadCompletedQuestionnaire,
  props<{ certificationId: string }>(),
);

export const LoadCompletedQuestionnaireSuccessAction = createAction(
  QuestionnaireActionTypes.LoadCompletedQuestionnaireSuccess,
  props<{ questionnaire: Questionnaire; answers: any; isComplete?: boolean }>(),
);

export const LoadCompletedQuestionnaireErrorAction = createAction(
  QuestionnaireActionTypes.LoadCompletedQuestionnaireError,
  props<{ errorText: string }>(),
);

export const QuestionnaireLoadQuestionAction = createAction(QuestionnaireActionTypes.LoadQuestion);

export const QuestionnaireLoadQuestionSuccessAction = createAction(
  QuestionnaireActionTypes.LoadQuestionSuccess,
  props<{ question: Question; currentAnswer: AnsweredQuestion; onFirstQuestion: boolean }>(),
);

export const QuestionnaireLoadQuestionErrorAction = createAction(
  QuestionnaireActionTypes.LoadQuestionError,
  props<{ errorText: string }>(),
);

export const QuestionnaireUploadFilesAction = createAction(
  QuestionnaireActionTypes.UploadFiles,
  props<{ files: object[] }>(),
);

export const QuestionnaireUploadFilesAndSaveAction = createAction(
  QuestionnaireActionTypes.UploadFilesAndSave,
  props<{ certificationId: string; questionId: string; answer: any }>(),
);

export const UpdateShowInstructionsAction = createAction(
  QuestionnaireActionTypes.UpdateShowInstructions,
  props<{ showInstructions: boolean }>(),
);

//
// STATE
//
export interface QuestionnaireState {
  questionnaire: Questionnaire; // needed for tracking and displaying questions, but should never change
  question: Question; // the current question object to pass to sub-component
  answers: Answers; // object of currently answered questions
  answerLater: Question[]; // click 'answer later' and questions end up here.  isComplete will check answers and this
  inAnswerLater: boolean; // track if the user is viewing questions in the 'answerLater' array
  currentQuestionId: string; // for tracking next/previous clicking
  onFirstQuestion: boolean;
  onLastQuestion: boolean;
  currentAnswer: AnsweredQuestion; // if one exists that correlates to current question
  isComplete: boolean;
  isSubmitted: boolean;
  renewing: boolean;
  showRenewalInstructions: boolean;
  editingCompletedQuestion: boolean;
  pending: boolean; // only for loading
  errorText: string;
  uploadedFiles: object[];
}

const initialQuestionnaireState = {
  questionnaire: {} as Questionnaire,
  question: {} as Question,
  currentQuestionId: '',
  onFirstQuestion: true,
  onLastQuestion: false,
  answers: {} as Answers,
  answerLater: [] as Question[],
  inAnswerLater: false,
  currentAnswer: {} as AnsweredQuestion,
  isComplete: false,
  isSubmitted: false,
  renewing: false,
  showRenewalInstructions: false,
  editingCompletedQuestion: false,
  pending: true,
  errorText: undefined as string,
  uploadedFiles: null as object[],
};

//
// REDUCER
//
export const QuestionnaireReducer = createReducer(
  initialQuestionnaireState,

  on(QuestionnaireLoadAction, (state, { renewing, inAnswerLater }) => ({
    ...state,
    isComplete: false,
    isSubmitted: false,
    renewing,
    question: undefined,
    pending: true,
    errorText: undefined,
    inAnswerLater,
  })),

  on(QuestionnaireLoadAndSetQuestionAction, (state, { renewing }) => ({
    ...state,
    isComplete: false,
    isSubmitted: false,
    renewing,
    question: undefined,
    editingCompletedQuestion: false,
    pending: true,
    errorText: undefined,
  })),

  on(
    QuestionnaireLoadSuccessAction,
    (
      state,
      {
        questionnaire,
        currentQuestionId,
        answers,
        currentAnswer,
        answerLater,
        onFirstQuestion,
        inAnswerLater,
        isComplete,
      },
    ) => ({
      ...state,
      questionnaire,
      currentQuestionId,
      onFirstQuestion,
      onLastQuestion: false,
      answers,
      currentAnswer: currentAnswer || ({} as AnsweredQuestion),
      answerLater,
      inAnswerLater,
      isComplete,
      isSubmitted: false,
      pending: false,
    }),
  ),

  on(QuestionnaireLoadErrorAction, (state, { errorText }) => ({
    ...state,
    pending: false,
    errorText,
  })),

  on(LoadCompletedQuestionnaireAction, (state) => ({
    ...state,
    isComplete: true,
    isSubmitted: false,
    pending: true,
    errorText: undefined,
  })),

  on(LoadCompletedQuestionnaireSuccessAction, (state, { questionnaire, answers }) => ({
    ...state,
    questionnaire,
    onFirstQuestion: false,
    onLastQuestion: false,
    answers,
    isComplete: true,
    isSubmitted: false,
    pending: false,
  })),

  on(LoadCompletedQuestionnaireErrorAction, (state, { errorText }) => ({
    ...state,
    pending: false,
    errorText,
  })),

  // save and go to next question or show 'complete' screen if all answered
  on(QuestionnaireSaveAction, (state) => ({
    ...state,
    pending: true,
    errorText: undefined,
  })),

  on(
    QuestionnaireSaveSuccessAction,
    (state, { currentQuestionId, answers, isComplete, inAnswerLater, currentAnswer, currentQuestion }) => ({
      ...state,
      currentQuestionId,
      onFirstQuestion: false,
      onLastQuestion: false,
      answers,
      isComplete,
      answerLater: answers.answerLater,
      inAnswerLater,
      editingCompletedQuestion: false,
      pending: false,
      uploadedFiles: null,
      currentAnswer: currentAnswer || ({} as AnsweredQuestion),
      currentQuestion,
    }),
  ),

  on(QuestionnaireSaveErrorAction, (state, { errorText }) => ({
    ...state,
    pending: false,
    errorText,
  })),

  on(EditCompletedQuestionAction, (state) => ({
    ...state,
    isComplete: false,
    editingCompletedQuestion: true,
  })),

  on(CancelEditCompletedQuestionAction, (state) => ({
    ...state,
    isComplete: true,
    editingCompletedQuestion: false,
  })),

  // go to previous question
  on(QuestionnairePreviousAction, (state) => ({
    ...state,
    isComplete: false,
    isSubmitted: false,
    pending: true,
    errorText: undefined,
  })),

  on(
    QuestionnairePreviousSuccessAction,
    (state, { currentQuestionId, onFirstQuestion, answers, inAnswerLater, currentQuestion, currentAnswer }) => ({
      ...state,
      currentQuestionId,
      onFirstQuestion,
      onLastQuestion: false,
      answers,
      answerLater: answers.answerLater,
      inAnswerLater,
      pending: false,
      uploadedFiles: null,
      currentQuestion,
      currentAnswer: currentAnswer || ({} as AnsweredQuestion),
    }),
  ),

  on(QuestionnairePreviousErrorAction, (state, { errorText }) => ({
    ...state,
    pending: false,
    errorText,
  })),

  // add question to 'answer later' group and go to next question
  on(QuestionnaireAnswerLaterAction, (state) => ({
    ...state,
    pending: true,
    errorText: undefined,
  })),

  on(
    QuestionnaireAnswerLaterSuccessAction,
    (state, { currentQuestionId, answers, inAnswerLater, currentAnswer, currentQuestion }) => ({
      ...state,
      currentQuestionId,
      onFirstQuestion: false,
      onLastQuestion: false,
      answers,
      answerLater: answers.answerLater,
      inAnswerLater,
      currentAnswer: currentAnswer || ({} as AnsweredQuestion),
      pending: false,
      currentQuestion,
    }),
  ),

  on(QuestionnaireAnswerLaterErrorAction, (state, { answers, currentAnswer, errorText }) => ({
    ...state,
    answers: answers || state.answers,
    currentAnswer: currentAnswer || ({} as AnsweredQuestion),
    pending: false,
    errorText,
  })),

  // load individual question and answer
  on(QuestionnaireLoadQuestionAction, (state) => ({
    ...state,
    pending: true,
    errorText: undefined,
    question: undefined,
  })),

  on(QuestionnaireLoadQuestionSuccessAction, (state, { question, onFirstQuestion, currentAnswer }) => ({
    ...state,
    question,
    currentQuestionId: question.id,
    onFirstQuestion,
    currentAnswer: currentAnswer || ({} as AnsweredQuestion),
    pending: false,
  })),

  on(QuestionnaireLoadQuestionErrorAction, (state, { errorText }) => ({
    ...state,
    pending: false,
    errorText,
  })),

  // submit final questionnaire after complete/reviewed
  on(QuestionnaireSubmitAction, (state) => ({
    ...state,
    pending: true,
    errorText: undefined,
  })),

  on(QuestionnaireSubmitSuccessAction, (state) => ({
    ...state,
    isComplete: true,
    isSubmitted: true,
    pending: false,
  })),

  on(QuestionnaireSubmitErrorAction, (state, { errorText }) => ({
    ...state,
    pending: false,
    errorText,
  })),

  on(QuestionnaireUploadFilesAction, (state, { files }) => ({
    ...state,
    uploadedFiles: files,
  })),

  on(QuestionnaireUploadFilesAndSaveAction, (state) => ({
    ...state,
    pending: true,
    errorText: undefined,
  })),

  on(UpdateShowInstructionsAction, (state, { showInstructions }) => ({
    ...state,
    showRenewalInstructions: showInstructions,
  })),
);
