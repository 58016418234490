<h1>Pricing Information</h1>
<p><strong>Pricing is on a per-website basis.</strong> Our pricing comprises two parts: a one-time application fee and a recurring annual fee. For fees under $20,000, your credit card will be charged a nonrefundable application fee after completing this enrollment form. For fees more than $20,000, you will be issued an invoice for a bank ACH transfer for a nonrefundable application fee after completing this enrollment form. After that, the annual fee will be automatically charged on the date of approval of your application and will recur via credit card or invoice on an annual basis starting from the date of approval.</p>

<h2>Standard Pricing</h2>
<table class="pricing-modal-table">
  <tr>
    <th>Application Fee</th>
    <th>Annual Fee</th>
  </tr>
  <tr>
    <td>${{ pricing.CBD_WEBSITE_A.APPLICATION }}</td>
    <td>${{ pricing.CBD_WEBSITE_A.SUBSCRIPTION }}</td>
  </tr>
</table>

<h2>Probationary Category</h2>
<p>The purpose of probationary status is to provide a path forward for merchants and other CBD entities with a past history of significant compliance issues. Probationary certification will be granted in cases where LegitScript determines, through the application review, that a merchant is now in compliance with our standards. The application fee for probationary status is the same as regular applications but the annual fee is higher because we monitor the applicant’s activities in more depth and on a more frequent cadence.</p>

<h2>Probationary Pricing</h2>
<table class="pricing-modal-table">
  <tr>
    <th>Application Fee</th>
    <th>Annual Fee</th>
  </tr>
  <tr>
    <td>${{ pricing.CBD_WEBSITE_A_PROBATIONARY.APPLICATION }}</td>
    <td>${{ pricing.CBD_WEBSITE_A_PROBATIONARY.SUBSCRIPTION }}</td>
  </tr>
</table>
<expedited-pricing-info [enabled]="expeditedEnabled"></expedited-pricing-info>