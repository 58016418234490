import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthenticatedUser, JwtPayload } from '@ls/common-ts-models';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@ls/common-ng-components';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationService {
  private JwtHelper: JwtHelperService = new JwtHelperService();
  // Credentials
  constructor(
    private http: HttpClient,
    private localStorageSvc: LocalStorageService,
  ) {}

  public login(userEmail: string, userPassword: string): Observable<AuthenticatedUser> {
    return this.http
      .post(
        environment.CONFIG.portalApiHost + '/v1/login',
        {
          username: userEmail,
          password: userPassword,
        },
        { observe: 'response' },
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          const header = response.headers.get('Authorization') || response.headers.get('x-amzn-remapped-authorization');
          const jwt = header.replace('Bearer ', '');
          return this.decodeAndStoreFromJwt(jwt);
        }),
      );
  }

  public decodeAndStoreFromJwt(jwt: string): AuthenticatedUser {
    const decodedToken: JwtPayload = this.JwtHelper.decodeToken(jwt);
    const user = {} as AuthenticatedUser;
    user.email = decodedToken.useremail;
    user.name = decodedToken.username;
    user.roles = decodedToken.roles;
    user.lsUserId = Number(decodedToken.sub);
    user.lsAccountId = Number(decodedToken.account_ref);
    user.accountIds = decodedToken.accounts || [];
    // Note: in lambdas, account_ref now comes through the context as ls_account_id
    // BUT, in the actual encoded JWT, it's still called 'account_ref'.

    if (user) {
      this.localStorageSvc.setToken(jwt);
      this.localStorageSvc.setAuthenticatedUser(user);
    }
    return user;
  }
}
