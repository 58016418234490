<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<h1>LegitScript Addiction Treatment Certification </h1>
<p>
  This agreement (“Agreement”) is entered into by LegitScript LLC (“LegitScript”) and applicant, which seeks to be certified as a drug or alcohol addiction treatment provider or advertiser (“Applicant”). Applicant understands and acknowledges that separate applications and payment may be required per unique website or facility, in LegitScript's sole discretion.
</p>
<p>
  By clicking “Agree,” Applicant understands and agrees to be bound by this Agreement, and any LegitScript policy such as <a href="https://www.legitscript.com/certification/addiction-treatment-certification/standards/" target="_blank">LegitScript’s Addiction Treatment Certification Standards</a> (“Standards”). Applicant understands and acknowledges that applying for LegitScript certification is a voluntary process. Applicant agrees to be bound by LegitScript’s interpretation of the Standards and, if certified, agrees to remain in compliance with the Standards and not pose a reputational risk to LegitScript, as determined by LegitScript in its sole discretion, for the duration of the certification period. Failure to provide payment or to abide by these terms and conditions will result in LegitScript declining to approve or revoking any previous approval of Applicant.
</p>
<h2>General Requirements</h2>
<ol class="roman">
  <li>
    <label>Compliance with Applicable Laws.</label>Applicant agrees to advertise, offer, and provide any drug or alcohol addiction treatment services in compliance with all applicable laws, rules, and regulations (“Applicable Laws”). Applicable Laws shall include the laws, rules, certifications, accreditations, requirements, and regulations of any jurisdiction where Applicant is domiciled, any jurisdiction where Applicant or its employees or agents maintains a facility, license, permit, any jurisdiction where the Applicant conducts or offers to do business, and where any existing or prospective patient or customer is located. Applicant further agrees to adhere to all applicable and generally accepted standards of addiction treatment, ethics, medicine, and any other relevant best practices in all jurisdictions where Applicant provides or advertises addiction treatment services. 
  </li>  
  <li>
    <label>Accuracy of Information</label>
    <ol class="alpha">
        <li>Applicant represents and warrants that all information provided to LegitScript by Applicant is and will be truthful, accurate, and not misleading. Failure to provide full and accurate answers, (inclusive of all past and present communication with LegitScript whether related to a certification application or otherwise) as determined in LegitScript’s sole discretion, may result in a permanent denial or revocation of application.</li>
        <li>Applicant represents and warrants that all information provided to the public on its website or websites is and will be truthful, accurate, and not misleading. </li>
        <li>Applicant will fully disclose affiliates, marketing relationships, including lead generation as defined by LegitScript, customer incentives, and all other requested information in the application. “Affiliates” is defined as any individual, business, or entity who previously, currently, or is expected to have a subsidiary, commercial, and/or professional relationship with the applicant organization or its principals. “Customer incentives” includes any item, services, or anything of value offered to a prospective customer as part of the marketing or sign-up process. The failure to immediately disclose all Affiliates may result in denial or revocation of the application. Applicant specifically understands and acknowledges that co-ownership, business relationships, or other affiliations with lead generation, as defined by LegitScript in its sole discretion, is grounds for certification revocation or denial.</li>
        <li>Applicant agrees to on-site inspections by LegitScript of any facility to ensure compliance with the Standards and will in no way hinder or inhibit such an inspection. Applicant understands that such inspections may be regularly scheduled or unannounced.</li>
        <li>LegitScript may, at its discretion, require Applicant’s employees to submit to a criminal background check conducted by LegitScript. Applicant agrees to make employees available for criminal background checks, and will in no way hinder or inhibit such investigation. Background checks conducted during the application process are conducted at LegitScript’s sole discretion.</li>
    </ol>
  </li>
  <li>
    <label>Intellectual Property Rights.</label> Applicant represents and warrants that none of its information or business practices infringe any copyright, patent, trade secret, trademark, or other intellectual property right.  Applicant agrees not to use any LegitScript intellectual property, including but not limited to LegitScript’s logo and Seal (as defined herein), for any purpose except as expressly permitted by this Agreement.
  </li>
  <li>
    <label>License.</label> Applicant represents, warrants, and agrees as follows:
    <ol class="alpha">
        <li>Applicant and its treatment providers hold all necessary and valid licenses, accreditation, or certifications from each relevant jurisdiction as required in order to comply with Applicable Laws. Applicant and its employees are currently compliant with Applicable Laws and in good standing to conduct business and will maintain and conduct addiction treatment and business in accordance with the legal and regulatory requirements of its in all relevant jurisdictions. For example, Applicant should be able to demonstrate compliance with the standards adopted in the state in which it intends to provide services, including any required state licensure, accreditation, or certification.</li>
        <li>Applicant agrees to notify LegitScript within thirty (30) days of becoming aware of any change in its or its treatment providers’ licensure, business, or regulatory status in any jurisdiction including whether these statuses are in good standing.</li>
        <li>Applicant agrees to notify LegitScript within thirty (30) days of becoming aware that it is the subject of any adverse or potentially adverse legal, government, or regulatory action related to its addiction treatment services, license(s), accreditation(s), certification(s), business or ethical practices, practice of medicine, or the dispensing of prescription drugs (“Disciplinary Action”). Disciplinary Action shall be interpreted broadly and includes, but is not limited to: legal action (whether criminal or civil), suspensions, probationary statuses, reprimands, warning letters, consent agreements, and any other similar regulatory communication from any oversight board or other regulatory agency or organization, regardless of jurisdiction.</li>
        <li>Applicant will, at all times, employ or contract with licensed, certified, or accredited addiction treatment professionals as required by Applicable Laws for the provision of its services. Applicant will permit regulated activities to be conducted only by those individuals licensed to do so, and then only to the extent permitted or required by Applicable Laws. </li>
    </ol>
  </li>
  <li>
    <label>Website Control.</label>
    <ol class="alpha">
        <li>Applicant agrees to provide a full listing, to LegitScript, of all domain names, URLs, or websites (“Website(s)”) under its ownership or control.</li>
        <li>Applicant agrees that all Websites under its ownership or control will abide by the standards of this Agreement.</li>
        <li>All transactions processed through any Website shall conform to Applicable Laws, and, as applicable, to any requirements imposed by any payment provider or card brand used or offered by Applicant, including but not limited to Visa, MasterCard, American Express, DiscoverCard, PayPal, or any acquiring bank or downstream payment provider.</li>
        <li>Applicant agrees that it will not link or otherwise refer Internet users to addiction treatment merchant or advertiser websites that are not approved by LegitScript.</li>
        <li>Applicant agrees to clearly display its privacy policy on a page that Internet users can easily view and access. Applicant agrees that its privacy policy will be compliant with relevant laws and regulations, including but not limited to the United States Health Insurance Portability and Accountability Act of 1996 (HIPAA), if applicable, and will only provide information to third parties as permitted by Applicable Laws.</li>
        <li>Applicant agrees that any information about transactions, customers, patients, medical information, and, as required by Applicable Law, any other sensitive or confidential information is processed using Secure Socket Layer (SSL) technology.</li>      
    </ol>
  </li>
  <li>
    <label>Domain Name Registration.</label>
    <ol class="alpha">
      <li>Applicant will ensure that the domain name registration (Whois) details for all domain names under applicant’s control are accurate and that the registration does not use any privacy service (also known as a proxy service). </li>
      <li>Applicant consents to LegitScript obtaining, updating and maintaining domain name registration information for any website under Applicant’s control, and that such constitutes a “legitimate purpose” for accessing such records under the EU General Data Protection Regulation or similar privacy laws. </li>
      <li>If required, applicant agrees to contact their domain name registrar to request that LegitScript be able to query the applicant’s Whois records via Port 43 or such other bulk, high-speed or automated process. </li>
    </ol>
  </li>
  <li>
    <label>License Agreement</label>
    <ol class="alpha">
      <li>Subject to the terms of this Agreement and only after receiving the express written permission of LegitScript upon certification, LegitScript grants Applicant a non-exclusive, limited license to display the LegitScript Seal of Approval (“Seal” or “Approval”) on Applicant’s home page. LegitScript will not be liable to Applicant for any damages resulting from the functioning, or malfunctions, of the Seal.</li>
      <li>LegitScript and Applicant agree that use of the Approval is subject to the conditions of this Agreement, Applicant’s successful completion of LegitScript’s certification process, and ongoing monitoring. Applicant understands that LegitScript will charge Applicant a nonrefundable application fee prior to beginning the certification process. Additionally, LegitScript will charge Applicant an annual fee for use of the Approval, as described in <a href="https://www.legitscript.com/certification/addiction-treatment-certification/process-pricing/" target="_blank">LegitScript’s Certification Pricing.</a> Applicant also agrees that LegitScript is authorized to automatically charge the annual fees (as applicable), as described in <a href="https://www.legitscript.com/certification/addiction-treatment-certification/process-pricing/" target="_blank">LegitScript’s Certification Pricing</a>, at the time of renewal using the payment method provided to LegitScript upon application. Applicant is responsible for keeping payment information up to date in order for payment to be processed timely and certification to continue. Applicant is responsible for canceling certification prior to the renewal date to avoid paying applicable annual fees.   <strong>Application and annual fees are not refundable under any circumstances.</strong> For avoidance of doubt, if LegitScript determines for any reason that Applicant is no longer fit to utilize the Approval or otherwise continue to be LegitScript certified, LegitScript may terminate this certification, Approval, or Agreement and collect, retain, or refund any fees in its sole discretion and without recourse.</li>
      <li>LegitScript may at its sole discretion modify its pricing terms.</li>
      <li>Applicant agrees that the Approval is the intellectual property of LegitScript and agrees not to register the Approval or any other LegitScript logo or symbol in any jurisdiction. Applicant agrees to use LegitScript’s symbol, logo, or the Seal only as expressly permitted by this Agreement. Applicant agrees that LegitScript is the sole and exclusive owner of the Approval and of any goodwill resulting from use of the Approval. However, Applicant is allowed to advertise itself as a “LegitScript-certified website.” Applicant agrees to conform to LegitScript’s media guidelines and to reasonably respond to LegitScript’s requests to correct or remove incorrect or violative information regarding Applicant’s certification status published by Applicant. Failure to respond to LegitScript’s requests will be grounds for certification revocation or denial. </li>
      <li>Use of the Approval constitutes an action indicating acceptance of the terms of this Agreement, and by use of the Approval, Applicant agrees to be bound by the Standards and any other LegitScript policy. Applicant may only use the Approval as long as Applicant remains certified by LegitScript, fulfills its payment obligations, responds to and acts on communications from LegitScript regarding ongoing compliance obligations, and conducts business in accordance with the terms set forth in this Agreement. Applicant further agrees not to use or display the Approval in any manner that is misleading, defamatory, infringing, libelous, disparaging, obscene, or otherwise objectionable to LegitScript or in a manner that impairs, or negatively impacts, any and all rights of LegitScript, including, but not limited to, rights in LegitScript’s trademarks or logos. </li>
      <li>Applicant agrees not to permit the use of the Approval on any website other than a website explicitly authorized by LegitScript.  </li>
      <li>Applicant agrees that display of the Approval does not imply sponsorship or endorsement by LegitScript of Applicant’s products or services. The Approval will not be used or displayed in a manner suggesting such sponsorship or endorsement. </li>
      <li>Applicant agrees not to modify the Approval in any form including its size, and to automatically refresh images of the Seal in a timely manner. For avoidance of doubt, use of the Seal in a static form is not permitted on the Applicant’s website(s).</li>
      <li>Applicant recognizes that the Seal is an HTML-based or JAVA-based, trademarked (by LegitScript) symbol that contains information unique to Applicant, such as the domain name or URL approved by LegitScript. When clicked or double-clicked, the Seal initiates a hyperlink that opens a page within LegitScript.com containing information about Applicant, including its certification status with LegitScript. </li>
      <li>Applicant agrees that the Seal will only hyperlink to a page within legitscript.com identified by LegitScript, and may not link to any other website.</li>
      <li>Applicant understands that LegitScript reserves the right to terminate or modify Applicant’s license to use the Approval at any time for any reason, and to take action against any use of the Approval that does not conform to the terms and conditions set forth in this Agreement, or infringes any LegitScript intellectual property right or interest.</li>
    </ol>
  </li>
  <li>
    <label>Application Process.</label>
    <ol class="alpha">
      <li>LegitScript agrees to make best efforts to review Applicant’s application in a timely manner. Applicant agrees that LegitScript may revoke or deny certification should applicant fail to timely respond to LegitScript’s requests. Applicant understands that the certification process is complex and that the time for a certification review is dependent upon a variety of factors, including but not limited to applicant’s business practices, facility number, offering services in multiple jurisdictions, and times of high application volumes. Applicant understands and agrees that Applicant’s compliance with the Standards is required for certification and that such compliance is in and at LegitScript’s sole discretion.</li>
      <li>Applicant understands that LegitScript may reject its certification application. If Applicant chooses to appeal the rejection, Applicant agrees that appeals will take additional time and that it will bear additional costs, if any, related to the continued process of certification. Applicant understands that LegitScript is under no obligation to accept an appeal or future applications from Applicants whose certification has been denied or revoked.</li>
      <li>Applicant understands that Applicant must pay a nonrefundable application fee in full prior to LegitScript beginning certification review of Applicant. </li>
      <li>Applicant agrees to provide information to LegitScript required for application processing listed in the application form. Failure to immediately provide full and accurate information as part of Applicant’s application will, in LegitScript’s sole discretion, result in revocation or denial of certification.</li>
      <li>
        <p>LegitScript will treat all application information as highly confidential and will not use application information for commercial benefit or marketing purposes. Application data is not shared with other applicants. If LegitScript reasonably believes that Applicant is engaged in activity that violates any law or regulation, LegitScript reserves the right to share that information with law enforcement, regulatory authorities, or refer to it publicly.</p>
        <p>Similarly, if LegitScript believes an applicant is engaged in activity that is illegal or fraudulent, LegitScript reserves the right to provide to Google or other partners sufficient information to explain the nature of the violation. </p>
        <p>Please note LegitScript is specifically not requesting any information Applicant is legally bound not to disclose. Furthermore, LegitScript is not asking for, and is instructing Applicant not to provide, any information that is subject to HIPAA privacy protections or is otherwise protected health information (PHI).</p>
      </li>
      <li>Applicant agrees that LegitScript is authorized to make inquiries about, research, collect, and store information from various sources about Applicant, its directors, employees, agents, and contractors. </li>
      <li>Applicants that have been subject to Disciplinary Action (regardless of jurisdiction), pose a reputational risk to LegitScript or have otherwise been deceptive, uncooperative, noncompliant or not transparent (all determined in LegitScript’s sole discretion), may be awarded probationary certification. Applicant understands that LegitScript will award probationary certification in its sole discretion and that resolution of Disciplinary Actions alone does not guarantee that probationary certification will be awarded. Applicant understands that LegitScript may require additional information from Applicant in order to ensure ongoing compliance with LegitScript certification requirements and that heightened and more frequent LegitScript review of Applicant may be required. Applicant understands that LegitScript may revoke probationary certification status at any time and for any reason. Applicant understands that probationary certification pricing will be determined on a case-by-case basis.</li>
      <li>Applicant understands that LegitScript may, in its sole discretion, grant, deny, or revoke Applicant’s certification application or certification status for any reason and at any time, including if LegitScript determines that the quality, accuracy, or other characteristics of the Applicant do not meet LegitScript’s certification standards, including but not limited to Applicant’s violations of BRAM, GBPP, or other card company policies. Applicant understands and acknowledges that continued advertising on Google, Facebook, or other advertising platform requiring LegitScript certification after an application has been submitted, is presumed to be willfully ignoring and attempting to circumvent the certification process and may be grounds for certification denial.</li>
      <li>Applicant understands that, from time to time, LegitScript may update these standards and these Terms and Conditions. Please check these Terms and Conditions periodically for updates.</li>
      <li>LegitScript expressly reserves the right to refuse to consider any application. </li>
    </ol>
  </li>
  <li>
    <label>General Use of LegitScript Materials.</label> Applicant agrees that the materials, content, methodology, and concepts of the process and in LegitScript’s online materials are owned by LegitScript and are protected intellectual property. Applicant agrees not to reproduce, modify, distribute, reverse engineer, or publicly display such materials. NOTE: LEGITSCRIPT, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND SUPPLIERS PROVIDE LEGITSCRIPT’S WEBSITE AND SERVICES "AS IS" AND “AS AVAILABLE” WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED, OR STATUTORY. LEGITSCRIPT, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND SUPPLIERS SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY AND NON-INFRINGEMENT. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LEGITSCRIPT SHALL CREATE A WARRANTY. APPLICANT AGREES THAT COMMUNICATIONS WITH AND INFORMATION RECEIVED FROM LEGITSCRIPT DO NOT CONSTITUTE LEGAL OR REGULATORY ADVICE. APPLICANT IS SOLEY RESPONSIBLE FOR COMPLYING WITH ALL APPLICABLE LAWS AND REGULATIONS 
    <p>LEGITSCRIPT IS NOT RESPONSIBLE IN CONNECTION WITH APPLICANT’S USE OF LEGITSCRIPT’S WEBSITE, SERVICES, OR THE APPROVAL.</p>   
  </li>
  <li>
   TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL LEGITSCRIPT, ITS OFFICERS, AGENTS, AND EMPLOYEES BE LIABLE TO APPLICANT, FOR ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, (INCLUDING ANY LOST OPPORTUNITIES FOR ADVERTISING, OR OTHERWISE, AND INCLUDING WITHOUT LIMITATION LOST PROFITS, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES) EVEN IF LEGITSCRIPT HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL LEGITSCRIPT’S TOTAL LIABILITY FOR ALL CLAIMS ARISING FROM OR RELATING TO THIS AGREEMENT, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE GREATER OF THE FEES PAID BY APPLICANT TO LEGITSCRIPT UNDER THIS AGREEMENT DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT WHICH GAVE RISE TO THE CLAIM FOR DAMAGES, OR $1,000. THE LIMITATIONS SET FORTH IN THIS SECTION SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ANY ESSENTIAL PURPOSE OF ANY LIMITED REMEDY SET FORTH IN THIS AGREEMENT.
  </li>
  <li>
    <label>Indemnification.</label> Applicant agrees to indemnify, defend, and hold LegitScript, and as applicable its officers, directors, agents, employees, affiliates, suppliers, and partners, harmless from and against any claims, demands, causes of action, damages, costs, liabilities, judgments, penalties, and expenses (including but not limited to, reasonable attorneys fees and court costs), collectively “Action(s),” arising from this Agreement or relating to Applicant’s use of LegitScript’s websites, the certification process, or relating to Applicant’s use of the Approval or other services provided by LegitScript (including, without limitation, any claims arising from any representations Applicant makes about the Approval) or Applicant’s breach of this Agreement or the documents it incorporates by reference, or Applicant’s violation of any law, regulation or rights of a third party, or any action taken by LegitScript as part of its review of Applicant’s certification application or investigation of a suspected violation of this Agreement (or as a result of its finding or decision that a violation of this Agreement has occurred). Applicant’s defense and indemnification obligations shall survive the expiration or termination of this Agreement and shall apply regardless of Applicant’s certification status.
    <p>Applicant agrees that it shall not sue or recover any damages from LegitScript, its directors, officers, employees, affiliates, agents, contractors, and licensors as a result of LegitScript’s decision to not certify, revoke certification, to suspend or terminate its relationship with Applicant, or that Applicant has breached or otherwise violated the Standards or this Agreement.</p>
    <p>This waiver and indemnity provision applies to all violations described in or contemplated by this Agreement. </p>
    <ol class="alpha">
      <li><label>Indemnification Procedure.</label> LegitScript shall promptly notify Applicant of any Action for which LegitScript believes it is entitled to be indemnified. LegitScript shall cooperate with Applicant at Applicant's sole cost and expense. Applicant shall immediately take control of the defense and investigation of such Action and shall employ counsel acceptable to LegitScript to handle and defend the same, at Applicant’s sole cost and expense. LegitScript may participate in and observe the proceedings at its own cost and expense with counsel of its own choosing. Applicant shall not enter into any monetary or non-monetary settlement or admit fault or liability on LegitScript’s behalf without the prior written consent of LegitScript.  </li>
    </ol>
  </li>
  <li>
    <label>Effective Date.</label> Effective Date. The effective date of this Agreement is the date on which Applicant affirmatively agrees to these Terms and Conditions by completing and submitting the application. The Agreement will terminate when either party provides notice to the other of termination, or upon conduct by Applicant that violates the terms of this Agreement, whichever is earlier.  </li>
  <li>
    <label>Governing Law; Jurisdiction; Attorney Fees.</label> This Agreement and its interpretation and enforcement will be governed by the laws of the State of Oregon, U.S.A., without regard to conflict of laws principles and without regard to any international treaties or conventions, including the Convention on the International Sale of Goods. LegitScript and Applicant consent and submit to the exclusive jurisdiction of any state or federal court located in Multnomah County, Oregon, with respect to any dispute arising out of this Agreement; provided, that either party may seek injunctive relief for, or to prevent breach of, this Agreement in any court of competent jurisdiction located in Multnomah County, Oregon, without having to post a bond or show harm. If any action is instituted to enforce or interpret the terms of this Agreement, the prevailing party in such action will be entitled to recover its reasonable attorney fees and costs incurred in such action, at trial or on appeal, from the losing party. Any claim or controversy arising out of, connected with, or related to the interpretation, alleged breach by LegitScript, including any certification revocation or denial, of this Agreement shall, at LegitScript’s sole option, be submitted to and resolved exclusively by the expedited arbitration of a single arbitrator from, and in accordance with the rules of, Arbitration Service of Portland.  </li>
  <li>
    <label>Miscellaneous.</label>
    <ol class="alpha">
      <li><label>Notices.</label> Any and all notices to LegitScript must be made in writing and either mailed by first class postage to LegitScript at 818 SW 3rd Ave. #353, Portland, Oregon 97204 or to such other address as LegitScript may hereinafter designate, or via electronic mail to legal@legitscript.com. Such notices will be deemed delivered when received.</li>
      <li><label>Relationship.</label> Applicant agrees that Applicant and LegitScript are independent parties and neither party is the legal representative, agent, joint venture, partner, or employee of the other party for any purpose whatsoever. Applicant acknowledges that applicant has carefully read this Agreement in its entirety and understands its terms, consequences, and binding effect. Applicant has had the opportunity to seek the advice of legal counsel, or has determined that such advice is not necessary, and is entering into this agreement voluntarily and knowingly.  </li>
      <li><label>Force Majeure.</label> LegitScript shall not be liable to Applicant for its failure to perform under this Agreement to the extent that any such failure results from any cause beyond LegitScript’s reasonable control, including without limitation, acts of God, strike or other labor dispute, electrical or power outage, pandemic or widespread disease, the acts or omissions of a third party, earthquake, or weather.</li>
      <li>This Agreement is binding upon and shall inure to the benefit of LegitScript and Applicant and our respective successors and assigns. The failure of either party to enforce any of the provisions of this Agreement shall not be construed as a waiver of that provision or the right of the party to enforce that provision or any other provision. In the event that any provision of this Agreement is found to be unenforceable or invalid the parties agree that that provision will be changed and interpreted so as to best accomplish the objectives of the provision within the limits of applicable law or applicable court decisions. This Agreement constitutes the entire agreement between Applicant and LegitScript and supersedes any prior agreements with respect to the same subject matter between Applicant and LegitScript. </li>
      <li><label>Electronic submittal.</label> Applicant acknowledges and agrees that electronic submission of Applicant’s application together with this Agreement constitutes a binding agreement on Applicant.</li>
    </ol>
  </li>
  <li>
    Consent Clause. Applicant grants explicit consent and permission to collect, store, process, and use all data supplied by the Applicant to LegitScript for business purposes 
    including but not limited to the certification, operation and maintenance of the Applicant's operations. This data will only be maintained and stored as long as is necessary 
    to maintain Applicant's certification and/or prevent fraud or abuse.
  </li> 
</ol>
