import { Component, Input } from '@angular/core';

@Component({
  selector: 'text-pill',
  templateUrl: './text-pill.component.html',
  styleUrls: ['./text-pill.component.scss'],
})
export class TextPillComponent {
  @Input() public value: string;
}
