<div class="widgets-container">
  <div class="widget">
    <div class="widget-header">
      <h1>Choose Lookup Type</h1>
    </div>
    <div class="widget-body centered">
      <div class="entry-container entry-container-full">
        <purchase-processing-notice 
          *ngIf="(entitlements$ | async)?.recentlyPurchased[productFamily]; else selectType"
          [productName]="'Data Lookup'"
        ></purchase-processing-notice>
        <ng-template #selectType>
          <div class="lookup-type-wrapper">
            <div [routerLink]="['/services/lookups/data/select/product']" routerLinkActive="selected">
              <i class="fa fa-search" aria-hidden="true"></i><br />
              Product
            </div>
            <div [routerLink]="['/services/lookups/data/select/website']" routerLinkActive="selected">
              <i class="fa fa-laptop" aria-hidden="true"></i><br />
              Website
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>