import { APIProductInfo } from '@ls/common-ts-models';
import { ActiveProductTypes, ActiveProductListItem } from './list-item';
import { ActiveProductsComponent } from '../active-products.component';
import { MenuItem } from 'primeng/api';

export class APIProductListItem implements ActiveProductListItem {
  public readonly productType: ActiveProductTypes = ActiveProductTypes.API;

  public readonly name: string;
  public readonly menuItems: MenuItem[] = [];
  public readonly expiration: Date;
  public readonly plan: string;
  public readonly usageString: string;

  constructor(private parent: ActiveProductsComponent, isOwner: boolean, productData: APIProductInfo) {
    this.name = productData.name;
    this.plan = productData.plan;
    this.expiration = productData.expiration;

    this.usageString = `${productData.remainingLookups} queries remaining of ${productData.quotaLimit}`;

    if (isOwner && productData.apiKeyRequired) {
      this.menuItems = [
        { label: 'Refresh API Key', command: () => parent.confirmRefreshAPIKey(productData.externalReferenceId) }
      ];
    }
  }
}
