import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { TestTransactionsRequestNewAction, fnColumnValues$ } from '../../reducers';
import { Store } from '@ngrx/store';
import { AppState, fnSelectableAccountState, fnTestTransactionsState } from 'src/app/reducers';
import { Observable, Subject, map, take, takeUntil } from 'rxjs';
import { CreateTransactionRequest } from '../../angular-client';
import { concatLatestFrom } from '@ngrx/effects';
import { LocalStorageService } from '@ls/common-ng-components';

@Component({
  selector: 'request-new-test-transaction',
  templateUrl: './request-new-test-transaction.component.html',
  styleUrls: ['./request-new-test-transaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestNewTestTransactionComponent implements OnInit, OnDestroy {
  categories$: Observable<string[]>;
  tags$: Observable<string[]>;
  persistentMonitoring$: Observable<string[]>;
  requesting$: Observable<number>;

  @ViewChild(FileUpload) fileUpload: FileUpload;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  private destroyed$ = new Subject<boolean>();

  public newTransactionForm = new FormGroup({
    urls: new FormControl('', [Validators.required, Validators.min(1)]),
    category: new FormControl('', Validators.required),
    recordTag: new FormControl(''),
    persistentMonitoring: new FormControl(''),
    comment: new FormControl(''),
  });
  files: File[];

  constructor(
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.categories$ = fnColumnValues$(this.store, 'category');
    this.tags$ = fnColumnValues$(this.store, 'tags');
    this.persistentMonitoring$ = fnColumnValues$(this.store, 'monitoringStatus');
    this.requesting$ = this.store.select(fnTestTransactionsState).pipe(
      takeUntil(this.destroyed$),
      map((state) => state.request.requesting),
    );
  }

  updateFiles($event: File[]) {
    this.files = $event;
  }

  public hideModal() {
    this.closeModal.emit(false);
  }

  save() {
    this.store
      .select((appState) => appState.testTransactions.uniqueValues.categoriesMeta)
      .pipe(
        take(1),
        concatLatestFrom(() => this.store.select(fnSelectableAccountState)),
      )
      .subscribe(([categories, accounts]) => {
        const category = categories.find((c) => c.name === this.newTransactionForm.value.category);
        const user = this.localStorageService.getAuthenticatedUser();
        const account = accounts.accounts.find((a) => a.id === user.lsAccountId);

        (this.newTransactionForm.value.urls ?? '').split('\n').forEach((url) => {
          this.store.dispatch(
            TestTransactionsRequestNewAction({
              transaction: {
                client: account?.name ?? `${user.lsAccountId}` ?? 'unknown',
                accountId: `${user.lsAccountId}`,
                websiteUrl: url,
                source: CreateTransactionRequest.SourceEnum.Request,
                analysisNote: this.emptyStringToUndefined(this.newTransactionForm.value.comment),
                categoryId: category.id,
                recordTags: [this.newTransactionForm.value.recordTag],
                monitoringStatus: this.emptyStringToUndefined(
                  this.newTransactionForm.value.persistentMonitoring,
                ) as CreateTransactionRequest.MonitoringStatusEnum,
                transactionStatus: 'Pending',
              },
              files: this.files,
            }),
          );
        });
      });

    console.log('current form values', this.newTransactionForm);
    console.log('current files', this.files);
    this.newTransactionForm.reset();
    this.closeModal.emit(false);
  }

  cancel() {
    this.newTransactionForm.reset();
    this.closeModal.emit(false);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  private emptyStringToUndefined(value: string): string | undefined {
    if (value === '') {
      return undefined;
    }
    return value;
  }
}
