import { Component, Input } from '@angular/core';

@Component({
  selector: 'ls-transaction-details-data',
  templateUrl: './transaction-details-data.component.html',
  styleUrls: ['./transaction-details-data.component.scss'],
})
export class TransactionDetailsDataComponent {
  @Input() title: string;
  @Input() data: string | string[] | number;
  @Input() useTag = false;

  public internalData(): string[] {
    return Array.isArray(this.data)
      ? this.data
      : Number.isFinite(this.data)
      ? [this.data.toString()]
      : [this.data as string];
  }
}
