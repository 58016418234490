<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="login-container">
    <login-header></login-header>

    <div class="entry-main">
        <div class="entry-container entry-container-small">
            <h1>Sign In</h1>
            <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                <div class="subtext mb30">Don't have a LegitScript account?
                    <a (click)='createAccount()'>Create One</a>
                </div>
                <div id="login-form">
                    <div class="validation-error-container" *ngIf="(authentication$ | async)?.errorText">
                        <p>There was a problem logging you in:</p>
                        <p><em>{{ (authentication$ | async)?.errorText }}</em></p>
                        <p>If you continue to experience trouble, please contact support</p>
                </div>
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                        <label>Email</label>
                        <input type="text" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" placeholder="you@example.com"
                        required checkWhitespace />
                        <div *ngIf="f.submitted && !email.valid" class="help-block">*Email is required</div>
                    </div>
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                        <label>Password</label>
                        <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required checkWhitespace />
                        <div *ngIf="f.submitted && !password.valid" class="help-block">*Password is required</div>
                    </div>
                    <div class="form-actions">
                        <button *ngIf="!(authentication$ | async)?.pending" class="btn btn-primary">Sign In</button>
                        <div class="loading-container" [hidden]="!(authentication$ | async)?.pending">
                            <p-progressSpinner [style]="{ width: '35px', height: '35px' }"></p-progressSpinner>
                        </div>
                        <br />
                        <a routerLink="/forgot-password">Forgot Password</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
