<div class="widgets-container">
  <div class="widget full-widget">
    <div class="widget-header widget-header-flex">
      <span class="no-icon">
        <h1>Upload history</h1>
      </span>
      <a routerLink="/services/merchant-monitoring/list">
        <i class="fa fa-undo"></i>
        <span>Back to list</span>
      </a>
    </div>
    <div class="widget-body centered upload-wrapper">
      <p-table class="prime-table"  [loading]="historyUploadState.pending"
        selectionMode="single"
        [value]="historyUploadState.uploads" [columns]="columns" [paginator]="true" [rows]="pageSize"
        [totalRecords]="pagination.totalRecords" (onLazyLoad)="loadHistoryUploadLazy"
        [lazyLoadOnInit]="false" [resizableColumns]="true"
        columnResizeMode="expand" [lazy]="true">
        <div class="merchant-header">
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pResizableColumn class="header-no-wrap" [title]="col.header"
                [style.width]="col.width">
                {{col.header}}
              </th>
            </tr>
          </ng-template>
        </div>
        <ng-template pTemplate="body" let-upload let-columns="columns" let-i="rowIndex">
          <tr class="upload-history-table">
            <td *ngFor="let col of columns" class="ui-resizable-column">
              <ng-container [ngSwitch]="col.field">
                <ng-container *ngSwitchCase="'actions'">
                    <button class="btn-transparent-bg" *ngIf="upload.upload_status==='Complete'" (click)="searchMerchants(upload.id)">
                      <i class="fa fa-search"></i>
                    </button>
                  <a target="_blank" *ngIf="upload.upload_status==='Complete'" [href]="upload.uploaded_file_url">
                    <i class="fa fa-download"></i>
                  </a>
                </ng-container>
                <ng-container *ngSwitchCase="'error_count'">
                  <a target="_blank" *ngIf="upload.error_count!=='0'" class="error-count"[href]="upload.error_file_url">
                    {{upload.error_count}}
                  </a>
                </ng-container>
                <ng-container *ngSwitchCase="'uploaded_date'">
                  {{ upload.uploaded_date | date:'short'}}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{upload[col.field]}}
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr class="upload-history-table">
            <td [attr.colspan]="columns.length" class="empty-message">
              <span>No Records Found</span>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>