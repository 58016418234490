import { NgModule } from '@angular/core';
import { TestTransactionsComponent } from './pages/test-transactions/test-transactions.component';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PaginatorModule } from 'primeng/paginator';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { APP_REDUCERS } from './reducers';
import { APP_EFFECTS } from './effects';
import { RouterModule } from '@angular/router';
import { TextPillComponent } from './components/text-pill/text-pill.component';
import { ApiModule, BASE_PATH, TransactionsService } from './angular-client';
import { environment } from 'src/environments/environment';
import { RequestNewTestTransactionComponent } from './components/request-new-test-transaction/request-new-test-transaction.component';
import { LegitscriptUiModule } from '@ls/common-ng-components';
import { ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { S3Service } from './services';
import { TestTransactionDetailsComponent } from './components/test-transaction-details/test-transaction-details.component';
import { TransactionDetailsDataComponent } from './components/test-transaction-details/transaction-details-data/transaction-details-data.component';
import { TestTransactionDocumentsComponent } from './components/test-transaction-details/test-transaction-documents/test-transaction-documents.component';
import { TestTransactionDetailsTabComponent } from './components/test-transaction-details/test-transaction-details/test-transaction-details-tab.component';
import { TabViewModule } from 'primeng/tabview';
import { LsSideBarComponent } from './components/ls-side-bar/ls-side-bar.component';
import { GalleriaModule } from 'primeng/galleria';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  providers: [
    TransactionsService,
    [
      {
        provide: BASE_PATH,
        useValue: environment.CONFIG.testTransactionHost,
      },
    ],
    S3Service,
  ],
  declarations: [
    TestTransactionsComponent,
    TextPillComponent,
    RequestNewTestTransactionComponent,
    TestTransactionsComponent,
    TestTransactionDetailsComponent,
    TransactionDetailsDataComponent,
    TestTransactionDocumentsComponent,
    TestTransactionDetailsTabComponent,
    LsSideBarComponent,
  ],
  imports: [
    ApiModule,
    CommonModule,
    TableModule,
    PaginatorModule,
    TagModule,
    EffectsModule.forFeature(APP_EFFECTS),
    StoreModule.forFeature('testTransactions', APP_REDUCERS),
    RouterModule.forRoot([]),
    LegitscriptUiModule,
    ReactiveFormsModule,
    FileUploadModule,
    TabViewModule,
    InputTextareaModule,
    GalleriaModule,
    TooltipModule,
    DialogModule,
  ],
})
export class TestTransactionsModule {}
