// Copied from https://github.com/anasAsh/ngx-scroll-event/blob/master/projects/ngx-scroll-event/src/lib/ngx-scroll-event.module.ts

import { NgModule } from '@angular/core';
import { NgxScrollEventDirective } from './ngx-scroll-event.directive';

@NgModule({
  declarations: [NgxScrollEventDirective],
  imports: [],
  exports: [NgxScrollEventDirective]
})
export class NgxScrollEventModule {}
