import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AppState } from '../../../reducers';
import { ProductLookupService } from '../../../services';
import { ProductInquiryComponent } from '../../inquiry/product-inquiry.component';
import { LsError } from '@ls/common-ts-models';

@Component({
  selector: 'product-lookup-detail',
  styleUrls: ['./product-lookup-detail.scss', '../../../styles/list-detail.scss'],
  templateUrl: './product-lookup-detail.html',
  providers: [ProductLookupService],
})
export class ProductLookupsDetailComponent implements OnInit, OnDestroy {
  public loading = false;
  public product: any;
  public product$: Subscription;
  public error: LsError | undefined;
  @ViewChild(ProductInquiryComponent) private productInquiryModal: ProductInquiryComponent;

  constructor(
    public store: Store<AppState>,
    public location: Location,
    public route: ActivatedRoute,
    public service: ProductLookupService,
  ) {}

  public ngOnInit() {
    const snapshot = this.route.snapshot;
    const productId = snapshot.params['productId'];

    this.product$ = this.getProduct(productId).subscribe(
      (product) => {
        this.product = product;

        if (this.product.classifications) {
          const objKeys = Object.keys(this.product.classifications);
          if (objKeys.length) {
            this.product.displayClassifications = this.objToArr('classifications', objKeys);
          }
        }

        if (this.product.advertising) {
          const objKeys = Object.keys(this.product.advertising);
          if (objKeys.length) {
            this.product.displayAdvertising = this.objToArr('advertising', objKeys);
          }
        }

        if (this.product.notes) {
          const objKeys = Object.keys(this.product.notes);
          if (objKeys.length) {
            this.product.displayNotes = this.objToArr('notes', objKeys);
          }
        }
        this.loading = false;
      },
      (err) => {
        this.error = LsError.Default;
        if (err.status === 429) {
          this.error = LsError.ProductLookupOverQuota;
        }
        this.loading = false;
      },
    );
  }

  public ngOnDestroy() {
    if (this.product$) {
      this.product$.unsubscribe();
    }
  }

  public getProduct(id) {
    this.loading = true;
    this.error = undefined;
    return this.service.get(id);
  }

  public backToList() {
    this.location.back();
  }

  public showInquiryModal() {
    this.productInquiryModal.showModal();
  }

  private objToArr(name: string, objKeys: string[]): any[] {
    const displayArr = [];
    objKeys.forEach((key) => {
      displayArr.push({
        country: key,
        [name]: this.product[name][key],
      });
    });

    return displayArr;
  }
}
