import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TransactionDetailsForMyLs200Response } from '../../angular-client';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ls-test-transaction-details',
  templateUrl: './test-transaction-details.component.html',
  styleUrls: ['./test-transaction-details.component.scss'],
})
export class TestTransactionDetailsComponent implements OnInit, OnDestroy {
  @Input() visible: boolean;
  @Input() loading: boolean;
  @Input() isDownloadLoading: boolean;
  @Input() isZipping: boolean;
  @Input() testTransaction: TransactionDetailsForMyLs200Response;
  @Input() images: { url: string; id: string; name: string }[];
  @Input() downloadAttachmentError: Observable<boolean>;
  @Output() closeSidebar: EventEmitter<boolean> = new EventEmitter();
  @Output() copyLink: EventEmitter<boolean> = new EventEmitter();
  @Output() requestScreenshots: EventEmitter<{ id: string; client: string; websiteUrl: string }> = new EventEmitter();
  @Output() downloadDocuments: EventEmitter<{
    files: { id: string; url?: string; name?: string; order?: number }[];
    type: 'screenshot' | 'attachment';
    zip?: boolean;
    folderName?: string;
  }> = new EventEmitter();
  @Output() showImages: EventEmitter<{ ids: string[] }> = new EventEmitter();

  public showImageNumber?: number;
  public showImageViewer = false;

  private destroyed$ = new Subject();

  ngOnInit(): void {
    if (this.downloadAttachmentError) {
      this.downloadAttachmentError.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.showImageNumber = null;
      });
    }
  }

  onShowImages($event: { ids: string[]; index: number }) {
    this.images = [];
    this.showImages.next($event);
    this.showImageNumber = $event.index;
    this.showImageViewer = true;
  }

  onDownloadScreenshot($event: { id: string; url?: string; name?: string }) {
    this.downloadDocuments.emit({ files: [$event], type: 'screenshot' });
  }

  onRequestScreenshots($event: { id: string; client: string; websiteUrl: string }) {
    const { id, client, websiteUrl } = $event;
    this.requestScreenshots.emit({ id, client, websiteUrl });
  }

  onDownloadDocuments($event: {
    documents: { id: string; order?: number }[];
    type: 'screenshot' | 'attachment';
    zip?: boolean;
    folderName?: string;
  }) {
    this.downloadDocuments.emit({
      files: $event.documents.map((document) => ({ id: document.id, order: document.order ?? null })),
      type: $event.type,
      zip: $event.zip ?? false,
      folderName: $event.folderName,
    });
  }

  updateCurrentImage($event: number) {
    this.showImageNumber = $event;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }
}
