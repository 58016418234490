import { Contentlabel } from '@ls/common-ts-models';
import { createAction, createReducer, on, props } from '@ngrx/store';

export const GET_CONTENT_LABELS = '[ContentLabels] Get';
export const GET_CONTENT_LABELS_SUCCESS = '[ContentLabels] Get Success';
export const CONTENT_LABELS_ERROR = '[ContentLabels] Content Labels Error';

export const GetContentLabelsAction = createAction(GET_CONTENT_LABELS);

export const GetContentLabelsSuccessAction = createAction(
  GET_CONTENT_LABELS_SUCCESS,
  props<{ contentLabels: Contentlabel[] }>(),
);

export const ContentLabelsErrorAction = createAction(CONTENT_LABELS_ERROR, props<{ err?: Error }>());

export interface ContentLabelsState {
  err?: Error;
  contentLabels: Contentlabel[];
  pending: boolean;
}

export const initialContentLabelState: ContentLabelsState = {
  err: null,
  contentLabels: null,
  pending: false,
};

export const ContentLabelsReducer = createReducer(
  initialContentLabelState,

  on(GetContentLabelsAction, (state) => ({
    ...state,
    pending: true,
  })),

  on(GetContentLabelsSuccessAction, (state, { contentLabels }) => ({
    ...state,
    err: null,
    contentLabels,
    pending: false,
  })),

  on(ContentLabelsErrorAction, (state, { err }) => ({
    ...state,
    err,
    pending: false,
  })),
);
