import { MyLSIoTConfig, MyLSPushNotificationMessage } from '@ls/common-ts-models';
import { createAction, createReducer, createSelector, on, props } from '@ngrx/store';
import { AppState } from './app.state';

// CONSTANTS

export enum PushNotificationActionTypes {
  Authenticate = '[PushNotification] Authenticate',
  AuthenticateSuccess = '[PushNotification] Authenticate Success',
  AuthenticateError = '[PushNotification] Authenticate Error',
  AppendMessage = '[PushNotification] Append Message',
  RemoveMessage = '[PushNotification] Remove Message',
  SetMessages = '[PushNotification] Set Messages',
  ClearMessages = '[PushNotification] Clear Messages',
  UpdateLocalStorage = '[PushNotification] Update Messages in local storage',
}

// ACTIONS
export const PushNotificationUpdateLocalStorageAction = createAction(PushNotificationActionTypes.UpdateLocalStorage);

export const PushNotificationAuthenticateAction = createAction(PushNotificationActionTypes.Authenticate);

export const PushNotificationAuthenticateSuccessAction = createAction(
  PushNotificationActionTypes.AuthenticateSuccess,
  props<{ config: MyLSIoTConfig }>(),
);

export const PushNotificationAuthenticateErrorAction = createAction(
  PushNotificationActionTypes.AuthenticateError,
  props<{ errorMessage: Error }>(),
);

export const PushNotificationAppendMessageAction = createAction(
  PushNotificationActionTypes.AppendMessage,
  props<{ notification: MyLSPushNotificationMessage }>(),
);

export const PushNotificationRemoveJobMessagesAction = createAction(
  PushNotificationActionTypes.RemoveMessage,
  props<{ jobId: string }>(),
);

export const PushNotificationSetMessagesAction = createAction(
  PushNotificationActionTypes.SetMessages,
  props<{ notifications: MyLSPushNotificationMessage[] }>(),
);

export const PushNotificationClearMessagesAction = createAction(PushNotificationActionTypes.ClearMessages);

// STATE
export interface PushNotificationState {
  err?: Error;
  config: MyLSIoTConfig;
  notifications: MyLSPushNotificationMessage[];
}

export const initialPushNotificationState: PushNotificationState = {
  config: null,
  notifications: [],
};

// REDUCER
export const PushNotificationReducer = createReducer(
  initialPushNotificationState,

  on(PushNotificationSetMessagesAction, (state, { notifications }) => ({
    ...state,
    notifications,
  })),

  on(PushNotificationAppendMessageAction, (state, { notification }) => ({
    ...state,
    notifications: [notification, ...state.notifications], // Push new messages to front of the array to be read first!
  })),

  on(PushNotificationRemoveJobMessagesAction, (state, { jobId }) => ({
    ...state,
    notifications: state.notifications.filter((x) => x.jobId !== jobId), // replace with updated type
  })),

  on(PushNotificationUpdateLocalStorageAction, (state) => ({
    ...state,
    notifications: [...state.notifications],
  })),

  on(PushNotificationAuthenticateAction, (state) => ({ ...state })),

  on(PushNotificationAuthenticateSuccessAction, (state, { config }) => ({
    ...state,
    config,
  })),

  on(PushNotificationAuthenticateErrorAction, (state, { errorMessage }) => ({
    ...state,
    err: errorMessage,
  })),
);

// STATE SELECTORS
export const fnPNState = (state: AppState) => state.pushNotifications;

export const fnPNConfig = createSelector(fnPNState, (state) => state.config);
export const fnPNNotifications = createSelector(fnPNState, (state) => state.notifications);
