import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit, OnDestroy {
  public model = { email: '', confirmEmail: '' };
  public MAX_EMAIL_LENGTH = 80;
  public confirmEmailForm: FormGroup;
  @Output() public emailSet: EventEmitter<string> = new EventEmitter<string>();
  @Input() public parentForm: FormGroup;
  @Input() public initialValue: string;
  @Input() public type: string;

  private confirmEmailSubscription$: Subscription;

  public ngOnInit() {
    this.initialValue = this.initialValue || '';

    this.confirmEmailForm = new FormGroup({
      email: new FormControl(this.initialValue, {
        validators: [Validators.required, Validators.maxLength(this.MAX_EMAIL_LENGTH), Validators.email],
      }),
      confirmEmail: new FormControl(this.initialValue, {
        validators: [Validators.required, Validators.maxLength(this.MAX_EMAIL_LENGTH), Validators.email],
      }),
    });

    if (this.parentForm) {
      this.parentForm.setControl('confirmEmail', this.confirmEmailForm);
    }

    this.confirmEmailSubscription$ = this.confirmEmailForm.valueChanges.subscribe((valueChanges) => {
      if (valueChanges.email !== valueChanges.confirmEmail) {
        this.confirmEmailForm.controls['confirmEmail'].setErrors({ 'match-fail': 'email' });
        this.emailSet.emit(null);
      } else {
        this.confirmEmailForm.controls['confirmEmail'].setErrors(null);
        this.emailSet.emit(valueChanges.email);
      }
    });
  }

  public ngOnDestroy() {
    if (this.confirmEmailSubscription$) {
      this.confirmEmailSubscription$.unsubscribe();
    }
  }
}
