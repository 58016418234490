<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<pending-lookup *ngIf="oppCreated; else showForm"></pending-lookup>
<ng-template #showForm>
  <section class="form">
    <form class="form" (ngSubmit)="onSubmit()" id="enroll-contact-page-form" [formGroup]="formGroup">
      <section class="form-container form-group mb20">        
        <p>    
          <label>
            LEGAL BUSINESS NAME *
            <span *ngIf="!legalName.valid && !firstTry" class="error">Required</span>
          </label>
          <input type="text" class="form-control" name="legalName" [formControl]="legalName" [class.errorBorder]="legalName.invalid && (legalName.dirty || !firstTry)" required/>
        </p>
        <p>
          <label>
            DOING BUSINESS AS *
            <span *ngIf="!dba.valid && !firstTry" class="error">Required</span>
          </label>
          <input type="text" class="form-control" name="dba" [formControl]="dba" [class.errorBorder]="dba.invalid && (dba.dirty || !firstTry)" required/>
        </p>
        <p class="span2">
          <label>
            PRIMARY WEBSITE URL *
            <span *ngIf="!primaryUrl.valid && !firstTry" class="error">Required</span>
          </label>
          <input type="url" class="form-control" name="primaryUrl" [formControl]="primaryUrl" [class.errorBorder]="primaryUrl.invalid && (primaryUrl.dirty || !firstTry)" required/>
        </p>
        <p class="span2">
          <label>
            Please describe how you will be using the product lookup service *
            <span *ngIf="!description.valid && !firstTry" class="error">Required</span>
          </label>
          <textarea rows="5" class="form-control" name="description" [formControl]="description" [class.errorBorder]="description.invalid && (description.dirty || !firstTry)" required></textarea>
        </p>
        <div class="button-wrapper">
          <button type="submit" class="btn btn-primary" [disabled]="(firstTry ? false : (submitting || formGroup.invalid))">Submit</button>
          <span *ngIf="submitting">
            <p-progressSpinner [style]="{ width: '2em', height: '2em' }"></p-progressSpinner>
          </span>
        </div>
      </section>
    </form>
  </section>
</ng-template>