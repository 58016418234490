import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { MerchantDetailGetAction, MerchantDetailGetErrorAction, MerchantDetailGetSuccessAction } from '../reducers';
import { MMMerchantService } from '../services';

@Injectable()
export class MerchantDetailEffect {
  public get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantDetailGetAction),
      switchMap((action) =>
        this.merchantService.getMerchant(action.correlationId).pipe(
          map((merchant) => {
            // Hack: For some reason we are seeing arrays being saved as strings in the db. We need to clarify the rules around
            // what can and can't go in the ISO field in the DB and those rules need to be enforced at the API level. i.e. if
            // we expect multiple values, the db and model should reflect that with an array of strings instead of this. In the
            // meantime, this attempts to be as specific as possible about detecting the formatting used to define the array string,
            // and process it accurately.

            // Can't blanket split on commas, because of names like 'Lindgren, Morissette and Heaney'
            // Need to replace wrapping single quotes with double quotes so JSON parse will work
            // Can't blanket replace single quotes because we can't assume names won't include apostrophes
            if (merchant.iso && merchant.iso[0] === '[') {
              const replaceQuotesBorderingCommasWithSpace = merchant.iso.replaceAll(/', '/g, '", "');
              // Checking for comma delimiter without space just because I'm paranoid
              const replaceQuotesBorderingCommasWithoutSpace = replaceQuotesBorderingCommasWithSpace.replaceAll(
                /','/g,
                '", "',
              );
              const replaceQuoteAfterLeftBracket = replaceQuotesBorderingCommasWithoutSpace.replace("['", '["');
              const cleanedArrayString = replaceQuoteAfterLeftBracket.replace("']", '"]');

              const parsedIso = JSON.parse(cleanedArrayString);

              return MerchantDetailGetSuccessAction({
                merchant: {
                  ...merchant,
                  iso: parsedIso.join(', '),
                },
              });
            }
            return MerchantDetailGetSuccessAction({ merchant });
          }),
          catchError((error: Error) => of(MerchantDetailGetErrorAction({ err: error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private merchantService: MMMerchantService,
  ) {}
}
