<div class="security-widget widget" *ngIf="!((account$ | async)?.pending)">
  <div class="widget-header">
    <i class="fa fa-lock"></i><h1>Security</h1>
  </div>
  <div *ngIf="isAuth0" class="security-settings-container">
    <p>To change your password, please use the <em>Forgot Password</em> link available on the login page.</p>
    <p>Click the logout button below to return to the login page.</p>
    <button (click)="signOutClick()">Logout</button>
  </div>
  <div *ngIf="!isAuth0" class="security-settings-container widget-body centered">
    <h2>Change Password</h2>
    <form (ngSubmit)="submit()" #form="ngForm" novalidate *ngIf="!changedSuccessfully; else success">
      <div class="security-form-container form-container form-container-public"> 
        <div class="form-group">
          <div class="label-validator">
            <label for="password" class="left">Current Password</label>
            <field-validator class="right" [control]="password"></field-validator>
          </div>
          <input #password="ngModel" name="password" type="password" [(ngModel)]="model.password" placeholder="Password" required/>
        </div>
        <div class="form-group">
          <div class="label-validator">
            <label for="password" class="left">New Password</label>
            <field-validator class="right" [control]="newPassword"></field-validator>
          </div>
          <input #newPassword="ngModel" name="newPassword" type="password" [(ngModel)]="model.newPassword" placeholder="New Password" required minlength="10" maxlength="255" (keyup)="form.controls['confirmPassword'].updateValueAndValidity();" />
        </div>
        <div class="form-group">
          <div class="label-validator">
            <label for="confirmPassword" class="left">Confirm New Password</label>
            <field-validator class="right" [control]="confirmPassword"></field-validator>
          </div>
          <input #confirmPassword="ngModel" name="confirmPassword" type="password" [(ngModel)]="model.passwordConfirm" placeholder="Confirm Password" placeholder="Confirm Password" field-match="newPassword" required />
        </div>
        <div style="text-align: center">
          <button class="btn btn-primary" [disabled]="loading || !form.valid">Save</button>
        </div>
      </div>
    </form>
    <ng-template #success>
      <i class="fa fa-check-circle success"></i> Update succeeded.
    </ng-template>
  </div>
</div>