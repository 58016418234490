import { Injectable } from '@angular/core';
import { LocalStorageService } from '@ls/common-ng-components';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap, map, tap } from 'rxjs/operators';
import {
  DirtyMerchantState,
  GetDirtyMerchantsSuccessAction,
  MerchantDirtiedAction,
  MerchantListSuccessAction,
  MerchantsCleanedAction,
  MMAppState,
  SetDirtyMerchantsAction,
  GetDirtyMerchantsAction,
} from '../reducers';

const DIRTY_MERCHANT_CACHE_KEY = 'dirtyMerchants';
const FIVE_HOURS_IN_MS = 18_000_000; // 5 * 60 * 60 * 1000

@Injectable()
export class DirtyMerchantsCacheEffect {
  public get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetDirtyMerchantsAction),
      concatMap(() => {
        const dirtyMerchants = this.localStorageService.get(DIRTY_MERCHANT_CACHE_KEY, true) || [];

        const nextActions: Action[] = [GetDirtyMerchantsSuccessAction({ merchants: dirtyMerchants })];

        // it's possible that some merchants were marked dirty more than 3 hours ago, queue up an action to clear them
        const staleDirtyMerchantIds = dirtyMerchants
          .filter((merchant: { updateRequestTime: any }) => Date.now() - merchant.updateRequestTime > FIVE_HOURS_IN_MS)
          .map((merchant: { correlationId: any }) => merchant.correlationId);

        if (staleDirtyMerchantIds.length > 0) {
          nextActions.push(MerchantsCleanedAction({ correlationIds: staleDirtyMerchantIds }));
        }

        return nextActions;
      }),
    ),
  );

  public cache$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MerchantDirtiedAction),
        tap((action) => {
          const dirtyMerchant: DirtyMerchantState = {
            correlationId: action.correlationId,
            previousUpdateDate: action.previousUpdateDate,
            updateRequestTime: action.updateRequestTime,
          };

          const dirtyMerchantsInCache = this.localStorageService.get(DIRTY_MERCHANT_CACHE_KEY, true) || [];

          // remove the original version of the dirty merchant if it exists
          const merchantsToCache = dirtyMerchantsInCache.filter(
            (merchant: { correlationId: string }) => merchant.correlationId !== dirtyMerchant.correlationId,
          );
          this.localStorageService.set(DIRTY_MERCHANT_CACHE_KEY, [...merchantsToCache, dirtyMerchant]);

          // remove the merchant from the cache in 3 hours, if the user stays on the page that long
          setTimeout(() => {
            this.store.dispatch(MerchantsCleanedAction({ correlationIds: [dirtyMerchant.correlationId] }));
          }, FIVE_HOURS_IN_MS);
        }),
      ),
    { dispatch: false },
  );

  public markMerchants$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SetDirtyMerchantsAction),
        tap((action) => {
          const merchantsToMark: DirtyMerchantState[] = action.merchantsToMark.map((merchant) => ({
            correlationId: merchant.correlation_id,
            previousUpdateDate: merchant.update_date,
            updateRequestTime: action.updateRequestTime,
          }));

          const dirtyMerchantsInCache = this.localStorageService.get(DIRTY_MERCHANT_CACHE_KEY, true) || [];
          // remove the original version of the dirty merchant if it exists
          const merchantsToCache = [...dirtyMerchantsInCache, ...merchantsToMark];
          this.localStorageService.set(DIRTY_MERCHANT_CACHE_KEY, [...merchantsToCache]);

          // remove the merchant from the cache in 3 hours, if the user stays on the page that long
          setTimeout(() => {
            this.store.dispatch(
              MerchantsCleanedAction({ correlationIds: merchantsToMark.map((merchant) => merchant.correlationId) }),
            );
          }, FIVE_HOURS_IN_MS);
        }),
      ),
    { dispatch: false },
  );

  public removeFromCache$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MerchantsCleanedAction),
        tap((action) => {
          const dirtyMerchantsInCache = this.localStorageService.get(DIRTY_MERCHANT_CACHE_KEY, true) || [];

          const stillDirtyMerchants = dirtyMerchantsInCache.filter((merchant: { correlationId: string }) => {
            const isMerchantClean = !!action.correlationIds.find(
              (correlationId) => correlationId === merchant.correlationId,
            );

            return !isMerchantClean;
          });

          this.localStorageService.set(DIRTY_MERCHANT_CACHE_KEY, stillDirtyMerchants);
        }),
      ),
    { dispatch: false },
  );

  public handleListMerchantsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantListSuccessAction),
      map((action) => {
        const dirtyMerchantsInCache = this.localStorageService.get(DIRTY_MERCHANT_CACHE_KEY, true) || [];

        const cleanedMerchants = dirtyMerchantsInCache.filter(
          (dirtyMerchant: { correlationId: any; previousUpdateDate: string }) => {
            const actionMerchant = action.merchants.find(
              (actionMerchant) => actionMerchant.correlation_id === dirtyMerchant.correlationId,
            );
            if (actionMerchant) {
              const isMerchantUpdateDateNewer =
                Date.parse(actionMerchant.update_date) > Date.parse(dirtyMerchant.previousUpdateDate);

              const isCustomerActionNewer =
                Date.parse(actionMerchant.your_action_date as any) > Date.parse(dirtyMerchant.previousUpdateDate); // your_action_date is actually a string due to http serialization

              return isMerchantUpdateDateNewer || isCustomerActionNewer;
            }

            // if the merchant wasn't returned in the list call, we can safely assume it was on a seperate page of merchants and should be persisted
            return actionMerchant;
          },
        );

        return MerchantsCleanedAction({
          correlationIds: cleanedMerchants.map((m: { correlationId: any }) => m.correlationId),
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private store: Store<MMAppState>,
  ) {}
}
