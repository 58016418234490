import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthenticatedUser, User } from '@ls/common-ts-models';
import { AppState } from '../../../reducers';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { UsersService } from '../../../services/users.service';
import { LocalStorageService } from '@ls/common-ng-components';

@Component({
  selector: 'update-info',
  templateUrl: 'update-info.component.html',
  styleUrls: ['../invited-user-login.component.scss'],
})
export class UpdateInfoComponent implements OnInit, OnDestroy {
  public loading = true;
  public saving = false;
  public model: User = {} as User;
  public currentUser: AuthenticatedUser;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public usersService: UsersService,
    private store: Store<AppState>,
    private localStorage: LocalStorageService,
  ) {}

  public ngOnInit() {
    this.currentUser = this.localStorage.getAuthenticatedUser();
    this.getUserInfo();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getUserInfo() {
    this.usersService
      .getSFContactInfo(String(this.currentUser.lsUserId))
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe(
        (response) => {
          this.model = response;
          this.loading = false;
        },
        () => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Error loading info',
              detail: 'There was an error loading your contact info.  Please try again.',
              sticky: false,
              blocking: false,
            }),
          );
          this.loading = false;
        },
      );
  }

  public submit(formData: any) {
    this.saving = true;
    // need the lsUserId on the data object to update properly
    formData.lsUserId = this.currentUser.lsUserId;
    formData.lsAccountId = this.currentUser.lsAccountId;
    this.usersService
      .upsertUser(formData, 'update')
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe(
        () => {
          this.router.navigate(['/loading']);
        },
        () => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Error saving info',
              detail: 'There was an error saving your contact info.  Please try again.',
              sticky: false,
              blocking: false,
            }),
          );
          this.saving = false;
        },
      );
  }
}
