import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'overflow-button',
  templateUrl: './overflow-button.component.html',
  styleUrls: ['./overflow-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OverflowButtonComponent {
  @Input() public overflowNumber: number;
  @Output() buttonClick: EventEmitter<MouseEvent> = new EventEmitter();
}
