import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { QuestionnaireState, LoadCompletedQuestionnaireAction } from '../reducers';
import { environment } from 'src/environments/environment';
import { AppState, fnQuestionnaireState } from 'src/app/reducers';

@Component({
  selector: 'cert-submitted',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './certification-submitted.component.html',
  styleUrls: ['../certification-questionnaire-wrapper/certification-questionnaire-wrapper.component.scss'],
})
export class CertificationSubmittedComponent implements OnInit, OnDestroy {
  public questionnaire$: Observable<QuestionnaireState>;
  public destroyed$: Subject<boolean> = new Subject();
  public questions;
  public answers;

  public certificationEmail = String(environment.CONFIG.certificationEmail);

  constructor(
    public store: Store<AppState>,
    public route: ActivatedRoute,
  ) {}

  public ngOnInit() {
    const certificationId: string = this.route.snapshot.params['certificationId'];
    this.store.dispatch(LoadCompletedQuestionnaireAction({ certificationId }));

    this.questionnaire$ = this.store.select(fnQuestionnaireState).pipe(
      takeUntil(this.destroyed$.asObservable()),
      tap((questionnaireItem) => {
        if (questionnaireItem.questionnaire.questions && questionnaireItem.answers.answeredQuestions) {
          this.questions = JSON.parse(JSON.stringify(questionnaireItem.questionnaire.questions));
          this.answers = JSON.parse(JSON.stringify(questionnaireItem.answers.answeredQuestions));
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
