import { Component } from '@angular/core';

import { ValidationResult } from '../../SharedCustomTypes/validation-result';
import { EditableFieldComponent } from '../abstract/editable-field.component';

@Component({
  selector: 'editable-field-text',
  templateUrl: '../abstract/editable-field.component.html',
  styleUrls: ['../abstract/editable-field.component.scss']
})
export class EditableFieldTextComponent extends EditableFieldComponent {
  public validate(): ValidationResult[] {
    const validationResults = [];

    validationResults.push(EditableFieldComponent.IsFilledOut(this.editableField, this.required));
    validationResults.push(EditableFieldComponent.IsWithinFieldLength(this.editableField, this.maxLength));
    return validationResults;
  }
}
