import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import { MMMerchantService } from '../../merchant-monitoring/services';
import { GetIcasAction, GetIcasSuccessAction, IcasErrorAction } from '../reducers/icas.reducer';

@Injectable()
export class IcasEffect {
  public list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetIcasAction),
      switchMap((action) =>
        this.merchantsService.listMerchantICAs().pipe(
          map((res: string[]) =>
            GetIcasSuccessAction({
              icas: res,
            }),
          ),
          catchError((err) => of(IcasErrorAction({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private merchantsService: MMMerchantService,
  ) {}
}
