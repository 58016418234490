import { createAction, createReducer, on, props } from '@ngrx/store';
import { Certification } from '@ls/common-ts-models';
//
// CONSTANTS
//
export enum CertificationActionTypes {
  CERTIFICATIONS_GET = '[Certifications] Get',
  CERTIFICATIONS_GET_SUCCESS = '[Certifications] Get Success',
  CERTIFICATIONS_GET_ERROR = '[Certifications] Get Error',
}

//
// ACTIONS
//
export const CertificationsGetAction = createAction(CertificationActionTypes.CERTIFICATIONS_GET);

export const CertificationsGetSuccessAction = createAction(
  CertificationActionTypes.CERTIFICATIONS_GET_SUCCESS,
  props<{ list: Certification[] }>(),
);

export const CertificationsGetErrorAction = createAction(
  CertificationActionTypes.CERTIFICATIONS_GET_ERROR,
  props<{ errorText: string }>(),
);

//
// STATE
//
export interface CertificationsState {
  list: Certification[];
  pending: boolean;
  errorText: string;
}

export const initialCertificationsState = {
  list: [],
  pending: false,
  errorText: undefined,
};

//
// REDUCER
//
export const CertificationsReducer = createReducer(
  initialCertificationsState,

  on(CertificationsGetAction, (state) => ({
    ...state,
    pending: true,
    errorText: undefined,
  })),

  on(CertificationsGetSuccessAction, (state, { list }) => ({
    ...state,
    list,
    pending: false,
    errorText: undefined,
  })),

  on(CertificationsGetErrorAction, (state, { errorText }) => ({
    ...state,
    pending: false,
    errorText,
  })),
);
