<div class="login-container" id="account-created">
    <div class="entry-main">
        <div class="entry-container entry-container-small">
            <i class="fa fa-check-circle success"></i><br>
            <br/>
            <h1 class="mb40">Check Your Email</h1>
            <div class="subtext mb40">
                Thank you for submitting a request to create an account!<br />
                <br />
                If no account is registered under that name or email, you will receive a link to confirm your account. Once confirmed you will be able to log in.
            </div>
        </div>
    </div>
</div>
