<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="cert-questionnaire-wrapper">
    <div class="cert-questionnaire-container cert-questionnaire-container-complete" libScrollEvent (onscroll)="onWrapperScroll($event)" [topOffset]="250">
        <div class="questionnaire-complete">
            <div *ngIf="showLegacyRenewalText; else showECRenewalInstructions" style="margin-bottom: 5em">
                <h1>Review your previous application questionnaire</h1>
                <div>
                    You may review your answers below.  When you are ready <a (click)="submit()">Submit Your Application</a>.
                </div>
            </div>
            <ng-template #showECRenewalInstructions>
                <div>
                    <h1>Review your previous application questionnaire</h1>
                    <div>
                        Your certification renewal will take place in the new <a [href]=experienceCloudUrl target="_blank" style="text-decoration: underline;">MyLegitScript Certification portal</a>. You may find it helpful to view your previous responses here in order to facilitate your renewal process. After this renewal year, your previous year's renewal questionnaire responses will be available in the new MyLegitScript Certification portal.
                        <ol>
                            <li>Please note that given the enhancement to this year’s renewal questionnaire, the questions may not be a 1:1 match in number and order to what you see here. Ctrl+F or Cmd+F can help you find the responses you are looking for.</li>
                            <li>Review your answers to existing questions to ensure all information you provided is up to date.</li>
                        </ol>
                    </div>
                    <button class="btn-no-bg-bordered" (click)="navigateToLegacyPortal()">Go back to Legacy MyLegitScript Portal</button>
                    <button (click)="navigateToExperienceCloud()">Go to MyLegitScript Certification Portal</button>
                </div>
            </ng-template>
        </div>
        <loading-spinner *ngIf="(questionnaire$ | async).pending; else complete" loadingText="{{loadingText}}"></loading-spinner>
        <ng-template #complete>
            <div *ngIf="questions?.length && answers?.length">
                <questionnaire-review [questions]="questions" [answers]="answers" [certificationId]="certificationId" [enableEdit]="showLegacyRenewalText" (handleEditAnswer)="editAnswer($event)"></questionnaire-review>
            </div>
        </ng-template>
        <button *ngIf="showLegacyRenewalText" class="{{showBottomBtn ? 'slide-up' : ''}} fixed-bottom" (click)="submit()">Submit Your Application</button>
    </div>
</div>

<legitscript-modal [draggable]=false [closable]=false [focusOnShow]=false [resizable]=false [responsive]=true [modal]=true autoAlign=true [width]=800>
    <div class="dialog-body">
        <div>
            <h1>Confirm Review</h1>
            <div>
                I have reviewed my answers and updated any information that may have changed since I last submitted a certification questionnaire.
            </div>
        </div>
    </div>
    <div class="dialog-footer" style="display:flex; justify-content: flex-end; align-items: center;">
        <button (click)="confirmReview()">Confirm + Submit</button>
        <button (click)="modal.hideDialog()" class="btn-no-bg">Return to Review</button>
    </div>
</legitscript-modal>

<cert-renewal-instructions [showLegacyText]="showLegacyRenewalText"></cert-renewal-instructions>