import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { GenericNotificationAction, SeverityOptions, LegitScriptModalComponent } from '@ls/common-ng-components';
import { FormGroup } from '@angular/forms';
import { empty as observableEmpty } from 'rxjs';
import { CustomerAction, CustomerActionRequest, CustomerActionsDisplay, MyLsFilterGroup } from '@ls/common-ts-models';
import { MMMerchantService } from '../services';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { MerchantListCheckboxClearAction, SetDirtyMerchantsAction } from '../reducers';

@Component({
  selector: 'confirm-bulk-action',
  styleUrls: ['./confirm-bulk-action.component.scss'],
  templateUrl: './confirm-bulk-action.component.html',
})
export class MMConfirmBulkActionComponent implements OnInit {
  @Input() public merchantsToProcess: any;
  @Input() public filters: MyLsFilterGroup;
  @Input() public action: CustomerAction;
  @Input() public merchantNumber: number;
  @Input() public dropdown: any;

  public form: FormGroup;
  public processing = false;
  public comment: string;

  @ViewChild(LegitScriptModalComponent) private bulkActionModal: LegitScriptModalComponent;
  constructor(
    private merchantService: MMMerchantService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit() {
    this.comment = '';
    this.form = new FormGroup({});
  }

  public getActionText(): string {
    for (const customerActionDisplay of CustomerActionsDisplay) {
      if (customerActionDisplay.value === this.action) {
        return customerActionDisplay.text;
      }
    }

    return null;
  }

  public showModal() {
    this.bulkActionModal.showDialog();
  }

  public hideModal() {
    this.bulkActionModal.hideDialog();
  }

  public submit() {
    const request: CustomerActionRequest = {
      action: this.action,
      comment: this.comment,
    };

    if (this.comment === '') {
      return;
    }

    this.processing = true;
    const correlationIds = this.merchantsToProcess.map((merchant: { correlation_id: any }) => merchant.correlation_id);
    this.merchantService
      .updateBulkCustomerAction(this.filters, correlationIds, request)
      .pipe(
        catchError((err) => {
          this.processing = false;
          this.initiateBulkNotification(SeverityOptions.ERROR, err.error.message);

          return observableEmpty();
        }),
      )
      .subscribe(() => {
        this.store.dispatch(
          SetDirtyMerchantsAction({ merchantsToMark: this.merchantsToProcess, updateRequestTime: Date.now() }),
        );
        this.store.dispatch(MerchantListCheckboxClearAction());
        this.processing = false;
        this.dropdown.clear(null);
        this.bulkActionModal.hideDialog();
        this.comment = '';
        this.initiateBulkNotification(SeverityOptions.SUCCESS, 'Bulk action successfully initiated!');
      });
  }

  public cancel() {
    this.dropdown.clear(null);
    this.bulkActionModal.hideDialog();
    this.form.reset();
    this.comment = '';
  }

  public initiateBulkNotification(severityOption: SeverityOptions, notificationMessage: string) {
    this.store.dispatch(
      GenericNotificationAction({
        severity: severityOption,
        summary: 'Bulk Customer Action',
        detail: notificationMessage,
        sticky: false,
        blocking: false,
      }),
    );
  }
}
