<div class="ls-sidebar-container" *ngIf="isVisible">
  <ng-container *ngIf="isLoading">
    <div style="margin: auto">
      <i class="pi pi-spin pi-spinner" style="font-size: 2rem; margin-top: 1rem"></i>
    </div>
  </ng-container>

  <ng-container *ngIf="isVisible">
    <div class="ls-sidebar-header-container">
      <div class="info-container">
        <div class="company-name">{{ title1 }}</div>
        <div class="merchant-id">{{ title2 }}</div>
        <div class="flex gap-2 mt-2">
          <ls-tag
            *ngFor="let value of tags"
            [tagInput]="value"
          ></ls-tag>
        </div>
      </div>
      <div class="link-container">
        <span
          ><i
            class="pi pi-link"
            pTooltip="Copy Link"
            [tooltipPosition]="'bottom'"
            (click)="copyLink.next(true)"
            (keyup.enter)="copyLink.next(true)"
            tabindex="0"
          ></i
        ></span>
        <span
          ><i
            class="pi pi-times"
            pTooltip="Close"
            [tooltipPosition]="'bottom'"
            (click)="closeSidebar.next(true)"
            (keyup.enter)="closeSidebar.next(true)"
            tabindex="0"
          ></i
        ></span>
      </div>
    </div>
    <div class="ls-sidebar-content-container">
      <ng-content></ng-content>
    </div>
    <div class="ls-sidebar-footer-container">
      <ng-component select="[footer]"></ng-component>
    </div>
  </ng-container>

</div>
