import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { Observable, map } from 'rxjs';

import { Notification } from '../../models';
import { notificationsActions, selectNotificationsData } from '../../state';
import { UserSignOutAction } from 'src/app/reducers';
import { Router } from '@angular/router';
import { AccountSelectorHandlerService } from 'src/app/services';
import { ProductFamily, inMyLS } from '@ls/common-ts-models';
import { EntitlementsGetAction } from '@ls/common-ng-components';
import { environment } from 'src/environments/environment';

interface NavItem {
  icon: string;
  routerLink?: string;
  tooltip: string;
  routerLinkActive?: string;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  public accountSettingsMenuItems: MenuItem[] = [];
  public accountSettingsMenuIsVisible = false;
  public notificationsData$!: Observable<{
    notifications: Notification[];
    unreadNotificationsCount: number;
  }>;
  public notificationsMenuItems$!: Observable<MenuItem[]>;
  public showNotifications = false;
  public topNavItems!: NavItem[];
  public accountInitials: string;
  public isMMServiceAccessEnabled = false;
  public isCertsUser = false;
  public isHybrydUser = true;
  public isTestTransactionUser = false;
  public iconSource = '../../../assets/icon/badge-check-grey.svg';
  private defaultIconSource = '../../../assets/icon/badge-check-grey.svg';
  private hoverIconSource = '../../../assets/icon/badge-check-white.svg';
  @ViewChild('notificationsButton') notificationsButton: ElementRef;
  @ViewChild('notificationsContainer') notificationsContainer: ElementRef;
  @ViewChild('messageContainer') messageContainer: ElementRef;

  private bypassHideNotifications = false;

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (this.notificationsContainer) {
      if (
        !this.notificationsButton.nativeElement.contains(event.target) &&
        !this.notificationsContainer.nativeElement.contains(event.target)
      ) {
        if (this.bypassHideNotifications) {
          this.bypassHideNotifications = false;
        } else {
          this.showNotifications = false;
        }
      }
    }
  }

  constructor(
    private primengConfig: PrimeNGConfig,
    private messageService: MessageService,
    private router: Router,
    private store: Store,
    private accountSelectorService: AccountSelectorHandlerService,
  ) {
    this.notificationsData$ = this.store.select(selectNotificationsData);

    this.notificationsMenuItems$ = this.store
      .select(selectNotificationsData)
      .pipe(map((data) => this.getNotificationsMenuItems(data.notifications.length, data.unreadNotificationsCount)));

    this.store.dispatch(notificationsActions.loadNotifications());
  }

  public ngOnInit(): void {
    this.notificationsData$ = this.store.select(selectNotificationsData).pipe(
      map((data) => ({
        ...data,
        notifications: data.notifications.map((notification) => ({
          ...notification,
          // Set custom icons based on processStatus for all notification types except myls/spreadsheetDownload.
          icon:
            notification.scopedTopic !== 'myls/spreadsheetDownload'
              ? this.determineIcon(notification)
              : notification.icon || '',
        })),
      })),
    );

    this.primengConfig.ripple = false;

    const account = this.accountSelectorService.getCurrentUser();

    this.accountInitials = account.name
      .split(' ')
      .map((x) => x.substring(0, 1).toUpperCase())
      .join('')
      .substring(0, 2);

    this.accountSelectorService.getAvailableAccounts().subscribe((accounts) => this.setAccountMenuItems(accounts));

    this.store
      .select((state: any) => state.entitlements)
      .subscribe((entitlements) => {
        let refreshTopNav = false;
        if (entitlements.products && entitlements.products[ProductFamily.MERCHANT_MONITORING]) {
          const mmProduct = entitlements.products && entitlements.products[ProductFamily.MERCHANT_MONITORING];

          this.isMMServiceAccessEnabled = mmProduct && Object.hasOwn(mmProduct, 'serviceAccessEnabled');

          refreshTopNav = true;
        }
        if (entitlements?.products?.[ProductFamily.TEST_TRANSACTIONS]) {
          this.isTestTransactionUser = true;
          refreshTopNav = true;
        }

        if (refreshTopNav) {
          this.setTopNavItems();
        }
      });

    this.store.dispatch(EntitlementsGetAction());

    this.setTopNavItems();
  }

  public setTopNavItems = () => {
    const { url } = this.router;

    const account = this.accountSelectorService.getCurrentUser();
    const isCertsUser = account.roles.includes('CPv2 Account Owner SF');
    this.isCertsUser = isCertsUser;

    this.topNavItems = [
      {
        icon: 'home',
        tooltip: 'Home',
        routerLink: '/',
        routerLinkActive: url === '/' ? 'active' : '',
      },
      // Not currently enabled
      // { icon: 'certifications', tooltip: 'Certifications', routerLink: '/services/select-certification-type' },
      // {
      //   icon: 'data-lookup',
      //   tooltip: 'Data Lookup',
      //   routerLink: '/services/lookups/data',
      //   routerLinkActive: url === '/services/lookups/data' ? 'active' : '',
      // },
      // Not currently enabled
      // { icon: 'risk-check', tooltip: 'Risk Check' },
    ];

    if (isCertsUser) {
      this.topNavItems.push({
        icon: 'certifications',
        tooltip: 'Certifications',
        // routerLink: '/services/select-certification-type',
        routerLink: '/account/home',
        routerLinkActive: url === '/services/select-certification-type' ? 'active' : '',
      });
    } else {
      this.topNavItems.push({
        icon: 'data-lookup',
        tooltip: 'Data Lookup',
        routerLink: '/services/lookups/data',
        routerLinkActive: url === '/services/lookups/data' ? 'active' : '',
      });
      if (this.isMMServiceAccessEnabled) {
        this.topNavItems.push({
          icon: 'merchant-monitoring',
          tooltip: 'Merchant Monitoring',
          routerLink: '/merchant-monitoring/merchant-list',
          routerLinkActive: url.includes('/merchant-list') ? 'active' : '',
        });
      }
      if (this.isTestTransactionUser) {
        this.topNavItems.push({
          icon: 'billing-light',
          tooltip: 'Test Transactions',
          routerLink: 'services/test-transactions',
          routerLinkActive: url.includes('/test-transactions') ? 'active' : '',
        });
      }
    }
  };

  public setAccountMenuItems = (accounts: { id: number; name: string }[]) => {
    const account = this.accountSelectorService.getCurrentUser();

    const isAccountOwner = account.roles.includes('CPv2 Account Owner');
    const isCertsUser = account.roles.includes('CPv2 Account Owner SF');

    const navItems: MenuItem[] = [
      {
        label: 'Sign out',
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
          this.store.dispatch(UserSignOutAction({ location: '/login' }));
        },
      },
      {
        separator: true,
      },
    ];

    if (isAccountOwner) {
      navItems.push({
        label: 'Billing',
        icon: 'pi pi-fw pi-credit-card',
        command: () => {
          this.router.navigate(['/account/billing']);
        },
      });

      if (!isCertsUser) {
        navItems.push({
          label: 'Manage Users',
          icon: 'pi pi-fw pi-users',
          command: () => {
            this.router.navigate(['/account/users']);
          },
        });
      }
    }

    if (!isCertsUser) {
      navItems.push({
        label: 'Account Settings',
        icon: 'pi pi-fw pi-cog',
        command: () => {
          this.router.navigate(['/account/account-details']);
        },
      });
    }

    if (accounts?.length > 1) {
      navItems.push({
        separator: true,
      });

      navItems.push({
        label: accounts.find((acct) => acct.id === account.lsAccountId)?.name,
        items: accounts.map((acct) => ({
          label: acct.name,
          command: () => {
            this.accountSelectorService.onChange(acct.id);
          },
        })),
      });
    }

    navItems.push({
      separator: true,
    });

    navItems.push({
      label: `
          <div class="account-settings-menu-user-info">
            <div class="account-settings-menu-user-info-name">${account.name}</div>
            <div class="account-settings-menu-user-info-email">${account.email}</div>
          </div>
        `,
      escape: false,
    });

    this.accountSettingsMenuItems = [...navItems];
  };

  ngOnDestroy(): void {
    this.store.dispatch(notificationsActions.stopNotifications());
  }

  public onAccountSettingsClick = (): void => {
    this.showNotifications = false;
  };

  public onDeleteNotification = (notification: Notification, event: Event): void => {
    event.stopPropagation();
    this.store.dispatch(notificationsActions.deleteNotification({ notification }));

    this.messageService.add({
      severity: 'info',
      summary: 'Success',
      detail: 'Notification deleted successfully.',
      data: {
        action: {
          text: 'Undo',
          click: () => {
            this.messageService.clear();
            this.store.dispatch(notificationsActions.undoDelete());
            this.messageService.clear();
            this.bypassHideNotifications = true;
          },
        },
      },
    });
  };

  public onNotificationsClick = (): void => {
    this.showNotifications = !this.showNotifications;
  };

  public onNotificationClick = (notification: Notification, event: Event): void => {
    event.stopPropagation();
    this.store.dispatch(notificationsActions.markNotificationRead({ notification }));
  };

  public moveToEC() {
    if (localStorage.getItem(inMyLS) === 'true' && this.isHybrydUser) {
      localStorage.setItem(inMyLS, 'false');
      window.location.href = environment.CONFIG.experienceCloudURL;
    }
  }

  // switches the icon image when the user hovers over the icon
  public switchToHoverIcon(): void {
    this.iconSource = this.hoverIconSource;
  }

  // switches the icon back to the default image when the user stops hovering over the icon
  public switchToDefaultIcon(): void {
    this.iconSource = this.defaultIconSource;
  }

  private determineIcon(notification: Notification): string {
    const statusIconMap = {
      partial: 'pi-exclamation-triangle yellow',
      error: 'pi-times red',
      success: 'pi-check green',
    };

    return statusIconMap[notification.processStatus] || '';
  }

  public formatDateWithTimezone = function (input: Date): string {
    if (input === undefined || input === null) {
      return '';
    }
    const dt = new Date(input);
    return dt.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    });
  };

  private getNotificationsMenuItems = (notificationsCount: number, unreadNotificationCount: number): MenuItem[] => {
    return [
      {
        label: 'Mark all as read',
        icon: 'pi pi-fw pi-check',
        disabled: unreadNotificationCount < 1,
        command: () => {
          this.store.dispatch(notificationsActions.markAllNotificationsRead());
          this.messageService.add({
            severity: 'info',
            summary: 'Success',
            detail: 'All notifications marked read successfully.',
          });
        },
      },
      {
        label: 'Delete All',
        icon: 'pi pi-fw pi-trash',
        disabled: notificationsCount < 1,
        command: () => {
          this.store.dispatch(notificationsActions.deleteAllNotifications());
          this.messageService.add({
            severity: 'info',
            summary: 'Success',
            detail: 'All notifications deleted successfully.',
            data: {
              action: {
                text: 'Undo',
                click: () => {
                  this.messageService.clear();
                  this.store.dispatch(notificationsActions.undoDelete());
                  this.messageService.clear();
                  this.bypassHideNotifications = true;
                },
              },
            },
          });
        },
      },
    ];
  };
}
