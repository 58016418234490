import { createAction, createReducer, on, props } from '@ngrx/store';

//
// CONSTANTS
//
export const SELECTABLE_ACCOUNT_LOAD_SUCCESS = '[SelectableAccount] LoadSuccess';
export const SELECTABLE_LOAD_ERROR = '[SelectableAccount] LoadError';

//
// ACTIONS
//
export const SelectableAccountLoadSuccessAction = createAction(
  SELECTABLE_ACCOUNT_LOAD_SUCCESS,
  props<{ accounts: Array<{ id: number; name: string }> }>()
);

export const SelectableAccountLoadErrorAction = createAction(SELECTABLE_LOAD_ERROR, props<{ errorText: string }>());

//
// STATE
//
export interface SelectableAccountState {
  accounts: Array<{ id: number; name: string }>;
  errorText: string;
}

export const initialSelectableAccountState: SelectableAccountState = {
  accounts: [],
  errorText: undefined
};

//
// REDUCER
//
export const SelectableAccountReducer = createReducer(
  initialSelectableAccountState,

  on(SelectableAccountLoadSuccessAction, (state, { accounts }) => ({
    accounts,
    errorText: undefined
  })),

  on(SelectableAccountLoadErrorAction, (state, { errorText }) => ({
    ...state,
    errorText
  }))
);
