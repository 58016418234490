import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { Opportunity, OpportunityStageName, OpportunityLossReason } from '@ls/common-ts-models';
import { ProductLookupService } from '../../services';

@Component({
  selector: 'data-lookup-wrapper',
  styleUrls: ['./wrapper.scss', '../data-lookup.scss', '../../styles/enroll.scss'],
  templateUrl: './wrapper.html',
  providers: [ProductLookupService],
})
export class DataLookupWrapperComponent implements OnInit {
  public loading = true;
  public display;
  public opportunity: Opportunity;

  constructor(
    private store: Store<AppState>,
    private productLookupService: ProductLookupService,
  ) {}

  public ngOnInit() {
    this.productLookupService.checkForOpportunity().subscribe(
      (opp: Opportunity) => {
        if (opp.StageName === OpportunityStageName.closedLost) {
          switch (opp.Loss_Reason__c) {
            case OpportunityLossReason.referredToCertification:
              this.display = 'referred';
              break;
            case OpportunityLossReason.denied:
              this.display = 'denied';
              break;
            case OpportunityLossReason.canceled:
              this.display = 'canceled';
              break;
          }
        } else {
          switch (opp.StageName) {
            case OpportunityStageName.negotiationReview:
              this.display = 'pending';
              break;
            case OpportunityStageName.contractStarted:
              this.display = 'approved';
              this.opportunity = opp;
              break;
          }
        }
        this.loading = false;
      },
      (err) => {
        if (err.status === 404) {
          this.display = 'enroll';
        } else {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Error checking status',
              detail:
                'There was an issue checking the status of your request.  Please try again.  If the issue persists, please contact support.',
              sticky: true,
              blocking: false,
            }),
          );
        }
        this.loading = false;
      },
    );
  }
}
