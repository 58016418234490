<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="widget" *ngIf="isOwner">
  <div class="widget-header">
    <div>
      <i class="fa fa-clock-o"></i>
      <h1>Billing History</h1>
    </div>
  </div>
  <div class="widget-body centered">
    <loading-spinner *ngIf="(!billingHistory && !error) || loading" loadingText="Loading Transactions">
    </loading-spinner>
    <div class="billing-history-table-widget">
      <div *ngIf="!billingHistory && error" class="error">
        <i class="fa fa-exclamation-triangle"></i>
        <div>
          Error loading billing history - please try again. If this problem persists, please contact support.
        </div>
      </div>
      <p-table *ngIf="billingHistory && !loading" [responsive]="true" responsiveLayout="stack" [value]="billingHistory">
        <ng-template pTemplate="header">
          <tr>
            <th>DATE</th>
            <th>PRODUCT</th>
            <th>AMOUNT</th>
            <th>PAYMENT METHOD</th>
            <th>RECEIPT LINK</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-historyItem let-rowData>
          <tr #historyitemrow>
            <td>
              <span class="p-column-title">DATE</span>
              {{historyItem.date | date}}
            </td>
            <td>
              <span class="p-column-title">PRODUCT</span>
              {{historyItem.product}}
            </td>
            <td>
              <span class="p-column-title">AMOUNT</span>
              <span>{{historyItem.amount | currency}}</span>
              <span class="refund-notice" *ngIf="historyItem.refunds?.length" (click)="op.toggle($event)">
                * Refund{{historyItem.refunds.length > 1 ? 's' : ''}} Applied
              </span>
              <p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true">
                  <div *ngIf="historyItem.refunds.length > 1; else singleRefund">
                    Refunds were applied to this transaction.
                  </div>
                  <ng-template #singleRefund>
                    A refund was applied to this transaction.
                  </ng-template>
                  <ul>
                    <li *ngFor="let refund of historyItem.refunds">
                      {{refund.amount | currency}} on {{refund.date | date}} for: {{refund.reason}} 
                    </li>
                  </ul>
                  <div>
                    View the <a target="_blank" href="{{historyItem.receiptUrl}}">receipt link</a> for details.
                  </div>
              </p-overlayPanel>
            </td>
            <td>
              <span class="p-column-title">PAYMENT METHOD</span>
              <div class="pay-icon-wrapper">
                <div *ngIf="ccIconMap[historyItem.type]; else noIconForType">
                  <i class="fa fa-cc-{{ccIconMap[historyItem.type]}}"></i>
                </div>
                <ng-template #noIconForType>
                  <div>{{historyItem.type}}</div>
                </ng-template>
                <div>{{historyItem.id}}</div>
              </div>
            </td>
            <td>
              <span class="p-column-title">RECEIPT LINK</span>
              <a target="_blank" href="{{historyItem.receiptUrl}}">View</a>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="5">
              No transactions found.
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer" let-columns>
          <tr *ngIf="moreHistoryAvailable">
            <td [attr.colspan]="5" class="footer">
              <div *ngIf="moreHistoryAvailable">
                <a *ngIf="!loadingMore; else loadingMoreMsg" (click)="getMoreHistory()">Load More</a>
                <ng-template #loadingMoreMsg>
                  <p-progressSpinner
                    [style]="{ width: '1em', height: '1em' }"
                  ></p-progressSpinner>
                </ng-template>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>