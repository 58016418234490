const MIME_TYPES: {
  CSV: string;
  XLSX: string;
} = {
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

const MM_V4_DOC_MIGRATION_DETAILS = [
  {
    caption: 'Merchant',
    cells: [
      {
        description: 'LegitScript has added new fields to our merchant records',
        diff: [
          'customer_tags: Any customer defined tag(s) associated with the merchant',
          'custom: A stringified custom object for the merchant',
          'ls_tags: Any LegitScript defined tag(s) associated with the merchant',
          'referral_url: A referral url for the merchant'
        ],
        method: 'GET',
        path: 'merchant/{merchantId}, merchants'
      },
      {
        description: 'The merchant update process has been modified in the following ways:',
        diff: [
          'A merchant update that contains a tags parameter will be rejected -- please use merchant/{merchantId}/tags to set tags for existing merchants',
          'custom and referral_url fields are optionally setable in put requests',
          'LegitScript strongly recommends that PUT requests be used to perform updates on existing merchants instead of POST'
        ],
        method: 'PUT',
        path: 'merchant/{merchantId}'
      },
      {
        description: 'LegitScript has added fields to our merchant creation method:',
        diff: [
          'custom, tags(customer_tags), and referral_url fields will now be handled in merchant creation requests.'
        ],
        method: 'POST',
        path: 'merchant/{merchantId}'
      },
      {
        description:
          'A new method has been added to allow updated comments attached to a previously created customer action',
        diff: [
          'Customer actions must still be taken using the existing endpoint, which has not changed',
          'Customer action comments can be retrieved using the GET merchant endpoint'
        ],
        method: 'PUT',
        path: 'merchant/{merchantId}/comment'
      },
      {
        description:
          'The request for multiple merchants and their details has changed, please note the following changes:',
        diff: [
          'The itemsPerPage parameter has been deprecated.  Please use limit to specify the maximum items to request',
          'The page parameter has been deprecated.  Please use offset to appropriately slice resulting results',
          'Total found records will no longer be returned in the payload.  The caller is to assume the complete list has been exhausted when the returned merchant count is less than the specified limit.'
        ],
        method: 'GET',
        path: 'merchants'
      }
    ]
  },
  {
    caption: 'Tags',
    cells: [
      {
        description: 'LegitScript has added a new endpoint to query customer tags',
        diff: [
          'This new endpoint will allow the caller to audit all tags applied across all merchants in their portfolio'
        ],
        method: 'GET',
        path: 'tags/'
      },
      {
        description: 'LegitScript has added a new endpoint to set customer tags applied to a merchant',
        diff: [
          'Accepts a tags array to be applied as customer_tags to an existing merchant',
          'Please see documentation below for use/restrictions'
        ],
        method: 'POST',
        path: 'merchant/{merchantId}/tags'
      },
      {
        description: 'LegitScript has added a new endpoint to remove customer tags applied to a merchant',
        diff: [
          'Accepts a tags array to be removed from the customer_tags array of an existing merchant',
          'Please see documentation below for use/restrictions'
        ],
        method: 'DELETE',
        path: 'merchant/{merchantId}/tags/'
      },
      {
        description: 'LegitScript has added a new endpoint to help track changes made to LegitScript applied tags',
        diff: [
          'Allows the customer to see all recently added and removed ls_tags across all merchants for a given time range',
          'Please see documentation below for use/restrictions'
        ],
        method: 'GET',
        path: '/tags/recent'
      }
    ]
  }
];

export { MIME_TYPES, MM_V4_DOC_MIGRATION_DETAILS };
