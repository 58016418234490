<div class="widgets-container">
  <div class="widget full-widget">
    <div class="widget-header">
      <i class="fa fa-question-circle"></i><h1>Help</h1>
    </div>
    <div class="widget-body centered">
      <div class="entry-container">
        <h1 class="margin-vertical-md">Client Portal Help Documents</h1>
        <loading-spinner *ngIf="loading" loadingText="Loading Help Docs"></loading-spinner>
        <ul>
          <li *ngFor="let f of helpFiles"><a href="{{f.url}}" target="_blank">{{f.name}}</a></li>
        </ul>
        <p>For support please contact us <a href="https://help.legitscript.com" target="_blank">here</a>.</p>
      </div>
    </div>
  </div>
</div>
