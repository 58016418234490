import { Injectable } from '@angular/core';
import { PaymentPlan, CreditCard, BillingSummary, BillingItem, PricingInfo } from '@ls/common-ts-models';

@Injectable()
export class PaymentService {
  public buildPaymentPlan(paymentInfo: PricingInfo): PaymentPlan {
    const billingSummary: BillingSummary = {
      billingItems: []
    } as BillingSummary;
    const creditCard: CreditCard = {} as CreditCard;

    if (paymentInfo.certId) {
      billingSummary.externalReferenceId = paymentInfo.certId;
    }

    billingSummary.billingItems = paymentInfo.billingItems;

    billingSummary.dueToday = this.sumDueToday(billingSummary.billingItems);
    const discountedDueToday =
      this.sumDueToday(billingSummary.billingItems, true) >= 0
        ? this.sumDueToday(billingSummary.billingItems, true)
        : 0;
    billingSummary.discountedDueToday = discountedDueToday === billingSummary.dueToday ? undefined : discountedDueToday;

    billingSummary.dueOnApproval = this.sumDueOnApproval(billingSummary.billingItems);
    const discountedDueOnApproval =
      this.sumDueOnApproval(billingSummary.billingItems, true) >= 0
        ? this.sumDueOnApproval(billingSummary.billingItems, true)
        : 0;
    billingSummary.discountedDueOnApproval =
      discountedDueOnApproval === billingSummary.dueOnApproval ? undefined : discountedDueOnApproval;

    billingSummary.products = this.combineProducts(billingSummary.billingItems);
    billingSummary.invoiceCustomer =
      billingSummary.dueToday >= paymentInfo.maxCCAmount ||
      this.isInvoiceCustomer(billingSummary.billingItems, paymentInfo.maxCCAmount);
    return {
      creditCard,
      billingSummary
    };
  }

  private sumDueToday = (items: BillingItem[], discounted?: boolean) => {
    if (!items) {
      return 0;
    }
    return items.reduce((acc, cur) => {
      if (discounted && typeof cur.discountedAmount === 'number') {
        return acc + cur.discountedAmount;
      }
      if (cur.amount) {
        return acc + cur.amount;
      }
      return acc;
    }, 0);
  };

  private sumDueOnApproval = (items: BillingItem[], discounted?: boolean) => {
    if (!items) {
      return 0;
    }
    return items.reduce((acc, cur) => {
      if (cur.recurringAmount === null || !cur.recurringOnApproval) {
        return acc;
      }
      if (discounted && typeof cur.discountedRecurringAmount === 'number') {
        return acc + cur.discountedRecurringAmount;
      }
      return acc + cur.recurringAmount;
    }, 0);
  };

  private combineProducts = (items: any[]) => {
    if (!items) {
      return [];
    }
    return items.map(item => ({ id: item.productId, quantity: item.quantity }));
  };

  private isInvoiceCustomer = (items: any[], maxCCAmount: number) => {
    if (!items) {
      return false;
    }

    const invoiceProducts = items.filter(item => item.recurringAmount >= maxCCAmount);
    return invoiceProducts.length > 0;
  };
}
