<div *ngIf="question">
    <div class="error-text" *ngIf="formErrorsExist()">
      <i class="fa fa-exclamation-triangle"></i>
      <span>Please save at least one item to continue</span>
    </div>
    <div class="cert-item-instructions">
      <span class="item-count-warning">You enrolled to certify {{certItemsAppliedFor}} item{{certItemsAppliedFor > 1 ? 's' : ''}}.  If you enter a different number of items here, there may be a pricing change upon analyst review.</span>
      <span>You will also be able to review these answers after completing the questionnaire.</span>
      <i class="fa fa-question-circle" style="color:#0372C6;" pTooltip="Fill out each field, then click the Add Item button to save the item. You can review your answers after adding the item by clicking the name of the item when its added below. If you need to make changes, delete the item and add a new one. Click Save & Next when you have added all your items and are ready to move to the next question." tooltipPosition="bottom"></i>
    </div>
    <div class="cert-question" [formGroup]="certItemForm">
      <div class="multi-row-outer-wrapper">  
        <div class="multi-row-input-wrapper">
          <div *ngFor="let inputElement of question.inputs" class="full-width {{ ['textbox', 'file_upload', 'multi_row', 'multi_input_other'].includes(inputElement.input_type) ? 'span-all' : ''}}">
            <div [ngSwitch]="inputElement.input_type">
              <div 
                *ngIf="inputElement.label_text" 
                class="question-text"
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(inputElement.label_text)"
              ></div>
              <div *ngSwitchCase="'single_select'" class="full-width">
                <single-select-question [question]="inputElement" [multiRow]="true" [answer]="answer"
                  (handleChanges)="handleQuestionChanges($event)" [parentForm]="certItemForm"
                  [questionInput]="inputElement" [ngClass]="setFieldValidity(inputElement.id)"></single-select-question>
              </div>
              <div 
                *ngSwitchCase="['multi_select', 'multi_select_location', 'multi_input_other'].includes(inputElement.input_type) ? inputElement.input_type : ''" 
                class="full-width"
                [ngClass]="{'grid-2': inputElement.input_type === 'multi_input_other'}"
              >
                <div>
                  <multi-select-question [question]="inputElement" [multiRow]="true" [answer]="answer" (handleChanges)="handleQuestionChanges($event)"
                  [parentForm]="certItemForm" [questionInput]="inputElement" [ngClass]="setFieldValidity(inputElement.id)"></multi-select-question>
                </div>
                <div *ngIf="inputElement.input_type === 'multi_input_other' && showTextArea[inputElement.id] && otherInputs[inputElement.id]">
                  <single-text-question [question]="otherInputs[inputElement.id]" [multiRow]="true" [answer]="answer"
                    (handleChanges)="handleQuestionChanges($event)" [parentForm]="certItemForm"
                    [ngClass]="setFieldValidity(inputElement.id + ':other')" [placeholder]="otherInputs[inputElement.id].label_text">
                  </single-text-question>
                </div>
              </div>
              <div *ngSwitchCase="['text', 'url'].includes(inputElement.input_type) ? inputElement.input_type : ''" class="full-width" #input>
                <single-text-question [question]="inputElement" [multiRow]="true" [answer]="answer"
                  (handleChanges)="handleQuestionChanges($event)" [parentForm]="certItemForm"
                  [ngClass]="setFieldValidity(inputElement.id)" [type]="inputElement.input_type"></single-text-question>
              </div>
              <div *ngSwitchCase="'yes_no'" class="full-width" [ngClass]="setFieldValidity(inputElement.id, 'yesNoElementError')">
                  <yes-no-question [question]="inputElement" [multiRow]="true" [answer]="answer" (handleChanges)="handleQuestionChanges($event)" [parentForm]="certItemForm"></yes-no-question>
              </div>
              <div *ngSwitchCase="'textbox'" class="full-width">
                <text-area-question [question]="inputElement" [multiRow]="true" [answer]="answer"
                  (handleChanges)="handleQuestionChanges($event)" [parentForm]="certItemForm"
                  [ngClass]="setFieldValidity(inputElement.id)" [showTextArea]="true" [placeholder]="''">
                </text-area-question>
              </div>
              <div *ngSwitchCase="'file_upload'" class="full-width" [ngClass]="setFieldValidity(inputElement.id, 'uploadElemenentErrorBorder')">
                <text-and-file-question [question]="inputElement" [answer]="answer"
                  (handleChanges)="handleQuestionChanges($event)" [parentForm]="certItemForm" [showTextArea]="false"
                  (handleFileSelection)="handleFileSelection($event, inputElement)" [clearFiles]="clearFiles"></text-and-file-question>
              </div>
              <div *ngSwitchCase="'multi_row'" class="full-width">
                <multi-row-question *ngSwitchCase="'multi_row'" [question]="inputElement" [answer]="inputElement.answer"
                  (handleChanges)="handleQuestionChanges($event)" [outerForm]="certItemForm" [isCertItemForm]=true [ngClass]="setFieldValidity(inputElement.id)"></multi-row-question>
              </div>
            </div>
          </div>
        </div>
  
        <div class="btn-right-wrapper">
          <button (click)="addNewCertifiableItem()" title="Save this entry">
            Add Item
          </button>
        </div>
  
        <div *ngIf="answer?.answered_inputs[0]?.answer_rows?.length" class="added-cert-items">
          <h3>
            My Certification Item<span *ngIf="multipleItemSubmissionEnabled">s ({{getCertItemDifference()}})</span>
          </h3>
          <div style="position:relative;">
            <div *ngIf="updatingItems" class="cert-item-updating">
              <p-progressSpinner></p-progressSpinner>
            </div>
            <div class="cert-item-instructions">Click an item to view the details and remove it if necessary.  Click the "Save & Next" button when you're ready to move to the next question.</div>
            <certifiable-item-detail [question]="question" [answer]="answer" (handleRemoveItem)="handleRemoveItem($event)"></certifiable-item-detail>
          </div>
        </div>
      </div>
    </div>
  </div>