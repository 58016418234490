import { ViewChild, Input, Component } from '@angular/core';
import {
  LegitScriptModalComponent,
  GenericNotificationAction,
  SeverityOptions,
  EntitlementsGetAction,
} from '@ls/common-ng-components';
import { PortalService } from '../services';
import { AppState } from 'src/app/app.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'refresh-api-key',
  styleUrls: ['./refresh-api-key.component.scss'],
  templateUrl: './refresh-api-key.component.html',
})
export class RefreshApiKeyComponent {
  public refreshSubmitting = false;
  public refreshComplete = false;

  @Input() public mmProduct: { externalReferenceId: any; apiKeyExists?: boolean };
  @ViewChild(LegitScriptModalComponent) public confirmAPIKeyRefreshModal: LegitScriptModalComponent;

  public newApiKey: string;

  constructor(
    public portalService: PortalService,
    private store: Store<AppState>,
  ) {}

  public refreshClick() {
    this.confirmAPIKeyRefreshModal.showDialog();
  }

  public performRefreshAPIKey() {
    this.refreshSubmitting = true;

    this.portalService.refreshApiKey(this.mmProduct.externalReferenceId).subscribe(
      (response) => {
        this.store.dispatch(EntitlementsGetAction());
        if (response && response.value) {
          this.newApiKey = response.value;
          this.refreshSubmitting = false;
          this.refreshComplete = true;
        } else {
          this.handleRefreshFailure();
        }
      },
      () => {
        this.handleRefreshFailure();
      },
    );
  }

  public hideRefreshModal() {
    this.confirmAPIKeyRefreshModal.hideDialog();
    this.newApiKey = null;
    this.refreshSubmitting = this.refreshComplete = false;
  }

  private handleRefreshFailure() {
    this.store.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.ERROR,
        summary: 'Error refreshing API key',
        detail:
          'An error occured while refreshing your API key.  The original key remains active and has not been changed.  Please try this update again later.  If this problem persists, please contact support',
        sticky: false,
        blocking: false,
      }),
    );
    this.hideRefreshModal();
  }
}
