import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductFamily } from '@ls/common-ts-models';
import { Observable } from 'rxjs';
import { AppState, fnEntitlementsState } from 'src/app/reducers';

@Component({
  selector: 'select-lookup',
  styleUrls: ['../data-lookup.scss', './select-lookup.scss'],
  templateUrl: './select-lookup.html',
})
export class DataLookupSelectComponent implements OnInit {
  public entitlements$: Observable<any>;
  public productFamily = ProductFamily.PRODUCT_LOOKUP;

  constructor(public store: Store<AppState>) {}

  public ngOnInit() {
    this.entitlements$ = this.store.select(fnEntitlementsState);
  }
}
