import { Injectable } from '@angular/core';
import { MyLSListMerchantCheckbox } from '@ls/common-ts-models';
import { MyLSMerchantConstants } from '@ls/common-ts-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  MerchantListChildCheckboxAction,
  MerchantListGlobalCheckboxAction,
  MerchantListCheckboxAction,
  MerchantListCheckboxSuccessAction,
  MerchantListErrorAction,
} from '../reducers';
import { MMMerchantService } from '../services';

const bulkActionMax = MyLSMerchantConstants.BULK_ACTION_MAX;

@Injectable()
export class CheckboxMerchantEffect {
  public childCheckbox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantListChildCheckboxAction),
      map((action) => {
        const actionPositions = action.actionPositions;
        const checkboxIndex = action.checkboxIndex;
        let globalCheckbox = action.globalCheckbox;

        actionPositions[checkboxIndex].checked = !actionPositions[checkboxIndex].checked;
        if (globalCheckbox) {
          actionPositions.map((actionPos) => {
            if (!actionPos.checked) {
              globalCheckbox = false;
            }
          });
        }

        const selectedMerchantNumber = actionPositions.filter((box) => box.checked).length;
        const bulkActionEnabled = action.totalRecords <= bulkActionMax;

        return MerchantListCheckboxSuccessAction({
          actionPositions,
          globalCheckbox,
          selectedMerchantNumber,
          bulkActionEnabled,
        });
      }),
    ),
  );

  public globalCheckbox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantListGlobalCheckboxAction),
      map((action) => {
        let actionPositions = action.actionPositions;
        let globalCheckbox = action.globalCheckbox;

        globalCheckbox = !globalCheckbox;

        actionPositions = actionPositions.map((actionPos) => {
          actionPos.checked = globalCheckbox;
          return actionPos;
        });

        const selectedMerchantNumber = actionPositions.filter((box) => box.checked).length;
        const bulkActionEnabled = action.totalRecords <= bulkActionMax;

        return MerchantListCheckboxSuccessAction({
          actionPositions,
          globalCheckbox,
          selectedMerchantNumber,
          bulkActionEnabled,
        });
      }),
    ),
  );

  public getCheckboxes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantListCheckboxAction),
      switchMap((action) => {
        let newGlobalCheckbox = action.globalCheckbox;

        if (action.totalRecords > bulkActionMax) {
          newGlobalCheckbox = false;
        }
        return this.merchantService.listCorrelationIds(action.sortField, action.sortDirection, action.filters).pipe(
          map((resCIDUpdateDate) => {
            const newActionPositions: MyLSListMerchantCheckbox[] = [];
            for (const receivedCID of resCIDUpdateDate) {
              const box = action.actionPositions.find((x) => x.correlationId === receivedCID.correlationId);
              const isChecked = box ? box.checked : newGlobalCheckbox;

              newActionPositions.push({
                correlationId: receivedCID.correlationId,
                updateDate: receivedCID.updateDate,
                checked: isChecked,
              });
            }

            const selectedMerchantNumber = newActionPositions.filter((box) => box.checked).length;
            const bulkActionEnabled = action.totalRecords <= bulkActionMax;

            return MerchantListCheckboxSuccessAction({
              actionPositions: newActionPositions,
              globalCheckbox: newGlobalCheckbox,
              selectedMerchantNumber,
              bulkActionEnabled,
            });
          }),
          catchError((err) => of(MerchantListErrorAction({ err }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private merchantService: MMMerchantService,
  ) {}
}
