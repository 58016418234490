import { empty as observableEmpty, Subject } from 'rxjs';

import { takeUntil, catchError } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
import { BillingService, GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'billing',
  templateUrl: 'billing.component.html',
  styleUrls: ['billing.component.scss'],
})
export class BillingComponent implements OnInit, OnDestroy, AfterViewInit {
  public processingCCChange = false;
  public submitButtonEnabled = false;
  public loadedStripeCustomer = false;
  public creditCardChangeSuccess: boolean;
  public defaultErrorMsg = 'Please Try Again.  If the problem persists, please contact support';
  public paymentForm: FormGroup = new FormGroup({});
  public destroyed$: Subject<boolean> = new Subject();
  public defaultCard: { id: any; last4: string };
  public cardUpdated = false;
  public sources: any;
  public error: any;

  private stripe: any = (window as any).Stripe(environment.CONFIG.stripeKey);
  private stripeElements: any;
  private card: any;

  constructor(
    private store: Store<AppState>,
    private billingSvc: BillingService,
  ) {}

  public ngOnInit(): void {
    this.stripeElements = this.stripe.elements();
    this.card = this.stripeElements.create('card');

    this.billingSvc
      .getStripeCustomer()
      .pipe(
        catchError(() => {
          this.showNotification('Failed to load customer billing information.');
          this.error = true; // stop spinner if this fails
          return observableEmpty();
        }),
      )
      .subscribe((result) => {
        this.loadedStripeCustomer = true;
        const customer: any = result;
        this.sources = (customer as any).sources;

        // make sure we are pulling the default card
        this.defaultCard = this.sources
          ? this.sources.data.find(
              (source: { id: any; object: string }) =>
                source.id === customer.default_source && source.object === 'card',
            )
          : null;
      });
  }

  public ngAfterViewInit(): void {
    this.card.mount('#stripeCard');

    // Handle real-time validation errors from the card Element:
    this.card.addEventListener('change', (event: { error: any; complete: any }) => {
      if (event.error) {
        this.submitButtonEnabled = false;
      } else if (event.complete) {
        this.submitButtonEnabled = true;
      } else {
        this.submitButtonEnabled = false;
      }
    });
  }

  public async onSubmit() {
    this.submitButtonEnabled = false;
    this.processingCCChange = true;

    this.stripe.createToken(this.card).then((source: { error: any; token: { id: string } }) => {
      if (source.error) {
        this.creditCardChangeSuccess = false;
        this.processingCCChange = false;
        this.showNotification('Credit Card Update Initialization Failed');
      } else {
        const previousSource = this.defaultCard ? this.defaultCard.id : null;
        this.billingSvc
          .updateCreditCard(source.token.id, previousSource)
          .pipe(takeUntil(this.destroyed$.asObservable()))
          .subscribe(
            () => {
              this.cardUpdated = true;
              this.creditCardChangeSuccess = true;
              this.processingCCChange = false;
              this.showNotification('Thank You!', 'Your credit card was successfully updated!', SeverityOptions.SUCCESS);
              this.card.clear();
              this.ngOnInit();
              this.ngAfterViewInit();
            },
            (err) => {
              this.creditCardChangeSuccess = false;
              this.submitButtonEnabled = true;
              this.processingCCChange = false;
              const errorMsg = err.error && err.error.message ? err.error.message : null;
              this.showNotification('Credit Card Update Failed', errorMsg);
              return observableEmpty();
            },
          );
      }
    });
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    // clean up Stripe objects and iframes
    this.card.unmount();
    this.card.destroy();
    if (this.stripe && this.stripe._controller) {
      this.stripe._controller._controllerFrame._iframe.remove();
    }
  }

  private showNotification(summary: string, detail?: string, severity?: SeverityOptions) {
    if (!detail) {
      detail = this.defaultErrorMsg;
    }
    this.store.dispatch(
      GenericNotificationAction({
        severity: severity ? severity : SeverityOptions.ERROR,
        summary,
        detail,
        sticky: false,
        blocking: false,
      }),
    );
  }
}
