import { createReducer, on } from '@ngrx/store';

import { notificationsActions } from '../actions';
import { Notification } from '../../models';

export interface NotificationsState {
  notifications: Notification[];
}

export const initialNotificationsState: NotificationsState = {
  notifications: [],
};

export const notificationsReducer = createReducer(
  initialNotificationsState,
  on(notificationsActions.setNotifications, (state, { notifications }) => ({
    ...state,
    notifications,
  })),
);
