<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<main class="enroll-page">
  <form class="form " (ngSubmit)="onSubmit()" (change)="onChange()" id="enroll-page-form" [formGroup]="enrollFormGroup">
    <ng-container class="intro">
      <h1>You're one step closer to experiencing LegitScript’s Industry-leading Merchant Monitoring. No false positives. Actionable intelligence. Results you can trust.</h1>
      <section class="intro">
        <picture>
          <img class="product-logo" src="../assets/img/merchant-monitoring-icon.png" />
        </picture>
        <div>
          <div class="list-title">Signing up for LegitScript Merchant Monitoring is straightforward:</div>
          <ul>
            <li>Complete the enrollment questionnaire.</li>
            <li>Depending on the size of your merchant portfolio, you may be eligible for automatic enrollment. If not, we'll be in touch soon to help you complete the enrollment process.</li>
            <li>Once enrolled, upload your merchants, and our ongoing monitoring will begin.</li>
          </ul>
        </div>
      </section>
  </ng-container>
    <h1>First, let's determine your monitoring service pricing</h1>
    <p class="row1 span2">
      This enrollment form is used to determine the applicable category and pricing for your service.
    </p>
    <section class="form-container form-group mb20">
      <div class="question-number">
        <span class="question-number">1</span>
      </div>
      <div>
        <label>Primary Website URL:</label>
        <input type="url" class="form-control" name="primaryUrl" [formControl]="primaryUrl" [class.errorBorder]="primaryUrl.invalid && (primaryUrl.dirty || !firstTry)" required />
        <span *ngIf="!primaryUrl.valid && !firstTry" class="error">* Required</span>
      </div>
      <div class="question-number">
        <span class="question-number">2</span>
      </div>
      <div>
        <label>How many merchants do you have in your portfolio?</label>
        <div class="radio-group" [class.errorBorder]="numMerchants.invalid && (numMerchants.dirty || !firstTry)">
          <input value="{{NumMerchantsQuestionValues.UNDER_5k}}" name="numMerchants" id="numMerchantsUnder5k" type="radio" [formControl]="numMerchants" required>
          <label for="numMerchantsUnder5k">1 - 4,999</label>
          <input value="{{NumMerchantsQuestionValues.UNDER_50k}}" name="numMerchants" id="numMerchantsUnder50k" type="radio" [formControl]="numMerchants">
          <label for="numMerchantsUnder50k">5,000 - 49,999</label>
          <input value="{{NumMerchantsQuestionValues.UNDER_100k}}" name="numMerchants" id="numMerchantsUnder100k" type="radio" [formControl]="numMerchants">
          <label for="numMerchantsUnder100k">50,000 - 99,999</label>
          <input value="{{NumMerchantsQuestionValues.OVER_100k}}" name="numMerchants" id="numMerchantsOver100k" type="radio" [formControl]="numMerchants">
          <label for="numMerchantsOver100k">100,000 or more</label>
        </div>
        <span *ngIf="!numMerchants.valid && !firstTry" class="error">* Required</span>
      </div>
      <div class="question-number">
        <span class="question-number">3</span>
      </div>
      <div>
        <fieldset class="span2">
          <label>
            Do you, or do you currently plan to, support merchants operating in one or more of the following areas?
          </label>
          <div class="question-subtext">
            Adult Entertainment, Cigar Stores and Stands, Dating and Escort Services, Digital Goods, Direct Marketing, Gambling, Pharmaceuticals, Quasi Cash (e.g.,Cryptocurrency), Securities Brokers/Dealers
          </div>
          <div class="radio-group" [class.errorBorder]="highRisk.invalid && (highRisk.dirty || !firstTry)">
            <input value="yes" name="highRisk" id="highRiskYesAnswer" type="radio" [formControl]="highRisk" required>
            <label for="highRiskYesAnswer">Yes</label>
            <input value="no" name="highRisk" id="highRiskNoAnswer" type="radio" [formControl]="highRisk">
            <label for="highRiskNoAnswer">No</label>
          </div>
          <span *ngIf="!highRisk.valid && !firstTry" class="error">* Required</span>
        </fieldset>
      </div>
    </section>

    <div class="api-error span3" *ngIf="apiError">
      An unknown error occurred. Please try again later or contact support.
    </div>

    <section class="calculated-price-container" *ngIf="!loadingPricing; else loadingPrices">
      <header class="mb30">Your Merchant Monitoring Pricing</header>
      <p class="price-disclaimer mb30" *ngIf="!pricingInfo; else prices">
        Please complete the questions above to calculate pricing.
      </p>
      <ng-template #prices>
        <div class="mb30" *ngIf="pricingInfo.price >= 0; else customPricing">
          <div class="prices-container mb30">
            <section style="justify-self: right;">
              <header>MONTHLY FEE <span *ngIf="pricingDisplayFirstMonthIsProrated">(prorated)</span></header>
              <p>${{pricingDisplayFirstMonth}}</p>
            </section>
          </div>
          <p class="price-disclaimer">
            Upon signing up for LegitScript Merchant Monitoring, you will be charged ${{pricingDisplayFirstMonth}} at the time of enrollment for your first month,
            and then ${{pricingInfo.price}} thereafter every month on the first day of the month.
          </p>
        </div>
        <ng-template #customPricing>
          <div class="prices-container mb30">
            <section style="grid-column: 1 / span 2;">
              <p class="custom-pricing">
                A LegitScript sales team member will be in contact with you shortly about pricing.
              </p>
            </section>
          </div>
        </ng-template>
      </ng-template>
    </section>
    <ng-template #loadingPrices>
      <div *ngIf="!apiError" class="mb30" style="margin-top: 4em; text-align: center">
        Loading...
        <span class="floating-spinner-container">
          <p-progressSpinner [style]="{ width: '1.5em', height: '1.5em' }"></p-progressSpinner>
        </span>
      </div>
    </ng-template>

    <div class="terms-conditions" *ngIf="!apiError && shouldEnforceTerms">
      <input #termsCheckbox="ngModel" [ngModelOptions]="{standalone: true}" id="terms-checkbox" name="terms-checkbox" type="checkbox" (change)="onTermsChecked($event)" [(ngModel)]="termsSelected" required />
      <label for="terms-checkbox">I have read and agree to LegitScript's</label>
      <a (click)="termsModal.showDialog()">Terms of Service</a>
      <span *ngIf="!termsCheckbox.valid && !firstTry" class="error"><br>* Required</span>
    </div>

    <div style="text-align: center" *ngIf="!apiError">
      <p *ngIf="enrollFormGroup.invalid && !firstTry" class="errorBox errorBorder error">
        * Some answers are missing or invalid
      </p>
      <button type="submit" class="btn btn-primary" [disabled]="(firstTry ? false : (enrolling || enrollFormGroup.invalid || (shouldEnforceTerms && !termsSelected)))">Continue</button>
      <span class="floating-spinner-container" *ngIf='enrolling'>
        <p-progressSpinner [style]="{ width: '1.5em', height: '1.5em' }"></p-progressSpinner>
      </span>
    </div>
  </form>

  <legitscript-modal #termsModal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=800 height=auto>
    <div class="dialog-body" style="overflow:auto; height:75vh" libScrollEvent (onscroll)="onDialogScroll($event)" [bottomOffset]=10 [topOffset]=50>
      <merchant-monitoring-terms></merchant-monitoring-terms>
    </div>
    <div class="dialog-footer">
      <button type="button" (click)="onAgreeToTerms();" label="Yes" [disabled]="apiError || hasScrolledToBottom === false">I Agree</button>
    </div>
  </legitscript-modal>

  <legitscript-modal #customPricingModal (closed)="continueToService()" [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=400 height=auto>
    <div class="dialog-body">
      <h2>Thank You!</h2>
      <div>
        <p>We have received your request to enroll in our merchant monitoring service.</p>
        <p>We will contact you when our review of your request is complete.</p>
      </div>
    </div>
    <div class="dialog-footer">
      <button type="button" (click)="customPricingModal.hideDialog();" label="Continue">Continue</button>
    </div>
  </legitscript-modal>
</main>
