<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<main class="enroll-page">
  <form class="form " (ngSubmit)="onSubmit()" (change)="onChange($event)" id="enroll-page-form" [formGroup]="enrollFormGroup">    <ng-content select=".intro"></ng-content>
    <h1>First, let's determine your certification pricing</h1>
    <p class="row1 span2">
      The application is divided into two parts. This enrollment form is used to determine the applicable category and pricing for your application. The questionnaire follows automatically after payment and is used to gather information regarding your company and operations. This will be the basis of LegitScript’s evaluation of your company.
    </p>
    <section class="form-container form-group mb20">
      <div class="question-number">
        <span class="question-number">1</span>
      </div>
      <p>
        <label>LEGAL BUSINESS NAME</label>
        <input type="text" class="form-control" name="legalName" [formControl]="legalName" [class.errorBorder]="legalName.invalid && (legalName.dirty || !firstTry)" required />
        <span *ngIf="!legalName.valid && !firstTry" class="error">* Required</span>
      </p>
      <p>
        <label>DOING BUSINESS AS</label>
        <input type="text" class="form-control" name="dba" [formControl]="dba" [class.errorBorder]="dba.invalid && (dba.dirty || !firstTry)" required />
        <span *ngIf="!dba.valid && !firstTry" class="error">* Required</span>
      </p>
      <p class="span2-offset1" [hidden]="isCbdProduct">
        <label>PRIMARY WEBSITE URL</label>
        <input type="url" class="form-control" name="primaryUrl" [formControl]="primaryUrl" [class.errorBorder]="primaryUrl.invalid && (primaryUrl.dirty || !firstTry)" required />
        <span *ngIf="!primaryUrl.valid && !firstTry" class="error">* Required</span>
      </p>
      <ng-content></ng-content>
    </section>

    <section class="calculated-price-container" *ngIf="pricing; else loadingPrices">
      <header class="mb30">Your Certification Pricing</header>
      <p class="price-disclaimer mb30" *ngIf="(!pricingInfo?.applicationFee || !pricingInfo?.subscriptionFee); else prices">
        Please complete the questions above to calculate pricing
      </p>
      <ng-template #prices>
        <p class="price-disclaimer mb30" *ngIf="pricingInfo.subscriptionFee > 0">
          If you have a coupon your pricing will be updated on the next page.
        </p>
        <div class="prices-container mb30">
          <section>
            <header>APPLICATION FEE</header>
            <p>{{ '$' + pricingInfo.applicationFee }}</p>
            <footer>
              <span *ngIf="pricingInfo.perItem">*Per {{ pricingInfo.perItem }}<br></span> Paid up front
            </footer>
          </section>
          <section>
            <header>ANNUAL FEE</header>
            <p>{{ '$' + pricingInfo.subscriptionFee }}</p>
            <footer>
              <span *ngIf="pricingInfo.perItem">*Per {{ pricingInfo.perItem }}<br></span> Paid when certified
            </footer>
          </section>
        </div>
        <p *ngIf="expeditedEnabled" class="inline-checkbox">
          <input #expeditedCheckbox="ngModel" [ngModelOptions]="{standalone: true}" id="expedited-checkbox" name="expedited-checkbox" type="checkbox" [(ngModel)]="expeditedReview" (change)="onExpeditedChecked($event)"/>
          <label for="expedited-checkbox">Add <a (click)="expeditedModal.showDialog()">Expedited Processing Service</a></label>
        </p>
        <p *ngIf="pricingInfo.custom" class="price-disclaimer mb30">
          The <a href="mailto:sales@legitscript.com">LegitScript Sales Team</a> will contact you about annual fee pricing for this certification.
        </p>
        <p class="price-disclaimer mb30" *ngIf="pricingInfo.subscriptionFee > 0; else noSubscriptionFee">
          You will be charged a non-refundable application fee. After that, the annual fee will be automatically charged on the date of approval of your application, and will recur on an annual basis starting from the date of approval.
        </p>
        <ng-template #noSubscriptionFee>
          <p class="price-disclaimer mb30">
            Promotional pricing is temporary. Annual fees may increase upon annual renewal.
          </p>
        </ng-template>
      </ng-template>
      <ng-content *ngIf="pricingInfo.subscriptionFee > 0" select=".pricing-info"></ng-content>
    </section>
    <ng-template #loadingPrices>
      <div *ngIf="!apiError" class="mb30" style="margin-top: 4em; text-align: center">
        Loading...
        <span class="floating-spinner-container">
          <p-progressSpinner [style]="{ width: '1.5em', height: '1.5em' }"></p-progressSpinner>
        </span>
      </div>
    </ng-template>

    <div class="terms-conditions" *ngIf="!apiError">
      <input #termsCheckbox="ngModel" [ngModelOptions]="{standalone: true}" id="terms-checkbox" name="terms-checkbox" type="checkbox" (change)="onTermsChecked($event)" [(ngModel)]="termsSelected" required />
      <label for="terms-checkbox">I have read and agree to LegitScript's</label>
      <a (click)="termsModal.showDialog()">Terms of Service</a>
      <span *ngIf="!termsCheckbox.valid && !firstTry" class="error"><br>* Required</span>
    </div>
    
    <div *ngIf="isCbdProduct || isCbdWebsite">
      <div class="terms-conditions">
        <input (change)="onCBDTermsChecked($event, 'cbdTermsStandars')" [ngModelOptions]="{standalone: true}" id="cbd-terms-standards" name="cbd-terms-standards" type="checkbox" [(ngModel)]="cbdTerms.cbdTermsStandards" required />
        <label for="cbd-terms-standards">I have read and understand the LegitScript CBD Product and Website Certification standards.</label>
        <span *ngIf="!cbdTerms.cbdTermsStandards && !firstTry" class="error"><br>* Required</span>
      </div>
      <div class="terms-conditions">
        <input (change)="onCBDTermsChecked($event, 'cbdTermsNonRefundable')" [ngModelOptions]="{standalone: true}" id="cbd-terms-non-refundable" name="cbd-terms-non-refundable" type="checkbox" [(ngModel)]="cbdTerms.cbdTermsNonRefundable" required />
        <label for="cbd-terms-non-refundable">I acknowledge that the application fee is non-refundable.</label>
        <span *ngIf="!cbdTerms.cbdTermsNonRefundable && !firstTry" class="error"><br>* Required</span>
      </div>
      <div class="terms-conditions">
        <input (change)="onCBDTermsChecked($event, 'cbdTermsData')" [ngModelOptions]="{standalone: true}" id="cbd-terms-data" name="cbd-terms-data" type="checkbox" [(ngModel)]="cbdTerms.cbdTermsData" required />
        <label for="cbd-terms-data">I consent to the processing of my</label>
        <a (click)="cbdTermsModal.showDialog()">data.</a>
        <span *ngIf="!cbdTerms.cbdTermsData && !firstTry" class="error"><br>* Required</span>
        
      </div>
           
    </div>

    <div style="text-align: center" *ngIf="!apiError">
      <p *ngIf="enrollFormGroup.invalid && !firstTry" class="errorBox errorBorder error">
        * Some answers are missing or invalid
      </p>
      <button type="submit" class="btn btn-primary" [disabled]="preventPayment || (firstTry ? false : (enrolling || enrollFormGroup.invalid || !termsSelected || !validConfirms || !cbdTermsAccepted))">Continue</button>
      <span class="floating-spinner-container" *ngIf='enrolling'>
        <p-progressSpinner [style]="{ width: '1.5em', height: '1.5em' }"></p-progressSpinner>
      </span>
    </div>
  </form>

  <legitscript-modal #expeditedModal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=800 height=auto>
    <div class="dialog-body" style="overflow:auto; height:50">
      The Expedited Processing Service will expedite the start of your review. This will not shorten the certification review process, but will ensure that the review of your application begins as soon as possible.
      <expedited-pricing-info [enabled]="expeditedEnabled"></expedited-pricing-info>
    </div>
    <div class="dialog-footer">
      <button type="button" (click)="confirmExpeditedReview()">Add</button>
      <button type="button" (click)="expeditedModal.hideDialog(); expeditedReview=false">Cancel</button>
    </div>
  </legitscript-modal>

  <legitscript-modal #termsModal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=800 height=auto>
    <div class="dialog-body" style="overflow:auto; height:75vh" libScrollEvent (onscroll)="onDialogScroll($event)" [bottomOffset]=10 [topOffset]=50>
      <ng-content select=".terms"></ng-content>
    </div>
    <div class="dialog-footer">
      <button type="button" (click)="onAgreeToTerms();" label="Yes" [disabled]="apiError || hasScrolledToBottom === false">I Agree</button>
    </div>
  </legitscript-modal>

  <legitscript-modal #cbdTermsModal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=800 height=auto [header]="'LegitScript Data Collection Consent'">
    <div class="dialog-body" style="overflow:auto; height:75vh" libScrollEvent (onscroll)="onDialogScroll($event)" [bottomOffset]=10 [topOffset]=50>
      <ng-content select=".terms-data"></ng-content>
    </div>
    <div class="dialog-footer">
      <button type="button" (click)="onAgreeToCBDTerms();">I Agree</button>
    </div>
  </legitscript-modal>
</main>