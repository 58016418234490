import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isProdBuild } from 'config/helpers';

@Component({
  selector: 'docs',
  templateUrl: 'docs.component.html',
  styleUrls: ['docs.component.scss'],
})
export class DocsComponent {
  public docs;
  private accountIdForS3 = isProdBuild() ? '908113025037' : '916533587194';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.docs = [
      {
        name: 'Merchant Monitoring API V3 Docs',
        route: '../v3-docs',
      },
      {
        name: 'Merchant Monitoring User Guide',
        extraLinks: {
          en: `https://myls-help-docs-us-west-2-${this.accountIdForS3}-ls.s3-us-west-2.amazonaws.com/Merchant+Monitoring+MyLegitScript+User+Guide.pdf`,
          jp: `https://myls-help-docs-us-west-2-${this.accountIdForS3}-ls.s3-us-west-2.amazonaws.com/Merchant+Monitoring+MyLegitScript+User+Guide_JP.pdf`,
        },
      },
    ];
  }

  public openDoc(doc: any) {
    this.router.navigate([doc.route], { relativeTo: this.route });
  }
}
