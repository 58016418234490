
<ls-side-bar
  [isVisible]="visible"
  [isLoading]="loading"
  [title1]="testTransaction?.id"
  [title2]="testTransaction?.websiteUrl"
  (closeSidebar)="closeSidebar.next(true)"
  (copyLink)="copyLink.next(true)"
>
  <ng-container *ngIf="visible">
    <div class="transaction-details">
      <div class="persistent-monitoring-info">
        Persistent Monitoring - {{testTransaction?.monitoringStatus}}
      </div>
      <p-tabView>
        <p-tabPanel header="Transaction Details">
          <div class="last-updated">
            Performed {{testTransaction?.analysisDate | date: 'short'}}
          </div>
          <div class="test-transaction-details-contents">
            <ls-test-transaction-details-tab
              [loading]="loading"
              [isDownloadLoading]="isDownloadLoading"
              [isZipping]="isZipping"
              [testTransaction]="testTransaction"
              (requestScreenshots)="onRequestScreenshots($event)"
              (downloadDocuments)="onDownloadDocuments($event)"
              (showScreenshots)="onShowImages($event)"
            ></ls-test-transaction-details-tab>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Documents">
          <div class="last-updated">
            Performed {{testTransaction?.analysisDate | date: 'short'}}
          </div>
          <div class="test-transaction-details-contents">
            <ls-test-transaction-documents
              [loading]="loading"
              [isDownloadLoading]="isDownloadLoading"
              [isZipping]="isZipping"
              [testTransaction]="testTransaction"
              (downloadDocuments)="onDownloadDocuments($event)"
            ></ls-test-transaction-documents>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </ng-container>

</ls-side-bar>

<ls-image-carousel
    [visible]="showImageViewer"
    [images]="images"
    [currentImage]="showImageNumber"
    (currentImageChanged)="updateCurrentImage($event)"
    (closeGallery)="showImageViewer = false"
    (download)="onDownloadScreenshot($event)"
></ls-image-carousel>
