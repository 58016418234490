import { createReducer, on } from '@ngrx/store';
import { CustomerActionRequest } from '@ls/common-ts-models';
import { merchantListActions } from '../actions/merchant-list.actions';

export interface MerchantListState {
  selectedMerchantCorrelationIds: string[];
  allMerchantCorrelationIds: string[];
  selectAll: boolean;
  currentCustomerActionRequest: CustomerActionRequest;
  customerActionPending: boolean;
  lastMerchantCorrelationIdsActedOn: string[];
}

export const initialMerchantListState: MerchantListState = {
  selectedMerchantCorrelationIds: [],
  allMerchantCorrelationIds: [],
  selectAll: false,
  currentCustomerActionRequest: {
    action: undefined,
    comment: '',
  },
  customerActionPending: false,
  lastMerchantCorrelationIdsActedOn: [],
};

export const merchantListReducer = createReducer(
  initialMerchantListState,

  on(merchantListActions.selectMerchant, (state, { merchant }) => {
    const selectedMerchants = [...state.selectedMerchantCorrelationIds, merchant.correlation_id];
    return {
      ...state,
      selectedMerchantCorrelationIds: selectedMerchants,
    };
  }),

  on(merchantListActions.unselectMerchant, (state, { merchant }) => {
    if (state.selectAll) {
      return {
        ...state,
        selectedMerchantCorrelationIds: state.allMerchantCorrelationIds.filter(
          (cid) => cid !== merchant.correlation_id,
        ),
        selectAll: false,
      };
    }
    const selectedMerchants = state.selectedMerchantCorrelationIds.filter((cid) => cid !== merchant.correlation_id);
    return {
      ...state,
      selectAll: false,
      selectedMerchantCorrelationIds: selectedMerchants,
    };
  }),

  on(merchantListActions.setSelectAllMerchants, (state, { value }) => {
    return {
      ...state,
      // If the user selects all, the back end call to take actions relies on filters instead of correlation ids,
      // so we clear the selected correlation ids. If we're unselecting all, the selected merchants should have been empty already
      selectedMerchantCorrelationIds: [],
      selectAll: value,
    };
  }),

  on(merchantListActions.clearMerchantSelection, (state) => {
    return {
      ...initialMerchantListState,
      lastMerchantCorrelationIdsActedOn: [...state.lastMerchantCorrelationIdsActedOn],
    };
  }),

  on(merchantListActions.applyBulkMerchantAction, (state, { actionRequest }) => {
    return {
      ...state,
      currentCustomerActionRequest: actionRequest,
      customerActionPending: true,
    };
  }),

  on(merchantListActions.applyBulkMerchantActionComplete, (state) => {
    return {
      ...state,
      selectedMerchantCorrelationIds: [],
      selectAll: false,
      lastMerchantCorrelationIdsActedOn: state.selectAll
        ? [...state.allMerchantCorrelationIds]
        : [...state.selectedMerchantCorrelationIds],
      currentCustomerActionRequest: {
        action: undefined,
        comment: '',
      },
      customerActionPending: false,
    };
  }),

  on(merchantListActions.getAllCorrelationIdsComplete, (state, { correlationIds }) => {
    return {
      ...state,
      allMerchantCorrelationIds: [...correlationIds],
    };
  }),
);
