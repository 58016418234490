import { createAction, createReducer, on, props } from '@ngrx/store';
import { Account, Certification, User } from '@ls/common-ts-models';

// CONSTANTS
export enum AccountActionTypes {
  Load = '[Account] Load',
  LoadSuccess = '[Account] LoadSuccess',
  LoadError = '[Account] LoadError',
  LoadCertifications = '[Account] LoadCertifications',
  LoadCertificationsError = '[Account] LoadCertificationsError',
  LoadCertificationsSuccess = '[Account] LoadCertificationsSuccess',
}

// ACTIONS
export const AccountLoadAction = createAction(AccountActionTypes.Load);

export const AccountLoadSuccessAction = createAction(AccountActionTypes.LoadSuccess, props<{ payload: Account }>());

export const AccountLoadErrorAction = createAction(AccountActionTypes.LoadError, props<{ err: Error }>());

export const AccountLoadCertificationsSuccessAction = createAction(
  AccountActionTypes.LoadCertificationsSuccess,
  props<{ certifications: Certification[] }>(),
);

export const AccountLoadCertificationsErrorAction = createAction(
  AccountActionTypes.LoadCertificationsError,
  props<{ err: Error }>(),
);

// STATE
export interface AccountState extends Account {
  err?: Error;
  pending?: boolean;
  certificationsPending?: boolean;
  user?: User;
}

export const initialAccountState: AccountState = {
  err: null,
  pending: false,
  certificationsPending: false,
  user: {} as User,
};

// REDUCER

export const AccountReducer = createReducer(
  initialAccountState,

  on(
    AccountLoadAction,
    (state) =>
      ({
        ...state,
        pending: true,
        err: null,
      }) as AccountState,
  ),

  on(
    AccountLoadSuccessAction,
    (state, { payload }) =>
      ({
        ...state,
        pending: false,
        ...payload,
      }) as AccountState,
  ),

  on(
    AccountLoadErrorAction,
    (state, { err }) =>
      ({
        ...state,
        pending: false,
        err,
      }) as AccountState,
  ),

  on(
    AccountLoadCertificationsSuccessAction,
    (state, { certifications }) =>
      ({
        ...state,
        certificationsPending: false,
        certifications,
      }) as AccountState,
  ),

  on(
    AccountLoadCertificationsErrorAction,
    (state, { err }) =>
      ({
        ...state,
        certificationsPending: false,
        err,
      }) as AccountState,
  ),
);
