import { DatePipe, CommonModule } from '@angular/common';
import { ApplicationRef, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LegitscriptUiModule, LocalStorageService } from '@ls/common-ng-components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';

import { MMDashboardComponent } from './dashboard.component';
import { MMMerchantDetailComponent } from './detail/merchant-detail.component';
import { MMConfirmBulkActionComponent } from './confirm-bulk-action/confirm-bulk-action.component';
import { EditableFieldTextComponent, EditableFieldUrlComponent } from './editable-field/';
import { APP_EFFECTS } from './effects';
import { MMEnrollComponent, MMTermsComponent } from './enroll';
import { MMMerchantFiltersComponent } from './filter/merchant-filters.component';
import { MMEnrolledGuard, MMNoOpportunityExistsGuard } from './guards';
import { MMMerchantListComponent } from './list/merchant-list.component';
import { MMLSRiskActionLabelsComponent } from './ls-risk-action-labels/ls-risk-action-labels.component';
import { APP_REDUCERS } from './reducers';
import { RefreshApiKeyComponent } from './refresh-api-key';
import {
  MMCustomerService,
  MMEnrollService,
  MMMerchantService,
  MMUploadService,
  PortalService,
  S3DownloadService,
  ContentLabelsService,
} from './services';
import { UploadWorkbookValidator } from './services/validators/upload-workbook-validator';
import { MMUploadComponent } from './upload/upload.component';
import { MMUploadHistoryComponent } from './uploadHistory/uploadHistory.component';
import { ComponentsModule } from '../../components/components.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxScrollEventModule } from '../ngx-scroll-event/ngx-scroll-event.module';
import { MerchantSummaryService, TagsService } from '../merchant-monitoring-v2/services';

@NgModule({
  bootstrap: [MMDashboardComponent, MMEnrollComponent],
  declarations: [
    MMDashboardComponent,
    MMEnrollComponent,
    MMTermsComponent,
    MMMerchantListComponent,
    MMLSRiskActionLabelsComponent,
    MMMerchantDetailComponent,
    MMConfirmBulkActionComponent,
    MMUploadComponent,
    MMUploadHistoryComponent,
    MMMerchantFiltersComponent,
    EditableFieldTextComponent,
    EditableFieldUrlComponent,
    RefreshApiKeyComponent,
  ],
  imports: [
    ComponentsModule,
    // BrowserModule,
    CommonModule,
    FormsModule,
    // HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    EffectsModule.forFeature(APP_EFFECTS),
    StoreModule.forFeature('merchantMonitoring', APP_REDUCERS),
    LegitscriptUiModule,
    NgxScrollEventModule,
    ProgressSpinnerModule,
    CalendarModule,
    TableModule,
    FileUploadModule,
    ProgressBarModule,
    MultiSelectModule,
    TooltipModule,
    DropdownModule,
    AutoCompleteModule,
    MessagesModule,
    MessageModule,
  ],
  providers: [
    MMEnrollService,
    MMEnrolledGuard,
    MMNoOpportunityExistsGuard,
    MMMerchantService,
    MMUploadService,
    UploadWorkbookValidator,
    MMCustomerService,
    PortalService,
    DatePipe,
    S3DownloadService,
    LocalStorageService,
    ContentLabelsService,
    TagsService,
    MerchantSummaryService,
  ],
  exports: [
    MMDashboardComponent,
    MMEnrollComponent,
    MMLSRiskActionLabelsComponent,
    MMMerchantListComponent,
    MMMerchantDetailComponent,
    MMConfirmBulkActionComponent,
    MMUploadComponent,
    MMUploadHistoryComponent,
  ],
})
export class MerchantMonitoringModule {
  constructor(public appRef: ApplicationRef) {}
}
