<div class="cert-questionnaire-wrapper">
    <div class="cert-questionnaire-container">
        <div class="questionnaire-wrapper">
            <div class="spinner-container" *ngIf="(questionnaireChanges$ | async).pending || !(questionnaire$ | async).question">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <form class="outer" [formGroup]="outerForm">
                <div>
                    <h1 *ngIf="(questionnaire$ | async).question?.name">{{(questionnaire$ | async).question.name}}</h1>
                    <div *ngIf="(questionnaire$ | async).question?.question_text" 
                        class="question-text"
                        [innerHTML]="sanitizer.bypassSecurityTrustHtml((questionnaire$ | async).question?.question_text)"
                    ></div>
                    <div [ngSwitch]="(questionnaire$ | async).question?.question_type">
                        <multi-row-question *ngSwitchCase="'multi_row'"
                            [question]="(questionnaire$ | async).question"
                            [answer]="(questionnaire$ | async).currentAnswer"
                            [serverError]="(questionnaire$ | async).errorText"
                            [pending]="(questionnaire$ | async).pending"
                            (handleChanges)="handleChangesFromQuestionInput($event)"
                            [outerForm]="outerForm"
                            [saveClicked]="saveClicked"
                        ></multi-row-question>
                        <certifiable-item *ngSwitchCase="'certifiable_item_product'"
                            [question]="(questionnaire$ | async).question"
                            [answer]="(questionnaire$ | async).currentAnswer"
                            [serverError]="(questionnaire$ | async).errorText"
                            [pending]="(questionnaire$ | async).pending"
                            (handleChanges)="handleChangesFromQuestionInput($event)"
                            [outerForm]="outerForm"
                            [saveClicked]="saveClicked"
                            [tileTitleId]="'productName'"
                        ></certifiable-item>
                        <certifiable-item *ngSwitchCase="'certifiable_item_website'"
                            [question]="(questionnaire$ | async).question"
                            [answer]="(questionnaire$ | async).currentAnswer"
                            [serverError]="(questionnaire$ | async).errorText"
                            [pending]="(questionnaire$ | async).pending"
                            (handleChanges)="handleChangesFromQuestionInput($event)"
                            [outerForm]="outerForm"
                            [saveClicked]="saveClicked"
                            [tileTitleId]="'domain'"
                        ></certifiable-item>
                        <question *ngSwitchDefault
                            [question]="(questionnaire$ | async).question"
                            [answer]="(questionnaire$ | async).currentAnswer"
                            [serverError]="(questionnaire$ | async).errorText"
                            [pending]="(questionnaire$ | async).pending"
                            (handleChanges)="handleChangesFromQuestionInput($event)"
                            [outerForm]="outerForm"
                            [saveClicked]="saveClicked"
                        ></question>
                    </div>
                </div>
                <div *ngIf="(questionnaire$ | async).question">
                    <hr />
                    <div class="btn-wrapper">
                        <div>
                            <button
                                class="btn-gray nav-button"
                                [hidden]="(questionnaire$ | async).onFirstQuestion || (questionnaire$ | async).editingCompletedQuestion"
                                (click)="prevQuestion()"
                            >
                                <i class="fa fa-chevron-left"></i>
                                <span class="mobile-hide">Previous</span>
                            </button>
                        </div>
                        <div class="btn-proceed-wrapper">
                            <button class="btn-no-bg"
                                *ngIf="!(questionnaire$ | async).currentAnswer?.dirty"
                                (click)="answerLater()"
                            >
                                Answer Later
                            </button>
                            <button class="btn-no-bg"
                                *ngIf="(questionnaire$ | async).editingCompletedQuestion"
                                (click)="cancelEdit()"
                            >
                                Cancel
                            </button>
                            <button class="nav-button" (click)="nextQuestion()">
                                <span>Save</span> <span [hidden]="(questionnaire$ | async).editingCompletedQuestion"> & Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
        <cert-progress *ngIf="(questionnaireChanges$ | async)?.questionnaire?.questions"
            [answers]="(questionnaireChanges$ | async).answers?.answeredQuestions"
            [totalQuestions]="(questionnaireChanges$ | async)?.questionnaire?.questions.length"
            [pending]="(questionnaireChanges$ | async).pending"
            [serverError]="(questionnaireChanges$ | async).errorText"
            [editing]="(questionnaire$ | async).editingCompletedQuestion"
        ></cert-progress>
    </div>
</div>

<cert-renewal-instructions></cert-renewal-instructions>