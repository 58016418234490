import { ContentLabelsService } from '../../merchant-monitoring/services';
import {
  ContentLabelsErrorAction,
  GetContentLabelsAction,
  GetContentLabelsSuccessAction,
} from '../reducers/content-labels.reducer';
import { ContentlabelList } from '@ls/common-ts-models';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';

@Injectable()
export class ContentLabelsEffect {
  public list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetContentLabelsAction),
      switchMap((action) =>
        this.contentLabelsService.getContentLabels().pipe(
          map((res: ContentlabelList) =>
            GetContentLabelsSuccessAction({
              contentLabels: res.contentLabels,
            }),
          ),
          catchError((err) => of(ContentLabelsErrorAction({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private contentLabelsService: ContentLabelsService,
  ) {}
}
