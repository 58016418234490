<ng-container *ngIf="pricing; else loading">
    <h1>Pricing Information</h1>
    <p><strong>Pricing is on a per-facility basis.</strong> Our pricing structure is divided into a one-time application fee and a recurring annual fee. Your credit card will be charged a non-refundable application fee after completing this enrollment form. After that, the annual fee will be automatically charged on the date of approval of your application and will recur on your credit card on an annual basis starting from the date of approval.</p>
    <table class="pricing-modal-table">
      <tr>
        <th>Number of unique facilities</th>
        <th>Application fee per unique facility</th>
        <th>Annual fee per unique facility</th>
      </tr>
      <tr>
        <td>1 to 9</td>
        <td>${{ pricing.ONE_TO_NINE.APPLICATION }}</td>
        <td>${{ pricing.ONE_TO_NINE.SUBSCRIPTION }}</td>
      </tr>
      <tr>
        <td>10 to 24</td>
        <td>${{ pricing.TEN_TO_TWENTYFOUR.APPLICATION }}</td>
        <td>${{ pricing.TEN_TO_TWENTYFOUR.SUBSCRIPTION }}</td>
      </tr>
      <tr>
        <td>25 to 49</td>
        <td>${{ pricing.TWENTYFIVE_TO_FORTYNINE.APPLICATION }}</td>
        <td>${{ pricing.TWENTYFIVE_TO_FORTYNINE.SUBSCRIPTION }}</td>
      </tr>
      <tr>
        <td>50 to 99</td>
        <td>${{ pricing.FIFTY_TO_NINETYNINE.APPLICATION }}</td>
        <td>${{ pricing.FIFTY_TO_NINETYNINE.SUBSCRIPTION }}</td>
      </tr>
      <tr>
        <td>100+</td>
        <td>${{ pricing.HUNDRED_PLUS.APPLICATION }}</td>
        <td>${{ pricing.HUNDRED_PLUS.SUBSCRIPTION }}</td>
      </tr>
    </table>
    <expedited-pricing-info></expedited-pricing-info>
  </ng-container>
  <ng-template #loading>
    Loading pricing...
  </ng-template>
