import { QuestionnaireReducer } from './questionnaire.reducer';
import { CertificationsReducer } from './certifications.reducer';

export const APP_REDUCERS = {
  questionnaire: QuestionnaireReducer,
  certifications: CertificationsReducer
};

export * from './questionnaire.reducer';
export * from './certifications.reducer';
export * from './app.state';
