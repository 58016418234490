import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';
import { map, skip, takeUntil } from 'rxjs/operators';
import {
  CustomerActionsDisplay,
  CustomerAction,
  ResubmittableActions,
  AnalysisStateExternal,
  COMMON_ERRORS,
  MyLSDomain,
  MyLSMerchant,
} from '@ls/common-ts-models';
import { GenericNotificationAction, SeverityOptions, UrlRegEx } from '@ls/common-ng-components';
import { MerchantDetailGetAction, MerchantDirtiedAction, MMAppState } from '../reducers';
import { MMMerchantService } from '../services';
import countryCodes from '../../../../assets/country-abbreviations.json';
import { isEqual, isEmpty, sortBy } from 'lodash';
import { AppState } from 'src/app/reducers';
import { TagSelectorComponent } from 'src/app/components/tag-selector/tag-selector.component';

// Exported for specs only.
export interface MMMerchantDetailInfoColumn {
  name: string;
  properties: string;
  type?: string;
  validators?: ValidatorFn[];
}

@Component({
  selector: 'mm-merchant-detail',
  styleUrls: ['./merchant-detail.component.scss'],
  templateUrl: './merchant-detail.component.html',
})
export class MMMerchantDetailComponent implements OnInit, OnDestroy {
  private static IsHyperText(url: string): boolean {
    const hyperTextRegex = new RegExp('^https?://', 'i');
    const isHyperText = hyperTextRegex.test(url);
    return isHyperText;
  }

  private static getInvalidUrl(domains: string[]): string {
    const invalidURLs = domains.filter(
      (domain) => !MMMerchantDetailComponent.IsHyperText(domain) || !UrlRegEx().test(domain),
    );
    return invalidURLs.length > 0 ? invalidURLs[0] : null;
  }

  public loading = true;
  public formSubmitLoading = false;
  public err: Error;
  public merchant: MyLSMerchant;
  public correlationId: string;
  public actionForDisplay: string;
  public actionFormGroup: FormGroup = this.formBuilder.group({
    action: this.formBuilder.control(''),
    comment: this.formBuilder.control(''),
  });
  public firstTry = true;
  public updatingAction = false;

  public allCustomerActions = CustomerActionsDisplay;
  public customerActions: Array<{
    value: CustomerAction;
    text: string;
  }>;

  public canEdit = true;
  public editingList = false;
  public editingAdditionalInfo = false;
  public firstTryInfo = true;
  public infoFormGroup: FormGroup = this.formBuilder.group({});
  public infoFormErrorMessages: Message[] = [];
  public domainUpdateValidationError = '';
  public countryFields = ['merchant_country', 'dba_country', 'business_owner_country'];
  public countries: any[] = countryCodes.map((c) => c.name);
  public filteredCountries: any[] = [...this.countries];
  public originalCountries: any[] = countryCodes;

  public additionalInfoColumnsLeft: MMMerchantDetailInfoColumn[] = [
    {
      name: 'Merchant Category Code',
      properties: 'category_code',
      validators: [Validators.pattern(/^([0-9]){4}$/)],
    },
    {
      name: 'Merchant Descriptor',
      properties: 'merchant_descriptor',
      validators: [Validators.maxLength(500)],
    },
    {
      name: 'Merchant Email',
      properties: 'merchant_email',
      validators: [Validators.maxLength(150)],
    },
    {
      name: 'Merchant Phone',
      properties: 'merchant_phone',
      validators: [Validators.maxLength(20)],
    },
    {
      name: 'Merchant Street',
      properties: 'merchant_street',
      validators: [Validators.maxLength(150)],
    },
    {
      name: 'Merchant City',
      properties: 'merchant_city',
      validators: [Validators.maxLength(150)],
    },
    {
      name: 'Merchant Region',
      properties: 'merchant_region',
      validators: [Validators.maxLength(50)],
    },
    {
      name: 'Merchant Postal Code',
      properties: 'merchant_postal_code',
      validators: [Validators.maxLength(255)],
    },
    {
      name: 'Merchant Country',
      properties: 'merchant_country',
      type: 'country',
      validators: [Validators.required, this.countryValidator()],
    },
    { name: 'DBA Name', properties: 'dba_name', validators: [Validators.maxLength(100)] },
    { name: 'DBA Phone', properties: 'dba_phone', validators: [Validators.maxLength(100)] },
    { name: 'DBA Street', properties: 'dba_street', validators: [Validators.maxLength(100)] },
    { name: 'DBA City', properties: 'dba_city', validators: [Validators.maxLength(100)] },
    { name: 'DBA Region', properties: 'dba_region', validators: [Validators.maxLength(100)] },
    { name: 'DBA Postal Code', properties: 'dba_postal_code', validators: [Validators.maxLength(100)] },
    { name: 'DBA Country', properties: 'dba_country', type: 'country', validators: [this.countryValidator()] },
  ];
  public additionalInfoColumnsRight: MMMerchantDetailInfoColumn[] = [
    { name: 'Additional URLs', properties: 'additional_domains' },
    {
      name: 'Associated Email Addresses',
      properties: 'associated_email_addresses',
      validators: [Validators.maxLength(500)],
    },
    {
      name: 'Business Owner Postal Code',
      properties: 'business_owner_postal_code',
      validators: [Validators.maxLength(100)],
    },
    {
      name: 'Business Owner Country',
      properties: 'business_owner_country',
      type: 'country',
      validators: [this.countryValidator()],
    },
    { name: 'ICA', properties: 'ica', validators: [Validators.maxLength(100)] },
    { name: 'ISO', properties: 'iso', validators: [Validators.maxLength(100)] },
    {
      name: 'Sub ISO',
      properties: 'sub_iso',
      validators: [Validators.maxLength(100)],
    },
    { name: 'Tags', properties: 'customer_tags', type: 'tags' },
    { name: 'Agent', properties: 'agent', validators: [Validators.maxLength(100)] },
  ];

  public authoritativeDomainList: string[] = [];
  public domainFromAcquirerList: string[] = [];

  private destroyed$: Subject<boolean> = new Subject();
  @ViewChild('tagEditor') private tagEditor: TagSelectorComponent;

  constructor(
    private store: Store<MMAppState & AppState>,
    private route: ActivatedRoute,
    private merchantService: MMMerchantService,
    private formBuilder: FormBuilder,
  ) {}

  public ngOnInit() {
    this.actionFormGroup = this.formBuilder.group({
      action: ['', Validators.required],
      comment: ['', Validators.required],
    });

    this.store
      .select((state) => state.merchantMonitoring.merchantDetail)
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .pipe(skip(1)) // Skip old merchant detail in store
      .subscribe(({ err, merchant, pending }: { err: any; merchant: MyLSMerchant; pending: any }) => {
        this.customerActions = [...this.allCustomerActions];

        this.loading = pending;
        if (pending) {
          return;
        }

        if (err) {
          this.merchant = null;
          this.err = err;
        }

        if (!err && merchant) {
          this.err = null;
          this.merchant = merchant;
          this.canEdit = ![AnalysisStateExternal.Terminated, AnalysisStateExternal.Inactive].includes(
            this.merchant.status,
          );
          this.setValidCustomerActions();
          const existingAction = this.allCustomerActions.find((a) => a.value === this.merchant.your_action);
          if (merchant.your_action_date && !existingAction) {
            this.actionForDisplay = this.allCustomerActions.find((a) => a.value === CustomerAction.Reset).text;
          } else {
            this.actionForDisplay = existingAction ? existingAction.text : 'N/A';
          }
          this.actionFormGroup.setValue({
            action: null,
            comment: this.merchant.your_action_comment,
          });

          this.mapDomainsToLists(merchant.merchant_domain);
        }
      });

    this.route.paramMap
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .pipe(map((params: ParamMap) => params.get('correlationId')))
      .subscribe((correlationId: string) => {
        this.correlationId = correlationId;
        this.store.dispatch(MerchantDetailGetAction({ correlationId }));
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public submitAction() {
    this.firstTry = false;

    if (this.actionFormGroup.invalid) {
      return;
    }

    const { action, comment } = this.actionFormGroup.value;
    this.updatingAction = true;
    this.merchantService.updateCustomerAction(this.correlationId, { action, comment }).subscribe(
      () => {
        this.store.dispatch(
          MerchantDirtiedAction({
            correlationId: this.correlationId,
            previousUpdateDate: this.merchant.your_action_date as any,
            updateRequestTime: Date.now(),
          }),
        ); // your_action_date is actually a string due to http serialization
        this.store.dispatch(MerchantDetailGetAction({ correlationId: this.correlationId }));
        this.updatingAction = false;
      },
      () => {
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Action Update Error',
            detail:
              'An error occurred while processing your action update request.  Please try again.  If this problem persists, please contact support',
            sticky: true,
            blocking: true,
          }),
        );
        this.updatingAction = false;
      },
    );
  }

  public resetForm() {
    this.actionFormGroup.reset({
      action: null,
      comment: this.merchant.your_action_comment,
    });
  }

  public setValidCustomerActions() {
    if (!ResubmittableActions.includes(this.merchant.your_action)) {
      this.customerActions = this.customerActions.filter((action) => action.value !== this.merchant.your_action);
    }

    if (
      [AnalysisStateExternal.Pending, AnalysisStateExternal.Monitored, AnalysisStateExternal.Published].includes(
        this.merchant.status,
      )
    ) {
      // pending/monitored/published status can receive any action except reset
      this.customerActions = this.customerActions.filter((action) => action.value !== CustomerAction.Reset);
    } else if (this.merchant.status === AnalysisStateExternal.Terminated) {
      // terminated status can receive ten/fifteen/thirty day warning and reset action
      // terminated can _also_ receive immediate_termination action but only if the current customer action is not immediate_termination
      // we aren't worried about that here, as it should be handled by the check on ResubmittableActions above
      this.customerActions = this.customerActions.filter((action) =>
        [
          CustomerAction.TenDayTermination,
          CustomerAction.FifteenDayTermination,
          CustomerAction.ThirtyDayTermination,
          CustomerAction.Reset,
          CustomerAction.ImmediateTermination,
        ].includes(action.value),
      );
    } else if (this.merchant.status === AnalysisStateExternal.Inactive) {
      // inactive status can only be reset
      this.customerActions = this.customerActions.filter((action) => action.value === CustomerAction.Reset);
    }
  }

  public editAdditionalDetails() {
    this.editingAdditionalInfo = true;
    this.initInfoForm();
  }

  public initInfoForm() {
    this.infoFormErrorMessages = [];
    this.infoFormGroup = this.formBuilder.group({});
    [...this.additionalInfoColumnsLeft, ...this.additionalInfoColumnsRight].forEach((col) => this.addControl(col));
  }

  public cancelInfoForm() {
    this.editingAdditionalInfo = false;
    this.initInfoForm();
  }

  public editField(name: string, value: any) {
    const merchantUpdates = this.getMerchantUpdates({ [name]: value });
    this.updateMerchant(merchantUpdates);
  }

  public editFields() {
    const isValid = this.validateAdditionalInfoFields();
    if (!isValid) {
      return;
    }

    const merchantUpdates = this.getMerchantUpdates(this.infoFormGroup.value);
    this.updateMerchant(merchantUpdates);
  }

  public updateMerchant(merchantUpdates) {
    this.formSubmitLoading = true;

    if (!Object.keys(merchantUpdates).length) {
      this.cancelInfoForm();
      this.formSubmitLoading = false;
      return;
    }

    this.merchantService.updateMerchantDetails(this.correlationId, merchantUpdates).subscribe(
      () => {
        this.store.dispatch(
          MerchantDirtiedAction({
            correlationId: this.correlationId,
            previousUpdateDate: this.merchant.update_date,
            updateRequestTime: Date.now(),
          }),
        );
        this.store.dispatch(MerchantDetailGetAction({ correlationId: this.correlationId }));
        this.cancelInfoForm();
        this.formSubmitLoading = false;
      },
      (err) => {
        let errorMessage = 'Please try again.  If this problem persists, please contact support';
        if (err.error && err.error.message) {
          errorMessage = err.error.message;
        }

        if (errorMessage === COMMON_ERRORS.INVALID_MCC) {
          // instead of getting all the category codes, we let the api validation handle this special case, but make the display match the other individual field errors
          this.infoFormGroup.controls['category_code'].setErrors({
            pattern: true,
          });
          this.showErrors('category_code');
        } else {
          // generic "something went wrong on the server" error path
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Update Error',
              detail: `An error occurred while processing your update request.  ${errorMessage}`,
              sticky: true,
              blocking: true,
            }),
          );
        }

        // Preserve editing state on list if error when user submits
        if (merchantUpdates && merchantUpdates.merchant_domain) {
          this.editingList = true;
        }
        this.formSubmitLoading = false;
      },
    );
  }

  // primeng autocomplete methods
  public search(event: any) {
    this.filteredCountries = [
      ...this.countries.filter((country) => {
        return country.toLowerCase().includes(event.query.toLowerCase());
      }),
    ];
  }

  public selectCountry(event: unknown, column: string) {
    this.infoFormGroup.controls[column].setValue(event);
  }

  public countryValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const originalCountryObj = this.originalCountries.find((country) => country.name === control.value);
      return originalCountryObj ? null : { invalidCountry: { value: control.value } };
    };
  }

  public addControl(col: MMMerchantDetailInfoColumn) {
    const { properties, validators, type } = col;
    let initValue = this.merchant[properties as keyof MyLSMerchant];
    if (col.name === 'Tags') {
      if (isEmpty(initValue)) {
        initValue = [];
      } else {
        initValue = [...(initValue as string[])];
      }
    }

    if (type === 'country' && initValue) {
      const countryObj = this.originalCountries.find(
        (country) =>
          country.twoCharacters === this.merchant[properties as keyof MyLSMerchant] ||
          country.threeCharacters === this.merchant[properties as keyof MyLSMerchant],
      );
      initValue = countryObj ? countryObj.name : null;
    }
    this.infoFormGroup.addControl(properties, this.formBuilder.control(initValue, validators));
  }

  public formControlFor(column: MMMerchantDetailInfoColumn): FormControl {
    return this.infoFormGroup.controls[column.properties] as FormControl;
  }

  public setCountryCodes(updatedProperties: { [key: string]: string }) {
    this.countryFields.forEach((prop) => {
      const newValue = updatedProperties[prop];
      if (newValue) {
        const originalCountryObj = this.originalCountries.find((country) => country.name === newValue);
        updatedProperties[prop] = originalCountryObj.twoCharacters; // country code maps from the UI name to 2 char code.
      }
    });
  }

  public validateAdditionalInfoFields(): boolean {
    if (this.tagEditor && this.tagEditor.isFocused) {
      return false;
    }

    this.infoFormErrorMessages = [];
    if (!this.canEdit) {
      return false;
    }

    this.firstTryInfo = false;
    if (this.infoFormGroup.invalid) {
      for (const control in this.infoFormGroup.controls) {
        this.showErrors(control);
      }
      return false;
    }
    return true;
  }

  public updateEditing(editingList: boolean) {
    this.editingList = editingList;
  }

  // We do list updating separate from editField since the logic doesn't account for nested properties
  public updateDomainFromAcquirerList(list: string[]) {
    const invalidUrl = MMMerchantDetailComponent.getInvalidUrl(list);

    if (invalidUrl) {
      this.domainUpdateValidationError = `Invalid Url: ${invalidUrl}`;
    } else {
      const merchantDomain: MyLSDomain[] = list.map((x) => ({
        domain_from_acquirer: x,
      }));
      // merchant_domain singular is the name of the merchant domain array property in MyLSMerchant
      this.updateMerchant({ merchant_domain: merchantDomain });
      this.domainUpdateValidationError = '';
    }
  }

  private showErrors(control: string) {
    const controlObj = this.infoFormGroup.controls[control];
    if (controlObj.invalid && controlObj.errors) {
      const propertyCol = [...this.additionalInfoColumnsRight, ...this.additionalInfoColumnsLeft].find((col) => {
        return col.properties === control;
      });

      this.infoFormErrorMessages.push({
        severity: SeverityOptions.ERROR,
        summary: propertyCol.name,
        detail: this.getErrorDetail(controlObj),
      });
    }
  }

  private getErrorDetail(controlObj: AbstractControl): string {
    const { maxlength, minlength, pattern, required, invalidCountry } = controlObj.errors;
    let detail = '';

    if (maxlength) {
      detail = `must be ${maxlength.requiredLength} characters or less`;
    } else if (minlength) {
      detail = `must be ${minlength.requiredLength} characters or more`;
    } else if (pattern) {
      // currently this is just to ensure the MCC is a 4 digit value
      detail = `must be a valid 4 digit code`;
    } else if (required) {
      detail = `is required`;
    } else if (invalidCountry) {
      detail = `must be a valid country - please select from the autocomplete list`;
    }

    return detail;
  }

  private getMerchantUpdates(updateObj: { [key: string]: string }) {
    this.setCountryCodes(updateObj);

    const merchantUpdates: any = Object.keys(updateObj).reduce((acc: { [key: string]: unknown }, cur) => {
      if (updateObj[cur] !== null) {
        const val = this.merchant[cur as keyof MyLSMerchant] as string[];
        const isUpdated = Array.isArray(updateObj[cur])
          ? !isEqual(sortBy(updateObj[cur]), sortBy(val))
          : !isEqual(updateObj[cur], val);

        if (isUpdated) {
          acc[cur] = updateObj[cur];
        }
      }
      return acc;
    }, {});

    // schema validation on mcc requires integer
    // eslint-disable-next-line no-prototype-builtins
    if (merchantUpdates.hasOwnProperty('category_code')) {
      // mcc is not required - but if being removed on update needs to be null to satisfy api schema validation
      merchantUpdates.category_code = merchantUpdates.category_code === '' ? null : +merchantUpdates.category_code;
    }

    return merchantUpdates;
  }

  private mapDomainsToLists(merchantDomain: MyLSMerchant['merchant_domain']) {
    if (!merchantDomain || !merchantDomain.length) {
      return;
    }

    // Clear the list to avoid dupe issues
    this.authoritativeDomainList = [];
    this.domainFromAcquirerList = [];

    for (const { authoritative_domain, domain_from_acquirer } of merchantDomain) {
      this.authoritativeDomainList.push(authoritative_domain);
      this.domainFromAcquirerList.push(domain_from_acquirer);
    }
  }

  public clearDomainFromAcquirerError() {
    this.domainUpdateValidationError = '';
  }
}
