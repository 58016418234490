import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Notification } from '../../models';

export const notificationsActions = createActionGroup({
  source: 'Notifications',
  events: {
    'Load Notifications': emptyProps(),
    'Set Notifications': props<{ notifications: Notification[] }>(),
    'Mark Notification Read': props<{ notification: Notification }>(),
    'Mark All Notifications Read': emptyProps(),
    'Delete Notification': props<{ notification: Notification }>(),
    'Delete All Notifications': emptyProps(),
    'Undo Delete': emptyProps(),
    'Stop Notifications': emptyProps(),
    'Stop Notifications Success': emptyProps(),
    Noop: emptyProps(),
  },
});
