import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { MMUploadService } from '../services';
import {
  MerchantMonitoringBulkUploadAction,
  MerchantMonitoringBulkUploadErrorAction,
  MerchantMonitoringBulkUploadProgressAction,
  MerchantMonitoringBulkUploadSuccessAction,
  MerchantMonitoringBulkUploadValidationCompleteAction,
  MerchantMonitoringBulkUploadValidationErrorAction,
  MerchantMonitoringBulkUploadValidationStartAction,
} from '../reducers/bulk-upload.reducer';
import { catchError, map, of, switchMap } from 'rxjs';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { SpreadSheetFileReader } from '../services/spreadsheet-file-reader';
import { ValidationResult } from '../SharedCustomTypes/validation-result';
import { UploadWorkbookValidator } from '../services/validators/upload-workbook-validator';

@Injectable()
export class BulkUploadEffect {
  constructor(
    private actions$: Actions,
    private uploadService: MMUploadService,
  ) {}

  public validateUploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantMonitoringBulkUploadValidationStartAction),
      switchMap((action) => SpreadSheetFileReader.getWorkbook(action.file)),
      map((workbook) => {
        const validationResult: ValidationResult = UploadWorkbookValidator.validate(workbook);
        return MerchantMonitoringBulkUploadValidationCompleteAction({
          succeeded: validationResult.success,
          message: validationResult.validationError ?? '',
        });
      }),
      catchError((error: Error) => of(MerchantMonitoringBulkUploadValidationErrorAction({ error: error }))),
    ),
  );

  public upload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantMonitoringBulkUploadAction),
      switchMap((action) =>
        this.uploadService.uploadSpreadsheet(action.file).pipe(
          map((event) => {
            if (event.type === HttpEventType.UploadProgress) {
              console.log({ percent: Math.floor((event.loaded / event.total) * 100), status: event.type });
              return MerchantMonitoringBulkUploadProgressAction({
                progress: { percent: Math.floor((event.loaded / event.total) * 100), status: event.type },
              });
            } else if (event.type === HttpEventType.Response) {
              console.log({ percent: 100, status: event.type });
              return MerchantMonitoringBulkUploadProgressAction({ progress: { percent: 100, status: event.type } });
            }
            return MerchantMonitoringBulkUploadProgressAction({ progress: { percent: 0, status: event.type } });
          }),
        ),
      ),
    ),
  );

  public uploadProgress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MerchantMonitoringBulkUploadProgressAction),
      map((action) => {
        if (action.progress.status === HttpEventType.Response) {
          return MerchantMonitoringBulkUploadSuccessAction();
        }
      }),
      catchError((err: HttpErrorResponse) => of(MerchantMonitoringBulkUploadErrorAction({ err: err.error.message }))),
    );
  });

  public uploadFailures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MerchantMonitoringBulkUploadErrorAction),
      map((errorAction) =>
        GenericNotificationAction({
          severity: SeverityOptions.ERROR,
          summary: 'There was a problem uploading',
          detail: errorAction.err,
          sticky: false,
          blocking: false,
        }),
      ),
    );
  });
}
