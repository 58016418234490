import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserSignOutAction, AppState, AccountState, AuthenticationState } from '../../reducers';
import { Observable, Subject } from 'rxjs';
import { EntitlementsState } from '@ls/common-ng-components';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { ProductFamily, Roles, inMyLS } from '@ls/common-ts-models';
/* tslint:disable no-var-requires */
import { isFeatureAvailable } from '../../../../config/helpers';
import { environment } from 'src/environments/environment';

// TODO: Can remove all of this extra entitlements checking after Docs are available to _all_ users
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  public canViewMMDocs = false;
  public notificationsEnabled = false;
  public selectorVisible = false;
  private entitlements$: Observable<EntitlementsState>;
  private destroyed$: Subject<boolean> = new Subject();
  public isHybrydUser = false;
  public iconSource = '../../../assets/icon/badge-check-grey.svg';
  private defaultIconSource = '../../../assets/icon/badge-check-grey.svg';
  private hoverIconSource = '../../../assets/icon/badge-check-white.svg';

  constructor(
    private router: Router,
    private store: Store<AppState>,
  ) {}

  public openDocs() {
    this.router.navigate(['/account/docs']);
  }

  public openHelp() {
    window.open('https://help.legitscript.com', '_blank');
  }

  public signOutClick() {
    this.store.dispatch(UserSignOutAction({ location: '/login' }));
  }

  public ngOnInit() {
    this.entitlements$ = this.store.select('entitlements').pipe(
      skipWhile((entitlements) => entitlements.pending),
      takeUntil(this.destroyed$),
    );

    this.entitlements$.subscribe((entitlements) => {
      if (entitlements && entitlements.products && entitlements.products[ProductFamily.MERCHANT_MONITORING]) {
        const opportunity = entitlements.products[ProductFamily.MERCHANT_MONITORING].opportunity;
        this.canViewMMDocs = opportunity ? true : false;
        if (this.canViewMMDocs) {
          this.store
            .select('account')
            .pipe(takeUntil(this.destroyed$.asObservable()))
            .subscribe((accountState: AccountState) => {
              if (accountState) {
                this.notificationsEnabled = isFeatureAvailable('notificationsEnabled', accountState.lsAccountId);
              }
            });
        }
      }
    });

    const initialLoadComplete$: Subject<boolean> = new Subject();
    this.store
      .select('authentication')
      .pipe(takeUntil(initialLoadComplete$))
      .subscribe((authentication: AuthenticationState) => {
        if (authentication.pending || authentication.errorText) {
          return;
        }

        if (authentication.user.roles) {
          if (authentication.user.roles.includes(Roles.cpv2AccountOwnerSF)) {
            this.isHybrydUser = true;
          }
        }

        if (authentication.user.accountIds && authentication.user.accountIds.length > 1) {
          this.selectorVisible = true;
        }
      });

    // Authentication complete, don't listen for future events
    initialLoadComplete$.next(true);
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public moveToEC() {
    if (localStorage.getItem(inMyLS) === 'true' && this.isHybrydUser) {
      localStorage.setItem(inMyLS, 'false');
      window.location.href = environment.CONFIG.experienceCloudURL;
    }
  }

  // switches the icon image when the user hovers over the icon
  public switchToHoverIcon(): void {
    this.iconSource = this.hoverIconSource;
  }

  // switches the icon back to the default image when the user stops hovering over the icon
  public switchToDefaultIcon(): void {
    this.iconSource = this.defaultIconSource;
  }
}
