import { createAction, createReducer, on, props } from '@ngrx/store';
import {
  WBFilterSortDirection,
  MyLsFilterGroup,
  MyLSListMerchantCheckbox,
  MyLSMerchantSortFieldKeys,
} from '@ls/common-ts-models';

export const MERCHANT_LIST_CHECKBOX = '[MerchantList] Checkbox';
export const MERCHANT_LIST_CHILD_CHECKBOX = '[MerchantList] Child Checkbox';
export const MERCHANT_LIST_GLOBAL_CHECKBOX = '[MerchantList] Global Checkbox';
export const MERCHANT_LIST_CHECKBOX_SUCCESS = '[MerchantList] Checkbox Success';
export const MERCHANT_LIST_CHECKBOX_CLEAR = '[MerchantList] Checkbox Clear';

export const MerchantListCheckboxAction = createAction(
  MERCHANT_LIST_CHECKBOX,
  props<{
    sortField: MyLSMerchantSortFieldKeys;
    sortDirection: WBFilterSortDirection;
    filters: MyLsFilterGroup;
    actionPositions: MyLSListMerchantCheckbox[];
    globalCheckbox: boolean;
    totalRecords: number;
  }>(),
);

export const MerchantListChildCheckboxAction = createAction(
  MERCHANT_LIST_CHILD_CHECKBOX,
  props<{
    actionPositions: MyLSListMerchantCheckbox[];
    globalCheckbox: boolean;
    totalRecords: number;
    checkboxIndex: number;
  }>(),
);

export const MerchantListGlobalCheckboxAction = createAction(
  MERCHANT_LIST_GLOBAL_CHECKBOX,
  props<{
    actionPositions: MyLSListMerchantCheckbox[];
    globalCheckbox: boolean;
    totalRecords: number;
  }>(),
);

export const MerchantListCheckboxSuccessAction = createAction(
  MERCHANT_LIST_CHECKBOX_SUCCESS,
  props<{
    actionPositions: MyLSListMerchantCheckbox[];
    globalCheckbox: boolean;
    selectedMerchantNumber: number;
    bulkActionEnabled: boolean;
  }>(),
);

export const MerchantListCheckboxClearAction = createAction(MERCHANT_LIST_CHECKBOX_CLEAR);

export interface MerchantListCheckboxState {
  globalCheckbox: boolean;
  actionPositions: MyLSListMerchantCheckbox[];
  actionPositionLoaded: boolean;
  bulkActionEnabled: boolean;
  selectedMerchantNumber: number;
}

export const initialMerchantListCheckboxState: MerchantListCheckboxState = {
  globalCheckbox: false,
  actionPositions: [],
  actionPositionLoaded: false,
  bulkActionEnabled: false,
  selectedMerchantNumber: 0,
};

export const MerchantListCheckboxReducer = createReducer(
  initialMerchantListCheckboxState,

  on(MerchantListChildCheckboxAction, (state, { globalCheckbox, actionPositions }) => ({
    ...state,
    globalCheckbox,
    actionPositions,
  })),

  on(MerchantListGlobalCheckboxAction, (state, { globalCheckbox, actionPositions }) => ({
    ...state,
    globalCheckbox,
    actionPositions,
  })),

  on(
    MerchantListCheckboxSuccessAction,
    (state, { globalCheckbox, actionPositions, selectedMerchantNumber, bulkActionEnabled }) => ({
      ...state,
      globalCheckbox,
      actionPositions,
      selectedMerchantNumber,
      bulkActionEnabled,
      actionPositionLoaded: true,
    }),
  ),

  on(MerchantListCheckboxAction, (state, { globalCheckbox, actionPositions }) => ({
    ...state,
    globalCheckbox,
    actionPositions,
    actionPositionLoaded: false,
  })),

  on(MerchantListCheckboxClearAction, (state) => ({
    ...state,
    globalCheckbox: false,
    actionPositions: state.actionPositions.map((actionPos) => {
      actionPos.checked = false;
      return actionPos;
    }),
  })),
);

//
// STATE SELECTORS
//
export const fnMerchantListCheckboxState = (state: any) => state.merchantMonitoring.merchantCheckbox;
