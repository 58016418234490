import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TransactionsService } from '../angular-client';
import { Observable, map, mergeMap } from 'rxjs';

@Injectable()
export class S3Service {
  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(TransactionsService) private transactionsService: TransactionsService,
  ) {}

  public uploadFile(
    transactionId: string,
    file: File,
    authHeader: string,
  ): Observable<{ res: Response; fileInfo: { name: string; awsName: string }; attachmentId: string }> {
    let awsName: string;
    let attachmentId: string;
    // get pre-signed cert / file name from service.
    return this.transactionsService
      .uploadAttachment({ id: transactionId, UploadFileRequest: { name: file.name }, Authorization: authHeader })
      .pipe(
        mergeMap((response) => {
          awsName = response.name;
          attachmentId = response.id;

          const formData = new FormData();

          // build the multipart form that S3 requires
          Object.keys(response.fields).forEach((field) => formData.append(field, response.fields[field]));
          formData.append('file', file, response.name); // add the file
          return this.http.post<any>(response.url, formData); // upload to S3
        }),
        map((res: Response) => ({ res, fileInfo: { name: file.name, awsName }, attachmentId })),
      );
  }
}
