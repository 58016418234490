import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  AfterViewChecked,
  SimpleChanges,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { AnsweredQuestion, INPUT_TYPE, AnsweredInput, Question } from '@ls/common-ts-models';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { QuestionnaireUploadFilesAction } from '../reducers/';
import { AppState } from 'src/app/reducers';

@Component({
  selector: 'question',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './certification-question.component.html',
  styleUrls: ['../certification-questionnaire-wrapper/certification-questionnaire-wrapper.component.scss'],
})
export class CertificationQuestionComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  public wrapperStyles: unknown = {};
  public ignoreItem: FormControl = new FormControl();
  public questionForm: FormGroup = new FormGroup({
    checkIgnore: this.ignoreItem,
  });
  @Input() public question: Question;
  @Input() public answer: AnsweredQuestion;

  @Input() public serverError: string | null;
  @Input() public pending: boolean;
  @Input() public outerForm: FormGroup;
  @Input() public saveClicked = false;
  @Output() public handleChanges = new EventEmitter<object>();

  public answeredInputs: AnsweredInput[];
  public ignoreItemSubscription: Subscription;
  public showTextArea = true;

  constructor(
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    public store: Store<AppState>,
  ) {}

  public ngOnInit() {
    this.outerForm.removeControl('parentForm');
    this.questionForm.setParent(this.outerForm);
    this.outerForm.addControl('parentForm', this.questionForm);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.pending) {
      return;
    }

    if (this.answer) {
      this.ignoreItem.setValue(Boolean(this.answer.not_applicable));
    }

    if (this.question && !this.answer?.id) {
      this.answer = this.createNewAnswerObj();
      this.answeredInputs = this.answer.answered_inputs;
      this.ignoreItem.setValue(false);
    }

    if (this.question) {
      this.setWrapperColumns(this.question.inputs.length);
    }

    if (
      changes['question'] &&
      changes['question'].previousValue !== null &&
      changes['question'].previousValue !== undefined
    ) {
      // we changed questions (but same question type), clear the form and reset controls
      this.outerForm.removeControl('parentForm');
      this.questionForm = new FormGroup({
        checkIgnore: this.ignoreItem,
      });
      this.questionForm.setParent(this.outerForm);
      this.outerForm.addControl('parentForm', this.questionForm);
      this.cdr.detectChanges();
    }

    this.ignoreItemSubscription = this.ignoreItem.valueChanges.subscribe((value) => {
      if (this.answer) {
        this.answer.not_applicable = value;
      }
    });

    this.showTextArea = true; // should always be true unless textarea is included with a multi_select 'other' option
  }

  public ngAfterViewChecked() {
    // this question has multiple inputs and an 'other' option, update config based on form value
    if (
      this.question &&
      (this.question.question_type === 'multi_input_other' ||
        (this.question.question_type === INPUT_TYPE.YES_NO && this.question.triggerOption)) &&
      this.answer
    ) {
      this.configureOptionalField(this.answer);
    }
  }

  public ngOnDestroy() {
    if (this.ignoreItemSubscription) {
      this.ignoreItemSubscription.unsubscribe();
    }
  }

  public handleQuestionChanges(answer) {
    const answerUpdated = this.answer.answered_inputs.find((input) => input.input_id === answer.input_id);
    if (answerUpdated) {
      answerUpdated.answer_text = answer.answer_text;
    }
    this.handleChanges.emit({ answer: this.answer });
  }

  public getAnswerInput(input: any) {
    // check if there is an answer and if this id appears in the answered_inputs
    if (this.answer) {
      if (this.answer.answered_inputs.find((ansInput) => ansInput.input_id === input.id)) {
        return this.answer.answered_inputs.find((ansInput) => ansInput.input_id === input.id);
      } else {
        const newInput = this.createAnsweredInput(input);
        this.answer.answered_inputs.push(newInput);
        return newInput;
      }
    }
  }

  public handleFileSelection(event: File[]) {
    this.store.dispatch(QuestionnaireUploadFilesAction({ files: [...event] }));
    this.cdr.detectChanges();
  }

  private createNewAnswerObj(): AnsweredQuestion {
    const currentAnswer = {
      id: crypto.randomUUID(),
      question_id: this.question.id,
      input_type: this.question.question_type,
      answered_inputs: [],
      not_applicable: false,
    } as AnsweredQuestion;
    return currentAnswer;
  }

  private createAnsweredInput(input): AnsweredInput {
    return {
      id: crypto.randomUUID(),
      input_id: input.id,
      input_type: input.input_type,
      answer_text: '',
    } as AnsweredInput;
  }

  private configureOptionalField(answer: AnsweredQuestion) {
    // find id of optional field (last in inputs array for now) and get the associated form control
    const optionalInput = this.question.inputs[this.question.inputs.length - 1];
    const optionalFormField = this.questionForm.get(optionalInput.id);
    this.showTextArea = true;

    // we have to wait for the optional input form field to be created by the child component
    if (!optionalFormField) {
      return;
    }

    // check array of answers (or answer) from select list- if 'Other' is present, we need to enable the text box
    const selectElement = answer.answered_inputs.find(
      (input) =>
        input.input_type === 'multi_select' || input.input_type === 'single_select' || input.input_type === 'yes_no',
    );
    const optionalElement = answer.answered_inputs.find((input) => input.input_type === 'textbox');

    if (selectElement.input_type === 'yes_no') {
      if (this.question.triggerOption === answer.answered_inputs[0].answer_text) {
        this.showTextArea = true;
        optionalFormField.enable();
      } else {
        optionalFormField.reset();
        optionalElement.answer_text = '';
        optionalFormField.disable();
        this.showTextArea = false;
      }
    } else {
      // if there are no answers yet, 'other' textbox should be hidden
      if (!selectElement.selected_value_ids) {
        optionalFormField.disable();
        this.showTextArea = false;
        return;
      } else {
        const hasOtherInAnswer = selectElement.selected_value_ids[0].indexOf('Other') !== -1;
        if (hasOtherInAnswer) {
          this.showTextArea = true;
          optionalFormField.enable();
        } else {
          optionalFormField.reset();
          optionalElement.answer_text = '';
          optionalFormField.disable();
          this.showTextArea = false;
        }
      }
    }
  }

  private setWrapperColumns(total: number) {
    // we don't know how many columns there will be, get it from the total inputs for this question so the grid display is correct
    // 4 or less can generally fit on a row, more and we break into groups of 3.
    let totalFrs = '';

    if (total <= 4) {
      for (let i = 0; i < total; i += 1) {
        totalFrs += '1fr ';
      }
    } else {
      totalFrs = '1fr 1fr 1fr';
    }

    this.wrapperStyles = {
      'grid-template-columns': `${totalFrs}`,
    };
  }
}
