import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CertificationsGetSuccessAction, CertificationsGetErrorAction, CertificationsGetAction } from '../reducers';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { CertificationService } from '../services';
import { AppState } from 'src/app/reducers';

@Injectable()
export class CertificationsEffect {
  public certificationsGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationsGetAction),
      mergeMap(() => {
        return this.certificationService.getCertificationsForAccount().pipe(
          map((response) => CertificationsGetSuccessAction({ list: response })),
          catchError((error) => {
            if (error.error.statusCode !== 401) {
              this.store.dispatch(
                GenericNotificationAction({
                  severity: SeverityOptions.WARN,
                  summary: 'Problem Loading Certifications',
                  detail:
                    'An issue occured while loading your certifications.  If this problem persists, please contact support',
                  sticky: true,
                  blocking: true,
                }),
              );
            }
            return observableOf(CertificationsGetErrorAction({ errorText: error.message }));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private certificationService: CertificationService,
  ) {}
}
