<div class="merchant-list-container">
  <loading-spinner class="loading-container" *ngIf="isInitiallyBusy || filterLoading"
    loadingText="Loading Merchant List"></loading-spinner>
  <div class="widgets-container" *ngIf="(!isInitiallyBusy && !filterLoading)">
    <div class="merchants-panel widget full-widget">
      <div class="widget-header">
        <span class="no-icon">
          <h1>Merchant List</h1>
        </span>
        <span>
          <button *ngIf="uploadHistoryEnabled && isServiceAccessEnabled" routerLink="/services/merchant-monitoring/upload-history">
            <i class="fa fa-history" aria-hidden="true"></i>
            Upload History
          </button>
          <button *ngIf="isServiceAccessEnabled" routerLink="/services/merchant-monitoring/upload">
            <i class="fa fa-upload" aria-hidden="true"></i>
            Upload Merchants
          </button>
          <button (click)="handleS3DownloadRequest()" [disabled]="!pagination.totalRecords || downloadInProgress">
            <span *ngIf="downloadInProgress; else defaultDownloadButton">
              Processing Request
            </span>
            <ng-template #defaultDownloadButton>
              <i class="fa fa-download" aria-hidden="true"></i>
              Download Merchants
            </ng-template>
          </button>
        </span>
      </div>
      <div class="widget-body centered">
        <section class="filters">
          <merchant-filters (filterEmitter)="onFilterChange($event)" [icas]="icas" [contentLabels]="contentLabels"></merchant-filters>
        </section>
        <section class="filtered-records">
          <span class="records-body">
            <button *ngIf= "merchantCheckboxState.selectedMerchantNumber === 0; else elseBlock" class="total-records">Total Records: {{pagination.totalRecords}}</button>
            <ng-template #elseBlock><button class="selected-records">Selected Records: {{merchantCheckboxState.selectedMerchantNumber}}</button></ng-template>
          </span>
          <span pTooltip="You have no merchants selected." *ngIf="bulkEnabled" [tooltipDisabled]="merchantCheckboxState.selectedMerchantNumber > 0" tooltipPosition="left">
            <p-dropdown #dropActions [style]="{'width':'270px', 'font-size': '15px', 'padding': '0'}" [options]="customerActions" (onChange)= "handleShowConfirmBulkAction()" 
            [(ngModel)]='currentAction' [disabled]="merchantCheckboxState.selectedMerchantNumber === 0" placeholder="Take action on selected merchants">
            </p-dropdown>
            <confirm-bulk-action [dropdown]= "dropActions" [merchantsToProcess] = "getSelectedMerchants()" [filters] = "merchantsState.filters" [action]= "currentAction" [merchantNumber]="merchantCheckboxState.selectedMerchantNumber === 0 ? pagination.totalRecords : merchantCheckboxState.selectedMerchantNumber"></confirm-bulk-action>
          </span>
        </section>
        <p-table class="prime-table" [responsive]="true" [loading]="merchantsState.pending"
          [sortOrder]="primeNgSortDirection" [sortField]="primeNgSortField" selectionMode="single"
          [value]="merchantsState.merchants" [columns]="columns" [paginator]="true" [rows]="pageSize"
          [totalRecords]="pagination.totalRecords" (onLazyLoad)="loadMerchantsLazy($event)" [scrollable]="true"
          scrollHeight='100%' scrollWidth='100%' [lazyLoadOnInit]="false" [resizableColumns]="true"
          columnResizeMode="expand" [lazy]="true">
          <div class="merchant-header">
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngIf="bulkEnabled">
                <col *ngFor="let col of columns">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th class="checkbox-column"  *ngIf="bulkEnabled" pTooltip="Disabled Button" pTooltip="You cannot select more than 4000 merchants." [tooltipDisabled]="this.merchantCheckboxState.bulkActionEnabled" tooltipPosition="left">
                  <input type="checkbox" class="list-checkbox" [checked]="merchantCheckboxState.globalCheckbox" [value]="merchantCheckboxState.globalCheckbox" 
                  [disabled]="!merchantCheckboxState.actionPositionLoaded || !this.merchantCheckboxState.bulkActionEnabled" (click)= "handleGlobalCheckboxClick()">
                </th>
                <th *ngFor="let col of columns" pResizableColumn class="header-no-wrap" [title]="col.header"
                  [pSortableColumn]="!unsortableFields.includes(col.field) ? col.field : ''" [style.width]="col.width">
                  <p-sortIcon *ngIf="!unsortableFields.includes(col.field)" [field]="col.field"></p-sortIcon>
                  {{col.header}}
                </th>
              </tr>
            </ng-template>
          </div>
          <ng-template pTemplate="body" let-merchant let-columns="columns" let-i="rowIndex">
            <tr [ngClass]="{'dirty-merchant-row': isMerchantDirty(merchant.correlation_id)}" class="merchant-table"
              pTooltip="Please note, a recent action or update is currently pending and may not be reflected in listed results."
              [tooltipDisabled]="!isMerchantDirty(merchant.correlation_id)">
              <td class="checkbox-column" *ngIf="bulkEnabled">
                <input type="checkbox" class="list-checkbox" [checked]="getCheckboxPosition(i)" [value]="getCheckboxPosition(i)" [disabled]="!merchantCheckboxState.actionPositionLoaded" (click)= "handleChildCheckboxClick(i)">
              </td>
              <td *ngFor="let col of columns" (click)="handleRowClick(merchant)" class="ui-resizable-column">
                <ng-container [ngSwitch]="col.field">
                  <ng-container *ngSwitchCase="'ls_actions'">
                    <mm-ls-risk-action-labels [emptyPlaceholder]="emptyFieldPlaceHolder"
                      [lsRiskActions]="merchant[col.field]"></mm-ls-risk-action-labels>
                  </ng-container>
                  <ng-container *ngSwitchCase="'url'">
                    <div *ngIf="!multipleMerchantURLs(merchant, col.field)">
                      <a [href]="merchant[col.field]" [title]="merchant[col.field]" class="ellipsis-clamp-4-lines"
                      (click)="$event.stopPropagation()" target="_blank">{{merchant[col.field]}}</a>
                    </div>
                    <div *ngIf="multipleMerchantURLs(merchant, col.field)" class="multi-url-chip">{{merchant[col.field].length}}</div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    {{getFieldValue(merchant, col.field)}}
                  </ng-container>
                </ng-container>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr class="merchant-table">
              <td [attr.colspan]="columns.length" class="empty-message">
                <span>No Records Found</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>