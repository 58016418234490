import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@ls/common-ng-components';
import {
  CustomerActionRequest,
  MyLSMerchant,
  WBFilterSortDirection,
  MyLsFilterGroup,
  MyLSMerchantConstants,
  MyLSMerchantSortFieldKeys,
} from '@ls/common-ts-models';
import { environment } from 'src/environments/environment';

@Injectable()
export class MMMerchantService {
  private mmApiHost = environment.CONFIG.mmApiHost;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {}

  public listMerchants(
    sortField: MyLSMerchantSortFieldKeys,
    sortDirection: WBFilterSortDirection,
    pageNumber: number,
    pageSize: number,
    filters: MyLsFilterGroup,
  ): Observable<any> {
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '';
    const sortFieldParam = sortField ? `&sortField=${sortField}` : '&sortField=submitted_date';

    return this.http.post(
      `${this.mmApiHost}/merchants?pageSize=${
        pageSize ? pageSize : MyLSMerchantConstants.MAX_MERCHANTS_PER_PAGE
      }&pageNumber=${pageNumber ? pageNumber : 1}${sortDirectionParam}${sortFieldParam}`,
      filters,
      this.localStorageService.getJWTAndSetOptions(),
    );
  }

  public listCorrelationIds(
    sortField: MyLSMerchantSortFieldKeys,
    sortDirection: WBFilterSortDirection,
    filters: MyLsFilterGroup,
  ): Observable<any> {
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '';
    const sortFieldParam = sortField ? `&sortField=${sortField}` : '&sortField=submitted_date';

    return this.http.post(
      `${this.mmApiHost}/correlationids?${sortDirectionParam}${sortFieldParam}`,
      filters,
      this.localStorageService.getJWTAndSetOptions(),
    );
  }

  public listMerchantICAs(): Observable<any> {
    return this.http.get(`${this.mmApiHost}/listMerchantICAs`, this.localStorageService.getJWTAndSetOptions());
  }

  public getMerchant(correlationId: string): Observable<MyLSMerchant> {
    return this.http.get<MyLSMerchant>(
      `${this.mmApiHost}/merchant/${correlationId}`,
      this.localStorageService.getJWTAndSetOptions(),
    );
  }

  public updateCustomerAction(correlationId: string, request: CustomerActionRequest): Observable<any> {
    return this.http.post(
      `${this.mmApiHost}/merchant/customerAction/${correlationId}`,
      request,
      this.localStorageService.getJWTAndSetOptions(),
    );
  }

  public updateBulkCustomerAction(
    filters: MyLsFilterGroup,
    correlationIds: string[],
    actionRequest: CustomerActionRequest,
  ): Observable<any> {
    const localDate = new Date();
    const offset = localDate.getTimezoneOffset(); // Getting the UTC time difference from browser
    let request;
    if (correlationIds.length > 0) {
      request = {
        comment: actionRequest.comment,
        action: actionRequest.action,
        correlationIds,
        timeZoneOffset: offset,
      };
    } else {
      request = {
        comment: actionRequest.comment,
        action: actionRequest.action,
        filters: filters.filters,
        timeZoneOffset: offset,
      };
    }

    return this.http.post(
      `${this.mmApiHost}/merchant/customerAction`,
      request,
      this.localStorageService.getJWTAndSetOptions(),
    );
  }

  public updateMerchantDetails(correlationId: string, request: any): Observable<any> {
    //The api is rejecting null values on non-required fields. To prevent that we convert nulls to empty strings.
    const merchantWithConvertedNulls: MyLSMerchant = JSON.parse(JSON.stringify(request), (key, value) => {
      return value === null ? '' : value;
    });
    const putMCCBackFromRequest: MyLSMerchant = {
      ...merchantWithConvertedNulls,
      category_code: request.category_code ? parseInt(request.category_code, 10) : null,
    };
    return this.http.put(
      `${this.mmApiHost}/merchant/${correlationId}`,
      putMCCBackFromRequest,
      this.localStorageService.getJWTAndSetOptions(),
    );
  }
}
