import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AccountService } from '../../services';
import { Roles, BillingHistory, HISTORY_ITEMS_PER_REQUEST } from '@ls/common-ts-models';
import { LocalStorageService, GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { Subject } from 'rxjs';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'billing-history',
  styleUrls: ['./billing-history.component.scss'],
  templateUrl: './billing-history.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class BillingHistoryComponent implements OnInit, OnDestroy {
  public isOwner = false;
  public loading = true;
  public loadingMore = false;
  public moreHistoryAvailable = false;
  public error = false;
  public destroyed$: Subject<boolean> = new Subject();
  public billingHistory: BillingHistory[];

  public ccIconMap = {
    Visa: 'visa',
    MasterCard: 'mastercard',
    'American Express': 'amex',
    'Diners Club': 'diners-club',
    Discover: 'discover',
    JCB: 'jcb',
  };

  constructor(
    private localStorageSvc: LocalStorageService,
    private accountSvc: AccountService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit() {
    const authUser = this.localStorageSvc.getAuthenticatedUser();
    this.isOwner = authUser.roles && authUser.roles.includes(Roles.cpv2AccountOwner);
    if (this.isOwner) {
      this.getHistory();
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getMoreHistory() {
    const lastId = this.billingHistory[this.billingHistory.length - 1].chargeObjId;
    this.loadingMore = true;
    this.getHistory(lastId);
  }

  public getHistory(lastId?: string) {
    this.accountSvc
      .getBillingHistory(lastId)
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe(
        (history: BillingHistory[]) => {
          let prevHistoryItemTotal;
          if (history) {
            if (this.billingHistory) {
              prevHistoryItemTotal = this.billingHistory.length;
              this.billingHistory = [...this.billingHistory, ...history];
              // we didn't add any new items on a getMoreHistory request: we have loaded all items
              this.moreHistoryAvailable = prevHistoryItemTotal !== this.billingHistory.length;
            } else {
              // this is the initial 'get' request
              this.billingHistory = [...history];
              // we made the request and got back the max number allowed: there _may_ be more items to get
              this.moreHistoryAvailable = this.billingHistory.length === HISTORY_ITEMS_PER_REQUEST;
            }
            this.loading = false;
            this.loadingMore = false;
          }
        },
        () => {
          this.error = true;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Failed to retrieve billing history',
              detail: 'An error occured attempting to fetch the requested history items. Please try again.',
              sticky: false,
              blocking: false,
            }),
          );
          this.loading = false;
          this.loadingMore = false;
        },
      );
  }
}
