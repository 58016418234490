import { Injectable } from '@angular/core';
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CustomerAction } from '@ls/common-ts-models';
import { MMMerchantService } from 'src/app/modules/merchant-monitoring/services';
import { fnMerchantListTableState } from 'src/app/modules/merchant-monitoring/reducers';
import { Store } from '@ngrx/store';
import { merchantListActions } from '../actions/merchant-list.actions';
import { selectMerchantSelectionState } from '../selectors';

@Injectable()
export class MerchantActionEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private merchantService: MMMerchantService,
  ) {}

  $bulkMerchantActionTaken = createEffect(() =>
    this.actions$.pipe(
      ofType(merchantListActions.applyBulkMerchantAction),
      withLatestFrom(this.store.select(fnMerchantListTableState), this.store.select(selectMerchantSelectionState)),
      switchMap((data) => {
        const action = data[0];
        const tableState = data[1];
        const selectionState = data[2];
        if (selectionState.selectAll) {
          // Pass filters through when select all is checked instead of correlation ids
          return this.merchantService.updateBulkCustomerAction(tableState.filters, [], action.actionRequest).pipe(
            map((res: any) => {
              return merchantListActions.applyBulkMerchantActionComplete();
            }),
            catchError((err) => of(merchantListActions.applyBulkMerchantActionError({ err: err }))),
          );
        }
        return this.merchantService
          .updateBulkCustomerAction(
            { filters: [] },
            selectionState.selectedMerchantCorrelationIds,
            action.actionRequest,
          )
          .pipe(
            map((res: any) => {
              return merchantListActions.applyBulkMerchantActionComplete();
            }),
            catchError((err) => of(merchantListActions.applyBulkMerchantActionError({ err: err }))),
          );
      }),
    ),
  );
}
