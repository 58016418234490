import { Component, ViewEncapsulation, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'cert-progress',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './progress-tracker.component.html',
  styleUrls: [
    '../certification-questionnaire-wrapper/certification-questionnaire-wrapper.component.scss',
    './progress-tracker.component.scss',
  ],
})
export class ProgressTrackerComponent implements OnInit, OnChanges {
  @Input() public answers: any;
  @Input() public totalQuestions: number;
  @Input() public pending: boolean;
  @Input() public serverError: string | null;
  @Input() public editing?: boolean = false;
  public complete: number;
  public progressValue = 0;

  public ngOnInit() {
    this.updateInputsFromParent();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(_changes) {
    this.updateInputsFromParent();
  }

  private updateInputsFromParent() {
    if (this.answers) {
      this.complete = this.answers.filter((answer) => answer.dirty).length;
      this.progressValue = (this.complete / this.totalQuestions) * 100;
    }
  }
}
