import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class MaintenanceModeEnabledGuard implements CanActivate {
  constructor(private router: Router) {}

  public canActivate(): boolean {
    if (!environment.MAINTENANCE_MODE) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
