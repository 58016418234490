import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';
import { AuthenticatedUser, SalesforceAccountInfoResponse } from '@ls/common-ts-models';

export interface ServiceAccess {
  mmProductName: string;
  mmProductId: number;
  mmProductCurrent: boolean;
  mmProductEnabled: boolean;
  mmProductAccessType: string;
  websitesProductName: string;
  websitesProductId: number;
  websitesProductCurrent: boolean;
  websitesProductEnabled: boolean;
  websitesProductAccessType: string;
  productsProductName: string;
  productsProductId: number;
  productsProductCurrent: boolean;
  productsProductEnabled: boolean;
  productsProductAccessType: string;
  testTransactionProductName: string;
  testTransactionProductId: number;
  testTransactionProductCurrent: boolean;
  testTransactionProductEnabled: boolean;
  testTransactionProductAccessType: string;
}

const ProductFamilies = {
  MerchantMonitoring: 'Merchant Monitoring',
  Websites: 'Website Database Search',
  ProductsAPI: 'Products API',
  ProductsDB: 'Product Database Search',
  TestTransactions: 'Test Transactions',
};

const IntercomEventType = {
  Initialize: 'initialize',
  Login: 'login',
  Logout: 'logout',
  CustomerData: 'customerData',
  AddUser: 'addUser',
  EditUser: 'editUser',
  WebsitesLookUp: 'websitesLookUp',
  ProductsLookUp: 'productsLookUp',
  NeedsAction: 'needsAction',
  DownloadedMerchantList: 'downloadedMerchantList',
  UserGuide: 'userGuide',
  APIV3Guide: 'apiV3Guide',
  APIV4Guide: 'apiV4Guide',
};

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private window;

  constructor(private _windowReferenceService: WindowReferenceService) {
    this.window = _windowReferenceService.nativeWindow;
  }

  // Push given object to the data layer
  private pushEvent(obj: any): void {
    if (obj) {
      this.window.dataLayer.push(obj);
    }
  }

  // Push generic event to the data layer
  public logEvent(event: any, key: any, value: any) {
    this.pushEvent({
      event: event,
      key: key,
      value: value,
    });
  }

  // Push initial data to the data layer in a Initialize event
  public logInitializeEvent(user: AuthenticatedUser) {
    // null check the user
    if (!user) {
      return;
    }

    this.pushEvent({
      event: IntercomEventType.Initialize,
      account: {
        lsAccountId: user.accountIds,
      },
      user: {
        lsUserId: user.lsUserId,
        name: user.name,
        email: user.email,
        roles: user.roles,
      },
    });
  }

  // Push customer data to the data layer in a customerData event
  public logCustomerDataEvent(customer: SalesforceAccountInfoResponse) {
    let account = null;
    let contact = null;
    // null check the customer's account
    if (customer.account) {
      account = {
        address: customer.account.address,
        sfAccountId: customer.account.sfAccountId,
        lsAccountName: customer.account.name,
      };
    }
    // null check the customer's contact
    if (customer.contact) {
      contact = {
        position: customer.contact.position,
        sfContactId: customer.contact.sfContactId,
        isActive: customer.contact.isActive,
        hasOptedOutOfEmail: customer.contact.hasOptedOutOfEmail,
      };
    }

    this.pushEvent({
      event: IntercomEventType.CustomerData,
      account,
      contact,
      ...this.mapServiceAccesses(customer.serviceAccesses),
    });
  }

  public mapServiceAccesses(serviceAccesses: any) {
    const serviceAccessObj: ServiceAccess = {
      mmProductName: '',
      mmProductId: null,
      mmProductCurrent: null,
      mmProductEnabled: null,
      mmProductAccessType: '',
      websitesProductName: '',
      websitesProductId: null,
      websitesProductCurrent: null,
      websitesProductEnabled: null,
      websitesProductAccessType: '',
      productsProductName: '',
      productsProductId: null,
      productsProductCurrent: null,
      productsProductEnabled: null,
      productsProductAccessType: '',
      testTransactionProductName: '',
      testTransactionProductId: null,
      testTransactionProductCurrent: null,
      testTransactionProductEnabled: null,
      testTransactionProductAccessType: '',
    };

    const mmProducts = serviceAccesses.filter((service) => service.family === ProductFamilies.MerchantMonitoring);
    if (mmProducts.length > 0) {
      mmProducts.sort((a, b) => new Date(b.lastModifiedDate).getTime() - new Date(a.lastModifiedDate).getTime());
      serviceAccessObj.mmProductName = mmProducts[0].name;
      serviceAccessObj.mmProductId = mmProducts[0].productId;
      serviceAccessObj.mmProductCurrent = mmProducts[0].current;
      serviceAccessObj.mmProductEnabled = mmProducts[0].enabled;
      serviceAccessObj.mmProductAccessType = mmProducts[0].accessType;
    }

    const websitesProducts = serviceAccesses.filter((service) => service.family === ProductFamilies.Websites);
    if (websitesProducts.length > 0) {
      websitesProducts.sort((a, b) => new Date(b.lastModifiedDate).getTime() - new Date(a.lastModifiedDate).getTime());
      serviceAccessObj.websitesProductName = websitesProducts[0].name;
      serviceAccessObj.websitesProductId = websitesProducts[0].productId;
      serviceAccessObj.websitesProductCurrent = websitesProducts[0].current;
      serviceAccessObj.websitesProductEnabled = websitesProducts[0].enabled;
      serviceAccessObj.websitesProductAccessType = websitesProducts[0].accessType;
    }

    const productsProducts = serviceAccesses.filter(
      (service) => service.family === ProductFamilies.ProductsAPI || service.family === ProductFamilies.ProductsDB,
    );
    if (productsProducts.length > 0) {
      productsProducts.sort((a, b) => new Date(b.lastModifiedDate).getTime() - new Date(a.lastModifiedDate).getTime());
      serviceAccessObj.productsProductName = productsProducts[0].name;
      serviceAccessObj.productsProductId = productsProducts[0].productId;
      serviceAccessObj.productsProductCurrent = productsProducts[0].current;
      serviceAccessObj.productsProductEnabled = productsProducts[0].enabled;
      serviceAccessObj.productsProductAccessType = productsProducts[0].accessType;
    }

    const testTransactionProducts = serviceAccesses.filter(
      (service) => service.family === ProductFamilies.TestTransactions,
    );
    if (testTransactionProducts.length > 0) {
      testTransactionProducts.sort(
        (a, b) => new Date(b.lastModifiedDate).getTime() - new Date(a.lastModifiedDate).getTime(),
      );
      serviceAccessObj.testTransactionProductName = testTransactionProducts[0].name;
      serviceAccessObj.testTransactionProductId = testTransactionProducts[0].productId;
      serviceAccessObj.testTransactionProductCurrent = testTransactionProducts[0].current;
      serviceAccessObj.testTransactionProductEnabled = testTransactionProducts[0].enabled;
      serviceAccessObj.testTransactionProductAccessType = testTransactionProducts[0].accessType;
    }

    return serviceAccessObj;
  }

  // Log that the User has logged in
  public logLoginEvent() {
    this.pushEvent({
      event: IntercomEventType.Login,
    });
  }

  // Log that the User has logged out
  public logLogoutEvent() {
    this.pushEvent({
      event: IntercomEventType.Logout,
    });
  }

  // Log that the User has added a new User
  public logAddUserEvent() {
    this.pushEvent({
      event: IntercomEventType.AddUser,
    });
  }

  // Log that the User has edited a User
  public logEditUserEvent() {
    this.pushEvent({
      event: IntercomEventType.EditUser,
    });
  }

  // Log that the User has looked up a Website
  public logWebsitesLookUpEvent() {
    this.pushEvent({
      event: IntercomEventType.WebsitesLookUp,
    });
  }

  // Log that the User has looked up a Product
  public logProductsLookUpEvent() {
    this.pushEvent({
      event: IntercomEventType.ProductsLookUp,
    });
  }

  // Log that the User looked at the API V3 Guide
  public logAPIV3GuideEvent() {
    this.pushEvent({
      event: IntercomEventType.APIV3Guide,
    });
  }

  // Log that the User looked at the API V4 Guide
  public logAPIV4GuideEvent() {
    this.pushEvent({
      event: IntercomEventType.APIV4Guide,
    });
  }

  // Log that the User looked at the User Guide
  public logUserGuideEvent() {
    this.pushEvent({
      event: IntercomEventType.UserGuide,
    });
  }

  // Log that the User downloaded the merchant list
  public logDownloadedMerchantListEvent() {
    this.pushEvent({
      event: IntercomEventType.DownloadedMerchantList,
    });
  }

  // Log that the User filtered Action Needed Merchants
  public logNeedsActionEvent() {
    this.pushEvent({
      event: IntercomEventType.NeedsAction,
    });
  }
}
