import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MAX_CC_AMOUNT_CERTIFICATION, UpdatedNumberItemsResponse } from '@ls/common-ts-models';
import { CertificationService } from '../services';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';

@Component({
  selector: 'certification-pricing-update',
  templateUrl: './certification-pricing-update.component.html',
  styleUrls: ['./certification-pricing-update.component.scss'],
})
export class CertificationPricingUpdateComponent implements OnInit, OnDestroy {
  public certificationId: string;
  public pricingInfo;
  public updatedInfo;
  public loading = true;
  public loadingText = 'Loading updated info';
  public paymentSuccess = false;
  public MAX_CC_AMOUNT = MAX_CC_AMOUNT_CERTIFICATION;
  public isProductType = false;
  private updatedInfo$: Subscription;
  private approval$: Subscription;

  constructor(
    public route: ActivatedRoute,
    private certificationSvc: CertificationService,
    public store: Store<AppState>,
    public router: Router,
  ) {}

  public ngOnDestroy() {
    if (this.updatedInfo$) {
      this.updatedInfo$.unsubscribe();
    }

    if (this.approval$) {
      this.approval$.unsubscribe();
    }
  }

  public ngOnInit() {
    this.certificationId = this.route.snapshot.params['certificationId'];
    this.updatedInfo$ = this.certificationSvc.getUpdatedNumberEnrolledItems(this.certificationId).subscribe(
      (result: UpdatedNumberItemsResponse) => {
        this.pricingInfo = result;
        this.isProductType = this.pricingInfo.itemDisplayName.toLowerCase() === 'products';
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        if (err.status === 404) {
          this.router.navigate(['/not-found']);
        } else {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Updated Pricing Failed to Load',
              detail:
                'Sorry! We are having trouble displaying your updated pricing - please try again.  If this problem persists, please contact support.',
              sticky: false,
              blocking: false,
            }),
          );
        }
      },
    );
  }

  public approveUpdatedPricing() {
    this.loading = true;
    this.loadingText = 'Approving Pricing Adjustment';
    this.approval$ = this.certificationSvc.approveUpdatedNumberEnrolledItems(this.certificationId).subscribe(
      () => {
        this.loading = false;
        this.paymentSuccess = true;
      },
      (err) => {
        this.loading = false;
        let message =
          'Sorry! We are having trouble approving your updated pricing - please try again.  If this problem persists, please contact support.';

        if (err.error && typeof err.error === 'string' && err.error.includes('Adjusted facilities already approved')) {
          message =
            "It looks like you've already approved your adjusted pricing. If this seems incorrect please contact support.";
        }

        if (err.error && err.error.message) {
          if (err.error.message.includes('Your card was declined')) {
            message = 'Your card was declined. Please update your credit card information and try again.';
          }

          if (
            err.error.message.includes('Cannot charge a customer that has no active card') ||
            err.error.message.includes("You passed an empty string for 'customer'") ||
            err.error.message.includes('No card on file')
          ) {
            message =
              'You do not have a credit card on file. Please update your credit card information and try again.';
          }
        }

        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Failed to Approve Updated Pricing',
            detail: message,
            sticky: true,
            blocking: true,
          }),
        );
      },
    );
  }
}
