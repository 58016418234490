import { MenuItem } from 'primeng/api';

export enum ActiveProductTypes {
  API,
  CERTIFICATION,
  MONITORING
}

export interface ActiveProductListItem {
  name: string;
  productType: ActiveProductTypes;
  menuItems?: MenuItem[];
  expiration?: Date;
  plan?: string;
  usageString?: string;
}
