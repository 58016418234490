import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScreenshotObject, TransactionDetailsForMyLs200Response } from '../../../angular-client';

@Component({
  selector: 'ls-test-transaction-details-tab',
  templateUrl: './test-transaction-details-tab.component.html',
  styleUrls: ['./test-transaction-details-tab.component.scss'],
})
export class TestTransactionDetailsTabComponent {
  @Input() testTransaction: TransactionDetailsForMyLs200Response;
  @Input() loading: boolean;
  @Input() isDownloadLoading: boolean;
  @Input() isZipping: boolean;

  @Output() requestScreenshots: EventEmitter<{ id: string; client: string; websiteUrl: string }> = new EventEmitter();
  @Output() downloadDocuments: EventEmitter<{
    documents: { id: string; order?: number }[];
    type: 'screenshot';
    zip?: boolean;
    folderName?: string;
  }> = new EventEmitter();
  @Output() showScreenshots: EventEmitter<{ ids: string[]; index: number }> = new EventEmitter();

  onRequestScreenshotsClick() {
    const { id, client = '', websiteUrl } = this.testTransaction;
    this.requestScreenshots.next({ id, client, websiteUrl });
  }

  onDownloadAllClick() {
    const allScreenshots = (this.getSortedScreenshots() || []).map((s) => {
      return { id: s.id, order: s.order };
    });
    const folderName = `${this.testTransaction?.websiteUrl}-screenshots`;
    this.downloadDocuments.next({ documents: allScreenshots, type: 'screenshot', zip: true, folderName });
  }

  onDownloadClick(screenshotId: string) {
    this.downloadDocuments.next({ documents: [{ id: screenshotId }], type: 'screenshot' });
  }

  onShowScreenshots(screenshot: ScreenshotObject) {
    const sortedScreenshots = this.getSortedScreenshots();
    const index = (sortedScreenshots || []).findIndex((s) => s.id === screenshot.id);
    const allScreenshots = (sortedScreenshots || []).map((s) => s.id);
    this.showScreenshots.next({ ids: allScreenshots, index });
  }

  getSortedScreenshots() {
    return [...(this.testTransaction?.screenshots ?? [])].sort((a, b) => a.order - b.order);
  }
}
