/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WebsiteMonitoringStatus } from './websiteMonitoringStatus';


export interface CreateWebsiteRequest { 
    accountId: string;
    categoryId: string;
    client: string;
    websiteUrl: string;
    network?: string;
    monitoringStatus?: WebsiteMonitoringStatus;
}
export namespace CreateWebsiteRequest {
}



