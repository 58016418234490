// TODO: ask devops if we should create a process.env.REGION environment variable and use that in these strings instead of hard coding us-west-2.
enum UPLOAD_COMPONENT_ENUMS {
  fileUploadRestriction = 'Note: Maximum number of merchants that can be uploaded at one time is 20,000.',
  templateDownloadURLDev = 'https://static-assets-us-west-2-916533587194-ls.s3-us-west-2.amazonaws.com/myls-merchant-monitoring-templates/template.xlsx',
  templateDownloadURLProd = 'https://static-assets-us-west-2-908113025037-ls.s3-us-west-2.amazonaws.com/myls-merchant-monitoring-templates/MyLS+Upload+Template.xlsx',
  mcCodesDownloadURLDev = 'https://static-assets-us-west-2-916533587194-ls.s3-us-west-2.amazonaws.com/myls-merchant-monitoring-templates/Merchant+Category+Codes.xlsx',
  mcCodesDownloadURLProd = 'https://static-assets-us-west-2-908113025037-ls.s3-us-west-2.amazonaws.com/myls-merchant-monitoring-templates/Merchant+Category+Codes.xlsx',
  // TODO: set the correct url for the onboarding template
  onboardingTemplateDownloadURLDev = '#',
  onboardingTemplateDownloadURLProd = '#',
}

enum UPLOAD_VALIDATION_ENUMS {
  fileUploadHeadersError = 'Invalid template. Please try again with the correct template.',
  fileUploadRowCountError = 'File size exceeds limit. Please ensure a maximum of 20,000 merchants per upload and try again.',
  fileUploadMinRowCountError = 'File does not meet minimum criteria. Please make sure at least one complete record is present.',
  fileUploadTabCountError = 'File can only have one tab. Please remove the extra tab(s) and try again.',
  invalidFileType = 'Invalid file type.',
  fileUploadNoTabsError = 'File does not have any tabs. Please ensure you have exactly 1 tab.',
}

export { UPLOAD_COMPONENT_ENUMS, UPLOAD_VALIDATION_ENUMS };
