<legitscript-modal class="no-footer update-email" #manageUserModal [draggable]=false [resizable]=false [responsive]=true [modal]=true autoAlign=true [width]=800 height=auto>
  <div class="dialog-body" style="overflow:auto; text-align: left;">
    <div *ngIf="!success">

        <h1>Update Email</h1>
        <div *ngIf="!success && errorMsg" class="error-container validation-error-container">
          <p>An error occurred while submitting your email change request:</p>
          <p><em>{{errorMsg}}</em></p>
        </div>
        <form name="form" (ngSubmit)="form.valid && submit()" [formGroup]="form">
          <loading-spinner [loadingText]="'Processing'" *ngIf="processing" [transparent]="true"></loading-spinner>
    
          <confirm-email (emailSet)="emailSet($event)" [parentForm]="form"></confirm-email>

          <div class="form-footer" style="text-align: center;">
              <button type="submit" class="submit-button" label="Submit" [disabled]="!email">Submit</button>
              <button type="button" (click)="cancel()" label="Cancel">Cancel</button>
          </div>
        </form>
    </div>
        <div *ngIf="success">
            <div class="update-thanks">
                <i class="fa fa-check-circle success"></i>
                <h1>Thank You!</h1>
                <p>
                  You have successfully requested an email change to {{email}}. Please check your email within 24 hours to confirm this change.
                </p>
                <p>
                  <strong>Please note:</strong> This change will not take effect until the confirmation link in your email has been clicked.
                </p>
            </div>
            <div class="form-footer" style="text-align: center;">
                <button type="button" (click)="cancel()" label="Cancel">Ok</button>
            </div>
        </div>

         
    </div>


</legitscript-modal> 

