import { Subject, of as observableOf } from 'rxjs';

import { mergeMap, catchError, skip, throttle, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SalesforceAccountInfoResponse } from '@ls/common-ts-models';
import { LocalStorageService, GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';

import { AccountLoadErrorAction, AccountLoadSuccessAction, AppState } from '../reducers';

import { AccountService } from '../services';
import { AccountLoadAction, AccountState } from '../reducers/account.reducer';

import { DataLayerService } from '../services/data-layer.service';

@Injectable()
export class AccountEffect {
  public accountLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountLoadAction),
      throttle(() => this.throttleWatcher), // turn off subsequent requests to get account info until the throttleWatcher is finished
      mergeMap(() => {
        this.actions$.pipe(ofType(AccountLoadAction), skip(1));

        return this.accountService.getAccountInfoByLegitScriptAccountId().pipe(
          mergeMap((res: SalesforceAccountInfoResponse) => {
            try {
              this._dataLayerService.logCustomerDataEvent(res);

              if (!localStorage.getItem('logInEvent')) {
                this._dataLayerService.logLoginEvent();
                localStorage.setItem('logInEvent', 'true');
              }
            }
            catch (e) {
              console.error('Error: Unable to log the Customer Data to Data Layer: ', e);
            }
            const account: AccountState = this.localStorageSvc.getAccount();

            account.lsAccountId = res.account.lsAccountId;
            account.name = res.account.name;
            account.address = res.account.address;
            account.sfAccountId = res.account.sfAccountId;
            account.primaryContact = res.account.primaryContact;
            account.user = res.contact;
            this.localStorageSvc.setAccount(account);

            if (res.contact) {
              this.localStorageSvc.setUser(res.contact);
            }

            return observableOf(AccountLoadSuccessAction({ payload: account }));
          }),
          catchError((err) => {
            // If account load throws unauthorized, let user know (logout handled automatically)
            if (err?.error?.statusCode === 401) {
              this.store.dispatch(
                GenericNotificationAction({
                  severity: SeverityOptions.WARN,
                  summary: 'Session Expired',
                  detail: 'Please log in again.',
                  sticky: false,
                  blocking: false,
                }),
              );
            }
            return observableOf(AccountLoadErrorAction({ err }));
          }),
          finalize(() => {
            this.throttleWatcher.next(true); // turn on subsequent requests
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private accountService: AccountService,
    private localStorageSvc: LocalStorageService,
    private _dataLayerService: DataLayerService,
  ) {}

  private throttleWatcher: Subject<boolean> = new Subject<boolean>();
}
