import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cert-type-select',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './certification-type-select.component.html',
  styleUrls: [
    './certification-type-select.component.scss',
    '../certification-questionnaire-wrapper/certification-questionnaire-wrapper.component.scss'
  ]
})
export class CertificationTypeSelectComponent {}
