import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EnrollService, QuestionnaireInfo } from '../../services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { takeUntil, catchError, map } from 'rxjs/operators';
import {
  ProductFamily,
  CbdProductPricebookGuid,
  CbdWebsitePricebookGuid,
  CertifiableItemType,
  CertifiableItemStatus,
} from '@ls/common-ts-models';
import { LegitScriptModalComponent } from '@ls/common-ng-components';
import { CbdProductProductGuid, CbdWebsiteProductGuid } from '@ls/common-ts-models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cbd-enroll',
  templateUrl: './cbd-enroll.component.html',
  styleUrls: ['./cbd-enroll.component.scss'],
})
export class CbdEnrollComponent implements OnInit, OnDestroy {
  @ViewChild('pricingModal') public pricingModal: LegitScriptModalComponent;
  @ViewChild('certifiedProductModal') public certifiedProductModal: LegitScriptModalComponent;

  public questions: Observable<any>;
  public originalQuestionnaires: any;
  public questionnaireInfo: QuestionnaireInfo = {} as QuestionnaireInfo;
  public apiError = false;

  public certificationType: FormControl = new FormControl<string>('', Validators.required);

  public productSupplements: FormControl = new FormControl<string>('', [Validators.required, Validators.pattern('no')]);
  public productEdibles: FormControl = new FormControl<string>('', [Validators.required, Validators.pattern('no')]);
  public productSubmissionNumber: FormControl = new FormControl<string>('', [
    Validators.required,
    Validators.pattern('[0-9]*'),
    Validators.max(1000),
    Validators.min(1),
  ]);

  public websiteDirect: FormControl = new FormControl<string>('', Validators.required);
  public websiteEdibles: FormControl = new FormControl<string>('', [Validators.required, Validators.pattern('no')]);
  public websiteSubmissionNumber: FormControl = new FormControl<string>('', [
    Validators.required,
    Validators.pattern('[0-9]*'),
    Validators.max(1000),
    Validators.min(1),
  ]);
  public websiteSupplements: FormControl = new FormControl<string>('', [Validators.required, Validators.pattern('no')]);
  public websiteTHC: FormControl = new FormControl<string>('', [Validators.required, Validators.pattern('no')]);
  public websiteCertifiedProducts: FormControl = new FormControl<string>('', [
    Validators.required,
    Validators.pattern('yes'),
  ]);

  public cbdFormGroup = new FormGroup<{ [key: string]: FormControl<string> }>({
    certificationType: this.certificationType,
  });

  public expeditedEnabled = environment.CONFIG.expeditedForCBDandSupp;

  public productConfirmationFormGroup = new FormGroup({
    standards: new FormControl('', Validators.required),
    refund: new FormControl('', Validators.required),
  });

  public websiteConfirmationFormGroup = new FormGroup({
    ingest: new FormControl('', Validators.required),
    certified: new FormControl('', Validators.required),
    doubleCertified: new FormControl('', Validators.required),
    standards: new FormControl('', Validators.required),
    refund: new FormControl('', Validators.required),
  });

  public firstTry = true;

  public pricing: { website: any; product: any } = undefined;
  public pricing$: Observable<any>;
  public pricingInfo: any = {};

  public destroyed$: Subject<boolean> = new Subject();
  public listTableReloadFlag = false;
  public certItemType: CertifiableItemType = CertifiableItemType.CbdProduct;
  public certItemStatus: CertifiableItemStatus = CertifiableItemStatus.Certified;

  public validConfirms = false;
  public usCountry = 'US';

  @ViewChild('faqModal') public faqModal: LegitScriptModalComponent;
  @ViewChild('uniqueProductModal') public uniqueProductModal: LegitScriptModalComponent;
  @ViewChild('productConfirmationModal') public productConfirmationModal: LegitScriptModalComponent;
  @ViewChild('websiteConfirmationModal') public websiteConfirmationModal: LegitScriptModalComponent;

  private readonly productControlKeys = ['productEdibles', 'productSubmissionNumber', 'productSupplements'];

  private readonly websiteControlKeys = [
    'websiteDirect',
    'websiteSubmissionNumber',
    'websiteEdibles',
    'websiteSupplements',
    'websiteTHC',
    'websiteCertifiedProducts',
  ];

  // tslint:disable-next-line: member-ordering
  constructor(
    private router: Router,
    private enrollSvc: EnrollService,
  ) {}

  public ngOnInit() {
    // whenever the type changes, make sure the right controls
    // are in the form group for validation purposes
    this.certificationType.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      const [addList, removeList] =
        value === 'product'
          ? [this.productControlKeys, this.websiteControlKeys]
          : [this.websiteControlKeys, this.productControlKeys];
      removeList.forEach((key) => this.cbdFormGroup.removeControl(key));
      addList.forEach((key) => this.cbdFormGroup.addControl(key, this[key]));
      this.questionnaireInfo.questions = this.originalQuestionnaires[value].questions;
      this.questionnaireInfo.certificationType = `cbd_${value}`;
      this.questionnaireInfo.questionnaireTypeName = `cbd_${value}_enrollment`;
    });

    // let's make the questionnaire a bit easier to deal with
    function questionnaireToQuestionTextMap(questionnaire) {
      return questionnaire.questions.reduce((acc, cur) => {
        if (cur.name) {
          acc[cur.name] = cur.question_text;
        }
        return acc;
      }, {});
    }

    this.questions = forkJoin(
      this.enrollSvc.getEnrollmentQuestions('cbd_website_enrollment'),
      this.enrollSvc.getEnrollmentQuestions('cbd_product_enrollment'),
    ).pipe(
      catchError(() => {
        this.apiError = true;
        return of([null, null]);
      }),
      map((result) => {
        const [website, product] = result;
        // welp, submission needs the question id's
        this.originalQuestionnaires = {
          website,
          product,
        };
        return {
          website: questionnaireToQuestionTextMap(website),
          product: questionnaireToQuestionTextMap(product),
        };
      }),
    );

    this.pricing$ = forkJoin(
      this.enrollSvc.getPricing(ProductFamily.CBD_WEBSITE_CERT),
      this.enrollSvc.getPricing(ProductFamily.CBD_PRODUCT_CERT),
    ).pipe(
      catchError(() => {
        this.apiError = true;
        return of([null, null]);
      }),
      map((result) => {
        const [website, product] = result;
        this.pricing = {
          website,
          product,
        };
        return this.pricing;
      }),
    );
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public validate(firstTry) {
    this.firstTry = firstTry;
  }

  public validateConfirmations() {
    // TODO: If the CBD Websites are not going to use the checkbox confirmations
    // we can remove this validConfirms
    if (this.certificationType.value === 'product') {
      this.validConfirms = true;
    } else {
      this.validConfirms = true;
    }
  }

  public hideCertProductDialog() {
    this.certifiedProductModal.hideDialog();
    this.listTableReloadFlag = !this.listTableReloadFlag;
  }

  public calculatePricing() {
    if (!this.pricing || !this.certificationType.dirty) {
      return;
    }
    if (this.certificationType.value === 'product') {
      this.calculateProductPricing();
    } else {
      this.calculateWebsitePricing();
    }
    this.questionnaireInfo.productId = this.pricingInfo.productId;

    // We're taking the answers for each questions but the certificationType (product or website)
    // and from that we're building a new object with the building question and answer coming from
    // the buildAnsweredQuestion service which is in charge to add some UUIDs and shape the object
    this.questionnaireInfo.answeredQuestions = Object.keys(this.cbdFormGroup.controls)
      .filter((key) => key !== 'certificationType')
      .map((key) => {
        const question = this.questionnaireInfo.questions.find((q) => q.name === key);
        return this.enrollSvc.buildAnsweredQuestion(
          question.question_type,
          question.id,
          this.cbdFormGroup.controls[key].value,
        );
      });
  }

  private calculateProductPricing() {
    const quantity = parseInt(this.productSubmissionNumber.value, 10);
    if (isNaN(quantity) || quantity === 0) {
      this.pricingInfo = {};
      return;
    }

    let tier: string;
    if (quantity <= 5) {
      tier = 'ONE_TO_FIVE';
    } else if (quantity <= 50) {
      tier = 'SIX_TO_FIFTY';
    } else if (quantity <= 99) {
      tier = 'FIFTYONE_TO_NINETYNINE';
    } else {
      tier = 'ONEHUNDRED_PLUS';
    }

    const subscriptionFee = this.pricing.product[tier].SUBSCRIPTION;
    const applicationFee = this.pricing.product[tier].APPLICATION;

    this.questionnaireInfo.enrolledItemCount = quantity;
    this.questionnaireInfo.pricingTierSku = CbdProductPricebookGuid[tier];
    this.pricingInfo = {
      subscriptionFee,
      applicationFee,
      productId: CbdProductProductGuid.APPLICATION,
      subscriptionProductId: CbdProductProductGuid.SUBSCRIPTION,
      billingAmount: applicationFee * quantity,
      recurringAmount: subscriptionFee * quantity,
      perItem: 'product',
      quantity,
    };
  }

  private calculateWebsitePricing() {
    const quantity = parseInt(this.websiteSubmissionNumber.value, 10);
    if (isNaN(quantity) || quantity === 0) {
      this.pricingInfo = {};
      return;
    }

    const tier = 'CBD_WEBSITE_A';

    const subscriptionFee = this.pricing.website[tier].SUBSCRIPTION;
    const applicationFee = this.pricing.website[tier].APPLICATION;

    this.questionnaireInfo.enrolledItemCount = quantity;
    this.questionnaireInfo.pricingTierSku = CbdWebsitePricebookGuid.A;
    this.pricingInfo = {
      subscriptionFee,
      applicationFee,
      productId: CbdWebsiteProductGuid.APPLICATION,
      subscriptionProductId: CbdWebsiteProductGuid.SUBSCRIPTION,
      billingAmount: applicationFee * quantity,
      recurringAmount: subscriptionFee * quantity,
      perItem: 'website',
      quantity,
    };
  }
}
