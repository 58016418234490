<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="widgets-container">
  <div class="widget">
    <div class="widget-header">
      <i class="fa fa-book"></i><h1>Docs</h1>
    </div>
    <div class="widget-body centered">
      <div id="doc-table-widget">
        <p-table
        class="doc-list"
        [value]="docs">
          <ng-template pTemplate="body" let-doc let-rowData>
            <tr class="doc-table">
              <td class="doc-column">
                <a *ngIf="!doc.extraLinks" (click)="openDoc(doc)">{{doc.name}}</a>
                <span *ngIf="doc.extraLinks" class="doc-title">{{doc.name}}
                  <a target="_blank" [href]="doc.extraLinks.en">(EN)</a>
                  <a target="_blank" [href]="doc.extraLinks.jp">(JP)</a>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>