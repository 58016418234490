export class ValidationResult {
  /**
   * A plain/default success result
   */
  public static Success: ValidationResult = new ValidationResult(true, null);

  /**
   * @param validationResults All your applicable validation results
   * @returns If no failures then {@link ValidationResult.Success} else the first failure.
   */
  public static getPassOrFirstFailure(validationResults: ValidationResult[]): ValidationResult {
    let result = validationResults.find(this.isFailedResult);
    if (result === undefined) {
      // no errors
      result = ValidationResult.Success;
    }
    return result;
  }

  /**
   *
   * @param result true if failed, false if success
   */
  public static isFailedResult(result: ValidationResult): boolean {
    return result.success !== true;
  }

  public constructor(public success: boolean, public validationError: string) {}
}
