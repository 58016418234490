import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BillingService,
  EntitlementsGetAction,
  GenericNotificationAction,
  LegitScriptModalComponent,
  LocalStorageService,
  SeverityOptions,
  UrlRegEx,
} from '@ls/common-ng-components';
import { MMEnrollService } from '../services';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  CustomerQuestionnaireTypes,
  MAX_CC_AMOUNT_CERTIFICATION,
  MerchantMonitoringPricebookGuid,
  Opportunity,
  OpportunityTypes,
  PaymentChargeProductsRequest,
  ProductFamily,
  EnrollQuestions,
  NumMerchantsQuestionValues,
} from '@ls/common-ts-models';
import { Router } from '@angular/router';
import { NgxScrollEvent } from '../../ngx-scroll-event/ngx-scroll-event.directive';

@Component({
  selector: 'mm-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.scss'],
})
export class MMEnrollComponent implements OnInit, OnDestroy {
  public firstTry = true; // the "Submit" button should be enabled so validation can be triggered
  public enrollFormGroup: FormGroup;
  public loadingPricing = true;
  public enrolling = false;
  public pricebookId: string;
  public pricingInfo: any;
  public pricing: any;
  public pricingDisplayFirstMonth: number;
  public pricingDisplayFirstMonthIsProrated: boolean;
  // have to do this otherwise not visible to template222
  public NumMerchantsQuestionValues = NumMerchantsQuestionValues;
  public shouldEnforceTerms = false;

  public hasScrolledToBottom = false;
  public termsSelected = false;

  public apiError: any;
  public pricingSubscription: Subscription;
  public primaryUrl: FormControl = new FormControl('', [Validators.required, Validators.pattern(UrlRegEx())]);
  public numMerchants: FormControl = new FormControl('', Validators.required);
  public highRisk: FormControl = new FormControl('', Validators.required);

  @ViewChild('termsModal') private termsModal: LegitScriptModalComponent;
  @ViewChild('customPricingModal') private customPricingModal: LegitScriptModalComponent;

  constructor(
    private store: Store<AppState>,
    public billingSvc: BillingService,
    public router: Router,
    public localStorageService: LocalStorageService,
    public mmService: MMEnrollService,
  ) {}

  public ngOnInit() {
    this.enrollFormGroup = new FormGroup({
      primaryUrl: this.primaryUrl,
      numMerchants: this.numMerchants,
      highRisk: this.highRisk,
    });

    this.pricingSubscription = this.billingSvc.getProductForFamily(ProductFamily.MERCHANT_MONITORING).subscribe(
      (prices: any) => {
        this.pricing = prices;
        this.loadingPricing = false;
      },
      () => {
        this.apiError = true;
        this.loadingPricing = false;
      },
    );
  }

  public ngOnDestroy() {
    if (this.pricingSubscription) {
      this.pricingSubscription.unsubscribe();
    }
  }

  public onDialogScroll(event: NgxScrollEvent) {
    if (event.isWindowEvent === false && event.isReachingBottom === true) {
      this.hasScrolledToBottom = true;
    }
  }

  public onTermsChecked(e: Event) {
    const checkbox: HTMLInputElement = e.target as any;

    // If we're clicking the checkbox AND we haven't read the terms yet (scrolled to the bottom of the modal) then show the modal instead of allowing the checkbox click.
    if (checkbox.checked === true && this.hasScrolledToBottom === false) {
      this.termsModal.showDialog();
      // The checked event has already fired, to consistently revert we raise another event to revert the checkbox state.
      setTimeout(() => {
        this.termsSelected = false;
      });
    }
  }

  public onAgreeToTerms() {
    this.termsSelected = true;
    this.termsModal.hideDialog();
  }

  public onChange() {
    if (!this.pricing || this.numMerchants.pristine || this.highRisk.pristine) {
      return; // don't calculate pricing unless we have answers for both questions
    }

    let price = -1;
    let product;
    // Only one case where we are low tier
    if (this.numMerchants.value === NumMerchantsQuestionValues.UNDER_5k && this.highRisk.value !== 'yes') {
      this.pricebookId = MerchantMonitoringPricebookGuid.UNDER_5k_LOW_RISK;
      const selectedPricebook = this.pricing.find(
        (pb: { externalReferenceId: string }) => pb.externalReferenceId === this.pricebookId,
      );
      [product] = selectedPricebook.products;
      price = product.price;
    } else {
      this.pricebookId = MerchantMonitoringPricebookGuid.OVER_5k_HIGH_RISK;
      const selectedPricebook = this.pricing.find(
        (pb: { externalReferenceId: string }) => pb.externalReferenceId === this.pricebookId,
      );
      [product] = selectedPricebook.products;
    }

    this.pricingInfo = { price, productId: product.externalId };
    this.pricingDisplayFirstMonthIsProrated = this.isFirstMonthProrated();
    this.pricingDisplayFirstMonth = this.getPriceByMonths(1);
    this.shouldEnforceTerms = this.pricingInfo && this.pricingInfo.price && this.pricingInfo.price >= 0;
  }

  public continueToService() {
    this.router.navigate(['services', 'merchant-monitoring']);
  }

  public isFirstMonthProrated(): boolean {
    return this.pricebookId === MerchantMonitoringPricebookGuid.UNDER_5k_LOW_RISK;
  }

  // Only ever called with 1 month now but keeping for posterity in case we need this again
  public getPriceByMonths(months: number): number {
    return this.isFirstMonthProrated()
      ? this.getProratedMonthAmount() + (months - 1) * this.pricingInfo.price
      : months * this.pricingInfo.price;
  }

  public onSubmit() {
    if (this.firstTry) {
      this.firstTry = false;
      this.enrollFormGroup.updateValueAndValidity();
      if (!this.enrollFormGroup.valid || (this.shouldEnforceTerms && !this.termsSelected)) {
        return;
      }
    }

    this.submit();
  }

  public submit() {
    const questionnaire: { [str: string]: string | number | null | undefined | boolean } = {};
    this.enrolling = true;
    const { lsAccountId, name, address, phone } = this.localStorageService.getAccount();
    const { street1: address1, city, province: state, countryCode: country, postalCode } = address;

    // Prepend an http scheme on the customer's primary URL if necessary
    const httpRegex = /^https?:\/\//i;
    const url = httpRegex.test(this.primaryUrl.value) ? this.primaryUrl.value : `http://${this.primaryUrl.value}`;

    // TODO: https://legitscript.atlassian.net/browse/LS-14400
    questionnaire[EnrollQuestions.NumMerchants] = this.numMerchants.value;
    questionnaire[EnrollQuestions.URL] = url;
    questionnaire[EnrollQuestions.HighRisk] = this.highRisk.value;

    this.mmService
      .upsertCustomer({
        account_svc_id: +lsAccountId,
        name,
        company_type: 'Acquirer', // default for MM customers
        address1,
        city,
        state,
        country,
        postal_code: postalCode,
        phone,
        tier: this.pricebookId,
        questionnaires: [
          { type: CustomerQuestionnaireTypes.Application, questionnaire: JSON.stringify(questionnaire) },
        ],
        url,
      })
      .pipe(take(1))
      .subscribe(
        () => {
          if (this.pricingInfo.price < 0) {
            this.enrollWithoutPayment();
          } else {
            this.enrollWithPayment();
          }
        },
        () => this.handleEnrollError(),
      );
  }

  private getProratedMonthAmount(): number {
    return +this.billingSvc.prorateRemainderOfMonth(new Date(), this.pricingInfo.price);
  }

  private enrollWithoutPayment() {
    const request: PaymentChargeProductsRequest = {
      expectedAmount: 0,
      products: [{ id: this.pricingInfo.productId, quantity: 1 }],
      pricebookId: this.pricebookId,
      oppType: OpportunityTypes.MERCHANT_MONITORING,
    };
    return this.billingSvc.chargeProducts(request).subscribe(
      () => {
        this.store.dispatch(EntitlementsGetAction());
        this.customPricingModal.showDialog();
        this.enrolling = false;
      },
      () => this.handleEnrollError(),
    );
  }

  private enrollWithPayment() {
    this.mmService.createOpportunity().subscribe(
      (opportunity: Opportunity) => {
        this.billingSvc.setPricingInfo({
          billingItems: [
            {
              description: 'Merchant Monitoring',
              amount: this.isFirstMonthProrated() ? this.getProratedMonthAmount() : this.pricingInfo.price,
              recurringAmount: this.pricingInfo.price,
              recurringPeriod: 'monthly',
              recurringDescription: 'Subscription',
              productId: this.pricingInfo.productId,
              prorated: this.isFirstMonthProrated(),
              quantity: 1,
            },
          ],
          oppId: opportunity.Id,
          maxCCAmount: MAX_CC_AMOUNT_CERTIFICATION,
          oppType: OpportunityTypes.MERCHANT_MONITORING,
          successPage: '/merchant-monitoring/merchant-list',
        });
        this.store.dispatch(EntitlementsGetAction());
        this.enrolling = false;
        this.router.navigate(['services', 'payment']);
      },
      () => this.handleEnrollError(),
    );
  }

  private handleEnrollError() {
    this.store.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.ERROR,
        summary: 'Enrollment Error',
        detail:
          'An error occurred while processing your enrollment request.  Please try again.  If this problem persists, please contact support',
        sticky: true,
        blocking: true,
      }),
    );
    this.enrolling = false;
  }
}
