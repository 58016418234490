import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '@ls/common-ng-components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState, UserSignOutAction } from '../reducers';

@Injectable()
export class SignOutGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store<AppState>,
    private localStorage: LocalStorageService,
  ) {}

  /**
   * Checks for currently active session.
   * If Session is active, prevent routing to the component and start signout with the intent of routing back to this same location.
   * If Session is NOT active, route to the component.
   */
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.localStorage.getAuthenticatedUser()) {
      this.store.dispatch(UserSignOutAction({ location: state.url }));
      return false;
    }

    return true;
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(route, state);
  }
}
