<!-- Master copy of the V3 Migration Guide for making edits and pasting back into v3.html -->
<section id="migration-guide">
  <hr/>
  <h2>V3 Migration Guide</h2>
  <h3>Merchant Status <em>*NEW*</em></h3>
  <p>Visibility enhancements were made to show each merchant’s analysis status with the addition of a new
    <code>status</code> property. This property is now part of the API response body when requesting merchant
    details:
  </p>
  <pre class="json-example"><code>
"merchant_details:" {{ '{' }}
  ...
  "status":"monitored"
  ...
 {{ '}' }}
          </code></pre>
  <p>The <code>status</code> property will contain one of the following string values depending upon the current
    analysis state of a merchant:
  </p>
  <table>
    <thead>
    <tr>
      <th>Status</th>
      <th>Value</th>
      <th>Description</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>Pending</td>
      <td><code>pending</code></td>
      <td>Analysis is scheduled and has not yet completed.</td>
    </tr>
    <tr>
      <td>Published</td>
      <td><code>published</code></td>
      <td>The merchant has been analyzed but has LS Risk and/or LS Actions.</td>
    </tr>
    <tr>
      <td>Monitored</td>
      <td><code>monitored</code></td>
      <td>The merchant has been analyzed and has no LS Risk or LS Actions.</td>
    </tr>
    <tr>
      <td>Terminated</td>
      <td><code>terminated</code></td>
      <td>
        A customer action of "Immediately Terminated", "10 day termination notice", "15 day termination notice",
        or
        "30 day termination notice" has been supplied for this merchant.
        <ul>
          <li>This state will effectively freeze the merchant record from further analysis and future changes.</li>
          <li>This customer action removes the merchant from the monitoring queue.</li>
        </ul>
      </td>
    </tr>
    <tr>
      <td>Inactive</td>
      <td><code>inactive</code></td>
      <td>
        A customer action of "Inactive" has been supplied for this merchant.
        <ul>
          <li>This state will effectively freeze the merchant from further analysis and future changes.</li>
          <li>
            This customer action removes the merchant from the monitoring queue and may
            only be selected if such removal is compliant with contractual obligations.
          </li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>
  <h3>Customer Action Updates (POST v3/merchant/{{ '{' }}merchantId{{ '}' }}/action)</h3>
  <h4>Reset Action <em>*NEW*</em></h4>
  <p>
    You can now send merchant reset requests by placing a <code>reset</code> string value as the action type in the
    customer action update JSON request payload. Use reset to re-enable analysis by you or LegitScript for merchants
    in the following statuses:
  </p>
  <ul>
    <li>Terminated</li>
    <li>Inactive</li>
  </ul>
  <p>
    Performing a merchant reset will result in a change to the merchant's <code>status</code> to <code>pending</code>.
  </p>
  <h3>LS Actions (GET /v3/merchant/{{ '{' }}merchantId{{ '}' }} or GET v3/merchants)</h3>
  <p>
    Some LS Actions will no longer display in the <code>ls_actions -> actions</code> response property as they
    are now listed under <code>status</code>. The values that will now show under <code>status</code> instead
    are:
  </p>
  <ul>
    <li><code>pending</code></li>
    <li><code>monitored</code></li>
  </ul>
  <h3>Merchant Creation/Update Processing Time Changes</h3>
  <p>
    To ensure accurate and consistent processing of merchant creation and update requests, an additional but
    brief processing window may be required (usually less than 5 minutes). Requests that may take longer to process
    include, but not are not limited to:
  </p>
  <ul>
    <li>Merchant creation</li>
    <li>URL changes</li>
    <li>Merchant actions</li>
  </ul>

  <h3>Migrating from V2 to V3</h3>
  <h4>Required Property Changes</h4>
  <table>
    <thead>
    <tr>
      <th>V3 API Route</th>
      <th>V3 Required Properties</th>
      <th>V2 API Route</th>
      <th>V2 Required Properties</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td><code>POST /v3/merchant</code></td>
      <td><code>merchant_id, merchant_country</code></td>
      <td><code>POST /v2/merchant</code></td>
      <td><code>merchant_id</code>, <code>merchant_url</code>, <code>merchant_category_code</code>,
        <code>merchant_country</code></td>
    </tr>
    <tr>
      <td><code>PUT /v3/merchant</code></td>
      <td><code>merchant_id, merchant_country</code></td>
      <td><code>PUT /v2/merchant</code></td>
      <td><code>merchant_id</code>, <code>merchant_url</code>, <code>merchant_category_code</code>,
        <code>merchant_country</code></td>
    </tr>
    </tbody>
  </table>

  <h3>Migrating from V1 to V3</h3>
  <h4>Required Property Changes</h4>
  <table>
    <thead>
    <tr>
      <th>V3 API Route</th>
      <th>V3 Required Properties</th>
      <th>V1 API Route</th>
      <th>V1 Required properties</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td><code>POST /v3/merchant</code></td>
      <td><code>merchant_id, merchant_country</code></td>
      <td><code>POST /payments/v1/merchants</code></td>
      <td><code>mid</code>, <code>url</code>, <code>merchant_category_code</code>,
        <code>legal_business_country</code></td>
    </tr>
    <tr>
      <td><code>PUT /v3/merchant</code></td>
      <td><code>merchant_id, merchant_country</code></td>
      <td><code>PUT /payments/v1/merchants</code></td>
      <td><code>mid</code>, <code>url</code>, <code>merchant_category_code</code>,
        <code>legal_business_country</code></td>
    </tr>
    </tbody>
  </table>
  <h4>Record Identifier Changes</h4>
  <h5>LSDB ID or Record ID</h5>
  <p>
    The data record ID (sometimes referred to as LSDB ID, or just ID) is the historical ID of the record.
    This property can still be used for all PUT (update) calls within newer versions of the APIs as the unique
    identifier of the merchant record in our system.
    <em>In future API versions, this field may be deprecated or removed in favor of the <code>merchant_id</code>
      (formerly <code>mid</code>) and <code>correlation_id</code> properties.</em>
  </p>
  <h5>Correlation ID</h5>
  <p>
    In V1, the ID returned on a create call was used as the record identifier. Starting with V2,
    a Correlation ID (UUID) was introduced to improve scalability and will display after enqueueing your request
    to create a merchant. You then can lookup the status of that record with the Correlation ID to see that it has
    persisted and make any subsequent updates or queries. GET calls on a record will still return a record ID for use
    in PUT calls to ease the transition.</p>
  <h5>MID / Merchant ID</h5>
  <p>
    MID was renamed to Merchant_ID. This property is your internal ID for the merchant and must be a unique string.
    You can use this identifier to perform GET requests to fetch a record by your internal Merchant ID. If you
    attempt to create a merchant with a duplicate Merchant ID, the original record will be updated--In this case an
    “upsert” is performed.</p>
  <h5>URLs</h5>
  <p>
    URLs need to be a fully formed URL including the scheme (<code>http:</code> or <code>https:</code>). We use
    the following library to parse URLs:
    <a href="https://www.npmjs.com/package/parse-domain">https://www.npmjs.com/package/parse-domain</a>.
    Any URL that fails URL validation will cause the create or update calls to fail and return a parse error. The
    published list of valid top level domains we use to validate is located here:
    <a href="https://publicsuffix.org/list/public_suffix_list.dat">https://publicsuffix.org/list/public_suffix_list.dat</a>.
  </p>
  <h6>Valid URL Examples</h6>
  <ul>
    <li><a href="http://www.legitscript.com">http://www.legitscript.com</a></li>
    <li><a href="https://payments.legitscript.com">https://payments.legitscript.com</a></li>
  </ul>
  <h6>Invalid URL Examples</h6>
  <ul>
    <li><a href="http://www.legitscript.com">www.legitscript.com</a> <em>no scheme on url</em></li>
    <li><a href="https://www.api.legitscript">https://www.api.legitscript</a>
      <em>invalid top level domain (TLD)</em>
    </li>
  </ul>
  <h5>Merchant Category Code (MCC)</h5>
  <p>The merchant category codes now need to match a 3 or 4 digit code specified in the list of retail financial service
    codes outlined in ISO 18245. If the MCC you provide in your call is not a valid code it will be rejected and an
    error will be returned.</p>
  <h5>Timestamps</h5>
  <p>Timestamps are returned in ISO 8601 format.</p>
  <hr/>
</section>