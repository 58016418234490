import { Component } from '@angular/core';

@Component({
  selector: 'alerts',
  templateUrl: './alerts.component.html',
})
export class AlertsComponent {
  // public alerts = ['Alert a', 'Alert b'];
  public alerts = [];
}
