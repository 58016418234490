import { Component } from '@angular/core';
import { MM_V4_DOC_MIGRATION_DETAILS } from '../../../constants';

@Component({
  selector: 'v4-docs',
  templateUrl: 'v4-docs.component.html',
  styleUrls: ['v4-docs.component.scss']
})
export class V4DocsComponent {
  public migrationDetails = MM_V4_DOC_MIGRATION_DETAILS;
}
