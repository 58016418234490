import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  ApplyFilterSetAction,
  DeleteFilterSetAction,
  DeleteFilterSetSuccessAction,
  GetSavedFilterSetsAction,
  GetSavedFilterSetsSuccessAction,
  SaveFilterSetAction,
  SaveFilterSetSuccessAction,
  SetDefaultFilterSetAction,
  SetFavoriteFilterSetAction,
  SetFavoriteFilterSetSuccessAction,
  SetUnnammedFilterSetAction,
  UpdateFilterSetAction,
  UpdateFilterSetSuccessAction,
} from '../reducers/filter-sets.reducer';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import {
  FilterSet,
  FilterType,
  GenericNotificationAction,
  SeverityOptions,
  TableFilter,
} from '@ls/common-ng-components';
import { SavedFiltersService } from '../services/saved-filters.service';
import { AppState } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import {
  fnMerchantListSavedFiltersState,
  fnMerchantListTableFiltersAppliedFilterSetState,
  fnMerchantListTableFiltersAppliedFiltersState,
  fnMerchantListTableFiltersByName,
  fnMerchantListTableFiltersSavedFilterSetsState,
  fnMerchantListTableFiltersVisibleFiltersState,
} from '../../merchant-monitoring/reducers';
import { ApplyFiltersAction } from '../reducers/tableFilters.reducer';

@Injectable()
export class SavedFiltersEffect {
  constructor(
    private action$: Actions,
    private filtersService: SavedFiltersService,
    private store: Store<AppState>,
  ) {}

  public getSavedFilterSets$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetSavedFilterSetsAction),
      switchMap(() =>
        this.filtersService.getSavedFilterSets().pipe(
          map((savedFilters) => GetSavedFilterSetsSuccessAction({ filterSets: savedFilters })),
          catchError((err) => {
            return of(
              GenericNotificationAction({
                severity: SeverityOptions.ERROR,
                summary: 'Could not load your saved filters',
                detail: err?.error?.message ?? err?.error ?? err,
                sticky: false,
              }),
            );
          }),
        ),
      ),
    ),
  );

  public saveFilterSet$ = createEffect(() =>
    this.action$.pipe(
      ofType(SaveFilterSetAction),
      concatLatestFrom(() => [
        this.store.select(fnMerchantListTableFiltersAppliedFiltersState),
        this.store.select(fnMerchantListTableFiltersVisibleFiltersState),
        this.store.select(fnMerchantListTableFiltersAppliedFilterSetState),
      ]),
      switchMap(([action, appliedFilters, visibleFilters, activeFilterSet]) => {
        const combinedFilters = [...visibleFilters].map((vf) => {
          const appliedFilter = appliedFilters.find((af) => af.displayName === vf.displayName);
          return appliedFilter ? appliedFilter : vf;
        });

        const tableFilterNamesAndValues = combinedFilters.map((f) => {
          const hasExactMatch = 'exactMatch' in f;
          return {
            name: f.displayName,
            values: f.value,
            ...(hasExactMatch && { exactMatch: f.exactMatch }),
          };
        });
        if (action.saveAsName) {
          const filterSet: FilterSet = {
            id: -1,
            filterName: action.saveAsName ?? `New Filter ${new Date().toISOString()}`,
            isFavorite: false,
            tableFilterNamesAndValues,
          };
          return this.filtersService.createFilterSet(filterSet).pipe(
            map((filterSet: FilterSet) => SaveFilterSetSuccessAction({ filterSet })),
            catchError((err) =>
              of(
                GenericNotificationAction({
                  severity: SeverityOptions.ERROR,
                  summary: 'Filter configuration was NOT saved',
                  detail: err?.error?.message ?? err?.error ?? err,
                  sticky: false,
                }),
              ),
            ),
          );
        } else {
          const filterSet: FilterSet = {
            ...activeFilterSet,
            tableFilterNamesAndValues,
          };
          return this.filtersService.updateFilterSet(filterSet).pipe(
            map((filterSet: FilterSet) => UpdateFilterSetSuccessAction({ filterSet })),
            catchError((err) =>
              of(
                GenericNotificationAction({
                  severity: SeverityOptions.ERROR,
                  summary: 'Filter configuration was NOT saved',
                  detail: err?.error?.message ?? err?.error ?? err,
                  sticky: false,
                }),
              ),
            ),
          );
        }
      }),
    ),
  );

  public updateFilterSet$ = createEffect(() =>
    this.action$.pipe(
      ofType(UpdateFilterSetAction),
      concatLatestFrom(() => [
        this.store.select(fnMerchantListTableFiltersAppliedFiltersState),
        this.store.select(fnMerchantListTableFiltersVisibleFiltersState),
      ]),
      switchMap(([action, appliedFilters, visibleFilters]) => {
        const combinedFilters = [...visibleFilters].map((vf) => {
          const appliedFilter = appliedFilters.find((af) => af.displayName === vf.displayName);
          return appliedFilter ? appliedFilter : vf;
        });
        const filterSet = {
          ...action.filterSet,
          filterName: action.name ?? action.filterSet.filterName,
          tableFilterNamesAndValues: combinedFilters.map((f) => {
            const hasExactMatch = 'exactMatch' in f;

            return {
              name: f.displayName,
              values: f.value,
              ...(hasExactMatch && { exactMatch: f.exactMatch }),
            };
          }),
        };
        return this.filtersService.updateFilterSet(filterSet).pipe(
          map((filterSet: FilterSet) => UpdateFilterSetSuccessAction({ filterSet })),
          catchError((err) =>
            of(
              GenericNotificationAction({
                severity: SeverityOptions.ERROR,
                summary: 'Filter configuration was NOT saved',
                detail: err?.error?.message ?? err?.error ?? err,
                sticky: false,
              }),
            ),
          ),
        );
      }),
    ),
  );

  public deleteFilterSet$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeleteFilterSetAction),
      switchMap((action) =>
        this.filtersService.deleteFilterSet(action.filterSet).pipe(
          map((filterSet: FilterSet) => DeleteFilterSetSuccessAction({ filterSet })),
          catchError((err) =>
            of(
              GenericNotificationAction({
                severity: SeverityOptions.ERROR,
                summary: 'Filter was NOT deleted',
                detail: err?.error?.message ?? err?.error ?? err,
                sticky: false,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public setFavoriteFilterSet$ = createEffect(() =>
    this.action$.pipe(
      ofType(SetFavoriteFilterSetAction),
      concatLatestFrom(() => [this.store.select(fnMerchantListTableFiltersSavedFilterSetsState)]),
      switchMap(([action, filterSets]) =>
        this.filtersService.favoriteFilterSet(action.filterSet).pipe(
          map(() => {
            const newFilterSets = filterSets.map((fs) => ({
              ...fs,
              isFavorite: fs.id === action.filterSet.id,
            }));
            return SetFavoriteFilterSetSuccessAction({ filterSets: newFilterSets });
          }),
          catchError((err) =>
            of(
              GenericNotificationAction({
                severity: SeverityOptions.ERROR,
                summary: 'Could not update default filters',
                detail: err?.error?.message ?? err?.error ?? err,
                sticky: false,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public setInitialFilters$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetSavedFilterSetsSuccessAction, SetDefaultFilterSetAction),
      concatLatestFrom(() => [
        this.store.select(fnMerchantListTableFiltersByName),
        this.store.select(fnMerchantListSavedFiltersState),
      ]),
      filter(([, , savedFilterState]) => !!savedFilterState.appliedFilterSet),
      map(([, filtersByName, savedFilterState]) => {
        const filters = savedFilterState.appliedFilterSet.tableFilterNamesAndValues
          .filter((f) => filtersByName[f.name])
          .map((f) => {
            const hasExactMatch = 'exactMatch' in f;
            return {
              ...filtersByName[f.name],
              value:
                f.values && filtersByName[f.name].filterType === FilterType.date_range
                  ? (f.values as [string, string]).every((d) => d !== null && d !== undefined)
                    ? (f.values as [string, string]).map((d) => new Date(d))
                    : [null, null]
                  : f.values,
              ...(hasExactMatch && { exactMatch: f.exactMatch }),
            };
          });
        return ApplyFiltersAction({ filters: filters as TableFilter[], filterSet: savedFilterState.appliedFilterSet });
      }),
    ),
  );

  public setFilters$ = createEffect(() =>
    this.action$.pipe(
      ofType(ApplyFilterSetAction),
      concatLatestFrom(() => [this.store.select(fnMerchantListTableFiltersByName)]),
      map(([action, filtersByName]) => {
        const result = ApplyFiltersAction({
          filters: action.filterSet.tableFilterNamesAndValues
            .filter((f) => filtersByName[f.name])
            .map((f) => {
              const isDateRange = filtersByName[f.name].filterType === FilterType.date_range;
              let value = f.values;

              if (isDateRange && value) {
                value = (value as [string, string]).every((d) => d !== null && d !== undefined)
                  ? (value as [string, string]).map((d) => {
                      if (d !== null && d !== undefined) {
                        const date = new Date(d);
                        if (isNaN(date.getTime())) {
                          throw new Error('Invalid date');
                        }
                        return date;
                      } else {
                        return null;
                      }
                    })
                  : [null, null];
              }

              return {
                ...filtersByName[f.name],
                value: value,
                exactMatch: f.exactMatch,
              } as TableFilter;
            }),
          filterSet: action.filterSet,
        });
        return result;
      }),
      catchError((err) =>
        of(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Could not set filters',
            detail: err?.error?.message ?? err?.error ?? err,
            sticky: false,
          }),
        ),
      ),
    ),
  );

  public setUnnammedFilters$ = createEffect(() =>
    this.action$.pipe(
      ofType(ApplyFiltersAction),
      filter((applyFiltersAction) => !applyFiltersAction.filterSet),
      map(() => SetUnnammedFilterSetAction()),
    ),
  );
}
