<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<h1>You’re one step closer to verifying the legitimacy of high-risk merchant websites</h1>

<section class="overview">
  <picture class="mobile-hide">
    <img src="../assets/img/website-lookup.png">
  </picture>
  <div>
    <h2>Website database access is straightforward:</h2>
    <ul>
      <li>Choose your website database search plan</li>
      <li>Type a website address into the website legitimacy search box</li>
      <li>The number of searches you can make is based upon your monthly search plan</li>
      <li>Each month your plan resets automatically until cancelled</li>
    </ul>
  </div>
</section>

<section class="pricing">
  <h2>Select your usage plan:</h2>
  <loading-spinner [loadingText]="'Loading Pricing'" *ngIf="loadingPricing; else pricing"></loading-spinner>
  <ng-template #pricing>
    <input type="range" [(ngModel)]="productChoiceIndex" (input)="updateProductChoice()" [max]="products.length -1" step="1">
    <p *ngIf="productChoice">
      {{productChoice.name.replace('Website Database Search - ','') | number:"1.0"}}
      requests per month for
      <span class="amount">${{productChoice.price | number:"1.0"}}</span>*
    </p>
    <p>* Initial payment is prorated for days remaining in the current month</p>
  </ng-template>
</section>

<section class="agree">
  <input #termsCheckbox="ngModel" [ngModelOptions]="{standalone: true}" id="terms-checkbox" name="terms-checkbox" type="checkbox" (change)="onTermsChecked($event)" [(ngModel)]="termsSelected" required/>
  <label for="terms-checkbox">
    I have read and agree to LegitScript's <a (click)="termsModal.showDialog()">Terms of Service</a>
  </label>
  <br>
  <button class="btn btn-primary" (click)="goToPayment()" [disabled]="productChoiceIndex === undefined || !termsSelected">Continue to Payment</button>
</section>

<legitscript-modal #termsModal [draggable]=false [resizable]=false [responsive]=true [closable]=true
  [modal]=true autoAlign=true [width]=800 height=auto>
  <div class="dialog-body" style="overflow:auto; height:75vh"  libScrollEvent (onscroll)="onDialogScroll($event)" [bottomOffset]=10 [topOffset]=50>
    <data-subscription-terms></data-subscription-terms>
  </div>
  <div class="dialog-footer">
    <button type="button" (click)="onAgreeToTerms();" label="Yes" [disabled]="hasScrolledToBottom === false" >I Agree</button>
  </div>
</legitscript-modal>
