<div class="cert-questionnaire-wrapper">
  <div class="cert-questionnaire-container" style="display:block;">
    <div class="questionnaire-complete">
      <h1>Application Submitted</h1>
      <p>Thank you for completing your application. It has been received by LegitScript's Certification Team.</p>
      <p>If you have any questions regarding your application or any other LegitScript service, do not hesitate to <a href="mailto:{{ certificationEmail }}">contact us</a>.</p>
      <div>
        <button routerLink="/account/home">Return to Certification Home</button>
      </div>
    </div>
    <loading-spinner *ngIf="(questionnaire$ | async).pending; else loaded" loadingText="Loading Your Answers"></loading-spinner>
    <ng-template #loaded>
      <questionnaire-review [questions]="questions" [answers]="answers"></questionnaire-review>
    </ng-template>
  </div>
</div>