import { Label } from '@ls/common-ts-models';
import { createAction, createReducer, on, props } from '@ngrx/store';

export const GET_LSTAGS = '[LsTags] Get';
export const GET_LSTAGS_SUCCESS = '[LsTags] Get Success';
export const LSTAGS_ERROR = '[LsTags] Tags Error';

export const GetLsTagsAction = createAction(GET_LSTAGS);

export const GetLsTagsSuccessAction = createAction(GET_LSTAGS_SUCCESS, props<{ tags: Label[] }>());

export const LsTagsErrorAction = createAction(LSTAGS_ERROR, props<{ err?: Error }>());

export interface LsTagsState {
  err?: Error;
  tags: Label[];
  pending: boolean;
}

export const initialLsTagsState: LsTagsState = {
  err: null,
  tags: [],
  pending: true,
};

export const LsTagsReducer = createReducer(
  initialLsTagsState,

  on(GetLsTagsSuccessAction, (state) => ({
    ...state,
    tags: [],
    err: null,
    pending: true,
  })),

  on(GetLsTagsSuccessAction, (state, { tags }) => ({
    ...state,
    err: null,
    tags: tags,
    pending: false,
  })),

  on(LsTagsErrorAction, (state, { err }) => ({
    ...state,
    err,
    pending: false,
  })),
);
