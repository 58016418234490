import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[field-match]',
  providers: [{ provide: NG_VALIDATORS, useExisting: FieldMatchDirective, multi: true }]
})
export class FieldMatchDirective implements Validator {
  @Input('field-match') public compareFieldName: string;

  public validate(ourControl: AbstractControl): ValidationErrors | null {
    // Get our value.
    const ourValue = ourControl.value;

    // Get other control.
    const otherControl = ourControl.root.get(this.compareFieldName);
    const otherControlValue = otherControl ? otherControl.value : '';

    if (ourValue === otherControlValue) {
      return null;
    } else {
      return {
        'match-fail': this.compareFieldName
      };
    }
  }
}
