import { Subject, Observable } from 'rxjs';
import { map, skipWhile, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  MyLSListMerchant,
  Pagination,
  WBFilterSortDirection,
  MyLSMerchantSortFields,
  PageUpdateOptions,
  MyLSMerchantSortFieldKeys,
  CustomerActionsDisplay,
  MyLsFilterGroup,
  RECORD_VALIDATION,
  ProductFamily,
  CustomerAction,
  MyLSMerchant,
  MyLSProcessNotificationStatus,
  MyLSIoTTopicSuffix,
  MyLSPushNotificationJobMessage,
  MyLSMerchantConstants,
} from '@ls/common-ts-models';
import {
  EntitlementsGetAction,
  EntitlementsState,
  LocalStorageService,
  SeverityOptions,
  GenericNotificationAction,
} from '@ls/common-ng-components';
import {
  MerchantListAction,
  MerchantListState,
  initialMerchantListState,
  DirtyMerchantsState,
  fnDirtyMerchantState,
  GetDirtyMerchantsAction,
  MerchantListGlobalCheckboxAction,
  MerchantListChildCheckboxAction,
} from '../reducers';
import { DatePipe } from '@angular/common';
import {
  AccountState,
  AppState,
  PushNotificationAppendMessageAction,
  fnMerchantMonitoringState,
} from '../../../reducers';
import { Router, ActivatedRoute } from '@angular/router';
import { ContentLabelsService, MMMerchantService, S3DownloadService } from '../services';
import { MMConfirmBulkActionComponent } from '../confirm-bulk-action/confirm-bulk-action.component';
import {
  fnMerchantListCheckboxState,
  initialMerchantListCheckboxState,
  MerchantListCheckboxState,
} from '../reducers/checkbox-merchants.reducer';
import { TableLazyLoadEvent } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { isFeatureAvailable } from '../../../../../config/helpers';
/* tslint:disable no-var-requires */

type PrimeNGSortDirection = 1 | -1;

@Component({
  selector: 'merchant-list',
  styleUrls: ['./merchant-list.component.scss'],
  templateUrl: './merchant-list.component.html',
})
export class MMMerchantListComponent implements OnInit, OnDestroy {
  @ViewChild(MMConfirmBulkActionComponent) public bulkActionModal: MMConfirmBulkActionComponent;

  public loadingText = 'Loading Merchants';
  public merchants: MyLSListMerchant[];
  public merchantLoadError = false;
  public columns: any[] = [];

  public accountState$: Observable<AccountState>;

  public merchantCheckboxState$: Observable<MerchantListCheckboxState>;
  public merchantCheckboxState: MerchantListCheckboxState = initialMerchantListCheckboxState;

  public merchantsState$: Observable<MerchantListState>;
  public merchantsState: MerchantListState = initialMerchantListState;
  public pagination$: Observable<Pagination>;
  public pagination: Pagination = {
    totalRecords: 0,
    totalPages: 0,
    pageNumber: 1,
    startPosition: 0,
    endPosition: 0,
  };
  public isInitiallyBusy = true;
  public downloadInProgress = false;
  public pageSize: number = MyLSMerchantConstants.MAX_MERCHANTS_PER_PAGE;
  public sortFields = MyLSMerchantSortFields;
  public unsortableFields = ['ls_actions', 'ls_tags', 'content_labels', 'customer_tags'];
  public primeNgSortDirection: PrimeNGSortDirection = 1;
  public primeNgSortField = 'submitted_date';
  public emptyFieldPlaceHolder = '';
  public customerActions: Array<{ label: string; value: CustomerAction }>;
  public currentAction: CustomerAction;

  public filterLoading = true;
  public icas: any[] = [];
  public contentLabels: Array<{ label: string; value: any }> = [];
  public filters: MyLsFilterGroup = {
    filters: [],
  };
  public uploadHistoryEnabled: boolean = environment.CONFIG.uploadHistoryEnabled || false;
  public notificationsEnabled = false;
  public isServiceAccessEnabled = false;
  public bulkEnabled = false;

  private dirtyMerchantsState$: Observable<DirtyMerchantsState>;
  private dirtyMerchants: DirtyMerchantsState = [];

  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<AppState>,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private merchantService: MMMerchantService,
    private s3DownloadService: S3DownloadService,
    private localStorageService: LocalStorageService,
    private contentLabelsService: ContentLabelsService,
  ) {}

  public ngOnInit() {
    // Needed to determine whether the customer can upload merchants
    this.store.dispatch(EntitlementsGetAction());

    this.store
      .select('entitlements')
      .pipe(
        skipWhile((entitlements: EntitlementsState) => entitlements.pending),
        takeUntil(this.destroyed$),
      )
      .subscribe((entitlements) => {
        const mmProduct = entitlements.products && entitlements.products[ProductFamily.MERCHANT_MONITORING];

        this.isServiceAccessEnabled = mmProduct && Object.hasOwn(mmProduct, 'serviceAccessEnabled');
      });

    this.merchantService
      .listMerchantICAs()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (icas) => {
          this.icas = icas
            .filter((item: string | any[]) => {
              return item && item.length;
            })
            .map((item: string) => {
              return { label: item + '', value: item };
            });
          this.filterLoading = false;
        },
        () => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Merchant ICAs Failed to Load',
              detail:
                'Sorry- it looks like we are having trouble loading your merchant ICAs - please try again.  If this problem persists, please click the Support button at top right',
              sticky: true,
              blocking: false,
            }),
          );
          this.filterLoading = false;
        },
      );

    this.contentLabelsService
      .getContentLabels()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (contentLabels) => {
          this.contentLabels = contentLabels.contentLabels
            .map((label: { label: any; id: any }) => {
              return {
                label: label.label,
                value: label.id,
              };
            })
            .sort((a: { label: number }, b: { label: number }) => (a.label < b.label ? -1 : 1));
          this.contentLabels.unshift({ label: '---', value: null });
        },
        () => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Content Labels failed to load',
              detail:
                'Sorry- it looks like we are having trouble loading the Content Labels options. If this problem persists, please click the Support button at top right',
              sticky: true,
              blocking: false,
            }),
          );
        },
      );

    // Can probably do something fancy with types like in
    // ts-models/src/monitoring/ui/filters/definitions but this
    // seems so much cleaner
    this.columns = [
      { field: 'mid', header: 'MID' },
      { field: 'submitted_date', header: 'Submission date' },
      {
        field: 'last_updated_by_legitscript_on',
        header: 'Last Updated By LegitScript On',
      },
      { field: 'url', header: 'Authoritative URL' },
      { field: 'merchant_country', header: 'Merchant Country' },
      { field: 'merchant_name', header: 'Merchant Name' },
      { field: 'ls_actions', header: 'LS actions' },
      { field: 'ls_tags', header: 'LS tags' },
      { field: 'content_labels', header: 'Content labels' },
      { field: 'your_action', header: 'Your action' },
      { field: 'your_action_date', header: 'Your action date' },
      { field: 'customer_tags', header: 'Tags' },
      { field: 'status', header: 'Status' },
    ];

    this.store
      .select('account')
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe((accountState: AccountState) => {
        if (accountState) {
          this.bulkEnabled = isFeatureAvailable('mmBulkEnabled', accountState.lsAccountId);
          this.notificationsEnabled = isFeatureAvailable('notificationsEnabled', accountState.lsAccountId);
        }
      });

    this.merchantsState$ = this.store.select(fnMerchantMonitoringState).pipe(map((x) => x.merchantList));
    this.merchantsState$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      this.merchantsState = state;
      if (state.err) {
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Merchant List Failed to Load',
            detail:
              'Sorry- it looks like we are having trouble displaying your merchants - please try again.  If this problem persists, please click the Support button at top right',
            sticky: true,
            blocking: false,
          }),
        );
      }
      if (!state.pending) {
        this.isInitiallyBusy = false;
      }
    });

    this.dirtyMerchantsState$ = this.store.select(fnDirtyMerchantState);
    this.dirtyMerchantsState$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      this.dirtyMerchants = state;
    });

    this.merchantCheckboxState$ = this.store.select(fnMerchantListCheckboxState);
    this.merchantCheckboxState$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      this.merchantCheckboxState = state;
    });

    this.store.dispatch(GetDirtyMerchantsAction());

    this.pagination$ = this.merchantsState$.pipe(map((x) => x.pagination));
    this.pagination$.pipe(takeUntil(this.destroyed$)).subscribe((pagination) => (this.pagination = pagination));

    const filtersToSend = this.getFilters();
    const sortOptionsToSend = this.getSortOptions();

    this.primeNgSortDirection = this.ensurePrimeNGSortDirection(sortOptionsToSend.sortDirection);
    this.primeNgSortField = sortOptionsToSend.sortField;

    this.customerActions = CustomerActionsDisplay.map((action) => {
      return {
        label: action.text,
        value: action.value,
      };
    });
    this.currentAction = 'Take action on selected merchants' as CustomerAction;

    const sortField = sortOptionsToSend.sortField;
    const sortOrder = this.ensureWbFilterDirection(sortOptionsToSend.sortDirection) as WBFilterSortDirection;

    this.store.dispatch(
      MerchantListAction({
        sortField,
        sortDirection: sortOrder,
        pageUpdateOptions: {
          pageNumber: 1,
          pageSize: MyLSMerchantConstants.MAX_MERCHANTS_PER_PAGE,
        },
        filters: filtersToSend,
        actionPositions: [],
        globalCheckbox: false,
      }),
    );
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public loadMerchantsLazy(event: TableLazyLoadEvent) {
    this.localStorageService.setMerchantSortOptions({
      sortField: event.sortField as MyLSMerchantSortFieldKeys,
      sortDirection: this.getReadableSortDirection(event.sortOrder as PrimeNGSortDirection),
    });
    const sortField = event.sortField as MyLSMerchantSortFieldKeys;
    const sortOrder = this.getReadableSortDirection(event.sortOrder as PrimeNGSortDirection);

    this.store.dispatch(
      MerchantListAction({
        sortField,
        sortDirection: sortOrder,
        pageUpdateOptions: {
          pageNumber: this.getPageNumber(event.first),
        } as PageUpdateOptions,
        filters: this.filters,
        actionPositions: this.merchantCheckboxState.actionPositions,
        globalCheckbox: this.merchantCheckboxState.globalCheckbox,
      }),
    );
  }

  public onFilterChange(event: MyLsFilterGroup) {
    this.filters = event;
    this.localStorageService.setMerchantFilters(event);
    const sortField = this.merchantsState.sortField as MyLSMerchantSortFieldKeys;
    const sortOrder = this.merchantsState.sortDirection;

    this.store.dispatch(
      MerchantListAction({
        sortField,
        sortDirection: sortOrder,
        pageUpdateOptions: {
          pageNumber: this.merchantsState.pagination.pageNumber,
        } as PageUpdateOptions,
        filters: this.filters,
        actionPositions: this.merchantCheckboxState.actionPositions,
        globalCheckbox: this.merchantCheckboxState.globalCheckbox,
      }),
    );
  }

  public handleRowClick(event: { correlation_id: any }) {
    const correlationId = event.correlation_id;
    this.router.navigate([`detail/${correlationId}`], {
      relativeTo: this.route.parent,
    });
  }

  public handleGlobalCheckboxClick() {
    this.store.dispatch(
      MerchantListGlobalCheckboxAction({
        actionPositions: this.merchantCheckboxState.actionPositions,
        globalCheckbox: this.merchantCheckboxState.globalCheckbox,
        totalRecords: this.merchantsState.pagination.totalRecords,
      }),
    );
  }

  public handleChildCheckboxClick(index: number) {
    if (!this.merchantsState.merchants) {
      return;
    }

    const currentCorrelationId =
      this.merchantsState.merchants[index % MyLSMerchantConstants.MAX_MERCHANTS_PER_PAGE].correlation_id;
    const checkboxIndex = this.merchantCheckboxState.actionPositions.findIndex(
      (actionPosition) => actionPosition.correlationId === currentCorrelationId,
    );

    if (checkboxIndex < 0) {
      return;
    }

    this.store.dispatch(
      MerchantListChildCheckboxAction({
        actionPositions: this.merchantCheckboxState.actionPositions,
        globalCheckbox: this.merchantCheckboxState.globalCheckbox,
        totalRecords: this.merchantsState.pagination.totalRecords,
        checkboxIndex,
      }),
    );
  }

  public getCheckboxPosition(index: number): boolean {
    const currentCorrelationId =
      this.merchantsState.merchants[index % MyLSMerchantConstants.MAX_MERCHANTS_PER_PAGE].correlation_id;
    const currentPosition = this.merchantCheckboxState.actionPositions.find(
      (actionPosition) => currentCorrelationId === actionPosition.correlationId,
    );

    return currentPosition ? currentPosition.checked : false;
  }

  public getSelectedMerchants(): MyLSMerchant[] {
    return this.merchantCheckboxState.actionPositions
      .filter((actionPos) => actionPos.checked)
      .map((actionPos) => {
        return { correlation_id: actionPos.correlationId, update_date: actionPos.updateDate } as MyLSMerchant;
      });
  }

  public handleShowConfirmBulkAction() {
    if (Object.values(CustomerAction).includes(this.currentAction as CustomerAction)) {
      this.bulkActionModal.showModal();
    }
  }

  public getFieldValue(merchant: MyLSListMerchant, field: keyof MyLSListMerchant) {
    switch (field) {
      case 'submitted_date':
      case 'last_updated_by_legitscript_on':
      case 'your_action_date':
        return this.datePipe.transform(merchant[field], 'short') || this.emptyFieldPlaceHolder;
      case 'content_labels':
      case 'ls_tags':
      case 'customer_tags':
        return merchant[field].join(', ') || this.emptyFieldPlaceHolder;
      case 'your_action': {
        let retVal = this.emptyFieldPlaceHolder;
        CustomerActionsDisplay.some((display) => {
          if (display.value === merchant[field]) {
            retVal = display.text;
            return true;
          } else {
            return false;
          }
        });
        return retVal;
      }
      default:
        return merchant[field] || this.emptyFieldPlaceHolder;
    }
  }

  public handleS3DownloadRequest() {
    if (this.merchantsState.pagination.totalRecords > RECORD_VALIDATION.maxNumRecords) {
      this.dispatchMessage(
        SeverityOptions.ERROR,
        'Result set is too large for download.',
        `Please restrict your search to less than ${RECORD_VALIDATION.maxNumRecords} results.`,
      );
      return;
    }

    this.downloadInProgress = true;
    const request = this.s3DownloadService.requestDownloadUrl(this.merchantsState.filters);
    request.subscribe(
      (response: any) => {
        const spreadSheetDownloadWasRequestedMessage: MyLSPushNotificationJobMessage = {
          title: 'We’re preparing your download',
          description: 'Your file will be available to download from the alerts icon shortly.',
          scopedTopic: `myls/${MyLSIoTTopicSuffix.SPREADSHEET_DOWNLOAD}`,
          processStatus: MyLSProcessNotificationStatus.SUCCESS,
          jobId: response.jobId,
          isDismissed: false,
          currentStatus: undefined,
          isFinalJobMessage: false,
        };

        this.downloadInProgress = false;

        if (this.notificationsEnabled) {
          this.store.dispatch(
            PushNotificationAppendMessageAction({ notification: spreadSheetDownloadWasRequestedMessage }),
          );
        } else {
          this.dispatchMessage(
            SeverityOptions.SUCCESS,
            'We’re preparing your download',
            'A link to your file will be emailed to you shortly.',
          );
        }
      },
      (errorResponse: any) => {
        this.downloadInProgress = false;
        console.log(`Console error returned ${JSON.stringify(errorResponse)}`);
        console.log(`JobId was ${errorResponse.jobId}`);
        const spreadSheetDownloadFailedMessage: MyLSPushNotificationJobMessage = {
          title: 'An error occurred while preparing your download.',
          description: 'Please try again.  If this problem persists, please contact support',
          scopedTopic: `myls/${MyLSIoTTopicSuffix.SPREADSHEET_DOWNLOAD}`,
          processStatus: MyLSProcessNotificationStatus.ERROR,
          jobId: errorResponse.jobId,
          isDismissed: false,
          currentStatus: undefined,
          isFinalJobMessage: true,
        };

        if (this.notificationsEnabled) {
          this.store.dispatch(
            PushNotificationAppendMessageAction({
              notification: spreadSheetDownloadFailedMessage,
            }),
          );
        } else {
          this.dispatchMessage(
            SeverityOptions.ERROR,
            'An error occurred while preparing your download.',
            'Please try again.  If this problem persists, please contact support',
          );
        }
      },
    );
  }

  public isMerchantDirty(correlationId: string) {
    return !!this.dirtyMerchants.find((merchant) => merchant.correlationId === correlationId);
  }

  public multipleMerchantURLs(merchant: MyLSMerchant, field: string) {
    return Array.isArray(merchant[field]) && merchant[field].length > 1;
  }

  private getFilters() {
    const localFilters = this.localStorageService.getMerchantFilters();
    const defaultLsFilters = { filters: [] } as MyLsFilterGroup;
    return localFilters || defaultLsFilters;
  }

  private getSortOptions() {
    const localSortOptions = this.localStorageService.getMerchantSortOptions();
    const defaultSortOptions = {
      sortField: 'submitted_date',
      sortDirection: WBFilterSortDirection.Ascending,
    };
    return localSortOptions || defaultSortOptions;
  }

  private getPageNumber(startPosition: number) {
    if (startPosition === 0) {
      return 1;
    }
    return Math.ceil(startPosition / this.pageSize) + 1;
  }

  private ensureWbFilterDirection(order: WBFilterSortDirection | PrimeNGSortDirection): WBFilterSortDirection {
    return Object.values(WBFilterSortDirection).includes(order as any)
      ? (order as WBFilterSortDirection)
      : this.getReadableSortDirection(order as PrimeNGSortDirection);
  }

  private ensurePrimeNGSortDirection(order: WBFilterSortDirection | PrimeNGSortDirection): PrimeNGSortDirection {
    return Object.values(WBFilterSortDirection).includes(order as any)
      ? this.getPrimeNGSortDirection(order as WBFilterSortDirection)
      : (order as PrimeNGSortDirection);
  }

  private getReadableSortDirection(order: PrimeNGSortDirection): WBFilterSortDirection {
    return order === 1 ? WBFilterSortDirection.Ascending : WBFilterSortDirection.Descending;
  }

  private getPrimeNGSortDirection(direction: WBFilterSortDirection): PrimeNGSortDirection {
    return direction === WBFilterSortDirection.Ascending ? 1 : -1;
  }

  private dispatchMessage(severity: SeverityOptions, title: string, message: string) {
    this.store.dispatch(
      GenericNotificationAction({
        severity,
        summary: title,
        detail: message,
        sticky: true,
        blocking: true,
      }),
    );
  }
}
