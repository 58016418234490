<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="tag-selector">
  <span class="tag-container" [class.fails-validation]="!isValid" [class.calc-width]="editAble" [class.full-width]="!editAble">
    <span class="tag" [class.remove-enabled]="removeEnabled" *ngFor="let selectedTag of listedTags">
      <span>{{ selectedTag }}</span>
      <ng-container *ngIf="editAble">
        <span class="remove-tag-icon" (click)="removeTag(selectedTag)">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </ng-container>
    </span>
  </span>

  <ng-container *ngIf="editAble">
    <span class="auto-complete-container">
      <input (blur)="blurEventHandler()" (focus)="focusEventHandler()" [(ngModel)]="inputText"
        (keyup.enter)="addTag()" type="text" [disabled]="!isUnderLimit" placeholder="New Tag">
    </span>
  </ng-container>
</div>