import { empty as observableEmpty, Subject } from 'rxjs';

import { takeUntil, catchError } from 'rxjs/operators';
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '../../services';
import { RequestPasswordResetRequest } from '@ls/common-ts-models/src';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'forgot-password',
  templateUrl: 'forgot-password.component.html',
  styleUrls: ['../login/login.component.scss', 'forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnDestroy {
  public done = false;
  public sending = false;
  public recaptchaSiteKey: string = environment.CONFIG.recaptchaSiteKey as string;
  public model: RequestPasswordResetRequest = {
    email: '',
    captchaToken: '',
  };
  public destroyed$: Subject<boolean> = new Subject();

  @ViewChild('captcha') public captcha: { reset: () => void };

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public service: AccountService,
  ) {}

  public resolvedCaptcha(captchaToken: string) {
    this.model.captchaToken = captchaToken;
  }

  public submit(): void {
    this.sending = true;
    this.service
      .requestPasswordReset(this.model)
      .pipe(
        catchError(() => {
          this.sending = false;
          this.captcha.reset();
          return observableEmpty();
        }),
        takeUntil(this.destroyed$.asObservable()),
      )
      .subscribe(() => {
        this.done = true;
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
