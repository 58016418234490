import { NgModule } from '@angular/core';

import { WrapperComponent } from '../pages/wrapper/wrapper.component';
import { AuthenticatedWrapperComponent } from './authenticated-wrapper/authenticated-wrapper.component';
import { LoginHeaderComponent } from './headers/login-header/login-header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header/header.component';
import { BreadcrumbNavComponent } from './header/breadcrumb-nav/breadcrumb-nav.component';
import { AlertsComponent } from './alerts/alerts.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { HelpComponent } from '../pages/help/help.component';
import { HomeComponent } from '../pages/home/home.component';
import { SecuritySettingsComponent } from './account/security/security-settings.component';
import { AccountDetailsComponent } from './account/account-details/account-details.component';
import { EditAccountDetailsComponent } from './account/edit-account-details/edit-account-details.component';
import { TermsComponent } from './terms/terms.component';
import { ConsentComponent } from './forms/consent/consent.component';
import { AccountDetailsWrapperComponent } from '../pages/account-details-wrapper/account-details-wrapper.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { MultiLineInputComponent } from './multi-line-input/multi-line-input.component';
import { TagSelectorComponent } from './tag-selector/tag-selector.component';
// Module imports
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FileUploadModule } from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { MessagesModule } from 'primeng/messages';
import { ProgressBarModule } from 'primeng/progressbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { PaginatorModule } from 'primeng/paginator';
import { SliderModule } from 'primeng/slider';

import { ContactFormService, LegitscriptUiModule } from '@ls/common-ng-components';

import { PaymentPlanComponent } from '../pages/payment-plan/payment-plan.component';
import { PaymentPlanInvoiceComponent } from '../pages/payment-plan-invoice/payment-plan-invoice.component';
import { BillingComponent } from '../pages/billing/billing.component';
import { BillingHistoryComponent } from './billing-history/billing-history.component';

import { FieldMatchDirective } from '../directives/field-match.directive';
import { WhitespaceValidatorDirective } from '../directives/whitespace-validator.directive';
import { InvalidCharacterValidatorDirective } from '../directives/invalid-character-validator.directive';
import { FieldValidatorComponent } from './forms';
import {
  LoginComponent,
  CreatePasswordComponent,
  UpdateInfoComponent,
  V3DocsComponent,
  V3MigrationGuideComponent,
  DocsComponent,
  CreateAccountBeginComponent,
  CreateAccountProductComponent,
  CreateAccountInfoComponent,
  CreateAccountPasswordComponent,
  AccessDeniedComponent,
} from '../pages';
import { LoadingComponent } from '../pages/loading/loading.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { TableModule, TableService } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { MessageService, SharedModule } from 'primeng/api';
import { TagModule } from 'primeng/tag';
import { GalleriaModule } from 'primeng/galleria';
import { UsersComponent } from '../pages/users/users.component';
import { ActiveProductsComponent } from '../components/active-products/active-products.component';
import { AmountDueComponent } from '../components/payment/amount-due/amount-due.component';
import { ConfirmEmailComponent } from '../components/forms/confirm-email/confirm-email.component';
import { UpdateEmailComponent } from '../components/users/update-email/update-email.component';
import { VerifyEmailComponent } from '../pages/verify-email/verify-email.component';
import { MMEnrollService } from '../modules/merchant-monitoring/services';
import { NotificationComponent } from './header/alerts-tool/notification/notification.component';
import { NgxScrollEventModule } from '../modules/ngx-scroll-event/ngx-scroll-event.module';
import { BreadcrumbModule } from '../modules/breadcrumb';

import { CertificationsModule } from '../modules/certifications/certification.module';
import { environment } from 'src/environments/environment';
import { OverflowButtonComponent } from './overflow-button/overfow-button.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

const components = [
  WrapperComponent,
  AuthenticatedWrapperComponent,
  LoginHeaderComponent,
  LoginComponent,
  CreateAccountBeginComponent,
  CreateAccountProductComponent,
  CreateAccountInfoComponent,
  CreateAccountPasswordComponent,
  FieldValidatorComponent,
  SidenavComponent,
  HeaderComponent,
  BreadcrumbNavComponent,
  AlertsComponent,
  NotificationComponent,
  NotFoundComponent,
  HelpComponent,
  HomeComponent,
  AccountDetailsComponent,
  SecuritySettingsComponent,
  PaymentPlanComponent,
  PaymentPlanInvoiceComponent,
  BillingComponent,
  DocsComponent,
  V3DocsComponent,
  V3MigrationGuideComponent,
  TermsComponent,
  ConsentComponent,
  LoadingComponent,
  UserListComponent,
  AccountDetailsWrapperComponent,
  UsersComponent,
  EditAccountDetailsComponent,
  CreatePasswordComponent,
  UpdateInfoComponent,
  EditUserComponent,
  ActiveProductsComponent,
  AmountDueComponent,
  BillingHistoryComponent,
  ConfirmEmailComponent,
  UpdateEmailComponent,
  VerifyEmailComponent,
  MultiLineInputComponent,
  TagSelectorComponent,
  AccessDeniedComponent,
  OverflowButtonComponent,
  AccessDeniedComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    RecaptchaModule,
    BreadcrumbModule,
    ProgressSpinnerModule,
    ToastModule,
    FileUploadModule,
    LegitscriptUiModule.forRoot({
      auth0Domain: environment.CONFIG.auth0Domain,
      auth0ClientId: environment.CONFIG.auth0ClientId,
      auth0RedirectUri: environment.CONFIG.auth0RedirectUri,
      auth0Audience: environment.CONFIG.auth0Audience,
      portalApiHost: environment.CONFIG.portalApiHost,
      paymentServiceHost: environment.CONFIG.paymentServiceHost,
      certificationApiHost: environment.CONFIG.certificationApiHost,
      certificationRelatedApiHost: environment.CONFIG.certificationApiHost,
    }),
    NgxScrollEventModule,
    TooltipModule,
    TableModule,
    MenuModule,
    OverlayPanelModule,
    CardModule,
    InputTextareaModule,
    InputTextModule,
    DialogModule,
    PanelModule,
    MessagesModule,
    ProgressBarModule,
    SelectButtonModule,
    CertificationsModule,
    SkeletonModule,
    PaginatorModule,
    TagModule,
    ButtonModule,
    InputSwitchModule,
    OverlayPanelModule,
    MultiSelectModule,
    CalendarModule,
    SliderModule,
    GalleriaModule,
    TieredMenuModule,
    SharedModule,
    SplitButtonModule,
    ConfirmDialogModule,
  ],
  exports: components,
  providers: [MessageService, MMEnrollService, ContactFormService, TableService],
  declarations: [...components, FieldMatchDirective, WhitespaceValidatorDirective, InvalidCharacterValidatorDirective],
})
export class ComponentsModule {}
