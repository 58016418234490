import { of as observableOf, Observable, from } from 'rxjs';
import { catchError, mergeMap, map, mergeAll, toArray, tap, concatMap, flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Answers } from '../reducers';
import {
  Questionnaire,
  Question,
  AnsweredQuestion,
  Certification,
  INPUT_TYPE,
  UpdatedNumberItemsResponse,
} from '@ls/common-ts-models';
import { LocalStorageService } from '@ls/common-ng-components';
import { environment } from 'src/environments/environment';

@Injectable()
export class CertificationService {
  public certificationServiceHost = environment.CONFIG.certificationApiHost;

  public get certificationApiUrl(): string {
    return `${this.certificationServiceHost}/v1/`;
  }

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {}

  public getCertificationsForAccount() {
    const options = this.localStorageService.getJWTAndSetOptions();

    return this.http.get<Certification[]>(`${this.certificationApiUrl}account/certifications`, options);
  }

  public getUpdatedNumberEnrolledItems(certId: string) {
    const options = this.localStorageService.getJWTAndSetOptions();

    return this.http.get<UpdatedNumberItemsResponse>(
      `${this.certificationApiUrl}certification/${certId}/updatedEnrolledItems`,
      options,
    );
  }

  public approveUpdatedNumberEnrolledItems(certId: string) {
    const options = this.localStorageService.getJWTAndSetOptions();

    return this.http.post(`${this.certificationApiUrl}certification/${certId}/updatedEnrolledItems`, {}, options);
  }

  /**
   * Get a questionnaire and associated answers object with a certification id and specific question id
   * @param certificationId: string
   * @param currentQuestionId: string
   * @param inAnswerLater: boolean tracking if we are displaying questions in the 'answer later' array
   */
  public getQuestionnaireData(
    certificationId: string,
    currentQuestionId: string,
    inAnswerLater: boolean,
  ): Observable<any> {
    const options = this.localStorageService.getJWTAndSetOptions();
    return this.http
      .get<QuestionnaireResponse>(
        `${this.certificationApiUrl}answeredQuestionnaire?certificationId=${certificationId}`,
        options,
      )
      .pipe(
        map((res: QuestionnaireResponse) => {
          const { answeredQuestionnaire, questionnaire } = res;
          // answers may be null?  if so, set to empty array so we can get started with below checks
          if (!answeredQuestionnaire.answeredQuestions) {
            answeredQuestionnaire.answeredQuestions = [];
          }

          const currentQuestion = questionnaire.questions.find((question) => question.id === currentQuestionId);
          if (!currentQuestion) {
            return { errorText: 'No question for this ID' };
          }

          const currentAnswer = answeredQuestionnaire.answeredQuestions.find(
            (answer) => answer.question_id === currentQuestionId,
          );

          const onFirstQuestion =
            !inAnswerLater && questionnaire.questions.findIndex((question) => question.id === currentQuestionId) === 0;

          return {
            questionnaire,
            answers: answeredQuestionnaire,
            currentQuestionId,
            currentAnswer,
            onFirstQuestion,
            inAnswerLater,
          };
        }),
      );
  }

  /**
   * Get a questionnaire and associated answers object with only a certification id
   * @param certificationId: string
   */
  public getQuestionnaireDataWithoutQuestionId(certificationId: string): Observable<any> {
    const options = this.localStorageService.getJWTAndSetOptions();
    return this.http
      .get<QuestionnaireResponse>(
        `${this.certificationApiUrl}answeredQuestionnaire?certificationId=${certificationId}`,
        options,
      )
      .pipe(
        map(({ answeredQuestionnaire, questionnaire }) => {
          let inAnswerLater = false;
          // answers may be null by default in db.  if so, set to empty array so we can get started
          if (!answeredQuestionnaire.answeredQuestions) {
            answeredQuestionnaire.answeredQuestions = [];
          }

          let currentAnswer: AnsweredQuestion, currentQuestionId: string;
          const questionnaireLength = questionnaire.questions.length;
          const answersLength = answeredQuestionnaire.answeredQuestions.length;

          // it's possible that all questions in the answered array are 'dirty' - if so, we want to redirect to the completed screen in the effect
          const isComplete =
            answeredQuestionnaire.answeredQuestions.length === questionnaire.questions.length &&
            answeredQuestionnaire.answeredQuestions.every((answer) => answer.dirty);

          // we also check if this questionnaire has been submitted- if so, we show them their answers but don't allow editing
          const isSubmitted = answeredQuestionnaire.completed;

          if (
            answersLength === 0 &&
            (!answeredQuestionnaire.answerLater || answeredQuestionnaire.answerLater.length === 0)
          ) {
            // if there are no answers and nothing in answerLater array, default to the first question
            currentQuestionId = questionnaire.questions[0].id;
          } else if (answersLength !== questionnaireLength) {
            // there are some answers, but not all questions have a correlated answer object.  loop through questions, grab id, compare to each question_id on an answer in the answers array.  the first question in questions that doesn't have a matching answer and isn't in answerLater is our first question.  If that doesn't exist, the first question in answerLater is our first question.
            for (let i = 0, l = questionnaireLength; i < l; i += 1) {
              const myQuestionId = questionnaire.questions[i].id;
              let existsInAnswerLater: Question, firstQuestionIdInAnswerLater;
              const hasAnswer = answeredQuestionnaire.answeredQuestions.find(
                (answer) => answer.question_id === myQuestionId && answer.dirty,
              );

              if (answeredQuestionnaire.answerLater && answeredQuestionnaire.answerLater.length > 0) {
                firstQuestionIdInAnswerLater = answeredQuestionnaire.answerLater[0].id;
                existsInAnswerLater = answeredQuestionnaire.answerLater.find(
                  (question) => question.id === myQuestionId,
                );
              }

              if (!hasAnswer && !existsInAnswerLater) {
                // found the first unanswered question- break the loop and be sure to reset inAnswerLater to false
                currentQuestionId = myQuestionId;
                inAnswerLater = false;
                break;
              } else {
                // all questions have answers or are in the answer later array- default to the first question in answer later
                currentQuestionId = firstQuestionIdInAnswerLater;
                inAnswerLater = true;
              }
            }
          } else {
            // answers array is pre-populated or full: find first answer that's not marked with the dirty flag - that should have your first question_id
            currentAnswer = answeredQuestionnaire.answeredQuestions.find((answer) => !answer.dirty);
            currentQuestionId = currentAnswer ? currentAnswer.question_id : '';
          }

          return {
            questionnaire,
            answers: answeredQuestionnaire,
            currentQuestionId,
            currentAnswer,
            isComplete,
            isSubmitted,
            inAnswerLater,
          };
        }),
      );
  }

  public getCompletedQuestionnaireData(
    certificationId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _answersFromStore: Answers,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _questionnaireFromStore: Questionnaire,
  ): Observable<any> {
    const options = this.localStorageService.getJWTAndSetOptions();
    return this.http
      .get<QuestionnaireResponse>(
        `${this.certificationApiUrl}answeredQuestionnaire?certificationId=${certificationId}`,
        options,
      )
      .pipe(
        map(({ answeredQuestionnaire, questionnaire }) => {
          return {
            questionnaire,
            answers: answeredQuestionnaire,
            isComplete: true,
          };
        }),
      );
  }

  /**
   * Save question to API and provide values for the next question id
   * @param questionnaire: Questionnaire (full object)
   * @param _question: Question (single question object)
   * @param answers: Answers (full answered_questionnaire object)
   * @param currentQuestionId: string
   * @param newAnswerData: answer object with id property
   * @param inAnswerLater: boolean tracking if we are displaying questions in the 'answer later' array
   */
  public saveQuestionData(
    questionnaire: Questionnaire,
    _question: Question,
    answers: any,
    currentQuestionId: string,
    newAnswerData: any,
    inAnswerLater: boolean,
  ): Observable<any> {
    let currentQuestionIndex = questionnaire.questions.findIndex((q) => q.id === currentQuestionId);
    if (inAnswerLater) {
      currentQuestionIndex = answers.answerLater.findIndex((q) => q.id === currentQuestionId);
    }

    // if this was previously saved to 'answerLater', remove it now
    if (answers.answerLater && answers.answerLater.find((q) => q.id === currentQuestionId)) {
      answers = this.removeFromAnswerLater(answers, currentQuestionId);
    }

    // mark this object in the answered_questions array as 'dirty' (used in completion check)
    newAnswerData.dirty = true;

    // create the form of the object the api expects
    const dataObjForApi = {
      id: newAnswerData.id,
      answered_question: newAnswerData,
      answerLater: answers.answerLater,
    };

    const options = this.localStorageService.getJWTAndSetOptions();
    return this.http
      .patch<Answers>(`${this.certificationApiUrl}answeredQuestionnaire/${answers.id}`, dataObjForApi, options)
      .pipe(
        map((updatedAnswersFromPatch: Answers) => {
          const isComplete =
            updatedAnswersFromPatch.answeredQuestions.filter((answer) => answer.dirty).length ===
            questionnaire.questions.length;

          // if this answer completes the survey, return now
          if (isComplete) {
            return { answers: updatedAnswersFromPatch, currentQuestionId, isComplete, undefined, inAnswerLater };
          }

          let nextQuestionId: string;
          if (inAnswerLater) {
            // we've removed the question from answer later (as we just answered it), so don't increment the index unless there are still questions in answerLater and none at this index- this means the user answered the 'last' question (order-wise) in answerLater and we should loop back to start of answer later
            if (!updatedAnswersFromPatch.answerLater[currentQuestionIndex]) {
              currentQuestionIndex = 0;
            }
            nextQuestionId = updatedAnswersFromPatch.answerLater[currentQuestionIndex].id;
          } else {
            // save success, need to check if this index was the last in questionnaire.questions.  if so, we've moved into 'inAnswerLater' and get the first id from that array
            if (currentQuestionIndex + 1 === questionnaire.questions.length) {
              inAnswerLater = true;
              nextQuestionId = updatedAnswersFromPatch.answerLater[0].id;
            } else {
              nextQuestionId = questionnaire.questions[currentQuestionIndex + 1].id;
            }
          }

          // It's possible we are in a reviewing state and the next question has an answer- provide here just in case
          const nextAnswer: AnsweredQuestion | undefined = updatedAnswersFromPatch.answeredQuestions.find(
            (answer) => answer.question_id === nextQuestionId,
          );

          return {
            answers: updatedAnswersFromPatch,
            nextQuestionId,
            isComplete,
            currentAnswer: nextAnswer,
            inAnswerLater,
          };
        }),
      );
  }

  /**
   * Save (or delete) individual certifiable item answer with the full json bag
   * @param answers: Answers (full answered_questionnaire object)
   * @param newAnswerData: answer object with id property
   * @param deleting: boolean true if deleting an item
   */
  public saveCertItem(answers: any, newAnswerData: any, deleting = false): Observable<any> {
    const dataObjForApi = {
      id: newAnswerData.id,
      answered_question: newAnswerData,
      answerLater: answers.answerLater,
    };
    const answerRows = dataObjForApi.answered_question.answered_inputs[0].answer_rows;
    const options = this.localStorageService.getJWTAndSetOptions();
    let uploadError = false;

    if (deleting) {
      return this.saveCertItemWithoutUpload(answers.id, dataObjForApi, options, uploadError);
    }

    const fileUploadAnswers = answerRows.length
      ? answerRows[answerRows.length - 1].row_input_answers.filter((f) => f.input_type === INPUT_TYPE.FILE_UPLOAD)
      : [];

    if (fileUploadAnswers && fileUploadAnswers.length) {
      return from(fileUploadAnswers).pipe(
        concatMap((fileUploadAnswer: any) =>
          this.uploadFiles(
            fileUploadAnswer.files,
            answers.certificationId,
            newAnswerData.question_id,
            fileUploadAnswer.id,
          ).pipe(
            tap((uploadedFilenames: UploadFileItem[] = []) => {
              const uploadErrorString = 'UPLOAD_ERROR_';
              if (uploadedFilenames.some((f) => f.name.startsWith(uploadErrorString))) {
                uploadError = true;
              }

              const fileAnswerToUpdate = fileUploadAnswers.find((f) => f.id === fileUploadAnswer.id);
              fileAnswerToUpdate.files = uploadedFilenames.map((file) => ({ name: file.name, awsName: file.awsName }));
            }),
            catchError((err) => observableOf(err)),
          ),
        ),
        toArray(),
        flatMap(() => this.saveCertItemWithoutUpload(answers.id, dataObjForApi, options, uploadError)),
      );
    } else {
      return this.saveCertItemWithoutUpload(answers.id, dataObjForApi, options, uploadError);
    }
  }

  /**
   * Get the previous question to pass to load method
   * @param questionnaire: Questionnaire (full object)
   * @param answers: Answers (full answered_questionnaire object)
   * @param currentQuestionId: string
   * @param inAnswerLater: boolean tracking if we are displaying questions in the 'answer later' array
   */
  public previousQuestion(
    questionnaire: Questionnaire,
    answers: Answers,
    currentQuestionId: string,
    inAnswerLater: boolean,
  ): Observable<any> {
    let currentQuestionIndex = questionnaire.questions.findIndex((question) => question.id === currentQuestionId);
    let nextQuestionId;
    if (inAnswerLater) {
      currentQuestionIndex = answers.answerLater.findIndex((question) => question.id === currentQuestionId);
      if (currentQuestionIndex === 0) {
        // user hit 'previous' on first question in 'answer later' array.  get id of last question in regular questions array
        inAnswerLater = false;
        nextQuestionId = questionnaire.questions[questionnaire.questions.length - 1].id;
      } else {
        nextQuestionId = answers.answerLater[currentQuestionIndex - 1].id;
      }
    } else {
      nextQuestionId = questionnaire.questions[currentQuestionIndex - 1].id;
    }

    const onFirstQuestion = currentQuestionIndex === 1 && !inAnswerLater;

    return observableOf({
      nextQuestionId,
      onFirstQuestion,
      inAnswerLater,
    });
  }

  /**
   * Add a question to be answered later
   * @param questionnaire: Questionnaire (full object)
   * @param question: Question (single question object)
   * @param answers: Answers (full answered_questionnaire object)
   * @param currentQuestionId: string
   * @param inAnswerLater: boolean tracking if we are displaying questions in the 'answer later' array
   */
  public answerLater(
    questionnaire: Questionnaire,
    question: Question,
    answers: Answers,
    currentQuestionId: string,
    inAnswerLater: boolean,
  ): Observable<any> {
    // if we are on the last unanswered question (total dirty minus total # of questions = 1), we don't want to allow adding to 'answer later', so return early with a notice
    const totalDirty = answers.answeredQuestions.filter((answer) => answer.dirty).length;
    if (questionnaire.questions.length - totalDirty === 1) {
      return observableOf({ answers, currentQuestionId, errorText: 'There is only one question remaining!' });
    }

    // this might be the first question we are adding to answer later- prep the array
    if (!answers.answerLater) {
      answers.answerLater = [];
    }

    let currentQuestionIndex = questionnaire.questions.findIndex((q) => q.id === currentQuestionId);
    if (inAnswerLater) {
      currentQuestionIndex = answers.answerLater.findIndex((q) => q.id === currentQuestionId);
    }

    // if this is already in answer later array, don't push it again, just move to next index
    if (!answers.answerLater.find((answerLaterQuestion) => answerLaterQuestion.id === currentQuestionId)) {
      answers.answerLater.push(question);
    }

    const dataObjForApi = {
      answerLater: answers.answerLater,
    };

    const options: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
    } = this.localStorageService.getJWTAndSetOptions();
    return this.http
      .patch<Answers>(`${this.certificationApiUrl}answeredQuestionnaire/${answers.id}`, dataObjForApi, options)
      .pipe(
        map((updatedAnswersFromPatch: Answers) => {
          let nextQuestionId: string;
          currentQuestionIndex += 1;
          if (inAnswerLater) {
            if (updatedAnswersFromPatch.answerLater[currentQuestionIndex]) {
              nextQuestionId = updatedAnswersFromPatch.answerLater[currentQuestionIndex].id;
            } else {
              // user hit 'answer later' on last question in answerLater array, but there are still other questions in answer_later, loop back around
              // TO REVIEW: there is an edge case where this may be confusing.  If they hit 'previous' right after they loop back around, that click takes them to the last question in the main questions array.  There might be a better way?
              nextQuestionId = updatedAnswersFromPatch.answerLater[0].id;
            }
          } else {
            if (questionnaire.questions[currentQuestionIndex]) {
              nextQuestionId = questionnaire.questions[currentQuestionIndex].id;
            } else {
              // user hit 'answer later' on last question in normal questionnaire.questions array, but there are some already in answer later, so we are now in 'answerLater'
              inAnswerLater = true;
              nextQuestionId = updatedAnswersFromPatch.answerLater[0].id;
            }
          }

          return { answers: updatedAnswersFromPatch, nextQuestionId, inAnswerLater };
        }),
      );
  }

  /**
   * Load an individual question given the current question id
   * @param questionnaire: Questionnaire (full object)
   * @param answers: Answers (full answered_questionnaire object)
   * @param currentQuestionId: string
   * @param inAnswerLater: boolean tracking if we are displaying questions in the 'answer later' array
   */
  public loadQuestion(
    questionnaire: Questionnaire,
    answers: Answers,
    currentQuestionId: string,
    inAnswerLater: boolean,
  ): Observable<any> {
    let currentQuestionIndex = questionnaire.questions.findIndex((q) => q.id === currentQuestionId);
    let currentQuestion = questionnaire.questions[currentQuestionIndex];

    if (inAnswerLater) {
      currentQuestionIndex = answers.answerLater.findIndex((q) => q.id === currentQuestionId);
      currentQuestion = answers.answerLater[currentQuestionIndex];
    }

    let currentAnswer: AnsweredQuestion | undefined;
    if (currentQuestion) {
      currentAnswer = answers.answeredQuestions.find((answer) => answer.question_id === currentQuestion.id);
    }

    return observableOf({
      currentQuestion,
      currentAnswer,
      onFirstQuestion: currentQuestionIndex === 0 && !inAnswerLater,
    });
  }

  /**
   * Final submission of a completed questionniare
   * @param answeredQuestionsId: string
   */
  public questionnaireSubmit(answeredQuestionsId: string): Observable<any> {
    // will be http call to certification api to mark questionnaire as complete (answers should already have been saved)
    // may pass entire answers object as well for history tracking
    const options = this.localStorageService.getJWTAndSetOptions();

    return this.http.post(`${this.certificationApiUrl}answeredQuestionnaire/${answeredQuestionsId}`, {}, options);
  }

  public uploadFiles(
    files: any[] = [],
    certificationId: string,
    questionId: string,
    rowId?: string,
  ): Observable<UploadFileItem[]> {
    const fileUploads = [];
    for (const file of files) {
      fileUploads.push(this.uploadFileForQuestionnaireAnswer(certificationId, questionId, file.name, file, rowId));
    }
    return from(fileUploads).pipe(
      mergeAll(),
      map((file: any) => file && file.fileInfo),
      toArray<UploadFileItem>(),
    );
  }

  public uploadFileForQuestionnaireAnswer(
    certificationId: string,
    questionId: string,
    filename: string,
    file: Blob,
    rowId?: string,
  ) {
    let url = `${this.certificationApiUrl}answeredQuestionnaire/${certificationId}/${questionId}/upload`;
    let awsName = '';
    if (rowId) {
      url += `/${rowId}`;
    }

    return this.http
      .post(url, filename, {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          Authorization: this.localStorageService.getToken(),
        }),
      })
      .pipe(
        mergeMap((params: any) => {
          const formData = new FormData();

          // build the multipart form that S3 requires
          Object.keys(params.fields).forEach((field) => formData.append(field, params.fields[field]));
          awsName = params.fileName;
          formData.append('file', file, params.fileName); // add the file
          return this.http.post<any>(params.url, formData); // upload to S3
        }),
        map((res: Response) => ({ res, fileInfo: { name: filename, awsName } })),
        catchError((err) => observableOf({ res: err, fileInfo: { name: `UPLOAD_ERROR_${filename}`, awsName } })),
      );
  }

  /**
   * Update the answered questions to remove a question from the 'answerLater' array
   * @param answersObject: Answers
   * @param questionId: string
   */
  private removeFromAnswerLater(answersObject: Answers, questionId: string): Answers {
    const answerLaterIndex = answersObject.answerLater.findIndex(
      (deferredQuestion) => deferredQuestion.id === questionId,
    );
    if (answerLaterIndex > -1) {
      answersObject.answerLater.splice(answerLaterIndex, 1);
    }
    return answersObject;
  }

  private saveCertItemWithoutUpload(id, dataObjForApi, options, uploadError) {
    return this.http.patch(`${this.certificationApiUrl}answeredQuestionnaire/${id}`, dataObjForApi, options).pipe(
      map((answers) => ({ answers, uploadError })),
      catchError((err) => observableOf(err)),
    );
  }
}

export interface QuestionnaireResponse {
  answeredQuestionnaire: any;
  questionnaire: any;
}

export interface UploadFileItem {
  name: string;
  awsName: string;
}
