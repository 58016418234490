/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionCurrencyCodes } from './transactionCurrencyCodes';
import { TransactionStatuses } from './transactionStatuses';
import { WebsiteMonitoringStatus } from './websiteMonitoringStatus';
import { TransactionSources } from './transactionSources';
import { WebsiteCountries } from './websiteCountries';


export interface TransactionDetailsResponse { 
    id: string;
    websiteUrl: string;
    monitoringStatus?: WebsiteMonitoringStatus;
    client?: string;
    clientSub?: string;
    transactionStatus: TransactionStatuses;
    screenshots?: Array<any>;
    attachments?: Array<any>;
    analysisNote: string;
    recordTags?: Array<any>;
    source?: TransactionSources;
    requestId?: string;
    legitscriptId?: string;
    category?: string;
    mccCategory?: string;
    agent?: string;
    network?: string;
    transactionAmount?: number;
    transactionUSDAmount?: number;
    transactionCurrency?: TransactionCurrencyCodes;
    transactionDescription?: string;
    transactionDate?: string;
    analysisDate?: string;
    cardAcceptorId?: string;
    ccLast4?: string;
    purchaseAmount?: number;
    cardholderLocation?: WebsiteCountries;
    issuerLocation?: WebsiteCountries;
    paymentWebsite?: string;
    merchantDescriptor?: string;
    merchantState?: string;
    merchantCountry?: WebsiteCountries;
    merchantCity?: string;
    transactionId?: string;
    posEntryMode?: number;
    posConditionCode?: number;
    acquirerBin?: number;
    acquirerBid?: number;
    acquirerCountry?: WebsiteCountries;
    acquirerName?: string;
    acquirerRegion?: string;
    rightsHolder?: string;
    rightsHolderContactName?: string;
    rightsHolderContactPhone?: string;
    rightsHolderContactEmail?: string;
    rightsHolderContactAddress?: string;
    merchantCategoryCode?: number;
}
export namespace TransactionDetailsResponse {
}



