import { MerchantListEffect } from './merchant-list.effect';
import { MerchantDetailEffect } from './merchant-detail.effect';
import { DirtyMerchantsCacheEffect } from './dirty-merchants-cache.effect';
import { CheckboxMerchantEffect } from './checkbox-merchants.effect';
import { HistoryUploadListEffect } from './history-upload.effect';
import { ContentLabelsEffect } from '../../merchant-monitoring-v2/effects/content-labels.effect';
import { BulkUploadEffect } from './bulk-upload.effect';
import { TagsEffect } from '../../merchant-monitoring-v2/effects/tags.effect';
import { LsTagsEffect } from '../../merchant-monitoring-v2/effects/ls-tags.effect';
import { IcasEffect } from '../../merchant-monitoring-v2/effects/icas.effect';
import { MerchantSummaryEffect } from '../../merchant-monitoring-v2/effects/merchant-summary.effect';
import { SnackbarEffects } from '../../merchant-monitoring-v2/state/effects/snackbar.effects';
import { SavedFiltersEffect } from '../../merchant-monitoring-v2/effects/saved-filters.effect';

export * from './merchant-list.effect';

export const APP_EFFECTS = [
  ContentLabelsEffect,
  MerchantDetailEffect,
  MerchantListEffect,
  MerchantSummaryEffect,
  DirtyMerchantsCacheEffect,
  CheckboxMerchantEffect,
  HistoryUploadListEffect,
  BulkUploadEffect,
  TagsEffect,
  LsTagsEffect,
  IcasEffect,
  SnackbarEffects,
  SavedFiltersEffect,
];
