/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';


import { AddTags201Response } from '../model/addTags201Response';

import { AnalystSearchForTransactions200Response } from '../model/analystSearchForTransactions200Response';

import { ConfirmAttachmentUpload200Response } from '../model/confirmAttachmentUpload200Response';

import { CreateAWebsite201Response } from '../model/createAWebsite201Response';

import { CreateAWebsiteRequest } from '../model/createAWebsiteRequest';

import { CreateAccountRequest } from '../model/createAccountRequest';

import { CreateCategory201Response } from '../model/createCategory201Response';

import { CreateCategoryRequest } from '../model/createCategoryRequest';

import { CreateTag201Response } from '../model/createTag201Response';

import { CreateTagRequest } from '../model/createTagRequest';

import { CreateTransaction201Response } from '../model/createTransaction201Response';

import { CreateTransactionRequest } from '../model/createTransactionRequest';

import { DownloadAllAttachments200ResponseInner } from '../model/downloadAllAttachments200ResponseInner';

import { DownloadAttachment200Response } from '../model/downloadAttachment200Response';

import { GetTransaction200Response } from '../model/getTransaction200Response';

import { ListAllAccounts200Response } from '../model/listAllAccounts200Response';

import { ListAllCategories200ResponseInner } from '../model/listAllCategories200ResponseInner';

import { ListTags200ResponseInner } from '../model/listTags200ResponseInner';

import { ListUniqueValues200Response } from '../model/listUniqueValues200Response';

import { ListWebsites200Response } from '../model/listWebsites200Response';

import { ReorderScreenshots200ResponseInner } from '../model/reorderScreenshots200ResponseInner';

import { RequestScreenshots200Response } from '../model/requestScreenshots200Response';

import { RequestScreenshotsRequest } from '../model/requestScreenshotsRequest';

import { TransactionDetailsForMyLs200Response } from '../model/transactionDetailsForMyLs200Response';

import { TransactionsSummary200Response } from '../model/transactionsSummary200Response';

import { UpdateAWebsiteRequest } from '../model/updateAWebsiteRequest';

import { UpdateTagsRequest } from '../model/updateTagsRequest';

import { UpdateTransactionRequest } from '../model/updateTransactionRequest';

import { UploadAttachment201Response } from '../model/uploadAttachment201Response';

import { UploadFileRequest } from '../model/uploadFileRequest';

import { UploadTransactions200Response } from '../model/uploadTransactions200Response';

import { UploadTransactions400Response } from '../model/uploadTransactions400Response';

import { UserSearchForTransactions200Response } from '../model/userSearchForTransactions200Response';

import { UserSearchForTransactions400Response } from '../model/userSearchForTransactions400Response';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AddTagsRequestParams {
    /** ID of the transaction */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    UpdateTagsRequest?: UpdateTagsRequest;
}

export interface AnalystSearchForTransactionsRequestParams {
    /** Sets the page number in the search results */
    page?: number;
    /** Sets the number of records that will be returned. */
    size?: number;
    /** asc, desc */
    sortDirection?: string;
    /**  */
    sortField?: string;
    /** Flag for including deleted records */
    includeDeleted?: boolean;
    /** When set to &#x60;true&#x60; only the latest transaction for a website will show in the results. */
    uniqueWebsites?: boolean;
    /** Filters for the record Id; comma separated list */
    id?: string;
    /** Filters for the LegitScript Internal ID */
    legitscriptId?: string;
    /** Filter for acquirerBid on the website */
    acquirerBid?: string;
    /** Filter for acquirerBin on the website */
    acquirerBin?: string;
    /** Filter for acquirerCountry on the website */
    acquirerCountry?: string;
    /** Filter for acquirerName on the website */
    acquirerName?: string;
    /** Filter for acquirerRegion on the website */
    acquirerRegion?: string;
    /** Filter for agent on the website */
    agent?: string;
    /** Filters for analysisDate */
    analysisDate?: string;
    /** Filter for the cardAcceptorId on the transaction */
    cardAcceptorId?: string;
    /** Filters for cardholderLocation */
    cardholderLocation?: string;
    /** Filter for transaction category. Comma separated list. Example &#x60;val1,val2,val3&#x60; */
    category?: string;
    /** Filter for the last for digits of the credit card */
    ccLast4?: number;
    /** Filter for the client on the website */
    client?: string;
    /** Filter for the client sub on the website */
    clientSub?: string;
    /** Filter for Transaction creation date. Comma separated list of Date formatted strings. */
    createdAt?: string;
    /** Filters for mccCategory */
    mccCategory?: string;
    /** Filter for merchantCategoryCode on the website */
    merchantCategoryCode?: string;
    /** Filter for merchantCountry on the website */
    merchantCountry?: string;
    /** Filter for merchant merchantDescriptor */
    merchantDescriptor?: string;
    /** Filter for merchantState on the website */
    merchantState?: string;
    /** Filter for monitoringStatus on the website */
    monitoringStatus?: string;
    /** Filter for network on the website */
    network?: string;
    /** Filters for paymentWebsite */
    paymentWebsite?: string;
    /** Filters for issuerLocation */
    issuerLocation?: string;
    /** Filters for purchaseAmount */
    purchaseAmount?: string;
    /** Filter for tags assigned to the transaction. Comma separated list. Example &#x60;val1,val2,val3&#x60; */
    recordTags?: string;
    /** Filter for the requestId on the transaction */
    requestId?: string;
    /** Filter for the rightsHolder on the transaction */
    rightsHolder?: string;
    /** Filter for the source on the transaction */
    source?: string;
    /** Filter for the terminalId on the transaction */
    terminalId?: string;
    /** Filter for transaction amount */
    transactionAmount?: number;
    /** Filters for transactionCurrency */
    transactionCurrency?: string;
    /** Filter for Transaction Date. Comma separated list of Date formatted strings. */
    transactionDate?: string;
    /** Filters for transactionDescription */
    transactionDescription?: string;
    /** Filter for the transactionId on the transaction */
    transactionId?: string;
    /** Filter for the Status. Uses enum values. */
    transactionStatus?: string;
    /** Filters for transactionUSDAmount */
    transactionUSDAmount?: string;
    /** Filter for the website url */
    websiteUrl?: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 (optional) */
    X_Account_Id?: string;
}

export interface ConfirmAttachmentUploadRequestParams {
    /** ID of the transaction */
    id: string;
    /** ID of the attachment to download */
    attachmentId: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface ConfirmScreenshotUploadRequestParams {
    /** ID of the transaction */
    id: string;
    /** ID of the screenshot to download */
    screenshotId: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface CreateAWebsiteRequestParams {
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    CreateAWebsiteRequest?: CreateAWebsiteRequest;
}

export interface CreateAccountRequestParams {
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    CreateAccountRequest?: CreateAccountRequest;
}

export interface CreateCategoryRequestParams {
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    CreateCategoryRequest?: CreateCategoryRequest;
}

export interface CreateTagRequestParams {
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    CreateTagRequest?: CreateTagRequest;
}

export interface CreateTransactionRequestParams {
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    CreateTransactionRequest?: CreateTransactionRequest;
}

export interface DeleteAWebsiteRequestParams {
    /** ID of the website to remove */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface DeleteAttachmentRequestParams {
    /** ID of the transaction */
    id: string;
    /** ID of the attachment to delete */
    attachmentId: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface DeleteScreenshotRequestParams {
    /** ID of the transaction */
    id: string;
    /** ID of the screenshot to delete */
    screenshotId: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface DeleteTagRequestParams {
    /** ID of the transaction */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface DeleteTransactionRequestParams {
    /**  */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface DownloadAllAttachmentsRequestParams {
    /** ID of the transaction */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface DownloadAllScreenshotsRequestParams {
    /** ID of the transaction */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface DownloadAttachmentRequestParams {
    /** ID of the transaction */
    id: string;
    /** ID of the attachment to download */
    attachmentId: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface DownloadScreenshotRequestParams {
    /** ID of the transaction */
    id: string;
    /** ID of the screenshot to download */
    screenshotId: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface DownloadTransactionsRequestParams {
    /** asc, desc */
    sortDirection?: string;
    /**  */
    sortField?: string;
    /** Flag for including deleted records */
    includeDeleted?: boolean;
    /** When set to &#x60;true&#x60; only the latest transaction for a website will show in the results. */
    uniqueWebsites?: boolean;
    /** Filters for the record Id; comma separated list */
    id?: string;
    /** Filter for the accountId on the website */
    accountId?: number;
    /** Filter for acquirerBid on the website */
    acquirerBid?: string;
    /** Filter for acquirerBin on the website */
    acquirerBin?: string;
    /** Filter for acquirerCountry on the website */
    acquirerCountry?: string;
    /** Filter for acquirerName on the website */
    acquirerName?: string;
    /** Filter for acquirerRegion on the website */
    acquirerRegion?: string;
    /** Filter for agent on the website */
    agent?: string;
    /** Filters for analysisDate */
    analysisDate?: string;
    /** Filter for the cardAcceptorId on the transaction */
    cardAcceptorId?: string;
    /** Filters for cardholderLocation */
    cardholderLocation?: string;
    /** Filter for transaction category. Comma separated list. Example &#x60;val1,val2,val3&#x60; */
    category?: string;
    /** Filter for the last for digits of the credit card */
    ccLast4?: number;
    /** Filter for the client on the website */
    client?: string;
    /** Filter for the client sub on the website */
    clientSub?: string;
    /** Filter for Transaction creation date. Comma separated list of Date formatted strings. */
    createdAt?: string;
    /** Filters for mccCategory */
    mccCategory?: string;
    /** Filter for merchantCategoryCode on the website */
    merchantCategoryCode?: string;
    /** Filter for merchantCountry on the website */
    merchantCountry?: string;
    /** Filter for merchant merchantDescriptor */
    merchantDescriptor?: string;
    /** Filter for merchantState on the website */
    merchantState?: string;
    /** Filter for monitoringStatus on the website */
    monitoringStatus?: string;
    /** Filter for network on the website */
    network?: string;
    /** Filters for paymentWebsite */
    paymentWebsite?: string;
    /** Filters for issuerLocation */
    issuerLocation?: string;
    /** Filters for purchaseAmount */
    purchaseAmount?: string;
    /** Filter for tags assigned to the transaction. Comma separated list. Example &#x60;val1,val2,val3&#x60; */
    recordTags?: string;
    /** Filter for the requestId on the transaction */
    requestId?: string;
    /** Filter for the rightsHolder on the transaction */
    rightsHolder?: string;
    /** Filter for the source on the transaction */
    source?: string;
    /** Filter for the terminalId on the transaction */
    terminalId?: string;
    /** Filter for transaction amount */
    transactionAmount?: number;
    /** Filters for transactionCurrency */
    transactionCurrency?: string;
    /** Filter for Transaction Date. Comma separated list of Date formatted strings. */
    transactionDate?: string;
    /** Filters for transactionDescription */
    transactionDescription?: string;
    /** Filter for the transactionId on the transaction */
    transactionId?: string;
    /** Filter for the Status. Uses enum values. */
    transactionStatus?: string;
    /** Filters for transactionUSDAmount */
    transactionUSDAmount?: string;
    /** Filter for the website url */
    websiteUrl?: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface GetAWebsiteByIDRequestParams {
    /** ID of the website to retrieve */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface GetTransactionRequestParams {
    /**  */
    id: string;
    /** Flag for including deleted records */
    includeDeleted?: boolean;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface ListAllAccountsRequestParams {
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
}

export interface ListAllCategoriesRequestParams {
    /** String to search the name or description Ex. &#x60;name&#x3D;foo&#x60; */
    filter?: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface ListTagsRequestParams {
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface ListUniqueValuesRequestParams {
    /** The column to query */
    column: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface ListWebsitesRequestParams {
    /** Sets the page number in the search results */
    page?: number;
    /** Sets the number of records that will be returned. */
    size?: number;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface ReorderScreenshotsRequestParams {
    /** ID of the transaction */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    request_body?: Array<any>;
}

export interface RequestScreenshotsRequestParams {
    /** ID of the transaction */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    RequestScreenshotsRequest?: RequestScreenshotsRequest;
}

export interface TransactionDetailsForMyLsRequestParams {
    /**  */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface TransactionsSummaryRequestParams {
    /** Flag for including deleted records */
    includeDeleted?: boolean;
    /** When set to &#x60;true&#x60; only the latest transaction for a website will show in the results. */
    uniqueWebsites?: boolean;
    /** Filters for the record Id */
    id?: string;
    /** Filter for acquirerBid on the website */
    acquirerBid?: string;
    /** Filter for acquirerBin on the website */
    acquirerBin?: string;
    /** Filter for acquirerCountry on the website */
    acquirerCountry?: string;
    /** Filter for acquirerName on the website */
    acquirerName?: string;
    /** Filter for acquirerRegion on the website */
    acquirerRegion?: string;
    /** Filter for agent on the website */
    agent?: string;
    /** Filters for analysisDate */
    analysisDate?: string;
    /** Filter for the cardAcceptorId on the transaction */
    cardAcceptorId?: string;
    /** Filters for cardholderLocation */
    cardholderLocation?: string;
    /** Filter for transaction category. Comma separated list. Example &#x60;val1,val2,val3&#x60; */
    category?: string;
    /** Filter for the last for digits of the credit card */
    ccLast4?: number;
    /** Filter for the client sub on the website */
    clientSub?: string;
    /** Filter for Transaction creation date. Comma separated list of Date formatted strings. */
    createdAt?: string;
    /** Filters for mccCategory */
    mccCategory?: string;
    /** Filter for merchantCategoryCode on the website */
    merchantCategoryCode?: string;
    /** Filter for merchantCountry on the website */
    merchantCountry?: string;
    /** Filter for merchant merchantDescriptor */
    merchantDescriptor?: string;
    /** Filter for merchantState on the website */
    merchantState?: string;
    /** Filter for monitoringStatus on the website */
    monitoringStatus?: string;
    /** Filter for network on the website */
    network?: string;
    /** Filters for paymentWebsite */
    paymentWebsite?: string;
    /** Filters for issuerLocation */
    issuerLocation?: string;
    /** Filters for purchaseAmount */
    purchaseAmount?: string;
    /** Filter for tags assigned to the transaction. Comma separated list. Example &#x60;val1,val2,val3&#x60; */
    recordTags?: string;
    /** Filter for the requestId on the transaction */
    requestId?: string;
    /** Filter for the rightsHolder on the transaction */
    rightsHolder?: string;
    /** Filter for the source on the transaction */
    source?: string;
    /** Filter for the terminalId on the transaction */
    terminalId?: string;
    /** Filter for transaction amount */
    transactionAmount?: number;
    /** Filters for transactionCurrency */
    transactionCurrency?: string;
    /** Filter for Transaction Date. Comma separated list of Date formatted strings. */
    transactionDate?: string;
    /** Filters for transactionDescription */
    transactionDescription?: string;
    /** Filter for the transactionId on the transaction */
    transactionId?: string;
    /** Filter for the Status. Uses enum values. */
    transactionStatus?: string;
    /** Filters for transactionUSDAmount */
    transactionUSDAmount?: string;
    /** Filter for the website url */
    websiteUrl?: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}

export interface UpdateAWebsiteRequestParams {
    /** ID of the website to retrieve */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    UpdateAWebsiteRequest?: UpdateAWebsiteRequest;
}

export interface UpdateTagsRequestParams {
    /** ID of the transaction */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    UpdateTagsRequest?: UpdateTagsRequest;
}

export interface UpdateTransactionRequestParams {
    /**  */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    UpdateTransactionRequest?: UpdateTransactionRequest;
}

export interface UploadAttachmentRequestParams {
    /** ID of the transaction */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    UploadFileRequest?: UploadFileRequest;
}

export interface UploadScreenshotRequestParams {
    /** ID of the transaction */
    id: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    UploadFileRequest?: UploadFileRequest;
}

export interface UploadTransactionsRequestParams {
    /** Should ONLY be used if the request is coming from data migration. Will set the external ID of the record. Use with caution! */
    migration?: boolean;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
    body?: string;
}

export interface UserSearchForTransactionsRequestParams {
    /** Sets the page number in the search results */
    page?: number;
    /** Sets the number of records that will be returned. */
    size?: number;
    /** asc, desc */
    sortDirection?: string;
    /**  */
    sortField?: string;
    /** Flag for including deleted records */
    includeDeleted?: boolean;
    /** When set to &#x60;true&#x60; only the latest transaction for a website will show in the results. */
    uniqueWebsites?: boolean;
    /** Filters for the record Id; comma separated list */
    id?: string;
    /** Filters for the LegitScript Internal ID */
    legitscriptId?: string;
    /** Filter for acquirerBid on the website */
    acquirerBid?: string;
    /** Filter for acquirerBin on the website */
    acquirerBin?: string;
    /** Filter for acquirerCountry on the website */
    acquirerCountry?: string;
    /** Filter for acquirerName on the website */
    acquirerName?: string;
    /** Filter for acquirerRegion on the website */
    acquirerRegion?: string;
    /** Filter for agent on the website */
    agent?: string;
    /** Filters for analysisDate */
    analysisDate?: string;
    /** Filter for the cardAcceptorId on the transaction */
    cardAcceptorId?: string;
    /** Filters for cardholderLocation */
    cardholderLocation?: string;
    /** Filter for transaction category. Comma separated list. Example &#x60;val1,val2,val3&#x60; */
    category?: string;
    /** Filter for the last for digits of the credit card */
    ccLast4?: number;
    /** Filter for the client sub on the website */
    clientSub?: string;
    /** Filter for Transaction creation date. Comma separated list of Date formatted strings. */
    createdAt?: string;
    /** Filters for mccCategory */
    mccCategory?: string;
    /** Filter for merchantCategoryCode on the website */
    merchantCategoryCode?: string;
    /** Filter for merchantCountry on the website */
    merchantCountry?: string;
    /** Filter for merchant merchantDescriptor */
    merchantDescriptor?: string;
    /** Filter for merchantState on the website */
    merchantState?: string;
    /** Filter for monitoringStatus on the website */
    monitoringStatus?: string;
    /** Filter for network on the website */
    network?: string;
    /** Filters for paymentWebsite */
    paymentWebsite?: string;
    /** Filters for issuerLocation */
    issuerLocation?: string;
    /** Filters for purchaseAmount */
    purchaseAmount?: string;
    /** Filter for tags assigned to the transaction. Comma separated list. Example &#x60;val1,val2,val3&#x60; */
    recordTags?: string;
    /** Filter for the requestId on the transaction */
    requestId?: string;
    /** Filter for the rightsHolder on the transaction */
    rightsHolder?: string;
    /** Filter for the source on the transaction */
    source?: string;
    /** Filter for the terminalId on the transaction */
    terminalId?: string;
    /** Filter for transaction amount */
    transactionAmount?: number;
    /** Filters for transactionCurrency */
    transactionCurrency?: string;
    /** Filter for Transaction Date. Comma separated list of Date formatted strings. */
    transactionDate?: string;
    /** Filters for transactionDescription */
    transactionDescription?: string;
    /** Filter for the transactionId on the transaction */
    transactionId?: string;
    /** Filter for the Status. Uses enum values. */
    transactionStatus?: string;
    /** Filters for transactionUSDAmount */
    transactionUSDAmount?: string;
    /** Filter for the website url */
    websiteUrl?: string;
    /** e.g. Bearer ${JWT} */
    Authorization?: string;
    /** e.g. 5 */
    X_Account_Id?: string;
}


@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Add Tags
     * Adds a tag to a Transaction object Accepts a list of tag IDs to associate to the transaction.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTags(requestParameters?: AddTagsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AddTags201Response>;
    public addTags(requestParameters?: AddTagsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AddTags201Response>>;
    public addTags(requestParameters?: AddTagsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AddTags201Response>>;
    public addTags(requestParameters?: AddTagsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling addTags.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const UpdateTagsRequest = requestParameters?.UpdateTagsRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/tags`;
        return this.httpClient.request<AddTags201Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: UpdateTagsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Analyst search for transactions
     * Search for Transactions intended for Analyst role which takes X-Account-Id optionally, but otherwise allows access to all. Returns a paginated list of results.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public analystSearchForTransactions(requestParameters?: AnalystSearchForTransactionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AnalystSearchForTransactions200Response>;
    public analystSearchForTransactions(requestParameters?: AnalystSearchForTransactionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AnalystSearchForTransactions200Response>>;
    public analystSearchForTransactions(requestParameters?: AnalystSearchForTransactionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AnalystSearchForTransactions200Response>>;
    public analystSearchForTransactions(requestParameters?: AnalystSearchForTransactionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const page = requestParameters?.page;
        const size = requestParameters?.size;
        const sortDirection = requestParameters?.sortDirection;
        const sortField = requestParameters?.sortField;
        const includeDeleted = requestParameters?.includeDeleted;
        const uniqueWebsites = requestParameters?.uniqueWebsites;
        const id = requestParameters?.id;
        const legitscriptId = requestParameters?.legitscriptId;
        const acquirerBid = requestParameters?.acquirerBid;
        const acquirerBin = requestParameters?.acquirerBin;
        const acquirerCountry = requestParameters?.acquirerCountry;
        const acquirerName = requestParameters?.acquirerName;
        const acquirerRegion = requestParameters?.acquirerRegion;
        const agent = requestParameters?.agent;
        const analysisDate = requestParameters?.analysisDate;
        const cardAcceptorId = requestParameters?.cardAcceptorId;
        const cardholderLocation = requestParameters?.cardholderLocation;
        const category = requestParameters?.category;
        const ccLast4 = requestParameters?.ccLast4;
        const client = requestParameters?.client;
        const clientSub = requestParameters?.clientSub;
        const createdAt = requestParameters?.createdAt;
        const mccCategory = requestParameters?.mccCategory;
        const merchantCategoryCode = requestParameters?.merchantCategoryCode;
        const merchantCountry = requestParameters?.merchantCountry;
        const merchantDescriptor = requestParameters?.merchantDescriptor;
        const merchantState = requestParameters?.merchantState;
        const monitoringStatus = requestParameters?.monitoringStatus;
        const network = requestParameters?.network;
        const paymentWebsite = requestParameters?.paymentWebsite;
        const issuerLocation = requestParameters?.issuerLocation;
        const purchaseAmount = requestParameters?.purchaseAmount;
        const recordTags = requestParameters?.recordTags;
        const requestId = requestParameters?.requestId;
        const rightsHolder = requestParameters?.rightsHolder;
        const source = requestParameters?.source;
        const terminalId = requestParameters?.terminalId;
        const transactionAmount = requestParameters?.transactionAmount;
        const transactionCurrency = requestParameters?.transactionCurrency;
        const transactionDate = requestParameters?.transactionDate;
        const transactionDescription = requestParameters?.transactionDescription;
        const transactionId = requestParameters?.transactionId;
        const transactionStatus = requestParameters?.transactionStatus;
        const transactionUSDAmount = requestParameters?.transactionUSDAmount;
        const websiteUrl = requestParameters?.websiteUrl;
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortDirection !== undefined && sortDirection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDirection, 'sortDirection');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeDeleted, 'includeDeleted');
        }
        if (uniqueWebsites !== undefined && uniqueWebsites !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uniqueWebsites, 'uniqueWebsites');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (legitscriptId !== undefined && legitscriptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>legitscriptId, 'legitscriptId');
        }
        if (acquirerBid !== undefined && acquirerBid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerBid, 'acquirerBid');
        }
        if (acquirerBin !== undefined && acquirerBin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerBin, 'acquirerBin');
        }
        if (acquirerCountry !== undefined && acquirerCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerCountry, 'acquirerCountry');
        }
        if (acquirerName !== undefined && acquirerName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerName, 'acquirerName');
        }
        if (acquirerRegion !== undefined && acquirerRegion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerRegion, 'acquirerRegion');
        }
        if (agent !== undefined && agent !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>agent, 'agent');
        }
        if (analysisDate !== undefined && analysisDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>analysisDate, 'analysisDate');
        }
        if (cardAcceptorId !== undefined && cardAcceptorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cardAcceptorId, 'cardAcceptorId');
        }
        if (cardholderLocation !== undefined && cardholderLocation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cardholderLocation, 'cardholderLocation');
        }
        if (category !== undefined && category !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>category, 'category');
        }
        if (ccLast4 !== undefined && ccLast4 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ccLast4, 'ccLast4');
        }
        if (client !== undefined && client !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>client, 'client');
        }
        if (clientSub !== undefined && clientSub !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientSub, 'clientSub');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (mccCategory !== undefined && mccCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>mccCategory, 'mccCategory');
        }
        if (merchantCategoryCode !== undefined && merchantCategoryCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantCategoryCode, 'merchantCategoryCode');
        }
        if (merchantCountry !== undefined && merchantCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantCountry, 'merchantCountry');
        }
        if (merchantDescriptor !== undefined && merchantDescriptor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantDescriptor, 'merchantDescriptor');
        }
        if (merchantState !== undefined && merchantState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantState, 'merchantState');
        }
        if (monitoringStatus !== undefined && monitoringStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>monitoringStatus, 'monitoringStatus');
        }
        if (network !== undefined && network !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>network, 'network');
        }
        if (paymentWebsite !== undefined && paymentWebsite !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentWebsite, 'paymentWebsite');
        }
        if (issuerLocation !== undefined && issuerLocation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>issuerLocation, 'issuerLocation');
        }
        if (purchaseAmount !== undefined && purchaseAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>purchaseAmount, 'purchaseAmount');
        }
        if (recordTags !== undefined && recordTags !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recordTags, 'recordTags');
        }
        if (requestId !== undefined && requestId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>requestId, 'requestId');
        }
        if (rightsHolder !== undefined && rightsHolder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rightsHolder, 'rightsHolder');
        }
        if (source !== undefined && source !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>source, 'source');
        }
        if (terminalId !== undefined && terminalId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>terminalId, 'terminalId');
        }
        if (transactionAmount !== undefined && transactionAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionAmount, 'transactionAmount');
        }
        if (transactionCurrency !== undefined && transactionCurrency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionCurrency, 'transactionCurrency');
        }
        if (transactionDate !== undefined && transactionDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionDate, 'transactionDate');
        }
        if (transactionDescription !== undefined && transactionDescription !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionDescription, 'transactionDescription');
        }
        if (transactionId !== undefined && transactionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionId, 'transactionId');
        }
        if (transactionStatus !== undefined && transactionStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionStatus, 'transactionStatus');
        }
        if (transactionUSDAmount !== undefined && transactionUSDAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionUSDAmount, 'transactionUSDAmount');
        }
        if (websiteUrl !== undefined && websiteUrl !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>websiteUrl, 'websiteUrl');
        }

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/analyst-search`;
        return this.httpClient.request<AnalystSearchForTransactions200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Confirm attachment upload
     * 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmAttachmentUpload(requestParameters?: ConfirmAttachmentUploadRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ConfirmAttachmentUpload200Response>;
    public confirmAttachmentUpload(requestParameters?: ConfirmAttachmentUploadRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ConfirmAttachmentUpload200Response>>;
    public confirmAttachmentUpload(requestParameters?: ConfirmAttachmentUploadRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ConfirmAttachmentUpload200Response>>;
    public confirmAttachmentUpload(requestParameters?: ConfirmAttachmentUploadRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling confirmAttachmentUpload.');
        }
        const attachmentId = requestParameters?.attachmentId;
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling confirmAttachmentUpload.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments/${this.configuration.encodeParam({name: "attachmentId", value: attachmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/confirm`;
        return this.httpClient.request<ConfirmAttachmentUpload200Response>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Confirm screenshot upload
     * 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmScreenshotUpload(requestParameters?: ConfirmScreenshotUploadRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ConfirmAttachmentUpload200Response>;
    public confirmScreenshotUpload(requestParameters?: ConfirmScreenshotUploadRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ConfirmAttachmentUpload200Response>>;
    public confirmScreenshotUpload(requestParameters?: ConfirmScreenshotUploadRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ConfirmAttachmentUpload200Response>>;
    public confirmScreenshotUpload(requestParameters?: ConfirmScreenshotUploadRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling confirmScreenshotUpload.');
        }
        const screenshotId = requestParameters?.screenshotId;
        if (screenshotId === null || screenshotId === undefined) {
            throw new Error('Required parameter screenshotId was null or undefined when calling confirmScreenshotUpload.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/screenshots/${this.configuration.encodeParam({name: "screenshotId", value: screenshotId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/confirm`;
        return this.httpClient.request<ConfirmAttachmentUpload200Response>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Website
     * Creates a website to be stored in the database
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAWebsite(requestParameters?: CreateAWebsiteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CreateAWebsite201Response>;
    public createAWebsite(requestParameters?: CreateAWebsiteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CreateAWebsite201Response>>;
    public createAWebsite(requestParameters?: CreateAWebsiteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CreateAWebsite201Response>>;
    public createAWebsite(requestParameters?: CreateAWebsiteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const CreateAWebsiteRequest = requestParameters?.CreateAWebsiteRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/websites`;
        return this.httpClient.request<CreateAWebsite201Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: CreateAWebsiteRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Account
     * Creates and an Account that has access to Test Transactions. ID and Name should correlate back to data in the Account Service. **Analyst only endpoint.**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAccount(requestParameters?: CreateAccountRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<any>>;
    public createAccount(requestParameters?: CreateAccountRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<any>>>;
    public createAccount(requestParameters?: CreateAccountRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<any>>>;
    public createAccount(requestParameters?: CreateAccountRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const Authorization = requestParameters?.Authorization;
        const CreateAccountRequest = requestParameters?.CreateAccountRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/accounts`;
        return this.httpClient.request<Array<any>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: CreateAccountRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create category
     * Creates a category to be used with websites
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCategory(requestParameters?: CreateCategoryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CreateCategory201Response>;
    public createCategory(requestParameters?: CreateCategoryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CreateCategory201Response>>;
    public createCategory(requestParameters?: CreateCategoryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CreateCategory201Response>>;
    public createCategory(requestParameters?: CreateCategoryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const CreateCategoryRequest = requestParameters?.CreateCategoryRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/categories`;
        return this.httpClient.request<CreateCategory201Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: CreateCategoryRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tag
     * 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTag(requestParameters?: CreateTagRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CreateTag201Response>;
    public createTag(requestParameters?: CreateTagRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CreateTag201Response>>;
    public createTag(requestParameters?: CreateTagRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CreateTag201Response>>;
    public createTag(requestParameters?: CreateTagRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const CreateTagRequest = requestParameters?.CreateTagRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/tags`;
        return this.httpClient.request<CreateTag201Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: CreateTagRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Transaction
     * 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTransaction(requestParameters?: CreateTransactionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CreateTransaction201Response>;
    public createTransaction(requestParameters?: CreateTransactionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CreateTransaction201Response>>;
    public createTransaction(requestParameters?: CreateTransactionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CreateTransaction201Response>>;
    public createTransaction(requestParameters?: CreateTransactionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const CreateTransactionRequest = requestParameters?.CreateTransactionRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions`;
        return this.httpClient.request<CreateTransaction201Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: CreateTransactionRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a website
     * (Soft) Deletes a website from the database
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAWebsite(requestParameters?: DeleteAWebsiteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteAWebsite(requestParameters?: DeleteAWebsiteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteAWebsite(requestParameters?: DeleteAWebsiteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteAWebsite(requestParameters?: DeleteAWebsiteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAWebsite.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/websites/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete attachment
     * Deletes an attachment
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAttachment(requestParameters?: DeleteAttachmentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteAttachment(requestParameters?: DeleteAttachmentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteAttachment(requestParameters?: DeleteAttachmentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteAttachment(requestParameters?: DeleteAttachmentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAttachment.');
        }
        const attachmentId = requestParameters?.attachmentId;
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling deleteAttachment.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments/${this.configuration.encodeParam({name: "attachmentId", value: attachmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete screenshot
     * Deletes a screenshot
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteScreenshot(requestParameters?: DeleteScreenshotRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteScreenshot(requestParameters?: DeleteScreenshotRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteScreenshot(requestParameters?: DeleteScreenshotRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteScreenshot(requestParameters?: DeleteScreenshotRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteScreenshot.');
        }
        const screenshotId = requestParameters?.screenshotId;
        if (screenshotId === null || screenshotId === undefined) {
            throw new Error('Required parameter screenshotId was null or undefined when calling deleteScreenshot.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/screenshots/${this.configuration.encodeParam({name: "screenshotId", value: screenshotId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Tag
     * Removes all tags from a Transaction object
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTag(requestParameters?: DeleteTagRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteTag(requestParameters?: DeleteTagRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteTag(requestParameters?: DeleteTagRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteTag(requestParameters?: DeleteTagRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteTag.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/tags`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Transaction
     * Soft deletes a transaction.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTransaction(requestParameters?: DeleteTransactionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteTransaction(requestParameters?: DeleteTransactionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteTransaction(requestParameters?: DeleteTransactionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteTransaction(requestParameters?: DeleteTransactionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteTransaction.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download all attachments
     * Download all attachments on a transaction
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAllAttachments(requestParameters?: DownloadAllAttachmentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<DownloadAllAttachments200ResponseInner>>;
    public downloadAllAttachments(requestParameters?: DownloadAllAttachmentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<DownloadAllAttachments200ResponseInner>>>;
    public downloadAllAttachments(requestParameters?: DownloadAllAttachmentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<DownloadAllAttachments200ResponseInner>>>;
    public downloadAllAttachments(requestParameters?: DownloadAllAttachmentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadAllAttachments.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments`;
        return this.httpClient.request<Array<DownloadAllAttachments200ResponseInner>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download all screenshots
     * Download all screenshots on a transaction
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAllScreenshots(requestParameters?: DownloadAllScreenshotsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<DownloadAllAttachments200ResponseInner>>;
    public downloadAllScreenshots(requestParameters?: DownloadAllScreenshotsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<DownloadAllAttachments200ResponseInner>>>;
    public downloadAllScreenshots(requestParameters?: DownloadAllScreenshotsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<DownloadAllAttachments200ResponseInner>>>;
    public downloadAllScreenshots(requestParameters?: DownloadAllScreenshotsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadAllScreenshots.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/screenshots`;
        return this.httpClient.request<Array<DownloadAllAttachments200ResponseInner>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download attachment
     * Returns metadata for a client to download the file. Metadata will contain a url for the file.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAttachment(requestParameters?: DownloadAttachmentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DownloadAttachment200Response>;
    public downloadAttachment(requestParameters?: DownloadAttachmentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DownloadAttachment200Response>>;
    public downloadAttachment(requestParameters?: DownloadAttachmentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DownloadAttachment200Response>>;
    public downloadAttachment(requestParameters?: DownloadAttachmentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadAttachment.');
        }
        const attachmentId = requestParameters?.attachmentId;
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling downloadAttachment.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachment/${this.configuration.encodeParam({name: "attachmentId", value: attachmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<DownloadAttachment200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download screenshot
     * Returns metadata for a client to download the file. Metadata will contain a url for the file.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadScreenshot(requestParameters?: DownloadScreenshotRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DownloadAttachment200Response>;
    public downloadScreenshot(requestParameters?: DownloadScreenshotRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DownloadAttachment200Response>>;
    public downloadScreenshot(requestParameters?: DownloadScreenshotRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DownloadAttachment200Response>>;
    public downloadScreenshot(requestParameters?: DownloadScreenshotRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadScreenshot.');
        }
        const screenshotId = requestParameters?.screenshotId;
        if (screenshotId === null || screenshotId === undefined) {
            throw new Error('Required parameter screenshotId was null or undefined when calling downloadScreenshot.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/screenshot/${this.configuration.encodeParam({name: "screenshotId", value: screenshotId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<DownloadAttachment200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download transactions
     * Downloads the search results to a CSV
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTransactions(requestParameters?: DownloadTransactionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext}): Observable<string>;
    public downloadTransactions(requestParameters?: DownloadTransactionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext}): Observable<HttpResponse<string>>;
    public downloadTransactions(requestParameters?: DownloadTransactionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext}): Observable<HttpEvent<string>>;
    public downloadTransactions(requestParameters?: DownloadTransactionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext}): Observable<any> {
        const sortDirection = requestParameters?.sortDirection;
        const sortField = requestParameters?.sortField;
        const includeDeleted = requestParameters?.includeDeleted;
        const uniqueWebsites = requestParameters?.uniqueWebsites;
        const id = requestParameters?.id;
        const accountId = requestParameters?.accountId;
        const acquirerBid = requestParameters?.acquirerBid;
        const acquirerBin = requestParameters?.acquirerBin;
        const acquirerCountry = requestParameters?.acquirerCountry;
        const acquirerName = requestParameters?.acquirerName;
        const acquirerRegion = requestParameters?.acquirerRegion;
        const agent = requestParameters?.agent;
        const analysisDate = requestParameters?.analysisDate;
        const cardAcceptorId = requestParameters?.cardAcceptorId;
        const cardholderLocation = requestParameters?.cardholderLocation;
        const category = requestParameters?.category;
        const ccLast4 = requestParameters?.ccLast4;
        const client = requestParameters?.client;
        const clientSub = requestParameters?.clientSub;
        const createdAt = requestParameters?.createdAt;
        const mccCategory = requestParameters?.mccCategory;
        const merchantCategoryCode = requestParameters?.merchantCategoryCode;
        const merchantCountry = requestParameters?.merchantCountry;
        const merchantDescriptor = requestParameters?.merchantDescriptor;
        const merchantState = requestParameters?.merchantState;
        const monitoringStatus = requestParameters?.monitoringStatus;
        const network = requestParameters?.network;
        const paymentWebsite = requestParameters?.paymentWebsite;
        const issuerLocation = requestParameters?.issuerLocation;
        const purchaseAmount = requestParameters?.purchaseAmount;
        const recordTags = requestParameters?.recordTags;
        const requestId = requestParameters?.requestId;
        const rightsHolder = requestParameters?.rightsHolder;
        const source = requestParameters?.source;
        const terminalId = requestParameters?.terminalId;
        const transactionAmount = requestParameters?.transactionAmount;
        const transactionCurrency = requestParameters?.transactionCurrency;
        const transactionDate = requestParameters?.transactionDate;
        const transactionDescription = requestParameters?.transactionDescription;
        const transactionId = requestParameters?.transactionId;
        const transactionStatus = requestParameters?.transactionStatus;
        const transactionUSDAmount = requestParameters?.transactionUSDAmount;
        const websiteUrl = requestParameters?.websiteUrl;
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortDirection !== undefined && sortDirection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDirection, 'sortDirection');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeDeleted, 'includeDeleted');
        }
        if (uniqueWebsites !== undefined && uniqueWebsites !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uniqueWebsites, 'uniqueWebsites');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (accountId !== undefined && accountId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accountId, 'accountId');
        }
        if (acquirerBid !== undefined && acquirerBid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerBid, 'acquirerBid');
        }
        if (acquirerBin !== undefined && acquirerBin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerBin, 'acquirerBin');
        }
        if (acquirerCountry !== undefined && acquirerCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerCountry, 'acquirerCountry');
        }
        if (acquirerName !== undefined && acquirerName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerName, 'acquirerName');
        }
        if (acquirerRegion !== undefined && acquirerRegion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerRegion, 'acquirerRegion');
        }
        if (agent !== undefined && agent !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>agent, 'agent');
        }
        if (analysisDate !== undefined && analysisDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>analysisDate, 'analysisDate');
        }
        if (cardAcceptorId !== undefined && cardAcceptorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cardAcceptorId, 'cardAcceptorId');
        }
        if (cardholderLocation !== undefined && cardholderLocation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cardholderLocation, 'cardholderLocation');
        }
        if (category !== undefined && category !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>category, 'category');
        }
        if (ccLast4 !== undefined && ccLast4 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ccLast4, 'ccLast4');
        }
        if (client !== undefined && client !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>client, 'client');
        }
        if (clientSub !== undefined && clientSub !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientSub, 'clientSub');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (mccCategory !== undefined && mccCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>mccCategory, 'mccCategory');
        }
        if (merchantCategoryCode !== undefined && merchantCategoryCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantCategoryCode, 'merchantCategoryCode');
        }
        if (merchantCountry !== undefined && merchantCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantCountry, 'merchantCountry');
        }
        if (merchantDescriptor !== undefined && merchantDescriptor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantDescriptor, 'merchantDescriptor');
        }
        if (merchantState !== undefined && merchantState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantState, 'merchantState');
        }
        if (monitoringStatus !== undefined && monitoringStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>monitoringStatus, 'monitoringStatus');
        }
        if (network !== undefined && network !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>network, 'network');
        }
        if (paymentWebsite !== undefined && paymentWebsite !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentWebsite, 'paymentWebsite');
        }
        if (issuerLocation !== undefined && issuerLocation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>issuerLocation, 'issuerLocation');
        }
        if (purchaseAmount !== undefined && purchaseAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>purchaseAmount, 'purchaseAmount');
        }
        if (recordTags !== undefined && recordTags !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recordTags, 'recordTags');
        }
        if (requestId !== undefined && requestId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>requestId, 'requestId');
        }
        if (rightsHolder !== undefined && rightsHolder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rightsHolder, 'rightsHolder');
        }
        if (source !== undefined && source !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>source, 'source');
        }
        if (terminalId !== undefined && terminalId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>terminalId, 'terminalId');
        }
        if (transactionAmount !== undefined && transactionAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionAmount, 'transactionAmount');
        }
        if (transactionCurrency !== undefined && transactionCurrency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionCurrency, 'transactionCurrency');
        }
        if (transactionDate !== undefined && transactionDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionDate, 'transactionDate');
        }
        if (transactionDescription !== undefined && transactionDescription !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionDescription, 'transactionDescription');
        }
        if (transactionId !== undefined && transactionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionId, 'transactionId');
        }
        if (transactionStatus !== undefined && transactionStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionStatus, 'transactionStatus');
        }
        if (transactionUSDAmount !== undefined && transactionUSDAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionUSDAmount, 'transactionUSDAmount');
        }
        if (websiteUrl !== undefined && websiteUrl !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>websiteUrl, 'websiteUrl');
        }

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/download`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Website by ID
     * Finds a single website by a given ID.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAWebsiteByID(requestParameters?: GetAWebsiteByIDRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CreateAWebsite201Response>;
    public getAWebsiteByID(requestParameters?: GetAWebsiteByIDRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CreateAWebsite201Response>>;
    public getAWebsiteByID(requestParameters?: GetAWebsiteByIDRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CreateAWebsite201Response>>;
    public getAWebsiteByID(requestParameters?: GetAWebsiteByIDRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAWebsiteByID.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/websites/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<CreateAWebsite201Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Transaction
     * 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTransaction(requestParameters?: GetTransactionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetTransaction200Response>;
    public getTransaction(requestParameters?: GetTransactionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetTransaction200Response>>;
    public getTransaction(requestParameters?: GetTransactionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetTransaction200Response>>;
    public getTransaction(requestParameters?: GetTransactionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTransaction.');
        }
        const includeDeleted = requestParameters?.includeDeleted;
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (includeDeleted !== undefined && includeDeleted !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeDeleted, 'includeDeleted');
        }

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<GetTransaction200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all accounts
     * Lists all the accounts that have access to Test Transactions. Uses a hard-coded list of accounts and IDs. **Analyst only endpoint.**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAllAccounts(requestParameters?: ListAllAccountsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ListAllAccounts200Response>;
    public listAllAccounts(requestParameters?: ListAllAccountsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ListAllAccounts200Response>>;
    public listAllAccounts(requestParameters?: ListAllAccountsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ListAllAccounts200Response>>;
    public listAllAccounts(requestParameters?: ListAllAccountsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const Authorization = requestParameters?.Authorization;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/accounts`;
        return this.httpClient.request<ListAllAccounts200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all categories
     * Searches and filtes for categories.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAllCategories(requestParameters?: ListAllCategoriesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ListAllCategories200ResponseInner>>;
    public listAllCategories(requestParameters?: ListAllCategoriesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ListAllCategories200ResponseInner>>>;
    public listAllCategories(requestParameters?: ListAllCategoriesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ListAllCategories200ResponseInner>>>;
    public listAllCategories(requestParameters?: ListAllCategoriesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const filter = requestParameters?.filter;
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (filter !== undefined && filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filter, 'filter');
        }

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/categories`;
        return this.httpClient.request<Array<ListAllCategories200ResponseInner>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Tags
     * 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listTags(requestParameters?: ListTagsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ListTags200ResponseInner>>;
    public listTags(requestParameters?: ListTagsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ListTags200ResponseInner>>>;
    public listTags(requestParameters?: ListTagsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ListTags200ResponseInner>>>;
    public listTags(requestParameters?: ListTagsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/tags`;
        return this.httpClient.request<Array<ListTags200ResponseInner>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List unique values
     * Returns all unique values for a given column. For &#x60;Test Transaction Users&#x60; the results will be scoped to their account. For &#x60;Test Transaction Analysts&#x60; they will receive all values. &#x60;Test Transaction Users&#x60; will not have access to the Client and Account ID columns.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listUniqueValues(requestParameters?: ListUniqueValuesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ListUniqueValues200Response>;
    public listUniqueValues(requestParameters?: ListUniqueValuesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ListUniqueValues200Response>>;
    public listUniqueValues(requestParameters?: ListUniqueValuesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ListUniqueValues200Response>>;
    public listUniqueValues(requestParameters?: ListUniqueValuesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const column = requestParameters?.column;
        if (column === null || column === undefined) {
            throw new Error('Required parameter column was null or undefined when calling listUniqueValues.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/meta/${this.configuration.encodeParam({name: "column", value: column, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<ListUniqueValues200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Websites
     * Paginated list of websites
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listWebsites(requestParameters?: ListWebsitesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ListWebsites200Response>;
    public listWebsites(requestParameters?: ListWebsitesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ListWebsites200Response>>;
    public listWebsites(requestParameters?: ListWebsitesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ListWebsites200Response>>;
    public listWebsites(requestParameters?: ListWebsitesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const page = requestParameters?.page;
        const size = requestParameters?.size;
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/websites`;
        return this.httpClient.request<ListWebsites200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reorder screenshots
     * Sets the order of the screenshots for a transaction Accepts the array of the file ids, in the desired order
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reorderScreenshots(requestParameters?: ReorderScreenshotsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ReorderScreenshots200ResponseInner>>;
    public reorderScreenshots(requestParameters?: ReorderScreenshotsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ReorderScreenshots200ResponseInner>>>;
    public reorderScreenshots(requestParameters?: ReorderScreenshotsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ReorderScreenshots200ResponseInner>>>;
    public reorderScreenshots(requestParameters?: ReorderScreenshotsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling reorderScreenshots.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const request_body = requestParameters?.request_body;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/screenshots/order`;
        return this.httpClient.request<Array<ReorderScreenshots200ResponseInner>>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: request_body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request screenshots
     * Request screenshots. It sends an email to Analysts requesting screenshots for an specific transaction
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestScreenshots(requestParameters?: RequestScreenshotsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<RequestScreenshots200Response>;
    public requestScreenshots(requestParameters?: RequestScreenshotsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<RequestScreenshots200Response>>;
    public requestScreenshots(requestParameters?: RequestScreenshotsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<RequestScreenshots200Response>>;
    public requestScreenshots(requestParameters?: RequestScreenshotsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling requestScreenshots.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const RequestScreenshotsRequest = requestParameters?.RequestScreenshotsRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/screenshots/request`;
        return this.httpClient.request<RequestScreenshots200Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: RequestScreenshotsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transaction Details for MyLs
     * Returns a details object that meets the needs for MyLs.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transactionDetailsForMyLs(requestParameters?: TransactionDetailsForMyLsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TransactionDetailsForMyLs200Response>;
    public transactionDetailsForMyLs(requestParameters?: TransactionDetailsForMyLsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TransactionDetailsForMyLs200Response>>;
    public transactionDetailsForMyLs(requestParameters?: TransactionDetailsForMyLsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TransactionDetailsForMyLs200Response>>;
    public transactionDetailsForMyLs(requestParameters?: TransactionDetailsForMyLsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling transactionDetailsForMyLs.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/details`;
        return this.httpClient.request<TransactionDetailsForMyLs200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transactions Summary
     * Returns summary information for the transactions in the Account.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transactionsSummary(requestParameters?: TransactionsSummaryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TransactionsSummary200Response>;
    public transactionsSummary(requestParameters?: TransactionsSummaryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TransactionsSummary200Response>>;
    public transactionsSummary(requestParameters?: TransactionsSummaryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TransactionsSummary200Response>>;
    public transactionsSummary(requestParameters?: TransactionsSummaryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const includeDeleted = requestParameters?.includeDeleted;
        const uniqueWebsites = requestParameters?.uniqueWebsites;
        const id = requestParameters?.id;
        const acquirerBid = requestParameters?.acquirerBid;
        const acquirerBin = requestParameters?.acquirerBin;
        const acquirerCountry = requestParameters?.acquirerCountry;
        const acquirerName = requestParameters?.acquirerName;
        const acquirerRegion = requestParameters?.acquirerRegion;
        const agent = requestParameters?.agent;
        const analysisDate = requestParameters?.analysisDate;
        const cardAcceptorId = requestParameters?.cardAcceptorId;
        const cardholderLocation = requestParameters?.cardholderLocation;
        const category = requestParameters?.category;
        const ccLast4 = requestParameters?.ccLast4;
        const clientSub = requestParameters?.clientSub;
        const createdAt = requestParameters?.createdAt;
        const mccCategory = requestParameters?.mccCategory;
        const merchantCategoryCode = requestParameters?.merchantCategoryCode;
        const merchantCountry = requestParameters?.merchantCountry;
        const merchantDescriptor = requestParameters?.merchantDescriptor;
        const merchantState = requestParameters?.merchantState;
        const monitoringStatus = requestParameters?.monitoringStatus;
        const network = requestParameters?.network;
        const paymentWebsite = requestParameters?.paymentWebsite;
        const issuerLocation = requestParameters?.issuerLocation;
        const purchaseAmount = requestParameters?.purchaseAmount;
        const recordTags = requestParameters?.recordTags;
        const requestId = requestParameters?.requestId;
        const rightsHolder = requestParameters?.rightsHolder;
        const source = requestParameters?.source;
        const terminalId = requestParameters?.terminalId;
        const transactionAmount = requestParameters?.transactionAmount;
        const transactionCurrency = requestParameters?.transactionCurrency;
        const transactionDate = requestParameters?.transactionDate;
        const transactionDescription = requestParameters?.transactionDescription;
        const transactionId = requestParameters?.transactionId;
        const transactionStatus = requestParameters?.transactionStatus;
        const transactionUSDAmount = requestParameters?.transactionUSDAmount;
        const websiteUrl = requestParameters?.websiteUrl;
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (includeDeleted !== undefined && includeDeleted !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeDeleted, 'includeDeleted');
        }
        if (uniqueWebsites !== undefined && uniqueWebsites !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uniqueWebsites, 'uniqueWebsites');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (acquirerBid !== undefined && acquirerBid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerBid, 'acquirerBid');
        }
        if (acquirerBin !== undefined && acquirerBin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerBin, 'acquirerBin');
        }
        if (acquirerCountry !== undefined && acquirerCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerCountry, 'acquirerCountry');
        }
        if (acquirerName !== undefined && acquirerName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerName, 'acquirerName');
        }
        if (acquirerRegion !== undefined && acquirerRegion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerRegion, 'acquirerRegion');
        }
        if (agent !== undefined && agent !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>agent, 'agent');
        }
        if (analysisDate !== undefined && analysisDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>analysisDate, 'analysisDate');
        }
        if (cardAcceptorId !== undefined && cardAcceptorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cardAcceptorId, 'cardAcceptorId');
        }
        if (cardholderLocation !== undefined && cardholderLocation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cardholderLocation, 'cardholderLocation');
        }
        if (category !== undefined && category !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>category, 'category');
        }
        if (ccLast4 !== undefined && ccLast4 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ccLast4, 'ccLast4');
        }
        if (clientSub !== undefined && clientSub !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientSub, 'clientSub');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (mccCategory !== undefined && mccCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>mccCategory, 'mccCategory');
        }
        if (merchantCategoryCode !== undefined && merchantCategoryCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantCategoryCode, 'merchantCategoryCode');
        }
        if (merchantCountry !== undefined && merchantCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantCountry, 'merchantCountry');
        }
        if (merchantDescriptor !== undefined && merchantDescriptor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantDescriptor, 'merchantDescriptor');
        }
        if (merchantState !== undefined && merchantState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantState, 'merchantState');
        }
        if (monitoringStatus !== undefined && monitoringStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>monitoringStatus, 'monitoringStatus');
        }
        if (network !== undefined && network !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>network, 'network');
        }
        if (paymentWebsite !== undefined && paymentWebsite !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentWebsite, 'paymentWebsite');
        }
        if (issuerLocation !== undefined && issuerLocation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>issuerLocation, 'issuerLocation');
        }
        if (purchaseAmount !== undefined && purchaseAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>purchaseAmount, 'purchaseAmount');
        }
        if (recordTags !== undefined && recordTags !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recordTags, 'recordTags');
        }
        if (requestId !== undefined && requestId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>requestId, 'requestId');
        }
        if (rightsHolder !== undefined && rightsHolder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rightsHolder, 'rightsHolder');
        }
        if (source !== undefined && source !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>source, 'source');
        }
        if (terminalId !== undefined && terminalId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>terminalId, 'terminalId');
        }
        if (transactionAmount !== undefined && transactionAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionAmount, 'transactionAmount');
        }
        if (transactionCurrency !== undefined && transactionCurrency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionCurrency, 'transactionCurrency');
        }
        if (transactionDate !== undefined && transactionDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionDate, 'transactionDate');
        }
        if (transactionDescription !== undefined && transactionDescription !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionDescription, 'transactionDescription');
        }
        if (transactionId !== undefined && transactionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionId, 'transactionId');
        }
        if (transactionStatus !== undefined && transactionStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionStatus, 'transactionStatus');
        }
        if (transactionUSDAmount !== undefined && transactionUSDAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionUSDAmount, 'transactionUSDAmount');
        }
        if (websiteUrl !== undefined && websiteUrl !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>websiteUrl, 'websiteUrl');
        }

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/summary`;
        return this.httpClient.request<TransactionsSummary200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a website
     * Updates attributes of a website
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAWebsite(requestParameters?: UpdateAWebsiteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CreateAWebsite201Response>;
    public updateAWebsite(requestParameters?: UpdateAWebsiteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CreateAWebsite201Response>>;
    public updateAWebsite(requestParameters?: UpdateAWebsiteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CreateAWebsite201Response>>;
    public updateAWebsite(requestParameters?: UpdateAWebsiteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateAWebsite.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const UpdateAWebsiteRequest = requestParameters?.UpdateAWebsiteRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/websites/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<CreateAWebsite201Response>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: UpdateAWebsiteRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tags
     * Replaces the tags set on a Transaction object
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTags(requestParameters?: UpdateTagsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetTransaction200Response>;
    public updateTags(requestParameters?: UpdateTagsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetTransaction200Response>>;
    public updateTags(requestParameters?: UpdateTagsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetTransaction200Response>>;
    public updateTags(requestParameters?: UpdateTagsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateTags.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const UpdateTagsRequest = requestParameters?.UpdateTagsRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/tags`;
        return this.httpClient.request<GetTransaction200Response>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: UpdateTagsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Transaction
     * 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTransaction(requestParameters?: UpdateTransactionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CreateTransaction201Response>;
    public updateTransaction(requestParameters?: UpdateTransactionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CreateTransaction201Response>>;
    public updateTransaction(requestParameters?: UpdateTransactionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CreateTransaction201Response>>;
    public updateTransaction(requestParameters?: UpdateTransactionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateTransaction.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const UpdateTransactionRequest = requestParameters?.UpdateTransactionRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<CreateTransaction201Response>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: UpdateTransactionRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload attachment
     * Upload an attachment to a transaction
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadAttachment(requestParameters?: UploadAttachmentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UploadAttachment201Response>;
    public uploadAttachment(requestParameters?: UploadAttachmentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UploadAttachment201Response>>;
    public uploadAttachment(requestParameters?: UploadAttachmentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UploadAttachment201Response>>;
    public uploadAttachment(requestParameters?: UploadAttachmentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling uploadAttachment.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const UploadFileRequest = requestParameters?.UploadFileRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments`;
        return this.httpClient.request<UploadAttachment201Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: UploadFileRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload screenshot
     * Upload an screenshot to a transaction
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadScreenshot(requestParameters?: UploadScreenshotRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DownloadAttachment200Response>;
    public uploadScreenshot(requestParameters?: UploadScreenshotRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DownloadAttachment200Response>>;
    public uploadScreenshot(requestParameters?: UploadScreenshotRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DownloadAttachment200Response>>;
    public uploadScreenshot(requestParameters?: UploadScreenshotRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling uploadScreenshot.');
        }
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const UploadFileRequest = requestParameters?.UploadFileRequest;

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/screenshots`;
        return this.httpClient.request<DownloadAttachment200Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: UploadFileRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload transactions
     * Accepts a CSV to bulk upload test transactions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadTransactions(requestParameters?: UploadTransactionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UploadTransactions200Response>;
    public uploadTransactions(requestParameters?: UploadTransactionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UploadTransactions200Response>>;
    public uploadTransactions(requestParameters?: UploadTransactionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UploadTransactions200Response>>;
    public uploadTransactions(requestParameters?: UploadTransactionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const migration = requestParameters?.migration;
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;
        const body = requestParameters?.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (migration !== undefined && migration !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>migration, 'migration');
        }

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'text/csv'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/upload`;
        return this.httpClient.request<UploadTransactions200Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User search for transactions
     * Search for Transactions intended for User role which locks to a rqeuired account based on X-Account-Id. Does not return internal_note field for User. Returns a paginated list of results.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userSearchForTransactions(requestParameters?: UserSearchForTransactionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserSearchForTransactions200Response>;
    public userSearchForTransactions(requestParameters?: UserSearchForTransactionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserSearchForTransactions200Response>>;
    public userSearchForTransactions(requestParameters?: UserSearchForTransactionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserSearchForTransactions200Response>>;
    public userSearchForTransactions(requestParameters?: UserSearchForTransactionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const page = requestParameters?.page;
        const size = requestParameters?.size;
        const sortDirection = requestParameters?.sortDirection;
        const sortField = requestParameters?.sortField;
        const includeDeleted = requestParameters?.includeDeleted;
        const uniqueWebsites = requestParameters?.uniqueWebsites;
        const id = requestParameters?.id;
        const legitscriptId = requestParameters?.legitscriptId;
        const acquirerBid = requestParameters?.acquirerBid;
        const acquirerBin = requestParameters?.acquirerBin;
        const acquirerCountry = requestParameters?.acquirerCountry;
        const acquirerName = requestParameters?.acquirerName;
        const acquirerRegion = requestParameters?.acquirerRegion;
        const agent = requestParameters?.agent;
        const analysisDate = requestParameters?.analysisDate;
        const cardAcceptorId = requestParameters?.cardAcceptorId;
        const cardholderLocation = requestParameters?.cardholderLocation;
        const category = requestParameters?.category;
        const ccLast4 = requestParameters?.ccLast4;
        const clientSub = requestParameters?.clientSub;
        const createdAt = requestParameters?.createdAt;
        const mccCategory = requestParameters?.mccCategory;
        const merchantCategoryCode = requestParameters?.merchantCategoryCode;
        const merchantCountry = requestParameters?.merchantCountry;
        const merchantDescriptor = requestParameters?.merchantDescriptor;
        const merchantState = requestParameters?.merchantState;
        const monitoringStatus = requestParameters?.monitoringStatus;
        const network = requestParameters?.network;
        const paymentWebsite = requestParameters?.paymentWebsite;
        const issuerLocation = requestParameters?.issuerLocation;
        const purchaseAmount = requestParameters?.purchaseAmount;
        const recordTags = requestParameters?.recordTags;
        const requestId = requestParameters?.requestId;
        const rightsHolder = requestParameters?.rightsHolder;
        const source = requestParameters?.source;
        const terminalId = requestParameters?.terminalId;
        const transactionAmount = requestParameters?.transactionAmount;
        const transactionCurrency = requestParameters?.transactionCurrency;
        const transactionDate = requestParameters?.transactionDate;
        const transactionDescription = requestParameters?.transactionDescription;
        const transactionId = requestParameters?.transactionId;
        const transactionStatus = requestParameters?.transactionStatus;
        const transactionUSDAmount = requestParameters?.transactionUSDAmount;
        const websiteUrl = requestParameters?.websiteUrl;
        const Authorization = requestParameters?.Authorization;
        const X_Account_Id = requestParameters?.X_Account_Id;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortDirection !== undefined && sortDirection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDirection, 'sortDirection');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeDeleted, 'includeDeleted');
        }
        if (uniqueWebsites !== undefined && uniqueWebsites !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uniqueWebsites, 'uniqueWebsites');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (legitscriptId !== undefined && legitscriptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>legitscriptId, 'legitscriptId');
        }
        if (acquirerBid !== undefined && acquirerBid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerBid, 'acquirerBid');
        }
        if (acquirerBin !== undefined && acquirerBin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerBin, 'acquirerBin');
        }
        if (acquirerCountry !== undefined && acquirerCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerCountry, 'acquirerCountry');
        }
        if (acquirerName !== undefined && acquirerName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerName, 'acquirerName');
        }
        if (acquirerRegion !== undefined && acquirerRegion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acquirerRegion, 'acquirerRegion');
        }
        if (agent !== undefined && agent !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>agent, 'agent');
        }
        if (analysisDate !== undefined && analysisDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>analysisDate, 'analysisDate');
        }
        if (cardAcceptorId !== undefined && cardAcceptorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cardAcceptorId, 'cardAcceptorId');
        }
        if (cardholderLocation !== undefined && cardholderLocation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cardholderLocation, 'cardholderLocation');
        }
        if (category !== undefined && category !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>category, 'category');
        }
        if (ccLast4 !== undefined && ccLast4 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ccLast4, 'ccLast4');
        }
        if (clientSub !== undefined && clientSub !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientSub, 'clientSub');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (mccCategory !== undefined && mccCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>mccCategory, 'mccCategory');
        }
        if (merchantCategoryCode !== undefined && merchantCategoryCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantCategoryCode, 'merchantCategoryCode');
        }
        if (merchantCountry !== undefined && merchantCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantCountry, 'merchantCountry');
        }
        if (merchantDescriptor !== undefined && merchantDescriptor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantDescriptor, 'merchantDescriptor');
        }
        if (merchantState !== undefined && merchantState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantState, 'merchantState');
        }
        if (monitoringStatus !== undefined && monitoringStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>monitoringStatus, 'monitoringStatus');
        }
        if (network !== undefined && network !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>network, 'network');
        }
        if (paymentWebsite !== undefined && paymentWebsite !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentWebsite, 'paymentWebsite');
        }
        if (issuerLocation !== undefined && issuerLocation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>issuerLocation, 'issuerLocation');
        }
        if (purchaseAmount !== undefined && purchaseAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>purchaseAmount, 'purchaseAmount');
        }
        if (recordTags !== undefined && recordTags !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recordTags, 'recordTags');
        }
        if (requestId !== undefined && requestId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>requestId, 'requestId');
        }
        if (rightsHolder !== undefined && rightsHolder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rightsHolder, 'rightsHolder');
        }
        if (source !== undefined && source !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>source, 'source');
        }
        if (terminalId !== undefined && terminalId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>terminalId, 'terminalId');
        }
        if (transactionAmount !== undefined && transactionAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionAmount, 'transactionAmount');
        }
        if (transactionCurrency !== undefined && transactionCurrency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionCurrency, 'transactionCurrency');
        }
        if (transactionDate !== undefined && transactionDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionDate, 'transactionDate');
        }
        if (transactionDescription !== undefined && transactionDescription !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionDescription, 'transactionDescription');
        }
        if (transactionId !== undefined && transactionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionId, 'transactionId');
        }
        if (transactionStatus !== undefined && transactionStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionStatus, 'transactionStatus');
        }
        if (transactionUSDAmount !== undefined && transactionUSDAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transactionUSDAmount, 'transactionUSDAmount');
        }
        if (websiteUrl !== undefined && websiteUrl !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>websiteUrl, 'websiteUrl');
        }

        let localVarHeaders = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(Authorization));
        }
        if (X_Account_Id !== undefined && X_Account_Id !== null) {
            localVarHeaders = localVarHeaders.set('X-Account-Id', String(X_Account_Id));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions/user-search`;
        return this.httpClient.request<UserSearchForTransactions200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
