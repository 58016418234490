import { of as observableOf, timer as observableTimer, throwError as observableThrowError, Observable } from 'rxjs';

import { mergeMap, map, switchMap, retryWhen, shareReplay, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { EntitlementsState, BillingService, LocalStorageService } from '@ls/common-ng-components';
import { ProductFamily } from '@ls/common-ts-models';
import { environment } from 'src/environments/environment';
import { AppState, fnEntitlementsState } from 'src/app/reducers';

@Injectable()
export class ProductsAPILookupService {
  private doc$: Observable<any>;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private localStorageSvc: LocalStorageService,
    private billingSvc: BillingService,
  ) {}

  public getPricing(): Observable<any> {
    return this.billingSvc.getProductForFamily(ProductFamily.PRODUCT_API).pipe(map((response: any) => response[0]));
  }

  public getDocumentation(): Observable<any> {
    if (!this.doc$) {
      this.doc$ = this.store.select(fnEntitlementsState).pipe(
        switchMap((entitlements: EntitlementsState) => {
          const productId = entitlements.products[ProductFamily.PRODUCT_API].externalReferenceId;
          return this.http.get(`${environment.CONFIG.portalApiHost}/v1/api-docs/${productId}`, {
            headers: new HttpHeaders({
              Authorization: this.localStorageSvc.getToken(),
            }),
          });
        }),
        retryWhen((errors) => {
          // retry 15 times, every ten seconds, because I don't know how
          // to better juggle caching this w/ the "recently purchased" issue
          return errors.pipe(
            mergeMap((error, i) => {
              const attempt = i + 1;
              if (attempt >= 15) {
                return observableThrowError('Error loading documentation');
              }
              return observableTimer(10000);
            }),
          );
        }),
        catchError((err) => observableOf(err)),
        shareReplay(1),
      );
    }
    return this.doc$;
  }
}
