import { takeUntil, distinctUntilKeyChanged, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';

import {
  QuestionnaireState,
  QuestionnaireSaveAction,
  QuestionnaireLoadAction,
  QuestionnairePreviousAction,
  QuestionnaireAnswerLaterAction,
  QuestionnaireUploadFilesAndSaveAction,
  CancelEditCompletedQuestionAction,
} from '../reducers';
import { CertificationService } from '../services';
import { AnsweredQuestion, INPUT_TYPE } from '@ls/common-ts-models';
import { AppState, fnQuestionnaireState } from 'src/app/reducers';

@Component({
  selector: 'certification-questionnaire-wrapper',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './certification-questionnaire-wrapper.component.html',
  styleUrls: ['./certification-questionnaire-wrapper.component.scss'],
})
export class CertificationQuestionnaireWrapperComponent implements OnInit, OnDestroy, AfterViewChecked {
  public questionnaire$: Observable<QuestionnaireState>;
  public questionnaireChanges$: Observable<QuestionnaireState>;
  public destroyed$: Subject<boolean> = new Subject();
  public questionType: string;
  public newInputValue: AnsweredQuestion;
  public pending: boolean;
  public saveClicked = false;
  public outerForm: FormGroup = new FormGroup({});
  public multiRowIsValid = false; // for multi row input, validity doesn't depend on form but on row input
  public renewing: boolean;
  public originalAnswerValue: AnsweredQuestion;
  public checkMultiRowValidity = [
    INPUT_TYPE.MULTI_ROW.valueOf(),
    INPUT_TYPE.CERTIFIABLE_ITEM_PRODUCT.valueOf(),
    INPUT_TYPE.CERTIFIABLE_ITEM_WEBSITE.valueOf(),
  ];

  private fileList: object[];
  private certificationId: string;
  private questionId: string;

  constructor(
    public store: Store<AppState>,
    public route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private certificationService: CertificationService,
  ) {}

  public ngOnInit() {
    this.certificationId = this.route.snapshot.params['certificationId'];
    this.questionId = this.route.snapshot.params['questionId'];

    // this fragment will be present on the url when the user is in the 'answerLater' question array.  if they do a full site refresh with a specific question id, it will ensure they return to the correct spot in the questionnaire
    const inAnswerLater: boolean = this.route.queryParams && this.route.snapshot.queryParams['inAnswerLater'];

    // if we are in a renewing certification state, need to do custom sorting
    this.renewing = this.route.queryParams && this.route.snapshot.queryParams['renewing'];

    this.store.dispatch(
      QuestionnaireLoadAction({
        certificationId: this.certificationId,
        currentQuestionId: this.questionId,
        inAnswerLater,
        renewing: this.renewing,
      }),
    );

    this.questionnaireChanges$ = this.store.select(fnQuestionnaireState);

    this.questionnaire$ = this.questionnaireChanges$.pipe(
      distinctUntilKeyChanged('question'),
      map((q) => JSON.parse(JSON.stringify(q))), // can't modify the actual state object, so operate on a copy
      takeUntil(this.destroyed$.asObservable()),
    );

    this.questionnaire$.subscribe((details: QuestionnaireState) => {
      if (details.currentAnswer?.id) {
        this.originalAnswerValue = details.currentAnswer;
        this.newInputValue = details.currentAnswer;
      }
      if (details.question?.id) {
        this.questionType = details.question.question_type;
        this.questionId = details.question.id;
      }
      if (details.isComplete) {
        // if we have a completed questionnaire, go to that component directly
        this.router.navigate([`/questionnaire-complete/${this.certificationId}`], { replaceUrl: true });
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  public handleChangesFromQuestionInput(obj) {
    const { answer, valid, resetSaveClicked } = obj;
    this.newInputValue = JSON.parse(JSON.stringify(answer));

    if (valid !== undefined) {
      this.multiRowIsValid = valid;
    }

    if (resetSaveClicked) {
      this.saveClicked = false;
    }
  }

  public nextQuestion() {
    this.saveClicked = true;
    this.cdr.detectChanges();
    const valid = this.checkMultiRowValidity.includes(this.questionType) ? this.multiRowIsValid : this.outerForm.valid;
    if (valid) {
      if (this.renewing && this.newInputValue !== this.originalAnswerValue) {
        // mark this answer for analysts in workbench
        this.newInputValue.edited = true;
      }
      const answer = this.newInputValue;
      if (
        [INPUT_TYPE.CERTIFIABLE_ITEM_PRODUCT.valueOf(), INPUT_TYPE.CERTIFIABLE_ITEM_WEBSITE.valueOf()].includes(
          this.questionType,
        )
      ) {
        // certifiable_item questions are saved as they are added (uploads too), so when the user hits 'save + next', all we have to do is dispatch a final save action to ensure the answered questions json bag is up to date and get the correct next question id
        this.store.dispatch(QuestionnaireSaveAction({ updatedInfo: answer }));
      } else {
        this.store.dispatch(
          QuestionnaireUploadFilesAndSaveAction({
            certificationId: this.certificationId,
            questionId: this.questionId,
            answer,
          }),
        );
      }
      this.saveClicked = false;
      this.cdr.detectChanges();
    } else {
      this.outerForm.markAsDirty();
    }
  }

  public prevQuestion() {
    this.saveClicked = false;
    this.store.dispatch(QuestionnairePreviousAction());
  }

  public answerLater() {
    this.saveClicked = false;
    this.store.dispatch(QuestionnaireAnswerLaterAction());
  }

  public cancelEdit() {
    const valid = this.checkMultiRowValidity.includes(this.questionType) ? this.multiRowIsValid : this.outerForm.valid;
    if (valid) {
      this.store.dispatch(CancelEditCompletedQuestionAction());
      this.router.navigate([`/questionnaire-complete/${this.certificationId}`], { queryParamsHandling: 'merge' });
    }
  }
}
