import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

/* tslint:disable */
export const TAG_SELECTOR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TagSelectorComponent),
  multi: true,
};

const TAG_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => TagSelectorComponent),
  multi: true,
};

@Component({
  selector: 'tag-selector',
  templateUrl: './tag-selector.component.html',
  styleUrls: ['./tag-selector.component.scss'],
  providers: [TAG_SELECTOR_VALUE_ACCESSOR, TAG_VALIDATOR],
})
export class TagSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() public inputTags: string[] = [];
  @Input() public removeEnabled = false;
  @Input() public editAble = false;
  @Input() public inputText = ''; // value from the input field

  public listedTags: string[];

  public isFocused = false;
  public isValid = false;
  public isUnderLimit = false;

  public ngOnInit() {
    this.listedTags = this.inputTags;
  }

  public onTouch: () => void;
  public onChange: (_: any) => void;

  public addTag() {
    const newTag = this.inputText.trim();
    if (!newTag.length) {
      return;
    }

    const matchedTags = this.listedTags.find((t) => t === newTag);

    if (!matchedTags) {
      this.listedTags.push(newTag);
    }
    this.inputText = '';
    this.validate();
    this.onChange(this.listedTags);
    this.onTouch();
  }

  public removeTag(Tag: string): void {
    const index = this.listedTags.indexOf(Tag);

    if (index >= 0) {
      this.listedTags.splice(index, 1);
    }

    this.validate();
    this.onChange(this.listedTags);
    this.onTouch();
  }

  public writeValue(value: string[]): void {
    this.inputTags = value;
    this.listedTags = value;
  }

  public validate() {
    this.isValid = this.listedTags.length <= 15;
    this.isUnderLimit = this.listedTags.length < 15;

    const placeHolder = this.isUnderLimit ? '' : 'Tag Limit';
    this.inputText = placeHolder;

    const isNotValid = !this.isValid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public blurEventHandler() {
    this.isFocused = false;
  }
  public focusEventHandler() {
    this.isFocused = true;
  }
}
