<legitscript-modal class="no-footer" [draggable]=false [resizable]=false [responsive]=true [modal]=true autoAlign=true [width]=800 height=auto>
    <div class="dialog-body" style="overflow:auto; text-align: left;">
      <div *ngIf="!processing">
          <h1>Are you sure you want to proceed with this bulk action?</h1>
          <form name="form">
              You’re about to apply the <strong>{{getActionText()}}</strong> action to <strong>{{merchantNumber}}</strong> merchants.
              <br>
              <br>
              Please note that the system may reject your changes if you apply an action that is incompatible with a certain status:
              <ul>
                <li>For merchants with a status of Pending, Monitored, or Published, all actions except Reset are allowed.</li>
                <li>For merchants with a status of Terminated, the following actions are allowed: Immediate termination (unless your last action was identical), 10 Day Termination, 15 Day Termination, 30 Day Termination, Reset.</li>
                <li>For merchants with a status of Inactive, Reset is the only permitted action.</li>
              </ul>
              <div style= "display: flex; justify-content: space-between;">
                <span>Comment</span>
                <field-validator class="right" [control]="commentSection"></field-validator>
              </div>
              <textarea #commentSection="ngModel" name="commentSection" rows="15" cols="30" [(ngModel)]="comment" class="comment-box-container" required checkWhitespace></textarea>
            <div class="button-layout" style="text-align: center;">
                <button type="button" (click)="cancel()" label="Cancel"  class="btn-no-bg-bordered">Cancel</button>
                <button type="button" (click)="submit()" label="Confirm" [disabled]= "comment ===''">Confirm</button>
            </div>
          </form>
      </div>
      <div *ngIf="processing">
        <loading-spinner loadingText="Processing Your Request"></loading-spinner>
      </div>
    </div>
</legitscript-modal> 
  
  