import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '@ls/common-ng-components';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Label } from '@ls/common-ts-models';

@Injectable()
export class TagsService {
  private mmApiHost = environment.CONFIG.mmApiHost;

  constructor(
    private http: HttpClient,
    private localStorageSvc: LocalStorageService,
  ) {}

  public getTags(): Observable<Label[]> {
    return this.http.get<Label[]>(`${this.mmApiHost}/customerTags`, this.localStorageSvc.getJWTAndSetOptions());
  }

  public getLsTags(): Observable<Label[]> {
    return this.http.get<Label[]>(`${this.mmApiHost}/lsTags`, this.localStorageSvc.getJWTAndSetOptions());
  }
}
