import { createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { WBFilterSortDirection, PageUpdateOptions, Pagination, Upload } from '@ls/common-ts-models';
import { MMAppState } from './app.state';
import { fnMerchantListState } from './merchant-list.reducer';

export const HISTORYUPLOAD_LIST = '[HistoryUpload] List';
export const HISTORYUPLOAD_LIST_SUCCESS = '[HistoryUpload] List Success';
export const HISTORYUPLOAD_LIST_ERROR = '[HistoryUpload] List Error';
export const HISTORYUPLOAD_SET_VISIBLE = '[HistoryUpload] Set Visible';
export const HISTORYUPLOAD_GET_MIDS = '[HistoryUpload] Get MIDs';
export const HISTORYUPLOAD_GET_MIDS_SUCCESS = '[HistoryUpload] Get MIDs Success';
export const HISTORYUPLOAD_GET_MIDS_ERROR = '[HistoryUpload] Get MIDs Error';

export const HistoryUploadListAction = createAction(
  HISTORYUPLOAD_LIST,
  props<{
    sortDirection: WBFilterSortDirection;
    pageUpdateOptions: PageUpdateOptions;
  }>(),
);

export const HistoryUploadListSuccessAction = createAction(
  HISTORYUPLOAD_LIST_SUCCESS,
  props<{ historyUpload: Upload[]; pagination: Pagination }>(),
);

export const HistoryUploadListErrorAction = createAction(HISTORYUPLOAD_LIST_ERROR, props<{ err?: Error }>());

export const HistoryUploadSetVisibleAction = createAction(HISTORYUPLOAD_SET_VISIBLE, props<{ visible: boolean }>());

export const HistoryUploadGetMidsAction = createAction(HISTORYUPLOAD_GET_MIDS, props<{ uploadId: number }>());

export const HistoryUploadGetMidsSuccessAction = createAction(
  HISTORYUPLOAD_GET_MIDS_SUCCESS,
  props<{ mids: string[] }>(),
);

export const HistoryUploadGetMidsErrorAction = createAction(HISTORYUPLOAD_GET_MIDS_ERROR, props<{ err: Error }>());

export interface HistoryUploadListState {
  err: Error;
  uploads: Upload[];
  sortDirection: WBFilterSortDirection;
  pagination: Pagination;
  pending: boolean;
  historyUploadTableVisible: boolean;
}

export const initialHistoryUploadListState: HistoryUploadListState = {
  err: null,
  uploads: [],
  sortDirection: WBFilterSortDirection.Descending,
  pagination: {
    totalRecords: 0,
    totalPages: 0,
    pageNumber: 1,
    startPosition: 0,
    endPosition: 0,
  },
  pending: true,
  historyUploadTableVisible: false,
};

export const HistoryUploadListReducer = createReducer(
  initialHistoryUploadListState,

  on(HistoryUploadListAction, (state, { sortDirection }) => ({
    ...state,
    err: null,
    uploads: [],
    sortDirection,
    pending: true,
  })),

  on(HistoryUploadListSuccessAction, (state, { historyUpload, pagination }) => ({
    ...state,
    err: null,
    uploads: historyUpload,
    pagination,
    pending: false,
  })),

  on(HistoryUploadListErrorAction, (state, { err }) => ({
    ...state,
    err,
    pending: false,
  })),

  on(HistoryUploadSetVisibleAction, (state, { visible }) => ({
    ...state,
    historyUploadTableVisible: visible,
  })),

  on(HistoryUploadGetMidsAction, (state, { uploadId }) => ({
    ...state,
    uploads: [],
    pending: true,
  })),

  on(HistoryUploadGetMidsSuccessAction, (state, { mids }) => ({
    ...state,
    pending: false,
  })),
);

//
// STATE SELECTORS
//

export const fnHistoryUploadListState = createSelector(fnMerchantListState, (state) => state.historyUploadList);

export const fnHistoryUploadListUploads = createSelector(fnHistoryUploadListState, (state) => state.uploads);
export const fnHistoryUploadListPagination = createSelector(fnHistoryUploadListState, (state) => state.pagination);
export const fnHistoryUploadListVisible = createSelector(
  fnHistoryUploadListState,
  (state) => state.historyUploadTableVisible,
);
