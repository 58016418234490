import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, of, switchMap, tap } from 'rxjs';
import { Notification, NotificationStatus } from '../models';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '@ls/common-ng-components';
import { HttpClient } from '@angular/common/http';
import {
  IoTSystemMessagesTopics,
  IotSystemMessages,
  MyLSIoTConfig,
  MyLSIoTTopics,
  MyLSPushNotificationMessage,
} from '@ls/common-ts-models';
import { io, iot, mqtt } from 'aws-crt';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { notificationsActions } from '../state';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private mmApiHost = environment.CONFIG.mmApiHost;
  private connection: mqtt.MqttClientConnection;
  private clientId: string;
  private timer: number;
  private _allNotifications: Notification[];
  private lastDeleted: Notification[];
  private lastErrorMsg: DateTime;
  private notificationsAreOn = false;

  private get allNotifications(): Notification[] {
    if (!this._allNotifications) {
      this._allNotifications = this.reloadMessagesFromPastSubscriptions();
    }
    return this._allNotifications;
  }
  private set allNotifications(notifications) {
    this._allNotifications = notifications;
    this.saveNotifications(notifications);
  }

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
  ) {}

  public getNotificationsForUser(): Observable<Notification[]> {
    if (this.notificationsAreOn) {
      // notifications are already on for this user. Skip
      return of([]);
    }

    const options = this.localStorageService.getJWTAndSetOptions();
    // The iot autorizer endpoint returns a string for some reason...
    const configObservable = this.http.get<string>(`${this.mmApiHost}/iot-authorizer`, options);

    const REFRESH_INTERVAL: number = 55 * 60 * 1000; // Interval for grabing new PN config before end of each hour

    return configObservable.pipe(
      // The iot autorizer endpoint returns a string for some reason...
      map((response: string) => {
        return JSON.parse(response) as MyLSIoTConfig;
      }),
      switchMap((config) => this.buildConnection(config)),
      tap(() => {
        // on successful connection, start/restart refresh timer.
        window.clearInterval(this.timer);
        this.timer = window.setInterval(this.updateCredentials.bind(this), REFRESH_INTERVAL); // Operation occurs on intervals
      }),
      catchError(() => {
        return of([]);
      }),
    );
  }

  public stopGetNotificationsForUser() {
    window.clearInterval(this.timer);

    if (this.connection) {
      this.connection.disconnect();
      this.connection = null;
    }
  }

  public markNotificationRead(notification: Notification): Notification[] {
    const messages = this.allNotifications;
    const newMessages: Notification[] = messages.map((n) => ({
      ...n,
      status: n.id === notification.id ? NotificationStatus.read : n.status,
      showToast: false,
    }));
    this.allNotifications = newMessages;
    return newMessages;
  }

  public markNotificationDeleted(notification: Notification): Notification[] {
    const messages = this.allNotifications;
    const newMessages: Notification[] = messages.filter((n) => n.id !== notification.id);
    this.allNotifications = newMessages;
    this.lastDeleted = [notification];
    return newMessages;
  }

  public markAllNotificationsRead(): Notification[] {
    const messages = this.allNotifications;
    const newMessages: Notification[] = messages.map((n) => ({
      ...n,
      status: NotificationStatus.read,
      showToast: false,
    }));
    this.allNotifications = newMessages;
    return newMessages;
  }

  public markAllNotificationsDeleted() {
    this.lastDeleted = this.allNotifications;
    const newMessages: Notification[] = [];
    this.allNotifications = newMessages;
  }

  public undoDelete(): Notification[] {
    const undeleted = [...this.allNotifications, ...this.lastDeleted];
    const defaultDate = new Date(-8640000000000000);
    const sorted = undeleted.sort((n1, n2) => +(n2.date ?? defaultDate) - +(n1.date ?? defaultDate));
    this.allNotifications = sorted;
    this.lastDeleted = [];
    return sorted;
  }

  public addNotification(toAdd: Notification): Notification[] {
    let newNotifications: Notification[];
    const currentNotifications = this.allNotifications;
    const existingNotification = currentNotifications.find((notification) => notification.jobId === toAdd.jobId);

    //If the incoming notification has a job id and an existing message we want to update the existing notification in place
    if (toAdd.jobId && existingNotification) {
      let updatedIcon: string = undefined;
      if (existingNotification.icon && !toAdd.icon) {
        updatedIcon = existingNotification.icon;
      }
      const existingIndex = currentNotifications.indexOf(existingNotification);
      const newNotification: Notification = {
        ...existingNotification,
        ...toAdd,
        icon: updatedIcon ?? toAdd.icon,
        description: toAdd.description, // explicitly assigning so old defined values don't persist when new undefined value comes in
        status: toAdd.isFinalJobMessage ? NotificationStatus.unread : existingNotification.status,
        showToast: toAdd.showToast ?? toAdd.isFinalJobMessage ? true : false,
      };
      newNotifications = currentNotifications.map((notification, i) => {
        // If we update in place and the top notification has showToast === true we will spam the user with toasts
        // so we set the first notification's showToast value
        if (i === 0 && existingIndex !== 0) {
          return { ...notification, showToast: false };
        }
        if (i === existingIndex) {
          return newNotification;
        }
        return notification;
      });
    }
    //Otherwise there is no update necessary so just throw it at the top of notifcations
    else {
      newNotifications = [toAdd, ...currentNotifications];
    }
    this.allNotifications = newNotifications;
    return newNotifications;
  }

  private updateCredentials(): void {
    this.notificationsAreOn = false; // this will allow the getNotificationsForUser to reset the connection
    this.store.dispatch(notificationsActions.loadNotifications());
  }

  // Constructs an initial connection with the IoT cloud. Reconnects if connection was already made.
  private buildConnection(configCreds: MyLSIoTConfig): Observable<Notification[]> {
    const configBuilder = iot.AwsIotMqttConnectionConfigBuilder.new_with_websockets();

    configBuilder.with_clean_session(false);
    configBuilder.with_client_id(configCreds.iotUserId);
    configBuilder.with_endpoint(configCreds.host);
    configBuilder.with_credentials(configCreds.region, configCreds.key, configCreds.secret, configCreds.sessionToken);

    const configObj = configBuilder.build();
    const client = new mqtt.MqttClient(new io.ClientBootstrap());
    configObj.port = 443;

    this.connection = client.new_connection(configObj);

    this.connection.on('error', () => {
      console.log('Error with push notification connection');
    });

    this.connection.on('interrupt', () => {
      console.log('Interrupt with push notification connection. Refresh the page to fix the connection');
    });

    const initialMessages = new Subject<Notification[]>();

    this.connection.on('connect', () => {
      const messages = this.allNotifications;
      this.buildSubscriptions(configCreds.iotUserId);
      initialMessages.next(messages);
      this.notificationsAreOn = true;
    });

    return initialMessages.asObservable();
  }

  // Obtains the push notifications already obtained from storage
  private reloadMessagesFromPastSubscriptions(): Notification[] {
    const user = this.localStorageService.getAuthenticatedUser();

    const pastMessages = this.localStorageService.getPushNotifications(
      user.lsUserId,
      user.lsAccountId,
    ) as Notification[];
    const updatedPastMessages = pastMessages.map((m) => {
      return {
        ...m,
        date: new Date(m.date),
        showToast: false,
      };
    });
    return updatedPastMessages;
  }

  private saveNotifications(notifications: Notification[]) {
    const user = this.localStorageService.getAuthenticatedUser();
    this.localStorageService.setPushNotifications(
      notifications as MyLSPushNotificationMessage[],
      user.lsUserId,
      user.lsAccountId,
    );
  }

  // Subscribes to all of the user's related topics
  private async buildSubscriptions(previousClientId: string) {
    this.clientId = previousClientId;
    const topics = MyLSIoTTopics.map((topic) => topic(previousClientId));
    for (const topic of topics) {
      this.connection.subscribe(topic, mqtt.QoS.AtLeastOnce, this.topicCallback.bind(this));
    }
    // System notifications
    const systemTopics = Object.values(IoTSystemMessagesTopics);
    for (const systemTopic of systemTopics) {
      this.connection.subscribe(systemTopic, mqtt.QoS.AtLeastOnce, this.systemMessages.bind(this));
    }
  }

  // We need to unsubscribe before calling disconnect
  // Otherwise we can enter a bad connection state (interrupt or error).
  // This is because we are using configBuilder.with_clean_session set to false
  // This causes us to use the previous disconnected state which may no longer be valid for these topics.
  private async unsubscribeTopics() {
    const topics = MyLSIoTTopics.map((topic) => topic(this.clientId));
    for (const topic of topics) {
      this.connection.unsubscribe(topic);
    }
    const systemTopics = Object.values(IoTSystemMessagesTopics);
    for (const systemTopic of systemTopics) {
      this.connection.unsubscribe(systemTopic);
    }
    this.connection.disconnect();
  }

  // Parse received system messages
  // Refresh the connection on IoT salt rotation
  private async systemMessages(_topic: string, payload: ArrayBuffer) {
    const decoder = new (window as any).TextDecoder('utf-8');
    const topicDecode = decoder.decode(payload);
    // Case when we updated the IOTSalt on the backend
    if (topicDecode === IotSystemMessages.UpdateIotSalt) {
      await this.unsubscribeTopics();
      this.store.dispatch(notificationsActions.loadNotifications()); // Reload the iotAuthorizer
    }
  }

  // Will save the received push notifications in NGRX notifications array. From there, it will be displayed to the ui.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private async topicCallback(_topic: string, payload: ArrayBuffer, _dup: boolean, _qos: mqtt.QoS, _retain: boolean) {
    const decoder = new (window as any).TextDecoder('utf-8');
    const json = decoder.decode(payload);
    const message: Notification = JSON.parse(json);
    // new message: set date and status
    message.date = new Date();
    message.status = NotificationStatus.unread;
    // give the message a unique id
    message.id = crypto.randomUUID();
    message.showToast = message.isFinalJobMessage;
    // save locally
    const newMessages = this.addNotification(message);

    this.store.dispatch(notificationsActions.setNotifications({ notifications: newMessages }));
  }
}
