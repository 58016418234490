import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ValidationResult } from '../../SharedCustomTypes/validation-result';

@Component({
  selector: '../abstract/editable-field-do-not-use',
  template: 'do not use',
})
export abstract class EditableFieldComponent {
  /**
   * Determines if the field's value is a blank in an optional field, returns an error message otherwise.
   * @param editableField The value within the field
   * @param isRequired  Whether the field is required to be filled out
   */
  public static IsEmptyOption(editableField: string, isRequired: boolean): ValidationResult {
    const isBlank = !editableField;

    if (isRequired) {
      if (isBlank) {
        return new ValidationResult(false, 'Required');
      }
      return new ValidationResult(false, 'Content is not blank');
    }

    if (isBlank) {
      return ValidationResult.Success;
    }
    return new ValidationResult(false, 'Content is not blank');
  }

  /**
   * Determines if the required field is filled out. If not required then passes always.
   * @param editableField The value within the field
   * @param isRequired  Whether the field is required to be filled out
   */
  public static IsFilledOut(editableField: string, isRequiredField: boolean): ValidationResult {
    const isEmpty = !editableField;
    const isNotFilledOut = isRequiredField && isEmpty;
    const errorMessage = isNotFilledOut ? 'Required' : null;

    const isFilledOut = !isNotFilledOut;
    return new ValidationResult(isFilledOut, errorMessage);
  }

  /**
   * Determines if the value is less than or equal to the specified field length. If no length specified, then passes.
   * @param editableField The value within the field
   * @param isRequired  Whether the field is required to be filled out
   */
  public static IsWithinFieldLength(editableField: string, maxLength?: number): ValidationResult {
    // elvis operator wasn't accepted by build. Investigate
    const isRealValidation = maxLength && editableField;
    const isGreaterThanMaxLength = isRealValidation && editableField.length > maxLength;
    const isWithinFieldLength = !isGreaterThanMaxLength;
    const errorMessage = isGreaterThanMaxLength ? `Must be ${maxLength} characters or less` : null;
    return new ValidationResult(isWithinFieldLength, errorMessage);
  }

  @Output() public updateField: EventEmitter<string> = new EventEmitter<string>();
  @Input() public field: any;
  @Input() public inputType: string; // no spec because this is only used by html
  @Input() public required: boolean;
  @Input() public maxLength?: number;
  @Input() public width: string;
  @Input() public canEdit: boolean;
  @Input() public placeholder: string;
  @Input() public tooltip?: string;
  public editableField: any; // Should this be type string?
  public editing = false;
  public validationError: string;
  public abstract validate(): ValidationResult[];

  public save(): void {
    this.editableField = this.editableField.trim();
    const results = this.validate();
    if (this.IsValid(results)) {
      this.updateField.emit(this.editableField);
      this.editing = false;
      this.validationError = null;
    } else {
      this.validationError = ValidationResult.getPassOrFirstFailure(results).validationError;
    }
  }

  public edit(): void {
    this.editing = true;
    this.editableField = this.field ? this.field : '';
  }

  public cancel(): void {
    this.editing = false;
    this.validationError = null;
  }

  private IsValid(results: ValidationResult[]): boolean {
    return results.every((x) => x.success);
  }
}
