<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<h1>LegitScript CBD Certification Terms and Conditions</h1>
<p>
  This agreement (“Agreement”) is entered into by LegitScript LLC (“LegitScript”) and applicant, which seeks to receive CBD Product Certification and/or CBD Website Certification (“Applicant”). 
</p>
<p>
  By clicking “Agree,” Applicant understands and agrees to be bound by this Agreement, LegitScript’s CBD Product and/or Website Certification Standards (“Standards” or “Certification Standards”), and any LegitScript policy such as LegitScript’s Applicant Certification FAQs. Failure to provide payment or abide by these terms and conditions will result in LegitScript declining to approve or revoking any previous approval of Applicant. Applicant understands and acknowledges that applying for LegitScript certification is a voluntary process, and that CBD Product Certification and CBD Website Certification are separate, discrete certifications and are not in any way interchangeable. Applicant agrees to be bound by LegitScript’s interpretation of the Standards and pricing categories, and, if certified, agrees to remain in compliance with the Standards and not pose a reputational risk to LegitScript, as determined by LegitScript in its sole discretion, for the duration of the certification period.  
</p>
<h2>General Requirements</h2>
<ol class="roman">
  <li>
    <label>Compliance with Applicable Laws.</label> Applicant agrees to advertise, offer, manufacture, and distribute its products and services in compliance with all applicable laws, rules, and regulations (“Applicable Laws”). Applicable Laws shall include the laws, rules, and regulations of any jurisdiction where Applicant is domiciled and any jurisdiction where the Applicant offers to do business, including, but not limited to, the distribution of products or provision of services to customers and where any existing or prospective customer is located. LegitScript reserves the right to decline to approve or revoke any previous approval of Applicant for failure to abide by all Applicable Laws.
  </li>  
  <li>
    <label>Accuracy of Information.</label>
    <ol class="alpha">
      <li>Applicant represents and warrants that all information provided to LegitScript by Applicant is and will be truthful, accurate, and not misleading. Failure to provide full and accurate answers, (inclusive of all past and present communication with LegitScript whether related to a certification application or otherwise) as determined in LegitScript’s discretion, may result in a permanent denial or revocation of application.</li>
      <li>Applicant represents and warrants that all information provided to the public on its website or websites is and will be truthful, accurate, and not misleading.</li>
      <li>Applicant will fully disclose affiliates in the application where requested. “Affiliates” is defined as any individual, business, or entity who previously, currently, or is expected to have a commercial and/or professional relationship with the applicant organization or its principals. The failure to disclose all Affiliates may result in denial or revocation of the application.</li>
    </ol>
  </li>
  <li>
    <label>Intellectual Property Rights.</label> Applicant represents and warrants that none of its information or business practices infringe any copyright, patent, trade secret, trademark, or other intellectual property right.  Applicant agrees not to use any LegitScript intellectual property, including but not limited to LegitScript’s logo and Seal (as defined herein), for any purpose except as expressly permitted by this Agreement.
  </li>
  <li>
    <label>License.</label> Applicant represents, warrants, and agrees as follows:
    <ol class="alpha">
      <li>Applicant holds all necessary and valid license(s) from each jurisdiction or regulatory authority required in order to comply with Applicable Laws. Applicant is in good standing to conduct business pursuant to the terms of any necessary license(s), and will maintain and conduct its business in accordance with the requirements of its license(s) in all relevant jurisdictions.</li>
      <li>Applicant is in compliance with all Applicable Laws regarding provision of its services and distribution of its products. </li>
      <li>Applicant agrees to notify LegitScript within thirty (30) days of becoming aware of any change in status to its licensure, business, registration, or regulatory status in any jurisdiction), including whether these statuses are in good standing. </li>
      <li>Applicant agrees to notify LegitScript within thirty (30) days of becoming aware that it is the subject of any adverse or potentially adverse legal (whether civil or criminal), ethical, government, or regulatory action related to its license(s), business or ethical practice (“Disciplinary Action”). Disciplinary Action shall be interpreted broadly and includes, but is not limited to: lawsuits, suspensions, probationary statuses, reprimands, warning letters, consent agreements, and any communication from any other regulatory agency or organization, regardless of jurisdiction. </li>
      <li>If applicable, Applicant will, at all times, employ or contract with licensed professionals as required by Applicable Laws for the provision of its services. Applicant will permit regulated activities to be conducted only by those individuals licensed to do so, and then only to the extent permitted or required by Applicable Laws.</li>
    </ol>
  </li>
  <li>
    <label>Manufacturing or Selling CBD Products.</label> Applicant represents, warrants, and agrees that:
    <ol class="alpha">
      <li>Applicant’s CBD product ingredients are manufactured or sourced in compliance with all cannabis cultivation and processing requirements, or are otherwise exempt from such requirements.</li>
      <li>Applicant complies with all LegitScript CBD Product or Website Certification Standards (as applicable) and that LegitScript’s interpretation of the Certification Standards and all certification decisions are final.</li>
      <li>Applicant will respond to all requests for information from LegitScript during the application process. Failure to do so timely, fully, and transparently (in LegitScript’s sole discretion) will be grounds for certification denial.</li>
    </ol>
  </li>
  <li>
    <label>Website Control.</label>
    <ol class="alpha">
      <li>If applying for CBD Website certification, Applicant agrees to provide a full listing, to LegitScript, of all domain names, URLs, or websites (“Website(s)”) under its ownership or control.</li>
      <li>If applying for CBD Website certification, Applicant agrees that all Websites under its ownership or control will abide by the standards of this Agreement.</li>
      <li>All transactions processed through any Website shall conform to Applicable Laws, and, as applicable, to any requirements imposed by any payment provider or card brand used or offered by Applicant, including but not limited to Visa, MasterCard, American Express, DiscoverCard, PayPal, or any acquiring bank or downstream payment provider.</li>
      <li>If applying for CBD Website certification, Applicant agrees that it will not link or otherwise refer Internet users to CBD merchant or advertiser websites that are not approved by LegitScript and that any certified CBD Websites will sell CBD products that are LegitScript-certified.</li>
      <li>If applicable, Applicant agrees that any website from which it accepts or facilitates prescriptions or dispenses drugs will allow patients to access the contact information for the actual dispensing pharmacy, including physical address, telephone number, and facsimile number. Applicant agrees to provide this same contact information to LegitScript. Applicant understands and agrees that LegitScript may disclose this information at its own discretion.</li>
      <li>Applicant agrees to clearly display its privacy policy on a page that Internet users can easily view and access. Applicant agrees that its privacy policy will be compliant with relevant laws and regulations.</li>
      <li>Applicant agrees that any information about transactions, customers, patients, medical information, and, as required by Applicable Law, any other sensitive or confidential information is processed using Secure Socket Layer (SSL) technology.</li>
    </ol>
  </li>
  <li>
    <label>Domain Name Registration.</label>
    <ol class="alpha">
      <li>If requested, Applicant will ensure that the domain name registration (Whois) details for all domain names under applicant’s control are accurate and that the registration does not use any privacy service (also known as a proxy service). </li>
      <li>Applicant consents to LegitScript obtaining, updating and maintaining domain name registration information for any website under Applicant’s control, and that such constitutes a “legitimate purpose” for accessing such records under the EU General Data Protection Regulation or similar privacy laws. </li>
      <li>If required, applicant agrees to contact their domain name registrar to request that LegitScript be able to query the applicant’s Whois records via Port 43 or such other bulk, highspeed or automated process. </li>
    </ol>
  </li>
  <li>
    <label>License Agreement</label>
    <ol class="alpha">
      <li>Subject to the terms of this Agreement and only after receiving the express written permission of LegitScript, LegitScript grants Applicant a non-exclusive, limited license to display the LegitScript Seal of Approval (“Seal” or “Approval”) on Applicant’s home page (for CBD Websites) or individual products (for CBD Product certification). At no time will LegitScript be liable to Applicant for any damages resulting from the functioning, or malfunctions, of the Seal.</li>
      <li>LegitScript and Applicant agree that use of the Approval is subject to the conditions of this Agreement, Applicant’s successful completion of LegitScript’s certification process, and satisfactory ongoing monitoring. Applicant understands that LegitScript will charge Applicant a nonrefundable application fee prior to beginning the certification process. Additionally, LegitScript will charge Applicant an annual fee for use of the Approval, as described in LegitScript’s Certification Pricing. All fees must be paid prior to the use of the Approval. <strong>Application and annual fees are not refundable under any circumstances.</strong> For avoidance of doubt, if LegitScript determines for any reason that Applicant is no longer fit to utilize the Approval, LegitScript may terminate this Approval or Agreement and collect, retain, or refund any fees in its sole discretion and without recourse.</li>
      <li>LegitScript may at its sole discretion modify its pricing terms.</li>
      <li>Applicant agrees that the Approval is the intellectual property of LegitScript and agrees not to register the Approval or any other LegitScript logo or symbol in any jurisdiction. Applicant agrees to use LegitScript’s symbol, logo, or the Seal only as expressly permitted by this Agreement. Applicant agrees that LegitScript is the sole and exclusive owner of the Approval and of any goodwill resulting from use of the Approval. However, if certified as a CBD Website, Applicant is allowed to advertise itself as a “LegitScript-certified website,” and certified CBD Products may be advertised as “LegitScript Certified.” Applicant agrees to conform to LegitScript’s media guidelines and to respond promptly to LegitScript’s requests to correct or remove incorrect or violative information. Failure to respond to such requests from LegitScript will be grounds for certification revocation or denial.</li>
      <li>Use of the Approval constitutes an action indicating acceptance of the terms of this Agreement, and by use of the Approval, Applicant agrees to be bound by the Standards and any other LegitScript policy. Applicant may only use the Approval as long as Applicant remains certified by LegitScript, fulfills its payment obligations, promptly responds to and acts on communications from LegitScript regarding ongoing compliance obligations, and conducts business in accordance with the Standards and the terms set forth in this Agreement. Applicant further agrees not to use or display the Approval in any manner that is misleading, defamatory, infringing, libelous, disparaging, obscene, or otherwise objectionable to LegitScript or in a manner that impairs, or negatively impacts, any and all rights of LegitScript, including, but not limited to, rights in LegitScript’s trademarks or logos.</li>
      <li>Applicant agrees not to permit the use of the Approval on any product or website other than a product or website explicitly authorized by LegitScript.</li>
      <li>Applicant agrees that display of the Approval does not imply sponsorship or endorsement by LegitScript of Applicant’s products or services. The Approval will not be used or displayed in a manner suggesting such sponsorship or endorsement.</li>
      <li>Applicant agrees not to modify the Approval in any form including its size, and to automatically refresh images of the Seal in a timely manner. For avoidance of doubt, use of the Seal in a static form is not permitted on the Applicant’s website(s).</li>
      <li>Applicant recognizes that the Seal is an HTML-based or JAVA based, trademarked (by LegitScript) symbol that contains information unique to Applicant, such as the domain name or URL approved by LegitScript. When clicked or double-clicked, the Seal initiates a hyperlink that opens a page within LegitScript.com containing information about Applicant, including its certification status with LegitScript.</li>
      <li>Applicant agrees that the Seal will only hyperlink to a page within legitscript.com identified by LegitScript, and may not link to any other website.</li>
      <li>Applicant understands that LegitScript reserves the right to terminate or modify Applicant’s license to use the Approval at any time for any reason, and to take action against any use of the Approval that does not conform to the terms and conditions set forth in this Agreement, or infringes any LegitScript intellectual property right or interest.</li>
    </ol>
  </li>
  <li>
    <label>Application Process.</label>
    <ol class="alpha">
      <li>LegitScript agrees to make best efforts to review Applicant’s application in a timely manner. Applicant agrees that LegitScript may revoke or deny certification should applicant fail to timely respond to LegitScript’s requests. Applicant understands that the certification process is complex and that the time for a certification review is dependent upon a variety of factors, including but not limited to applicant’s business practices, offering services or products in multiple jurisdictions, and times of high application volumes. Applicant understands and agrees that Applicant’s compliance with the Standards is required for certification and that the determination of such compliance is in and at LegitScript’s sole discretion.</li>
      <li>Applicant understands that LegitScript may reject its certification application for any reason. If Applicant chooses to appeal the rejection, Applicant agrees that appeals will take additional time and that it will bear additional costs, if any, related to the continued process of certification. Applicant understands that LegitScript is under no obligation to accept an appeal or future applications from Applicants whose certification has been denied or revoked. </li>
      <li>Applicant understands that Applicant must pay a nonrefundable application fee in full prior to LegitScript beginning certification review of Applicant.  </li>
      <li>Applicant agrees to provide information to LegitScript and, as applicable for CBD Product certification, any third party as required for application processing listed in the application form. For clarification, Applicant agrees to submit any products for which it seeks CBD Product certification to a laboratory previously approved by LegitScript. In the event of an inconclusive or failed laboratory test or for certain products, including but not limited to vaping products, if eligible, LegitScript may, in its sole discretion, require additional testing of Applicant’s products. Such testing will be at applicant’s sole expense. LegitScript may modify laboratory testing requirements in its sole discretion.</li>
      <li>Applicant agrees that LegitScript may disclose information about Applicant provided to LegitScript by Applicant or by other entities, unless that information is subject to an additional confidentiality agreement between the LegitScript and Applicant. For example, LegitScript may disclose evidence of illegal activity to appropriate governmental authorities or violative content to LegitScript’s corporate clients or other third parties.</li>
      <li>Applicant agrees that LegitScript is authorized to make inquiries about, research, collect, and store information from various sources about Applicant, its products, business practices, directors, employees, agents, and contractors.</li>
      <li>Applicants that have been subject to Disciplinary Action, pose a reputational risk to LegitScript or have otherwise been deceptive, uncooperative, noncompliant, or not transparent (all determined in LegitScript’s sole discretion), may be awarded probationary certification. Applicant understands that LegitScript will award probationary certification in its sole discretion and that resolution of Disciplinary Actions alone does not guarantee that probationary certification will be awarded. Applicant understands that LegitScript may require additional information from Applicant in order to ensure ongoing compliance with LegitScript certification requirements and that heightened and more frequent LegitScript review of Applicant may be required. Applicant understands that LegitScript may revoke probationary certification status at any time and for any reason. Applicant understands that probationary certification pricing will be determined on a case-by-case basis.</li>
      <li>Applicant understands that LegitScript may, in its sole discretion, grant, deny, or revoke Applicant’s certification application or certification status for any reason and at any time, including, but not limited to, if LegitScript determines that the quality, accuracy, or other characteristics of the Applicant do not meet LegitScript’s certification standards, including but not limited to Applicant’s violations of BRAM, GBPP, or other card company policies.</li>
      <li>LegitScript may modify or add additional aspects of review and approval at its discretion to satisfy the needs of its partners’ CBD policies, such as abbreviated reviews of landing pages or product labels. Applicant understands and agrees that such additional reviews may be necessary to access LegitScript’s partners’ services and the details of such services may differ slightly from LegitScript’s CBD Product and CBD Website certification. However, in general, approval under such reviews will require that Applicant comply with Applicable Laws and these Terms and Conditions.</li>
      <li>Applicant understands that the regulation of hemp, cannabis, and CBD is evolving. Applicant agrees, if regulations change that remove Applicant’s certified product(s) or websites from LegitScript certification eligibility, to bring any such products or websites into compliance. Failure to do so will result in certification revocation.</li>
      <li>Applicant understands that any communications from LegitScript are not legal advice or legal opinions and may not reflect the most current legal and regulatory developments. As a result, any communications from LegitScript or information on any LegitScript websites are not to be used as a definitive statement of law or regulatory guidance. Any communications from LegitScript or information on any LegitScript websites are not to be relied upon or construed as a legal opinion or legal advice regarding any specific issue or factual circumstance. Always consult qualified legal counsel to ensure compliance with applicable laws and regulations.</li>
      <li>Applicant understands that, from time to time, LegitScript may update the Standards and these Terms and Conditions. Please check these Terms and Conditions periodically for updates.</li>
      <li>LegitScript expressly reserves the right to refuse to consider any application. </li>
    </ol>
  </li>
  <li>
    <label>General Use of LegitScript Materials.</label> Applicant agrees that the materials, content, methodology, and concepts of the process and in LegitScript’s online materials are owned by LegitScript and are protected intellectual property. Applicant agrees not to reproduce, modify, distribute, reverse engineer, or publicly display such materials. <u>NOTE:</u> LEGITSCRIPT, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND SUPPLIERS PROVIDE LEGITSCRIPT’S WEBSITE AND SERVICES "AS IS" AND “AS AVAILABLE” WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED, OR STATUTORY. LEGITSCRIPT, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND SUPPLIERS SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY AND NON-INFRINGEMENT. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LEGITSCRIPT SHALL CREATE A WARRANTY. APPLICANT AGREES THAT COMMUNICATIONS WITH AND INFORMATION RECEIVED FROM LEGITSCRIPT DO NOT CONSTITUTE LEGAL OR REGULATORY ADVICE. APPLICANT IS SOLEY RESPONSIBLE FOR COMPLYING WITH ALL APPLICABLE LAWS AND REGULATIONS. LEGITSCRIPT IS NOT RESPONSIBLE IN CONNECTION WITH APPLICANT’S USE OF LEGITSCRIPT’S WEBSITE, SERVICES, OR THE APPROVAL.
  </li>
  <li>
    TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL LEGITSCRIPT, ITS OFFICERS, AGENTS, AND EMPLOYEES BE LIABLE TO APPLICANT, FOR ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, (INCLUDING ANY LOST OPPORTUNITIES FOR ADVERTISING, OR OTHERWISE, AND INCLUDING WITHOUT LIMITATION LOST PROFITS, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES) EVEN IF LEGITSCRIPT HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL LEGITSCRIPT’S TOTAL LIABILITY FOR ALL CLAIMS ARISING FROM OR RELATING TO THIS AGREEMENT, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE GREATER OF THE FEES PAID BY APPLICANT TO LEGITSCRIPT UNDER THIS AGREEMENT DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT WHICH GAVE RISE TO THE CLAIM FOR DAMAGES, OR $1,000. THE LIMITATIONS SET FORTH IN THIS SECTION SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ANY ESSENTIAL PURPOSE OF ANY LIMITED REMEDY SET FORTH IN THIS AGREEMENT.
  </li>
  <li>
    <label>Waiver and Indemnification.</label> Applicant agrees to indemnify, defend, and hold LegitScript, and as applicable its officers, directors, agents, employees, affiliates, suppliers, and partners, harmless from and against any claims, demands, causes of action, damages, costs, liabilities, judgments, penalties, and expenses (including but not limited to, reasonable attorneys fees and court costs), collectively “Action(s),” arising from this Agreement, the certification process, of or relating to Applicant’s use of the Approval or other services provided by LegitScript (including, without limitation, any claims arising from any representations Applicant makes about the Approval) or Applicant’s breach of this Agreement or the documents it incorporates by reference, or Applicant’s violation of any law or the rights of a third party, or any action taken by LegitScript as part of its review of Applicant’s certification application or investigation of a suspected violation of this Agreement (or as a result of its finding or decision that a violation of this Agreement has occurred). Applicant’s defense and indemnification obligations shall survive the expiration or termination of this Agreement and shall apply regardless of Applicant’s certification status.
    <p>Applicant agrees that it shall not sue or recover any damages from LegitScript, its directors, officers, employees, affiliates, agents, contractors, and licensors as a result of LegitScript’s decision to not certify, revoke certification, to suspend or terminate its relationship with Applicant, or that Applicant has breached or otherwise violated the Standards or this Agreement.</p>
    <p>Applicant understands and agrees that LegitScript does not control other entities which may recognize or require LegitScript’s CBD Certification Program. Applicant understands and agrees that LegitScript is not responsible for the actions of any such entity.</p>
    <p>Applicant understands and agrees that LegitScript certification, the Seal, or Approval may provide Applicant with access to third party products or services. The Approval may also provide Applicant with access to advertisements or payment accounts from third party businesses. Applicant understands and agrees that LegitScript is not responsible for any advertising or payment account decisions or revocations or any other services relating to any third party products, services, advertisements or other materials. In no event will LegitScript be liable, directly or indirectly, to Applicant for any damage or loss relating to any use of or reliance on the Approval or LegitScript’s CBD certification program. Applicant understands and agrees that LegitScript certification does not make available access to or discounts for these third party services does not constitute provision of such third party services by LegitScript, and Applicant will look solely to the applicable third party for provision of the applicable third party services and for compensation for any claims, damages, liabilities or losses Applicant may incur in connection with such third party services.</p>
    <p>This waiver and indemnity provision applies to all violations described in or contemplated by this Agreement.</p>
    <ol class="alpha">
      <li>Indemnification Procedure. LegitScript shall promptly notify Applicant of any Action for which LegitScript believes it is entitled to be indemnified. LegitScript shall cooperate with Applicant at Applicant's sole cost and expense. Applicant shall immediately take control of the defense and investigation of such Action and shall employ counsel acceptable to LegitScript to handle and defend the same, at Applicant’s sole cost and expense. LegitScript may participate in and observe the proceedings at its own cost and expense with counsel of its own choosing. Applicant shall not enter into any monetary or non-monetary settlement or admit fault or liability on LegitScript’s behalf without the prior written consent of LegitScript.</li>
    </ol>
  </li>
  <li>
    <label>Effective Date.</label> The effective date of this Agreement is the date on which Applicant affirmatively agrees to these Terms and Conditions by completing and submitting the application. The Agreement will terminate when either party provides notice to the other of termination, or upon conduct by Applicant that violates the terms of this Agreement, whichever is earlier.
  </li>
  <li>
    <label><strong>Governing Law; Jurisdiction; Attorney Fees.</strong></label> This Agreement and its interpretation and enforcement will be governed by the laws of the State of Delaware, without regard to its conflict of laws rules and without regard to any international treaties or conventions, including the Convention on the International Sale of Goods. The Applicant irrevocably and unconditionally (i) agrees that any action or proceeding arising out of or in connection with this Agreement shall be brought only in the Chancery Court of the State of Delaware (“Delaware Chancery Court”), and not in any other state or federal court in the United States of America or any court in any other country or territory, (ii) consents to submit to the exclusive jurisdiction of the Delaware Chancery Court for purposes of any action or proceeding arising out of or in connection with this Agreement, (iii) waives any objection to venue of any such action or proceeding in the Delaware Chancery Court, and (iv) waives, and agrees not to plead or to make, any claim that any such action or proceeding brought in the Delaware Chancery Court has been brought in an improper or inconvenient forum, including but not limited to for reasons of lack of personal jurisdiction or unconscionability.
  </li>
  <p>If any action is instituted to enforce or interpret the terms of this Agreement, the prevailing party in such action will be entitled to recover its reasonable attorney fees and costs incurred in such action, at trial or on appeal, from the losing party.</p>
  <li>
    <label>Miscellaneous.</label>
    <ol class="alpha">
      <li><label>Notices.</label> Any and all notices to LegitScript must be made in writing and either mailed by first class postage to LegitScript at 818 SW 3rd Ave. #353, Portland, Oregon 97204 or to such other address as LegitScript may hereinafter designate, or via electronic mail to <u>legal@legitscript.com.</u> Such notices will be deemed delivered when received.</li>
      <li><label>Relationship.</label> Applicant agrees that Applicant and LegitScript are independent parties and neither party is the legal representative, agent, joint venture, partner, or employee of the other party for any purpose whatsoever. Applicant grants LegitScript the right to take any and all steps LegitScript believes are reasonably necessary or appropriate to enforce or verify compliance with any part of this Agreement.</li>
      <li><label>Force Majeure.</label> LegitScript shall not be liable to Applicant for its failure to perform under this Agreement to the extent that any such failure results from any cause beyond LegitScript’s reasonable control, including without limitation, acts of God, strike or other labor dispute, electrical or power outage, pandemic or widespread disease, the acts or omissions of a third party, earthquake, or weather.</li>
      <p>This Agreement is binding upon and shall inure to the benefit of LegitScript and Applicant and our respective successors and assigns. The failure of either party to enforce any of the provisions of this Agreement shall not be construed as a waiver of that provision or the right of the party to enforce that provision or any other provision. In the event that any provision of this Agreement is found to be unenforceable or invalid the parties agree that that provision will be changed and interpreted so as to best accomplish the objectives of the provision within the limits of applicable law or applicable court decisions. This Agreement constitutes the entire agreement between Applicant and LegitScript and supersedes any prior agreements with respect to the same subject matter between Applicant and LegitScript.</p>
      <li><label>Electronic submittal.</label> Applicant acknowledges and agrees that electronic submission of Applicant’s application together with this Agreement constitutes a binding agreement on Applicant.</li>
    </ol>
  </li>
  <li>
    <label>Consent Clause.</label> Applicant grants explicit consent and permission to LegitScript to collect, store, process, and use all data supplied by the Applicant to LegitScript for business purposes including (i) for the purpose of managing Applicant’s application for LegitScript certification (“Personal Data”) and business interactions with LegitScript, (ii) to carry out the provision of services contemplated under this Agreement and other agreements between LegitScript and any Affiliates and (iii) to carry out management of LegitScript’s businesses, including, but not limited to, financial and operational management, improvement, and reporting, risk management, legal and regulatory compliance, and client service management. Applicant understands Personal Data may be transferred to any third parties assisting in the implementation, administration and management of LegitScript’s certification program, including those third parties recognizing and requiring LegitScript certification, now or in the future, that these recipients may be located in Applicant’s country or elsewhere, and that the recipient’s country may have different data privacy laws and protections than Applicant’s country. Applicant authorizes the recipients to receive, possess, use, retain and transfer the Personal Data, in electronic or other form, for the purposes of implementing, administering and managing Applicant’s business relationship with LegitScript.
  </li> 
</ol>
