import { Injectable } from '@angular/core';
import { Auth0Handler, LocalStorageService } from '@ls/common-ng-components';
import { AuthenticatedUser, AuthenticationInfo, Roles } from '@ls/common-ts-models';
import { Store } from '@ngrx/store';
import { AccountLoadAction, AppState, UserAuthenticateSuccessAction } from '../reducers';
import { Router } from '@angular/router';

@Injectable()
export class Auth0HandlerService implements Auth0Handler {
  constructor(
    private localStorage: LocalStorageService,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public onSuccess(auth: AuthenticationInfo): void {
    this.localStorage.setTokenRaw(auth.jwt);
    this.localStorage.setAuthenticatedUser(auth.user);

    if (this.isAccessAllowed(auth.user)) {
      this.store.dispatch(UserAuthenticateSuccessAction({ user: auth.user, roles: auth.user.roles }));
      this.store.dispatch(AccountLoadAction());
    } else {
      this.router.navigate(['/access-denied']);
    }
  }

  public isAccessAllowed(user: AuthenticatedUser): boolean {
    return [this.hasAccountId, this.hasUserId, this.hasMyLSRole].every((test) => test(user));
  }

  public hasAccountId(user: AuthenticatedUser): boolean {
    return user && !!user.lsAccountId;
  }

  public hasUserId(user: AuthenticatedUser): boolean {
    return user && !!user.lsUserId;
  }

  public hasMyLSRole(user: AuthenticatedUser): boolean {
    return user && !!user.roles && user.roles.some((role) => [Roles.cpv2AccountOwner, Roles.cpv2User].includes(role));
  }
}
