import { LocalStorageService } from '@ls/common-ng-components';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class PortalService {
  private readonly portalApiHost = environment.CONFIG.portalApiHost;
  constructor(private http: HttpClient, private localStorageSvc: LocalStorageService) {}

  public refreshApiKey(productId: string) {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<{ value: string }>(
      `${this.portalApiHost}/v1/account/api-key/refresh`,
      { productId },
      options
    );
  }
}
