import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Roles } from '@ls/common-ts-models';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { GenericNotificationAction, LocalStorageService, SeverityOptions } from '@ls/common-ng-components';

@Injectable()
export class OwnersOnlyGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private store: Store<AppState>,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const user = this.localStorage.getAuthenticatedUser();

    if (user && user.roles && user.roles.includes(Roles.cpv2AccountOwner)) {
      return true;
    }

    // // tried to access a route for owners only, show notice and redirect home
    this.store.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.WARN,
        summary: 'Not Authorized',
        detail: 'The page you attempted to access is for account owners only.',
        sticky: false,
        blocking: false,
      }),
    );
    this.router.navigate(['/account/home']);
    return false;
  }
}
