<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="login-container">
    <login-header></login-header>
    <div class="entry-main">
        <div class="entry-container entry-container-small">
            <i class="fa fa-exclamation-triangle icon-lg"></i>
            <h1 class="margin-vertical-md">404: Page Not Found</h1>
            <div class="subtext margin-vertical-md">
                Sorry! That page doesn't seem to exist.
            </div>
            <div class="icons-flex">
                <a (click)="goBack()" class="from-left">
                    <i class="fa fa-arrow-circle-left"></i>
                    <span>Back</span>
                </a>
                <a routerLink="/" class="from-bottom">
                    <i class="fa fa-home"></i>
                    <span>Home</span>
                </a>
                <a href="https://help.legitscript.com" target="_blank" class="from-right">
                    <i class="fa fa-life-ring"></i>
                    <span>Support</span>
                </a>
            </div>
        </div>
    </div>
</div>