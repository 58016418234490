import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductFamily } from '@ls/common-ts-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState, fnEntitlementsState } from 'src/app/reducers';

@Component({
  selector: 'website-lookups-search',
  styleUrls: ['../../styles/search.scss'],
  templateUrl: './website-lookup-search.html',
})
export class WebsiteLookupsSearchComponent implements OnInit {
  public website = '';
  public processing = false;
  public searchTerm = '';
  public entitlements$: Observable<any>;
  public productFamily = ProductFamily.WEBSITE_LOOKUP;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public store: Store<AppState>,
  ) {}

  public ngOnInit() {
    this.entitlements$ = this.store.select(fnEntitlementsState);

    // if hard refresh but there was a previous search, load the term into the form
    this.searchTerm = this.route.snapshot.queryParams['website'];
    if (this.searchTerm) {
      this.website = decodeURIComponent(this.searchTerm);
    }
  }

  public submit(formRef: NgForm) {
    if (formRef.form.valid) {
      this.router.navigate(['info'], {
        relativeTo: this.route,
        queryParams: { website: formRef.form.value.website },
      });
    }
  }

  public clearSearch(formRef: NgForm) {
    if (formRef) {
      formRef.reset();
    }
    this.router.navigate(['./'], { relativeTo: this.route });
  }
}
