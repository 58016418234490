import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AUTH0_HANDLER, Auth0Service, LocalStorageService } from '@ls/common-ng-components';
import { Auth0HandlerService } from '../services';
import { Store } from '@ngrx/store';
import { AppState, UserSignOutAction } from '../reducers';
import { Roles, isForLogout, inMyLS, firstECTry } from '@ls/common-ts-models';
import { environment } from 'src/environments/environment';

import { DataLayerService } from 'src/app/services/data-layer.service';
@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private auth0: Auth0Service,
    private localStorage: LocalStorageService,
    private JwtHelper: JwtHelperService,
    @Inject(AUTH0_HANDLER) private auth0Handler: Auth0HandlerService,
    private store: Store<AppState>,
    private _dataLayerService: DataLayerService,
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const lsJwt = this.localStorage.getTokenRaw();

    // add `?auth0=true` to any route that uses this guard to trigger
    if (this.localStorage.setAuth0Enabled(route)) {
      return await this.auth0Auth(lsJwt, state);
    } else {
      return this.standardAuth(lsJwt, state);
    }
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    return this.canActivate(route, state);
  }

  public standardAuth(lsJwt: string, state: RouterStateSnapshot) {
    if (lsJwt == null) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    if (!this.JwtHelper.isTokenExpired(lsJwt)) {
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  public async auth0Auth(lsJwt: string, state: RouterStateSnapshot) {
    const user = this.localStorage.getAuthenticatedUser();
    const accessAllowed = this.auth0Handler.isAccessAllowed(user);
    if (user && !accessAllowed) {
      if (user.roles && user.roles.includes(Roles.cpv2AccountOwnerSF)) {
        // If the isForLogout property is true, that means that the user is coming from
        // Experience Cloud, since they don't have permissions to go to MyLS, we simply
        // take them out of the session
        if (localStorage.getItem(isForLogout) === 'true') {
          localStorage.removeItem(isForLogout);
          this.store.dispatch(UserSignOutAction({ location: '/login' }));
          return false;
        } else {
          // If the above is FALSE, that means that this is the first time the user arrives to this guard
          // on that case, we're setting the isForLogout property to true, so the next time the same user
          // comes to MyLS, he can be removed from the session because it should not have access to MyLS
          localStorage.setItem(isForLogout, 'true');
          window.location.href = environment.CONFIG.experienceCloudURL;
          return false;
        }
      }
      this.router.navigate(['/access-denied']);
      return false;
    }

    // If the localstorage properties inMyLS & firstECTry are not present, that means this is the
    // first time the user tries to access, from here we're valdiating if the user
    // has Salesforce role, if it does, we're setting the properties and we're redireting them
    // to Salesforce Experience Cloud
    if (user && accessAllowed) {
      if (!localStorage.getItem(inMyLS) || !localStorage.getItem(firstECTry)) {
        if (user.roles && user.roles.includes(Roles.cpv2AccountOwnerSF)) {
          localStorage.setItem(inMyLS, 'false');
          localStorage.setItem(firstECTry, 'true');
          window.location.href = environment.CONFIG.experienceCloudURL;
          return false;
        }
      } else {
        localStorage.setItem(inMyLS, 'true');
      }
    }

    if (lsJwt && !this.JwtHelper.isTokenExpired(lsJwt)) {
      return true;
    }

    try {
      await this.auth0.refresh();
      return true;
    } catch (e) {
      this.localStorage.clearLocalStorage();
      await this.auth0.login(state);
      return false;
    }
  }
}
