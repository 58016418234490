import { of as observableOf } from 'rxjs';

import { map, skip, mergeMap, takeUntil, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AuthenticatedUser } from '@ls/common-ts-models';

import {
  AppState,
  UserAuthenticateAction,
  UserAuthenticateErrorAction,
  UserAuthenticateSuccessAction,
  UserConfirmAction,
  UserConfirmErrorAction,
  UserConfirmSuccessAction,
} from '../reducers';

import { AccountService, AuthenticationService } from '../services';

@Injectable()
export class UserEffect {
  public authenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthenticateAction),
      map((action) => action.user),
      mergeMap((value: AuthenticatedUser) => {
        // setup for when to stop this subscription
        const nextSearch$ = this.actions$.pipe(ofType(UserAuthenticateAction), skip(1)); // new subscribers will get the previous record, so we will skip it to ensure that we are sending to correct, new search.

        return this.authenticationService.login(value.email, value['password']).pipe(
          // string-key reference is used because 'password' exists on the model but not the User interface
          takeUntil(nextSearch$),
          map((user: AuthenticatedUser) => {
            return UserAuthenticateSuccessAction({ user, roles: user.roles });
          }),
          catchError((error) => {
            let errorMsg = 'Please check your username and password.';
            try {
              errorMsg = error.error.message;
            } catch (e) {
              // just here in case there's an issue with getting the error json- backup message already set
            }

            return observableOf(UserAuthenticateErrorAction({ errorText: errorMsg }));
          }),
        );
      }),
    ),
  );

  public confirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserConfirmAction),
      map((action) => ({
        lsUserId: action.lsUserId,
        verificationToken: action.verificationToken,
      })),
      mergeMap((verificationInfo) => {
        return this.accountService.confirmAccount(verificationInfo.verificationToken).pipe(
          map((response) => {
            return UserConfirmSuccessAction({ success: response.success, name: response.name });
          }),
          catchError((error) => {
            return observableOf(UserConfirmErrorAction({ success: false, errorText: error }));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
  ) {}
}
