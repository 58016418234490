<legitscript-modal #editAccountModal [draggable]=false [resizable]=false [responsive]=true [closable]="false" [modal]=true autoAlign=true [width]=800 height=auto>
  <div class="dialog-body" style="overflow:auto; text-align: left;">
    <h1>Edit Account Details</h1>
    <form #form="ngForm" (ngSubmit)="submit()" id="accountDetailsForm">
      <div class="form-submitting-mask" *ngIf="loading">
        <p-progressSpinner [style]="{ width: '35px', height: '35px' }"></p-progressSpinner>
        <div style="margin-left: 16px;">Processing</div>
      </div>
      <div class="edit-account-form">
        <div class="display-line mb20">
          <div class="label-validator">
            <label for="companyName" class="left">COMPANY NAME*</label>
            <field-validator class="right" [control]="companyName"></field-validator>
          </div>
          <div><input #companyName="ngModel" type="text" [(ngModel)]="account.name" name="companyName" maxlength="{{MAX_ACCOUNT_NAME_LENGTH}}" required checkWhitespace></div>
        </div>
        <div class="column-two">
          <div class="mb20 mr5">
            <div class="label-validator">
              <label for="street1" class="left">Street 1</label>
              <field-validator class="right" [control]="street1"></field-validator>
            </div>
            <div><input #street1="ngModel" type="text" [(ngModel)]="account.address.street1" name="street1" maxlength="{{MAX_STREET_LENGTH}}"  required checkWhitespace></div>
          </div>
          <div class="mb20">
            <div class="label-validator">
              <label for="street2" class="left">Street 2</label>
              <field-validator class="right" [control]="street2"></field-validator>
            </div>
            <div><input #street2="ngModel" type="text" [(ngModel)]="account.address.street2" name="street2" maxlength="{{MAX_STREET_LENGTH}}"></div>
          </div>
        </div>
        <div class="column-three">
          <div class="mb20 mr5">
            <div class="label-validator">
              <label for="city" class="left">City</label>
              <field-validator class="right" [control]="city" name="city"></field-validator>
            </div>
            <div><input #city="ngModel" type="text" [(ngModel)]="account.address.city" name="city" maxlength="{{MAX_CITY_LENGTH}}" required checkWhitespace></div>
          </div>
          <div class="mb20 mr5">
            <div class="label-validator">
              <label for="state" class="left">State/Province</label>
              <field-validator class="right" [control]="state"></field-validator>
            </div>
            <div><input #state="ngModel" type="text" [(ngModel)]="account.address.province" name="state" maxlength="{{MAX_STATE_LENGTH}}" required checkWhitespace></div>
          </div>
          <div class="mb20">
            <div class="label-validator">
              <label for="postalCode" class="left">Postal Code / Zip</label>
              <field-validator class="right" [control]="postalCode"></field-validator>
            </div>
            <div><input #postalCode="ngModel" type="text" [(ngModel)]="account.address.postalCode" name="postalCode" maxlength="{{MAX_ZIP_LENGTH}}" required checkWhitespace></div>
          </div>
        </div>
        <div class="column-two">
          <div class="mb20 mr5">
            <div class="label-validator">
              <label for="country" class="left">Country</label>
              <field-validator class="right" [control]="country" name="country"></field-validator>
            </div>
            <select #country="ngModel" name="country" [(ngModel)]="account.address.countryCode" required>
              <option *ngFor="let c of countries" [ngValue]="c.value">{{c.display}}</option>
            </select>
          </div>
          <div class="mb20">
            <div class="label-validator">
              <label for="phone" class="left">COMPANY PHONE</label>
              <field-validator class="right" [control]="phone" name="phone"></field-validator>
            </div>
            <div><input type="text" #phone="ngModel" [(ngModel)]="account.phone" name="phone" maxlength="{{MAX_PHONE_LENGTH}}" required checkWhitespace></div>
          </div>
        </div>

      </div>  
    </form>
  </div>
  <div class="dialog-footer">
    <div *ngIf="form.submitted && form.invalid" class="form-group span6 validation-error-container">
        Please complete all required fields
      </div>
      <div style="text-align: center">
        <button *ngIf="!loading" class="btn btn-primary" [disabled]="form.invalid" form="accountDetailsForm">Save</button>
        <button *ngIf="!loading" class="btn" type="button" (click)="cancel()">Cancel</button>
      </div>
  </div>
</legitscript-modal>
