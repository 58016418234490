import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '@ls/common-ng-components';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class MMCustomerService {
  private mmApiHost = environment.CONFIG.mmApiHost;

  constructor(private http: HttpClient, private localStorageSvc: LocalStorageService) {}

  public getCustomer(accountServiceId: number): Observable<any> {
    return this.http.get(`${this.mmApiHost}/customer/${accountServiceId}`, this.localStorageSvc.getJWTAndSetOptions());
  }
}
