import { map, withLatestFrom, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { Router, NavigationExtras } from '@angular/router';
import {
  Answers,
  QuestionnaireActionTypes,
  QuestionnaireLoadAndSetQuestionAction,
  QuestionnaireLoadErrorAction,
  QuestionnaireLoadSuccessAction,
  LoadCompletedQuestionnaireSuccessAction,
  QuestionnaireLoadQuestionAction,
  QuestionnaireLoadQuestionErrorAction,
  QuestionnaireLoadQuestionSuccessAction,
  QuestionnaireSubmitErrorAction,
  QuestionnaireSubmitSuccessAction,
  QuestionnaireSaveErrorAction,
  QuestionnaireSaveSuccessAction,
  QuestionnaireAnswerLaterErrorAction,
  QuestionnaireAnswerLaterSuccessAction,
  QuestionnairePreviousErrorAction,
  QuestionnairePreviousSuccessAction,
  UpdateShowInstructionsAction,
  QuestionnaireLoadAction,
  QuestionnaireUploadFilesAndSaveAction,
  LoadCompletedQuestionnaireAction,
  QuestionnaireSubmitAction,
  QuestionnairePreviousAction,
  QuestionnaireAnswerLaterAction,
  QuestionnaireState,
} from '../reducers';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { Questionnaire, AnsweredQuestion, Question, QuestionTypes } from '@ls/common-ts-models';
import { CertificationService } from '../services';
import { QuestionnaireSaveAction } from '../reducers';
import { AppState, fnQuestionnaireState } from 'src/app/reducers';

interface GetQuestionnaireDataResponse {
  questionnaire: Questionnaire;
  answers: Answers;
  currentAnswer: AnsweredQuestion;
  currentQuestion: Question;
  onFirstQuestion: boolean;
  currentQuestionId: string;
  isComplete: boolean;
  isSubmitted?: boolean;
  inAnswerLater: boolean;
  errorText?: string;
}

interface GetQuestionnaireDataAndSetFirstQuestionResponse {
  questionnaire: Questionnaire;
  currentQuestionId: string;
  answers: Answers;
  currentAnswer: AnsweredQuestion;
  isComplete: boolean;
  isSubmitted?: boolean;
  inAnswerLater: boolean;
  onFirstQuestion?: boolean;
}

interface SaveQuestionResponse {
  answers: Answers;
  nextQuestionId: string;
  isComplete: boolean;
  inAnswerLater: boolean;
  errorText?: string;
}

interface PreviousQuestionResponse {
  currentQuestionId: string;
  // answers: Answers;
  nextQuestionId: string;
  inAnswerLater: boolean;
  onFirstQuestion: boolean;
}

interface AnswerLaterQuestionResponse {
  answers: Answers;
  nextQuestionId: string;
  isComplete: boolean;
  currentAnswer: AnsweredQuestion;
  inAnswerLater: boolean;
  errorText?: string;
}

interface LoadQuestionResponse {
  currentQuestion: Question;
  onFirstQuestion: boolean;
  currentAnswer: AnsweredQuestion;
}

const inputTypesWithFiles = [QuestionTypes.comboUploadText, QuestionTypes.fileUpload];

@Injectable()
export class QuestionnaireEffect {
  public questionnaireLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionnaireLoadAction),
      mergeMap((action) => {
        return this.certificationService
          .getQuestionnaireData(action.certificationId, action.currentQuestionId, action.inAnswerLater)
          .pipe(
            map((response: GetQuestionnaireDataResponse) => {
              if (response.errorText) {
                // there was no question for the id passed in.  this should only ever happen if a user edits the question id in the url directly or if they try to access a questionnaire that has already been submitted- but this will prevent an application crash in that case
                this.router.navigate(['/not-found'], { replaceUrl: true });
                return QuestionnaireLoadErrorAction({
                  errorText: response.errorText,
                });
              }

              if (action['renewing']) {
                const unansweredQuestions = [];
                const answeredQuestions = [];

                response.questionnaire.questions.forEach((question) => {
                  if (response.answers.answeredQuestions.find((aq) => aq.question_id === question.id)) {
                    answeredQuestions.push(question);
                  } else {
                    unansweredQuestions.push(question);
                  }
                });

                response.questionnaire.questions = [...answeredQuestions, ...unansweredQuestions];
              }

              return QuestionnaireLoadSuccessAction({
                questionnaire: response.questionnaire,
                currentQuestionId: response.currentQuestionId,
                answers: response.answers,
                currentAnswer: response.currentAnswer,
                answerLater: response.answers.answerLater,
                onFirstQuestion: response.onFirstQuestion,
                inAnswerLater: response.inAnswerLater,
                isComplete: response.isComplete,
              });
            }),
            catchError((err) => {
              if (err.status && err.status === 403) {
                this.router.navigate(['/services/merchant-certification'], {
                  replaceUrl: true,
                });
                this.store$.dispatch(
                  GenericNotificationAction({
                    severity: SeverityOptions.WARN,
                    summary: 'Action Not Permitted',
                    detail: err,
                    sticky: false,
                    blocking: false,
                  }),
                );
                return of(QuestionnaireLoadErrorAction({ errorText: err }));
              } else if (err.status && err.status === 401) {
                // User is trying to access questionnaire that doesn't belong to account (possibly because of changing accounts)
                this.router.navigate(['/services/merchant-certification', { replaceUrl: true }]);
                this.store$.dispatch(
                  GenericNotificationAction({
                    severity: SeverityOptions.WARN,
                    summary: 'The questionnaire you are attempting to access does not belong to this account',
                    detail: err,
                    sticky: false,
                    blocking: false,
                  }),
                );
                return of(QuestionnaireLoadErrorAction({ errorText: err }));
              } else {
                this.router.navigate(['/not-found'], { replaceUrl: true });
                return of(QuestionnaireLoadErrorAction({ errorText: err }));
              }
            }),
          );
      }),
      catchError((err) => of(QuestionnaireLoadErrorAction({ errorText: err }))),
    ),
  );

  public questionnaireLoadAndSetQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionnaireLoadAndSetQuestionAction),
      mergeMap((action) => {
        return this.certificationService.getQuestionnaireDataWithoutQuestionId(action.certificationId).pipe(
          map((response: GetQuestionnaireDataAndSetFirstQuestionResponse) => {
            if (response.isSubmitted) {
              this.router.navigate([`/questionnaire-submitted/${response.answers.certificationId}`]);
              return { type: 'complete' };
            } else if (response.isComplete) {
              const queryParams = {};
              if (action.renewing) {
                if (response.answers.showRenewalInstructions) {
                  this.store$.dispatch(UpdateShowInstructionsAction({ showInstructions: true }));
                }
                queryParams['renewing'] = 'true';
              }
              const navigationExtras: NavigationExtras = { queryParams };
              this.router.navigate([`/questionnaire-complete/${response.answers.certificationId}`], navigationExtras);
              return { type: 'complete' };
            } else {
              if (action.renewing) {
                if (response.answers.showRenewalInstructions) {
                  this.store$.dispatch(UpdateShowInstructionsAction({ showInstructions: true }));
                }
                const unansweredQuestions = [];
                const answeredQuestions = [];

                response.questionnaire.questions.forEach((question) => {
                  if (response.answers.answeredQuestions.find((aq) => aq.question_id === question.id)) {
                    answeredQuestions.push(question);
                  } else {
                    unansweredQuestions.push(question);
                  }
                });

                response.questionnaire.questions = [...answeredQuestions, ...unansweredQuestions];
              }

              return QuestionnaireLoadSuccessAction({
                questionnaire: response.questionnaire,
                currentQuestionId: response.currentQuestionId,
                answers: response.answers,
                currentAnswer: response.currentAnswer,
                answerLater: response.answers.answerLater,
                onFirstQuestion: response.onFirstQuestion,
                inAnswerLater: response.inAnswerLater,
                isComplete: response.isComplete,
              });
            }
          }),
          catchError((err) => {
            this.router.navigate(['/not-found'], { replaceUrl: true });
            return of(QuestionnaireLoadErrorAction(err));
          }),
        );
      }),
      catchError((err) => of(QuestionnaireLoadErrorAction({ errorText: err }))),
    ),
  );

  public questionnaireLoadComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCompletedQuestionnaireAction),
      withLatestFrom(this.store$.select(fnQuestionnaireState)),
      mergeMap(([action, storeState]) => {
        return this.certificationService
          .getCompletedQuestionnaireData(action['certificationId'], storeState.answers, storeState.questionnaire)
          .pipe(
            map((response: GetQuestionnaireDataAndSetFirstQuestionResponse) => {
              if (response.answers.showRenewalInstructions) {
                this.store$.dispatch(UpdateShowInstructionsAction({ showInstructions: true }));
              }
              return LoadCompletedQuestionnaireSuccessAction({
                questionnaire: response.questionnaire,
                answers: response.answers,
                isComplete: response.isComplete,
              });
            }),
            catchError((err) => {
              this.router.navigate(['/not-found'], { replaceUrl: true });
              return of(QuestionnaireLoadErrorAction({ errorText: err }));
            }),
          );
      }),
      catchError((err) => of(QuestionnaireLoadErrorAction({ errorText: err }))),
    ),
  );

  public questionnaireSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionnaireSubmitAction),
      withLatestFrom(this.store$.select(fnQuestionnaireState)),
      mergeMap(([, storeState]) => {
        return this.certificationService.questionnaireSubmit(storeState.answers.id).pipe(
          map(() => {
            return QuestionnaireSubmitSuccessAction();
          }),
          catchError((error) => {
            if (error.status && error.status === 403) {
              this.routeToListAndShowError(error.error);
            }
            return of(QuestionnaireSubmitErrorAction({ errorText: error.statusText }));
          }),
        );
      }),
      catchError((error) => of(QuestionnaireSubmitErrorAction({ errorText: error.statusText }))),
    ),
  );

  public questionnaireSubmitSuccessRouting$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuestionnaireSubmitSuccessAction),
        withLatestFrom(this.store$.select(fnQuestionnaireState)),
        map(([, storeState]: [Action, QuestionnaireState]) => {
          this.router.navigate([`/questionnaire-submitted/${storeState.answers.certificationId}`], {
            replaceUrl: true,
          });
        }),
      ),
    { dispatch: false },
  );

  public questionnaireUploadFilesAndSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionnaireUploadFilesAndSaveAction),
      withLatestFrom(this.store$.select(fnQuestionnaireState)),
      mergeMap(([action, storeState]) => {
        const answer = JSON.parse(JSON.stringify(action['answer']));
        const files: File[] = (storeState.uploadedFiles as File[]) || [];

        if (!answer.answered_inputs[0]) {
          answer.answered_inputs[0] = {} as AnsweredQuestion;
        }

        if (
          !answer.answered_inputs[0].files &&
          inputTypesWithFiles.some((t) => t === answer.answered_inputs[0].input_type)
        ) {
          answer.answered_inputs[0].files = [];
        }

        // It's important to do this after the previous two if statements, files property needs to be there before we save
        if (!files || !files.length) {
          return of(QuestionnaireSaveAction({ updatedInfo: answer }));
        }

        const filesToUpload = [];
        files.forEach((file) => {
          // don't upload files that have already been uploaded
          if (!answer.answered_inputs[0].files.find((f) => f.name === file.name)) {
            filesToUpload.push(file);
          }
        });

        if (!filesToUpload.length) {
          return of(QuestionnaireSaveAction({ updatedInfo: answer }));
        }

        return this.certificationService
          .uploadFiles(filesToUpload, action['certificationId'], action['questionId'])
          .pipe(
            map((uploadedFilenames: any[] = []) => {
              const uploadErrorString = 'UPLOAD_ERROR_';

              // show the Upload Error message if one of the file didn't upload correctly
              if (uploadedFilenames.some((file) => file.name.startsWith(uploadErrorString))) {
                this.showUploadErrorMessage();
              }

              // Merge latest
              answer.answered_inputs[0].files = answer.answered_inputs[0].files.concat(
                uploadedFilenames.map(({ name, awsName }) => ({
                  name,
                  awsName,
                })),
              );

              // Handle edge case where an uploaded filename with the same filename was uploaded successfully
              // If so: remove in-place the unsuccessful filename with UPLOAD_ERROR_...
              answer.answered_inputs[0].files = answer.answered_inputs[0].files.filter(
                (file) =>
                  !file.name.startsWith(uploadErrorString) || // Keep all the non upload failures
                  (file.name.startsWith(uploadErrorString) && // Remove successful, previously failed
                    !answer.answered_inputs[0].files.find((f) => f.name === file.name.slice(uploadErrorString.length))),
              );

              // It's still possible at this point to have multiple failed uploads, with the same filename,
              // so we must perform another duplicate check and remove here. This method simply removes objects with duplicate
              // name properties
              answer.answered_inputs[0].files = answer.answered_inputs[0].files.filter(
                (f1, pos, arr) => arr.findIndex((f2) => f2.name === f1.name) === pos,
              );

              return QuestionnaireSaveAction({ updatedInfo: answer });
            }),
          );
      }),
      catchError((error) => {
        return of(QuestionnaireSaveErrorAction({ errorText: error.statusText }));
      }),
    ),
  );

  public questionnaireSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionnaireSaveAction),
      withLatestFrom(this.store$.select(fnQuestionnaireState)),
      mergeMap(([action, storeState]) => {
        const { answers, question, currentQuestionId, questionnaire, inAnswerLater }: QuestionnaireState = JSON.parse(
          JSON.stringify(storeState),
        );
        const newAnswerData = JSON.parse(JSON.stringify(action.updatedInfo));
        return this.certificationService
          .saveQuestionData(questionnaire, question, answers, currentQuestionId, newAnswerData, inAnswerLater)
          .pipe(
            map((response: SaveQuestionResponse) => {
              if (response.errorText) {
                return QuestionnaireSaveErrorAction({
                  errorText: response.errorText,
                });
              }
              // have we already answered? If so, then grab the existing answer
              const currentAnswer = response.answers.answeredQuestions.find(
                (a) => a.question_id === response.nextQuestionId,
              );
              const currentQuestion = questionnaire.questions.find((q) => q.id === response.nextQuestionId);

              return QuestionnaireSaveSuccessAction({
                answers: response.answers,
                currentQuestionId: response.nextQuestionId,
                isComplete: response.isComplete,
                inAnswerLater: response.inAnswerLater,
                currentAnswer,
                currentQuestion,
              });
            }),
            catchError((error) => {
              if (error.status && error.status === 403) {
                this.routeToListAndShowError(error.error);
              } else {
                this.showServerNotAvailableMessage();
              }
              const message = error.statusText || error.error || 'Server Error';
              return of(QuestionnaireSaveErrorAction({ errorText: message }));
            }),
          );
      }),
      catchError((error) => {
        return of(QuestionnaireSaveErrorAction({ errorText: error.statusText }));
      }),
    ),
  );

  public questionnairePrevious$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionnairePreviousAction),
      withLatestFrom(this.store$.select(fnQuestionnaireState)),
      mergeMap(([, storeState]) => {
        const { currentQuestionId, questionnaire, answers, inAnswerLater }: QuestionnaireState = JSON.parse(
          JSON.stringify(storeState),
        );
        return this.certificationService
          .previousQuestion(questionnaire, answers, currentQuestionId, inAnswerLater)
          .pipe(
            map((response: PreviousQuestionResponse) => {
              return QuestionnairePreviousSuccessAction({
                answers,
                currentQuestionId: response.nextQuestionId,
                onFirstQuestion: response.onFirstQuestion,
                inAnswerLater: response.inAnswerLater,
                currentQuestion: questionnaire.questions.find((q) => q.id === currentQuestionId),
                currentAnswer: answers.answeredQuestions.find((a) => a.question_id === currentQuestionId),
              });
            }),
            catchError((error) => of(QuestionnairePreviousErrorAction({ errorText: error.message }))),
          );
      }),
      catchError((error) => of(QuestionnairePreviousErrorAction({ errorText: error.message }))),
    ),
  );

  public questionnaireAnswerLater$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionnaireAnswerLaterAction),
      withLatestFrom(this.store$.select(fnQuestionnaireState)),
      mergeMap(([, storeState]) => {
        const { answers, question, currentQuestionId, questionnaire, inAnswerLater }: QuestionnaireState = JSON.parse(
          JSON.stringify(storeState),
        );
        return this.certificationService
          .answerLater(questionnaire, question, answers, currentQuestionId, inAnswerLater)
          .pipe(
            map((response: AnswerLaterQuestionResponse) => {
              // error text in this case would be if we only have one question left! (can't save that for later...)
              if (response.errorText) {
                return QuestionnaireAnswerLaterErrorAction({
                  errorText: response.errorText,
                  answers: response.answers,
                  currentAnswer: response.currentAnswer,
                });
              }
              return QuestionnaireAnswerLaterSuccessAction({
                answers: response.answers,
                currentQuestionId: response.nextQuestionId,
                currentAnswer: response.currentAnswer,
                answerLater: response.answers.answerLater,
                inAnswerLater: response.inAnswerLater,
                currentQuestion: questionnaire.questions.find((q) => q.id === currentQuestionId),
              });
            }),
            catchError((error) => {
              const message = error.statusText || 'Server Error';
              this.showServerNotAvailableMessage();
              return of(QuestionnaireAnswerLaterErrorAction({ errorText: message }));
            }),
          );
      }),
      catchError((error) => of(QuestionnaireAnswerLaterErrorAction({ errorText: error.message }))),
    ),
  );

  public doNavRouting = createEffect(() =>
    this.actions$.pipe(
      ofType(
        QuestionnaireSaveSuccessAction,
        QuestionnairePreviousSuccessAction,
        QuestionnaireAnswerLaterSuccessAction,
        QuestionnaireLoadSuccessAction,
      ),
      withLatestFrom(this.store$),
      map(([action, storeState]) => {
        // By this point (the routing) state should already have the correct values from save/previous/answer later
        const { isComplete, currentQuestionId, answers, inAnswerLater, renewing } = storeState.questionnaire;

        // only replace url if navigating from question to question (not if first loading from another screen or hard refresh)
        const replaceUrl = action.type !== QuestionnaireActionTypes.LoadSuccess;

        // append a query param if in answerLater array or renewing state in case of full page reload
        const queryParams = {};
        if (inAnswerLater && !isComplete) {
          queryParams['inAnswerLater'] = 'true';
        }
        if (renewing) {
          queryParams['renewing'] = 'true';
        }

        const navigationExtras: NavigationExtras = {
          queryParams,
          replaceUrl,
        };

        // on save success, if it was last question, we need to go to complete route and take no further action
        if (isComplete) {
          this.router.navigate([`/questionnaire-complete/${answers.certificationId}`], navigationExtras);
          return { type: 'complete' };
        }

        this.router.navigate(
          [`/questionnaire/${answers.certificationId}/question/${currentQuestionId}`],
          navigationExtras,
        );

        return QuestionnaireLoadQuestionAction();
      }),
    ),
  );

  public loadQuestion = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionnaireLoadSuccessAction, QuestionnaireLoadQuestionAction),
      withLatestFrom(this.store$.select(fnQuestionnaireState)),
      mergeMap(([, storeState]) => {
        const { currentQuestionId, questionnaire, answers, inAnswerLater } = storeState;
        return this.certificationService.loadQuestion(questionnaire, answers, currentQuestionId, inAnswerLater).pipe(
          map((response: LoadQuestionResponse) => {
            return QuestionnaireLoadQuestionSuccessAction({
              question: response.currentQuestion,
              currentAnswer: response.currentAnswer,
              onFirstQuestion: response.onFirstQuestion,
            });
          }),
          catchError((error) =>
            of(
              QuestionnaireLoadQuestionErrorAction({
                errorText: error.statusText,
              }),
            ),
          ),
        );
      }),
      catchError((error) => of(QuestionnaireLoadQuestionErrorAction({ errorText: error.statusText }))),
    ),
  );

  constructor(
    private actions$: Actions,
    private certificationService: CertificationService,
    private router: Router,
    public store$: Store<AppState>,
  ) {}

  // Make these blocking for now
  private showServerNotAvailableMessage(blocking = true) {
    this.store$.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.ERROR,
        summary: 'Progress Not Saved',
        detail:
          'Sorry--it looks like an error occurred while saving your progress - please try again.  If this problem persists, contact support by clicking the Support button at top right',
        sticky: true,
        blocking,
      }),
    );
  }

  // Make these blocking for now
  private showUploadErrorMessage(blocking = true) {
    this.store$.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.ERROR,
        summary: 'File(s) Not Uploaded',
        detail:
          'File Type or File Size not supported. Please upload a file with type (.bmp, .png, .jpeg, .jpg, .tif, .tiff, .gif, .docx, .doc, .xls, .xlsx, .csv, .pdf) and with a size less than 50MB.',
        sticky: true,
        blocking,
      }),
    );
  }

  private routeToListAndShowError(error = 'Unknown error - please try again or contact support') {
    this.router.navigate([`/services/merchant-certification`], {
      replaceUrl: true,
    });
    this.store$.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.WARN,
        summary: 'Action Not Permitted',
        detail: error,
        sticky: false,
        blocking: false,
      }),
    );
  }
}
