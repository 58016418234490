import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'docs',
  templateUrl: 'docs.component.html',
  styleUrls: ['docs.component.scss']
})
export class DocsComponent {
  public docs;
  private accountIdForS3 = environment.BUILD_ENV === 'prod' ? '908113025037' : '916533587194';

  private mmv4ApiEnabled: boolean = environment.CONFIG.mmv4ApiEnabled || false;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.docs = [
      {
        name: 'Merchant Monitoring API V3 Docs',
        route: '../v3-docs'
      },
      {
        name: 'Merchant Monitoring User Guide',
        extraLinks: {
          en: `https://myls-help-docs-us-west-2-${
            this.accountIdForS3
          }-ls.s3-us-west-2.amazonaws.com/Merchant+Monitoring+MyLegitScript+User+Guide.pdf`,
          jp: `https://myls-help-docs-us-west-2-${
            this.accountIdForS3
          }-ls.s3-us-west-2.amazonaws.com/Merchant+Monitoring+MyLegitScript+User+Guide_JP.pdf`
        }
      }
    ];
    if (this.mmv4ApiEnabled) {
      this.docs.unshift({
        name: 'Merchant Monitoring API V4 Docs',
        route: '../v4-docs'
      });
    }
  }

  public openDoc(doc: any) {
    this.router.navigate([doc.route], { relativeTo: this.route });
  }
}
