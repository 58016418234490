import { createAction, createReducer, on, props } from '@ngrx/store';

export const TestTransactionStatusGetAction = createAction('[Test Transactions Status] Get - Paged');
export const TestTransactionsStatusGetSuccessAction = createAction(
  '[Test Transactions Status] Get Success',
  props<{ isAvailable: boolean }>(),
);
export const TestTransactionsStatusGetErrorAction = createAction(
  '[Test Transactions Status] Get Error',
  props<{ errorText: string; errorType: number }>(),
);

export interface TestTransactionStatusState {
  pending: boolean;
  isAvailable: boolean;
  error?: {
    errorText;
    errorType;
  };
}

const initialState: TestTransactionStatusState = {
  pending: false,
  isAvailable: true,
};

export const TestTransactionsStatusReducer = createReducer(
  initialState,

  on(TestTransactionStatusGetAction, (state) => ({
    ...state,
    isAvailable: true, // TODO: this needs to change
    pending: true,
  })),

  on(TestTransactionsStatusGetSuccessAction, (state) => ({
    ...state,
    pending: false,
  })),

  on(TestTransactionsStatusGetErrorAction, (state, props) => ({
    ...state,
    error: props,
    pending: false,
  })),
);
