import { of, Observable, Subject, Subscription } from 'rxjs';
import { filter, skipWhile, map, takeUntil } from 'rxjs/operators';
import {
  PaymentPlan,
  PaymentChargeProductsRequest,
  PricingInfo,
  ProductFamily,
  Certification,
  OpportunityTypes,
} from '@ls/common-ts-models';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { QuestionnaireLoadAndSetQuestionAction } from '../../modules/certifications/reducers';
import { Store } from '@ngrx/store';
import { Router, NavigationStart } from '@angular/router';
import {
  BillingService,
  EntitlementsAddRecentlyPurchasedAction,
  EntitlementsGetAction,
  GenericNotificationAction,
  LocalStorageService,
  SeverityOptions,
} from '@ls/common-ng-components';
import { AccountService, PaymentService } from '../../services';
import { environment } from 'src/environments/environment';
import { AccountState } from 'src/app/reducers/account.reducer';
import { AppState, fnAccountState, fnCertificationsState } from 'src/app/reducers';

@Component({
  selector: 'payment-plan',
  templateUrl: 'payment-plan.component.html',
  styleUrls: ['payment-plan.component.scss'],
})
export class PaymentPlanComponent implements OnInit, OnDestroy, AfterViewInit {
  public processingPayment = false;
  public loadingNextScreen = false;
  public submitButtonEnabled = false;
  public loadedStripeCustomer = false;
  public savedCard: any = null;
  public useSavedCC = 'yes';
  public saveCC = 'yes';
  public paymentSuccess: boolean;
  public sources: any;
  public dueToday: number;
  public defaultErrorMsg = 'Internal error occured. If the problem persists, please contact support';
  public pricingInfo: PricingInfo;
  public paymentPlan: PaymentPlan;
  public destroyed$: Subject<boolean> = new Subject();
  public isLookupPurchase: boolean;
  public bypassPayment = false;
  public account: AccountState;

  private stripe: any = (window as any).Stripe(environment.CONFIG.stripeKey);
  private stripeElements: any;
  private card: any;
  private routeSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private billingSvc: BillingService,
    private localStorageService: LocalStorageService,
    private paymentSvc: PaymentService,
    private accountService: AccountService,
  ) {}

  public ngOnInit(): void {
    this.pricingInfo = this.billingSvc.getPricingInfo();
    if (!this.pricingInfo) {
      this.router.navigate(['/account/home']);
    }

    if (this.pricingInfo.certId) {
      // if a customer is paying for a certification application, we need to check to make sure they haven't already paid the fee
      this.store
        .select(fnCertificationsState)
        .pipe(
          skipWhile((certObj) => certObj.pending),
          map((certObj) => certObj.list),
          takeUntil(this.destroyed$.asObservable()),
        )
        .subscribe((certList: Certification[]) => {
          const thisCert = certList.find((cert) => cert.id === this.pricingInfo.certId);
          if (thisCert && thisCert.billingStatus) {
            // if billingStatus is not null, we have already processed payment for this application and the customer should NOT be able to access this view
            this.isDoublePayment();
          }
        });
    }

    const { description } = this.pricingInfo.billingItems[0];
    this.isLookupPurchase = [
      ProductFamily.PRODUCT_API.valueOf(),
      ProductFamily.PRODUCT_LOOKUP.valueOf(),
      ProductFamily.WEBSITE_LOOKUP.valueOf(),
    ].includes(description);
    if (this.pricingInfo) {
      this.paymentPlan = this.paymentSvc.buildPaymentPlan(this.pricingInfo);
    } else {
      this.showErrorNotification('Could not retrieve pricing information', this.defaultErrorMsg);
    }

    if (
      this.paymentPlan.billingSummary.dueToday < this.pricingInfo.maxCCAmount ||
      (this.paymentPlan.billingSummary.discountedDueToday !== undefined &&
        this.paymentPlan.billingSummary.discountedDueToday <= this.pricingInfo.maxCCAmount)
    ) {
      this.stripeElements = this.stripe.elements();
      this.card = this.stripeElements.create('card');
    }

    this.billingSvc.getStripeCustomer().subscribe((result: any) => {
      this.loadedStripeCustomer = true;
      const customer: any = result;
      this.sources = result.sources;
      // make sure we are pulling the default card
      this.savedCard =
        this.sources && this.sources.data
          ? this.sources.data.find(
              (source: { id: any; object: string }) =>
                source.id === customer.default_source && source.object === 'card',
            )
          : null;
    });

    // user has paid, confirmation screen shows, but instead of clicking 'continue', they try to go back to enroll.  Need to remove the pricing info to prevent possibility of double charge
    this.routeSubscription = this.router.events
      .pipe(filter((evt): evt is NavigationStart => evt instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (this.paymentSuccess && event.url === '/services/merchant-certification/enroll') {
          this.billingSvc.clearPricingInfo();
        }
      });

    this.store.select(fnAccountState).subscribe((accountState) => {
      this.account = accountState;
    });
  }

  public ngAfterViewInit(): void {
    if (this.card && !this.account.invoiceCustomer) {
      this.card.mount('#stripeCard');

      // Handle real-time validation errors from the card Element:
      this.card.addEventListener('change', (event: { error: any; complete: any }) => {
        if (event.error) {
          this.submitButtonEnabled = false;
        } else if (event.complete) {
          this.submitButtonEnabled = true;
        } else {
          this.submitButtonEnabled = false;
        }
      });
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (this.processingPayment) {
      this.store.dispatch(
        GenericNotificationAction({
          severity: SeverityOptions.WARN,
          summary: 'Payment Processing',
          detail:
            'Your payment request is still being processed.  Please avoid navigating away from this screen until that process is complete.',
          sticky: false,
          blocking: false,
        }),
      );
      return false;
    }
    return true;
  }

  public enableSubmitButtonOnError(): void {
    this.submitButtonEnabled = true;
    this.processingPayment = false;
    this.paymentSuccess = false;
  }

  public async onSubmitSavedCC() {
    this.submitButtonEnabled = false;
    this.processingPayment = true;
    this.accountService.getCertificationsForAccount().subscribe(
      (data) => {
        const thisCert = data.find((cert) => cert.id === this.pricingInfo.certId);
        if (thisCert && thisCert.billingStatus) {
          this.enableSubmitButtonOnError();
          this.isDoublePayment();
        } else {
          this.continueOnSubmitSavedCC();
        }
      },
      () => {
        this.showErrorNotification('Process failed', this.defaultErrorMsg);
      },
    );
  }

  public async onSubmit() {
    this.submitButtonEnabled = false;
    this.processingPayment = true;
    this.accountService.getCertificationsForAccount().subscribe(
      (data) => {
        const thisCert = data.find((cert) => cert.id === this.pricingInfo.certId);
        if (thisCert && thisCert.billingStatus) {
          this.enableSubmitButtonOnError();
          this.isDoublePayment();
        } else {
          this.continueOnSubmit();
        }
      },
      () => {
        this.showErrorNotification('Process failed', this.defaultErrorMsg);
      },
    );
  }

  public async continueOnSubmitSavedCC() {
    this.pricingInfo = this.billingSvc.getPricingInfo();
    this.paymentPlan = this.paymentSvc.buildPaymentPlan(this.pricingInfo);
    this.saveCC = 'yes';
    this.pricingInfo.sfPricebookId ? this.chargeProducts() : this.chargeOpportunity();
  }

  public async continueOnSubmit() {
    // get latest pricing info in case it has changed due to promo code application
    this.pricingInfo = this.billingSvc.getPricingInfo();
    this.paymentPlan = this.paymentSvc.buildPaymentPlan(this.pricingInfo);

    if (this.bypassPayment) {
      this.pricingInfo.sfPricebookId ? this.chargeProducts() : this.chargeOpportunity();
      return;
    }

    this.stripe.createToken(this.card).then((source: any) => {
      if (source.error) {
        this.enableSubmitButtonOnError();
        const { message = this.defaultErrorMsg } = source.error;
        this.showErrorNotification('Payment Initialization Failed', message);
      } else {
        this.pricingInfo.sfPricebookId ? this.chargeProducts(source) : this.chargeOpportunity(source);
      }
    });
  }

  public continueToNext(): void {
    if (this.pricingInfo.certId) {
      // if a certId is included in the billing summary, user has just paid initial application - route directly to questionnaire.
      this.loadingNextScreen = true;
      this.store.dispatch(
        QuestionnaireLoadAndSetQuestionAction({
          certificationId: this.pricingInfo.certId,
        }),
      );
    } else if (this.pricingInfo && this.pricingInfo.successPage) {
      this.store.dispatch(EntitlementsGetAction());
      this.router.navigate([this.pricingInfo.successPage]);
    } else {
      this.router.navigate(['/account/home']);
    }

    this.billingSvc.clearPricingInfo();
  }

  public checkForPayment(event: boolean) {
    this.bypassPayment = event;

    if (this.savedCard) {
      this.submitButtonEnabled = true;
    } else {
      this.submitButtonEnabled = event;
      if (!this.bypassPayment) {
        this.ngAfterViewInit();
      }
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    // if user leaves screen or reloads browser while payment is in process, payment can succeed but not clear saved info in localStorage - meaning they could come back to payment view and resubmit an already processed payment
    if (this.paymentSuccess) {
      this.billingSvc.clearPricingInfo();
    }

    this.loadingNextScreen = false;
  }

  private chargeProducts(source: any = null) {
    const request: PaymentChargeProductsRequest = {
      customerToken: source ? source.token.id : undefined,
      expectedAmount: this.paymentPlan.billingSummary.dueToday,
      products: this.paymentPlan.billingSummary.products,
      pricebookId: this.pricingInfo.sfPricebookId,
      oppType: this.pricingInfo.oppType,
      externalReferenceId: this.paymentPlan.billingSummary.externalReferenceId,
      saveCC: this.checkAmountToPay() > 0 && this.saveCC === 'yes',
      promoCode: this.pricingInfo.promoCode,
    };
    return this.billingSvc.chargeProducts(request).subscribe(
      () => {
        this.paymentSuccess = true;
        this.processingPayment = false;
        this.billingSvc.clearPricingInfo();
      },
      (err) => {
        const message = this.parseBillingApiError(err);
        this.showErrorNotification('Payment Failed', message);
        return of();
      },
    );
  }

  private chargeOpportunity(source: any = null) {
    const customerToken = source && source.token ? source.token.id : undefined;

    return this.billingSvc
      .chargeOpportunity(this.pricingInfo.oppId, null, customerToken, this.saveCC === 'yes', this.pricingInfo.promoCode)
      .subscribe(
        () => {
          // Update entitlements, so that monitoring guards have up to date data
          this.store.dispatch(EntitlementsGetAction());

          this.paymentSuccess = true;
          this.processingPayment = false;
          this.billingSvc.clearPricingInfo();

          const { description } = this.pricingInfo.billingItems[0];
          const { oppType } = this.pricingInfo;
          if (description.includes('Database Search')) {
            this.localStorageService.setRecentlyPurchasedEntitlement(ProductFamily.PRODUCT_LOOKUP);
            this.store.dispatch(
              EntitlementsAddRecentlyPurchasedAction({
                productFamily: ProductFamily.PRODUCT_LOOKUP,
              }),
            );
            this.localStorageService.setRecentlyPurchasedEntitlement(ProductFamily.WEBSITE_LOOKUP);
            this.store.dispatch(
              EntitlementsAddRecentlyPurchasedAction({
                productFamily: ProductFamily.WEBSITE_LOOKUP,
              }),
            );
          } else if (oppType === OpportunityTypes.MERCHANT_MONITORING) {
            this.localStorageService.setRecentlyPurchasedEntitlement(ProductFamily.MERCHANT_MONITORING);
            this.store.dispatch(
              EntitlementsAddRecentlyPurchasedAction({
                productFamily: ProductFamily.MERCHANT_MONITORING,
              }),
            );
          }
        },
        (err) => {
          const message = this.parseBillingApiError(err);
          this.showErrorNotification('Payment Failed', message);
          return of();
        },
      );
  }

  private checkAmountToPay(): number {
    const { dueToday, discountedDueToday, dueOnApproval, discountedDueOnApproval } = this.paymentPlan.billingSummary;
    const amountToPayToday = discountedDueToday === undefined ? dueToday : discountedDueToday;
    const amountToPayOnApproval = discountedDueOnApproval === undefined ? dueOnApproval : discountedDueOnApproval;
    return amountToPayToday + amountToPayOnApproval;
  }

  private parseBillingApiError(err: { error: { message: string; err: { data: any } } }): string {
    this.paymentSuccess = false;
    this.submitButtonEnabled = true;
    this.processingPayment = false;

    let innerError;
    if (err.error) {
      if (err.error.message) {
        return err.error.message;
      }
      if (err.error.err) {
        innerError = err.error.err.data; // could be nested this deep from billing api
      }
    }
    return innerError && innerError.stripeErrorCode ? innerError.failureReason : this.defaultErrorMsg;
  }

  private showErrorNotification(summary: string, detail: string) {
    this.store.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.ERROR,
        summary,
        detail,
        sticky: true,
        blocking: true,
      }),
    );
  }

  private isDoublePayment() {
    this.billingSvc.clearPricingInfo();
    this.store.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.INFO,
        summary: 'Payment Processed',
        detail:
          'It appears that you have already paid the fee for this application.  Please click the row for the application in the list to access your questionnaire.',
        sticky: true,
        blocking: true,
      }),
    );
    this.router.navigate(['/services/merchant-certification']);
  }
}
