import { Store, createAction, createReducer, createSelector, on, props } from '@ngrx/store';
import { CategoryObject, CreateAWebsite201Response, CreateTransaction201Response } from '../angular-client';
import { AppState, fnTestTransactionsState } from 'src/app/reducers';
import { Observable, defer, filter, map, share } from 'rxjs';
import { MetaColumns } from '../angular-client/model/metaColumns';
import { TagObject } from '../angular-client/model/tagObject';

export const TestTransactionsUniqueValuesAction = createAction(
  '[Test Transactions] Get Unique Values',
  props<{ column: allColumnNames }>(),
);
export const TestTransactionsUniqueValuesSuccessAction = createAction(
  '[Test Transactions] Get Unique Values Success',
  props<{
    column: string;
    values: string[];
    tags?: TagObject[];
    categories?: CategoryObject[];
  }>(),
);
export const TestTransactionsUniqueValuesErrorAction = createAction(
  '[Test Transactions] Get Unique Values Error',
  props<{ errorText: string; errorType: number }>(),
);
export const TestTransactionUniqueValuesNoOpAction = createAction('[Test Transactions] Get Unique Values No Op');

export interface TestTransactionsUniqueValuesState {
  error?: {
    errorText;
    errorType;
  };
  columns: {
    [key in allColumnNames]?: string[];
  };
  tagsMeta?: TagObject[];
  categoriesMeta?: CategoryObject[];
  pending: boolean;
}

const initialState: TestTransactionsUniqueValuesState = {
  columns: {
    merchantCountry: Object.values(CreateTransaction201Response.AcquirerCountryEnum),
    acquirerCountry: Object.values(CreateTransaction201Response.AcquirerCountryEnum),
    monitoringStatus: Object.values(CreateAWebsite201Response.MonitoringStatusEnum),
    source: Object.values(CreateTransaction201Response.SourceEnum),
    transactionStatus: Object.values(CreateTransaction201Response.TransactionStatusEnum),
    transactionCurrency: Object.values(CreateTransaction201Response.TransactionCurrencyEnum),
  },
  pending: false,
};

export const TestTransactionsUniqueValuesReducer = createReducer(
  initialState,

  on(TestTransactionsUniqueValuesAction, (state) => ({
    ...state,
    pending: true,
  })),

  on(TestTransactionsUniqueValuesSuccessAction, (state, props) => ({
    ...state,
    columns: { ...state.columns, [props.column]: props.values },
    tagsMeta: props.tags ?? state.tagsMeta,
    categoriesMeta: props.categories ?? state.categoriesMeta,
    pending: false,
  })),

  on(TestTransactionsUniqueValuesErrorAction, (state, props) => ({
    ...state,
    error: props,
    pending: false,
  })),

  on(TestTransactionUniqueValuesNoOpAction, (state) => ({
    ...state,
    pending: false,
  })),
);

const selectUniqueSate = () => createSelector(fnTestTransactionsState, (state) => state?.uniqueValues);

const columnObservables: { [key in allColumnNames]?: Observable<string[]> } = {};

export const fnColumnValues$ = (store: Store<AppState>, columnName: allColumnNames): Observable<string[]> =>
  defer(() => {
    if (columnObservables[columnName]) {
      return columnObservables[columnName];
    }
    store.dispatch(TestTransactionsUniqueValuesAction({ column: columnName }));
    columnObservables[columnName] = store.select(selectUniqueSate()).pipe(
      filter((state) => !state.pending && !!state.columns[columnName]),
      map((state) => state.columns[columnName]),
      share(),
    );
    return columnObservables[columnName];
  });

export type allColumnNames =
  | MetaColumns
  | 'merchantCountry'
  | 'acquirerCountry'
  | 'acquirerRegion'
  | 'monitoringStatus'
  | 'source'
  | 'transactionStatus'
  | 'transactionCurrency'
  | 'category'
  | 'purchaseAmount';
