<div class="entry-main payment">
  <div class="entry-container entry-container-large payment-thanks" *ngIf="paymentSuccess && !processingPayment">
    <i class="fa fa-check-circle success"></i>
    <h1>Thank You!</h1>
    <div *ngIf="(
      (paymentPlan.billingSummary.dueToday > 0 && (paymentPlan.billingSummary.discountedDueToday === undefined || paymentPlan.billingSummary.discountedDueToday > 0))  || paymentPlan.billingSummary.discountedDueToday > 0
    ); else nothingDueToday">
      <span *ngIf="paymentPlan.billingSummary.discountedDueToday === undefined; else dueTodayDiscount">Your payment in the amount of {{ paymentPlan.billingSummary.dueToday | currency:'USD':'symbol':'1.2-4' }} was successful</span>
      <ng-template #dueTodayDiscount>
        <span>Your payment in the amount of {{ paymentPlan.billingSummary.discountedDueToday | currency:'USD':'symbol':'1.2-4' }} was successful</span>
      </ng-template>
    </div>
    <ng-template #nothingDueToday>
      There are no charges due today.
      <div *ngIf="saveCC && !bypassPayment">
        Your payment information was successfully saved.
      </div>
    </ng-template>
    <div class="continue-wrapper">
      <button [disabled]="loadingNextScreen" (click)="continueToNext()">
        {{ loadingNextScreen ? 'Loading' : 'Continue' }}
      </button>
      <p-progressSpinner
      *ngIf="loadingNextScreen"
      [style]="{ width: '50px', height: '50px', position: 'absolute', left: '0', right: '0' }"
      ></p-progressSpinner>
    </div>
  </div>

  <div class="entry-container entry-container-large">
    <div class="paymentPage">
      <div class="payment-form-column" *ngIf="(!paymentPlan.billingSummary.invoiceCustomer || !pricingInfo.invoiceMessage) && !account.invoiceCustomer">

        <div class="payment-header">
          <h1 class="span6" *ngIf="!bypassPayment; else noPaymentNotice">Payment</h1>
          <ng-template class="form-container" #noPaymentNotice>
            <div class="form-group">
              <h1 class="span6">No Payment Required</h1>
              Congratulations! You can click on submit to start your application.
            </div>
            <div class="form-actions span1 flex-row">
              <button (click)="onSubmit()" [disabled]="!submitButtonEnabled">
                {{processingPayment ? "Processing..." : " Submit "}}
              </button>
              <p-progressSpinner *ngIf="processingPayment" [style]="{ width: '2em', height: '2em' }"></p-progressSpinner>
            </div>
          </ng-template>
        </div>

        <loading-spinner *ngIf="!loadedStripeCustomer" [inline]="true" [size]="'large'"></loading-spinner>
        <div *ngIf="savedCard && !bypassPayment">
          <div class="form-group">
            <div class="radio-group">
                <input value="yes" name="useSavedCC" id="useSavedCCYes" type="radio" [(ngModel)]="useSavedCC" [attr.disabled]="processingPayment || null">
                <label for="useSavedCCYes">Use card on file ending in {{savedCard.last4}}</label>
                <input value="no" name="useSavedCC" id="useSavedCCNo" type="radio" [(ngModel)]="useSavedCC" [attr.disabled]="processingPayment || null">
                <label for="useSavedCCNo">Use a new card</label>
            </div>
          </div>
        </div>

        <div class="form-actions span1 flex-row" *ngIf="(useSavedCC === 'yes' && savedCard) && !bypassPayment">
          <button (click)="onSubmitSavedCC()" [disabled]="paymentSuccess || processingPayment">
            {{processingPayment ? "Processing..." : " Submit "}}
          </button>
          <p-progressSpinner *ngIf="processingPayment" [style]="{ width: '2em', height: '2em' }"></p-progressSpinner>
        </div>
        <form id="payment-method-form" [ngClass]="{'hidden': (useSavedCC === 'yes' && savedCard) || !loadedStripeCustomer || bypassPayment}">
          <div class="form-container">
            <div class="form-group span6">
              <label for="stripeCard">CARD INFORMATION</label>
              <div #stripeCard id="stripeCard" [class.error-border]="paymentSuccess===false">
                <!-- A Stripe Element will be inserted here. -->
              </div>

              <div class="radio-group" *ngIf="savedCard && paymentPlan.billingSummary.dueToday > 0">
                  <input value="yes" name="saveCC" type="radio" id="saveCCYes" [(ngModel)]="saveCC" [attr.disabled]="processingPayment || null">
                  <label for="saveCCYes">Save Card</label>
                  <input value="no" name="saveCC" type="radio" id="saveCCNo" [(ngModel)]="saveCC" [attr.disabled]="processingPayment || null">
                  <label for="saveCCNo">Use this card for this purchase only.</label>
              </div>
            </div>
            <div class="form-actions span1 flex-row">
              <button (click)="onSubmit()" [disabled]="!submitButtonEnabled">
                {{processingPayment ? "Processing..." : " Submit "}}
              </button>
              <p-progressSpinner *ngIf="processingPayment" [style]="{ width: '2em', height: '2em' }"></p-progressSpinner>
            </div>
          </div>
        </form>
      </div>
      <div class="contact-sales" *ngIf="(paymentPlan.billingSummary.invoiceCustomer && pricingInfo.invoiceMessage) && !account.invoiceCustomer">
        <h1 class="span6" [innerHtml]="pricingInfo.invoiceMessage"></h1>
      </div>
      <div class="contact-sales" *ngIf="account.invoiceCustomer">
        <h1 class="span6">This is an invoice-only account. Please <a href="https://www.legitscript.com/contact-api/" target="_blank">contact us</a> for purchases.</h1>
      </div>

      <amount-due-panel [paymentPlan]="paymentPlan" [pricingInfo]="pricingInfo" [invoiceAccount]="account.invoiceCustomer" (bypassPaymentEmitter)="checkForPayment($event)"></amount-due-panel>
    </div>
  </div>
</div>
