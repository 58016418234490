<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->

<!-- Note that validation field lengths are from SalesForce: https://help.salesforce.com/articleView?id=000003589&type=1 -->

<div class="entry-main">
  <div class="entry-container entry-container-small info create-account">
    <h1>Create a LegitScript Account</h1>

    <div class="byline">
      <p class="subtext left"><a (click)="back()">&lt; Back</a></p>
      <p class="subtext right">Fields marked with an asterisk (*) are required.</p>
    </div>

    <form (ngSubmit)="submit()" #form="ngForm" novalidate>
      <div class="create-account-form info-stage form-container form-container-public">
        <div class="form-group span3">
          <field-validator class="right" [control]="firstName"></field-validator>
          <div class="label-validator">
            <input #firstName="ngModel" name="firstName" type="text" [(ngModel)]="model.firstName" placeholder=" " autofocus required maxlength="40" checkWhitespace checkInvalidCharacter aria-label="first name" />
            <label for="firstName" class="left">First Name*</label>
          </div>
        </div>

        <div class="form-group span3">
          <field-validator class="right" [control]="lastName"></field-validator>
          <div class="label-validator">
            <input #lastName="ngModel" name="lastName" type="text" [(ngModel)]="model.lastName" placeholder=" " required maxlength="80" checkWhitespace checkInvalidCharacter aria-label="last name"/>
            <label for="lastName" class="left">Last Name*</label>
          </div>
        </div>

        <div class="form-group span3">
          <field-validator class="right" [control]="company"></field-validator>
          <div class="label-validator">
            <input #company="ngModel" name="company" type="text" [(ngModel)]="model.companyName" placeholder=" " required maxlength="80" checkWhitespace aria-label="company name" />
            <label for="company" class="left">Company*</label>
          </div>
        </div>

        <div class="form-group span3">
          <field-validator class="right" [control]="position"></field-validator>
          <div class="label-validator">
            <input #position="ngModel" name="position" type="text" [(ngModel)]="model.position" placeholder=" " required maxlength="80" checkWhitespace aria-label="position" />
            <label for="position" class="left">Position*</label>
          </div>
        </div>

        <div class="form-group span3">
          <field-validator class="right" [control]="street1"></field-validator>
          <div class="label-validator">
            <input #street1="ngModel" name="street1" type="text" [(ngModel)]="model.address.street1" placeholder=" " required maxlength="125" checkWhitespace aria-label="street line 1" />
            <label for="street1" class="left">Street 1*</label>
          </div>
        </div>

        <div class="form-group span3">
          <field-validator class="right" [control]="street2"></field-validator>
          <div class="label-validator">
            <input #street2="ngModel" name="street2" type="text" [(ngModel)]="model.address.street2" placeholder=" " maxlength="125" aria-label="street line 2" />
            <label for="street2" class="left">Street 2</label>
          </div>
        </div>

        <div class="form-group span2">
          <field-validator class="right" [control]="city"></field-validator>
          <div class="label-validator">
            <input #city="ngModel" name="city" type="text" [(ngModel)]="model.address.city" placeholder=" " required maxlength="40" checkWhitespace aria-label="city" />
            <label for="city" class="left">City*</label>
          </div>
        </div>

        <div class="form-group span2">
          <field-validator class="right" [control]="province"></field-validator>
          <div class="label-validator">
            <input #province="ngModel" maxlength="20" name="province" type="text" [(ngModel)]="model.address.province" placeholder=" " required checkWhitespace aria-label="province" />
            <label for="province" class="left">State / Province*</label>
          </div>
        </div>

        <div class="form-group span2">
          <field-validator class="right" [control]="postalcode"></field-validator>
          <div class="label-validator">
            <input #postalcode="ngModel" name="postalcode" type="text" [(ngModel)]="model.address.postalCode" placeholder=" " required maxlength="20" checkWhitespace aria-label="postal code" />
            <label for="postalcode" class="left">Postal Code / Zip*</label>
          </div>
        </div>

        <div class="form-group span3">
          <field-validator class="right" [control]="country"></field-validator>
          <div class="label-validator">
            <div class="select">
              <select #country="ngModel" name="country" [(ngModel)]="model.address.countryCode" required aria-label="country">
                <ng-container *ngFor="let c of countries">
                  <!-- ignore empty value -->
                  <option *ngIf="c.value !== ''" [ngValue]="c.value">{{c.display}}</option>
                </ng-container>
              </select>
              <label for="country" class="left">Country*</label>
            </div>
          </div>
        </div>

        <div class="form-group span3">
          <field-validator class="right" [control]="phone"></field-validator>
          <div class="label-validator">
            <input #phone="ngModel" name="phone" type="text" [(ngModel)]="model.phone" placeholder=" " required maxlength="40" checkWhitespace aria-label="phone" />
            <label for="phone" class="left">Phone*</label>
          </div>
        </div>
        
        <div class="form-group span6">
          <field-validator class="right" [control]="audienceCategory"></field-validator>
          <div class="label-validator">
            <div class="select">
              <select #audienceCategory="ngModel" name="audienceCategory" [(ngModel)]="model.audienceCategory" (ngModelChange)="onAudienceCategorySelectionChange()" required aria-label="audience category">
                <option *ngFor="let ac of audienceCategories | keyvalue" [ngValue]="ac.value">{{ac.key}}</option>
              </select>
              <label for="audienceCategory" class="left">I Am a...*</label>
            </div>
          </div>
        </div>

        <div class="form-group span6" *ngIf="model.audienceCategory === 'Other'">
          <field-validator class="right" [control]="audienceCategoryOther"></field-validator>
          <div class="label-validator">
            <div class="select">
              <select #audienceCategoryOther="ngModel" name="audienceCategoryOther" [(ngModel)]="model.audienceCategoryOther" (ngModelChange)="onOtherAudienceCategorySelectionChange($event)" required aria-label="other audience category">
                <option *ngFor="let ac of audienceCategoriesOther | keyvalue" [ngValue]="ac.value">{{ac.key}}</option>
              </select>
              <label for="audienceCategoryOther" class="left">Tell us a little about yourself...*</label>
            </div>
          </div>
        </div>

        <!-- ERROR SECTION ****************************************************** -->
        <div *ngIf="form.submitted && form.invalid" class="form-group span6 validation-error-container">
          Please complete all required fields
        </div>
        
        <!-- SUBMIT BUTTON ************************************************************ -->
        <div class="form-actions">
          <button [disabled]="form.submitted && form.invalid" class="btn btn-primary">Continue</button>
        </div>
      </div>
    </form>
  </div>
</div>