import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  MyLSProcessNotificationStatus,
  MyLSPushNotificationJobMessage,
  MyLSPushNotificationMessage,
} from '@ls/common-ts-models';

@Component({
  selector: 'notification',
  styleUrls: ['./notification.component.scss'],
  templateUrl: './notification.component.html',
})
export class NotificationComponent {
  @Input()
  public get notification(): MyLSPushNotificationMessage | MyLSPushNotificationJobMessage {
    return this._notification;
  }
  public set notification(notificationMessage: MyLSPushNotificationMessage | MyLSPushNotificationJobMessage) {
    const knownStatuses = Object.values(MyLSProcessNotificationStatus).map((x) => x.toString());
    if (!knownStatuses.includes(notificationMessage.processStatus)) {
      notificationMessage.processStatus = MyLSProcessNotificationStatus.ERROR; // change to unknown?
    }

    this._notification = notificationMessage;
    if (this.hasCurrentStatus()) {
      this.progressBarValue = (this._notification as MyLSPushNotificationJobMessage).currentStatus as number;
    }
  }

  @Input() public index: number;
  @Input() public last;
  @Input() public closeNotification: (index: number) => void;
  @Output() public closeEvent: EventEmitter<number> = new EventEmitter<number>();

  public progressBarValue = 0;
  protected _notification: MyLSPushNotificationMessage;

  public hasCurrentStatus() {
    return 'currentStatus' in this._notification;
  }

  public close() {
    this.closeEvent.next(this.index);
  }
}
