import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CustomerActionRequest, MyLSListMerchant } from '@ls/common-ts-models';

export const merchantListActions = createActionGroup({
  source: 'Merchant List',
  events: {
    'Select Merchant': props<{ merchant: MyLSListMerchant }>(),
    'Set Merchant Selection': props<{ correlationIds: string[] }>(),
    'Unselect Merchant': props<{ merchant: MyLSListMerchant }>(),
    'Clear Merchant Selection': emptyProps(),
    'Set Select All Merchants': props<{ value: boolean }>(),
    'Apply Bulk Merchant Action': props<{ actionRequest: CustomerActionRequest }>(),
    'Apply Bulk Merchant Action Complete': emptyProps(),
    'Apply Bulk Merchant Action Error': props<{ err: Error }>(),
    'Get All Correlation Ids': emptyProps(),
    'Get All Correlation Ids Complete': props<{ correlationIds: string[] }>(),
    'Get All Correlation Ids Error': props<{ err: Error }>(),
    Noop: emptyProps(),
  },
});
