import { Label } from '@ls/common-ts-models';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import { TagsService } from '../services';
import { GetLsTagsAction, GetLsTagsSuccessAction, LsTagsErrorAction } from '../reducers/lsTags.reducer';

@Injectable()
export class LsTagsEffect {
  public list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetLsTagsAction),
      switchMap(() =>
        this.tagsService.getLsTags().pipe(
          map((res: Label[]) =>
            GetLsTagsSuccessAction({
              tags: res,
            }),
          ),
          catchError((err) => of(LsTagsErrorAction({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private tagsService: TagsService,
  ) {}
}
