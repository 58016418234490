import { PaginatedTransactionsState, TestTransactionsListReducer } from './paginated-transactions-list.reducer';
import { TestTranasctionsDownloadState, TestTransactionsDownloadReducer } from './test-transaactions-download.reducer';
import { TestTranasctionsRequestNewState, TestTransactionsRequestNewReducer } from './test-transacions-request.reducer';
import { TestTransactionStatusState, TestTransactionsStatusReducer } from './test-transaction-status.reducer';
import { TestTransactionsFetchReducer, TestTransactionsTransactionState } from './test-transactions-fetch.reducer';
import { TestTransactionsSummaryReducer, TestTransactionsSummaryState } from './test-transactions-summary.reducer';
import {
  TestTransactionsUniqueValuesReducer,
  TestTransactionsUniqueValuesState,
} from './test-transactions-unique-values.reducer';

export * from './test-transaactions-download.reducer';
export * from './test-transactions-fetch.reducer';
export * from './test-transacions-request.reducer';
export * from './test-transactions-summary.reducer';
export * from './paginated-transactions-list.reducer';
export * from './test-transaction-status.reducer';
export * from './test-transactions-unique-values.reducer';

export interface TestTransactionState {
  transactions: PaginatedTransactionsState;
  download: TestTranasctionsDownloadState;
  request: TestTranasctionsRequestNewState;
  status: TestTransactionStatusState;
  fetch: TestTransactionsTransactionState;
  summary: TestTransactionsSummaryState;
  uniqueValues: TestTransactionsUniqueValuesState;
}

export const APP_REDUCERS = {
  transactions: TestTransactionsListReducer,
  download: TestTransactionsDownloadReducer,
  request: TestTransactionsRequestNewReducer,
  status: TestTransactionsStatusReducer,
  fetch: TestTransactionsFetchReducer,
  summary: TestTransactionsSummaryReducer,
  uniqueValues: TestTransactionsUniqueValuesReducer,
};
