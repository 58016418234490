import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '@ls/common-ng-components';
import { MyLsFilterGroup } from '@ls/common-ts-models';
import { environment } from 'src/environments/environment';

@Injectable()
export class S3DownloadService {
  private mmApiHost = environment.CONFIG.mmApiHost;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

  public requestDownloadUrl(filterGroup: MyLsFilterGroup) {
    // passing basic date info to use when creating filename/email subject for end user display
    const localDate = new Date();
    const year = localDate.getFullYear();
    const month = localDate.getMonth() + 1; // js months are 0 indexed
    const day = localDate.getDate();
    const offset = localDate.getTimezoneOffset(); // Getting the UTC time difference from browser
    const zone = localDate.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    return this.http.post(
      `${this.mmApiHost}/spreadsheets/downloadurl`,
      { filterGroup, generateDate: { year, month, day }, generateTimeZone: { offset, zone } },
      this.localStorageService.getJWTAndSetOptions()
    );
  }
}
