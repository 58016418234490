import { Component, ViewChild, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { CreateAccountRequest } from '@ls/common-ts-models';

import { NgForm } from '@angular/forms';
import { AccountService } from '../../../services';
import { Auth0Service } from '@ls/common-ng-components';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'create-account-begin',
  templateUrl: './create-account-begin.component.html',
  styleUrls: ['../create-account.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateAccountBeginComponent implements OnInit, OnDestroy {
  public loading = false;
  public model: CreateAccountRequest;
  public recaptchaSiteKey: string = environment.CONFIG.recaptchaSiteKey as string;
  public error: number;
  public errorType = {
    UnknownError: 0,
    UserExists: 1,
    BadForm: 2,
    gdpr: 3,
  };
  private _destroyed$: Subject<boolean> = new Subject();

  @ViewChild('captcha') private captcha: { reset: () => void };
  @ViewChild('form') private form: NgForm;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private auth0Service: Auth0Service,
  ) {}

  public ngOnInit() {
    this.model = this.accountService.userCreationModel;
  }

  public ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  public emailSet(email: string) {
    this.model.email = email;
  }

  public loginAuth0() {
    this.auth0Service.login();
  }

  public resolvedCaptcha(captchaResponse: string) {
    this.model.captchaResponse = captchaResponse;
    if (captchaResponse) {
      this.submit();
    }
  }

  public gdprUpdate() {
    if ((this.model as any).gdpr === 'no') {
      this.error = this.errorType.gdpr;
    } else {
      if (this.error === this.errorType.gdpr) {
        this.error = this.form.valid ? undefined : this.errorType.BadForm;
      }
    }
  }

  private submit() {
    if (this.form.invalid || !this.model.captchaResponse) {
      Object.keys(this.form.controls).forEach((controlKey) => {
        this.form.form.controls[controlKey].markAsTouched();
      });
      this.form.form.updateValueAndValidity();
      this.error = this.errorType.BadForm;
      this.captcha.reset();
      return;
    }

    this.accountService.userCreationModel = this.model;
    this.router.navigate(['create-account', 'info']);
  }
}
