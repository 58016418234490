import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import {
  GetMerchantSummaryAction,
  GetMerchantSummarySuccessAction,
  MerchantSummaryErrorAction,
} from '../reducers/merchant-summary.reducer';
import { MerchantSummary } from '@ls/common-ts-models';
import { MerchantSummaryService } from '../services';

@Injectable()
export class MerchantSummaryEffect {
  public list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMerchantSummaryAction),
      switchMap(() =>
        this.summaryService.getMerchantSummary().pipe(
          map((res: MerchantSummary) =>
            GetMerchantSummarySuccessAction({
              summary: res,
            }),
          ),
          catchError((err) => of(MerchantSummaryErrorAction({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private summaryService: MerchantSummaryService,
  ) {}
}
