import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  UsageGetErrorAction,
  UsageGetSuccessAction,
  GenericNotificationAction,
  SeverityOptions,
  BillingService,
  UsageGetAction,
} from '@ls/common-ng-components';
import { AppState } from '../reducers';

@Injectable()
export class UsageEffect {
  public usageGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsageGetAction),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap((_) => {
        // TODO: Get the number of merchants that a user has uploaded if they have purchased monitoring
        return this.billingService.getUsagePlanInfo().pipe(
          map((response) => UsageGetSuccessAction({ usages: [response] })),
          catchError((e) => {
            if (e.error.statusCode !== 401) {
              // If the user has an expired or invalid jwt, we don't show this notice.
              this.store.dispatch(
                GenericNotificationAction({
                  severity: SeverityOptions.WARN,
                  summary: 'Problem Loading Usage Info',
                  detail:
                    "An issue occured while loading your account's usage information.  Your API usage information may currently be unavailable.  If this problem persists, please contact support",
                  sticky: true,
                  blocking: true,
                }),
              );
            }
            return observableOf(UsageGetErrorAction(e.message));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private billingService: BillingService,
  ) {}
}
