import { Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProductsAPILookupService } from '../../services';
import { ProductFamily } from '@ls/common-ts-models';
import { EntitlementsState } from '@ls/common-ng-components';
import { AppState, fnEntitlementsState } from 'src/app/reducers';

@Component({
  selector: 'products-api-docs',
  styleUrls: ['./products-api-docs.scss'],
  templateUrl: './products-api-docs.html',
  encapsulation: ViewEncapsulation.None,
})

/**
 * WARNING!!! Because this element includes (and needs to style) HTML sourced at runtime, it cannot use
 * view encapsulation. This means that styles defined in its CSS _will_ bleed to other styles in the site,
 * and must be verrry carefully manipulated. Be sure to test other pages in the site if you change the
 * styles here.
 */
export class ProductsApiDocsComponent {
  public docHtml$: Observable<any>;
  public productFamily = ProductFamily.PRODUCT_API;
  public entitlements$: Observable<EntitlementsState>;

  constructor(
    private docService: ProductsAPILookupService,
    private store: Store<AppState>,
  ) {
    this.docHtml$ = this.docService.getDocumentation();
    this.entitlements$ = this.store.select(fnEntitlementsState);
  }
}
