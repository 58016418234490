<div *ngIf="question && question.question_type">
    <div class="cert-question">
        <form [formGroup]="questionForm">
            <div class="error-text" *ngIf="outerForm.invalid && saveClicked">
                <i class="fa fa-exclamation-triangle"></i>
                <span>Please enter a value</span>
                <span *ngIf="!question.required"> or check 'not applicable'</span>
            </div>
            <div class="error-text" *ngIf="serverError">
                <span>{{serverError}}</span>
            </div>
            <div class="multi-input-wrapper" [ngStyle]="wrapperStyles">
                <div *ngFor="let inputElement of question.inputs" class="full-width">
                    <div [ngSwitch]="inputElement.input_type" class="full-width">
                        <div *ngSwitchCase="'textbox'" class="full-width">
                            <text-area-question [question]="inputElement" [placeholder]="inputElement.label_text" [answer]="getAnswerInput(inputElement)" (handleChanges)="handleQuestionChanges($event)"
                                                [parentForm]="questionForm" [notApplicable]="ignoreItem.value" [saveClicked]="saveClicked" [showTextArea]="showTextArea"></text-area-question>

                        </div>

                        <div *ngSwitchCase="'yes_no'" class="full-width">
                            <yes-no-question [question]="inputElement" [answer]="getAnswerInput(inputElement)" (handleChanges)="handleQuestionChanges($event)"
                                            [parentForm]="questionForm" [notApplicable]="ignoreItem.value" [saveClicked]="saveClicked"></yes-no-question>
                        </div>

                        <div *ngSwitchCase="'text'" class="full-width">
                            <single-text-question [question]="inputElement" [placeholder]="inputElement.label_text" [answer]="getAnswerInput(inputElement)" (handleChanges)="handleQuestionChanges($event)"
                                                [parentForm]="questionForm" [notApplicable]="ignoreItem.value" [saveClicked]="saveClicked"></single-text-question>
                        </div>

                        <div *ngSwitchCase="'single_select'" class="full-width">
                            <single-select-question [question]="inputElement" [answer]="getAnswerInput(inputElement)" (handleChanges)="handleQuestionChanges($event)"
                                                    [parentForm]="questionForm" [notApplicable]="ignoreItem.value" [questionInput]="question.inputs[0]" [saveClicked]="saveClicked"></single-select-question>
                        </div>

                        <div *ngSwitchCase="'multi_select'" class="full-width">
                            <multi-select-question [question]="inputElement" [answer]="getAnswerInput(inputElement)" (handleChanges)="handleQuestionChanges($event)"
                                                [parentForm]="questionForm" [notApplicable]="ignoreItem.value" [questionInput]="question.inputs[0]" [saveClicked]="saveClicked"></multi-select-question>
                        </div>
                        <div *ngSwitchCase="'combo_upload_text'">
                            <text-and-file-question [question]="inputElement" [answer]="getAnswerInput(inputElement)" (handleChanges)="handleQuestionChanges($event)"
                                                    [parentForm]="questionForm" [notApplicable]="ignoreItem.value" [showTextArea]="true"
                                                    (handleFileSelection)="handleFileSelection($event)"></text-and-file-question>
                        </div>

                        <div *ngSwitchCase="'file_upload'">
                            <text-and-file-question [question]="inputElement" [answer]="getAnswerInput(inputElement)" (handleChanges)="handleQuestionChanges($event)"
                                                    [parentForm]="questionForm" [notApplicable]="ignoreItem.value" [showTextArea]="false"
                                                    (handleFileSelection)="handleFileSelection($event)"></text-and-file-question>
                        </div>

                        <div *ngSwitchDefault>
                            Unrecognized question type: {{ inputElement.input_type }}<br />
                            Please <a href="https://help.legitscript.com" target="_blank">contact support</a> and let them know that {{ inputElement.input_type }} failed to load!
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="inline form-bottom-left" *ngIf="!question.required">
                <label for="notApplicable">Not applicable<span class="mobile-hide"> for this application</span></label>
                <input type="checkbox" 
                    [formControl]="ignoreItem" 
                    [value]="false"
                    id="notApplicable"
                />
            </div>
        </form>
    </div>
</div>
