import { environment } from 'src/environments/environment';

/**
 * Helper functions.
 */

export function isFeatureAvailable(flag, currentUser) {
  const isFeatureReleased = environment.CONFIG[flag].enabled || false;
  const validAccounts = environment.CONFIG[flag].validAccounts || [];
  //Check to see if feature is enabled
  if (isFeatureReleased && Array.isArray(validAccounts)) {
    //If the feature didn't include any users to check, return true. Otherwise, check if current user is an accepted user.
    return validAccounts.length === 0
      ? true
      : validAccounts.some(function (validAccount) {
          return validAccount === parseInt(currentUser);
        });
  }
  return false;
}
