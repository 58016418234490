import { of, Observable } from 'rxjs';

import { catchError, take, map, skipWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { GenericNotificationAction, SeverityOptions, EntitlementsState } from '@ls/common-ng-components';

@Injectable()
export class EnrollGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      skipWhile((state: AppState) => state.entitlements.pending),
      map((state: AppState) => {
        const entitlements: EntitlementsState = state.entitlements;

        if (entitlements.errorText) {
          throw new Error(entitlements.errorText);
        }

        const products = entitlements?.products ?? {};
        const recentlyPurchased = entitlements?.recentlyPurchased ?? {};

        if (
          (Object.keys(products).length && products[route.data['productFamily']]) ||
          recentlyPurchased[route.data['productFamily']]
        ) {
          // User has purchased this item
          this.router.navigate([route.data['altPath']]);
          return false;
        }
        return true;
      }),
      take(1),
      catchError((err) => {
        console.error(err);
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Error Loading Data',
            detail:
              'An issue occured while loading your data, please try again.  If the issue persists, please contact support.',
            sticky: false,
            blocking: false,
          }),
        );
        this.router.navigate(['/account/home']);
        return of(false);
      }),
    );
  }
}
