import { PaymentPlan, PricingInfo, PaymentChargeProductsRequest, OpportunityTypes } from '@ls/common-ts-models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppState } from '../../reducers';
import { QuestionnaireLoadAndSetQuestionAction } from '../../modules/certifications/reducers';
import { Store } from '@ngrx/store';
import { BillingService, GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { of } from 'rxjs';
import { PaymentService } from '../../services';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'payment-plan-invoice',
  templateUrl: 'payment-plan-invoice.component.html',
  styleUrls: ['payment-plan-invoice.component.scss'],
})
export class PaymentPlanInvoiceComponent implements OnInit, OnDestroy {
  public dueToday: number;
  public defaultErrorMsg = 'Internal error occured. If the problem persists, please contact support';
  public pricingInfo: PricingInfo;
  public paymentPlan: PaymentPlan;
  public loading = true;
  public error = false;
  public processingPayment = false;
  public invoiceSuccess = false;

  constructor(
    private store: Store<AppState>,
    private billingSvc: BillingService,
    private paymentSvc: PaymentService,
  ) {}

  public ngOnInit(): void {
    this.pricingInfo = this.billingSvc.getPricingInfo();
    if (this.pricingInfo && this.pricingInfo.certId) {
      this.paymentPlan = this.paymentSvc.buildPaymentPlan(this.pricingInfo);
    } else {
      this.showErrorNotification('Could not retrieve pricing information', this.defaultErrorMsg);
    }
  }

  public ngOnDestroy(): void {
    if (this.invoiceSuccess) {
      this.billingSvc.clearPricingInfo();
    }
  }

  public canDeactivate(): boolean {
    if (this.processingPayment) {
      this.store.dispatch(
        GenericNotificationAction({
          severity: SeverityOptions.WARN,
          summary: 'Invoice creation in process',
          detail:
            'Your invoice is currently being created.  Please avoid navigating away from this screen until that process is complete.',
          sticky: false,
          blocking: false,
        }),
      );
      return false;
    }
    return true;
  }

  public submit() {
    // get latest pricing info in case it has changed due to promo code application
    this.pricingInfo = this.billingSvc.getPricingInfo();
    this.paymentPlan = this.paymentSvc.buildPaymentPlan(this.pricingInfo);

    this.processingPayment = true;
    const request: PaymentChargeProductsRequest = {
      expectedAmount: this.paymentPlan.billingSummary.dueToday,
      products: this.paymentPlan.billingSummary.products,
      pricebookId: this.pricingInfo.sfPricebookId,
      oppType: this.pricingInfo.oppType,
      externalReferenceId: this.paymentPlan.billingSummary.externalReferenceId,
      promoCode: this.pricingInfo.promoCode,
    };
    this.billingSvc
      .sendProductInvoice(request)
      .pipe(
        mergeMap((invoice) => {
          if (request.oppType === OpportunityTypes.CERT_APP) {
            return this.billingSvc.processInvoiceApplication(this.pricingInfo.certId, invoice);
          }
          return of(true);
        }),
      )
      .subscribe(
        () => {
          this.processingPayment = false;
          this.invoiceSuccess = true;
          this.loading = false;
          this.billingSvc.clearPricingInfo();
        },
        () => {
          this.processingPayment = false;
          this.invoiceSuccess = false;
          this.loading = false;
          this.showErrorNotification('Failed to send product invoice', 'Internal error occured.'); // no need to show the backend error, it won't be useful to the end user anyway
        },
      );
  }

  public continueToQuestionnaire() {
    this.loading = true;
    this.store.dispatch(QuestionnaireLoadAndSetQuestionAction({ certificationId: this.pricingInfo.certId }));
  }

  private showErrorNotification(summary: string, detail: string) {
    this.store.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.ERROR,
        summary,
        detail,
        sticky: true,
        blocking: true,
      }),
    );
  }
}
