<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="widget">
  <div class="widget-header">
    <div *ngIf="loading; else resultsHeader">
      <h1>Loading Information</h1>
    </div>
    <ng-template #resultsHeader>
      <h1 class="error" *ngIf="error; else noErrorHeader">
        <div>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          {{error}}
        </div>
      </h1>
      <ng-template #noErrorHeader>
        <h1>
          <div>{{product.name}}</div>
          <div class="subtext" (click)="backToList()">
            <i class="fa fa-undo"></i>
            <span>Back to list</span>
          </div>
        </h1>
      </ng-template>
    </ng-template>
  </div>
  <div class="widget-body centered">
    <div class="entry-container entry-container-large">
      <loading-spinner [loadingText]="'Loading'" *ngIf="loading; else resultsBody"></loading-spinner>
      <ng-template #resultsBody>
        <error-notice *ngIf="error; else noErrorBody" [type]="error"></error-notice>
        <ng-template #noErrorBody>
          <div class="detail-wrapper">
            <div class="product-wrapper">
              <div class="product-info-grid" ngFor="let product of products">
                <div class="product-info">
                  <h2>Form</h2>
                  <div>{{product.form}}</div>
                </div>
                <div class="product-info">
                  <h2>Dosage</h2>
                  <div>{{product.dosage}}</div>
                </div>
                <div class="product-info">
                  <h2>Manufacturer</h2>
                  <div>{{product.manufacturer || 'Not Listed'}}</div>
                </div>
                <div class="product-info">
                  <h2>Active Ingredient(s)</h2>
                  <div>{{product.active_ingredient || 'Not Listed'}}</div>
                </div>
                <div class="product-info multi-row">
                  <h2>Classifications</h2>
                  <div *ngIf="product.displayClassifications?.length; else noClassifications">
                    <div class="product-multi-row" *ngFor="let item of product.displayClassifications">
                      <info-bubble [value]="item.classifications" [country]="item.country"></info-bubble>
                    </div>
                  </div>
                  <ng-template #noClassifications>No classifications for this product.</ng-template>
                </div>
                <div class="product-info multi-row">
                  <h2>Notes</h2>
                  <div *ngIf="product.displayNotes?.length; else noNotes">
                    <div class="product-multi-row" *ngFor="let item of product.displayNotes">
                      <flag [country]="item.country"></flag>
                      <div class="margin-top-sm">{{item.notes}}</div>
                    </div>
                  </div>
                  <ng-template #noNotes>No notes available for this product.</ng-template>
                </div>
                <div class="product-info multi-row">
                  <h2>Regulatory Actions</h2>
                  <div *ngIf="product.reg_actions?.length; else noRegActions">
                    <div class="product-multi-row" *ngFor="let item of product.reg_actions">
                      <flag [country]="item.country_code"></flag>
                      <div class="margin-top-sm">{{item.title}}</div>
                      <a href="{{item.url}}">{{item.url}}</a>
                    </div>
                  </div>
                  <ng-template #noRegActions>No regulatory actions for this product.</ng-template>
                </div>
              </div>
            </div>
            <product-classifications></product-classifications>
          </div>
          <div class="reporting" *ngIf="product">
            Do you have any questions or concerns about this product's classification, or do you have information that could help us keep this classification current? Please <a (click)="showInquiryModal()">click here</a> and let us know.
            <product-inquiry [product]="product"></product-inquiry>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
