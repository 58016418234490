import { map, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  QuestionnaireState,
  QuestionnaireSubmitAction,
  LoadCompletedQuestionnaireAction,
  EditCompletedQuestionAction,
  CertificationsState,
} from '../reducers';
import { LegitScriptModalComponent } from '@ls/common-ng-components';
import { AppState, fnQuestionnaireState } from 'src/app/reducers';
import { NgxScrollEvent } from '../../ngx-scroll-event/ngx-scroll-event.directive';
import { WorkflowStatus, CertAppStatus } from '@ls/common-ts-models';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'cert-complete',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './certification-complete.component.html',
  styleUrls: [
    '../certification-questionnaire-wrapper/certification-questionnaire-wrapper.component.scss',
    './certification-complete.component.scss',
  ],
})
export class CertificationCompleteComponent implements OnInit, OnDestroy {
  public questionnaire$: Observable<QuestionnaireState>;
  public destroyed$: Subject<boolean> = new Subject();
  public questions;
  public answers;
  public certificationId: string;
  public loadingText = 'Loading Your Answers';
  public showBottomBtn = false;
  public renewing: boolean;
  public showLegacyRenewalText = false;
  public experienceCloudUrl: string = environment.CONFIG.experienceCloudURL;

  @ViewChild(LegitScriptModalComponent) public modal: LegitScriptModalComponent;

  constructor(
    public store: Store<AppState>,
    public route: ActivatedRoute,
    public router: Router,
  ) {}

  public ngOnInit() {
    const certificationId: string = this.route.snapshot.params['certificationId'];
    this.renewing = this.route.queryParams && this.route.snapshot.queryParams['renewing'];

    this.store.dispatch(LoadCompletedQuestionnaireAction({ certificationId }));

    this.questionnaire$ = this.store.select(fnQuestionnaireState).pipe(
      takeUntil(this.destroyed$.asObservable()),
      map((q) => JSON.parse(JSON.stringify(q))),
      tap((questionnaireItem: QuestionnaireState) => {
        if (questionnaireItem.questionnaire.questions && questionnaireItem.answers.answeredQuestions) {
          this.questions = questionnaireItem.questionnaire.questions;
          this.answers = questionnaireItem.answers.answeredQuestions;
          this.certificationId = questionnaireItem.answers.certificationId;
        }
      }),
    );

    this.store
      .select((state) => state.certifications)
      .subscribe((certifications: CertificationsState) => {
        // find the current certification and check if it's renewing and that it hasn't been migrated
        if (certifications && certifications.list.length > 0) {
          const cert = certifications.list.find((c) => c.id === certificationId);
          if (
            cert &&
            (cert.workflowStatus === WorkflowStatus.Renewing || cert.certificationStatus !== CertAppStatus.Certified) &&
            !cert.migratedDate
          ) {
            this.showLegacyRenewalText = true;
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public submit() {
    if (this.renewing) {
      // renewals need to check box to confirm they reviewed
      this.modal.showDialog();
    } else {
      this.submitQuestionnaire();
    }
  }

  public confirmReview() {
    this.modal.hideDialog();
    this.submitQuestionnaire();
  }

  public submitQuestionnaire() {
    this.loadingText = 'Submitting Your Answers';
    this.store.dispatch(QuestionnaireSubmitAction({ certificationId: this.certificationId }));
  }

  public editAnswer(questionId) {
    const queryParams = {};
    if (this.renewing) {
      queryParams['renewing'] = 'true';
    }
    const navigationExtras: NavigationExtras = { queryParams };

    this.store.dispatch(EditCompletedQuestionAction());
    this.router.navigate([`/questionnaire/${this.certificationId}/question/${questionId}`], navigationExtras);
  }

  public onWrapperScroll(event: NgxScrollEvent) {
    this.showBottomBtn = !event.isReachingTop;
  }

  // navigate back to the home page of MyLegitScript
  public navigateToLegacyPortal() {
    this.router.navigate(['/account/home']);
  }

  // navigate to Experience Cloud
  public navigateToExperienceCloud() {
    window.location.href = this.experienceCloudUrl;
  }
}
