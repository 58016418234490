import { Injectable } from '@angular/core';
import { exhaustMap, map, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { notificationsActions } from '../../state';
import { NotificationsService } from '../../services';
import {
  MerchantListRequestDownloadCompleteAction,
  MerchantListRequestDownloadErrorAction,
} from 'src/app/modules/merchant-monitoring/reducers';
import { Notification, NotificationStatus } from '../../models';
import { MyLSIoTTopicSuffix, MyLSProcessNotificationStatus } from '@ls/common-ts-models';
import { GenericNotificationAction } from '@ls/common-ng-components';

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
  ) {}

  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.loadNotifications),
      exhaustMap(() =>
        this.notificationsService.getNotificationsForUser().pipe(
          map((notifications) =>
            notificationsActions.setNotifications({
              notifications: notifications,
            }),
          ),
        ),
      ),
    ),
  );

  stopNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.stopNotifications),
      map(() => {
        this.notificationsService.stopGetNotificationsForUser();
        return notificationsActions.stopNotificationsSuccess();
      }),
    ),
  );

  markAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.markNotificationRead),
      map((action) => {
        const newNotifications = this.notificationsService.markNotificationRead({
          ...action.notification,
          showToast: false,
        });
        return notificationsActions.setNotifications({ notifications: newNotifications });
      }),
    ),
  );

  markAllRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.markAllNotificationsRead),
      map(() => {
        const newNotifications = this.notificationsService.markAllNotificationsRead();
        return notificationsActions.setNotifications({ notifications: newNotifications });
      }),
    ),
  );

  markAsDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.deleteNotification),
      map((action) => {
        const newNotifications = this.notificationsService.markNotificationDeleted(action.notification);
        return notificationsActions.setNotifications({ notifications: newNotifications });
      }),
    ),
  );

  markAllDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.deleteAllNotifications),
      map(() => {
        this.notificationsService.markAllNotificationsDeleted();
        return notificationsActions.setNotifications({ notifications: [] });
      }),
    ),
  );

  undoDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.undoDelete),
      map(() => {
        const newNotifications = this.notificationsService.undoDelete();
        return notificationsActions.setNotifications({ notifications: newNotifications });
      }),
    ),
  );

  downloadRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantListRequestDownloadCompleteAction),
      map((response) => {
        const notification: Notification = {
          id: crypto.randomUUID(),
          icon: 'pi-arrow-down blue',
          title: 'We’re preparing your download',
          description: 'Your file will be available to download from the notifications icon shortly.',
          processStatus: MyLSProcessNotificationStatus.SUCCESS,
          isDismissed: false,
          status: NotificationStatus.unread,
          date: new Date(),
          scopedTopic: `myls/${MyLSIoTTopicSuffix.SPREADSHEET_DOWNLOAD}`,
          jobId: response.response?.jobId,
          showToast: true,
        };
        const newNotifications = this.notificationsService.addNotification(notification);
        return notificationsActions.setNotifications({ notifications: newNotifications });
      }),
    ),
  );

  downloadRequestError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantListRequestDownloadErrorAction),
      map(() => {
        const notification: Notification = {
          id: crypto.randomUUID(),
          icon: 'pi-times red',
          title: 'An error occurred while preparing your download.',
          description: 'Please try again.  If this problem persists, please contact support',
          processStatus: MyLSProcessNotificationStatus.ERROR,
          isDismissed: false,
          status: NotificationStatus.unread,
          date: new Date(),
          scopedTopic: `myls/${MyLSIoTTopicSuffix.SPREADSHEET_DOWNLOAD}`,
          jobId: '',
          showToast: true,
        };
        const newNotifications = this.notificationsService.addNotification(notification);
        return notificationsActions.setNotifications({ notifications: newNotifications });
      }),
    ),
  );
}
