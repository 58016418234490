<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<main style="height: 100%">
  <p-toast
    position="bottom-left"
    (onClose)="onClose($event)"
    key="notification"
  ></p-toast>
  <div
    class="blocking-background"
    (click)="clearAll()"
    *ngIf="showBlockingBg"
  ></div>
  <router-outlet></router-outlet>
</main>
