import { switchMap, takeUntil, map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BillingService, LocalStorageService, EntitlementsState } from '@ls/common-ng-components';
import { ApiProxyingRequest, ProductFamily } from '@ls/common-ts-models';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { AppState, fnEntitlementsState } from 'src/app/reducers';

@Injectable()
export class WebsiteLookupService implements OnDestroy {
  private destroyed$: Subject<boolean> = new Subject();
  private entitlements$: Observable<EntitlementsState>;

  constructor(
    private http: HttpClient,
    private billingSvc: BillingService,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
  ) {
    this.entitlements$ = this.store.select(fnEntitlementsState).pipe(takeUntil(this.destroyed$));
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getPricing(): Observable<any> {
    return this.billingSvc.getProductForFamily(ProductFamily.WEBSITE_LOOKUP).pipe(map((response: any) => response[0]));
  }

  public get(website): Observable<any> {
    return this.entitlements$.pipe(
      switchMap((entitlement) => {
        if (!entitlement.products[ProductFamily.WEBSITE_LOOKUP]) {
          throw new Error('Entitlement not found');
        }
        const request: ApiProxyingRequest = {
          productId: entitlement.products[ProductFamily.WEBSITE_LOOKUP].externalReferenceId,
          params: { website },
          method: 'fetch',
        };
        return this.http.post(environment.CONFIG.portalApiHost + '/v1/proxy', request, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.localStorageService.getToken(),
          }),
        });
      }),
    );
  }
}
