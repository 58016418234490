import { takeUntil, map, switchMap, shareReplay } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, fnEntitlementsState } from '../../../reducers';
import { EntitlementsState } from '@ls/common-ng-components';
import { BillingService, LocalStorageService } from '@ls/common-ng-components';
import { ApiProxyingRequest, Opportunity, ProductFamily } from '@ls/common-ts-models';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProductLookupService implements OnDestroy {
  private portalApiHost = environment.CONFIG.portalApiHost;
  private billingApiHost = environment.CONFIG.paymentServiceHost;
  private url = environment.CONFIG.portalApiHost + '/v1/proxy';
  private options: { headers: HttpHeaders };
  private entitlements$: Observable<EntitlementsState>;
  private destroyed$: Subject<boolean> = new Subject();
  private listCache = {};

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private billingSvc: BillingService,
    private localStorageSvc: LocalStorageService,
  ) {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.localStorageSvc.getToken(),
      }),
    };
    this.entitlements$ = this.store.select(fnEntitlementsState).pipe(takeUntil(this.destroyed$));
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getPricing(): Observable<any> {
    return this.billingSvc.getProductForFamily(ProductFamily.PRODUCT_LOOKUP).pipe(map((response: any) => response[0]));
  }

  public search(name: string, page = 1): Observable<any> {
    return this.entitlements$.pipe(
      switchMap((entitlement) => {
        if (!entitlement.products[ProductFamily.PRODUCT_LOOKUP]) {
          throw new Error('Entitlement not found');
        }
        const request: ApiProxyingRequest = {
          productId: entitlement.products[ProductFamily.PRODUCT_LOOKUP].externalReferenceId,
          params: { name, page },
          method: 'search',
        };

        const cacheId = `${name}-${page}`;
        if (!this.listCache[cacheId]) {
          this.listCache[cacheId] = this.http.post(this.url, request, this.options).pipe(shareReplay(1));
        }

        return this.listCache[cacheId];
      }),
    );
  }

  public get(id): Observable<any> {
    return this.entitlements$.pipe(
      switchMap((entitlement) => {
        if (!entitlement.products[ProductFamily.PRODUCT_LOOKUP]) {
          throw new Error('Entitlement not found');
        }
        const request: ApiProxyingRequest = {
          productId: entitlement.products[ProductFamily.PRODUCT_LOOKUP].externalReferenceId,
          params: { id },
          method: 'fetch',
        };
        return this.http.post(this.url, request, this.options);
      }),
    );
  }

  public sendInquiryEmail(product, comment) {
    const options = this.localStorageSvc.getJWTAndSetOptions();

    const url = `${this.portalApiHost}/v1/products/email`;
    return this.http.post(
      url,
      {
        product,
        comment,
      },
      options,
    );
  }

  public checkForOpportunity() {
    const options = this.localStorageSvc.getJWTAndSetOptions();
    const url = `${this.billingApiHost}/v1/dataServiceSubscriptionOpportunity`;
    return this.http.get<Opportunity>(url, options);
  }

  public createOpportunity() {
    const options = this.localStorageSvc.getJWTAndSetOptions();
    const url = `${this.billingApiHost}/v1/dataServiceSubscriptionOpportunity`;
    return this.http.post(url, {}, options);
  }
}
