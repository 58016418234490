import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AppState,
  UserAuthenticateSuccessAction,
  AccountLoadAction,
  CertificationsGetAction,
  AuthenticationState,
  fnNotificationsState,
} from './reducers';
import {
  LocalStorageService,
  NotificationState,
  GenericNotificationClear,
  EntitlementsGetAction,
  EntitlementsAddRecentlyPurchasedAction,
} from '@ls/common-ng-components';

import { Observable, Subject, takeUntil } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Message, MessageService } from 'primeng/api';

import { DataLayerService } from './services/data-layer.service';
import { isProdBuild } from 'config/helpers';
import LogRocket from 'logrocket';

if (isProdBuild()) {
  LogRocket.init('xqs9ls/mylegitscript', {
    network: {
      requestSanitizer: (request) => ({
        ...request,
        headers: {
          ...request.headers,
          Authorization: null,
        },
      }),
    },
  });
}

/**
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: [
    // Order matters here, render globals last for overrides to work
    '../../node_modules/primeng/resources/primeng.css',
    '../../node_modules/primeicons/primeicons.css',
    '../../node_modules/primeflex/primeflex.min.css',
    '../styles/fonts.scss',
    '../styles/styles.globals.scss',
  ],
  providers: [],
})
export class AppComponent implements OnInit {
  public logo = 'assets/img/LegitScript_logo.png';
  public name = 'LegitScript Customer Portal';
  public url = 'http://legitscript.com';

  // global notification variables
  public msgs: Message[] = [];
  public notifications$: Observable<NotificationState>;
  public showBlockingBg = false;

  private JwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private messageService: MessageService,
    private _dataLayerService: DataLayerService,
  ) {}

  public ngOnInit(): void {
    this.store.select(fnNotificationsState).subscribe((state: NotificationState) => {
      if (state.detail) {
        this.messageService.clear('notification'); // one notification at a time for now- remove if we want them to stack
        this.messageService.add({
          severity: state.severity,
          summary: state.summary,
          detail: state.detail,
          sticky: state.sticky,
          life: state.life,
          key: 'notification',
        });
        this.showBlockingBg = state.blocking;
      }
    });

    const initialLoadComplete$: Subject<boolean> = new Subject();
    this.store
      .select('authentication')
      .pipe(takeUntil(initialLoadComplete$))
      .subscribe((state: AuthenticationState) => {
        if (state.user && state.user.roles) {
          this._dataLayerService.logInitializeEvent(state.user);
          // the user needs to have a valid jwt to perform these actions
          const authToken = this.localStorageService.getToken();
          if (authToken && !this.JwtHelper.isTokenExpired(authToken)) {
            this.store.dispatch(CertificationsGetAction());
            this.store.dispatch(EntitlementsGetAction());

            const recentlyPurchasedEntitlements = this.localStorageService.getRecentlyPurchasedEntitlements();
            if (recentlyPurchasedEntitlements) {
              Object.keys(recentlyPurchasedEntitlements).forEach((productFamily) => {
                this.store.dispatch(EntitlementsAddRecentlyPurchasedAction({ productFamily }));
              });
            }
          }

          // we got it! don't listen for future events.
          initialLoadComplete$.next(true);
        }
      });

    // Check local storage for a user (aka, the page was refreshed)
    const user = this.localStorageService.getAuthenticatedUser();

    if (user && user.roles) {
      // we have a user record available, push to the store.
      this.store.dispatch(UserAuthenticateSuccessAction({ user, roles: user.roles }));
      this.store.dispatch(AccountLoadAction());
    }
  }

  // Hide notification and blocking background if that type is showing
  public clearAll() {
    this.messageService.clear('notification');
    this.onClose(null);
  }

  // primeng-specific handler
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onClose(_msg: unknown) {
    this.showBlockingBg = false;
    this.store.dispatch(GenericNotificationClear());
  }
}
