import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'multi-line-input',
  templateUrl: './multi-line-input.component.html',
  styleUrls: ['./multi-line-input.component.scss'],
})
export class MultiLineInputComponent implements OnInit, OnChanges {
  @Input() public inputArray: string[];
  @Output() public inputArrayChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  public inputString: string;

  constructor() {
    this.constructInputBody();
  }

  public ngOnInit() {
    this.constructInputBody();
  }

  public ngOnChanges() {
    this.constructInputBody();
  }

  public constructInputBody() {
    let templateString = '';
    if (this.inputArray) {
      this.inputArray.map((row, index) => {
        templateString += row;
        if (index + 1 < this.inputArray.length) {
          templateString += '\n';
        }
      });
    }
    this.inputString = templateString;
  }

  public handleChange() {
    const tempArray: string[] = this.inputString.split('\n');
    this.inputArray = tempArray.filter((row) => row !== '');

    this.inputArrayChange.emit(this.inputArray);
  }

  public handleFocusOut() {
    const multiLineElement = document.getElementById('multiLineInput');
    multiLineElement.scrollTo(0, 0);
  }
}
