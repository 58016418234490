<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->


<legitscript-modal #modal [draggable]=false [closable]=false [resizable]=false [responsive]=true [modal]=true autoAlign=true [width]=800>
    <div class="dialog-body" style="overflow:auto; padding: .5em; display: grid; grid-row: auto;">
      <div class="comment-block" *ngIf="!submitting">
        <label id="comment-label">Comments:</label>
        <textarea rows="10" [formControl]="comment"></textarea>
      </div>
      <div *ngIf="submitting">
        <loading-spinner [loadingText]="'Submitting comments...'"></loading-spinner>
      </div>
    </div>
    <div class="dialog-footer" *ngIf="!submitting">
      <button (click)="submit()" [disabled]="comment.invalid || submitting">Submit</button>
      <button (click)="cancel()">Cancel</button>
    </div>
  </legitscript-modal>
