<div class="product-classifications">
  <h2>Product Classification Information</h2>
  <div class="column-wrapper">
    <div class="column">
      <div class="group">
        <info-bubble [value]="'red'"></info-bubble>
        <div class="classification-description">
          This classification is used for a product that is high-risk for noncompliance in the jurisdiction in which it is designated red flag. LegitScript recommends that these products not be sold or marketed in those jurisdictions.
        </div>
      </div>
      <div class="group">
        <info-bubble [value]="'orange'"></info-bubble>
        <div class="classification-description">
          These products are not always problematic, but may have significant restrictions for online sales (e.g., most controlled substances, discontinued drugs, listed chemicals, etc.) in the designated jurisdictions        </div>
      </div>
      <div class="group">
        <info-bubble [value]="'green'"></info-bubble>
        <div class="classification-description">
          Green Flag is reserved for prescription and Over-The-Counter drugs that have been permitted for sale by a regulatory authority in the designated jurisdiction. It should be noted that one drug may be “approved” in one jurisdiction but “unapproved” in another.        </div>
      </div>
    </div>
    <div class="column">
      <div class="group">
        <info-bubble [value]="'certified'"></info-bubble>
        <div class="classification-description">
          A product that has been reviewed by LegitScript and laboratory tested. This product appears to meet the regulatory standards in the jurisdiction in which it is certified by LegitScript.
        </div>
      </div>
      <div class="group">
        <info-bubble [value]="'rx'"></info-bubble>
        <div class="classification-description">
          An approved prescription drug for human use.
        </div>
      </div>
      <div class="group">
        <info-bubble [value]="'adrx'"></info-bubble>
        <div class="classification-description">
          An approved prescription drug for animal use.
        </div>
      </div>
      <div class="group">
        <info-bubble [value]="'otc'"></info-bubble>
        <div class="classification-description">
          An approved Over-The-Counter drug for human use.
        </div>
      </div>
      <div class="group">
        <info-bubble [value]="'adotc'"></info-bubble>
        <div class="classification-description">
          An approved Over-The-Counter drug for animal use.
        </div>
      </div>
      <div class="group">
        <info-bubble [value]="'tent'"></info-bubble>
        <div class="classification-description">
          This is a tentatively approved drug.
        </div>
      </div>
      <div class="group">
        <info-bubble [value]="'rxh'"></info-bubble>
        <div class="classification-description">
          An approved drug available only from a hospital.
        </div>
      </div>
      <div class="group">
        <info-bubble [value]="'po'"></info-bubble>
        <div class="classification-description">
          An approved drug available only from a pharmacy. Pharmacy-only products do not require a prescription.
        </div>
      </div>
    </div>
  </div>
</div>