<div class="widgets-container">
  <div class="widget">
    <div class="widget-header">
      <i class="fa fa-credit-card"></i><h1>Billing</h1>
    </div>
    <div class="widget-body centered">

      <loading-spinner *ngIf="!loadedStripeCustomer && !error" loadingText="Loading Payment Data"></loading-spinner>

      <div class="entry-main payment" [ngClass]="{'hidden': !loadedStripeCustomer}">
        <div class="entry-container entry-container-large">
          <div class="paymentPage">
            <div class="payment-form-column">
              <form [formGroup]="paymentForm" id="payment-method-form">
                <h1 *ngIf="!defaultCard && cardUpdated; else hasDefaultCard">
                  Credit card successfully updated!
                </h1>
                <ng-template #hasDefaultCard>
                  <h1 *ngIf="defaultCard; else editCard" >
                    Update credit card ending in {{defaultCard.last4}}
                  </h1>
                  <ng-template #editCard>
                    <h1>Add credit card to account</h1>
                  </ng-template>
                </ng-template>

                <div class="form-container">
                  <div class="form-group span6">
                    <label for="stripeCard">CARD INFORMATION</label>
                    <div #stripeCard id="stripeCard" [class.error-border]="creditCardChangeSuccess===false">
                      <!-- A Stripe Element will be inserted here. -->
                    </div>
                  </div>

                  <div class="form-actions span1 flex-row">
                    <button (click)="onSubmit()" [disabled]="!submitButtonEnabled">
                      {{processingCCChange ? "Processing..." : " Submit "}}
                    </button>
                    <p-progressSpinner *ngIf="processingCCChange" [style]="{ width: '2em', height: '2em' }"></p-progressSpinner>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <active-products></active-products>
  <billing-history></billing-history>
</div>
