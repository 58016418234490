/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionCurrencyCodes } from './transactionCurrencyCodes';
import { TransactionStatuses } from './transactionStatuses';
import { TransactionSources } from './transactionSources';
import { WebsiteCountries } from './websiteCountries';


export interface TransactionObject { 
    id: string;
    websiteUrl: string;
    requestId?: string;
    legitscriptId?: string;
    source: TransactionSources;
    transactionStatus: TransactionStatuses;
    transactionDate?: string;
    transactionAmount?: number;
    transactionCurrency?: TransactionCurrencyCodes;
    transactionUSDAmount?: number;
    transactionDescription?: string;
    transactionId?: string;
    ccLast4?: string;
    analysisDate?: string;
    analysisNote: string;
    internalNote?: string;
    cardAcceptorId?: string;
    terminalId?: string;
    posConditionCode?: number;
    posEntryMode?: number;
    rightsHolder?: string;
    rightsHolderContactName?: string;
    rightsHolderContactPhone?: string;
    rightsHolderContactEmail?: string;
    rightsHolderContactAddress?: string;
    recordTags?: Array<any>;
    externalId?: string;
    client?: string;
    clientSub?: string;
    purchaseAmount?: number;
    mccCategory?: string;
    cardholderLocation?: WebsiteCountries;
    issuerLocation?: WebsiteCountries;
    merchantDescriptor?: string;
    merchantCity?: string;
    merchantState?: string;
    merchantCountry?: WebsiteCountries;
    merchantCategoryCode?: number;
    paymentWebsite?: string;
    acquirerName?: string;
    acquirerCountry?: WebsiteCountries;
    acquirerRegion?: string;
    acquirerBin?: number;
    acquirerBid?: number;
    agent?: string;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    updatedBy?: string;
    deletedAt?: string;
}
export namespace TransactionObject {
}



