import { takeUntil } from 'rxjs/operators';
import { Component, ViewChild, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { CreateAccountRequest, CreateAccountError, passwordPattern, CookieValues, MARKETO_COOKIE_LIST } from '@ls/common-ts-models/';

import { NgForm } from '@angular/forms';
import { AccountService } from '../../../services';
import { NgxScrollEvent } from 'src/app/modules/ngx-scroll-event/ngx-scroll-event.directive';
import { environment } from 'src/environments/environment';
import { LegitScriptModalComponent } from '@ls/common-ng-components';

@Component({
  selector: 'create-account-password',
  templateUrl: './create-account-password.component.html',
  styleUrls: ['../create-account.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateAccountPasswordComponent implements OnInit, OnDestroy {
  public loading = false;
  public model: CreateAccountRequest;
  public passwordConfirm = '';
  public recaptchaSiteKey: string = environment.CONFIG.recaptchaSiteKey as string;
  public destroyed$: Subject<boolean> = new Subject();
  public pwdPattern = passwordPattern;

  /**
   * Because of what appears to be a bug in at-loader,
   * this is effectively a COPY of the model from common-ts-models.
   * I'm so, so sorry. This is just so we can use the enum
   * as ngSwitchCase in the template.
   */
  public errorType = {
    DuplicateEmail: CreateAccountError.DuplicateEmail,
    DuplicateCompany: CreateAccountError.DuplicateCompany,
    DuplicateSalesforce: CreateAccountError.DuplicateSalesforce,
    Unknown: CreateAccountError.Unknown,
    BadForm: 99,
  };
  public createError: CreateAccountError | undefined = undefined;

  @ViewChild('form') public form: NgForm;
  @ViewChild('captcha') public captcha: { reset: () => void };
  @ViewChild(LegitScriptModalComponent) public termsModal: LegitScriptModalComponent;

  public hasScrolledToBottom = false;
  public termsSelected = false;

  constructor(
    private accountService: AccountService,
    private router: Router,
  ) {}

  public ngOnInit() {
    if (!this.accountService.thirdCreateFormComplete()) {
      this.router.navigate(['create-account', 'info']);
    }
    this.model = this.accountService.userCreationModel;
    this.model.captchaResponse = undefined;
  }

  // Fires when the div.dialog-body is scrolling through the T/Cs.
  public onDialogScroll(event: NgxScrollEvent) {
    if (event.isWindowEvent === false && event.isReachingBottom === true) {
      this.hasScrolledToBottom = true;
    }
  }

  // Fires when the T/Cs checkbox is clicked.
  public onTermsChecked(e: Event) {
    const checkbox: HTMLInputElement = e.target as any;

    // If we're clicking the checkbox AND we haven't read the terms yet (scrolled to the bottom of the modal) then show the modal instead of allowing the checkbox click.
    if (checkbox.checked === true && this.hasScrolledToBottom === false) {
      this.termsModal.showDialog();
      // The checked event has already fired, to consistently revert we raise another event to revert the checkbox state.
      setTimeout(() => {
        this.termsSelected = false;
      });
    }
  }

  public resolvedCaptcha(captchaResponse: string) {
    this.model.captchaResponse = captchaResponse;
    if (captchaResponse) {
      this.submit();
    }
  }

  // Fires when someone clicks "Agree" on the T/Cs modal.
  public onAgreeToTerms() {
    this.termsModal.hideDialog();
    this.termsSelected = true;
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public back() {
    this.router.navigate(['create-account', 'info']);
  }

  private fetchMarketoCookieValues() {
    const cookieValues = {} as CookieValues;
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      const trimmedName = name.trim();
      const trimmedValue = value.trim();
      if (MARKETO_COOKIE_LIST.includes(trimmedName)) {
        cookieValues[trimmedName] = trimmedValue;
      }
    }

    return cookieValues;
  }

  private submit() {
    if (this.form.invalid || !this.model.captchaResponse) {
      Object.keys(this.form.controls).forEach((controlKey) => {
        this.form.form.controls[controlKey].markAsTouched();
      });
      this.form.form.updateValueAndValidity();
      this.createError = this.errorType.BadForm;
      this.captcha.reset();
      return;
    }

    this.model.cookies = this.fetchMarketoCookieValues();

    this.loading = true;
    this.accountService
      .createAccount(this.model)
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe(
        () => {
          this.loading = false;
          this.router.navigate(['/account-created']);
        },
        (error) => {
          this.loading = false;
          this.captcha.reset();
          this.createError = error.error && error.error.type ? error.error.type : CreateAccountError.Unknown;
        },
      );
  }
}
