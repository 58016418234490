<!-- eslint-disable @angular-eslint/template/alt-text -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<generic-enroll [inputFormGroup]="daatcFormGroup" [pricing]="pricing" [pricingInfo]="pricingInfo" (validate)="validate(false)" [preventPayment]="recoveryResidence.value === 'yes'" (calculatePricing)="calculatePricing()" [questionnaireInfo]="questionnaireInfo">
  <ng-container class="questions">
    <ng-container *ngIf="questions | async as q; else loadingQuestions">
      <div class="question-number">
        <span class="question-number">2</span>
      </div>
      <fieldset class="span2">
        <div class="question-text" [innerHtml]="recoveryResidenceQuestionText"></div>
        <div class="radio-group">
          <input value="yes" name="recoveryResidence" id="recoveryResidenceYesAnswer" type="radio" [formControl]="recoveryResidence" required>
          <label for="recoveryResidenceYesAnswer">Yes</label>
          <input value="no" name="recoveryResidence" id="recoveryResidenceNoAnswer" type="radio" [formControl]="recoveryResidence">
          <label for="recoveryResidenceNoAnswer">No</label>
          <span *ngIf="!recoveryResidence.valid && !firstTry" class="error">* Required</span>
        </div>
        <div *ngIf="recoveryResidence.value === 'yes'" class="errorBox errorBorder error">
            Sorry, we are unable to certify recovery residences or lead generators at this time.
        </div>
      </fieldset>  
      <div class="question-number">
        <span class="question-number">3</span>
      </div>
      <fieldset class="span2">
        <div class="question-text" [innerHtml]="nonProfitQuestionText"></div>
        <div class="radio-group">
          <input value="yes" name="nonProfit" id="nonProfitYesAnswer" type="radio" [formControl]="nonProfit">
          <label for="nonProfitYesAnswer">Yes</label>
          <input value="no" name="nonProfit" id="nonProfitNoAnswer" type="radio" [formControl]="nonProfit">
          <label for="nonProfitNoAnswer">No</label>
          <span *ngIf="!nonProfit.valid && !firstTry" class="error">* Required</span>
        </div>
      </fieldset>
      <div class="question-number">
        <span class="question-number">4</span>
      </div>
      <fieldset class="span2">
        <div class="question-text" [innerHtml]="numFacilitiesQuestionText"></div>
        <input type="number" [formControl]="numFacilities">
        <span *ngIf="!numFacilities.valid && !firstTry" class="error">
          <span *ngIf="daatcFormGroup.get('numFacilities').hasError('required')">* Required</span>
          <span *ngIf="daatcFormGroup.get('numFacilities').hasError('max')">* Number of facilities must not be greater than 1000.</span>
          <span *ngIf="daatcFormGroup.get('numFacilities').hasError('min')">* Number of facilities must be greater than 0.</span>
          <span *ngIf="daatcFormGroup.get('numFacilities').hasError('pattern')">* Please enter only digits 0-9.</span>
        </span>
      </fieldset>
      <div class="question-number">
          <span class="question-number">5</span>
      </div>
      <fieldset class="span2">
          <div class="radio-group">
            <div class="question-text" [innerHtml]="hasThreeOrFewerPractitionersQuestionText"></div>
            <input value="yes" name="hasThreeOrFewerPractitioners" id="hasThreeOrFewerPractitionersYesAnswer" type="radio" [formControl]="hasThreeOrFewerPractitioners">
            <label for="hasThreeOrFewerPractitionersYesAnswer">Yes</label>
            <input value="no" name="hasThreeOrFewerPractitioners" id="hasThreeOrFewerPractitionersNoAnswer" type="radio" [formControl]="hasThreeOrFewerPractitioners">
            <label for="hasThreeOrFewerPractitionersNoAnswer">No</label>
          </div>
     </fieldset>
      <div class="question-number">
          <span class="question-number">6</span>
      </div>
      <fieldset class="span2">
          <div class="radio-group">
            <div class="question-text" [innerHtml]="hasMoreThanOneWebsiteQuestionText"></div>
            <input value="yes" name="hasMoreThanOneWebsite" id="hasMoreThanOneWebsiteYesAnswer" type="radio" [formControl]="hasMoreThanOneWebsite">
            <label for="hasMoreThanOneWebsiteYesAnswer">Yes</label>
            <input value="no" name="hasMoreThanOneWebsite" id="hasMoreThanOneWebsiteNoAnswer" type="radio" [formControl]="hasMoreThanOneWebsite">
            <label for="hasMoreThanOneWebsiteNoAnswer">No</label>
          </div>
      </fieldset>
      <div class="question-number">
          <span class="question-number">7</span>
      </div>
      <fieldset class="span2">
          <div class="radio-group">
            <div class="question-text" [innerHtml]="arePractitionersAffiliatedQuestionText"></div>
            <input value="yes" name="arePractitionersAffiliated" id="arePractitionersAffiliatedYesAnswer" type="radio" [formControl]="arePractitionersAffiliated">
            <label for="arePractitionersAffiliatedYesAnswer">Yes</label>
            <input value="no" name="arePractitionersAffiliated" id="arePractitionersAffiliatedNoAnswer" type="radio" [formControl]="arePractitionersAffiliated">
            <label for="arePractitionersAffiliatedNoAnswer">No</label>
          </div>
      </fieldset>
    </ng-container>
    
    <ng-template #loadingQuestions>
      <div *ngIf="!apiError" class="row4 span2 mb30" style="margin-top: 4em; text-align: center">
        Loading questionnaire...
        <span class="floating-spinner-container">
          <p-progressSpinner [style]="{ width: '1.5em', height: '1.5em' }"></p-progressSpinner>
        </span>
      </div>
    </ng-template>
    <div class="api-error span3" *ngIf="apiError">
      An unknown error occurred loading the tier selection questionnaire. Please try again later or contact support.
    </div>
  </ng-container>

  <ng-container class="pricing-info">
      <a class="price-faq-link mb30" (click)="pricingModal.showDialog()">How is my pricing category determined?</a>
      <legitscript-modal #pricingModal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=800 height=auto>
        <div class="dialog-body" style="overflow:auto; height: 75vh; text-align: left;">
          <daatc-pricing-info [pricing]="pricing"></daatc-pricing-info>
        </div>
        <div class="dialog-footer">
          <button type="button" (click)="pricingModal.hideDialog()" label="Yes">Dismiss</button>
        </div>
      </legitscript-modal>
  </ng-container>

  <ng-container class="terms">
    <daatc-terms></daatc-terms>
  </ng-container>

  <ng-container class="intro">
      <h1>You’re one step closer to unlocking the benefits of Addiction Treatment Certification</h1>
      <section class="intro">
        <picture>
          <img class="product-logo" src="../assets/img/certification-addiction-icon.png" />
        </picture>
        <div>
          <div class="list-title">Applying for Addiction Treatment Certification is straightforward:</div>
          <ul>
            <li>Complete the application questionnaire</li>
            <li>Applications are processed in the order they are received</li>
            <li>Certification is required by both Google and Facebook. Once you are certified and approved by these platforms, you'll be eligible to run ads</li>
            <li>You can’t just get certified and then drop out of the program: ongoing monitoring is required</li>
          </ul>
        </div>
      </section>
  </ng-container>
</generic-enroll>
