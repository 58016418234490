<div class="login-container" id="verify-container">
    <login-header></login-header>

    <div class="entry-main">
        <div class="entry-container entry-container-small" *ngIf="(verification$ | async)?.pending">
            <i class="fa fa-hourglass waiting"></i>
            <h1 class="mb40">Account Verification In Progress</h1>
            <div class="subtext mb40">
                We are currently checking your account info.<br />
                <br />
                This should only take a moment.
            </div>
            <div>Thank you for your patience.</div>
        </div>
        <div class="entry-container entry-container-small" *ngIf="!((verification$ | async)?.pending) && ((verification$ | async)?.success)">
            <i class="fa fa-check-circle success"></i>
            <h1 class="mb40">Account Verified</h1>
            <div class="subtext mb40">
                Thank you {{(verification$ | async)?.name}}<br />
                <br />
                You may now access the LegitScript Client Portal.
            </div>
            <button class="btn btn-primary" routerLink="/login">Sign In</button>
        </div>
        <div class="entry-container entry-container-small" *ngIf="!((verification$ | async)?.pending) && !((verification$ | async)?.success)">
            <i class="fa fa-times-circle danger"></i>
            <h1 class="mb40">Account Verification Failure</h1>
            <div class="subtext mb40">
                We don't seem to be able to verify your account.<br />
                <br />
                You may be using an old verification link or have encountered another problem.
            </div>
            <button class="btn btn-warning" (click)="contactSupport()">Contact Support</button>
        </div>
    </div>
</div>
    