import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Subject } from 'rxjs';

@Component({
  selector: 'help',
  styleUrls: ['./help.component.scss'],
  templateUrl: './help.component.html',
})
export class HelpComponent implements OnInit, OnDestroy {
  public helpFiles: [{ name: string; url: string }];
  public destroyed$: Subject<boolean> = new Subject();
  public loading = true;

  constructor(
    private accountSvc: AccountService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit(): void {
    this.accountSvc
      .listHelpFiles()
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe(
        (result) => {
          this.loading = false;
          this.helpFiles = result;
        },
        () => {
          this.loading = false;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Error fetching help files',
              detail: 'Please try again later',
              sticky: false,
              blocking: false,
            }),
          );
        },
      );
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
