import { empty as observableEmpty, Subscription } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { ProductLookupService } from '../../services';
import { AppState } from '../../reducers';
import { LegitScriptModalComponent } from '@ls/common-ng-components';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'product-inquiry',
  styleUrls: ['./product-inquiry.component.scss', '../../styles/range.scss'],
  templateUrl: './product-inquiry.component.html',
})
export class ProductInquiryComponent implements OnInit, OnDestroy {
  @Input() public product: any;
  public comment: FormControl;
  public submitting: boolean;
  @ViewChild(LegitScriptModalComponent) private modal: LegitScriptModalComponent;
  private result$: Subscription;

  constructor(
    private productLookupService: ProductLookupService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit() {
    this.comment = new FormControl('', Validators.required);
  }

  public ngOnDestroy() {
    if (this.result$) {
      this.result$.unsubscribe();
    }
  }

  public showModal() {
    this.modal.showDialog();
  }

  public submit() {
    this.submitting = true;
    this.result$ = this.productLookupService
      .sendInquiryEmail(this.product, this.comment.value)
      .pipe(
        catchError(() => {
          this.submitting = false;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Failed to send product inquiry',
              detail: 'An error occured attempting to send your product inquiry. Please try again later.',
              sticky: false,
              blocking: false,
            }),
          );
          return observableEmpty();
        }),
      )
      .subscribe(() => {
        this.submitting = false;
        this.modal.hideDialog();
        this.comment.reset();
      });
  }

  public cancel() {
    this.modal.hideDialog();
    this.comment.reset();
  }
}
