import { Injectable } from '@angular/core';
import { Auth0Handler, LocalStorageService } from '@ls/common-ng-components';
import { AuthenticatedUser, AuthenticationInfo, Roles, inMyLS, firstECTry } from '@ls/common-ts-models';
import { Store } from '@ngrx/store';
import { AccountLoadAction, AppState, UserAuthenticateSuccessAction } from '../reducers';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class Auth0HandlerService implements Auth0Handler {
  public newMyLSPortalURL = environment.CONFIG.experienceCloudURL;

  constructor(
    private localStorage: LocalStorageService,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public onSuccess(auth: AuthenticationInfo): void {
    this.localStorage.setTokenRaw(auth.jwt);
    this.localStorage.setAuthenticatedUser(auth.user);

    if (this.isAccessAllowed(auth.user)) {
      this.store.dispatch(UserAuthenticateSuccessAction({ user: auth.user, roles: auth.user.roles }));
      this.store.dispatch(AccountLoadAction());
    } else {
      // Maybe the user is not authorized to be on MyLS but has the Experience Cloud Role
      // We need to check that, and if that's the case, we need to redirect it to Salesforce
      if (auth.user.roles && auth.user.roles.includes(Roles.cpv2AccountOwnerSF)) {
        localStorage.setItem(inMyLS, 'false');
        localStorage.setItem(firstECTry, 'true');
        window.location.href = this.newMyLSPortalURL;
      } else {
        this.router.navigate(['/access-denied']);
      }
    }
  }

  public isAccessAllowed(user: AuthenticatedUser): boolean {
    return [this.hasAccountId, this.hasUserId, this.hasMyLSRole].every((test) => test(user));
  }

  public hasAccountId(user: AuthenticatedUser): boolean {
    return user && !!user.lsAccountId;
  }

  public hasUserId(user: AuthenticatedUser): boolean {
    return user && !!user.lsUserId;
  }

  public hasMyLSRole(user: AuthenticatedUser): boolean {
    return user && !!user.roles && user.roles.some((role) => [Roles.cpv2AccountOwner, Roles.cpv2User].includes(role));
  }
}
