<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<h1>
  Terms and Conditions for Use
</h1>
<p>
  To assist you in using LegitScript LLC's website(s), including the use of any LegitScript Portal (the "Website"), and
  to ensure a clear understanding of the relationship and obligations arising from your use of our Website, we have
  created a <a href="https://www.legitscript.com/privacy-policy/" target="_blank">Privacy Policy</a> and a set of Terms
  and Conditions for Use (“Terms and Conditions”) that govern your
  use of our Website. Our Privacy Policy explains how we protect and treat contact and other information you may
  provide to us through the Website, and our Terms and Conditions govern your use of our Website generally.
</p>
<p>
  Your use of our Website constitutes your agreement to follow and be bound by these Terms and Conditions and <a href="https://www.legitscript.com/privacy-policy/"
    target="_blank">Privacy
    Policy</a>. If you do not agree to the terms and conditions of these agreements, you are not authorized to use our
  Website.
</p>
<p>If you have any questions or comments regarding our Website, you may contact us as provided by our <a href="https://www.legitscript.com/privacy-policy/"
    target="_blank">Privacy Policy</a>.</p>

<h2>LegitScript LLC Terms and Conditions</h2>
<ol>
  <li>
    <label>AGREEMENT.</label> These Terms and Conditions (the "Terms and Conditions") govern your use of the Website,
    your receipt of any services through the Website, subject to the provisions of Section 14.0. These Terms and
    Conditions constitute an agreement between you and LegitScript and impose legal obligations upon you. By accessing
    our Website, you are acknowledging that you have read and understood these Terms and Conditions and agree to be
    legally bound by them. You accept these Terms and Conditions by accessing or using the Website in any manner, even
    if you do not create an account with the Website. You represent and warrant that you are at least 18 years of age
    and of legal competence to enter into this agreement. If you are using the Website on behalf of your employer or a
    third party, you represent and warrant that you are authorized to accept these Terms and Conditions on behalf of
    your employer or that third party and that your employer or third party will be responsible for any charges
    incurred by use of the Website. In addition to other agreements and acknowledgements stated herein, you explicitly
    agree and acknowledge that your use of the Website or Information will not violate any applicable privacy laws,
    including but not limited to the European Union’s General Data Protection Regulation.<br>
    In addition, if you register as a user of any of the features of our Website, during the registration process (and
    from time to time as LegitScript may require) you will be prompted to click an "I Agree to the Terms and Conditions
    and Privacy Policy," "Submit" or similar button, which further confirms your agreement to be legally bound by these
    Terms and Conditions. <br>
  </li>
  <li>
    <label>CONDITIONS ON PARTICIPATION.</label> You agree to be bound by and abide by all of the provisions of these
    Terms and Conditions. You agree not to use the Website or any data obtained from the Website to (i) violate any law
    or regulations, third party rights, or LegitScript policies, (ii) publish or distribute false, inaccurate,
    misleading, defamatory, infringing, or otherwise libelous content, (iii) except as authorized by Section 3 below,
    copy, modify, or distribute data or content from the Website or LegitScript copyrights or trademarks, or in any way
    use the Website or data from the Website to compete with LegitScript or otherwise interfere with LegitScript’s
    business operations or reputation, (iv) present or share the data or information received via the Website without
    LegitScript’s prior consent, or (v) sell, dispose, trade, license, or otherwise transfer any information received
    through the Website, which will be used only for your internal business purposes. All designs, engineering details,
    and any other technical, financial, marketing, commercial, or other information relating to LegitScript, the
    Website, or Information shall be considered confidential information, and you agree not to reproduce, copy, or
    disclose such information to any third party, without LegitScript’s prior written consent.
  </li>
  <li><label>OWNERSHIP OF INFORMATION; DOWNLOADING OF MATERIALS; RETRANSMISSION AND REDISTRIBUTION OF DATA.</label> The
    Website is made available solely as a convenience or service to you for general informational purposes for your
    internal business use, subject at all times to these Terms and Conditions. Unless otherwise noted, all right, title
    and interest in the Website, and information made available in the Website ("Information"), in all languages,
    formats and media throughout the world, including all copyrights, and trademarks, trade secrets and/or proprietary
    information therein, are and shall continue to be the exclusive property of LegitScript, its subsidiaries,
    affiliates or licensors, or used under principles of "fair use." <br>
    Other than connecting to the Website by http request using a web browser, you may not attempt to access
    LegitScript's servers or the Website by any means, unless explicitly authorized in writing by LegitScript. You are
    not authorized and agree not to access or query the Website through the use of electronic processes that are
    high-volume and automated. In particular, you agree not to and are prohibited from scraping, crawling, data-mining,
    or using any robot, spider, or other automatic device to send queries to LegitScript's servers or the Website. You
    may not use the Website to compile data for use by any commercial entity. <br>
    Certain materials may be made available for download from the Website. Subject to these Terms and Conditions,
    LegitScript grants you permission to download and copy such materials solely for your internal use. Without
    limiting any other right or remedy available to LegitScript, at law or in equity, the permission herein granted
    will automatically terminate if and when you cease a business relationship with LegitScript or in the event you
    breach the terms of this paragraph and, in such event you must destroy immediately all downloaded materials in your
    possession, custody or control. <br>
    EXCEPT AS OTHERWISE EXPRESSLY PERMITTED BY THE PRECEDING PARAGRAPH, YOU AGREE NOT TO REPRODUCE, RETRANSMIT,
    DISSEMINATE, SELL, DISTRIBUTE, PUBLISH, BROADCAST, CIRCULATE OR PROVIDE THE INFORMATION OR SERVICES OBTAINED FROM
    THE WEBSITE IN ANY MANNER OR FOR ANY PURPOSES (PERSONAL OR BUSINESS) WITHOUT THE PRIOR EXPRESS WRITTEN CONSENT OF
    LEGITSCRIPT. <br>
    You further agree not to use the Website or Information for any unlawful purpose and agree to comply with all
    requests by LegitScript to protect the respective rights of LegitScript, its subsidiaries, affiliates, or licensors
    in the Website in the Information. Use of the Website or Information to attempt to bypass online advertising
    policies or filters is strictly prohibited. If such activity is suspected, LegitScript retains the right to disable
    the user account and notify any affected third parties. <br>
  </li>
  <li><label>REGARDING CONTENT.</label> LegitScript cannot and does not guarantee that the Website or Information
    (including information obtained from third parties) is accurate, reliable, current, complete or appropriate for
    your needs. Due to various factors, including the inherent possibility of human and mechanical error, the accuracy,
    completeness, timeliness, results obtained from use, or correct sequencing of the Information, is not and cannot be
    guaranteed by LegitScript or by any third parties creating or transmitting such Information. In the event you
    disagree with any content on the Website or other Information, you agree to inform LegitScript of such
    disagreement, provide LegitScript any requested documentation, and engage in good faith dialogue with LegitScript
    before seeking other remedies, legal or otherwise. </li>
  <li><label>DISCLAIMER REGARDING LINKS AND OTHER THIRD PARTY CONTENT.</label> From time to time, the Website may
    provide you with the ability to access certain content from third parties through links to the websites of such
    other parties. When you access such third party content, YOU PROCEED AT YOUR OWN RISK. LegitScript does not
    guarantee that it will screen such content or that such content will be accurate, free from errors, consistent with
    what it purports to be or appropriate to fit your needs. You agree that LegitScript shall not be held liable for
    any activities that occur on any website you access through links on the Website. We provide these links as a
    convenience, and do not endorse the content or services offered by these other sites. </li>
  <li><label>INFORMATION YOU DOWNLOAD.</label> LegitScript cannot and does not guarantee or warrant that information
    that may be available for downloading through any LegitScript website, including but not limited to the Website,
    will be free of infection or viruses, worms, Trojan horses or other code that manifests contaminating or
    destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy your
    particular requirements for accuracy of information input and output, and for maintaining a means external to
    LegitScript for the reconstruction of any lost information. </li>
  <li><label>COMPLAINTS OVER PERCEIVED INFRINGEMENT.</label> LegitScript respects intellectual property rights and will
    use efforts that are commercially reasonable in light of our resources to accommodate generally accepted technical
    measures used by copyright owners to identify and protect their copyrighted works. If you believe that materials
    posted on our Website infringe your rights under copyright law in specific materials (collectively, a "Work"), you
    must direct your concerns to our legal department: Email: legal@legitscript.com. You agree to contact our legal
    department and engage in good faith dialogue before seeking other remedies, legal or otherwise. </li>
  <li><label>NO ADVICE OR SOLICITATION.</label> The Information is for informational purposes only and should not be
    used or construed as an approval, endorsement, or recommendation of any product or company by LegitScript, its
    subsidiaries, affiliates, officers, directors, employees or agents. You acknowledge and agree that neither the
    Website nor any of the Information is intended to supply medical, legal, regulatory, or health advice. You
    acknowledge and agree that the use of the Website and Information, and any decisions made in reliance upon the
    Website and Information, are made at your own risk. </li>
  <li>
    <label>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY.</label> You agree that neither LegitScript, its
    subsidiaries, affiliates, officers, directors, employees, agents or representatives shall have any liability,
    contingent or otherwise, for the truthfulness or accuracy of the Website or the truthfulness, accuracy, timeliness,
    completeness or correct sequencing of the Information, or for any decision made or action taken by you in reliance
    upon such Information or the Website, or for any other aspect of the Information or Website. You agree that you
    will not exploit or use the Information or Website in any way against LegitScript, its subsidiaries, affiliates,
    officers, directors, employees, agents, or representatives. <br>
    THE WEBSITE AND INFORMATION ARE PROVIDED "AS IS" AND “AS AVAILABLE.” TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
    LAW, THERE IS NO WARRANTY OF MERCHANTABILITY, NO WARRANTY OF FITNESS FOR A PARTICULAR USE AND NO OTHER WARRANTY OF
    ANY KIND, EXPRESS OR IMPLIED, REGARDING THE INFORMATION OR ANY ASPECT OF THE SERVICES (INCLUDING BUT NOT LIMITED TO
    INFORMATION ACCESS) OR THE WEBSITE. YOU RECOGNIZE THAT THE ACCURACY OF THE INFORMATION SHOULD BE INDEPENDENTLY
    EVALUATED AND CHECKED BEFORE YOU RELY ON IT. YOU EXPRESSLY AGREE THAT YOUR USE OF, OR INABILITY TO USE, THE WEBSITE
    OR INFORMATION IS AT YOUR SOLE RISK.<br>
    IN NO EVENT WILL LEGITSCRIPT BE LIABLE TO YOU, YOUR CUSTOMERS OR ANY OTHER PARTY FOR ANY DIRECT, INDIRECT,
    INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES (INCLUDING BUT NOT LIMITED TO, LOST PROFITS OR
    DAMAGES THAT RESULT FROM INCONVENIENCE, DELAY OR LOSS OF THE USE OF THE SERVICES), OR CLAIMS ARISING IN TORT
    (INCLUDING NEGLIGENCE), CONTRACT, OR OTHERWISE, EVEN IF LEGITSCRIPT HAS BEEN ADVISED OF OR ARE OTHERWISE AWARE OF
    THE POSSIBILITY OF ANY OF THE FOREGOING. THE FOREGOING LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED
    REMEDY SPECIFIED IN THIS AGREEMENT IS FOUND TO HAVE FAILED IN ITS ESSENTIAL PURPOSE.<br>
    You acknowledge that documents posted to and content available at the Website may contain computer viruses. Should
    you download any such materials from the Website, IT IS YOUR RESPONSIBILITY TO PERFORM ANY SCANNING OR OTHER
    ANTI-VIRUS PRECAUTIONS AS YOU DEEM NECESSARY. UNDER NO CIRCUMSTANCES WILL LEGITSCRIPT BE RESPONSIBLE FOR ANY LOSS
    OR DAMAGE CAUSED BY ANY MATERIALS DOWNLOADED FROM THE SITE AND YOU ASSUME FULL RESPONSIBILITY FOR ALL COSTS
    ASSOCIATED WITH ALL NECESSARY SERVICING OR REPAIRS OF ANY EQUIPMENT OR DATA YOU USE IN CONNECTION WITH OUR SITE.
    <br>
  </li>
  <li><label>INDEMNIFICATION.</label> You agree to indemnify and hold harmless LegitScript, its subsidiaries,
    affiliates and any respective officers, directors, employees, agents and representatives from and against any and
    all claims, losses, judgments, liability, costs and expenses (including but not limited to attorneys' fees) arising
    from or relating to your account, data provided to LegitScript, use of the Website or the Information, and/or your
    violation of these Terms and Conditions, your violation of any other LegitScript policy, or any third party's
    rights, including but not limited to copyright, trademark, trade secret proprietary, and privacy rights. </li>
  <li><label>MODIFICATION AND TERMINATION OF WEBSITE; MODIFICATION OF TERMS AND CONDITIONS.</label> Notwithstanding any
    provision in these Terms and Conditions, LegitScript reserves the right, in its sole discretion, at any time, to
    change or modify from time to time and/or terminate (i) this Website, (ii) any particular services made available
    via the Website, or (iii) these Terms and Conditions. Your use of our Website following any such change constitutes
    your agreement to follow and be bound by the Terms and Conditions, as changed. Please check back often for updates.
  </li>
  <li><label>PERSONAL DATA.</label> Your provision of personally identifiable information, and our use of such
    information, is governed by our Privacy Policy. Please read that policy to learn how we handle and safeguard such
    information. You represent and warrant that you have any necessary consent for provision of personal data to
    LegitScript.</li>
  <li><label>FORCE MAJEURE.</label> In addition to applicable disclaimers stated above, LegitScript's performance under
    these Terms and Conditions shall be excused in the event of interruption and/or delay due to causes beyond its
    reasonable control, such as acts of God, acts of any government, war or other hostility, civil disorder, the
    elements, fire, earthquake, explosion, acts of terrorism, power failures, equipment failure, industrial or labor
    dispute, acts of Data Providers or other third party information providers, third party software or communication
    method interruption. </li>
  <li><label>ENTIRE AGREEMENT.</label> These Terms and Conditions and Privacy Policy relate only to this Website and
    any other applicable LegitScript website, and embody the entire understanding between the parties with respect to
    this Website and supersede any and all prior representations and agreements, if any, oral or written, with respect
    to this Website. In the event of any conflict between these Terms and Conditions and/or Privacy Policy and any
    separate agreement you have executed or otherwise entered into regarding this Website, the terms of such separate
    agreement shall control. </li>
  <li><label>SEVERABILITY.</label> Should any provision of these Terms and Conditions or any provision of our Privacy
    Policy be held to be void, invalid, unenforceable or illegal by a court, such provision shall be valid and
    enforceable to the extent permitted by applicable law, and the validity and enforceability of the other provisions
    shall not be affected thereby. Failure of any party to enforce any provision of these Terms and Conditions or of
    the Privacy Policy shall not constitute or be construed as a waiver of such provision or of the right to enforce
    such provision. </li>
  <li><label>CHOICE OF LAW; CHOICE OF FORUM.</label> These Terms and Conditions and our Privacy Policy shall be
    governed by U.S. federal law or the laws of the State of Oregon, U.S.A., without regard to its conflict of law
    principles. In any action arising under these Terms and Conditions or our Privacy Policy, you consent to the
    jurisdiction and venue of the state and federal courts of Oregon, U.S.A. </li>
  <li><label>ASSIGNMENT.</label> These Terms and Conditions shall not be assignable by you, either in whole or in part.
    LegitScript reserves the right to assign its rights and obligations under these Terms and Conditions. </li>
  <li><label>SURVIVAL.</label> Sections 1 (Agreement), 3 (Ownership of Information; Downloading of Materials;
    Retransmission and Redistribution of Data), 4 (Disclaimer regarding Content), 5 (Disclaimer regarding Links and
    Other Third Party Content), 6 (Information you Download), 8 (No Advice or Solicitation), 9 (Disclaimer of
    Warranties and Limitation of Liability), 10 (Indemnification), 13 (Force Majeure), 14 (Entire Agreement),
    15(Severability), 16 (Choice of Law; Choice of Forum), 17 (Assignment), and this Section 18 of these Terms and
    Conditions shall survive the termination of these Terms and Conditions and shall apply indefinitely, as will any
    other provision which, in order to give it necessary effect, should survive termination or expiration. </li>
  <li><label>YOUR CONSENT.</label> By using our Website, you agree to comply with, and be bound by these Terms and
    Conditions. If you do not agree with some, or all of these Terms and Conditions, you are not authorized to visit
    our Website. </li>
  <li><label>CREDIT / DEBIT CARD PAYMENTS.</label> We do not store credit card details, nor do we share customer
    details with any Third Parties. All credit card payments for LegitScript's Product Subscription Service are
    processed by Stripe and are subject to Stripe's separate Term and Conditions. </li>
</ol>
<p style="font-weight: 400;">
  BY AGREEING TO THESE TERMS AND CONDITIONS YOU ACKNOWLEDGE AND UNDERSTAND THAT YOUR USAGE OF ANY DATA, INFORMATION, OR LEGITSCRIPT WEBSITE MAY BE MONITORED AND TRACKED. AT LEGITSCRIPT'S SOLE DISCRETION, IF LEGITSCRIPT SUSPECTS THAT YOU ARE MISUSING THIS DATA OR IN ANY WAY VIOLATING THESE TERMS AND CONDITIONS OR OTHER LEGITSCRIPT POLICIES, APPLICABLE LAWS, OR OTHER REGULATIONS, LEGITSCRIPT RESERVES THE RIGHT TO IMMEDIATELY TERMINATE YOUR ACCOUNT.
</p>