import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { CreateAccountRequest, PRODUCTS_OF_INSTEREST_CARDS } from '@ls/common-ts-models/';

import { NgForm } from '@angular/forms';
import { AccountService } from '../../../services';

@Component({
  selector: 'create-account-product',
  templateUrl: './create-account-product.component.html',
  styleUrls: ['../create-account.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateAccountProductComponent implements OnInit {
  public model: CreateAccountRequest;
  public destroyed$: Subject<boolean> = new Subject();
  public productsOfInterest = PRODUCTS_OF_INSTEREST_CARDS;

  @ViewChild('form') public form: NgForm;

  constructor(
    private accountService: AccountService,
    private router: Router,
  ) {}

  public ngOnInit() {
    if (!this.accountService.firstCreateFormComplete()) this.router.navigate(['create-account']);
    this.model = this.accountService.userCreationModel;
  }

  public back() {
    this.router.navigate(['create-account']);
  }

  public onClick(product) {
    this.model.productOfInterest = product;
  }

  public submit() {
    if (!this.model.productOfInterest) return;
    this.accountService.userCreationModel = this.model;
    this.router.navigate(['create-account', 'info']);
  }
}
