<ng-container *ngIf="pricing; else loading">
  <h1>Pricing Information</h1>
  <p><span>Pricing is on a per-website basis. </span>Our pricing comprises two parts: a one-time application fee and a recurring annual fee. For fees less than $20,000, your credit card will be charged a nonrefundable application fee after completing this enrollment form. For fees more than $20,000, you will be issued an invoice for a bank ACH transfer for a nonrefundable application fee after completing this enrollment form. If your application is approved for LegitScript Certification, the annual fee will be automatically charged on the date of approval and will recur via credit card or invoice on an annual basis.</p>
  <h2>Category A</h2>
  <p>
    This category is for informational websites and for pharmacies that have a very limited internet presence, only accepting refills (but not new prescriptions) online. Business models applicable to this category include:
  </p>
  <ul>
    <li>Pharmacies that do not operate a website or pharmacies that have a website without a mechanism for requesting a prescription or pharmaceuticals AND have only one dispensing location, license, or shipping jurisdiction.</li>
    <li>Pharmacies that have a website that facilitates refills or transfers (but not new prescriptions), AND have only one dispensing location, license, or shipping jurisdiction.</li>
  </ul>
  <h2>Category B</h2>
  <p>
    This category is the default category for most business models, and includes: websites that accept refills, transfers, or new prescriptions; hospitals; pharmaceutical manufacturers; or pharmacies that fill via mail order or some other form of remote ordering service. This is also for pharmacies that would otherwise be in Category A, but have two or more dispensing locations, licenses, or shipping jurisdictions.
  </p>
  <ul>
    <li>Traditional internet pharmacies (prescription or OTC)</li>
    <li>Mail-order pharmaceuticals or medicines</li>
    <li>Prescription drug price-comparison services</li>
    <li>Doctor clinics</li>
    <li>Pharmaceutical manufacturers (including virtual manufacturers)</li>
    <li>Any pharmacy or clinic with two or more dispensing locations, licenses, or shipping jurisdictions</li>
    <li>Discount card / Cost-savings websites</li>
    <li>Hospitals</li>
  </ul>
  <h2>Category C</h2>
  <p>This category is reserved for complex business models that take more time or expertise to review, such as sterile compounding, nuclear pharmacy, or online veterinary pharmacies.</p>
  <ul>
    <li>Telemedicine services</li>
    <li>Complex pharmacy practice</li>
    <li>Veterinary pharmacies</li>
    <li>Prescription eyeglasses and contact lenses merchants</li>
  </ul>
  <h2>Standard Pricing</h2>
  <table class="pricing-modal-table">
    <tr>
      <th>Category</th>
      <th>Application Fee<span> Per Website</span></th>
      <th>Annual Fee<span> Per Website</span></th>
    </tr>
    <tr>
      <td>A</td>
      <td>${{ pricing.HEALTHCARE_A.APPLICATION }}</td>
      <td>${{ pricing.HEALTHCARE_A.SUBSCRIPTION }}</td>
    </tr>
    <tr>
      <td>B</td>
      <td>${{ pricing.HEALTHCARE_B.APPLICATION }}</td>
      <td>${{ pricing.HEALTHCARE_B.SUBSCRIPTION }}</td>
    </tr>
    <tr>
      <td>C</td>
      <td>${{ pricing.HEALTHCARE_C.APPLICATION }}</td>
      <td>${{ pricing.HEALTHCARE_C.SUBSCRIPTION }}</td>
    </tr>
  </table>
  <h2>Probationary Category</h2>
  <p>The purpose of probationary status is to provide a path forward for pharmacies and other healthcare merchants with a past history of significant compliance issues. Probationary certification will be granted in cases where LegitScript determines, through the application review, that a merchant is now in compliance with our standards. The application fee for probationary status is the same as regular applications but the annual fee is higher because we monitor the applicant’s activities in more depth and on a more frequent cadence. For more information on probationary status, see our <a href="https://www.legitscript.com/blog/2017/08/legitscript-pharmacy-certification-to-include-probationary-status/" target="_blank">explanatory blog post</a>.</p>
  <h2>Probationary Pricing</h2>
  <table class="pricing-modal-table">
    <tr>
      <th>Category</th>
      <th>Application Fee Per Website</th>
      <th>Annual Fee Per Website</th>
    </tr>
    <tr>
      <td>A</td>
      <td>${{ pricing.HEALTHCARE_A_PROBATIONARY.APPLICATION }}</td>
      <td>${{ pricing.HEALTHCARE_A_PROBATIONARY.SUBSCRIPTION }}</td>
    </tr>
    <tr>
      <td>B</td>
      <td>${{ pricing.HEALTHCARE_B_PROBATIONARY.APPLICATION }}</td>
      <td>${{ pricing.HEALTHCARE_B_PROBATIONARY.SUBSCRIPTION }}</td>
    </tr>
    <tr>
      <td>C</td>
      <td>${{ pricing.HEALTHCARE_C_PROBATIONARY.APPLICATION }}</td>
      <td>${{ pricing.HEALTHCARE_C_PROBATIONARY.SUBSCRIPTION }}</td>
    </tr>
  </table>
  <expedited-pricing-info></expedited-pricing-info>
</ng-container>
<ng-template #loading>
  Loading pricing...
</ng-template>
