import { AppState, UserSignOutAction, fnEntitlementsState } from '../../reducers';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Roles, AuthenticatedUser, ProductFamily } from '@ls/common-ts-models';
import { EntitlementsState, LocalStorageService } from '@ls/common-ng-components';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sidenav',
  styleUrls: ['./sidenav.component.scss'],
  templateUrl: './sidenav.component.html',
})
export class SidenavComponent implements OnInit, OnDestroy {
  public isOwner = false;
  public productsApiEnabled: boolean = environment.CONFIG.productsApiEnabled || false;
  public smallIconBreakpoint = '850px'; // For Firefox until this is fixed: https://github.com/angular/angular/issues/13947
  public hasPurchasedMonitoring = false;

  private destroyed$: Subject<boolean> = new Subject();
  private entitlements$: Observable<EntitlementsState>;
  public isNotCertificationUser = true;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private localStorageSvc: LocalStorageService,
  ) {}

  public ngOnInit() {
    this.store
      .select('authentication')
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe((authUser: AuthenticatedUser) => {
        if (authUser && authUser.roles) {
          this.isOwner = authUser.roles.includes(Roles.cpv2AccountOwner);
        }

        // all certification users should have either the cpv2UserSF or cpv2AccountOwnerSF role
        if (
          authUser &&
          (authUser.roles.includes(Roles.cpv2UserSF) || authUser.roles.includes(Roles.cpv2AccountOwnerSF))
        ) {
          this.isNotCertificationUser = false;
        }
      });

    this.entitlements$ = this.store.select(fnEntitlementsState).pipe(takeUntil(this.destroyed$.asObservable()));

    this.entitlements$.subscribe((entitlements) => {
      this.hasPurchasedMonitoring = !!(
        entitlements.products && entitlements.products[ProductFamily.MERCHANT_MONITORING]
      );
    });
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public signOutClick() {
    this.store.dispatch(UserSignOutAction({ location: '/login' }));
  }

  public usersClick() {
    this.router.navigate(['/users']);
  }
}
