import { Component, Input } from '@angular/core';

@Component({
  selector: 'cbd-website-pricing-info',
  templateUrl: './cbd-website-pricing-info.component.html'
})
export class CbdWebsitePricingInfoComponent {
  @Input() public pricing;
  @Input() public expeditedEnabled;
}
