import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BillingService,
  EntitlementsState,
  GenericNotificationAction,
  LocalStorageService,
  SeverityOptions,
} from '@ls/common-ng-components';
import { ProductFamily } from '@ls/common-ts-models';
import { of as observableOf, Observable } from 'rxjs';
import { catchError, take, map, skipWhile } from 'rxjs/operators';
import { AppState, fnEntitlementsState } from '../../../reducers';
import { BaseMMOpportunityGuard } from '../../../guards';

@Injectable()
export class MMEnrolledGuard extends BaseMMOpportunityGuard implements CanActivate, CanActivateChild {
  constructor(
    router: Router,
    localStorage: LocalStorageService,
    store: Store<AppState>,
    billingService: BillingService,
  ) {
    super(router, localStorage, store, billingService);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    if (!this.canActivateMerchantMonitoring()) {
      return observableOf(false);
    }
    return this.store.select(fnEntitlementsState).pipe(
      skipWhile((entitlements: EntitlementsState) => entitlements.pending),
      map((entitlements: EntitlementsState) => {
        if (entitlements.errorText) {
          throw new Error(entitlements.errorText);
        }

        const mmProduct = entitlements?.products?.[ProductFamily.MERCHANT_MONITORING];
        const opportunity = mmProduct
          ? entitlements.products[ProductFamily.MERCHANT_MONITORING].opportunity
          : undefined;

        let externalReferenceId;
        if (mmProduct?.externalReferenceId) {
          externalReferenceId = mmProduct.externalReferenceId;
        } else if (opportunity?.externalReferenceId) {
          externalReferenceId = opportunity.externalReferenceId;
        }

        const canActivate = this.getCanActivateFromOpportunity(opportunity, externalReferenceId);

        if (!canActivate) {
          this.navigateToRouteBasedOnOpportunity(opportunity, externalReferenceId);
        }

        return canActivate;
      }),
      take(1),
      catchError(() => {
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Error Loading Data',
            detail:
              'An issue occured while loading your data, please try again.  If the issue persists, please contact support.',
            sticky: false,
            blocking: false,
          }),
        );
        this.router.navigate(['/account/home']);
        return observableOf(false);
      }),
    );
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
