/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionCurrencyCodes } from './transactionCurrencyCodes';
import { TransactionStatuses } from './transactionStatuses';
import { WebsiteMonitoringStatus } from './websiteMonitoringStatus';
import { TransactionSources } from './transactionSources';
import { WebsiteCountries } from './websiteCountries';


export interface TransactionSearchResponse { 
    id?: string;
    legitscriptId?: string;
    accountId?: string;
    client?: string;
    clientSub?: string;
    websiteUrl?: string;
    category?: string;
    source?: TransactionSources;
    requestId?: string;
    transactionStatus?: TransactionStatuses;
    transactionDate?: string;
    analysisDate?: string;
    transactionDescription?: string;
    ccLast4?: string;
    transactionAmount?: number;
    transactionCurrency?: TransactionCurrencyCodes;
    transactionUSDAmount?: number;
    paymentWebsite?: string;
    merchantDescriptor?: string;
    merchantState?: string;
    merchantCountry?: WebsiteCountries;
    analysisNote?: string;
    internalNote?: string;
    monitoringStatus?: WebsiteMonitoringStatus;
    recordTags?: Array<any>;
    rightsHolder?: string;
    terminalId?: string;
    cardAcceptorId?: string;
    acquirerBin?: number;
    acquirerBid?: number;
    acquirerCountry?: WebsiteCountries;
    acquirerName?: string;
    acquirerRegion?: string;
    agent?: string;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    updatedBy?: string;
    deletedAt?: string;
}
export namespace TransactionSearchResponse {
}



