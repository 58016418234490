import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { LocalStorageService } from '@ls/common-ng-components';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';

@Injectable()
export class EnforceTermsGuard implements CanActivate, CanActivateChild {
  private localStorageSvc: LocalStorageService = new LocalStorageService();
  constructor(private router: Router, private store: Store<AppState>) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.localStorageSvc.getUser();

    if (user && !this.agreedToTerms(user)) {
      this.store.dispatch(
        GenericNotificationAction({
          severity: SeverityOptions.WARN,
          summary: 'Not Authorized',
          detail: 'You must agree to the terms of use before accessing this area',
          sticky: false,
          blocking: false
        })
      );
      this.router.navigate(['/loading'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    return true;
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  private agreedToTerms(user): boolean {
    return user.gdprConsentDate && user.portalTermsConsentDate;
  }
}
