/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionCurrencyCodes } from './transactionCurrencyCodes';
import { TransactionStatuses } from './transactionStatuses';
import { TransactionSources } from './transactionSources';


export interface UpdateTransactionRequest { 
    accountId?: string;
    requestId?: string;
    legitscriptId?: string;
    source?: TransactionSources;
    transactionStatus?: TransactionStatuses;
    transactionDate?: string;
    transactionAmount?: number;
    transactionCurrency?: TransactionCurrencyCodes;
    transactionUSDAmount?: number;
    transactionDescription?: string;
    transactionId?: string;
    ccLast4?: string;
    analysisDate?: string;
    analysisNote?: string;
    internalNote?: string;
    cardAcceptorId?: string;
    terminalId?: string;
    posConditionCode?: number;
    posEntryMode?: number;
    rightsHolder?: string;
    rightsHolderContactName?: string;
    rightsHolderContactPhone?: string;
    rightsHolderContactEmail?: string;
    rightsHolderContactAddress?: string;
    recordTags?: Array<any>;
}
export namespace UpdateTransactionRequest {
}



