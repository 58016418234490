import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class MaintenanceModeGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) {}

  public canActivate(): boolean {
    if (environment.MAINTENANCE_MODE) {
      this.router.navigate(['/maintenance-in-progress']);
      return false;
    }
    return true;
  }

  public canActivateChild(): boolean {
    return this.canActivate();
  }
}
