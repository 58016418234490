import { Component } from '@angular/core';

@Component({
  selector: 'login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss']
})
export class LoginHeaderComponent {
  public openSupport() {
    window.open('https://help.legitscript.com', '_blank');
  }
}
