<div class="documents-container">
  <div class="documents">
    <div class="document-header">
      <div class="title">Documents ({{testTransaction?.attachments.length}})</div>
      <div><span (click)="onDownloadAllClick()" (keyup.enter)="onDownloadAllClick()" tabindex="0">Download all</span></div>
    </div>
    <div class="document" *ngFor="let document of testTransaction?.attachments" (click)="onDownloadClick(document.id)" (keyup.enter)="onDownloadClick(document.id)" tabindex="0">
      <span>
        {{document.name | midTruncate:29:7}}
      </span>
      <img src="assets/svg/icons/download.svg" alt="Download Image">
    </div>
  </div>
</div>
