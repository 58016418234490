<!-- eslint-disable @angular-eslint/template/alt-text -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<generic-enroll [inputFormGroup]="cbdFormGroup" [pricing]="pricing" [pricingInfo]="pricingInfo" (validateConfirmations)="validateConfirmations()" [validConfirms]="validConfirms" (validate)="validate(false)" [preventPayment]="false" (calculatePricing)="calculatePricing()" [questionnaireInfo]="questionnaireInfo" [expeditedEnabled]="expeditedEnabled">
  <ng-container class="questions">
    <ng-container *ngIf="questions | async as q; else loadingQuestions">
      <div class="question-number">
        <span class="question-number">2</span>
      </div>
      <fieldset class="span2">
        <div class="question-text">
          <strong>Would you like to certify a CBD product or a CBD website?</strong>
          <br><br>
        </div>
        <div class="radio-group">
          <input value="product" name="certificationType" id="certificationTypeProductAnswer" type="radio" [formControl]="certificationType" required>
          <label for="certificationTypeProductAnswer">Product</label>
          <input value="website" name="certificationType" id="certificationTypeWebsiteAnswer" type="radio" [formControl]="certificationType">
          <label for="certificationTypeWebsiteAnswer">Website</label>
          <span *ngIf="!certificationType.dirty && !firstTry" class="error">* Required</span>
        </div>
      </fieldset>

      <ng-container *ngIf="certificationType.dirty; else pleasePickType">
        <ng-container *ngIf="certificationType.value==='product'; else website">
          
          <div class="question-number">
            <span class="question-number">3</span>
          </div>
          <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.product.productSubmissionNumber"></div>
            <div class="question-text">
              <p><a (click)="uniqueProductModal.showDialog()">What is a product?</a></p>
            </div>
            <input type="number" style="margin-top: 0" [formControl]="productSubmissionNumber">
            <span *ngIf="!productSubmissionNumber.valid && !firstTry" class="error">
              <span *ngIf="productSubmissionNumber.hasError('required')">* Required</span>
              <span *ngIf="productSubmissionNumber.hasError('max')">* Number of products must not be greater than 1000.</span>
              <span *ngIf="productSubmissionNumber.hasError('min')">* Number of products must be greater than 0.</span>
              <span *ngIf="productSubmissionNumber.hasError('pattern')">* Please enter only digits 0-9.</span>
            </span>
          </fieldset>

          <div class="question-number">
            <span class="question-number">4</span>
          </div>
          <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.product.productSupplements"></div>
            <div class="radio-group">
              <input value="yes" name="productSupplements" id="productSupplementsYesAnswer" type="radio" [formControl]="productSupplements" required>
              <label for="productSupplementsYesAnswer">Yes</label>
              <input value="no" name="productSupplements" id="productSupplementsNoAnswer" type="radio" [formControl]="productSupplements">
              <label for="productSupplementsNoAnswer">No</label>
              <span *ngIf="!productSupplements.dirty && !firstTry" class="error">* Required</span>
            </div>
            <div *ngIf="productSupplements.value === 'yes'" class="errorBox errorBorder error">
              Unfortunately, dietary supplements containing CBD are not in scope for LegitScript CBD Product Certification at this time.
            </div>
          </fieldset>

          <div class="question-number">
            <span class="question-number">5</span>
          </div>
          <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.product.productEdibles"></div>
            <div class="radio-group">
              <input value="yes" name="productEdibles" id="productEdiblesYesAnswer" type="radio" [formControl]="productEdibles" required>
              <label for="productEdiblesYesAnswer">Yes</label>
              <input value="no" name="productEdibles" id="productEdiblesNoAnswer" type="radio" [formControl]="productEdibles">
              <label for="productEdiblesNoAnswer">No</label>
              <span *ngIf="!productEdibles.dirty && !firstTry" class="error">* Required</span>
            </div>
            <div *ngIf="productEdibles.value === 'yes'" class="errorBox errorBorder error">
              Unfortunately, edible CBD products are not in scope for LegitScript CBD Product Certification at this time.
            </div>
          </fieldset>


          
        </ng-container>

        <ng-template #website>
          <div class="question-number">
            <span class="question-number">3</span>
          </div>
          <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.website.websiteDirect"></div>
            <div class="radio-group">
              <input value="yes" name="websiteDirect" id="websiteDirectYesAnswer" type="radio" [formControl]="websiteDirect" required>
              <label for="websiteDirectYesAnswer">Yes</label>
              <input value="no" name="websiteDirect" id="websiteDirectNoAnswer" type="radio" [formControl]="websiteDirect">
              <label for="websiteDirectNoAnswer">No</label>
              <span *ngIf="!websiteDirect.dirty && !firstTry" class="error">* Required</span>
            </div>
            <div *ngIf="websiteDirect.value === 'yes'">
              Please note, to be eligible for LegitScript Certification, all CBD products sold to consumers on your behalf must be LegitScript-certified CBD Products.
            </div>
          </fieldset>

          <div class="question-number">
            <span class="question-number">4</span>
          </div>
          <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.website.websiteSubmissionNumber"></div>
            <input type="number" style="margin-top: 0" [formControl]="websiteSubmissionNumber">
            <span *ngIf="!websiteSubmissionNumber.valid && !firstTry" class="error">
              <span *ngIf="websiteSubmissionNumber.hasError('required')">* Required</span>
              <span *ngIf="websiteSubmissionNumber.hasError('max')">* Number of websites must not be greater than 1000.</span>
              <span *ngIf="websiteSubmissionNumber.hasError('min')">* Number of websites must be greater than 0.</span>
              <span *ngIf="websiteSubmissionNumber.hasError('pattern')">* Please enter only digits 0-9.</span>
            </span>
          </fieldset>

          <div class="question-number">
            <span class="question-number">5</span>
          </div>
          <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.website.websiteEdibles"></div>
            <div class="radio-group">
              <input value="yes" name="websiteEdibles" id="websiteEdiblesYesAnswer" type="radio" [formControl]="websiteEdibles" required>
              <label for="websiteEdiblesYesAnswer">Yes</label>
              <input value="no" name="websiteEdibles" id="websiteEdiblesNoAnswer" type="radio" [formControl]="websiteEdibles">
              <label for="websiteEdiblesNoAnswer">No</label>
              <span *ngIf="!websiteEdibles.dirty && !firstTry" class="error">* Required</span>
            </div>
            <div *ngIf="websiteEdibles.value === 'yes'" class="errorBox errorBorder error">
              Unfortunately, we are unable to certify organizations that sell ingestible or edible CBD products.
            </div>
          </fieldset>

          <div class="question-number">
            <span class="question-number">6</span>
          </div>
          <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.website.websiteSupplements"></div>
            <div class="radio-group">
              <input value="yes" name="websiteSupplements" id="websiteSupplementsYesAnswer" type="radio" [formControl]="websiteSupplements" required>
              <label for="websiteSupplementsYesAnswer">Yes</label>
              <input value="no" name="websiteSupplements" id="websiteSupplementsNoAnswer" type="radio" [formControl]="websiteSupplements">
              <label for="websiteSupplementsNoAnswer">No</label>
              <span *ngIf="!websiteSupplements.dirty && !firstTry" class="error">* Required</span>
            </div>
            <div *ngIf="websiteSupplements.value === 'yes'" class="errorBox errorBorder error">
              Unfortunately, we are unable to certify organizations that sell dietary supplements which contain CBD at this time.
            </div>
          </fieldset>

          <div class="question-number">
            <span class="question-number">7</span>
          </div>
          <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.website.websiteTHC"></div>
            <div class="radio-group">
              <input value="yes" name="websiteTHC" id="websiteTHCYesAnswer" type="radio" [formControl]="websiteTHC" required>
              <label for="websiteTHCYesAnswer">Yes</label>
              <input value="no" name="websiteTHC" id="websiteTHCNoAnswer" type="radio" [formControl]="websiteTHC">
              <label for="websiteTHCNoAnswer">No</label>
              <span *ngIf="!websiteTHC.dirty && !firstTry" class="error">* Required</span>
            </div>
            <div *ngIf="websiteTHC.value === 'yes'" class="errorBox errorBorder error">
              Unfortunately, we are unable to certify organizations that sell THC products at this time.
            </div>
          </fieldset>

          <div class="question-number">
            <span class="question-number">8</span>
          </div>
          <fieldset class="span2">
            <div class="question-text" [innerHtml]="q.website.websiteCertifiedProducts"></div>
            <p>
              <a class="price-faq-link mb30" (click)="certifiedProductModal.showDialog()">
                LegitScript Certified Products
              </a>
            </p>
            <div class="radio-group">
              <input value="yes" name="websiteCertifiedProducts" id="websiteCertifiedProductsYesAnswer" type="radio" [formControl]="websiteCertifiedProducts" required>
              <label for="websiteCertifiedProductsYesAnswer">Yes</label>
              <input value="no" name="websiteCertifiedProducts" id="websiteCertifiedProductsNoAnswer" type="radio" [formControl]="websiteCertifiedProducts">
              <label for="websiteCertifiedProductsNoAnswer">No</label>
              <span *ngIf="!websiteCertifiedProducts.dirty && !firstTry" class="error">* Required</span>
            </div>
            <div *ngIf="websiteCertifiedProducts.value === 'yes'">
              If you are unsure, please contact the product’s manufacturer to determine the certification status of the CBD product in question.
            </div>
            <div *ngIf="websiteCertifiedProducts.value === 'no'" class="errorBox errorBorder error">
              Unfortunately, we are unable to certify merchant organizations that sell CBD products lacking LegitScript Certification.
            </div>
          </fieldset>

        </ng-template>
      </ng-container>

      <ng-template #pleasePickType>
        <div>&nbsp;</div>
        <p class="span2" style="padding-top: 2em">
          Please complete the questions above to continue
          <br><br><br>
        </p>
      </ng-template>
    </ng-container>

    <ng-template #loadingQuestions>
      <div *ngIf="!apiError" class="row4 span2 mb30" style="margin-top: 4em; text-align: center">
        Loading questionnaire...
        <span class="floating-spinner-container">
          <p-progressSpinner [style]="{ width: '1.5em', height: '1.5em' }"></p-progressSpinner>
        </span>
      </div>
    </ng-template>
    <div class="api-error span3" *ngIf="apiError">
      An unknown error occurred loading the tier selection questionnaire. Please try again later or contact support.
    </div>
  </ng-container>

  <ng-container class="pricing-info">
    <a *ngIf="certificationType.dirty" class="price-faq-link mb30" (click)="pricingModal.showDialog()">How is my pricing category determined?</a>

    <legitscript-modal #pricingModal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=800 height=auto>
      <div *ngIf="pricing$ | async as prices; else loadingPrices" class="dialog-body" style="overflow:auto; height: 75vh; text-align: left;">
        <cbd-product-pricing-info *ngIf="certificationType.value==='product'; else websitePricing" [pricing]="prices.product" [expeditedEnabled]="expeditedEnabled"></cbd-product-pricing-info>
        <ng-template #websitePricing>
          <cbd-website-pricing-info [pricing]="prices.website" [expeditedEnabled]="expeditedEnabled"></cbd-website-pricing-info>
        </ng-template>
      </div>
      <div class="dialog-footer">
        <button type="button" (click)="pricingModal.hideDialog()" label="Yes">Dismiss</button>
      </div>
      <ng-template #loadingPrices>
        Loading prices...
      </ng-template>
    </legitscript-modal>

  </ng-container>

  <ng-container class="terms">
    <cbd-terms></cbd-terms>
  </ng-container>

  <ng-container class="terms-data">
    <cbd-data-terms></cbd-data-terms>
  </ng-container>

  <ng-container class="intro">
    <h1>You’re one step closer to unlocking the benefits of CBD Certification</h1>
    <p class="row1 span2">
      Once you are certified, you'll be eligible to run ads. Note that once you are certified, ongoing monitoring is required to maintain your certification status.
    </p>
    <section class="intro">
      <picture>
        <img class="product-logo" src="../assets/img/certification-cbd-icon.png" />
      </picture>
      <div>
        <div class="list-title">Applying for CBD Certification is straightforward:</div>
        <ul>
          <li><a (click)="faqModal.showDialog()">Review the FAQ</a> to determine if your business is in scope</li>
          <li>Complete the application questionnaire</li>
          <li>We’ll review your application against certification standards and get in touch if we have questions or need clarification</li>
          <li>We’ll inform you of the outcome based on the information provided</li>
          <li>Approved merchants receive a unique seal of approval and will appear in LegitScript’s searchable public directory of legitimate providers</li>
          <li>Approved products receive a product seal of approval</li>
        </ul>
      </div>
    </section>
  </ng-container>
</generic-enroll>

<legitscript-modal #uniqueProductModal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=800 height=auto>
  <div class="dialog-body" style="overflow:auto; height:25vh">
    <h1>What is a Product?</h1>
    <p>Products are certified by their UPC/GTIN (Universal Product Number/Global Trade Item Number) code. Once a product’s UPC is certified, all items registered by that number are considered LegitScript-certified. More information about UPC and GTIN can be found at <a href="https://www.gs1us.org/upcs-barcodes-prefixes" target="_blank">gs1us.org</a>. Don’t have a UPC number for your products yet? Refer to <a href="https://www.gs1us.org/upcs-barcodes-prefixes/get-started-guide" target="_blank">this barcode setup guide to get started</a>.</p>
    <p>A CBD product is considered to be a unique product, requiring separate certification, if the product has a unique UPC. Similar products with separate UPCs/GTINs require separate certifications. For example, if a two-ounce jar of CBD lotion has a UPC of 01234567890 and a four-ounce jar of the same formula has a UPC of 01234567891, both of these products should be submitted for review as unique products. All variations of a product should be submitted for review separately if the product UPC codes differ.</p>
  </div>
  <div class="dialog-footer">
    <button type="button" (click)="uniqueProductModal.hideDialog()">Close</button>
  </div>
</legitscript-modal>

<legitscript-modal #faqModal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=800 height=auto>
  <div class="dialog-body" style="overflow:auto; height:75vh">
    <h1>Frequently Asked Questions</h1>
    <h2>I sell CBD products on my website. Can I get certified?</h2>
    <p>Your website may qualify for LegitScript CBD Website Certification if all of your CBD products are LegitScript-certified. Refer to the list of certified products on the application pricing page. If any of your websites sell CBD products that are not LegitScript-certified, your website will not qualify for certification.</p>
    <h2>How do I find out if the CBD products I sell are LegitScript-certified?</h2>
    <p>Please refer to the certified product list on the application pricing page. If your products are not listed here, please check with the product’s manufacturer to determine if LegitScript Certification is in progress. Only manufacturers or private labelers may submit products for certification.</p>
    <h2>My organization manufactures CBD products and sells them on our website. How do I become LegitScript-certified?</h2>
    <p>First, submit your products for CBD Product Certification. If they are approved, you may apply separately for CBD Merchant Certification. Please note, to be approved for CBD Merchant Certification, all CBD products sold on your website/s must be LegitScript-certified.</p>
    <h2>I sell CBD dietary supplements or foods. Can I become LegitScript Certified?</h2>
    <p>The FDA currently considers CBD to be an impermissible ingredient in foods and dietary supplements. Merchants that sell edible CBD products do not qualify for CBD Website Certification at this time.</p>
    <h2>I manufacture CBD dietary supplements. Can I submit other products for LegitScript Certification?</h2>
    <p>While edible CBD products are not eligible for CBD Product Certification, a manufacturer may submit other CBD products, such as cosmetics, for LegitScript CBD Product Certification, so long as the products are compliant with state and federal regulations. </p>
    <h2>My organization sells and/or manufactures products with THC in addition to fully compliant CBD products. Am I eligible for certification?</h2>
    <p>THC and marijuana are still considered to be Schedule I controlled substances by the US DEA. Until THC is rescheduled by the DEA, manufacturers or merchants facilitating the sale of THC products will not be eligible for LegitScript Certification.</p>
    <h2>I purchase CBD products as a private labeler and sell them under my brand name. Am I required to submit my products to CBD Product Certification to allow certified websites to resell them?</h2>
    <p>Yes, all products with a unique UPC will require submission, review, and certification prior to earning the LegitScript-certified product designation.</p>
    <h2>Can you help me determine if my products or website comply with state and federal law?</h2>
    <p>While we review product and website compliance as part of our certification process, LegitScript does not offer regulatory compliance advice. As applicants are ultimately responsible for ensuring their products are compliant with applicable laws, we always recommend that you consult with your own regulatory professional and conduct your own regulatory due diligence before submitting an application.</p>
    <h2>My products don’t have a UPC. How do I get one?</h2>
    <p>See <a href="https://www.gs1us.org/upcs-barcodes-prefixes" target="_blank">gs1us.org</a> to start the UPC registration process.</p>
    <h2>Is marijuana legal in the United States?</h2>
    <p>No. Though marijuana has been legalized in many states, it is still a Schedule I controlled substance under federal law. Until recently, the definition of marijuana in the Controlled Substances Act included hemp and CBD derived from hemp. However, with the passage of the 2018 Farm Bill, hemp and hemp-derived CBD are no longer considered marijuana under federal law.</p>
    <h2>What types of CBD products does the FDA allow?</h2>
    <p>For detailed information regarding FDA regulation of cannabis and cannabis-derived products, including CBD, see <a href="https://www.fda.gov/news-events/public-health-focus/fda-regulation-cannabis-and-cannabis-derived-products-questions-and-answers" target="_blank">the FDA's FAQ on cannabis and cannabis-derived products</a>.</p>
  </div>
  <div class="dialog-footer">
    <button type="button" (click)="faqModal.hideDialog()">Close</button>
  </div>
</legitscript-modal>

<legitscript-modal #certifiedProductModal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]=800 height=auto [header]="'LegitScript Certified Products'">
  <div class="dialog-body" style="overflow:auto; max-height: 75vh; text-align: left;">
    <p>Please note, to ensure consumer safety and compliance with applicable laws and regulations, all CBD products sold by LegitScript-certified CBD websites must be LegitScript-certified. LegitScript Certified CBD products are CBD products that have previously been  submitted by the product's original manufacturer or private labeler to the LegitScript CBD Product Certification program and have met all applicable standards. Merchants are prohibited from altering the composition or reformulating LegitScript Certified CBD products.</p>
    <p>Please ensure the products you sell are LegitScript-certified before submitting an application; failure to do so may result in the denial of your application. If you are unsure about the certification status of your CBD products, please communicate directly with the product’s manufacturer. If your application is denied, you may re-apply at a later time. <strong>Application fees are non-refundable.</strong></p>
    <p>As a reminder, merchants that sell CBD products that are not LegitScript-certified will not be approved for certification</p>
    <certifiable-item-list [shouldReset]="listTableReloadFlag" [certItemType]="certItemType" [certItemStatus]="certItemStatus"></certifiable-item-list>
  </div>
  <div class="dialog-footer">
    <button type="button" (click)="hideCertProductDialog()">Dismiss</button>
  </div>
</legitscript-modal>