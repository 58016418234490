import { OnInit, OnDestroy, Component, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import {
  RiskActionsDisplay,
  CustomerActionsDisplay,
  AnalysisStateExternal,
  MyLsFilterGroup,
  MyLSFilter,
  MyLSFilterNames,
  WBFilterOperator,
  WBFilterGeneralValueType,
  LsActionsMyLSDisplay,
  WBFilterMIDValueType,
  MAX_MID_SEARCH_PERFORM,
  MAX_TAG_SEARCH_PERFORM,
  WBFilterDateRangeValueType,
} from '@ls/common-ts-models';
import countryCodes from '../../../../assets/country-abbreviations.json';
import { GenericNotificationAction, LocalStorageService, SeverityOptions } from '@ls/common-ng-components';
import { DateRangeToFilter } from './date-range-to-filter';
import { Store } from '@ngrx/store';
import { AccountState, AppState } from '../../../reducers';
import { takeUntil } from 'rxjs/operators';
import { isFeatureAvailable } from '../../../../../config/helpers';
import { MerchantListCheckboxClearAction } from '../reducers';

@Component({
  selector: 'merchant-filters',
  styleUrls: ['./merchant-filters.component.scss'],
  templateUrl: './merchant-filters.component.html',
})
export class MMMerchantFiltersComponent implements OnInit, OnDestroy {
  // Populating view list values
  public countries = countryCodes.map((code) => ({
    label: code.name,
    value: [code.twoCharacters, code.threeCharacters],
  }));
  public optionPlaceholder = '---';

  // Have to use {label: string, value: string} for primeng p-multiSelect
  public lsActions = RiskActionsDisplay.map((action) => {
    return { label: action.title, value: action.field };
  });
  public externalStates = Object.keys(AnalysisStateExternal).map((state) => {
    return { label: state, value: state };
  });
  public customerActions = CustomerActionsDisplay.map((action) => {
    return { label: action.text, value: action.value };
  });

  // Form Validation for span error ngIfs
  public submittedDateValid = true;
  public lastLSUpdatedValid = true;
  public lastCustomerValid = true;
  public merchantIDsValid = true;
  public lsTagsValid = true;
  public customerTagsValid = true;

  public error: Error;

  // Filter Model <--> View Communication
  //// MultiSelect fields
  public lsActionsControl: string[] = [];
  public yourActionControl: string[] = [];
  public ICAControl: string[] = [];
  public statusControl: string[] = [];
  //// Bulk fields
  public MIDControl: string[] = [];
  public lsTagControl: string[] = [];
  public customerTagControl: string[] = [];

  //// Date fields
  public submittedDateControl: [Date, Date] = null;
  public lastLSUpdateControl: [Date, Date] = null;
  public lastCustomerActionControl: [Date, Date] = null;
  //// Basic fields
  public nameControl: string;
  public URLControl: string;
  public countryControl: string[];
  public needsActionControl: boolean;
  public contentLabelControl: number;

  public basicFields: MyLSFilter[];

  @Input() public icas: Array<unknown>;
  @Input() public contentLabels: Array<unknown>;
  @Output() public filterEmitter: EventEmitter<MyLsFilterGroup> = new EventEmitter<MyLsFilterGroup>();

  public bulkEnabled = false;
  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit() {
    this.store
      .select('account')
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe((accountState: AccountState) => {
        if (accountState) {
          this.bulkEnabled = isFeatureAvailable('mmBulkEnabled', accountState.lsAccountId);
        }
      });

    const filters: MyLsFilterGroup = this.localStorageService.getMerchantFilters();
    this.countries.unshift({ label: '---', value: null });
    if (filters) {
      this.updateControlsFromStorage(filters);
    }
  }

  public onReset() {
    this.resetValues();
    this.validateForm();
    this.store.dispatch(MerchantListCheckboxClearAction());
    this.filterEmitter.emit({ filters: [] });
  }

  public onSubmit() {
    if (this.validateForm()) {
      this.filterEmitter.emit(this.constructFilters());
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private reconstructDate(filterValue: WBFilterDateRangeValueType): [Date, Date] {
    return [new Date(filterValue[0]), new Date(filterValue[1])];
  }

  private updateControlsFromStorage(filters: MyLsFilterGroup) {
    for (const filter of filters.filters) {
      switch (filter.name as MyLSFilterNames) {
        // MultiSelect fields
        case MyLSFilterNames.Status:
          this.statusControl.push(filter.value as string);
          break;
        case MyLSFilterNames.Ica:
          this.ICAControl.push(filter.value as string);
          break;
        case MyLSFilterNames.YourAction:
          this.yourActionControl.push(filter.value as string);
          break;
        // Bulk fields
        case MyLSFilterNames.Mid:
          this.MIDControl = filter.value as WBFilterMIDValueType;
          break;
        case MyLSFilterNames.LsTags:
          this.lsTagControl.push(filter.value as string);
          break;
        case MyLSFilterNames.CustomerTags:
          this.customerTagControl.push(filter.value as string);
          break;
        // Date Fields
        case MyLSFilterNames.LastCustomerAction:
          this.lastCustomerActionControl = this.reconstructDate(filter.value as WBFilterDateRangeValueType);
          break;
        case MyLSFilterNames.SubmittedDate:
          this.submittedDateControl = this.reconstructDate(filter.value as WBFilterDateRangeValueType);
          break;
        case MyLSFilterNames.LastLSUpdate:
          this.lastLSUpdateControl = this.reconstructDate(filter.value as WBFilterDateRangeValueType);
          break;
        // SingleSelect Fields
        // Basic Fields
        case MyLSFilterNames.NeedsAction:
          this.needsActionControl = filter.value as boolean;
          break;
        case MyLSFilterNames.MerchantName:
          this.nameControl = filter.value as string;
          break;
        case MyLSFilterNames.Url:
          this.URLControl = filter.value as string;
          break;
        case MyLSFilterNames.MerchantCountry:
          this.countryControl = filter.value as string[];
          break;
        case MyLSFilterNames.ContentLabels:
          this.contentLabelControl = filter.value as number;
          break;
        default:
          // Only one left is weird case where name is in Object.values(LsActionsMyLSDisplay)
          this.lsActionsControl.push(filter.name);
          break;
      }
    }
  }

  private resetValues() {
    // MultiSelects
    this.lsActionsControl = [];
    this.yourActionControl = [];
    this.ICAControl = [];
    this.statusControl = [];
    this.MIDControl = [];
    this.lsTagControl = [];
    this.customerTagControl = [];

    // Dates
    this.submittedDateControl = null;
    this.lastCustomerActionControl = null;
    this.lastLSUpdateControl = null;

    // Basic
    this.nameControl = null;
    this.URLControl = null;
    this.countryControl = null;
    this.needsActionControl = null;
    this.contentLabelControl = null;
  }

  private refreshModelValues() {
    this.basicFields = [
      {
        name: MyLSFilterNames.MerchantName,
        value: this.nameControl,
        operator: WBFilterOperator.Contains,
      },
      {
        name: MyLSFilterNames.Url,
        value: this.URLControl,
        operator: WBFilterOperator.Contains,
      },
      {
        name: MyLSFilterNames.NeedsAction,
        value: this.needsActionControl,
        operator: WBFilterOperator.Equals,
      },
      // Date Fields that require value modification
      {
        name: MyLSFilterNames.SubmittedDate,
        value: this.createDateRange(this.submittedDateControl),
        operator: WBFilterOperator.Between,
      },
      {
        name: MyLSFilterNames.LastLSUpdate,
        value: this.createDateRange(this.lastLSUpdateControl),
        operator: WBFilterOperator.Between,
      },
      {
        name: MyLSFilterNames.LastCustomerAction,
        value: this.createDateRange(this.lastCustomerActionControl),
        operator: WBFilterOperator.Between,
      },
      {
        name: MyLSFilterNames.ContentLabels,
        value: this.contentLabelControl,
        operator: WBFilterOperator.Equals,
      },
    ];
  }

  private createDateRange(dateFieldValue: [Date, Date]): WBFilterGeneralValueType {
    if (dateFieldValue) {
      return DateRangeToFilter.convert(dateFieldValue[0], dateFieldValue[1]);
    }
    return dateFieldValue;
  }

  private constructFilters(): MyLsFilterGroup {
    this.refreshModelValues();

    const filterGroup: MyLsFilterGroup = {
      filters: [],
    };
    for (const basicField of this.basicFields) {
      if (basicField.value) {
        filterGroup.filters.push(basicField);
      }
    }
    // Special ls Actions multiSelect case
    if (this.lsActionsControl) {
      filterGroup.filters = [...filterGroup.filters, ...this.createLSActionsFilters(this.lsActionsControl)];
    }
    // generic multiSelect cases
    if (this.statusControl) {
      filterGroup.filters = [
        ...filterGroup.filters,
        ...this.createMultiSelectFilters(this.statusControl, MyLSFilterNames.Status),
      ];
    }
    if (this.ICAControl) {
      filterGroup.filters = [
        ...filterGroup.filters,
        ...this.createMultiSelectFilters(this.ICAControl, MyLSFilterNames.Ica),
      ];
    }
    if (this.yourActionControl) {
      filterGroup.filters = [
        ...filterGroup.filters,
        ...this.createMultiSelectFilters(this.yourActionControl, MyLSFilterNames.YourAction),
      ];
    }
    if (this.MIDControl) {
      filterGroup.filters = [...filterGroup.filters, ...this.createBulkFilters(this.MIDControl, MyLSFilterNames.Mid)];
    }
    if (this.lsTagControl) {
      filterGroup.filters = [
        ...filterGroup.filters,
        ...this.createBulkFilters(this.lsTagControl, MyLSFilterNames.LsTags),
      ];
    }
    if (this.customerTagControl) {
      filterGroup.filters = [
        ...filterGroup.filters,
        ...this.createBulkFilters(this.customerTagControl, MyLSFilterNames.CustomerTags),
      ];
    }
    if (this.countryControl) {
      filterGroup.filters = [
        ...filterGroup.filters,
        ...this.createBulkFilters(this.countryControl, MyLSFilterNames.MerchantCountry),
      ];
    }
    return filterGroup;
  }

  private createMultiSelectFilters(multiSelectValues: string[], name: MyLSFilterNames): MyLSFilter[] {
    const operator = WBFilterOperator.Equals;
    const multiSelectFilters: MyLSFilter[] = [];
    for (const value of multiSelectValues) {
      multiSelectFilters.push({ name, value, operator });
    }
    return multiSelectFilters;
  }

  private createBulkFilters(bulkValues: string[], name: MyLSFilterNames): MyLSFilter[] {
    if (bulkValues.length === 0) {
      return [];
    }

    if (name === MyLSFilterNames.LsTags || name === MyLSFilterNames.CustomerTags) {
      return bulkValues.map((value) => ({ name, value, operator: WBFilterOperator.Equals }));
    }

    return [{ name, value: bulkValues, operator: WBFilterOperator.Equals }];
  }

  private createLSActionsFilters(lsActionsValue: string[]): MyLSFilter[] {
    const actionFilters: MyLSFilter[] = [];
    for (const action of lsActionsValue) {
      actionFilters.push({
        name: action as LsActionsMyLSDisplay,
        value: 1,
        operator: WBFilterOperator.Equals,
      });
    }
    return actionFilters;
  }

  private isValidDateRange(dateRange: [Date, Date]) {
    return dateRange.length && dateRange.length === 2 && dateRange[0] instanceof Date && dateRange[1] instanceof Date;
  }

  // Only need to do validation on date ranges and the provided merchantIDs
  private validateForm() {
    this.merchantIDsValid = this.MIDControl.length <= MAX_MID_SEARCH_PERFORM;
    this.lsTagsValid = this.lsTagControl.length <= MAX_TAG_SEARCH_PERFORM;
    this.customerTagsValid = this.customerTagControl.length <= MAX_TAG_SEARCH_PERFORM;
    this.submittedDateValid = !this.submittedDateControl || this.isValidDateRange(this.submittedDateControl);
    this.lastLSUpdatedValid = !this.lastLSUpdateControl || this.isValidDateRange(this.lastLSUpdateControl);
    this.lastCustomerValid = !this.lastCustomerActionControl || this.isValidDateRange(this.lastCustomerActionControl);
    const tagsError = !this.customerTagsValid ? 'Tags' : !this.lsTagsValid ? 'LS Tags' : '';

    if (this.merchantIDsValid === false) {
      this.store.dispatch(
        GenericNotificationAction({
          severity: SeverityOptions.ERROR,
          summary: 'Failed to submit',
          detail: `Your request had over ${MAX_MID_SEARCH_PERFORM} MIDs.`,
          sticky: false,
          blocking: false,
        }),
      );
    }

    if (tagsError) {
      this.store.dispatch(
        GenericNotificationAction({
          severity: SeverityOptions.ERROR,
          summary: 'Failed to submit',
          detail: `Your request had over ${MAX_TAG_SEARCH_PERFORM} ${tagsError}.`,
          sticky: false,
          blocking: false,
        }),
      );
    }

    return (
      this.submittedDateValid &&
      this.lastLSUpdatedValid &&
      this.lastCustomerValid &&
      this.merchantIDsValid &&
      this.lsTagsValid &&
      this.customerTagsValid
    );
  }
}
