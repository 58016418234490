<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div id="header" class="full-sidebar">
    <breadcrumb-nav class="bn-wrapper"></breadcrumb-nav>
    <div class="header-tool-container">
        <ng-container *ngIf="selectorVisible">
            <account-selector></account-selector>
        </ng-container>
        <div class="header-tool" (click)="moveToEC()" *ngIf="isHybrydUser" (mouseenter)="switchToHoverIcon()" (mouseleave)="switchToDefaultIcon()">
            <div class="centered-content">
                <img [src]="iconSource" alt="New Portal Icon" width="30" height="30">
                <span>New Portal</span>
            </div>
        </div>
        <div class="header-tool" (click)="openDocs()" *ngIf="canViewMMDocs">
            <i class="fa fa-book" aria-hidden="true"></i>Docs
        </div>
        <div class="header-tool" (click)="openHelp()">
            <i class="fa fa-life-ring" aria-hidden="true"></i>Help
        </div>
        <div class="header-tool" (click)="signOutClick()">
            <i class="fa fa-sign-out" aria-hidden="true"></i>Sign Out
        </div>
    </div>
</div>