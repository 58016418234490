import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { User, Roles } from '@ls/common-ts-models';

import { AppState, AuthenticationState, UserAuthenticateAction, fnAuthenticationState } from '../../reducers';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public model: User = {} as User;
  public returnUrl: string;
  public authentication$: Observable<AuthenticationState>;
  public destroyed$: Subject<boolean> = new Subject();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private store: Store<AppState>,
  ) {}

  public ngOnInit() {
    this.authentication$ = this.store.select(fnAuthenticationState);
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';

    this.store
      .select('authentication')
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe((state: AuthenticationState) => {
        if (state.pending || state.errorText) {
          return;
        }

        const user = state.user;
        const roles = state.roles;

        // make sure we have a valid user
        if (
          roles &&
          roles.length &&
          (roles.includes(Roles.cpv2User) || roles.includes(Roles.cpv2AccountOwner)) &&
          user &&
          user.email &&
          user.lsUserId
        ) {
          this.router.navigateByUrl(`/loading?returnUrl=${this.returnUrl}`);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public createAccount() {
    this.router.navigate(['/create-account']);
  }

  public login() {
    this.store.dispatch(UserAuthenticateAction({ user: { ...this.model } }));
  }
}
