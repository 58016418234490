<div class="entry-main">
  <div class="entry-container entry-container-small create-account">
    <h1>Create a LegitScript Account</h1>

    <div class="byline">
      <p class="subtext left">
        Already have an account?
        <a class="sign-in" (click)="loginAuth0()">Sign In</a>
      </p>
      <p class="subtext right">Fields marked with a * are required</p>
    </div>
    <form (ngSubmit)="captcha.execute()" #form="ngForm" novalidate>
      <div class="create-account-form form-container form-container-public begin">
        <div class="form-group span6">
          <confirm-email (emailSet)="emailSet($event)" [parentForm]="form.form" [initialValue]="this.model.email" type="create-account"></confirm-email>
        </div>

        <div class="span6 gdpr" style="text-align: justify">
          By clicking yes, you freely consent for LegitScript LLC to use and process any personal data submitted within the portal. 
          See our <a href="https://www.legitscript.com/privacy-policy/" target="_blank">Privacy Policy</a> for information about 
          how LegitScript will use and process your data.
        </div>

        <div class="form-group span6">
          <div class="radio-group">
            <input #gdpr="ngModel" name="gdpr" id="gdprYes" value="yes" type="radio" [(ngModel)]="model.gdpr" (change)="gdprUpdate()" required>
            <label for="gdprYes">Yes*</label>
            <input #gdpr="ngModel" name="gdpr" id="gdprNo" value="no" type="radio" [(ngModel)]="model.gdpr" (change)="gdprUpdate()">
            <label for="gdprNo">No</label>
            <field-validator class="right" [control]="gdpr" tabindex="-1"></field-validator>
          </div>
        </div>

        <!-- ERROR SECTION ****************************************************** -->
        <div *ngIf="error!==undefined" [ngSwitch]="error" class="form-group span6 validation-error-container">
          <span *ngSwitchCase="errorType.BadForm">
            All fields are required. Please confirm that emails match.
          </span>
          <span *ngSwitchCase="errorType.gdpr">
            In order to create a LegitScript account, you must consent to the processing of personal data
          </span>
          <span *ngSwitchDefault>
            An unexpected error occurred. Please try again or <a href="https://help.legitscript.com" target="_blank">contact customer support</a>
          </span>
        </div>

        <!-- INVISIBLE CAPTCHA *************************************************** -->
        <div class="form-group span6">
            <re-captcha #captcha size="invisible" (resolved)="resolvedCaptcha($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
        </div>

        <!-- SUBMIT BUTTON ******************************************************* -->
        <div class="form-actions">
          <button *ngIf="!loading" [disabled]="this.error === this.errorType.gdpr || (form.submitted && (form.invalid || model.captchaResponse === undefined))" class="btn btn-primary">
            Continue
          </button>
          <div class="loading-container" [hidden]="!loading">
            <p-progressSpinner [style]="{ width: '35px', height: '35px' }"></p-progressSpinner>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>