<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->

<div class="entry-main">
  <div class="entry-container entry-container-small create-account">
    <h1>Select a Primary Solution</h1>
    <div class="byline">
      <p class="subtext left" style="margin: 0px 0px 42px 0px"><a (click)="back()">&lt; Back</a></p>
    </div>
    <form (ngSubmit)="submit()" #form="ngForm" novalidate>
      <div class="create-account-form form-container form-container-public product-stage">
        <div class="card-container">
          <div class="card" *ngFor="let product of productsOfInterest" (click)="onClick(product.product)" [class.cardSelected]="model.productOfInterest == product.product">
            <div class="card-header">
              <h4 class="certication-header">
                {{product.title}}
              </h4>
              <img src="{{'../../../../assets/svg/' + product.image}}" width="34" height="34" />
            </div>
            <div class="card-body">
              <p class="certification-description">
                {{product.description}}
              </p>
            </div>
          </div>
        </div>
        <div class="span6 gdpr" style="text-align: center;">
          <div>
            Looking for something else? <a href="https://help.legitscript.com/s/contactsupport" target="_blank">Contact Us.</a>
          </div>
          <div style="text-align: center; font-size: 14px;">
            Our team will follow up to identify the solution that best meets your needs.
          </div>
        </div>
        <div *ngIf="form.submitted && !model.productOfInterest" class="form-group span6 validation-error-container">
          Select a solution to continue.
        </div>
        <div class="form-actions">
          <button  class="btn btn-primary">Continue</button>
        </div>
      </div>
    </form>
  </div>
</div>