import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { flatMap } from 'rxjs/operators';
import { AppState } from '../../reducers';
import {
  GenericNotificationAction,
  SeverityOptions,
  ContactFormService,
  LocalStorageService,
  UrlRegEx,
} from '@ls/common-ng-components';
import { TeamEmail, Opportunity } from '@ls/common-ts-models';
import { ProductLookupService } from '../../services';

@Component({
  selector: 'data-lookups-enroll',
  styleUrls: ['./enroll.scss', '../../styles/enroll.scss'],
  templateUrl: './enroll.html',
  providers: [ProductLookupService],
})
export class DataLookupEnrollContactComponent implements OnInit {
  public firstTry = true;
  public submitting = false;
  public formGroup: FormGroup;
  public dba: FormControl = new FormControl('', Validators.required);
  public legalName: FormControl = new FormControl('', Validators.required);
  public primaryUrl: FormControl = new FormControl('', [Validators.required, Validators.pattern(UrlRegEx())]);
  public description: FormControl = new FormControl('', Validators.required);
  public oppCreated = false;

  constructor(
    private store: Store<AppState>,
    private contactFormService: ContactFormService,
    private localStorageService: LocalStorageService,
    private productLookupService: ProductLookupService,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.formGroup = new FormGroup({
      dba: this.dba,
      legalName: this.legalName,
      primaryUrl: this.primaryUrl,
      description: this.description,
    });
  }

  public onSubmit() {
    this.firstTry = false;
    if (this.formGroup.invalid) {
      return;
    }

    this.submitting = true;

    this.productLookupService
      .createOpportunity()
      .pipe(
        flatMap<any, any>((opp: Opportunity) => {
          const options = {
            toName: 'Sales Team',
            toAddress: TeamEmail.SALES,
            subject: 'Request for access to Data Lookup Service from my.legitscript.com',
            template: 'ProductLookupEnroll',
          };
          const { name, email } = this.localStorageService.getUser();
          const businessName = this.localStorageService.getAccount().name;
          const request = { ...this.formGroup.value, name, email, businessName, opportunityId: opp.Id };
          return this.contactFormService.sendEmail(options, request);
        }),
      )
      .subscribe(
        () => {
          this.submitting = false;
          this.formGroup.reset();
          this.oppCreated = true;
        },
        () => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Request Failed',
              detail:
                'There was an issue submitting your request.  Please try again.  If the issue persists, please contact support.',
              sticky: true,
              blocking: false,
            }),
          );
          this.submitting = false;
        },
      );
  }
}
