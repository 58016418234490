import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject, catchError, filter, map, of, skipWhile, take, takeUntil } from 'rxjs';
import { AppState, fnTestTransactionsState } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import {
  PaginatedTestTransactionGetAction,
  TestTransactionDownloadAttachmentAction,
  TestTransactionRequestScreenshotsAction,
  TestTransactionState,
  TestTransactionsDownloadTransactionsAction,
  TestTransactionsFetchAction,
  TestTransactionsGetSummaryAction,
  TestTransactionsZippingAction,
  fnColumnValues$,
} from '../../reducers';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Pagination } from '@ls/common-ts-models';
import {
  TransactionDetailsForMyLs200Response,
  TransactionSearchResponse,
  TransactionDocuments,
} from '../../angular-client';
import {
  ColumnConfig,
  FilterCategory,
  FilterType,
  GenericNotificationAction,
  MultiSelectFilterOption,
  SeverityOptions,
  SortFilterPage,
  TableFilter,
  isFilterEmpty,
} from '@ls/common-ng-components';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { fileExtensionToMimeType } from '../../models/test-transaction-models';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

@Component({
  selector: 'test-transactions',
  templateUrl: './test-transactions.component.html',
  styleUrls: ['./test-transactions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TestTransactionsComponent implements OnInit, OnDestroy {
  public summaryItems$: Observable<{ title: string; count: number }[]>;
  public requesting$: Observable<number>;

  public colunnsData: ColumnConfig[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private http: HttpClient,
  ) {}

  public pageSize = 25;

  public isLoading$: Observable<boolean>;
  public isDownloadLoading$: Observable<boolean>;
  public isZipping$: Observable<boolean>;
  public pageData$: Observable<Pagination>;
  public testTransactions$: Observable<TransactionSearchResponse[]>;
  public currentFilters: TableFilter[];
  public appliedFilters: TableFilter[];
  public availableFilters: FilterCategory[];
  public sortField?: string;
  public sortOrder?: 1 | -1;
  public sortOrderString?: 'asc' | 'desc';
  public testTransactionDetails: Observable<TransactionDetailsForMyLs200Response>;
  public testTransactionDetailsLoading: Observable<boolean>;
  public testTransactionScreenshots: Observable<{ url: string; id: string; name: string }[]>;
  public showDetails: string = null;
  public showRequestNewTransaction = false;
  public downloadAttachmentError = new Subject<boolean>();
  public loadingFilter = false;

  private testTransactionsState$: Observable<TestTransactionState>;
  private destroyed$: Subject<boolean> = new Subject();
  private pageNumber = 0;
  private allFilters: {
    [key: string]: FilterWithFetchOptions;
  };
  private filterCount = 0;
  private summaryLoaded = false;

  ngOnInit(): void {
    this.setUpColumnsAndFilters();

    // Listen to activated route for page/filter/sort updates.
    // Note that this will trigger when calling this.router.navigate(...)
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((queryParams) => {
      let refreshTestTransactions = false;
      const pageNumber = Number.parseInt(queryParams['page'], 10) || 1;
      if (pageNumber !== this.pageNumber) {
        this.pageNumber = pageNumber;
        refreshTestTransactions = true;
      }
      const sortField = queryParams['sortField'] ?? 'transactionDate';
      if (sortField !== this.sortField) {
        this.sortField = sortField;
        refreshTestTransactions = true;
      }
      const sortOrderString = (queryParams['sortOrder'] as 'asc' | 'desc') ?? 'desc';
      if (sortOrderString !== this.sortOrderString) {
        this.sortOrderString = sortOrderString;
        this.sortOrder = this.getSortOrder(this.sortOrderString);
        refreshTestTransactions = true;
      }
      const hasNewFilters = this.extractFiltersFromParams(queryParams);
      this.appliedFilters = (this.currentFilters ?? []).filter((f) => !isFilterEmpty(f));
      if (
        hasNewFilters ||
        this.filterCount !== (this.currentFilters ?? []).filter((f) => f.value)?.length ||
        !this.summaryLoaded
      ) {
        this.filterCount = this.currentFilters?.length || 0;
        this.summaryLoaded = true;
        this.store.dispatch(TestTransactionsGetSummaryAction({ filters: this.appliedFilters }));
        refreshTestTransactions = true;
        // If we haven't loaded values for filters (i.e. user navigates using a deep-link), we need to populate them
        (this.currentFilters || [])
          .filter((f: FilterWithFetchOptions) => this.filterNeedsOptions(f))
          .forEach((f) => {
            this.getFilterWithOptions(f).subscribe((filterWithOptions) => {
              this.currentFilters = (this.currentFilters || []).map((cf) =>
                cf.propertyName === f.propertyName ? { ...filterWithOptions } : cf,
              );
            });
          });
      }

      if (refreshTestTransactions) {
        this.store.dispatch(
          PaginatedTestTransactionGetAction({
            page: this.pageNumber,
            size: this.pageSize,
            sortField: this.sortField,
            sortDirection: this.sortOrderString,
            filters: this.appliedFilters,
          }),
        );
      }

      const showDetails = queryParams['showDetails'];
      if (showDetails !== this.showDetails) {
        this.showDetails = showDetails;
        if (showDetails) {
          this.store.dispatch(TestTransactionsFetchAction({ id: showDetails }));
        }
      }
    });

    this.testTransactionsState$ = this.store.select(fnTestTransactionsState).pipe(takeUntil(this.destroyed$));

    // Listen to state changes
    const testTransactionsPaginatedState$ = this.testTransactionsState$.pipe(map((state) => state.transactions));

    // Listen to state changes
    const testTransactionsDownloadState$ = this.testTransactionsState$.pipe(map((state) => state.download));

    // Extract test transaction array
    this.testTransactions$ = testTransactionsPaginatedState$.pipe(
      skipWhile((state) => !state.paginatedTransactions?.transactions),
      map((state) => state.paginatedTransactions.transactions),
    );

    // Extract pagination data
    this.pageData$ = testTransactionsPaginatedState$.pipe(
      skipWhile((state) => !state.paginatedTransactions?.pagination?.totalRecords),
      map((state) => state.paginatedTransactions.pagination),
    );

    // Listen to loading property
    this.isLoading$ = testTransactionsPaginatedState$.pipe(map((state) => state.pending));
    this.isDownloadLoading$ = testTransactionsDownloadState$.pipe(map((state) => state.pending));
    this.isZipping$ = testTransactionsDownloadState$.pipe(map((state) => state.zipping));

    // Summary items
    this.summaryItems$ = this.testTransactionsState$.pipe(
      map((state) => state.summary),
      skipWhile((state) => !state?.summary),
      map((state) => [
        {
          title: 'Tested - Hit',
          count: state.summary.Tested_Hit,
        },
        {
          title: 'Tested - No Hit',
          count: state.summary.Tested_No_Hit,
        },
        {
          title: 'Tested - Non-Client CCs only',
          count: state.summary.Not_Tested_Non_Client_CCs_only,
        },
        {
          title: 'Not Tested - No CCs',
          count: state.summary.Not_Tested_No_CCs,
        },
      ]),
    );

    // Details
    const detailsState = this.testTransactionsState$.pipe(
      map((state) => state.fetch),
      filter((state) => !!state.transaction),
    );
    this.testTransactionDetails = detailsState.pipe(map((details) => details.transaction));
    this.testTransactionDetailsLoading = detailsState.pipe(map((details) => details.pending));
    this.testTransactionScreenshots = this.testTransactionsState$.pipe(
      map((state) => state?.download),
      filter((state) => state && !state.pending),
      takeUntil(this.destroyed$),
      map((downloadState) => (downloadState.attachmentUrls || []).filter((s) => s.type === 'screenshot')),
    );

    this.requesting$ = this.testTransactionsState$.pipe(map((state) => state.request.requesting));
    // listen for new requests, and refresh the page to capture them.
    let refreshWhenComplete = false;
    this.requesting$.subscribe((requesting) => {
      if (requesting > 0) {
        refreshWhenComplete = true;
      } else if (refreshWhenComplete) {
        refreshWhenComplete = false;
        this.store.dispatch(
          PaginatedTestTransactionGetAction({
            page: this.pageNumber,
            size: this.pageSize,
            sortField: this.sortField,
            sortDirection: this.sortOrderString,
            filters: this.currentFilters,
          }),
        );
      }
    });

    // subscribe to all errors
    this.testTransactions$.pipe();
  }

  public exportTransactions() {
    let isRequested = false;
    this.testTransactionsState$
      .pipe(
        map((state) => state.download),
        filter((downloadState) => !downloadState.pending && !!downloadState.transactionsFile && isRequested),
        take(1),
      )
      .subscribe((downloadState) => {
        this.saveBlob(downloadState.transactionsFile, `Test Transactions [${new Date().toISOString()}].csv`);
      });

    this.store.dispatch(
      TestTransactionsDownloadTransactionsAction({
        sortField: this.sortField,
        sortDirection: this.sortOrderString,
        filters: this.currentFilters,
      }),
    );
    isRequested = true;
  }

  public onApplyFilter($event: TableFilter) {
    if (!this.currentFilters) {
      this.currentFilters = [];
    }
    this.currentFilters = [...this.currentFilters.filter((f) => f.propertyName !== $event.propertyName), $event];
    this.appliedFilters = (this.currentFilters ?? []).filter((f) => !isFilterEmpty(f));
    this.loadPaginated(1, this.sortField, this.sortOrderString, this.showDetails);
  }

  public onRemoveFilter($event: TableFilter) {
    if ($event.value) {
      // filter has a value, so simply clear the value, but leave the filter
      this.currentFilters = this.currentFilters.map((f) => {
        if (f.propertyName === $event.propertyName) {
          return { ...f, value: null };
        }
        return f;
      });
      this.appliedFilters = this.currentFilters.filter((f) => f.value);
      this.loadPaginated(1, this.sortField, this.sortOrderString, this.showDetails);
    } else {
      // filter needs to be removed.
      this.currentFilters = (this.currentFilters ?? []).filter((f) => f.propertyName !== $event.propertyName);
    }
  }

  public onClearFilters() {
    this.currentFilters = this.currentFilters.map((f) => ({ ...f, value: null }));
    this.appliedFilters = this.appliedFilters.map((f) => ({ ...f, value: null }));
    this.loadPaginated(this.pageNumber, this.sortField, this.sortOrderString, this.showDetails);
  }

  public onAddFilter($event: FilterWithFetchOptions) {
    const filterToAdd = $event;
    if (filterToAdd) {
      this.getFilterWithOptions(filterToAdd).subscribe((filterWithOptions) => {
        this.currentFilters = [...(this.currentFilters || []), filterWithOptions];
      });
    }
  }

  public onPageChangeEvent($event: SortFilterPage) {
    this.loadPaginated($event.page + 1, this.sortField, this.sortOrderString, this.showDetails);
  }

  public onSortChangeEvent($event: SortFilterPage) {
    this.loadPaginated(this.pageNumber, $event.sortField, this.getSortOrderString($event.sortOrder), this.showDetails);
  }

  public onRowClicked($event: TransactionSearchResponse) {
    this.loadPaginated(this.pageNumber, this.sortField, this.sortOrderString, $event.id);
  }

  public onCopyLink() {
    if (!navigator.clipboard) {
      this.store.dispatch(
        GenericNotificationAction({
          severity: SeverityOptions.WARN,
          summary: 'Browser does not support copy.',
          detail: '',
          sticky: false,
        }),
      );
    }

    const url = location.href;
    navigator.clipboard.writeText(url);
    this.store.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.INFO,
        summary: 'Url copied to clipboard',
        detail: `Copied ${url} to clipboard`,
        sticky: false,
      }),
    );
  }

  public onCloseSidebar() {
    this.loadPaginated(this.pageNumber, this.sortField, this.sortOrderString, null);
  }

  public onRequestScreenshots($event: { id: string; client: string; websiteUrl: string }) {
    const { id, client = '', websiteUrl } = $event;

    const downloadDone = new Subject<boolean>();
    let dispatched = false;
    this.testTransactionsState$
      .pipe(
        map((state) => state?.download),
        filter((state) => state && dispatched && !state.pending && !state.error),
        takeUntil(downloadDone),
      )
      .subscribe(() => {
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.SUCCESS,
            summary: 'Screenshots Requested success',
            detail: `Screenshots successfully requested for ${websiteUrl}`,
            sticky: false,
            life: 5000,
          }),
        );
        downloadDone.next(true);
      });

    this.testTransactionsState$
      .pipe(
        map((state) => state?.download),
        filter((state) => state && dispatched && !state.pending && !!state.error),
        takeUntil(downloadDone),
      )
      .subscribe((state) => {
        // this triggers when there is an endpoint error
        const defaultErrorMessage = `There was an error requesting screenshots for ${websiteUrl}`;
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Screenshots Requested error',
            detail: state?.error?.errorText || defaultErrorMessage,
            sticky: false,
            life: 5000,
          }),
        );
        this.downloadAttachmentError.next(true);
        downloadDone.next(true);
      });

    this.store.dispatch(
      TestTransactionRequestScreenshotsAction({
        transactionId: id,
        client,
        websiteUrl,
      }),
    );

    dispatched = true;
  }

  public onDownloadFile($event: {
    files: TransactionDocuments[];
    type: 'screenshot' | 'attachment';
    zip?: boolean;
    folderName?: string;
  }) {
    const filesWithUrls = $event.files.filter((f) => f.url);
    const filesWithoutUrls = $event.files.filter((f) => !f.url);
    if (filesWithoutUrls.length > 0) {
      const downloadDone = new Subject<boolean>();
      let dispatched = false;
      this.testTransactionsState$
        .pipe(
          map((state) => state?.download),
          filter((state) => state && dispatched && !state.pending && !state.error),
          takeUntil(downloadDone),
        )
        .subscribe((state) => {
          const concatUrls = [...(state.donwloadUrls || []), ...(filesWithUrls || [])];
          if ($event.zip) {
            const urlsWithOrder = this.attachOrderToDocuments(concatUrls, $event.files);
            this.downloadAndZipImages(
              urlsWithOrder as { url: string; name: string; order?: number }[],
              $event.folderName,
            );
          } else {
            concatUrls.forEach((file) => this.downloadFromUrl(file as { url: string; name: string }));
          }
          downloadDone.next(true);
        });

      this.testTransactionsState$
        .pipe(
          map((state) => state?.download),
          filter((state) => state && dispatched && !state.pending && !!state.error),
          takeUntil(downloadDone),
        )
        .subscribe(() => {
          // this triggers when the download is in error. Force close the carousel
          this.downloadAttachmentError.next(true);
          downloadDone.next(true);
        });

      this.store.dispatch(
        TestTransactionDownloadAttachmentAction({
          transactionId: this.showDetails,
          attachmentIds: filesWithoutUrls.map((f) => f.id),
          downloadType: $event.type,
        }),
      );

      dispatched = true;
    } else if (filesWithUrls.length > 0) {
      if ($event.zip) {
        this.downloadAndZipImages(filesWithUrls as { url: string; name: string; order?: number }[], $event.folderName);
      } else {
        filesWithUrls.forEach((file) => this.downloadFromUrl(file as { url: string; name: string }));
      }
    }
  }

  public attachOrderToDocuments(
    documents: TransactionDocuments[],
    orderedItems: TransactionDocuments[],
  ): TransactionDocuments[] {
    const documentsWithOrder: TransactionDocuments[] = [];
    const maxOrder = Infinity; // used for documents without order
    documents.forEach((document) => {
      const index = orderedItems.findIndex((item) => item.id === document.id);

      const file: TransactionDocuments = {
        ...document,
        order: index > -1 ? orderedItems[index].order ?? maxOrder : maxOrder,
      };

      documentsWithOrder.push(file);
    });

    return documentsWithOrder;
  }

  public onShowImages($event: { ids: string[] }) {
    const downloadDone = new Subject<boolean>();
    let dispatched = false;
    this.testTransactionsState$
      .pipe(
        map((state) => state?.download),
        filter((state) => state && !state.pending && dispatched),
        takeUntil(downloadDone),
      )
      .subscribe((state) => {
        if (state.error) {
          // this triggers when the download is in error. Force close the carousel
          this.downloadAttachmentError.next(true);
        }
        downloadDone.next(true);
      });

    this.store.dispatch(
      TestTransactionDownloadAttachmentAction({
        transactionId: this.showDetails,
        attachmentIds: $event.ids,
        downloadType: 'screenshot',
      }),
    );
    dispatched = true;
  }

  public downloadFromUrl(url: { url: string; name: string }) {
    const fileType = url.url.split('?')[0].split('.').pop();
    const type = fileExtensionToMimeType[fileType];

    // It may seem strange to call http directly in a component, but I don't want to keep the blob in memory (it should be free for garbage collection)
    // Creating a state-based action here would require we either clear out the blob, which is clunky, or keep it in memory (in state), which is undesirable.
    this.http
      .get(url.url, { observe: 'body', responseType: 'arraybuffer' })
      .pipe(
        take(1),
        catchError((err: HttpErrorResponse) => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Downloading failed.',
              detail: err?.error?.message || err?.message || err || 'Unknown Error',
              sticky: false,
              blocking: false,
            }),
          );
          return of(null as ArrayBuffer);
        }),
      )
      .subscribe((response) => {
        if (response) {
          const blob = new Blob([response], { type });
          this.saveBlob(blob, url.name);
        }
      });
  }

  getSortedUrls(urls: { url: string; name: string; order?: number }[]) {
    return [...(urls ?? [])].sort((urlA, urlB) => urlA.order - urlB.order);
  }

  downloadAndZipImages(imageUrls: { url: string; name: string; order?: number }[], zipFileName: string): void {
    const zip = new JSZip();

    let urlResponses = [];
    this.store.dispatch(
      TestTransactionsZippingAction({
        zipping: true,
      }),
    );
    const imagePromises = imageUrls.map((image) => {
      return new Promise((resolve, _reject) => {
        this.http
          .get(image.url, { responseType: 'arraybuffer' })
          .pipe(
            take(1),
            catchError((err: HttpErrorResponse) => {
              console.error('Download failed for image:', image.url, err);
              return of(null as ArrayBuffer); // Handle download failure
            }),
          )
          .toPromise()
          .then((response) => {
            if (response) {
              urlResponses.push({ order: image.order, name: image.name, response });
              resolve(urlResponses);
            }
          });
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        urlResponses = this.getSortedUrls(urlResponses);
        urlResponses.map((url, index) => {
          // if the url has the "order" property, use that as index, otherwise use the fallback index
          const urlIndex = url.order !== null && isFinite(url.order) ? url.order : index;
          zip.file(`${urlIndex}_${url.name}`, url.response, { binary: true });
        });
      })
      .then(() => {
        return zip.generateAsync({ type: 'blob' });
      })
      .then((content) => {
        saveAs(content, `${zipFileName}.zip`);
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.SUCCESS,
            summary: 'Files download success',
            detail: `Files successfully downloaded`,
            sticky: false,
            life: 5000,
          }),
        );
        this.store.dispatch(
          TestTransactionsZippingAction({
            zipping: false,
          }),
        );
      })
      .catch((error) => {
        console.error('Error generating ZIP file:', error);
        this.store.dispatch(
          TestTransactionsZippingAction({
            zipping: false,
          }),
        );
      });
  }

  private saveBlob(blob: Blob, name: string) {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    document.body.removeChild(link);
  }

  private loadPaginated(pageNumber: number, sortField: string, sortOrder: string, showDetails?: string) {
    const filterParams = (this.appliedFilters ?? []).reduce((params, filter) => {
      if (filter.value) {
        const filterValueString =
          filter.filterType === FilterType.date_range
            ? filter.value.map((d) => d.toISOString()).join(',')
            : filter.filterType === FilterType.multi_select
            ? filter.value.map((v) => v.name).join(',')
            : filter.filterType === FilterType.numeric_range
            ? filter.value.map((n) => `${n}`).join(',')
            : filter.filterType === FilterType.single_line
            ? filter.value
            : filter.filterType === FilterType.multi_line
            ? filter.value.replaceAll('\n', ',')
            : '';
        params[filter.propertyName] = filterValueString;
      }
      return params;
    }, {});

    const queryParams: Params = {
      ...filterParams,
      page: pageNumber,
      sortField: sortField,
      sortOrder: sortOrder,
      showDetails,
    };

    // Calling router.navigate here to preserve the filter/sorting/paging info in the url.
    // This will trigger the activatedRoute.
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: '',
    });
  }

  private extractFiltersFromParams(queryParams: Params): boolean {
    // checks for filter's existance, as well as filter differences
    const parseQueryParam = (filter: TableFilter, queryParamValue: string): TableFilter => {
      switch (filter.filterType) {
        case FilterType.date_range: {
          const dateRange = queryParamValue.split(',').map((d) => new Date(d)) as [Date, Date];
          if (
            filter.value?.[0].getTime() !== dateRange[0].getTime() ||
            filter.value?.[1].getTime() !== dateRange[1].getTime()
          ) {
            const newFilter = { ...filter, value: dateRange };
            return newFilter;
          }
          return null as TableFilter;
        }
        case FilterType.multi_select: {
          const multiSelect = queryParamValue.split(',').map((v) => ({ name: v }));
          if (!filter.value?.length) {
            // we didn't have this filter before; now we do
            const newFilter = { ...filter, value: multiSelect };
            return newFilter;
          }
          // check to see if we have the same values as previous filter
          const prevValues: string[] = filter.value.map((v) => v.name);
          const newValues = multiSelect.map((v) => v.name);
          if (
            prevValues.length !== newValues.length ||
            prevValues.some((v1) => !newValues.some((v2) => v2 === v1)) ||
            newValues.some((v1) => !prevValues.some((v2) => v2 === v1))
          ) {
            const newFilter = { ...filter, value: multiSelect };
            return newFilter;
          }
          return null as TableFilter;
        }
        case FilterType.numeric_range: {
          const numericRange = queryParamValue.split(',').map((n) => +n) as [number, number];
          if (filter.value?.[0] !== numericRange[0] || filter.value?.[1] !== numericRange[1]) {
            const newFilter = { ...filter, value: numericRange };
            return newFilter;
          }
          return null as TableFilter;
        }
        case FilterType.single_line: {
          if (filter.value !== queryParamValue) {
            const newFilter = { ...filter, value: queryParamValue };
            return newFilter;
          }
          return null as TableFilter;
        }
        case FilterType.multi_line: {
          const multiLine = queryParamValue.replaceAll(',', '\n');
          if (filter.value !== multiLine) {
            // Although order doesn't matter in a filter, and it is technically correct to ignore the order in the check here,
            // it is not particularily easy to do an unordered compare here. And, due to the nature of how we save filters in the
            // url, and how we save filter objects, the order is _very_ likely to be the same. The only way to get out of sync is if
            // the user manually changed the order in the filter or url (unlikely). And, worst-case scenario if that happens, is that
            // we are doing an unnecessary service call. Overall, it is worth the tradeoff.
            const newFilter = { ...filter, value: multiLine };
            return newFilter;
          }
          return null as TableFilter;
        }
        default:
          return null as TableFilter;
      }
    };

    const replaceFilters = { ...this.allFilters };

    const changedFilters = Object.entries(this.allFilters).map(([key, filter]) => {
      const queryParamValue = queryParams[filter.propertyName] as string;
      if (!queryParamValue) {
        return null as TableFilter;
      }

      const newFilter = parseQueryParam(filter, queryParamValue);
      if (newFilter === null) {
        return null as TableFilter;
      }

      // We have a modified filter. Replace the original.
      replaceFilters[key] = newFilter;
      const currentFilterIndex = (this.currentFilters ?? []).findIndex(
        (f) => f.propertyName === newFilter.propertyName,
      );
      if (currentFilterIndex > -1) {
        this.currentFilters[currentFilterIndex] = {
          ...this.currentFilters[currentFilterIndex],
          value: newFilter.value,
        } as TableFilter;
      } else {
        this.currentFilters = [...(this.currentFilters ?? []), newFilter];
      }

      return newFilter;
    });

    this.allFilters = replaceFilters;
    return changedFilters.some((filter) => filter);
  }

  private getFilterWithOptions(filter: FilterWithFetchOptions): Observable<TableFilter> {
    switch (filter.filterType) {
      case FilterType.date_range:
        if (!filter.startDate) {
          this.loadingFilter = true;
          return filter.fetchOptions.pipe(
            take(1),
            map((value) => {
              const [filterName, _] = Object.entries(this.allFilters).find(
                ([_, f]) => f.propertyName === filter.propertyName,
              );
              const filterToReturn = { ...this.allFilters[filterName], startDate: value };
              this.allFilters[filterName] = filterToReturn as FilterWithFetchOptions;
              this.loadingFilter = false;
              return filterToReturn as TableFilter;
            }),
          );
        } else {
          return of(filter);
        }
      case FilterType.multi_select:
        if (!filter.multiSelectOptions) {
          this.loadingFilter = true;
          return filter.fetchOptions.pipe(
            take(1),
            map((value) => {
              const [filterName, _] = Object.entries(this.allFilters).find(
                ([_, f]) => f.propertyName === filter.propertyName,
              );
              const filterToReturn = { ...this.allFilters[filterName], multiSelectOptions: value };
              this.allFilters[filterName] = filterToReturn as FilterWithFetchOptions;
              this.loadingFilter = false;
              return filterToReturn as TableFilter;
            }),
          );
        } else {
          return of(filter);
        }
      case FilterType.numeric_range:
        if (!filter.numericRange) {
          this.loadingFilter = true;
          return filter.fetchOptions.pipe(
            take(1),
            map((value) => {
              const [filterName, _] = Object.entries(this.allFilters).find(
                ([_, f]) => f.propertyName === filter.propertyName,
              );
              const filterToReturn = { ...this.allFilters[filterName], numericRange: value };
              this.allFilters[filterName] = filterToReturn as FilterWithFetchOptions;
              this.loadingFilter = false;
              return filterToReturn as TableFilter;
            }),
          );
        } else {
          return of(filter);
        }
      default:
        return of(filter);
    }
  }

  private getSortOrderString(sortOrder: -1 | 1): 'asc' | 'desc' {
    return sortOrder ? (sortOrder === 1 ? 'asc' : 'desc') : null;
  }
  private getSortOrder(sortOrderString: 'asc' | 'desc'): 1 | -1 {
    return sortOrderString ? (sortOrderString === 'asc' ? 1 : -1) : null;
  }

  private setUpColumnsAndFilters() {
    // filter selectors
    const transactionDateRange$ = fnColumnValues$(this.store, 'transactionDate').pipe(
      map((dates) =>
        dates.map((d) => {
          const date = new Date(d);
          date.setHours(0, 0, 0, 0);
          return date;
        }),
      ),
    );
    const analysisDateRange$ = fnColumnValues$(this.store, 'analysisDate').pipe(
      map((dates) =>
        dates.map((d) => {
          const date = new Date(d);
          date.setHours(0, 0, 0, 0);
          return date;
        }),
      ),
    );
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const sources$ = fnColumnValues$(this.store, 'source').pipe(map((sources) => sources.map((s) => ({ name: s }))));
    const currencies$ = fnColumnValues$(this.store, 'transactionCurrency').pipe(
      map((currencies) => currencies.map((c) => ({ name: c }))),
    );
    const persistentMonitoring$ = fnColumnValues$(this.store, 'monitoringStatus').pipe(
      map((persistentMonitoring) => persistentMonitoring.map((pm) => ({ name: pm }))),
    );
    const categories$ = fnColumnValues$(this.store, 'category').pipe(map((values) => values.map((v) => ({ name: v }))));
    const tranactionStatuses$ = fnColumnValues$(this.store, 'transactionStatus').pipe(
      map((statuses) => statuses.map((s) => ({ name: s }))),
    );
    const tags$ = fnColumnValues$(this.store, 'tags').pipe(map((values) => values.map((v) => ({ name: v }))));
    const purchaseAmount$ = fnColumnValues$(this.store, 'purchaseAmount').pipe(
      map((amounts) => amounts.map((a) => +a)),
      map((amounts) => [Math.floor(Math.min(...amounts)), Math.ceil(Math.max(...amounts))] as [number, number]),
    );
    const locations$ = fnColumnValues$(this.store, 'acquirerCountry').pipe(
      map((c) => c.map((country) => ({ name: country }) as MultiSelectFilterOption)),
    );
    const mcc$ = fnColumnValues$(this.store, 'merchantCategoryCode').pipe(
      map((values) => values.map((v) => ({ name: v }))),
    );

    // filters
    this.allFilters = {
      legitscriptId: {
        displayName: 'LS Internal UID',
        filterType: FilterType.single_line,
        propertyName: 'legitscriptId',
      },
      network: {
        displayName: 'Network',
        filterType: FilterType.single_line,
        propertyName: 'network',
      },
      recordId: {
        displayName: 'Record ID',
        filterType: FilterType.multi_line,
        propertyName: 'id',
        exactMatch: true,
      },
      website: {
        displayName: 'Target Website',
        filterType: FilterType.multi_line,
        propertyName: 'websiteUrl',
        exactMatch: true,
      },
      client: {
        displayName: 'Client Sub',
        filterType: FilterType.single_line,
        propertyName: 'clientSub',
      },
      category: {
        displayName: 'Category',
        filterType: FilterType.multi_select,
        propertyName: 'category',
        multiSelectOptions: null,
        fetchOptions: categories$,
      },
      sources: {
        displayName: 'Source',
        filterType: FilterType.multi_select,
        propertyName: 'source',
        multiSelectOptions: null,
        fetchOptions: sources$,
      },
      requestId: {
        displayName: 'Request ID',
        filterType: FilterType.single_line,
        propertyName: 'requestId',
      },
      transactionStatus: {
        displayName: 'Transaction Status',
        filterType: FilterType.multi_select,
        propertyName: 'transactionStatus',
        multiSelectOptions: null,
        fetchOptions: tranactionStatuses$,
      },
      transactionDate: {
        displayName: 'Transaction Date',
        filterType: FilterType.date_range,
        propertyName: 'transactionDate',
        startDate: null,
        endDate: today,
        fetchOptions: transactionDateRange$.pipe(map((dates) => dates[0])),
      },
      analyzedDate: {
        displayName: 'Analyzed Date',
        filterType: FilterType.date_range,
        propertyName: 'analysisDate',
        startDate: null,
        endDate: today,
        fetchOptions: analysisDateRange$.pipe(map((dates) => dates[0])),
      },
      transactionDescription: {
        displayName: 'Transaction Item',
        propertyName: 'transactionDescription',
        filterType: FilterType.single_line,
      },
      cc: {
        displayName: 'Card Last 4 Digits',
        filterType: FilterType.single_line,
        propertyName: 'ccLast4',
      },
      currency: {
        displayName: 'Currency',
        filterType: FilterType.multi_select,
        propertyName: 'transactionCurrency',
        multiSelectOptions: null,
        fetchOptions: currencies$,
      },
      purchaseAmount: {
        displayName: 'Purchase Amount',
        filterType: FilterType.numeric_range,
        propertyName: 'purchaseAmount',
        numericRange: null,
        fetchOptions: purchaseAmount$,
      },
      mccCategory: {
        displayName: 'MCC Category',
        filterType: FilterType.single_line,
        propertyName: 'mccCategory',
      },
      cardholderLocation: {
        displayName: 'Cardholder Location',
        filterType: FilterType.multi_select,
        propertyName: 'cardholderLocation',
        multiSelectOptions: null,
        fetchOptions: locations$,
      },
      issuerLocation: {
        displayName: 'Issuer Location',
        filterType: FilterType.multi_select,
        propertyName: 'issuerLocation',
        multiSelectOptions: null,
        fetchOptions: locations$,
      },
      proxyLocation: {
        displayName: 'Proxy Location',
        filterType: FilterType.multi_select,
        propertyName: 'proxyLocation',
        multiSelectOptions: null,
        fetchOptions: locations$,
      },
      paymentWebsite: {
        displayName: 'Payment Website',
        filterType: FilterType.single_line,
        propertyName: 'paymentWebsite',
      },
      merchant: {
        displayName: 'Merchant Descriptor',
        filterType: FilterType.single_line,
        propertyName: 'merchantDescriptor',
      },
      persistentMonitoring: {
        displayName: 'Persistent Monitoring',
        filterType: FilterType.multi_select,
        propertyName: 'monitoringStatus',
        multiSelectOptions: null,
        fetchOptions: persistentMonitoring$,
      },
      // non-column filters
      transactionId: {
        displayName: 'Transaction ID / ARN',
        filterType: FilterType.single_line,
        propertyName: 'transactionId',
      },
      terminalId: {
        displayName: 'Terminal ID',
        filterType: FilterType.single_line,
        propertyName: 'terminalId',
      },
      tags: {
        displayName: 'Record Tags',
        filterType: FilterType.multi_select,
        propertyName: 'recordTags',
        multiSelectOptions: null,
        fetchOptions: tags$,
      },
      cardAcceptor: {
        displayName: 'Card Acceptor ID',
        filterType: FilterType.single_line,
        propertyName: 'cardAcceptorId',
      },
      acquirerBin: {
        displayName: 'Acquirer BIN',
        filterType: FilterType.single_line,
        propertyName: 'acquirerBin',
      },
      acquirerBid: {
        displayName: 'Acquirer BID',
        filterType: FilterType.single_line,
        propertyName: 'acquirerBid',
      },
      acquirerCountry: {
        displayName: 'Acquirer Country',
        filterType: FilterType.multi_select,
        propertyName: 'acquirerCountry',
        multiSelectOptions: null,
        fetchOptions: locations$,
      },
      acquirerRegion: {
        displayName: 'Acquirer Region',
        filterType: FilterType.single_line,
        propertyName: 'acquirerRegion',
      },
      acquirerName: {
        displayName: 'Acquirer Name',
        filterType: FilterType.single_line,
        propertyName: 'acquirerName',
      },
      merchantCategoryCode: {
        displayName: 'MCC',
        filterType: FilterType.multi_select,
        propertyName: 'merchantCategoryCode',
        multiSelectOptions: null,
        fetchOptions: mcc$,
      },
      agent: {
        displayName: 'Agent (TPA)',
        filterType: FilterType.single_line,
        propertyName: 'agent',
      },
      merchantState: {
        displayName: 'Merchant State',
        filterType: FilterType.single_line,
        propertyName: 'merchantState',
      },
      merchantCountry: {
        displayName: 'Merchant Country',
        filterType: FilterType.multi_select,
        propertyName: 'merchantCountry',
        multiSelectOptions: null,
        fetchOptions: locations$,
      },
    };

    // Columns
    this.colunnsData = [
      {
        headerValue: 'Record ID',
        fieldName: 'id',
        sortable: true,
        width: 'l',
      },
      {
        headerValue: 'Client Sub',
        fieldName: 'clientSub',
        sortable: true,
        width: 'm',
      },
      {
        headerValue: 'Target Website',
        fieldName: 'websiteUrl',
        sortable: true,
        width: 'l',
      },
      {
        headerValue: 'Category',
        fieldName: 'category',
        width: 'm',
      },
      {
        headerValue: 'Source',
        fieldName: 'source',
        width: 's',
      },
      {
        headerValue: 'Request ID',
        fieldName: 'requestId',
        sortable: true,
        width: 'l',
      },
      {
        headerValue: 'Transaction Status',
        fieldName: 'transactionStatus',
        sortable: true,
        width: 's',
      },
      {
        headerValue: 'Transaction Date',
        fieldName: 'transactionDate',
        sortable: true,
        width: 'm',
      },
      {
        headerValue: 'Analyzed Date',
        fieldName: 'analysisDate',
        sortable: true,
        width: 'm',
      },
      {
        headerValue: 'Transaction Item',
        fieldName: 'transactionDescription',
        sortable: true,
        width: 'm',
      },
      {
        headerValue: 'Card Last 4 Digits',
        fieldName: 'ccLast4',
        sortable: true,
        width: 's',
      },
      {
        headerValue: 'Transaction Amount',
        fieldName: 'transactionAmount',
        sortable: true,
        width: 's',
      },
      {
        headerValue: 'Currency',
        fieldName: 'transactionCurrency',
        sortable: true,
        width: 'xs',
      },
      {
        headerValue: 'To USD',
        fieldName: 'transactionUSDAmount',
        sortable: true,
        width: 's',
      },
      {
        headerValue: 'Purchase Amount',
        fieldName: 'purchaseAmount',
        sortable: true,
        width: 's',
      },
      {
        headerValue: 'MCC Category',
        fieldName: 'mccCategory',
        sortable: false,
        width: 's',
      },
      {
        headerValue: 'Cardholder Location',
        fieldName: 'cardholderLocation',
        sortable: true,
        width: 'l',
      },
      {
        headerValue: 'Proxy Location',
        fieldName: 'proxyLocation',
        sortable: true,
        width: 's',
      },
      {
        headerValue: 'Payment Website',
        fieldName: 'paymentWebsite',
        sortable: true,
        width: 'l',
      },
      {
        headerValue: 'Merchant Descriptor',
        fieldName: 'merchantDescriptor',
        sortable: true,
        width: 'm',
      },
      {
        headerValue: 'Analysis Note',
        fieldName: 'analysisNote',
        width: 'l',
      },
      {
        headerValue: 'Persistent Monitoring',
        fieldName: 'monitoringStatus',
        width: 'm',
      },
    ];

    this.availableFilters = [
      {
        name: 'Transaction Details',
        memberFilters: [
          this.allFilters['recordId'],
          this.allFilters['legitscriptId'],
          this.allFilters['requestId'],
          this.allFilters['website'],
          this.allFilters['category'],
          this.allFilters['network'],
          this.allFilters['sources'],
          this.allFilters['transactionStatus'],
          this.allFilters['transactionDate'],
          this.allFilters['analyzedDate'],
        ],
      },
      {
        name: 'Additional information',
        memberFilters: [
          this.allFilters['client'],
          this.allFilters['tags'],
          this.allFilters['paymentWebsite'],
          this.allFilters['cc'],
          this.allFilters['cardholderLocation'],
          this.allFilters['issuerLocation'],
          this.allFilters['agent'],
          this.allFilters['cardAcceptor'],
          this.allFilters['transactionId'],
          this.allFilters['terminalId'],
        ],
      },
      {
        name: 'Merchant Details',
        memberFilters: [
          this.allFilters['merchant'],
          this.allFilters['merchantState'],
          this.allFilters['merchantCountry'],
          this.allFilters['merchantCategoryCode'],
          this.allFilters['mccCategory'],
          this.allFilters['persistentMonitoring'],
        ],
      },
      {
        name: 'Acquirer Details',
        memberFilters: [
          this.allFilters['acquirerName'],
          this.allFilters['acquirerBin'],
          this.allFilters['acquirerBid'],
          this.allFilters['acquirerCountry'],
          this.allFilters['acquirerRegion'],
        ],
      },
    ];
  }

  private filterNeedsOptions(filter: FilterWithFetchOptions) {
    if (!filter.fetchOptions) {
      return false;
    }
    switch (filter.filterType) {
      case FilterType.binary:
      case FilterType.multi_line:
      case FilterType.single_line:
        return false;
      case FilterType.multi_select:
        return !filter.multiSelectOptions;
      case FilterType.numeric_range:
        return !filter.numericRange;
      case FilterType.date_range:
        return !filter.startDate;
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

type FilterWithFetchOptions = TableFilter & {
  fetchOptions?: Observable<MultiSelectFilterOption[] | Date | [number, number]>;
};
