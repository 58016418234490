import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HttpClientModule } from '@angular/common/http';
import { NgModule, ApplicationRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { APP_REDUCERS } from './reducers';
import { LegitscriptUiModule } from '@ls/common-ng-components';
import { LocalStorageService, ContactFormService } from '@ls/common-ng-components';
import {
  ProductsApiEnrollComponent,
  ProductsApiDocsComponent,
  ProductLookupsEnrollComponent,
  ProductLookupsSearchComponent,
  ProductLookupsListComponent,
  ProductLookupsDetailComponent,
  ProductClassificationsComponent,
  InfoBubbleComponent,
  FlagComponent,
  WebsiteLookupsEnrollComponent,
  WebsiteLookupsSearchComponent,
  WebsiteLookupsDetailComponent,
  ProductInquiryComponent,
  DataSubscriptionTermsComponent,
  DataLookupSelectComponent,
  DataLookupWrapperComponent,
  DataLookupEnrollContactComponent,
  DataLookupApprovedComponent,
  DataLookupPendingComponent,
  ProductLookupsSelectedComponent,
} from '../lookups';

import { ProductLookupService, ProductsAPILookupService } from './services';

import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { MessagesModule } from 'primeng/messages';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { NgxScrollEventModule } from '../ngx-scroll-event/ngx-scroll-event.module';

@NgModule({
  bootstrap: [
    ProductLookupsEnrollComponent,
    ProductLookupsDetailComponent,
    ProductLookupsListComponent,
    ProductLookupsSearchComponent,
    ProductsApiEnrollComponent,
    ProductsApiDocsComponent,
    WebsiteLookupsEnrollComponent,
    WebsiteLookupsSearchComponent,
    WebsiteLookupsDetailComponent,
    ProductClassificationsComponent,
    DataSubscriptionTermsComponent,
    InfoBubbleComponent,
    FlagComponent,
    DataLookupSelectComponent,
    DataLookupWrapperComponent,
    DataLookupEnrollContactComponent,
    DataLookupApprovedComponent,
    DataLookupPendingComponent,
    ProductLookupsSelectedComponent,
  ],
  declarations: [
    ProductLookupsEnrollComponent,
    ProductLookupsDetailComponent,
    ProductLookupsListComponent,
    ProductLookupsSearchComponent,
    ProductsApiEnrollComponent,
    ProductsApiDocsComponent,
    WebsiteLookupsEnrollComponent,
    WebsiteLookupsSearchComponent,
    WebsiteLookupsDetailComponent,
    ProductClassificationsComponent,
    DataSubscriptionTermsComponent,
    InfoBubbleComponent,
    FlagComponent,
    ProductInquiryComponent,
    DataLookupSelectComponent,
    DataLookupWrapperComponent,
    DataLookupEnrollContactComponent,
    DataLookupApprovedComponent,
    DataLookupPendingComponent,
    ProductLookupsSelectedComponent,
  ],
  imports: [
    CommonModule,
    // BrowserModule,
    // BrowserAnimationsModule,
    FormsModule,
    // HttpClientModule,
    ReactiveFormsModule,
    TableModule,
    DialogModule,
    PanelModule,
    MessagesModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    SelectButtonModule,
    RouterModule,
    StoreModule.forFeature('lookups', APP_REDUCERS),
    NgxScrollEventModule,
    LegitscriptUiModule,
  ],
  providers: [LocalStorageService, ProductLookupService, ProductsAPILookupService, ContactFormService],
  exports: [
    ProductLookupsEnrollComponent,
    ProductLookupsDetailComponent,
    ProductLookupsListComponent,
    ProductLookupsSearchComponent,
    WebsiteLookupsEnrollComponent,
    WebsiteLookupsSearchComponent,
    WebsiteLookupsDetailComponent,
    ProductsApiEnrollComponent,
    ProductsApiDocsComponent,
    DataLookupSelectComponent,
    DataLookupWrapperComponent,
    DataLookupEnrollContactComponent,
    DataLookupApprovedComponent,
    DataLookupPendingComponent,
    ProductLookupsSelectedComponent,
  ],
})
export class LookupsModule {
  constructor(public appRef: ApplicationRef) {}
}
