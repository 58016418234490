import { CommonModule } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HttpClientModule } from '@angular/common/http';
import { NgModule, ApplicationRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { APP_REDUCERS } from './reducers';
import { APP_EFFECTS } from './effects';
import { LegitscriptUiModule } from '@ls/common-ng-components';

import { TableModule } from 'primeng/table';
import { MessagesModule } from 'primeng/messages';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { CertificationService, LocalStorageService, EnrollService } from './services';

import {
  MerchantCertificationComponent,
  CertificationListComponent,
  CertificationTypeSelectComponent,
  GenericEnrollComponent,
  DaatcEnrollComponent,
  HealthcareEnrollComponent,
  CbdEnrollComponent,
  CertificationCompleteComponent,
  CertificationSubmittedComponent,
  CertificationQuestionComponent,
  CertificationQuestionnaireWrapperComponent,
  ProgressTrackerComponent,
  CertificationRenewalInstructionsComponent,
  CertifiableItemQuestionComponent,
  HealthcareTermsComponent,
  CbdTermsComponent,
  CbdDataTermsComponent,
  DaatcTermsComponent,
  DaatcPricingInfoComponent,
  CbdProductPricingInfoComponent,
  CbdWebsitePricingInfoComponent,
  HealthcarePricingInfoComponent,
  ExpeditedPricingInfoComponent,
  CertificationPricingUpdateComponent,
} from '../certifications';

import { NgxScrollEventModule } from '../ngx-scroll-event/ngx-scroll-event.module';

@NgModule({
  bootstrap: [MerchantCertificationComponent, HealthcareEnrollComponent, DaatcEnrollComponent],
  declarations: [
    MerchantCertificationComponent,
    CertificationListComponent,
    CertificationTypeSelectComponent,
    CertificationCompleteComponent,
    CertificationSubmittedComponent,
    CertificationQuestionComponent,
    CertificationQuestionnaireWrapperComponent,
    ProgressTrackerComponent,
    CertificationRenewalInstructionsComponent,
    CertificationPricingUpdateComponent,
    CertifiableItemQuestionComponent,
    GenericEnrollComponent,
    HealthcareEnrollComponent,
    HealthcareTermsComponent,
    HealthcarePricingInfoComponent,
    DaatcEnrollComponent,
    DaatcPricingInfoComponent,
    DaatcTermsComponent,
    CbdEnrollComponent,
    CbdProductPricingInfoComponent,
    CbdWebsitePricingInfoComponent,
    CbdTermsComponent,
    CbdDataTermsComponent,
    ExpeditedPricingInfoComponent,
  ],
  imports: [
    CommonModule,
    // BrowserModule,
    // BrowserAnimationsModule,
    FormsModule,
    // HttpClientModule,
    ReactiveFormsModule,
    TableModule,
    DialogModule,
    PanelModule,
    MessagesModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    MenuModule,
    RouterModule,
    EffectsModule.forFeature(APP_EFFECTS),
    StoreModule.forFeature('certification', APP_REDUCERS),
    NgxScrollEventModule,
    LegitscriptUiModule,
    TableModule,
    ToastModule,
    TooltipModule,
  ],
  providers: [CertificationService, EnrollService, LocalStorageService],
  exports: [
    MerchantCertificationComponent,
    HealthcareEnrollComponent,
    DaatcEnrollComponent,
    CbdEnrollComponent,
    CertificationTypeSelectComponent,
    CertificationCompleteComponent,
    CertificationSubmittedComponent,
    CertificationQuestionComponent,
    CertificationQuestionnaireWrapperComponent,
    CertificationRenewalInstructionsComponent,
    CertifiableItemQuestionComponent,
    CertificationPricingUpdateComponent,
    CertificationListComponent,
  ],
})
export class CertificationsModule {
  constructor(public appRef: ApplicationRef) {}
}
