<span class="mm-ls-risk-action-labels">
  <ng-container *ngIf="lsRiskActions.length">
    <ng-container *ngFor="let action of lsRiskActionsDisplay">
      <span *ngIf="lsRiskActions.includes(action.field)" [class]="action.class" [title]="action.title">{{action.displayName}}</span>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="emptyPlaceholder !== undefined && !lsRiskActions.length">
    <span>{{emptyPlaceholder}}</span>
  </ng-container>
</span>
