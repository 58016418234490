import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { LegitScriptModalComponent } from '@ls/common-ng-components';
import { FormGroup } from '@angular/forms';
import { UsersService } from '../../../services/users.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.scss'],
})
export class UpdateEmailComponent implements OnInit, OnDestroy {
  public email: string;
  public form: FormGroup;
  public processing = false;
  public success = false;
  public validationMsgs = [];
  public errorMsg: string;
  @ViewChild(LegitScriptModalComponent) private editUserModal: LegitScriptModalComponent;

  private updateSubscription$: Subscription;

  constructor(private userService: UsersService) {}

  public ngOnInit() {
    this.form = new FormGroup({});
    this.success = false;
  }

  public emailSet(email: string) {
    this.email = email;
  }

  public showModal() {
    this.success = false;
    this.editUserModal.showDialog();
  }

  public hideModal() {
    this.editUserModal.hideDialog();
  }

  public cancel() {
    this.editUserModal.hideDialog();
    this.form.reset();
    this.errorMsg = null;
  }

  public submit() {
    if (!this.email || !this.form.valid) {
      this.errorMsg = 'Emails must match.';
    }

    this.processing = true;
    this.userService.requestEmailUpdate(this.email).subscribe(
      () => {
        this.processing = false;
        this.success = true;
      },
      (err) => {
        this.processing = false;
        this.success = false;
        this.errorMsg = 'Failed to update email. Please try again';
        if (err.error && typeof err.error === 'string' && err.error.includes('Email already in use')) {
          this.errorMsg = err.error;
        }
      },
    );
  }

  public ngOnDestroy() {
    if (this.updateSubscription$) {
      this.updateSubscription$.unsubscribe();
    }
  }
}
