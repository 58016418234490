import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BillingService } from '@ls/common-ng-components';
import { Opportunity } from '@ls/common-ts-models';

@Component({
  selector: 'approved-lookup',
  styleUrls: ['../data-lookup.scss', '../../styles/enroll.scss'],
  templateUrl: './approved.html'
})
export class DataLookupApprovedComponent {
  @Input() public opportunity: Opportunity;

  constructor(private billingSvc: BillingService, private router: Router) {}

  public goToPayment() {
    this.billingSvc.setPricingInfo({
      billingItems: [
        {
          description: 'Database Search',
          amount: Number(this.billingSvc.prorateRemainderOfMonth(new Date(), this.opportunity.Amount)),
          recurringAmount: Number(this.opportunity.Amount),
          recurringPeriod: 'monthly',
          recurringDescription: 'Subscription',
          prorated: true,
          quantity: 1
        }
      ],
      maxCCAmount: 10000,
      oppId: this.opportunity.Id,
      oppType: 'API Subscription',
      successPage: '/services/lookups/data/select'
    });
    this.router.navigate(['services', 'payment']);
  }
}
