<legitscript-modal #modal [draggable]=false [resizable]=false [closable]=false [responsive]=true [modal]=true autoAlign=true [width]=800>
    <div class="dialog-body" style="overflow:auto; height: 75vh; padding: .5em; display: grid; grid-row: auto;">
      <div style="max-height: 30%" [ngClass]="{'gdpr': gdprSmallScreen}">
          <h1>Before you can continue, you must accept changes to our Terms and Conditions.</h1>
          <div>
            <form #form="ngForm">
                <div class="span6" style="text-align: justify">
                    By clicking yes, you freely consent for LegitScript LLC to use and process any personal data submitted within the portal. 
                    See our <a href="https://www.legitscript.com/privacy-policy/" target="_blank">Privacy Policy</a> for information about 
                    how LegitScript will use and process your data.
                </div>
                
                  <div class="form-group span6">
                    <div class="radio-group">
                      <input #gdpr="ngModel" name="gdpr" id="gdprYes" value="yes" type="radio" [(ngModel)]="model.gdpr" (change)="gdprUpdate()" required>
                      <label for="gdprYes">Yes*</label>
                      <input #gdpr="ngModel" name="gdpr" id="gdprNo" value="no" type="radio" [(ngModel)]="model.gdpr" (change)="gdprUpdate()">
                      <label for="gdprNo">No</label>
                      <field-validator class="right" [control]="gdpr"></field-validator>
                    </div>
                  </div>
      
                  <div *ngIf="error!==undefined" [ngSwitch]="error" class="form-group span6 validation-error-container">
                    <span>
                      In order to continue, you must consent to the processing of personal data
                    </span>
                  </div>
            </form>
          </div>          
      </div>

      

      <div style="overflow:auto; max-height: 100%" libScrollEvent (onscroll)="onDialogScroll($event)" [ngClass]="{'terms': !gdprSmallScreen}">
        <terms-copy></terms-copy>
      </div>
    </div>
    <div class="dialog-footer">
        <button [ngClass]="{'continue-button': !continued, 'gdpr': gdprSmallScreen}" type="button" (click)="continue()" [disabled]="model.gdpr !== 'yes'">Continue</button>
        <button [ngClass]="{'terms': !gdprSmallScreen}" type="button" (click)="onAgreeToTerms();" label="Yes" [disabled]="hasScrolledToBottom === false || model.gdpr !== 'yes'">I Agree</button>
      </div>
  </legitscript-modal>





