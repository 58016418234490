import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, switchMap, of, catchError, throwError, BehaviorSubject, filter, take } from 'rxjs';
import { LocalStorageService } from '@ls/common-ng-components';
import { environment } from '../../../../environments/environment';

interface TokenData {
  token: string;
  timestamp: Date;
}

interface ResponseData {
  accessToken: string;
  tokenType: string;
  scope: string;
}

const HOUR = 59 * 60 * 1000; // 59 minutes

@Injectable()
export class PortalApiAuthInterceptor implements HttpInterceptor {
  private tokenCache: TokenData = null;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Omit the interceptor if the request is not to the onboarding portal API
    if (!request.url.includes(environment.CONFIG.merchantOnboardingApiHost)) {
      return next.handle(request);
    }

    // Skip intercepting the access-token call itself to avoid circular condition
    if (request.url.includes('/access-token')) {
      return next.handle(request);
    }

    return this.handleRequest(request, next);
  }

  private handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isTokenValid()) {
      return this.handleRequestWithToken(request, next, this.tokenCache.token);
    }

    return this.fetchAccessToken().pipe(
      switchMap((token) => {
        if (!token) {
          return next.handle(request);
        }
        return this.handleRequestWithToken(request, next, token);
      }),
    );
  }

  private handleRequestWithToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: string,
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addTokenToRequest(request, token)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        }
        return throwError(() => error);
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.fetchAccessToken().pipe(
        switchMap((token) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          if (token) {
            return next.handle(this.addTokenToRequest(request, token));
          }
          return next.handle(request);
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(null);
          return throwError(() => err);
        }),
      );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => {
        return next.handle(this.addTokenToRequest(request, token));
      }),
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private fetchAccessToken(): Observable<string | null> {
    const lsAccountId = this.localStorageService.getAuthenticatedUser()?.lsAccountId;
    const ssoAuthToken = this.localStorageService.getToken();

    if (!lsAccountId || !ssoAuthToken) {
      return of(null);
    }

    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${ssoAuthToken}`,
        'Content-Type': 'application/json',
      }),
    };

    // Make a direct HTTP request that bypasses this interceptor
    return this.http
      .get<ResponseData>(
        `${environment.CONFIG.merchantOnboardingApiHost}/customers/${lsAccountId}/access-token`,
        options,
      )
      .pipe(
        switchMap((response: ResponseData) => {
          this.tokenCache = {
            token: response.accessToken,
            timestamp: new Date(),
          };
          return of(response.accessToken);
        }),
        catchError((err: any) => {
          console.log('Error fetching access token:', err);
          return of(null);
        }),
      );
  }

  private isTokenValid(): boolean {
    return this.tokenCache?.timestamp && Date.now() - this.tokenCache.timestamp.getTime() < HOUR;
  }
}
