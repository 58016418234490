<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->

<div class="entry-main">
  <div class="entry-container entry-container-small">
    <h1>Almost done!</h1>

    <div class="byline">
      <p class="subtext mb30 left"><a (click)="back()">&lt; Back</a></p>
      <p class="subtext mb30 right">Fields marked with a * are required</p>
    </div>
    <form (ngSubmit)="captcha.execute()" #form="ngForm" novalidate>
      <div class="create-account-form password-stage form-container form-container-public">
        <div class="form-group span3">
          <field-validator class="right" [control]="password"></field-validator>
          <div class="label-validator">
            <input #password="ngModel" name="password" type="password" [(ngModel)]="model.password" placeholder=" " autofocus
            required minlength="10" maxlength="255" (keyup)="form.controls['confirmPassword'].updateValueAndValidity();" aria-label="password" />
            <label for="password" class="left">Password*</label>
          </div>
        </div>

        <div class="form-group span3">
          <field-validator class="right" [control]="confirmPassword"></field-validator>
          <div class="label-validator">
            <input #confirmPassword="ngModel" name="confirmPassword" type="password" [(ngModel)]="passwordConfirm" placeholder=" "
            field-match="password" required aria-label="confirm password" />
            <label for="confirmPassword" class="left">Confirm Password*</label>
          </div>
        </div>

        <div class="form-group span6">
          <div class="terms-conditions">
            <div style="display:inline-block;">
              <field-validator class="left" style="text-align:left" [control]="termsCheckbox"></field-validator>
              <label class="checkbox">
                <input #termsCheckbox="ngModel" id="termsCheckbox" name="termsCheckbox" type="checkbox" (change)="onTermsChecked($event)"
                  [(ngModel)]="termsSelected" required aria-label="terms" />
                <span></span>
              </label>
              <label for="termsCheckbox">I have read and agree to LegitScript's</label>
              <a (click)="modal.showDialog()">Terms of Service</a>

            </div>
          </div>
        </div>

        <!-- ERROR SECTION ****************************************************** -->
        <div *ngIf="createError!==undefined" [ngSwitch]="createError" class="form-group span6 validation-error-container">
          <span *ngSwitchCase="errorType.DuplicateEmail">
            An account already exists for your email address. Try <a routerLink="/login">signing in</a> or resetting your password
          </span>
          <span *ngSwitchCase="errorType.DuplicateCompany">
            An account already exists for a company by that name. If you are an existing client, contact your account administrator for access. Otherwise, use a different Company Name, or <a href="https://help.legitscript.com" target="_blank">contact customer support</a>
          </span>
          <span *ngSwitchCase="errorType.DuplicateSalesforce">
            Your details match an account that already exists. Please <a href="https://help.legitscript.com" target="_blank">contact customer support</a>
          </span>
          <span *ngSwitchCase="errorType.BadForm">
            All fields are required
          </span>
          <span *ngSwitchDefault>
            An unexpected error occurred during account creation. Please <a href="https://help.legitscript.com" target="_blank">contact customer support</a>
          </span>
        </div>

        <!-- INVISIBLE CAPTCHA ******************************************************** -->
        <div class="form-group span6">
          <re-captcha #captcha size="invisible" (resolved)="resolvedCaptcha($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
        </div>

        <!-- SUBMIT BUTTON ************************************************************ -->
        <div class="form-actions">
          <button *ngIf="!loading" [disabled]="form.submitted && (form.invalid || model.captchaResponse === undefined)" class="btn btn-primary">Create Account</button>
          <div class="loading-container" [hidden]="!loading">
            <p-progressSpinner [style]="{ width: '35px', height: '35px' }"></p-progressSpinner>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<legitscript-modal #modal [draggable]=false [resizable]=false [responsive]=true [closable]=true [modal]=true autoAlign=true [width]="800">
  <div class="dialog-body" style="overflow:auto; height:75vh" libScrollEvent (onscroll)="onDialogScroll($event)">
   <terms-copy></terms-copy>
  </div>
  <div class="dialog-footer">
    <button type="button" (click)="onAgreeToTerms();" label="Yes" [disabled]="hasScrolledToBottom === false">I Agree</button>
  </div>
</legitscript-modal>
