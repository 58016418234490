import { TagsConstants } from '@ls/common-ts-models';

export class CustomerTagsValidator {
  public static validate(tags: string) {
    if (!tags) {
      return true;
    }

    if (typeof tags !== 'string') {
      return false;
    }

    const isMultiTag = tags.includes(',');
    if (isMultiTag) {
      const isValidArray = CustomerTagsValidator.isValidTagsArray(tags);
      return isValidArray;
    }

    return true;
  }

  public static isValidTagsArray(tagString: string): boolean {
    const tags = tagString.split(',');
    const hasInvalidString = tags.find(tag => typeof tag !== 'string' || CustomerTagsValidator.isEmptyTag(tag));
    if (hasInvalidString !== undefined) {
      return false;
    }
    const isValidLength = tags.length <= TagsConstants.REQUEST_MAX;
    console.log(isValidLength);
    return isValidLength;
  }

  private static isEmptyTag(field: any): boolean {
    return field === null || field === undefined || field === '';
  }
}
