import { of as observableOf } from 'rxjs';

import { map, skip, mergeMap, takeUntil, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  SelectableAccountLoadSuccessAction,
  SelectableAccountLoadErrorAction,
  UserAuthenticateSuccessAction,
} from '../reducers';

import { AccountService } from '../services';

@Injectable()
export class SelectableAccountEffect {
  public selectableAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthenticateSuccessAction),
      map((action) => action.user),
      mergeMap(() => {
        const nextSearch$ = this.actions$.pipe(ofType(UserAuthenticateSuccessAction), skip(1));

        return this.accountService.getAccountsForUser().pipe(
          takeUntil(nextSearch$),
          map((accounts) => {
            return SelectableAccountLoadSuccessAction({ accounts });
          }),
          catchError(() => {
            return observableOf(SelectableAccountLoadErrorAction({ errorText: 'Error occured loading account names' }));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
  ) {}
}
