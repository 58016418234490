<div id="access-denied">
    <div class="container">
        <div>
            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="50" fill="#FFDBDB"/>
                <path d="M24 24L49.9999 50M49.9999 50L75.9998 76M49.9999 50L76 24M49.9999 50L24.0002 76" stroke="#FF4C4C" stroke-width="5" stroke-linecap="square"/>
            </svg>
        </div>
        <div>
            <h1>Access Denied</h1>
        </div>
        <div>
            <p>You don't have permissions to access this page. Please contact your LegitScript account manager to get permissions or sign out and log in with a different account.</p>
        </div>
        <div>
            <button (click)="signOut()">Sign out</button>
        </div>
    </div>
</div>