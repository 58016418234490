<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div style="position:relative" class="editable-field">
  <div *ngIf="!editing" [ngSwitch]="inputType">
    <span *ngSwitchCase="'url'">
      <a class="ellipsis-clamp-4-lines" *ngIf="field; else empty" href="{{field}}" target="_BLANK">{{field}}</a>
      <ng-template #empty></ng-template>
    </span>
    <span *ngSwitchDefault>
      <span>{{field}}</span>
    </span>
    <span [pTooltip]="tooltip" class="fa fa-pencil icon-button" (click)="edit()" *ngIf="canEdit"></span>
  </div>
  <div *ngIf="editing" class="editing">
    <div class="edit-input">
      <span class="block" >
          <input [ngStyle]="{'width': width, 'height': '1.2em', 'font-size': 'inherit'}" [type]="inputType" [(ngModel)]="editableField" [class.errorBorder]="validationError">
      </span>
    </div>
    <div class="icons" *ngIf="editing">
        <span class="fa fa-check icon-button check-icon" (click)="save()"></span>
        <span class="fa fa-times icon-button times-icon" (click)="cancel()"></span>                                                          
    </div>  
  </div>
  <label class="error" *ngIf="validationError">*{{validationError}}</label>
</div>