<div class="data-wrapper">
  <div class="title">{{title}}</div>
  <div class="data" *ngIf="useTag; else noTag">
    <ls-tag *ngFor="let item of internalData()" [tagInput]="item">
    </ls-tag>
  </div>
  <ng-template #noTag>
    <div *ngFor="let item of internalData()" class="data">{{item}}</div>
  </ng-template>
</div>
