import { Component, ViewEncapsulation, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UpdateShowInstructionsAction } from '../reducers';
import { LegitScriptModalComponent } from '@ls/common-ng-components';
import { Subject } from 'rxjs';
import { tap, map, distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';
import { AppState, fnQuestionnaireState } from 'src/app/reducers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cert-renewal-instructions',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './certification-renewal-instructions.component.html',
})
export class CertificationRenewalInstructionsComponent implements OnDestroy, AfterViewInit {
  public destroyed$: Subject<boolean> = new Subject();
  @Input() public showLegacyText = false;
  @ViewChild(LegitScriptModalComponent) private modal: LegitScriptModalComponent;

  public experienceCloudUrl = environment.CONFIG.experienceCloudURL;

  constructor(public store: Store<AppState>) {}
  ngAfterViewInit(): void {
    this.store
      .select(fnQuestionnaireState)
      .pipe(
        distinctUntilKeyChanged('showRenewalInstructions'),
        map((q: any) => q.showRenewalInstructions),
        tap((show) => {
          if (show) {
            this.modal.showDialog();
          } else {
            this.modal.hideDialog();
          }
        }),
        takeUntil(this.destroyed$.asObservable()),
      )
      .subscribe();
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public hideInstructions() {
    this.store.dispatch(UpdateShowInstructionsAction({ showInstructions: false }));
  }
}
