<div class="details-container">
  <div class="triple-column">
    <ls-transaction-details-data title="Client Sub" [data]="testTransaction?.clientSub"></ls-transaction-details-data>
    <ls-transaction-details-data title="Status" [data]="testTransaction?.transactionStatus" [useTag]="true"></ls-transaction-details-data>
  </div>
  <div class="screenshots">
    <div class="screenshot-header">
      <div class="title">Screenshots ({{testTransaction?.screenshots?.length ?? 0}})</div>
      <div>
        <loading-spinner [inline]=true  *ngIf="isDownloadLoading || isZipping" class="download-spinner"></loading-spinner>
        <span [ngClass]="{'isLoading': isDownloadLoading}" *ngIf="!loading && testTransaction?.screenshots && testTransaction?.screenshots?.length === 0" class="request-screenshots-btn" (click)="onRequestScreenshotsClick()" (keyup.enter)="onRequestScreenshotsClick()" tabindex="0">Request Screenshots</span>
        <span [ngClass]="{'isLoading': isDownloadLoading || isZipping}" *ngIf="!loading && testTransaction?.screenshots && testTransaction?.screenshots?.length >= 1" (click)="onDownloadAllClick()" (keyup.enter)="onDownloadAllClick()" tabindex="0">Download all</span>
      </div>
    </div>
    <div class="screenshot" *ngFor="let screenshot of getSortedScreenshots()">
      <span (click)="onShowScreenshots(screenshot)" (keyup.enter)="onShowScreenshots(screenshot)" tabindex="0">
        {{screenshot.name | midTruncate:29:7}}
      </span>
      <img src="assets/svg/icons/download.svg" alt="Download Image" (click)="onDownloadClick(screenshot.id)" (keyup.enter)="onDownloadClick(screenshot.id)" tabindex="0">
    </div>
  </div>
  <div class="notes">
    <div class="title">Analysis Note</div>
    <div class="text-field">{{testTransaction?.analysisNote}}</div>
  </div>
  <div class="grouping-header">Tracking Info</div>
  <div class="triple-column">
    <ls-transaction-details-data title="Record Tags" [data]="testTransaction?.recordTags" [useTag]="true"></ls-transaction-details-data>
    <ls-transaction-details-data title="Source" [data]="testTransaction?.source"></ls-transaction-details-data>
    <ls-transaction-details-data title="Request ID" [data]="testTransaction?.requestId"></ls-transaction-details-data>
    <ls-transaction-details-data title="LS Internal UID" [data]="testTransaction?.legitscriptId"></ls-transaction-details-data>
  </div>
  <div class="grouping-header">LS Data</div>
  <div class="triple-column">
    <ls-transaction-details-data title="Category" [data]="testTransaction?.category" [useTag]="true"></ls-transaction-details-data>
    <ls-transaction-details-data title="Network" [data]="testTransaction?.network"></ls-transaction-details-data>
    <ls-transaction-details-data title="Analyzed Date" [data]="testTransaction?.analysisDate"></ls-transaction-details-data>
    <ls-transaction-details-data title="Purchase Amount" [data]="testTransaction?.purchaseAmount"></ls-transaction-details-data>
    <ls-transaction-details-data title="Currency" [data]="testTransaction?.transactionCurrency"></ls-transaction-details-data>
    <ls-transaction-details-data title="To USD" [data]="testTransaction?.transactionUSDAmount | currency: 'USD'"></ls-transaction-details-data>
    <ls-transaction-details-data title="Card Last 4 Digits" [data]="testTransaction?.ccLast4"></ls-transaction-details-data>
    <ls-transaction-details-data title="Transaction Item" [data]="testTransaction?.transactionDescription"></ls-transaction-details-data>
    <ls-transaction-details-data title="Cardholder Location" [data]="testTransaction?.cardholderLocation"></ls-transaction-details-data>
    <ls-transaction-details-data title="Issuer Location" [data]="testTransaction?.issuerLocation"></ls-transaction-details-data>
    <ls-transaction-details-data title="Payment Website" [data]="testTransaction?.paymentWebsite"></ls-transaction-details-data>
  </div>
  <div class="grouping-header">Client Data</div>
  <div class="triple-column">
    <ls-transaction-details-data title="Merchant Descriptor" [data]="testTransaction?.merchantDescriptor" [useTag]="true"></ls-transaction-details-data>
    <ls-transaction-details-data title="Transaction Amount" [data]="testTransaction?.transactionAmount | currency: testTransaction?.transactionCurrency"></ls-transaction-details-data>
    <ls-transaction-details-data title="Transaction Date" [data]="testTransaction?.transactionDate"></ls-transaction-details-data>
    <ls-transaction-details-data title="MCC" [data]="testTransaction?.merchantCategoryCode"></ls-transaction-details-data>
    <ls-transaction-details-data title="MCC Category" [data]="testTransaction?.mccCategory"></ls-transaction-details-data>
    <ls-transaction-details-data title="Agent (TPA)" [data]="testTransaction?.agent"></ls-transaction-details-data>
    <ls-transaction-details-data title="Merchant Country" [data]="testTransaction?.merchantCountry"></ls-transaction-details-data>
    <ls-transaction-details-data title="Card Acceptor" [data]="testTransaction?.cardAcceptorId"></ls-transaction-details-data>
    <ls-transaction-details-data title="Transaction ID/ARN" [data]="testTransaction?.transactionId"></ls-transaction-details-data>
    <ls-transaction-details-data title="Acquirer Name" [data]="testTransaction?.acquirerName"></ls-transaction-details-data>
    <ls-transaction-details-data title="Acquirer BIN" [data]="testTransaction?.acquirerBin"></ls-transaction-details-data>
    <ls-transaction-details-data title="Acquirer BID" [data]="testTransaction?.acquirerBid"></ls-transaction-details-data>
    <ls-transaction-details-data title="Acquirer Country" [data]="testTransaction?.acquirerCountry"></ls-transaction-details-data>
    <ls-transaction-details-data title="Acquirer Region" [data]="testTransaction?.acquirerRegion"></ls-transaction-details-data>
    <ls-transaction-details-data title="Rights Holder" [data]="testTransaction?.rightsHolder"></ls-transaction-details-data>
  </div>
</div>