<legitscript-modal class="no-footer" #manageUserModal [draggable]=false [resizable]=false [responsive]=true [closable]="false" [modal]=true autoAlign=true [width]=800 height=auto>
  <div class="dialog-body" style="overflow:auto; text-align: left;">
    <h1>{{action === 'invite' ? 'Invite' : 'Edit'}} User</h1>
    <form name="form" (ngSubmit)="submit(f)" #f="ngForm" novalidate>
      <loading-spinner [loadingText]="'Processing'" *ngIf="processing" [transparent]="true"></loading-spinner>
      <div *ngIf="action === 'invite'" class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
        <div class="label-wrapper">
          <label for="email">Email</label>
          <field-validator class="right" [control]="email"></field-validator>
        </div>
        <input type="text" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel"
          maxlength="{{MAX_EMAIL_LENGTH}}" required checkWhitespace email />
      </div>

      <div class="form-group-outer">
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !firstName.valid }">
          <div class="label-wrapper">
            <label for="firstName">First Name</label>
            <field-validator class="right" [control]="firstName"></field-validator>
          </div>
          <input type="text" class="form-control" name="firstName" [(ngModel)]="model.firstName" #firstName="ngModel" maxlength="{{MAX_FIRST_NAME_LENGTH}}" required checkWhitespace checkInvalidCharacter />
      </div>

      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !lastName.valid }">
          <div class="label-wrapper">
            <label for="lastName">Last Name</label>
            <field-validator class="right" [control]="lastName"></field-validator>
          </div>
          <input type="text" class="form-control" name="lastName" [(ngModel)]="model.lastName" #lastName="ngModel" maxlength="{{MAX_LAST_NAME_LENGTH}}" required checkWhitespace checkInvalidCharacter />
        </div>
      </div>

      <div class="form-group-outer">
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !phone.valid }">
          <div class="label-wrapper">
            <label for="phone">Phone</label>
            <field-validator class="right" [control]="phone"></field-validator>
          </div>
          <input type="text" class="form-control" name="phone" [(ngModel)]="model.phone" #phone="ngModel" maxlength="{{MAX_PHONE_LENGTH}}" required checkWhitespace />
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !position.valid }">
          <div class="label-wrapper">
            <label for="title">Title/Position</label>
            <field-validator class="right" [control]="position"></field-validator>
          </div>
          <input type="text" class="form-control" name="position" [(ngModel)]="model.position" #position="ngModel" maxlength="{{MAX_POSITION_LENGTH}}" required checkWhitespace />
        </div>
      </div>

      <div *ngIf="action === 'invite'" class="form-group">
        By inviting this user, I authorize them to provide information and submit materials on behalf of {{companyName}}. Any actions, information, or materials submitted by this user will be treated as if submitted by {{companyName}}. By using this account, all users acknowledge notice of, and agree to comply with, LegitScript's terms and conditions of use and all other applicable policies. Unauthorized or improper use of this account may result in actions including, but not limited to, termination of {{companyName}}'s account. By continuing to use this system you indicate your awareness of and consent to these terms and conditions of use.
      </div>

      <div class="form-footer">
        <button type="submit" class="submit-button" label="Submit">Submit</button>
        <button type="button" (click)="cancel(f)" label="Cancel">Cancel</button>
      </div>
    </form>
  </div>
</legitscript-modal>