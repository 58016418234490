import { createAction, createReducer, on, props } from '@ngrx/store';
import { TransactionsSummary200Response } from '../angular-client/model/transactionsSummary200Response';
import { TableFilter } from '@ls/common-ng-components';

export const TestTransactionsGetSummaryAction = createAction(
  '[Test Transactions] Get Summary',
  props<{ filters?: TableFilter[] }>(),
);
export const TestTransactionsGetSummarySuccessAction = createAction(
  '[Test Transactions] Get Summary Success',
  props<TransactionsSummary200Response>(),
);
export const TestTransactionsGetSummaryErrorAction = createAction(
  '[Test Transactions] Get Summary Error',
  props<{ errorText: string; errorType: number }>(),
);

export interface TestTransactionsSummaryState {
  error?: {
    errorText;
    errorType;
  };
  summary: TransactionsSummary200Response;
  pending: boolean;
}

const initialState: TestTransactionsSummaryState = {
  summary: {
    Not_Tested_No_CCs: 0,
    Not_Tested_Non_Client_CCs_only: 0,
    Tested_Hit: 0,
    Tested_No_Hit: 0,
  },
  pending: false,
};

export const TestTransactionsSummaryReducer = createReducer(
  initialState,

  on(TestTransactionsGetSummaryAction, (state) => ({
    ...state,
    pending: true,
  })),

  on(TestTransactionsGetSummarySuccessAction, (state, props) => ({
    ...state,
    summary: props,
    pending: false,
  })),

  on(TestTransactionsGetSummaryErrorAction, (state, props) => ({
    ...state,
    error: props,
    pending: false,
  })),
);
