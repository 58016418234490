import { NgModule } from '@angular/core';
import { AuthenticationGuard } from './authentication.guard';
import { EnsurePricingInfoGuard } from './ensurepricinginfo.guard';
import { OwnersOnlyGuard } from './owners-only.guard';
import { EnforceTermsGuard } from './enforce-terms.guard';
import { EntitlementsGuard } from './entitlements.guard';
import { EnrollGuard } from './enroll.guard';
import { DisabledFeatureGuard } from './disabled-feature.guard';
import { MaintenanceModeGuard } from './maintenance-mode.guard';
import { MaintenanceModeEnabledGuard } from './maintenance-mode-enabled.guard';
import { CanDeactivateGuard } from './candeactivate.guard';
import { MMDocsGuard } from './mm-docs.guard';
import { Auth0RedirectGuard } from './auth0-redirect.guard';
import { SignOutGuard } from './sign-out.guard';

@NgModule({
  imports: [],
  exports: [],
  providers: [
    Auth0RedirectGuard,
    AuthenticationGuard,
    EnsurePricingInfoGuard,
    OwnersOnlyGuard,
    EnforceTermsGuard,
    EntitlementsGuard,
    EnrollGuard,
    DisabledFeatureGuard,
    MaintenanceModeGuard,
    MaintenanceModeEnabledGuard,
    CanDeactivateGuard,
    MMDocsGuard,
    SignOutGuard,
  ],
  declarations: [],
})
export class GuardsModule {}
