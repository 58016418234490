<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
  <div class="merchant-detail-container">
  <div class="error" *ngIf="err">{{err.message}}. Please try again or contact support if the problem persists.</div>

  <loading-spinner class="loading-container" *ngIf="loading || (!merchant && !err)"
    loadingText="Loading Merchant Details"></loading-spinner>
 
  <!-- Spinner for form submit-->
  <loading-spinner [transparent]='true' [maxZIndex]='true' class="loading-container" *ngIf="formSubmitLoading"
    loadingText="Loading Merchant Details"></loading-spinner>

  <div *ngIf="merchant && !loading">
    <div class="widget" style="margin-bottom: 2em;">
      <div class="widget-header">
        <h2>
          <div class="header-edit-wrapper">
            <span><i class="fa fa-binoculars"></i>&nbsp;Merchant ID:</span>
            <span class="italic">
              <editable-field-text [field]="merchant.mid" inputType="text" [tooltip]="'Edit Merchant Id'"
                [canEdit]="canEdit" (updateField)="editField('mid', $event)" [required]="true"
                [maxLength]="50"></editable-field-text>
            </span>
          </div>
          <a routerLink="/services/merchant-monitoring/list">
            <i class="fa fa-undo"></i>
            <span>Back to list</span>
          </a>
        </h2>
      </div>
      <div class="widget-body centered">
        <section class="overview" *ngIf="merchant">
          <div class="grid">
            <div class="column">
              <div class="item">
                <label>Merchant Name</label>
                <editable-field-text [field]="merchant.merchant_name" inputType="text"
                  [tooltip]="'Edit Merchant Name'" inputType="text" [canEdit]="canEdit"
                  (updateField)="editField('merchant_name', $event)" [required]="true"
                  [maxLength]="255"></editable-field-text>
              </div>

              <div class="item">
                <label>Merchant URLS</label>
                <editable-list [editing]="editingList" [list]="domainFromAcquirerList" [editable]="canEdit"
                  [validationError]="domainUpdateValidationError" (onSavedEvent)="updateEditing($event)"
                  (onCancelledEvent)="clearDomainFromAcquirerError()"
                  (listChangeEvent)="updateDomainFromAcquirerList($event)">
                </editable-list>
              </div>

              <div class="item">
                <label>Submitted Date</label>
                <div>{{merchant.submitted_date | date:'short'}}</div>
              </div>

              <div class="item">
                <label>Status</label>
                <div class="capitalize">{{merchant.status}}</div>
              </div>
            </div>

            <div class="column">
              <div class="item">
                <label>Authoritative URLS</label>
                <editable-list [list]="authoritativeDomainList" [editable]="false"></editable-list>
              </div>

              <div class="item">
                <label>LS actions</label>
                <div>
                  <mm-ls-risk-action-labels [lsRiskActions]="merchant.ls_actions"></mm-ls-risk-action-labels>
                </div>
              </div>

              <div class="item">
                <label>LS Tags</label>
                <tag-selector [inputTags]="merchant.ls_tags" [editAble]="false">
                </tag-selector>

              </div>

              <div class="item">
                <label>Content Labels</label>
                <div>{{merchant.content_labels}}</div>
              </div>

              <div class="item">
                <label>Last Updated By LegitScript On</label>
                <div>{{(merchant.last_updated_by_legitscript_on | date:'short')}}</div>
              </div>
            </div>

            <div class="column">
              <div class="item">
                <label>Analysis Note</label>
                <div class="ellipsis-clamp-12-lines" [title]="merchant.analyst_note">{{merchant.analyst_note}}</div>
              </div>
            </div>

            <form class="column relative" (ngSubmit)="submitAction()" id="customer-action-form"
              [formGroup]="actionFormGroup">
              <div *ngIf="updatingAction" class="updating-in-progress">
                <p-progressSpinner [style]="{ width: '2em', height: '2em' }"></p-progressSpinner>
                <br />
                Updating...
              </div>
              <div class="item">
                <label>Your Action: {{actionForDisplay}}</label>
                <span class="italic" *ngIf="merchant.your_action_date">
                  (Last Submitted {{(merchant.your_action_date | date: 'short')}})
                </span>
              </div>
              <div class="item">
                <label for="customerAction">
                  How Did You Address This Merchant?
                </label>
                <select id="customerAction" formControlName="action">
                  <option [ngValue]="null">-- Please select an option --</option>
                  <option *ngFor="let action of customerActions" [value]="action.value" [text]="action.text">
                  </option>
                </select>
              </div>
              <div class="item small-margin-bottom">
                <div class="label-flex">
                  <label>Comment</label>
                  <span *ngIf="actionFormGroup.controls['comment'].invalid && !firstTry" class="error-msg">*
                    Required</span>
                </div>
                <div>
                  <textarea formControlName="comment"
                    [class.error-border]="actionFormGroup.controls['comment'].invalid && (actionFormGroup.controls['comment'].dirty || !firstTry)"></textarea>
                </div>
              </div>
              <div class="item">
                <button type="submit" [disabled]="updatingAction || actionFormGroup.invalid">Submit</button>
                <button type="button" class="btn-no-bg" (click)="resetForm()">Reset</button>
              </div>
            </form>
          </div>
        </section>
      </div>

      <section class="additional-info">
        <div *ngIf="infoFormErrorMessages.length" class="error-msg-wrapper">
          <p-messages [(value)]="infoFormErrorMessages"></p-messages>
        </div>
        <form (ngSubmit)="editFields()" id="update-additional-info-form" [formGroup]="infoFormGroup">
          <h3>
            Additional Information
            <span class="edit-buttons" *ngIf="editingAdditionalInfo; else showEditIcon">
              <button type="submit">Submit</button>
              <button class="btn-no-bg" type="button" (click)="initInfoForm()">Reset</button>
              <button class="btn-no-bg" type="button" (click)="cancelInfoForm()">Cancel</button>
            </span>
            <ng-template #showEditIcon>
              <i pTooltip="Edit the merchant details" *ngIf="canEdit" class="fa fa-pencil"
                (click)="editAdditionalDetails()"></i>
            </ng-template>
          </h3>
          <div class="grid">
            <div class="column">
              <div class="item" *ngFor="let column of additionalInfoColumnsLeft">
                <label>{{column.name}}</label>
                <span *ngIf="editingAdditionalInfo; else readOnly" [ngSwitch]="column.type">
                  <p-autoComplete *ngSwitchCase="'country'" [formControlName]="column.properties"
                    [suggestions]="filteredCountries"
                    [class.error-border]="formControlFor(column).invalid && (formControlFor(column).dirty || !firstTryInfo)"
                    [dropdown]="true" [delay]="0" (completeMethod)="search($event)"
                    (onSelect)="selectCountry($event, column.properties)">
                  </p-autoComplete>
                  <input *ngSwitchDefault type="text" [formControl]="formControlFor(column)"
                    [class.error-border]="formControlFor(column).invalid && (formControlFor(column).dirty || !firstTryInfo)" />
                </span>
                <ng-template #readOnly>
                  <span>{{merchant[column.properties]}}</span>
                  <span style="padding: 0;"
                    *ngIf="column.properties === 'category_code' && merchant.category_description">-
                    {{merchant.category_description}}</span>
                </ng-template>
              </div>
            </div>
            <div class="column">
              <div class="item" *ngFor="let column of additionalInfoColumnsRight">
                <label>{{column.name}}</label>
                <span *ngIf="editingAdditionalInfo; else readOnly" [ngSwitch]="column.type">
                  <p-autoComplete *ngSwitchCase="'country'" [formControl]="formControlFor(column)"
                    [suggestions]="filteredCountries"
                    [class.error-border]="formControlFor(column).invalid && (formControlFor(column).dirty || !firstTryInfo)"
                    [dropdown]="true" [delay]="0" (completeMethod)="search($event)"
                    (onSelect)="selectCountry($event, column.properties)">
                  </p-autoComplete>
                  <tag-selector *ngSwitchCase="'tags'" [formControl]="formControlFor(column)"
                    formControlName="customer_tags" [inputTags]="formControlFor(column).value"
                    [editAble]="true" [class.error-border]="formControlFor(column).invalid"
                    #tagEditor>
                  </tag-selector>
                  <input *ngSwitchDefault type="text" [formControl]="formControlFor(column)"
                    [class.error-border]="formControlFor(column).invalid && (formControlFor(column).dirty || !firstTryInfo)" />
                </span>
                <ng-template #readOnly>
                  <tag-selector *ngIf="column.type === 'tags'; else notTags" [inputTags]="merchant.customer_tags"
                    [editAble]="false">
                  </tag-selector>
                  <ng-template #notTags>
                    <span>{{merchant[column.properties]}}</span>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</div>