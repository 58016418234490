import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  EnrollService,
  HealthcarePricing,
  QuestionnaireInfo,
  PriceBookGuid,
  CbdWebsitePricing,
  CbdProductPricing,
  DAATCPricing,
} from '../../services';
import { HealthcareCertProductGuid, ProductFamily, CertificationType } from '@ls/common-ts-models';
import { LegitScriptModalComponent } from '@ls/common-ng-components';
import { Observable, Subscription, ReplaySubject, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';

@Component({
  selector: 'cert-enroll',
  styleUrls: ['./healthcare-enroll.component.scss'],
  templateUrl: './healthcare-enroll.component.html',
})
export class HealthcareEnrollComponent implements OnInit, OnDestroy {
  @ViewChild('pricingModal') public pricingModal: LegitScriptModalComponent;

  public healthcareFormGroup: FormGroup;
  public questions: Observable<any>;
  public pricingSubject: ReplaySubject<HealthcarePricing>;
  public apiError = false;

  public categoryA: FormControl = new FormControl('', Validators.required);
  public categoryC: FormControl = new FormControl('', Validators.required);
  public pharmacyBroker: FormControl = new FormControl('', Validators.required);
  public websiteSubmissionNumber: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[0-9]*'),
    Validators.max(1000),
    Validators.min(1),
  ]);
  public pricingInfo = {};
  public pricing;
  public firstTry = true;

  private tier: string;
  private questionnaireTypeName = 'healthcare_tier_selection';

  // Getting pricing info can take a few seconds.  If the user navigates away before success, a non-fatal error (ObjectUnsubscribedError) is sometimes thrown.  This subscription allows us to unsubscribe in onDestroy to avoid the error.
  private pricingSubscription: Subscription;

  // tslint:disable-next-line: member-ordering
  public questionnaireInfo: QuestionnaireInfo = {
    pricingTierSku: '',
    productId: '',
    questions: [],
    questionnaireTypeName: this.questionnaireTypeName,
    certificationType: CertificationType.healthcare,
  } as QuestionnaireInfo;

  constructor(
    private router: Router,
    private enrollSvc: EnrollService,
  ) {}

  public async ngOnInit() {
    this.questions = this.enrollSvc.getEnrollmentQuestions(this.questionnaireTypeName).pipe(
      catchError(() => {
        this.apiError = true;
        return of(null);
      }),
      tap((q) => {
        this.questionnaireInfo.questions = q.questions;
      }),
      map((q) => this.questionnaireToQuestionTextMap(q)),
    );

    this.pricingSubject = new ReplaySubject<HealthcarePricing>();
    this.pricingSubscription = this.enrollSvc.getPricing(ProductFamily.RX_CERT).subscribe(
      (pricing: HealthcarePricing | DAATCPricing | CbdProductPricing | CbdWebsitePricing) => {
        const r = pricing as HealthcarePricing;
        this.pricingSubject.next(r);
        this.pricing = r;
      },
      () => {
        this.apiError = true;
      },
    );

    this.healthcareFormGroup = new FormGroup({
      categoryA: this.categoryA,
      categoryC: this.categoryC,
      pharmacyBroker: this.pharmacyBroker,
      websiteSubmissionNumber: this.websiteSubmissionNumber,
    });
  }

  public ngOnDestroy() {
    this.pricingSubject.unsubscribe();
    this.pricingSubscription.unsubscribe();
  }

  public calculatePricing(): void {
    const quantity = parseInt(this.websiteSubmissionNumber.value, 10);
    this.pricingSubject.subscribe((pricing: HealthcarePricing) => {
      if (this.categoryA.invalid || this.categoryC.invalid || this.pharmacyBroker.invalid) {
        return;
      }
      this.tier = 'HEALTHCARE_B'; // starting tier
      if (this.categoryA.value === 'yes') {
        this.tier = 'HEALTHCARE_A';
      }
      if (this.categoryC.value === 'yes' || this.pharmacyBroker.value === 'yes') {
        this.tier = 'HEALTHCARE_C';
      }
      this.questionnaireInfo.pricingTierSku = PriceBookGuid[this.tier];
      this.questionnaireInfo.enrolledItemCount = quantity;
      this.questionnaireInfo.answeredQuestions = Object.keys(this.healthcareFormGroup.controls).map((key) => {
        const question = this.questionnaireInfo.questions.find((q) => q.name === key);
        return this.enrollSvc.buildAnsweredQuestion(
          question.question_type,
          question.id,
          this.healthcareFormGroup.controls[key].value,
        );
      });
      const applicationFee = pricing[this.tier].APPLICATION;
      const subscriptionFee = pricing[this.tier].SUBSCRIPTION;

      this.pricingInfo = {
        subscriptionFee,
        applicationFee,
        productId: HealthcareCertProductGuid.APPLICATION,
        subscriptionProductId: HealthcareCertProductGuid.SUBSCRIPTION,
        recurringAmount: subscriptionFee * quantity,
        billingAmount: applicationFee * quantity,
        perItem: 'website',
        quantity,
      };
    });
  }

  public validate(firstTry) {
    this.firstTry = firstTry;
  }

  private questionnaireToQuestionTextMap(questionnaire) {
    return questionnaire.questions.reduce((acc, cur) => {
      if (cur.name) {
        acc[cur.name] = cur.question_text;
      }
      return acc;
    }, {});
  }
}
