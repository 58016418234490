import { Component, Input } from '@angular/core';

@Component({
  selector: 'flag',
  styleUrls: ['./flag.component.scss'],
  templateUrl: './flag.component.html'
})
export class FlagComponent {
  @Input() public country: string;
}
