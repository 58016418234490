import { Injectable } from '@angular/core';
import { User, Account } from '@ls/common-ts-models';

@Injectable()
export class LocalStorageService {
  private localStorageSupported: boolean;
  private USER_KEY = 'currentUser';
  private ACCOUNT_KEY = 'currentAccount';
  private TOKEN_KEY = 'access_token';

  constructor() {
    this.localStorageSupported = typeof window['localStorage'] !== 'undefined' && window['localStorage'] !== null;

    if (!this.localStorageSupported) {
      console.error('LocalStorage is not supported on this browser');
    }
  }

  //
  // USER methods
  //
  public getUser(): User {
    if (this.localStorageSupported) {
      const userJson = localStorage.getItem(this.USER_KEY);

      if (userJson) {
        return JSON.parse(userJson);
      }
    }
  }

  public setUser(user: User) {
    if (this.localStorageSupported) {
      localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    }
  }

  public removeUser(): void {
    if (this.localStorageSupported) {
      localStorage.removeItem(this.USER_KEY);
    }
  }

  //
  // TOKEN methods
  //
  public getToken(): string {
    if (this.localStorageSupported) {
      const token = localStorage.getItem(this.TOKEN_KEY).replace(/^"(.*)"$/, '$1');

      if (token) {
        return token;
      }
    }
  }

  public setToken(token: string) {
    if (this.localStorageSupported) {
      localStorage.setItem(this.TOKEN_KEY, token);
    }
  }

  public removeToken(): void {
    if (this.localStorageSupported) {
      localStorage.removeItem(this.TOKEN_KEY);
    }
  }

  //
  // ACCOUNT methods
  //
  public getAccount(): Account {
    if (this.localStorageSupported) {
      const accountJson = localStorage.getItem(this.ACCOUNT_KEY);

      if (accountJson) {
        return JSON.parse(accountJson);
      }
    }
  }

  public setAccount(account: Account) {
    if (this.localStorageSupported) {
      localStorage.setItem(this.ACCOUNT_KEY, JSON.stringify(account));
    }
  }

  public removeAccount(): void {
    if (this.localStorageSupported) {
      localStorage.removeItem(this.ACCOUNT_KEY);
    }
  }

  public clearLocalStorage() {
    this.removeAccount();
    this.removeToken();
    this.removeUser();
  }
}
