import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProductFamily } from '@ls/common-ts-models';
import { AppState, fnEntitlementsState } from 'src/app/reducers';

import { DataLayerService } from 'src/app/services/data-layer.service';

@Component({
  selector: 'product-lookups-search',
  styleUrls: ['../../styles/search.scss'],
  templateUrl: './product-lookup-search.html',
})
export class ProductLookupsSearchComponent implements OnInit {
  public product = '';
  public entitlements$: Observable<any>;
  public productFamily = ProductFamily.PRODUCT_LOOKUP;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public store: Store<AppState>,
    private _dataLayerService: DataLayerService,
  ) {}

  public ngOnInit() {
    this.entitlements$ = this.store.select(fnEntitlementsState);

    // if hard refresh but there was a previous search, load the term into the form
    const searchTerm = this.route.snapshot.queryParams['product'];
    if (searchTerm) {
      this.product = searchTerm; // angular decodes URI component when parsing queryParams
    }
  }

  public submit(formRef: NgForm) {
    if (formRef.form.valid) {
      this._dataLayerService.logProductsLookUpEvent();
      this.router.navigate(['list'], {
        relativeTo: this.route,
        queryParams: { product: formRef.form.value.product },
      });
    }
  }

  public clearSearch(formRef: NgForm) {
    if (formRef) {
      formRef.reset();
    }
    this.router.navigate(['./'], { relativeTo: this.route });
  }
}
