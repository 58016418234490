import { createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import {
  WBFilterSortDirection,
  MyLSListMerchant,
  PageUpdateOptions,
  Pagination,
  MyLsFilterGroup,
  MyLSListMerchantCheckbox,
  MyLSMerchantSortFieldKeys,
} from '@ls/common-ts-models';
import { MMAppState } from './app.state';

export const MERCHANT_LIST = '[MerchantList] List';
export const MERCHANT_LIST_SUCCESS = '[MerchantList] List Success';
export const MERCHANT_LIST_ERROR = '[MerchantList] List Error';
export const MERCHANT_LIST_SET_PENDING = '[MerchantList] Set Pending';
export const MERCHANT_LIST_REQUEST_DOWNLOAD = '[MerchantList] Request Download';
export const MERCHANT_LIST_REQUEST_DOWNLOAD_WITH_NOTIFICATION = '[MerchantList] Request Download With Notification';
export const MERCHANT_LIST_REQUEST_DOWNLOAD_COMPLETE = '[MerchantList] Request Download Complete';
export const MERCHANT_LIST_REQUEST_DOWNLOAD_ERROR = '[MerchantList] Request Download Error';

export const MerchantListAction = createAction(
  MERCHANT_LIST,
  props<{
    sortField: MyLSMerchantSortFieldKeys;
    sortDirection: WBFilterSortDirection;
    pageUpdateOptions: PageUpdateOptions;
    filters: MyLsFilterGroup;
    actionPositions: MyLSListMerchantCheckbox[];
    globalCheckbox: boolean;
  }>(),
);

export const MerchantListSuccessAction = createAction(
  MERCHANT_LIST_SUCCESS,
  props<{ merchants: MyLSListMerchant[]; pagination: Pagination }>(),
);

export const MerchantListErrorAction = createAction(MERCHANT_LIST_ERROR, props<{ err?: Error }>());

export const MerchantListRequestDownloadAction = createAction(MERCHANT_LIST_REQUEST_DOWNLOAD);

export const MerchantListRequestDownloadCompleteAction = createAction(
  MERCHANT_LIST_REQUEST_DOWNLOAD_COMPLETE,
  props<{ response: any }>(),
);

export const MerchantListRequestDownloadErrorAction = createAction(
  MERCHANT_LIST_REQUEST_DOWNLOAD_ERROR,
  props<{ err?: Error }>(),
);

export const MerchantListSetPendingAction = createAction(MERCHANT_LIST_SET_PENDING, props<{ value: boolean }>());

export interface MerchantListState {
  err?: Error;
  merchants: MyLSListMerchant[];
  sortField: MyLSMerchantSortFieldKeys;
  sortDirection: WBFilterSortDirection;
  filters: MyLsFilterGroup;
  pagination: Pagination;
  pending: boolean;
  downloadRequestPending: boolean;
}

export const initialMerchantListState: MerchantListState = {
  err: null,
  merchants: [],
  sortField: 'submitted_date',
  sortDirection: WBFilterSortDirection.Descending,
  filters: { filters: [] },
  pagination: {
    totalRecords: 0,
    totalPages: 0,
    pageNumber: 1,
    startPosition: 0,
    endPosition: 0,
  },
  pending: true,
  downloadRequestPending: false,
};

export const MerchantListReducer = createReducer(
  initialMerchantListState,

  on(MerchantListAction, (state, { sortField, sortDirection, filters }) => ({
    ...state,
    merchants: [],
    err: null,
    sortField,
    sortDirection,
    filters,
    pending: true,
    downloadRequestPending: false,
  })),

  on(MerchantListSuccessAction, (state, { merchants, pagination }) => ({
    ...state,
    err: null,
    merchants,
    pagination,
    pending: false,
  })),

  on(MerchantListErrorAction, (state, { err }) => ({
    ...state,
    err,
    pending: false,
  })),

  on(MerchantListRequestDownloadAction, (state) => ({
    ...state,
    err: null,
    downloadRequestPending: true,
  })),

  on(MerchantListRequestDownloadCompleteAction, (state, { response }) => ({
    ...state,
    err: null,
    downloadRequestPending: false,
  })),

  on(MerchantListRequestDownloadErrorAction, (state, { err }) => ({
    ...state,
    err,
    downloadRequestPending: false,
  })),

  on(MerchantListSetPendingAction, (state, { value }) => ({
    ...state,
    pending: value,
    merchants: value ? [] : [...state.merchants],
  })),
);

//
// STATE SELECTORS
//
export const fnMerchantListState = createFeatureSelector<MMAppState>('merchantMonitoring');

export const fnMerchantListMerchantSummaryState = createSelector(fnMerchantListState, (state) => state.merchantSummary);
export const fnMerchantListLsTagsState = createSelector(fnMerchantListState, (state) => state.lsTags);
export const fnMerchantListTagsState = createSelector(fnMerchantListState, (state) => state.tags);
export const fnMerchantListIcasState = createSelector(fnMerchantListState, (state) => state.icas);
export const fnMerchantListContentLabelState = createSelector(fnMerchantListState, (state) => state.contentLabels);
export const fnMerchantListTableState = createSelector(fnMerchantListState, (state) => state.merchantList);
export const fnMerchantListPending = createSelector(fnMerchantListState, (state) => state.merchantList.pending);
export const fnMerchantListFilters = createSelector(fnMerchantListState, (state) => state.merchantList.filters);
export const fnMerchantListMerchants = createSelector(fnMerchantListState, (state) => state.merchantList.merchants);
export const fnMerchantListPagination = createSelector(fnMerchantListState, (state) => state.merchantList.pagination);
export const fnMerchantListTableFiltersState = createSelector(fnMerchantListState, (state) => state.tableFilters);
export const fnMerchantListTableFiltersAppliedFiltersState = createSelector(
  fnMerchantListTableFiltersState,
  (state) => state.appliedFilters,
);
export const fnMerchantListTableFiltersVisibleFiltersState = createSelector(
  fnMerchantListTableFiltersState,
  (state) => state.visibleFilters,
);
export const fnMerchantListSavedFiltersState = createSelector(fnMerchantListState, (state) => state.savedFilters);
export const fnMerchantListTableFiltersSavedFilterSetsState = createSelector(
  fnMerchantListSavedFiltersState,
  (state) => state.savedFilterSets,
);
export const fnMerchantListTableFiltersDefaultFilterSetState = createSelector(
  fnMerchantListSavedFiltersState,
  (state) => state.defaultFilterSet,
);
export const fnMerchantListTableFiltersAppliedFilterSetState = createSelector(
  fnMerchantListSavedFiltersState,
  (state) => state.appliedFilterSet,
);
export const fnMerchantListTableFiltersMenu = createSelector(
  fnMerchantListTableFiltersState,
  (state) => state.filterMenu,
);
export const fnMerchantListTableFiltersByName = createSelector(
  fnMerchantListTableFiltersState,
  (state) => state.filtersByName,
);
