/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TransactionCurrencyCodes = 'AFN' | 'ALL' | 'DZD' | 'USD' | 'EUR' | 'AOA' | 'XCD' | 'ARS' | 'AMD' | 'AWG' | 'AUD' | 'AZN' | 'BSD' | 'BHD' | 'BDT' | 'BBD' | 'BYN' | 'BZD' | 'XOF' | 'BMD' | 'BTN' | 'INR' | 'BOB' | 'BOV' | 'BAM' | 'BWP' | 'NOK' | 'BRL' | 'BND' | 'BGN' | 'BIF' | 'CVE' | 'KHR' | 'XAF' | 'CAD' | 'KYD' | 'CLF' | 'CLP' | 'CNY' | 'COP' | 'COU' | 'KMF' | 'CDF' | 'NZD' | 'CRC' | 'CUC' | 'CUP' | 'ANG' | 'CZK' | 'DKK' | 'DJF' | 'DOP' | 'EGP' | 'SVC' | 'ERN' | 'ETB' | 'FKP' | 'FJD' | 'XPF' | 'GMD' | 'GEL' | 'GHS' | 'GIP' | 'GTQ' | 'GBP' | 'GNF' | 'GYD' | 'HTG' | 'HNL' | 'HKD' | 'HUF' | 'ISK' | 'IDR' | 'XDR' | 'IRR' | 'IQD' | 'ILS' | 'JMD' | 'JPY' | 'JOD' | 'KZT' | 'KES' | 'KPW' | 'KRW' | 'KWD' | 'KGS' | 'LAK' | 'LBP' | 'LSL' | 'ZAR' | 'LRD' | 'LYD' | 'CHF' | 'MOP' | 'MGA' | 'MWK' | 'MYR' | 'MVR' | 'MRU' | 'MUR' | 'XUA' | 'MXN' | 'MXV' | 'MDL' | 'MNT' | 'MAD' | 'MZN' | 'MMK' | 'NAD' | 'NPR' | 'NIO' | 'NGN' | 'OMR' | 'PKR' | 'PAB' | 'PGK' | 'PYG' | 'PEN' | 'PHP' | 'PLN' | 'QAR' | 'MKD' | 'RON' | 'RUB' | 'RWF' | 'SHP' | 'WST' | 'STN' | 'SAR' | 'RSD' | 'SCR' | 'SLE' | 'SGD' | 'XSU' | 'SBD' | 'SOS' | 'SSP' | 'LKR' | 'SDG' | 'SRD' | 'SZL' | 'SEK' | 'CHE' | 'CHW' | 'SYP' | 'TWD' | 'TJS' | 'TZS' | 'THB' | 'TOP' | 'TTD' | 'TND' | 'TRY' | 'TMT' | 'UGX' | 'UAH' | 'AED' | 'USN' | 'UYI' | 'UYU' | 'UZS' | 'VUV' | 'VEF' | 'VED' | 'VND' | 'YER' | 'ZMW' | 'ZWL' | 'ERU';

export const TransactionCurrencyCodes = {
    AFN: 'AFN' as TransactionCurrencyCodes,
    ALL: 'ALL' as TransactionCurrencyCodes,
    DZD: 'DZD' as TransactionCurrencyCodes,
    USD: 'USD' as TransactionCurrencyCodes,
    EUR: 'EUR' as TransactionCurrencyCodes,
    AOA: 'AOA' as TransactionCurrencyCodes,
    XCD: 'XCD' as TransactionCurrencyCodes,
    ARS: 'ARS' as TransactionCurrencyCodes,
    AMD: 'AMD' as TransactionCurrencyCodes,
    AWG: 'AWG' as TransactionCurrencyCodes,
    AUD: 'AUD' as TransactionCurrencyCodes,
    AZN: 'AZN' as TransactionCurrencyCodes,
    BSD: 'BSD' as TransactionCurrencyCodes,
    BHD: 'BHD' as TransactionCurrencyCodes,
    BDT: 'BDT' as TransactionCurrencyCodes,
    BBD: 'BBD' as TransactionCurrencyCodes,
    BYN: 'BYN' as TransactionCurrencyCodes,
    BZD: 'BZD' as TransactionCurrencyCodes,
    XOF: 'XOF' as TransactionCurrencyCodes,
    BMD: 'BMD' as TransactionCurrencyCodes,
    BTN: 'BTN' as TransactionCurrencyCodes,
    INR: 'INR' as TransactionCurrencyCodes,
    BOB: 'BOB' as TransactionCurrencyCodes,
    BOV: 'BOV' as TransactionCurrencyCodes,
    BAM: 'BAM' as TransactionCurrencyCodes,
    BWP: 'BWP' as TransactionCurrencyCodes,
    NOK: 'NOK' as TransactionCurrencyCodes,
    BRL: 'BRL' as TransactionCurrencyCodes,
    BND: 'BND' as TransactionCurrencyCodes,
    BGN: 'BGN' as TransactionCurrencyCodes,
    BIF: 'BIF' as TransactionCurrencyCodes,
    CVE: 'CVE' as TransactionCurrencyCodes,
    KHR: 'KHR' as TransactionCurrencyCodes,
    XAF: 'XAF' as TransactionCurrencyCodes,
    CAD: 'CAD' as TransactionCurrencyCodes,
    KYD: 'KYD' as TransactionCurrencyCodes,
    CLF: 'CLF' as TransactionCurrencyCodes,
    CLP: 'CLP' as TransactionCurrencyCodes,
    CNY: 'CNY' as TransactionCurrencyCodes,
    COP: 'COP' as TransactionCurrencyCodes,
    COU: 'COU' as TransactionCurrencyCodes,
    KMF: 'KMF' as TransactionCurrencyCodes,
    CDF: 'CDF' as TransactionCurrencyCodes,
    NZD: 'NZD' as TransactionCurrencyCodes,
    CRC: 'CRC' as TransactionCurrencyCodes,
    CUC: 'CUC' as TransactionCurrencyCodes,
    CUP: 'CUP' as TransactionCurrencyCodes,
    ANG: 'ANG' as TransactionCurrencyCodes,
    CZK: 'CZK' as TransactionCurrencyCodes,
    DKK: 'DKK' as TransactionCurrencyCodes,
    DJF: 'DJF' as TransactionCurrencyCodes,
    DOP: 'DOP' as TransactionCurrencyCodes,
    EGP: 'EGP' as TransactionCurrencyCodes,
    SVC: 'SVC' as TransactionCurrencyCodes,
    ERN: 'ERN' as TransactionCurrencyCodes,
    ETB: 'ETB' as TransactionCurrencyCodes,
    FKP: 'FKP' as TransactionCurrencyCodes,
    FJD: 'FJD' as TransactionCurrencyCodes,
    XPF: 'XPF' as TransactionCurrencyCodes,
    GMD: 'GMD' as TransactionCurrencyCodes,
    GEL: 'GEL' as TransactionCurrencyCodes,
    GHS: 'GHS' as TransactionCurrencyCodes,
    GIP: 'GIP' as TransactionCurrencyCodes,
    GTQ: 'GTQ' as TransactionCurrencyCodes,
    GBP: 'GBP' as TransactionCurrencyCodes,
    GNF: 'GNF' as TransactionCurrencyCodes,
    GYD: 'GYD' as TransactionCurrencyCodes,
    HTG: 'HTG' as TransactionCurrencyCodes,
    HNL: 'HNL' as TransactionCurrencyCodes,
    HKD: 'HKD' as TransactionCurrencyCodes,
    HUF: 'HUF' as TransactionCurrencyCodes,
    ISK: 'ISK' as TransactionCurrencyCodes,
    IDR: 'IDR' as TransactionCurrencyCodes,
    XDR: 'XDR' as TransactionCurrencyCodes,
    IRR: 'IRR' as TransactionCurrencyCodes,
    IQD: 'IQD' as TransactionCurrencyCodes,
    ILS: 'ILS' as TransactionCurrencyCodes,
    JMD: 'JMD' as TransactionCurrencyCodes,
    JPY: 'JPY' as TransactionCurrencyCodes,
    JOD: 'JOD' as TransactionCurrencyCodes,
    KZT: 'KZT' as TransactionCurrencyCodes,
    KES: 'KES' as TransactionCurrencyCodes,
    KPW: 'KPW' as TransactionCurrencyCodes,
    KRW: 'KRW' as TransactionCurrencyCodes,
    KWD: 'KWD' as TransactionCurrencyCodes,
    KGS: 'KGS' as TransactionCurrencyCodes,
    LAK: 'LAK' as TransactionCurrencyCodes,
    LBP: 'LBP' as TransactionCurrencyCodes,
    LSL: 'LSL' as TransactionCurrencyCodes,
    ZAR: 'ZAR' as TransactionCurrencyCodes,
    LRD: 'LRD' as TransactionCurrencyCodes,
    LYD: 'LYD' as TransactionCurrencyCodes,
    CHF: 'CHF' as TransactionCurrencyCodes,
    MOP: 'MOP' as TransactionCurrencyCodes,
    MGA: 'MGA' as TransactionCurrencyCodes,
    MWK: 'MWK' as TransactionCurrencyCodes,
    MYR: 'MYR' as TransactionCurrencyCodes,
    MVR: 'MVR' as TransactionCurrencyCodes,
    MRU: 'MRU' as TransactionCurrencyCodes,
    MUR: 'MUR' as TransactionCurrencyCodes,
    XUA: 'XUA' as TransactionCurrencyCodes,
    MXN: 'MXN' as TransactionCurrencyCodes,
    MXV: 'MXV' as TransactionCurrencyCodes,
    MDL: 'MDL' as TransactionCurrencyCodes,
    MNT: 'MNT' as TransactionCurrencyCodes,
    MAD: 'MAD' as TransactionCurrencyCodes,
    MZN: 'MZN' as TransactionCurrencyCodes,
    MMK: 'MMK' as TransactionCurrencyCodes,
    NAD: 'NAD' as TransactionCurrencyCodes,
    NPR: 'NPR' as TransactionCurrencyCodes,
    NIO: 'NIO' as TransactionCurrencyCodes,
    NGN: 'NGN' as TransactionCurrencyCodes,
    OMR: 'OMR' as TransactionCurrencyCodes,
    PKR: 'PKR' as TransactionCurrencyCodes,
    PAB: 'PAB' as TransactionCurrencyCodes,
    PGK: 'PGK' as TransactionCurrencyCodes,
    PYG: 'PYG' as TransactionCurrencyCodes,
    PEN: 'PEN' as TransactionCurrencyCodes,
    PHP: 'PHP' as TransactionCurrencyCodes,
    PLN: 'PLN' as TransactionCurrencyCodes,
    QAR: 'QAR' as TransactionCurrencyCodes,
    MKD: 'MKD' as TransactionCurrencyCodes,
    RON: 'RON' as TransactionCurrencyCodes,
    RUB: 'RUB' as TransactionCurrencyCodes,
    RWF: 'RWF' as TransactionCurrencyCodes,
    SHP: 'SHP' as TransactionCurrencyCodes,
    WST: 'WST' as TransactionCurrencyCodes,
    STN: 'STN' as TransactionCurrencyCodes,
    SAR: 'SAR' as TransactionCurrencyCodes,
    RSD: 'RSD' as TransactionCurrencyCodes,
    SCR: 'SCR' as TransactionCurrencyCodes,
    SLE: 'SLE' as TransactionCurrencyCodes,
    SGD: 'SGD' as TransactionCurrencyCodes,
    XSU: 'XSU' as TransactionCurrencyCodes,
    SBD: 'SBD' as TransactionCurrencyCodes,
    SOS: 'SOS' as TransactionCurrencyCodes,
    SSP: 'SSP' as TransactionCurrencyCodes,
    LKR: 'LKR' as TransactionCurrencyCodes,
    SDG: 'SDG' as TransactionCurrencyCodes,
    SRD: 'SRD' as TransactionCurrencyCodes,
    SZL: 'SZL' as TransactionCurrencyCodes,
    SEK: 'SEK' as TransactionCurrencyCodes,
    CHE: 'CHE' as TransactionCurrencyCodes,
    CHW: 'CHW' as TransactionCurrencyCodes,
    SYP: 'SYP' as TransactionCurrencyCodes,
    TWD: 'TWD' as TransactionCurrencyCodes,
    TJS: 'TJS' as TransactionCurrencyCodes,
    TZS: 'TZS' as TransactionCurrencyCodes,
    THB: 'THB' as TransactionCurrencyCodes,
    TOP: 'TOP' as TransactionCurrencyCodes,
    TTD: 'TTD' as TransactionCurrencyCodes,
    TND: 'TND' as TransactionCurrencyCodes,
    TRY: 'TRY' as TransactionCurrencyCodes,
    TMT: 'TMT' as TransactionCurrencyCodes,
    UGX: 'UGX' as TransactionCurrencyCodes,
    UAH: 'UAH' as TransactionCurrencyCodes,
    AED: 'AED' as TransactionCurrencyCodes,
    USN: 'USN' as TransactionCurrencyCodes,
    UYI: 'UYI' as TransactionCurrencyCodes,
    UYU: 'UYU' as TransactionCurrencyCodes,
    UZS: 'UZS' as TransactionCurrencyCodes,
    VUV: 'VUV' as TransactionCurrencyCodes,
    VEF: 'VEF' as TransactionCurrencyCodes,
    VED: 'VED' as TransactionCurrencyCodes,
    VND: 'VND' as TransactionCurrencyCodes,
    YER: 'YER' as TransactionCurrencyCodes,
    ZMW: 'ZMW' as TransactionCurrencyCodes,
    ZWL: 'ZWL' as TransactionCurrencyCodes,
    ERU: 'ERU' as TransactionCurrencyCodes
};

