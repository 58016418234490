import { Label, MerchantSummary } from '@ls/common-ts-models';
import { createAction, createReducer, on, props } from '@ngrx/store';

export const GET_MERCHANT_SUMMARY = '[Merchant Summary] Get';
export const GET_MERCHANT_SUMMARY_SUCCESS = '[Merchant Summary] Get Success';
export const MERCHANT_SUMMARY_ERROR = '[Merchant Summary] Merchant Summary Error';

export const GetMerchantSummaryAction = createAction(GET_MERCHANT_SUMMARY);

export const GetMerchantSummarySuccessAction = createAction(
  GET_MERCHANT_SUMMARY_SUCCESS,
  props<{ summary: MerchantSummary }>(),
);

export const MerchantSummaryErrorAction = createAction(MERCHANT_SUMMARY_ERROR, props<{ err?: Error }>());

export interface MerchantSummaryState {
  err?: Error;
  summary: MerchantSummary;
  pending: boolean;
}

export const initialSummaryState: MerchantSummaryState = {
  err: null,
  summary: {
    totalMerchants: 0,
    activeMerchants: 0,
    needsAction: 0,
  },
  pending: true,
};

export const MerchantSummaryReducer = createReducer(
  initialSummaryState,

  on(GetMerchantSummarySuccessAction, (state) => ({
    ...state,
    summary: {
      totalMerchants: 0,
      activeMerchants: 0,
      needsAction: 0,
    },
    err: null,
    pending: true,
  })),

  on(GetMerchantSummarySuccessAction, (state, { summary }) => ({
    ...state,
    err: null,
    summary: summary,
    pending: false,
  })),

  on(MerchantSummaryErrorAction, (state, { err }) => ({
    ...state,
    err,
    pending: false,
  })),
);
