/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MetaColumns = 'requestId' | 'client' | 'transactionAmount' | 'ccLast4' | 'acquirerBin' | 'acquirerBid' | 'acquirerName' | 'merchantCategoryCode' | 'cardAcceptorId' | 'rightsHolder' | 'terminalId' | 'transactionId' | 'agent' | 'merchantState' | 'network' | 'tags' | 'websites' | 'transactionUSDAmount' | 'transactionDate' | 'analysisDate';

export const MetaColumns = {
    requestId: 'requestId' as MetaColumns,
    client: 'client' as MetaColumns,
    transactionAmount: 'transactionAmount' as MetaColumns,
    ccLast4: 'ccLast4' as MetaColumns,
    acquirerBin: 'acquirerBin' as MetaColumns,
    acquirerBid: 'acquirerBid' as MetaColumns,
    acquirerName: 'acquirerName' as MetaColumns,
    merchantCategoryCode: 'merchantCategoryCode' as MetaColumns,
    cardAcceptorId: 'cardAcceptorId' as MetaColumns,
    rightsHolder: 'rightsHolder' as MetaColumns,
    terminalId: 'terminalId' as MetaColumns,
    transactionId: 'transactionId' as MetaColumns,
    agent: 'agent' as MetaColumns,
    merchantState: 'merchantState' as MetaColumns,
    network: 'network' as MetaColumns,
    tags: 'tags' as MetaColumns,
    websites: 'websites' as MetaColumns,
    transactionUSDAmount: 'transactionUSDAmount' as MetaColumns,
    transactionDate: 'transactionDate' as MetaColumns,
    analysisDate: 'analysisDate' as MetaColumns
};

