import { CertificationProduct } from '@ls/common-ts-models';
import { ActiveProductTypes, ActiveProductListItem } from './list-item';
import { ActiveProductsComponent } from '../active-products.component';

export class CertificationProductListItem implements ActiveProductListItem {
  public readonly productType: ActiveProductTypes = ActiveProductTypes.CERTIFICATION;

  public readonly name: string;
  public readonly expiration?: Date;

  constructor(private parent: ActiveProductsComponent, private productData: CertificationProduct) {
    this.name = productData.name;
    this.expiration = productData.expiration;
  }
}
