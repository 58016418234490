import { Store } from '@ngrx/store';
import { AccountLoadAction, AppState, UserAuthenticateSuccessAction, fnSelectableAccountState } from '../reducers';
import { AccountSelectorHandler, LocalStorageService } from '@ls/common-ng-components';
import { Injectable } from '@angular/core';
import { SelectableAccountState } from '../reducers/selectable-account.reducer';
import { inMyLS } from '@ls/common-ts-models';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountSelectorHandlerService implements AccountSelectorHandler {
  constructor(
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
  ) {}
  public getCurrentAccountId(): number {
    const user = this.getCurrentUser();
    if (!user) {
      return null;
    }
    return user.lsAccountId;
  }

  public onChange(accountId: number): void {
    const user = this.getCurrentUser();
    if (!user) {
      return null;
    }
    user.lsAccountId = accountId;
    this.localStorageService.setAuthenticatedUser(user);
    this.localStorageService.removePricingInfo();
    this.store.dispatch(UserAuthenticateSuccessAction({ user, roles: user.roles }));
    this.store.dispatch(AccountLoadAction());
    localStorage.setItem(inMyLS, 'false');
    this.reloadPage();
  }

  public getAvailableAccounts() {
    return this.store.select(fnSelectableAccountState).pipe(
      map((accountState: SelectableAccountState) => {
        return accountState.accounts;
      }),
    );
  }

  public reloadPage() {
    location.href = '/';
  }

  public getCurrentUser() {
    return this.localStorageService.getAuthenticatedUser();
  }
}
