<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="widget">
  <div class="alert-msg">
    <p-messages [(value)]="certMsgs" key="'certMsg'" [escape]="false"></p-messages>
  </div>

  <div class="certifications-panel widget full-widget">
    <div class="widget-header">
      <span class="no-icon">
        <h1>Your Certifications</h1>
      </span>
    </div>
    <div class="widget-body centered" id="cert-table-widget">

      <loading-spinner *ngIf="loading || loadingCert" loadingText="{{loadingText}}"></loading-spinner>
      <p-table
      class="prime-table"
      [value]="certs"
      [responsive]="true"
      responsiveLayout="stack"
      selectionMode="single"
      (onRowSelect)="handleRowClick($event)"
      [ngClass]="{'hidden': loading || loadingCert}">
        <ng-template pTemplate="header" >
          <tr>
            <th>Company</th>
            <th>Primary URL</th>
            <th>Certification Type</th>
            <th>Status</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cert let-rowData>
          <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="!allowSelect(cert)" [ngClass]="{'no-click': !allowSelect(cert)}" class="cert-table">
            <td class="cert-table-column">
              <span class="p-column-title">Company</span>
              {{cert.businessName}}
            </td>
            <td class="cert-table-column">
              <span class="p-column-title">URL</span>
              {{cert.primaryUrl}}
            </td>
            <td class="cert-table-column">
              <span class="p-column-title">Type</span>
              {{cert.certificationType}}
            </td>
            <td class="cert-table-column">
              <span class="p-column-title">Status</span>
              <span class="status">
                <i class="fa fa-circle" [ngClass]="getStatusColor(cert)"></i>
                <span>{{getStatus(cert)}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
</div>
