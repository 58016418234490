import { MonitoringProduct } from '@ls/common-ts-models';
import { ActiveProductTypes, ActiveProductListItem } from './list-item';
import { ActiveProductsComponent } from '../active-products.component';
import { MenuItem } from 'primeng/api';

export class MonitoringProductListItem implements ActiveProductListItem {
  public readonly productType: ActiveProductTypes = ActiveProductTypes.MONITORING;

  public readonly name: string;
  public readonly menuItems: MenuItem[];
  public readonly usageString: string;

  constructor(private parent: ActiveProductsComponent, isOwner: boolean, productData: MonitoringProduct) {
    this.name = productData.name;
    this.usageString = `${productData.itemsMonitored} merchant(s) monitored`;

    // TODO: Check billing status and allow certain users to generate api keys here
    // (See https://legitscript.atlassian.net/browse/LS-13601)
    // if (isOwner && productData.apiKeyRequired) {
    //   this.menuItems = [
    //     { label: 'Refresh API Key', command: () => parent.confirmRefreshAPIKey(productData.externalReferenceId) }
    //   ];
    // }
  }
}
