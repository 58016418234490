import { Component, Input } from '@angular/core';

@Component({
  selector: 'info-bubble',
  styleUrls: ['./info-bubble.component.scss'],
  templateUrl: './info-bubble.component.html'
})
export class InfoBubbleComponent {
  @Input() public value: any;
  @Input() public country?: string;

  public greenValues: any[] = ['rx', 'adrx', 'rxh', 'po', 'otc', 'adotc', 'tent', 'green'];
  public valueDisplayText = {
    yellow: 'Yellow Flag',
    orange: 'Orange Flag',
    red: 'Red Flag',
    rx: 'Prescription',
    adrx: 'Animal Rx',
    rxh: 'Hospital',
    po: 'Pharmacy-Only',
    otc: 'Over the Counter',
    adotc: 'Animal OTC',
    tent: 'Tentative',
    green: 'Green Flag',
    certified: 'Certified'
  };
}
