import { createAction, createReducer, on, props } from '@ngrx/store';
import { TransactionDetailsForMyLs200Response } from '../angular-client';

export const TestTransactionsFetchAction = createAction(
  '[Test Transactions] Fetch Transaction',
  props<{ id: string }>(),
);
export const TestTransactionsFetchSuccessAction = createAction(
  '[Test Transactions] Fetch Transaction Success',
  props<TransactionDetailsForMyLs200Response>(),
);
export const TestTransactionsFetchErrorAction = createAction(
  '[Test Transactions] Fetch Transaction Error',
  props<{ errorText: string; errorType: number }>(),
);

export interface TestTransactionsTransactionState {
  error?: {
    errorText;
    errorType;
  };
  transaction?: TransactionDetailsForMyLs200Response;
  pending: boolean;
}

const initialState: TestTransactionsTransactionState = {
  pending: false,
};

export const TestTransactionsFetchReducer = createReducer(
  initialState,

  on(TestTransactionsFetchAction, (state) => ({
    ...state,
    transaction: {
      id: '',
      websiteUrl: '',
      transactionStatus: null,
      analysisNote: '',
      source: null,
      category: '',
    },
    pending: true,
  })),

  on(TestTransactionsFetchSuccessAction, (state, props) => ({
    ...state,
    transaction: props,
    pending: false,
  })),

  on(TestTransactionsFetchErrorAction, (state, props) => ({
    ...state,
    error: props,
    pending: false,
  })),
);
