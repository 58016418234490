<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<h1>You’re one step closer to plugging in to the world’s largest database of dietary supplements, pharmaceuticals, and other healthcare products</h1>

<section class="overview">
  <picture class="mobile-hide">
    <img src="../assets/img/product-api.png">
  </picture>
  <div>
    <h2>API access is straightforward:</h2>
    <ul>
      <li>Choose your API plan</li>
      <li>Connect using your API key</li>
      <li>The number of API calls you can make is based upon your monthly lookup plan</li>
      <li>Each month your plan resets automatically until cancelled</li>
    </ul>
  </div>
</section>

<section class="options">
  <h2>Select your search category:</h2>
  <p-selectButton class="selectbtn" [options]="[{'label': 'All', 'value': 'all'}, {'label': 'Red Flag', 'value': 'redFlag'}]" [(ngModel)]="searchType" (onChange)="handleTypeChange()"></p-selectButton>
  <ul>
    <li>The "All" search category includes all products published in our database, including those which are yellow flagged.</li>
    <li>The "Red Flag" search category does not include yellow flagged products.</li>
    <li>Searching for products does not affect your usage limit; only viewing the details of an individual product counts as a query.</li>
    <li>Initial payment is prorated for days remaining in the current month.</li>
  </ul>
</section>

<section class="pricing">
  <h2>Select your usage plan:</h2>
  <loading-spinner [loadingText]="'Loading Pricing'" *ngIf="loadingPricing; else pricing"></loading-spinner>
  <ng-template #pricing>
    <input type="range" [(ngModel)]="productChoiceIndex" (input)="updateProductChoice()" [max]="yellowFlagProducts.length -1" step="1">
    <p *ngIf="productChoice">
      {{productChoice.name.replace("Product Database API - ","").replace("Red Flag - ","").replace("Yellow Flag - ","") | number:'1.0'}}
      requests per month for
      <span class="amount">${{productChoice.price | number:'1.0'}}</span>
    </p>
  </ng-template>
</section>

<section class="agree">
  <input #termsCheckbox="ngModel" [ngModelOptions]="{standalone: true}" id="terms-checkbox" name="terms-checkbox" type="checkbox" (change)="onTermsChecked($event)" [(ngModel)]="termsSelected" required/>
  <label for="terms-checkbox">
    I have read and agree to LegitScript's <a (click)="termsModal.showDialog()">Terms of Service</a>
  </label>
  <br>
  <button class="btn btn-primary" (click)="goToPayment()" [disabled]="productChoiceIndex === undefined || !termsSelected">Continue to Payment</button>
</section>

<legitscript-modal #termsModal [draggable]=false [resizable]=false [responsive]=true [closable]=true
  [modal]=true autoAlign=true [width]=800 height="auto">
  <div class="dialog-body" style="overflow:auto; height:75vh"  libScrollEvent (onscroll)="onDialogScroll($event)" [bottomOffset]=10 [topOffset]=50>
    <data-subscription-terms></data-subscription-terms>
  </div>
  <div class="dialog-footer">
    <button type="button" (click)="onAgreeToTerms();" label="Yes" [disabled]="hasScrolledToBottom === false" >I Agree</button>
  </div>
</legitscript-modal>
