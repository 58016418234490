import { HttpEventType } from '@angular/common/http';
import { createAction, createReducer, on, props } from '@ngrx/store';
export const MERCHANT_MONITORING_BULK_UPLOAD = '[MerchantMonitoringBulkUpload] Upload';
export const MERCHANT_MONITORING_BULK_UPLOAD_PROGRESS = '[MerchantMonitoringBulkUpload] Upload Progress';
export const MERCHANT_MONITORING_BULK_UPLOAD_SUCCESS = '[MerchantMonitoringBulkUpload] Upload Success';
export const MERCHANT_MONITORING_BULK_UPLOAD_ERROR = '[MerchantMonitoringBulkUpload] Upload Error';
export const MERCHANT_MONITORING_BULK_UPLOAD_VALIDATION_START =
  '[MerchantMonitoringBulkUpload] Upload File Validation Start';
export const MERCHANT_MONITORING_BULK_UPLOAD_VALIDATION_COMPLETE =
  '[MerchantMonitoringBulkUpload] Upload File Validation Complete';
export const MERCHANT_MONITORING_BULK_UPLOAD_VALIDATION_RESET =
  '[MerchantMonitoringBulkUpload] Upload File Validation Reset';
export const MERCHANT_MONITORING_BULK_UPLOAD_VALIDATION_ERROR =
  '[MerchantMonitoringBulkUpload] Upload File Validation Error';

export const MerchantMonitoringBulkUploadAction = createAction(
  MERCHANT_MONITORING_BULK_UPLOAD,
  props<{ file: File }>(),
);

export const MerchantMonitoringBulkUploadProgressAction = createAction(
  MERCHANT_MONITORING_BULK_UPLOAD_PROGRESS,
  props<{ progress: { percent: number; status: HttpEventType } }>(),
);

export const MerchantMonitoringBulkUploadSuccessAction = createAction(MERCHANT_MONITORING_BULK_UPLOAD_SUCCESS);

export const MerchantMonitoringBulkUploadErrorAction = createAction(
  MERCHANT_MONITORING_BULK_UPLOAD_ERROR,
  props<{ err?: string }>(),
);

export const MerchantMonitoringBulkUploadValidationStartAction = createAction(
  MERCHANT_MONITORING_BULK_UPLOAD_VALIDATION_START,
  props<{ file: File }>(),
);

export const MerchantMonitoringBulkUploadValidationCompleteAction = createAction(
  MERCHANT_MONITORING_BULK_UPLOAD_VALIDATION_COMPLETE,
  props<{ succeeded: boolean; message: string }>(),
);

export const MerchantMonitoringBulkUploadValidationErrorAction = createAction(
  MERCHANT_MONITORING_BULK_UPLOAD_VALIDATION_ERROR,
  props<{ error: Error }>(),
);

export const MerchantMonitoringBulkUploadValidationResetAction = createAction(
  MERCHANT_MONITORING_BULK_UPLOAD_VALIDATION_RESET,
);

export interface MerchantMonitoringBulkUploadState {
  err?: string;
  pending: boolean;
  validationSucceeded: boolean;
  validationMessage: string;
  progress: { percent: number; status: HttpEventType };
}

export const initialMerchantMonitoringBulkUploadState: MerchantMonitoringBulkUploadState = {
  err: null,
  pending: false,
  progress: null,
  validationSucceeded: true,
  validationMessage: '',
};

export const MerchantMonitoringBulkUploadReducer = createReducer(
  initialMerchantMonitoringBulkUploadState,

  on(MerchantMonitoringBulkUploadAction, () => ({
    ...initialMerchantMonitoringBulkUploadState,
    pending: true,
  })),

  on(MerchantMonitoringBulkUploadProgressAction, (state, { progress }) => ({
    ...state,
    progress,
  })),

  on(MerchantMonitoringBulkUploadSuccessAction, (state) => ({
    ...state,
    err: null,
    pending: false,
    progress: null,
  })),

  on(MerchantMonitoringBulkUploadErrorAction, (state, { err }) => ({
    ...state,
    err,
    pending: false,
  })),

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(MerchantMonitoringBulkUploadValidationStartAction, (state, { file }) => ({
    ...state,
    pending: true,
  })),

  on(MerchantMonitoringBulkUploadValidationCompleteAction, (state, { succeeded, message }) => ({
    ...state,
    validationSucceeded: succeeded,
    validationMessage: message,
    pending: false,
  })),

  on(MerchantMonitoringBulkUploadValidationErrorAction, (state, { error }) => ({
    ...state,
    pending: false,
    err: error.message,
  })),

  on(MerchantMonitoringBulkUploadValidationResetAction, (state) => ({
    ...state,
    pending: false,
    validationSucceeded: true,
    validationMessage: '',
  })),
);
