import { FilterSet } from '@ls/common-ng-components';
import { createAction, createReducer, on, props } from '@ngrx/store';

export const SET_DEFAULT_FILTER_SETS = '[Filter Sets] Set Default FilterSets';
export const GET_SAVED_FILTER_SETS = '[Filter Sets] Get Saved FilterSets';
export const GET_SAVED_FILTER_SETS_SUCCESS = '[Filter Sets] Get Saved FilterSets Success';
export const APPLY_FILTER_SET = '[Filter Sets] Apply FilterSet';
export const SAVE_FILTER_SET = '[Filter Sets] Save FilterSet';
export const SAVE_FILTER_SET_SUCCESS = '[Filter Sets] Save FilterSet Success';
export const UPDATE_FILTER_SET = '[Filter Sets] Update FilterSet';
export const UPDATE_FILTER_SET_SUCCESS = '[Filter Sets] Update FilterSet Success';
export const DELETE_FILTER_SET = '[Filter Sets] Delete FilterSet';
export const DELETE_FILTER_SET_SUCCESS = '[Filter Sets] Delete FilterSet Success';
export const SET_FAVORITE_FILTER_SET = '[Filter Sets] Set Favorite';
export const SET_FAVORITE_FILTER_SET_SUCCESS = '[Filter Sets] Set Favorite Success';
export const SET_UNNAMMED_FILTER_SET_ACTION = '[Filter Sets] Set Unnamed Filter Set';

export const SetDefaultFilterSetAction = createAction(SET_DEFAULT_FILTER_SETS, props<{ filterSet: FilterSet }>());
export const GetSavedFilterSetsAction = createAction(GET_SAVED_FILTER_SETS);
export const GetSavedFilterSetsSuccessAction = createAction(
  GET_SAVED_FILTER_SETS_SUCCESS,
  props<{ filterSets: FilterSet[] }>(),
);
export const ApplyFilterSetAction = createAction(APPLY_FILTER_SET, props<{ filterSet: FilterSet }>());
export const SaveFilterSetAction = createAction(SAVE_FILTER_SET, props<{ saveAsName?: string }>());
export const SaveFilterSetSuccessAction = createAction(SAVE_FILTER_SET_SUCCESS, props<{ filterSet: FilterSet }>());
export const UpdateFilterSetAction = createAction(UPDATE_FILTER_SET, props<{ filterSet: FilterSet; name?: string }>());
export const UpdateFilterSetSuccessAction = createAction(UPDATE_FILTER_SET_SUCCESS, props<{ filterSet: FilterSet }>());
export const DeleteFilterSetAction = createAction(DELETE_FILTER_SET, props<{ filterSet: FilterSet }>());
export const DeleteFilterSetSuccessAction = createAction(DELETE_FILTER_SET_SUCCESS, props<{ filterSet: FilterSet }>());
export const SetFavoriteFilterSetAction = createAction(SET_FAVORITE_FILTER_SET, props<{ filterSet: FilterSet }>());
export const SetFavoriteFilterSetSuccessAction = createAction(
  SET_FAVORITE_FILTER_SET_SUCCESS,
  props<{ filterSets: FilterSet[] }>(),
);
export const SetUnnammedFilterSetAction = createAction(SET_UNNAMMED_FILTER_SET_ACTION);

export interface FilterSetsState {
  defaultFilterSet?: FilterSet;
  savedFilterSets?: FilterSet[];
  appliedFilterSet?: FilterSet;
}

const initialState: FilterSetsState = {};

export const FilterSetsReducer = createReducer(
  initialState,

  on(
    GetSavedFilterSetsSuccessAction,
    (state, { filterSets }) =>
      ({
        ...state,
        savedFilterSets: filterSets,
        appliedFilterSet: filterSets.find((fs) => fs.isFavorite) ?? state.defaultFilterSet,
      }) as FilterSetsState,
  ),

  on(
    SetDefaultFilterSetAction,
    (state, { filterSet }) =>
      ({
        ...state,
        defaultFilterSet: {
          ...filterSet,
          isFavorite: state.savedFilterSets?.some((fs) => fs.isFavorite) ? false : true,
        },
        appliedFilterSet: state.savedFilterSets?.find((fs) => fs.isFavorite) ?? filterSet,
      }) as FilterSetsState,
  ),

  on(
    ApplyFilterSetAction,
    (state, { filterSet }) =>
      ({
        ...state,
        appliedFilterSet: filterSet,
      }) as FilterSetsState,
  ),

  on(
    SaveFilterSetSuccessAction,
    (state, { filterSet }) =>
      ({
        ...state,
        savedFilterSets: [...(state.savedFilterSets ?? []), filterSet],
        appliedFilterSet: filterSet,
        defaultFilterSet: {
          ...state.defaultFilterSet,
          isFavorite: filterSet.isFavorite ? false : state.defaultFilterSet.isFavorite,
        },
      }) as FilterSetsState,
  ),

  on(
    UpdateFilterSetSuccessAction,
    (state, { filterSet }) =>
      ({
        ...state,
        savedFilterSets: [
          ...(state.savedFilterSets ?? []).map((fs) => {
            if (fs.id === filterSet.id) {
              return filterSet;
            }
            return fs;
          }),
        ],
        appliedFilterSet: filterSet,
      }) as FilterSetsState,
  ),

  on(
    DeleteFilterSetSuccessAction,
    (state, { filterSet }) =>
      ({
        ...state,
        savedFilterSets: [...(state.savedFilterSets ?? []).filter((fs) => fs.id !== filterSet.id)],
        appliedFilterSet: state.appliedFilterSet.id === filterSet.id ? state.defaultFilterSet : state.appliedFilterSet,
      }) as FilterSetsState,
  ),

  on(
    SetFavoriteFilterSetAction,
    (state, { filterSet }) =>
      ({
        ...state,
        savedFilterSets: state.savedFilterSets.map((fs) => ({ ...fs, isFavorite: fs.id === filterSet.id })),
        defaultFilterSet: { ...state.defaultFilterSet, isFavorite: state.defaultFilterSet.id === filterSet.id },
      }) as FilterSetsState,
  ),

  on(
    SetFavoriteFilterSetSuccessAction,
    (state, { filterSets }) =>
      ({
        ...state,
        savedFilterSets: filterSets,
        defaultFilterSet: {
          ...state.defaultFilterSet,
          isFavorite: state.savedFilterSets?.some((fs) => fs.isFavorite) ? false : true,
        },
      }) as FilterSetsState,
  ),

  on(
    SetUnnammedFilterSetAction,
    (state) =>
      ({
        ...state,
        appliedFilterSet: state.defaultFilterSet,
      }) as FilterSetsState,
  ),
);
