import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'not-found',
  styleUrls: ['./not-found.component.scss'],
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {
  constructor(public location: Location) {}

  public ngOnInit(): void {
    // when logging/audits enabled, could be useful to track 404 hits?
    console.error('Invalid path hit:', this.location.path());
  }

  public goBack(): void {
    this.location.back();
  }
}
