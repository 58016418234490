import { Component, Input } from '@angular/core';
import { LsActionsMyLSDisplay, RiskActionsDisplay, MMLSRiskActionLabelsDisplay } from '@ls/common-ts-models';

@Component({
  selector: 'mm-ls-risk-action-labels',
  templateUrl: './ls-risk-action-labels.component.html',
  styleUrls: ['./ls-risk-action-labels.component.scss']
})
// This component combines Published Risk + Published LS Actions (in that order) into a single set of color labels.
export class MMLSRiskActionLabelsComponent {
  @Input() public lsRiskActions: LsActionsMyLSDisplay[];

  // Optional. Pass something in if you want an empty placeholder displayed in the view if there are (1) no risk and (2) no actions.
  @Input() public emptyPlaceholder: string;
  public lsRiskActionsDisplay: MMLSRiskActionLabelsDisplay[] = RiskActionsDisplay;
}
