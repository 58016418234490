import { Component, Input } from '@angular/core';

@Component({
  selector: 'cbd-product-pricing-info',
  templateUrl: './cbd-product-pricing-info.component.html'
})
export class CbdProductPricingInfoComponent {
  @Input() public pricing;
  @Input() public expeditedEnabled;
}
