<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="login-container">
    <login-header></login-header>
      
    <div class="entry-main">
        <div *ngIf="!expiredToken && loading" class="entry-container entry-container-small loading">
            <div>Loading</div>
            <p-progressSpinner [style]="{ width: '35px', height: '35px' }"></p-progressSpinner>
        </div>
        <div *ngIf="expiredToken" class="entry-container entry-container-small error">
            <h1 class="inline">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <span>Invitation Expired</span>
            </h1>
            <div class="expired-message">
                For security reasons, login links expire after a fixed amount of time. Please contact your account owner and have them re-send your invitation.
            </div>
        </div>
        <div *ngIf="!expiredToken && !loading" class="entry-container entry-container-small" style="width: 60%;">
            <h1 class="mb20">Welcome!</h1>
            <form [formGroup]="passwordForm" (ngSubmit)="submit()">
                <div id="set-password-form">
                <div class="subtext mb20">Before signing in for the first time, you need to create a password for your account.</div>
                <div class="form-group">
                    <div class="label-wrapper">
                        <label>Password</label>
                    </div>
                    <input type="password" placeholder="Password" formControlName="password"/>
                    <field-validator [control]="this.passwordForm.get('password')" [forInviteUser]=true></field-validator>
                </div>
                <div class="form-group">
                    <div class="label-wrapper">
                        <label>Confirm Password</label>
                        <field-validator [control]="this.passwordForm.get('passwordConfirm')"></field-validator>
                    </div>
                    <input type="password" placeholder="Confirm Password" formControlName="confirmPassword"/>
                </div>
                <div class="form-actions">
                    <button type="submit" [disabled]="!passwordForm.valid" *ngIf="!loading" class="btn btn-primary">Set Password</button>
                    <div class="loading-container" [hidden]="!loading">
                        <p-progressSpinner [style]="{ width: '35px', height: '35px' }"></p-progressSpinner>
                    </div>
                    <br />
                </div>
                </div>
            </form>
        </div>
    </div>
</div>