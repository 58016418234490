import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BillingService } from '@ls/common-ng-components';

@Injectable()
export class EnsurePricingInfoGuard implements CanActivate {
  constructor(
    private router: Router,
    private billingService: BillingService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const currentPricingInfo = this.billingService.getPricingInfo();
    if (currentPricingInfo && currentPricingInfo.sfPricebookId) {
      return true;
    }
    this.router.navigate(['/services/merchant-certification']);
    return false;
  }
}
