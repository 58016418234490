import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { inMyLS, firstECTry } from '@ls/common-ts-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalStorageService, Auth0Service } from '@ls/common-ng-components';

import { UserSignOutAction, UserSignOutSuccessAction } from '../reducers';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DataLayerService } from 'src/app/services/data-layer.service';

@Injectable()
export class AuthenticationEffect {
  public userSignout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSignOutAction),
      mergeMap(async (action) => {
        this.localStorageSvc.clearLocalStorage();

        const location = action.location || '/';
        this._dataLayerService.logLogoutEvent();
        localStorage.removeItem('logInEvent');

        if (this.localStorageSvc.isAuth0Enabled()) {
          this.localStorageSvc.setAuth0TargetUrl(location);
          localStorage.removeItem(inMyLS);
          localStorage.removeItem(firstECTry);
          await this.auth0Service.logout({ logoutParams: { returnTo: environment.CONFIG['auth0RedirectUri'] } });
        } else {
          await this.router.navigate([location]);
        }

        return UserSignOutSuccessAction();
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private auth0Service: Auth0Service,
    private localStorageSvc: LocalStorageService,
    private router: Router,
    private _dataLayerService: DataLayerService,
  ) {}
}
