<div class="info-bubble-wrapper">
  <div class="info-bubble"
    *ngIf="value"
    [class.green]="greenValues.indexOf(value) > -1"
    [class.orange]="value === 'orange'"
    [class.red]="value === 'red'"
    [class.certified]="value === 'certified'"
    [title]="valueDisplayText[value] || value">  
    {{ valueDisplayText[value] || value }}
  </div>
  <flag *ngIf="country" [country]="country"></flag>
</div>