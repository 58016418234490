import { Component } from '@angular/core';
import { AppState, UserSignOutAction } from '../../reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'access-denied',
  styleUrls: ['./access-denied.component.scss'],
  templateUrl: './access-denied.component.html',
})
export class AccessDeniedComponent {
  constructor(private store: Store<AppState>) {}

  public signOut(): void {
    this.store.dispatch(UserSignOutAction({ location: '/login' }));
  }
}
