import { MerchantListState } from './merchant-list.reducer';
import { MerchantDetailState } from './merchant-detail.reducer';
import { HistoryUploadListState } from './history-upload.reducer';
import { DirtyMerchantState } from './dirty-merchants.reducer';
import { ContentLabelsState } from '../../merchant-monitoring-v2/reducers/content-labels.reducer';
import { MerchantMonitoringBulkUploadState } from './bulk-upload.reducer';
import { TagsState } from '../../merchant-monitoring-v2/reducers/tags.reducer';
import { LsTagsState } from '../../merchant-monitoring-v2/reducers/lsTags.reducer';
import { IcasState } from '../../merchant-monitoring-v2/reducers/icas.reducer';
import { TableFiltersState } from '../../merchant-monitoring-v2/reducers/tableFilters.reducer';
import { MerchantSummaryState } from '../../merchant-monitoring-v2/reducers/merchant-summary.reducer';
import { FilterSetsState } from '../../merchant-monitoring-v2/reducers/filter-sets.reducer';

export interface MMAppState {
  merchantList: MerchantListState;
  tags: TagsState;
  lsTags: LsTagsState;
  icas: IcasState;
  contentLabels: ContentLabelsState;
  tableFilters: TableFiltersState;
  savedFilters: FilterSetsState;
  merchantDetail: MerchantDetailState;
  merchantSummary: MerchantSummaryState;
  historyUploadList: HistoryUploadListState;
  dirtyMerchants: DirtyMerchantState[];
  bulkUpload: MerchantMonitoringBulkUploadState;
}
