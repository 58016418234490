import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  fnHistoryUploadListState,
  HistoryUploadListState,
  initialHistoryUploadListState,
  fnHistoryUploadListPagination,
  HistoryUploadListAction,
} from '../reducers';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { Router } from '@angular/router';
import { GenericNotificationAction, LocalStorageService, SeverityOptions } from '@ls/common-ng-components';
import { MMUploadService } from '../services';
import { takeUntil } from 'rxjs/operators';

import {
  MyLSMerchantConstants,
  PageUpdateOptions,
  Pagination,
  WBFilterSortDirection,
  MyLsFilterGroup,
  MyLSFilter,
  MyLSFilterNames,
  WBFilterOperator,
} from '@ls/common-ts-models';
import { Observable, Subject } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';

type PrimeNGSortDirection = 1 | -1;

@Component({
  selector: 'mm-upload-history',
  styleUrls: ['./uploadHistory.component.scss'],
  templateUrl: './uploadHistory.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class MMUploadHistoryComponent implements OnDestroy, OnInit {
  public columns: any[] = [];
  public rows: any[] = [];
  public historyUploadState$: Observable<HistoryUploadListState>;
  public historyUploadState: HistoryUploadListState = initialHistoryUploadListState;
  public pageSize: number = MyLSMerchantConstants.MAX_MERCHANTS_PER_PAGE;

  public pagination$: Observable<Pagination>;

  public pagination: Pagination = {
    totalRecords: 0,
    totalPages: 0,
    pageNumber: 1,
    startPosition: 0,
    endPosition: 0,
  };

  public isInitiallyBusy = true;

  private mechantsId$: Observable<any>;
  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private historyUploadService: MMUploadService,
    private localStorageService: LocalStorageService,
  ) {}

  // round buttons
  // reset  buttons styles change
  public ngOnInit(): void {
    this.columns = [
      { field: 'uploaded_date', header: 'Upload Date' },
      { field: 'username', header: 'Username' },
      { field: 'total_rows', header: 'Total' },
      { field: 'import_count', header: 'New' },
      { field: 'update_count', header: 'Updated' },
      { field: 'error_count', header: 'Failed' },
      { field: 'actions', header: 'Action' },
    ];

    this.historyUploadState$ = this.store.select(fnHistoryUploadListState);
    this.historyUploadState$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      this.historyUploadState = state;

      if (state.err) {
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'History Upload List Failed to Load',
            detail:
              'Sorry- it looks like we are having trouble displaying your merchants - please try again.  If this problem persists, please click the Support button at top right',
            sticky: true,
            blocking: false,
          }),
        );
      }
      if (!state.pending) {
        this.isInitiallyBusy = false;
      }
    });

    this.pagination$ = this.store.select(fnHistoryUploadListPagination);
    this.pagination$.pipe(takeUntil(this.destroyed$)).subscribe((pagination) => (this.pagination = pagination));
    const sortOrder = WBFilterSortDirection.Descending;

    this.store.dispatch(
      HistoryUploadListAction({
        sortDirection: sortOrder,
        pageUpdateOptions: {
          pageNumber: 1,
          pageSize: MyLSMerchantConstants.MAX_MERCHANTS_PER_PAGE,
        },
      }),
    );
  }

  public loadHistoryUploadLazy(event: LazyLoadEvent) {
    const sortOrder = this.getReadableSortDirection(event.sortOrder as PrimeNGSortDirection);
    this.store.dispatch(
      HistoryUploadListAction({
        sortDirection: sortOrder,
        pageUpdateOptions: { pageNumber: this.getPageNumber(event.first) } as PageUpdateOptions,
      }),
    );
  }

  public searchMerchants(customerUploadId: number) {
    this.historyUploadState.pending = true;
    this.mechantsId$ = this.historyUploadService.getMerchantsById(customerUploadId);
    this.mechantsId$.subscribe(
      (merchantIds: { merchantIds: any }) => {
        this.historyUploadState.pending = false;
        const filter: MyLSFilter = {
          name: MyLSFilterNames.Mid,
          operator: WBFilterOperator.Equals,
          value: merchantIds.merchantIds,
        };
        const filters: MyLsFilterGroup = {
          filters: [filter],
        };
        this.localStorageService.setMerchantFilters(filters);
        this.router.navigate(['/services/merchant-monitoring/list']);
      },
      () => {
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'History Upload Merchants List Failed to Load',
            detail:
              'Sorry- it looks like we are having trouble displaying your merchants - please try again.  If this problem persists, please click the Support button at top right',
            sticky: true,
            blocking: false,
          }),
        );
      },
    );
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getReadableSortDirection(order: PrimeNGSortDirection): WBFilterSortDirection {
    return order === 1 ? WBFilterSortDirection.Ascending : WBFilterSortDirection.Descending;
  }

  private getPageNumber(startPosition: number) {
    if (startPosition === 0) {
      return 1;
    }
    return Math.ceil(startPosition / this.pageSize) + 1;
  }
}
