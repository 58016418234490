import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'merchant-certification',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./merchant-certification.component.scss'],
  templateUrl: './merchant-certification.component.html',
})
export class MerchantCertificationComponent implements OnInit {
  public newMyLSPortalURL = environment.CONFIG.experienceCloudURL;

  public ngOnInit(): void {
    // @todo cant have empty block, dont want to remove ngoninit and test / break
    1 === 1;
  }
}
