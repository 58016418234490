import { takeUntil, skipWhile, tap } from 'rxjs/operators';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AppState,
  AccountLoadAction,
  UserAuthenticateSuccessAction,
  UserSignOutAction,
  fnAccountState,
  fnCertificationsState,
} from '../../reducers';
import { CertificationsGetAction } from '../../modules/certifications/reducers';
import {
  EntitlementsGetAction,
  GenericNotificationAction,
  SeverityOptions,
  LocalStorageService,
  EntitlementsAddRecentlyPurchasedAction,
} from '@ls/common-ng-components';
import { ConsentComponent } from '../../components/forms/consent/consent.component';
import { User, Roles } from '@ls/common-ts-models';
import { Subject, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  @ViewChild(ConsentComponent) public consentModal: ConsentComponent;
  public user: User;

  private destroyed$: Subject<boolean> = new Subject();
  private navigateUrl: string;
  private returnUrl: string;
  private localStorageSvc: LocalStorageService;

  constructor(
    public route: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
  ) {
    this.localStorageSvc = new LocalStorageService();
  }

  public ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    if (this.returnUrl === '/loading' || !this.returnUrl) {
      this.navigateUrl = 'account/home';
    } else {
      this.navigateUrl = this.returnUrl;
    }

    this.store.dispatch(AccountLoadAction());
    this.store.dispatch(CertificationsGetAction());
    this.store.dispatch(EntitlementsGetAction());

    const recentlyPurchasedEntitlements = this.localStorageSvc.getRecentlyPurchasedEntitlements();
    if (recentlyPurchasedEntitlements) {
      Object.keys(recentlyPurchasedEntitlements).forEach((productFamily) => {
        this.store.dispatch(EntitlementsAddRecentlyPurchasedAction({ productFamily }));
      });
    }

    combineLatest([this.store.select(fnAccountState), this.store.select(fnCertificationsState)])
      .pipe(
        skipWhile(([accountState, certificationsState]) => accountState.pending || certificationsState.pending),
        takeUntil(this.destroyed$.asObservable()),
        tap(() => {
          // If user gets here, they are successfully logged in, but the store might not have their roles (if coming from invited user or reset password flow, which log in via the api after success).
          // However, localStorage should have their roles, so we can update the store now.
          // NOTE: we do this in a finalize block because it will kick off another account selector dispatch- if we do it in the subscribe block, we get an infinite loop
          const authUser = this.localStorageSvc.getAuthenticatedUser();
          if (authUser && this.user) {
            this.store.dispatch(
              UserAuthenticateSuccessAction({
                user: authUser,
                roles: authUser.roles,
              }),
            );
          }
        }),
      )
      .subscribe({
        next: ([accountState]) => {
          if (accountState?.user?.role && accountState.user.role.includes(Roles.cpv2AccountOwnerSF)) {
            window.location.href = environment.CONFIG.experienceCloudURL;
          }
          this.user = { ...accountState.user };
          if (!this.user.portalTermsConsentDate || !this.user.gdprConsentDate) {
            this.consentModal.showModal();
          } else {
            this.router.navigate([this.navigateUrl]);
          }
        },
        error: () => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Error loading your account info',
              detail:
                'There was an error loading your account information.  Please try logging in again.  If this problem persists, please contact support',
              sticky: false,
              blocking: false,
            }),
          );
          this.store.dispatch(UserSignOutAction({ location: '/login' }));
        },
      });
  }

  public onAgreeToTerms() {
    this.router.navigate([this.navigateUrl]);
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
