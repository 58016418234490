import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BillingService,
  LegitScriptModalComponent,
  GenericNotificationAction,
  SeverityOptions,
} from '@ls/common-ng-components';
import { Subscription, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EnrollService, QuestionnaireInfo } from '../services';
import {
  MAX_CC_AMOUNT_CERTIFICATION,
  CertEnrollRequest,
  BillingItem,
  ExpeditedProcessingProductGuid,
  Labels,
  CertificationType,
} from '@ls/common-ts-models/';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CertificationsGetAction } from '../reducers';
import { UrlRegEx } from '@ls/common-ng-components';
import { AppState } from 'src/app/reducers';
import { NgxScrollEvent } from '../../ngx-scroll-event/ngx-scroll-event.directive';

@Component({
  selector: 'generic-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GenericEnrollComponent implements OnInit, OnDestroy {
  public firstTry = true; // the "Submit" button should be enabled so validation can be triggered
  public enrollFormGroup: FormGroup;
  public isCbdProduct: boolean;
  public isCbdWebsite: boolean;
  @Input() public expeditedEnabled = true;
  @Input() public inputFormGroup: FormGroup;
  @Input() public pricing;
  @Input() public pricingInfo;
  @Input() public questionnaireInfo: QuestionnaireInfo;
  @Input() public preventPayment = false;
  @Input() public confirmationStep: (fn) => void;
  @Input() public validConfirms = true;
  @Output() public calculatePricing: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public validate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public validateConfirmations: EventEmitter<any> = new EventEmitter<any>();

  public cbdTermsAccepted = true;
  // This object is going to help us to trigger the "Continue" button with only one variable
  // if later we want/need to add more terms (checkboxes) we can add those ones to this object with
  // no modifications on the "Continue" button submit validations
  public cbdTerms = {
    cbdTermsStandards: false,
    cbdTermsNonRefundable: false,
    cbdTermsData: false,
  };

  public hasScrolledToBottom = false;
  public termsSelected = false;
  public hasCBDScrolledToBottom = false;
  public expeditedReview = false;
  public expeditedReviewConfirmed = false;

  public cbdElement: HTMLInputElement;
  public enrolling = false;
  public apiError;

  public dba: FormControl = new FormControl('', Validators.required);
  public legalName: FormControl = new FormControl('', Validators.required);
  public primaryUrl: FormControl = new FormControl('', [Validators.required, Validators.pattern(UrlRegEx())]);

  public expeditedPrice: number = undefined;

  @ViewChild('pricingModal') public pricingModal: LegitScriptModalComponent;
  @ViewChild('termsModal') public termsModal: LegitScriptModalComponent;
  @ViewChild('expeditedModal') public expeditedModal: LegitScriptModalComponent;
  @ViewChild('cbdTermsModal') public cbdTermsModal: LegitScriptModalComponent;

  private enrollSubscription: Subscription;
  constructor(
    public enrollSvc: EnrollService,
    public billingSvc: BillingService,
    public router: Router,
    public store: Store<AppState>,
  ) {}

  public ngOnInit() {
    // certificationType property is only defined at the begining only for cert types differents than CBD
    // If it's not defined that means that cert type is CBD, and we set it to true in order to hide the
    // primaryUrl input until the user select the "Website" CBD certification
    if (this.questionnaireInfo) {
      this.isCbdProduct = this.questionnaireInfo.certificationType ? false : true;
    }

    this.enrollFormGroup = new FormGroup({
      dba: this.dba,
      legalName: this.legalName,
      primaryUrl: this.primaryUrl,
    });
    this.enrollFormGroup.addControl('inputFormGroup', this.inputFormGroup);

    this.enrollSvc.getAddonPricing().subscribe((r) => {
      this.expeditedPrice = r.EXPEDITED_PROCESSING;
    });
  }

  public ngOnDestroy() {
    if (this.enrollSubscription) {
      this.enrollSubscription.unsubscribe();
    }
  }

  // Fires when the div.dialog-body is scrolling through the T/Cs.
  public onDialogScroll(event: NgxScrollEvent) {
    if (event.isWindowEvent === false && event.isReachingBottom === true) {
      this.hasScrolledToBottom = true;
    }
  }

  public onExpeditedChecked(e: Event) {
    const checkbox: HTMLInputElement = e.target as any;

    // If we're clicking the checkbox AND we haven't read the terms yet (scrolled to the bottom of the modal) then show the modal instead of allowing the checkbox click.
    if (checkbox.checked === true && this.expeditedReviewConfirmed === false) {
      this.expeditedModal.showDialog();
      // The checked event has already fired, to consistently revert we raise another event to revert the checkbox state.
      setTimeout(() => {
        this.expeditedReview = false;
      });
    }
  }

  public confirmExpeditedReview() {
    this.expeditedModal.hideDialog();
    this.expeditedReview = true;
    this.expeditedReviewConfirmed = true;
  }

  // This method is for 2 purposes
  // 1.- We check if all the CBD checkboxes are checked, if is the case, we set the cbdTermsAccepted as true
  // 2.- We pop up the explanation/details about the "data" checkbox if it's the first time that the checkbox is marked
  public onCBDTermsChecked(e: Event, property: string) {
    const checkbox: HTMLInputElement = e.target as any;
    if (checkbox.name === 'cbd-terms-data') {
      // We had to assing the element to a constant so we can use it in other method
      this.cbdElement = checkbox;
      if (checkbox.checked === true && this.hasCBDScrolledToBottom === false) {
        this.cbdTermsModal.showDialog();
        // The checked event has already fired, to consistently revert we raise another event to revert the checkbox state.
        this.cbdElement.checked = false;
        this.cbdTerms.cbdTermsData = false;
      }
    } else {
      this.cbdTerms[property] = checkbox.checked;
    }
    this.cbdTermsAccepted = Object.values(this.cbdTerms).every((v) => v === true) ? true : false;
  }

  // Fires when the T/Cs checkbox is clicked.
  public onTermsChecked(e: Event) {
    const checkbox: HTMLInputElement = e.target as any;

    // If we're clicking the checkbox AND we haven't read the terms yet (scrolled to the bottom of the modal) then show the modal instead of allowing the checkbox click.
    if (checkbox.checked === true && this.hasScrolledToBottom === false) {
      this.termsModal.showDialog();
      // The checked event has already fired, to consistently revert we raise another event to revert the checkbox state.
      setTimeout(() => {
        this.termsSelected = false;
      });
    }
  }

  // Fires when someone clicks "Agree" on the T/Cs modal.
  public onAgreeToTerms() {
    this.termsSelected = true;
    this.termsModal.hideDialog();
  }

  public onAgreeToCBDTerms() {
    this.cbdElement.checked = true;
    this.cbdTerms.cbdTermsData = true;
    this.hasCBDScrolledToBottom = true;
    this.cbdTermsAccepted = Object.values(this.cbdTerms).every((v) => v === true) ? true : false;
    this.cbdTermsModal.hideDialog();
  }

  public async onChange(event: any) {
    this.calculatePricing.emit();
    // Update the validators only if the form input change is related to the certification type
    if (event.target.name === 'certificationType') {
      // Setting the primary url to optional only if the cert type is CBD Product
      if (this.questionnaireInfo?.certificationType === 'cbd_product') {
        // Hide the primary url input field and remove the "Required" validator and set only the "URL" validator
        this.isCbdProduct = true;
        this.isCbdWebsite = false;
        this.cbdTermsAccepted = false;
        this.enrollFormGroup.controls['primaryUrl'].clearValidators();
        this.enrollFormGroup.controls['primaryUrl'].setValue(' ');
      } else {
        // Show the primary url input field and set both "Required" and "URL" validators for all
        // other certification types but CBD Product
        this.enrollFormGroup.controls['primaryUrl'].setValidators([
          Validators.required,
          Validators.pattern(UrlRegEx()),
        ]);
        this.isCbdProduct = false;
        this.isCbdWebsite = true;

        // Set the value to null so the validators can take effect, if we don't set it to null, the user
        // needs to type something on the input in order to activate the validators which can be confused?
        this.enrollFormGroup.controls['primaryUrl'].setValue(null);
      }

      // Set the Terms to FALSE / UNCHECKED so the customer accept them again
      this.cbdTermsAccepted = false;
      this.cbdTerms.cbdTermsStandards = false;
      this.cbdTerms.cbdTermsNonRefundable = false;
      this.cbdTerms.cbdTermsData = false;
    }
    this.validateConfirmations.emit();
  }

  public async onSubmit() {
    this.validate.emit(this.firstTry);
    if (this.firstTry) {
      this.firstTry = false;
      this.enrollFormGroup.updateValueAndValidity();
      if (!this.enrollFormGroup.valid || !this.termsSelected || !this.cbdTermsAccepted) {
        return;
      }
    }

    const cbdTypes: string[] = [CertificationType.cbdProduct, CertificationType.cbdWebsite];
    if (!cbdTypes.includes(this.questionnaireInfo.certificationType)) {
      this.validConfirms = true;
    }

    if (this.validConfirms === true) {
      this.submit();
    }
  }

  private submit() {
    this.enrolling = true;
    const primaryUrlQ = this.questionnaireInfo.questions.find((question) => question.name === 'primaryUrl');
    const primaryUrlQuestion = this.enrollSvc.buildAnsweredQuestion('text', primaryUrlQ.id, this.primaryUrl.value);
    const dbaQ = this.questionnaireInfo.questions.find((question) => question.name === 'doingBusinessAs');
    const dbaQuestion = this.enrollSvc.buildAnsweredQuestion('text', dbaQ.id, this.dba.value);

    const labels: Labels[] = [];

    if (this.questionnaireInfo.certificationType === 'healthcare') {
      const brokerQ = this.questionnaireInfo.questions.find((question) => question.name === 'pharmacyBroker');
      if (brokerQ) {
        const broker = this.questionnaireInfo.answeredQuestions.find((question) => question.question_id === brokerQ.id);
        if (broker.answered_inputs[0].answer_text === 'yes') {
          labels.push(Labels.broker);
        }
      }
    }

    const submitData: CertEnrollRequest = {
      sku: this.questionnaireInfo.pricingTierSku,
      typeId: this.questionnaireInfo.typeId,
      certificationType: this.questionnaireInfo.certificationType,
      questionnaireTypeName: this.questionnaireInfo.questionnaireTypeName,
      businessName: this.legalName.value,
      primaryUrl: this.primaryUrl.value,
      nonprofit: this.questionnaireInfo.nonprofit,
      enrolledItemCount: this.questionnaireInfo.enrolledItemCount,
      dba: this.dba.value,
      questions: [primaryUrlQuestion, dbaQuestion].concat(this.questionnaireInfo.answeredQuestions),
      expedited: this.expeditedReview,
      labels,
    };

    this.enrollSubscription = this.enrollSvc
      .submit(submitData)
      .pipe(
        map((cert: any) => {
          return cert.id;
        }),
        catchError(() => {
          this.enrolling = false;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Operation failed',
              detail: 'Unable to create certification.  Please try again or contact support.',
              sticky: true,
              blocking: false,
            }),
          );
          return empty();
        }),
      )
      .subscribe((certId: any) => {
        if (!certId) {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Operation failed',
              detail: 'Unable to create certification.  Please try again or contact support.',
              sticky: true,
              blocking: false,
            }),
          );
          return; // if there's no cert ID, billing will fail
        }
        const billingAmount = this.pricingInfo.discount
          ? this.pricingInfo.billingAmount * (1 - this.pricingInfo.discount / 100)
          : this.pricingInfo.billingAmount;
        const recurringAmount = this.pricingInfo.discount
          ? this.pricingInfo.recurringAmount * (1 - this.pricingInfo.discount / 100)
          : this.pricingInfo.recurringAmount;

        const billingItems: BillingItem[] = [
          {
            description: 'Application',
            amount: billingAmount,
            recurringDescription: 'Subscription',
            recurringAmount,
            recurringOnApproval: true,
            recurringPeriod: 'yearly',
            productId: this.pricingInfo.productId,
            subscriptionProductId: this.pricingInfo.subscriptionProductId,
            quantity: this.pricingInfo.quantity,
          },
        ];

        if (this.expeditedReview) {
          billingItems.push({
            amount: this.expeditedPrice,
            description: 'Expedited Processing Service',
            quantity: 1,
            productId: ExpeditedProcessingProductGuid,
          });
        }

        this.billingSvc.setPricingInfo({
          billingItems,
          certId,
          sfPricebookId: this.questionnaireInfo.pricingTierSku,
          oppType: 'Certification Application',
          maxCCAmount: MAX_CC_AMOUNT_CERTIFICATION,
        });

        // load the updated list of certs into the store now that we've successfully enrolled
        this.store.dispatch(CertificationsGetAction());

        // get the "now" total, for invoice levels
        const billingTotal = billingItems.reduce((acc, cur) => acc + cur.amount, 0);
        if (billingTotal > MAX_CC_AMOUNT_CERTIFICATION) {
          // this customer will be sent an invoice for the application fee
          this.router.navigate(['/services/merchant-certification/payment-invoice']);
        } else {
          this.router.navigate(['/services/merchant-certification/payment']);
        }
      });
  }
}
