import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

type getMessageFunc = (params: any) => string;

@Component({
  selector: 'field-validator',
  templateUrl: './field-validator.component.html',
  styleUrls: ['./field-validator.component.scss'],
})
export class FieldValidatorComponent {
  @Input() public control: AbstractControlDirective | AbstractControl;

  private readonly errorMessages: { [type: string]: getMessageFunc } = {
    required: () => 'Required',
    email: () => 'Invalid email',
    'match-fail': (fieldName: string) => `Doesn't match ${fieldName}`,
    minlength: (params: { requiredLength: number }) => `Min length: ${params.requiredLength}`,
    maxlength: (params: { requiredLength: number }) => `Max length: ${params.requiredLength}`,
    pattern: (params: { requiredPattern: string }) => `Required pattern is: ${params.requiredPattern}`,
    checkWhitespace: () => 'Required',
    checkInvalidCharacter: () => 'Invalid character(s)',
  };

  public shouldShowErrors(): boolean {
    return this.control && this.control.errors && (this.control.dirty || this.control.touched);
  }

  public listOfErrors(): string[] {
    return Object.keys(this.control.errors).map((field) => this.getMessage(field, this.control.errors[field]));
  }

  // We can only display one error at a time (limited real-estate), so we expect errors to be prioritized.
  public getFieldError(): string {
    const errors = this.listOfErrors();
    if (errors.length > 0) {
      return errors[0];
    } else {
      return '';
    }
  }

  private getMessage(type: string, params: any) {
    return this.errorMessages[type](params);
  }
}
