import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ls-side-bar',
  templateUrl: './ls-side-bar.component.html',
  styleUrls: ['./ls-side-bar.component.scss'],
})
export class LsSideBarComponent {
  @Input() title1: string;
  @Input() title2: string;
  @Input() isVisible: boolean;
  @Input() isLoading: boolean;
  @Input() tags: string[] = [];
  @Output() closeSidebar: EventEmitter<boolean> = new EventEmitter();
  @Output() copyLink: EventEmitter<boolean> = new EventEmitter();
}
