import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@ls/common-ng-components';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class PushNotificationService {
  private mmApiHost = environment.CONFIG.mmApiHost;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {}

  public iotAuthorizer(): Observable<any> {
    const options = this.localStorageService.getJWTAndSetOptions();
    return this.http.get(`${this.mmApiHost}/iot-authorizer`, options);
  }
}
