<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="widgets-container">
  <div class="widget full-widget">
    <div class="widget-header widget-header-flex">
      <span class="no-icon">
        <h1>Upload Data</h1>
      </span>
      <a routerLink="/services/merchant-monitoring/list">
        <i class="fa fa-undo"></i>
        <span>Back to list</span>
      </a>
    </div>
    <div class="widget-body centered upload-wrapper">
      <div class="p-grid p-align-center">
        <div class="p-col-12 p-nogutter">
          <p-button icon='pi pi-download' label="MCC codes" styleClass="ui-button-info download-option" (onClick)="triggerDownload('downloadMcCodes')"></p-button>
          <p-button icon='pi pi-download' label="Upload Template" styleClass="ui-button-info download-option" (onClick)="triggerDownload('downloadTemplate')"></p-button>
        </div>
    </div>
      <div class="loading" *ngIf="isLoading">
        <p-progressBar [value]="uploadProgress" [showValue]="false"></p-progressBar>
        <br />
        <div *ngIf="uploadProgress < 100;else success">
          <div *ngIf="preparingUpload;else uploading">
            Preparing your upload
          </div>
          <ng-template #uploading>
            Uploading your file ({{fileName}}): {{uploadProgress}}%
          </ng-template>
          <br />
          <span class="warning-bold">Note: navigating away from this screen while the upload is in progress will cancel the operation</span>
        </div>
        <ng-template #success>
          <div class="upload-success">
            <div>Your file: <code>{{fileName}}</code> was successfully uploaded!</div>
            <div class="warning-notice">
              Please note: It can take some time to process new merchants or update existing merchants, so these may not appear immediately in the list and detail views.
            </div>
            <a (click)="continue()">Continue to List View</a>
          </div>
        </ng-template>
      </div>
      <p-fileUpload #file [customUpload]="true" id="fileUpload"
        accept="{{acceptedFileTypes}}" [chooseLabel]="'Select File'"
        ngDefaultControl [disabled]="isLoading"
        (uploadHandler)="startFileUploadProcess($event)"
        (onSelect)="resetValidationMessage()"
        (onClear)="resetValidationMessage()"
        (onRemove)="resetValidationMessage()">
      </p-fileUpload>
      <div class="file-restrictions">
        {{fileUploadRestrictions}}
      </div>
      <div class="file-restrictions-error" *ngIf='validationMessage'>
        {{validationMessage}}
      </div>
    </div>
  </div>
</div>

<a #downloadMcCodes hidden href="{{downloadURLs.mcCodes}}" download></a>
<a #downloadTemplate hidden href="{{downloadURLs.template}}" download></a>