import { createAction, createReducer, on, props } from '@ngrx/store';
import { TransactionOrderFilter } from './paginated-transactions-list.reducer';

export const TestTransactionsDownloadTransactionsAction = createAction(
  '[Test Transactions] Get Download',
  props<TransactionOrderFilter>(),
);
export const TestTransactionsDownloadTransactionsSuccessAction = createAction(
  '[Test Transactions] Get Download Success',
  props<{ data: Blob }>(),
);
export const TestTransactionsDownloadTransactionsErrorAction = createAction(
  '[Test Transactions] Get Download Error',
  props<{ errorText: string; errorType: number }>(),
);
export const TestTransactionsZippingAction = createAction('[Test Transactions] Zipping', props<{ zipping: boolean }>());
export const TestTransactionRequestScreenshotsAction = createAction(
  '[Test Transaction] Request screenshots',
  props<{ transactionId: string; client?: string; websiteUrl: string }>(),
);
export const TestTransactionRequestScreenshotsSuccessAction = createAction(
  '[Test Transaction] Request screenshots Success',
  props<{ status: string }>(),
);
export const TestTransactionRequestScreenshotsErrorAction = createAction(
  '[Test Transaction] Request screenshots Error',
  props<{ errorText: string; errorType: number }>(),
);
export const TestTransactionDownloadAttachmentAction = createAction(
  '[Test Transaction] Get Attachment Download',
  props<{ transactionId: string; attachmentIds: string[]; downloadType: 'screenshot' | 'attachment' }>(),
);
export const TestTransactionDownloadAttachmentSuccessAction = createAction(
  '[Test Transaction] Get Attachment Download Success',
  props<{ signedUrls: { url: string; name: string; id: string; type: 'screenshot' | 'attachment' }[] }>(),
);
export const TestTransactionDownloadAttachmentErrorAction = createAction(
  '[Test Transaction] Get Attachment Download Error',
  props<{ errorText: string; errorType: number }>(),
);

export interface TestTranasctionsDownloadState {
  transactionsFile?: Blob;
  pending: boolean;
  zipping?: boolean;
  error?: {
    errorText: string;
    errorType: number;
  };
  attachmentUrls?: { url: string; name: string; id: string; type: 'screenshot' | 'attachment' }[];
  donwloadUrls?: { url: string; name: string; id: string }[];
}

const initialState: TestTranasctionsDownloadState = { pending: false, zipping: false };

export const TestTransactionsDownloadReducer = createReducer(
  initialState,

  on(TestTransactionsDownloadTransactionsAction, (state) => ({
    ...state,
    pending: true,
    error: null,
  })),

  on(
    TestTransactionsDownloadTransactionsSuccessAction,
    (state, props) =>
      ({
        ...state,
        transactionsFile: props.data,
        pending: false,
        error: null,
      }) as TestTranasctionsDownloadState,
  ),

  on(
    TestTransactionsDownloadTransactionsErrorAction,
    (state, props) =>
      ({
        ...state,
        error: props,
        pending: false,
      }) as TestTranasctionsDownloadState,
  ),

  on(
    TestTransactionsZippingAction,
    (state, props) =>
      ({
        ...state,
        zipping: props.zipping,
      }) as TestTranasctionsDownloadState,
  ),

  on(TestTransactionDownloadAttachmentAction, (state) => ({
    ...state,
    pending: true,
    error: null,
  })),

  on(TestTransactionDownloadAttachmentSuccessAction, (state, props) => {
    return {
      ...state,
      pending: false,
      attachmentUrls: props.signedUrls,
      donwloadUrls: props.signedUrls,
      error: null,
    };
  }),

  on(TestTransactionDownloadAttachmentErrorAction, (state, props) => ({
    ...state,
    attachmentUrls: [],
    donwloadUrls: [],
    pending: false,
    error: props,
  })),

  on(TestTransactionRequestScreenshotsAction, (state) => ({
    ...state,
    pending: true,
    error: null,
  })),

  on(TestTransactionRequestScreenshotsSuccessAction, (state) => {
    return {
      ...state,
      pending: false,
      error: null,
    };
  }),

  on(TestTransactionRequestScreenshotsErrorAction, (state, props) => ({
    ...state,
    pending: false,
    error: props,
  })),
);
