import { UserEffect } from './user.effect';
import { AccountEffect } from './account.effect';
import { SelectableAccountEffect } from './selectable-account.effect';
import { EntitlementsEffect } from './entitlements.effect';
import { UsageEffect } from './usage.effect';
import { PushNotificationEffect } from './push-notification.effect';
import { AuthenticationEffect } from './authentication.effect';

export * from './user.effect';
export * from './account.effect';
export * from './selectable-account.effect';
export * from './entitlements.effect';
export * from './usage.effect';
export * from './push-notification.effect';
export * from './authentication.effect';

export const APP_EFFECTS = [
  UserEffect,
  AccountEffect,
  SelectableAccountEffect,
  EntitlementsEffect,
  UsageEffect,
  PushNotificationEffect,
  AuthenticationEffect,
];
