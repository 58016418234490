<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="login-container">
  <login-header></login-header>

  <div class="entry-main">
    <div class="entry-container entry-container-small reset-pw-main">
      <h1 class="mb20">Reset Your Password</h1>
      <div class="subtext mb20">Your new password must be at least ten characters long</div>
      <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
        <div id="reset-form">
          <div class="form-group">
            <label>Password</label>
            <input #passwordField="ngModel" name="password" type="password" [(ngModel)]="password" placeholder="Password"
              required minlength="10" maxlength="255" (keyup)="f.form.controls['confirmPassword'].updateValueAndValidity();" />
            <field-validator [control]="passwordField"></field-validator>
          </div>
          <div class="form-group">
            <label>Confirm Password</label>
            <input #confirmPassword="ngModel" name="confirmPassword" type="password" [(ngModel)]="passwordConfirm" placeholder="Confirm Password"
              placeholder="Confirm Password" field-match="password" required />
            <field-validator [control]="confirmPassword"></field-validator>
          </div>
          <div class="error mb20" *ngIf="error !== undefined" [ngSwitch]="error">
            <span *ngSwitchCase="409">This reset token is invalid. Please <a routerLink="/forgot-password">request a new password reset</a>.</span>
            <span *ngSwitchCase="422">{{errMsg}}</span>
            <span *ngSwitchDefault>An unknown error occurred. Please refresh and try again or <a routerLink="/forgot-password">request a new password reset</a>.</span>
          </div>
          <div class="form-actions">
            <button *ngIf="!loading" class="btn btn-primary">Sign In</button>
            <div class="loading-container" [hidden]="!loading">
              <p-progressSpinner [style]="{ width: '35px', height: '35px' }"></p-progressSpinner>
            </div>
            <br />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
