import { Label } from '@ls/common-ts-models';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import { GetTagsAction, GetTagsSuccessAction, TagsErrorAction } from '../reducers/tags.reducer';
import { TagsService } from '../services';

@Injectable()
export class TagsEffect {
  public list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTagsAction),
      switchMap((action) =>
        this.tagsService.getTags().pipe(
          map((res: Label[]) =>
            GetTagsSuccessAction({
              tags: res,
            }),
          ),
          catchError((err) => of(TagsErrorAction({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private tagsService: TagsService,
  ) {}
}
