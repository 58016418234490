
<div class="test-transactions-container">
    <div class="test-transactions-header">
        <div class="info">
            <span class="title">Test Transactions</span>
            <span class="summary">
                <div class="summary-item" *ngFor="let summaryItem of summaryItems$|async">
                    <span>{{summaryItem.title}}</span>
                    <span>{{summaryItem.count ?? 0}}</span>
                </div>
            </span>
        </div>
        <span class="actions">
            <button class="button secondary" (click)="exportTransactions()">
                <span class="pi pi-download"></span>
                Export Transactions
            </button>
            <button class="button primary" (click)="showRequestNewTransaction = true" [disabled]="(requesting$ | async) > 0">
                <span class="pi pi-file-edit"></span>
                Request Test Transactions
            </button>
        </span>
    </div>
    <div class="test-transactions-table">
        <ls-table
            [value]="testTransactions$|async"
            [pagination]="pageData$|async"
            [pageSize]="pageSize"
            [isLoading]="isLoading$|async"
            [columnsConfig]="colunnsData"
            [sortField]="sortField"
            [sortOrder]="sortOrder"
            [availableFilters]="availableFilters"
            [appliedFilters]="appliedFilters"
            [visibleFilters]="currentFilters"
            [filterSetsEnabled]="false"
            (pageChangeEvent)="onPageChangeEvent($event)"
            (sortChangeEvent)="onSortChangeEvent($event)"
            (addFilter)="onAddFilter($event)"
            (clearFilters)="onClearFilters()"
            (applyFilter)="onApplyFilter($event)"
            (removeFilter)="onRemoveFilter($event)"
        >
        <ng-template #row let-transaction>
            <td class="l" (click)="onRowClicked(transaction)">{{transaction.id}}</td>
            <td class="m" (click)="onRowClicked(transaction)">{{transaction.clientSub}}</td>
            <td class="l"><a target="_blank" [href]="'https://' + transaction.websiteUrl">{{transaction.websiteUrl}}</a></td>
            <td class="m" (click)="onRowClicked(transaction)"><text-pill [value]="transaction.category"></text-pill></td>
            <td class="s" (click)="onRowClicked(transaction)">{{transaction.source}}</td>
            <td class="l" (click)="onRowClicked(transaction)">{{transaction.requestId}}</td>
            <td class="s" (click)="onRowClicked(transaction)"><text-pill [value]="transaction.transactionStatus"></text-pill></td>
            <td class="m" (click)="onRowClicked(transaction)">{{transaction.transactionDate|date:'shortDate'}}</td>
            <td class="m" (click)="onRowClicked(transaction)">{{transaction.analysisDate|date:'shortDate'}}</td>
            <td class="m" (click)="onRowClicked(transaction)">{{transaction.transactionDescription}}</td>
            <td class="s" (click)="onRowClicked(transaction)">{{transaction.ccLast4}}</td>
            <td class="s" (click)="onRowClicked(transaction)">{{transaction.transactionAmount | currency: transaction.transactionCurrency}}</td>
            <td class="xs" (click)="onRowClicked(transaction)">{{transaction.transactionCurrency}}</td>
            <td class="s" (click)="onRowClicked(transaction)">{{transaction.transactionUSDAmount | currency: 'USD'}}</td>
            <td class="s" (click)="onRowClicked(transaction)">{{transaction.purchaseAmount | currency: 'USD'}}</td>
            <td class="s" (click)="onRowClicked(transaction)">{{transaction.mccCategory}}</td>
            <td class="l" (click)="onRowClicked(transaction)">{{transaction.cardholderLocation}}</td>
            <td class="s" (click)="onRowClicked(transaction)">{{transaction.proxyLocation}}</td>
            <td class="l" (click)="onRowClicked(transaction)">{{transaction.paymentWebsite}}</td>
            <td class="m" (click)="onRowClicked(transaction)">{{transaction.merchantDescriptor}}</td>
            <td class="l" (click)="onRowClicked(transaction)">{{transaction.analysisNote}}</td>
            <td class="m" (click)="onRowClicked(transaction)">{{transaction.monitoringStatus}}</td>
        </ng-template>

        <ng-template #emptyMessage>
            <div class="empty-message">
                <div class="empty-message-top">
                    <h2>Start performing test transactions!</h2>
                    <div class="empty-message-body">Get started by adding URLs to test. Enter multiple URLs or upload an XLS file.</div>
                </div>
                <div class="help-text">
                    Do you need help adding a search? <a href="https://help.legitscript.com/s/">Learn More</a>
                </div>
                <div class="request-transaction">
                    <button class="button primary" (click)="showRequestNewTransaction = true" [disabled]="(requesting$ | async) > 0">
                        <span class="pi pi-file-edit"></span>
                        Request Test Transactions
                    </button>
                </div>
            </div>
        </ng-template>
        </ls-table>
    </div>
    <div *ngIf="loadingFilter" class="loading-filter">
        <loading-spinner></loading-spinner>
    </div>

    <ng-container *ngIf="showRequestNewTransaction">
        <request-new-test-transaction (closeModal)="showRequestNewTransaction = false" ></request-new-test-transaction>
    </ng-container>

    <ls-test-transaction-details
        [visible]="!!showDetails"
        [loading]="testTransactionDetailsLoading|async"
        [isDownloadLoading]="isDownloadLoading$|async"
        [isZipping]="isZipping$|async"
        [testTransaction]="testTransactionDetails | async"
        [images]="testTransactionScreenshots | async"
        [downloadAttachmentError]="downloadAttachmentError"
        (closeSidebar)="onCloseSidebar()"
        (copyLink)="onCopyLink()"
        (requestScreenshots)="onRequestScreenshots($event)"
        (downloadDocuments)="onDownloadFile($event)"
        (showImages)="onShowImages($event)"
    ></ls-test-transaction-details>

    
</div>

