import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterSet, LocalStorageService } from '@ls/common-ng-components';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SavedFiltersService {
  private mmApiHost = environment.CONFIG.mmApiHost;

  constructor(
    private http: HttpClient,
    private localStorageSvc: LocalStorageService,
  ) {}

  updateFilterSet(filterSet: FilterSet): Observable<FilterSet> {
    return this.http
      .patch<ServerFilterSet>(
        `${this.mmApiHost}/user-filters/${filterSet.id}`,
        this.toServerFilterSet(filterSet),
        this.localStorageSvc.getJWTAndSetOptions(),
      )
      .pipe(map((response) => this.toFilterSet(response)));
  }

  favoriteFilterSet(filterSet: FilterSet): Observable<FilterSet> {
    return this.http
      .patch<ServerFilterSet>(
        `${this.mmApiHost}/user-filters/${filterSet.id}/default`,
        this.toServerFilterSet(filterSet),
        this.localStorageSvc.getJWTAndSetOptions(),
      )
      .pipe(map((response) => this.toFilterSet(response)));
  }

  deleteFilterSet(filterSet: FilterSet): Observable<FilterSet> {
    return this.http
      .delete(`${this.mmApiHost}/user-filters/${filterSet.id}`, this.localStorageSvc.getJWTAndSetOptions())
      .pipe(map(() => filterSet));
  }

  createFilterSet(filterSet: FilterSet): Observable<FilterSet> {
    return this.http
      .post<ServerFilterSet>(
        `${this.mmApiHost}/user-filters/`,
        this.toServerFilterSet(filterSet),
        this.localStorageSvc.getJWTAndSetOptions(),
      )
      .pipe(map((response) => this.toFilterSet(response)));
  }

  getSavedFilterSets(): Observable<FilterSet[]> {
    return this.http
      .get<{ filters: ServerFilterSet[] }>(`${this.mmApiHost}/user-filters`, this.localStorageSvc.getJWTAndSetOptions())
      .pipe(map((response) => response.filters.map((filterSet) => this.toFilterSet(filterSet))));
  }

  private toFilterSet(serverFilterSet: ServerFilterSet): FilterSet {
    return {
      id: serverFilterSet.id,
      filterName: serverFilterSet.name,
      isFavorite: serverFilterSet.is_default,
      tableFilterNamesAndValues: serverFilterSet.filters,
    };
  }

  private toServerFilterSet(filterSet: FilterSet): ServerFilterSet {
    return {
      id: filterSet.id,
      name: filterSet.filterName,
      filters: filterSet.tableFilterNamesAndValues,
      is_default: filterSet.isFavorite,
    };
  }
}

interface ServerFilterSet {
  id: number;
  name: string;
  filters: { name: string; values?: unknown; exactMatch?: boolean }[];
  is_default: boolean;
}
