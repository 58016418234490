import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Pagination, UploadList } from '@ls/common-ts-models';

import { HistoryUploadListAction, HistoryUploadListSuccessAction, HistoryUploadListErrorAction } from '../reducers';
import { MMUploadService } from '../services';
import { MMAppState } from '../reducers/app.state';

@Injectable()
export class HistoryUploadListEffect {
  public list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HistoryUploadListAction),
      switchMap((action) => {
        return this.uploadService
          .listUploadHistory(
            action.sortDirection,
            action.pageUpdateOptions.pageNumber,
            action.pageUpdateOptions.pageSize,
          )
          .pipe(
            map((res: UploadList) => {
              const pagination: Pagination = {
                totalRecords: res.total,
                totalPages: res.totalPages,
                pageNumber: res.pageNumber,
                startPosition: (res.pageNumber - 1) * res.pageSize + 1,
                endPosition: Math.min(res.pageNumber * res.pageSize, res.total),
              };
              res.pageNumber = action.pageUpdateOptions.pageNumber;
              return HistoryUploadListSuccessAction({ historyUpload: res.result, pagination });
            }),
            catchError((err) => of(HistoryUploadListErrorAction({ err }))),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<MMAppState>,
    private uploadService: MMUploadService,
  ) {}
}
