import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';

@Injectable()
export class DisabledFeatureGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private store: Store<AppState>,
  ) {}

  public canActivate(): boolean {
    this.store.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.INFO,
        summary: 'Feature Disabled',
        detail: 'The feature you are attempting to access has been disabled',
        sticky: false,
        blocking: false,
      }),
    );
    this.router.navigate(['/account/home']);
    return false;
  }

  public canActivateChild(): boolean {
    return this.canActivate();
  }
}
