import XLSX from 'xlsx';

import { UPLOAD_VALIDATION_ENUMS } from '../../../../enums';
import { ValidationResult } from '../../SharedCustomTypes/validation-result';
import { UploadWorksheetValidator } from './upload-worksheet-validator';
import { WorkBook } from 'xlsx';

export class UploadWorkbookValidator {
  public static readonly ErrorMultipleTabs = new ValidationResult(
    false,
    UPLOAD_VALIDATION_ENUMS.fileUploadTabCountError.toString()
  );
  public static readonly ErrorNoTabs = new ValidationResult(false, UPLOAD_VALIDATION_ENUMS.fileUploadNoTabsError);
  public static validate(workbook: XLSX.WorkBook): ValidationResult {
    const validationResults = [];
    const isSingleTab = UploadWorkbookValidator.isSingleTab(workbook);
    validationResults.push(isSingleTab);

    if (isSingleTab.success) {
      const onlySheetInWorkBook = workbook.Sheets[workbook.SheetNames[0]];
      const isSheetValid = UploadWorksheetValidator.validate(onlySheetInWorkBook);
      validationResults.push(isSheetValid);
    }

    const summaryResult = ValidationResult.getPassOrFirstFailure(validationResults);
    return summaryResult;
  }

  public static isSingleTab(workBook: XLSX.WorkBook): ValidationResult {
    const isWorkBookWithSheets = UploadWorkbookValidator.isDefinedWorkBook(workBook);
    if (isWorkBookWithSheets) {
      if (workBook.SheetNames.length === 1) {
        return ValidationResult.Success;
      }
      return UploadWorkbookValidator.ErrorMultipleTabs;
    }

    return UploadWorkbookValidator.ErrorNoTabs;
  }

  private static isDefinedWorkBook(workBook: WorkBook): boolean {
    return workBook && workBook.SheetNames && workBook.SheetNames.length > 0;
  }
}
