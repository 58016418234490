import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BillingService,
  EntitlementsState,
  GenericNotificationAction,
  LocalStorageService,
  SeverityOptions,
} from '@ls/common-ng-components';
import { ProductFamily } from '@ls/common-ts-models';
import { of as observableOf, Observable } from 'rxjs';
import { catchError, take, map, skipWhile } from 'rxjs/operators';
import { AppState, fnEntitlementsState } from '../../../reducers';
import { BaseMMOpportunityGuard } from '../../../guards';

@Injectable()
export class MMNoOpportunityExistsGuard extends BaseMMOpportunityGuard implements CanActivate, CanActivateChild {
  constructor(
    router: Router,
    localStorage: LocalStorageService,
    store: Store<AppState>,
    billingService: BillingService,
  ) {
    super(router, localStorage, store, billingService);
  }

  public canActivate(): Observable<boolean> {
    if (!this.canActivateMerchantMonitoring()) {
      return observableOf(false);
    }
    return this.store.select(fnEntitlementsState).pipe(
      skipWhile((entitlements: EntitlementsState) => entitlements.pending),
      map((entitlements: EntitlementsState) => {
        if (entitlements.errorText) {
          throw new Error(entitlements.errorText);
        }

        const mmProduct = entitlements?.products?.[ProductFamily.MERCHANT_MONITORING];

        const canActivate = !mmProduct || !mmProduct.opportunity;

        if (!canActivate) {
          this.navigateToRouteBasedOnOpportunity(mmProduct.opportunity);
        }

        return canActivate;
      }),
      take(1),
      catchError(() => {
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Error Loading Data',
            detail:
              'An issue occured while loading your data, please try again.  If the issue persists, please contact support.',
            sticky: false,
            blocking: false,
          }),
        );
        this.router.navigate(['/account/home']);
        return observableOf(false);
      }),
    );
  }

  public canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
