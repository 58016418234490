export const environment = {
  BUILD_ENV: 'prod',
  MAINTENANCE_MODE: false,
  CONFIG: {
    portalApiHost: 'https://api.staging.legitscript.com/internalstaging/portal',
    certificationApiHost: 'https://api.staging.legitscript.com/internalstaging/certification/cp',
    paymentServiceHost: 'https://api.staging.legitscript.com/internalstaging/billing',
    mmApiHost: 'https://api.staging.legitscript.com/mmmylsstaging/myls',
    testTransactionHost: 'https://3e5lk4rjm6.execute-api.us-west-2.amazonaws.com/staging',
    recaptchaSiteKey: '6LeKIVYgAAAAACd2StEbe9JUWodYu6uB7aoyvQff',
    stripeKey: 'pk_test_uyl6oAQSBeRKuMQ1YpRq73N2',
    certificationEmail: 'lsclientportaltest+certification@gmail.com',
    expeditedForCBDandSupp: false,
    productsApiEnabled: false,
    merchantMonitoringEnabled: true,
    mmBulkEnabled: { enabled: true, validAccounts: [] },
    uploadHistoryEnabled: true,
    notificationsEnabled: { enabled: true, validAccounts: [] },
    mmv4ApiEnabled: true,
    auth0Domain: 'login.staging.legitscript.com', // SSM: /myls/shared/auth0/domain
    auth0ClientId: 'iV4C0MbmKQpcXW4mLWuImnYoMbsH9H1R', // SSM: /myls/shared/auth0/client-id
    auth0RedirectUri: 'https://my.staging.legitscript.com/#/login/auth0-return',
    auth0Audience: 'https://api.legitscript.com', // SSM /myls/shared/auth0/audience
    experienceCloudURL: 'https://legitscript--lsuat.sandbox.my.site.com/certification/s/',
  },
};
