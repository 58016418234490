<ng-container *ngIf="enabled">
  <h1>Expedited Processing Service</h1>
  <p>Generally, applications are processed in the order that they are received. Adding Expedited Processing Service to your certification application, however, will ensure your application is added to the expedited queue. Note that this service does not shorten the length of the certification review, but will result in your certification review beginning sooner.</p>
  <p>The Expedited Processing Service fee will be added to your application fee. Upon enrollment with Expedited Processing Service, the LegitScript Certification Team will reach out to you within two business days to assist you with the application process.</p>

  <table class="pricing-modal-table" *ngIf="((addonPricing$ | async)?.EXPEDITED_PROCESSING) as price; else loading">
    <tr>
      <th>Service</th>
      <th>Application Fee</th>
    </tr>
    <tr>
      <td>Expedited Processing</td>
      <td>+${{price}}</td>
    </tr>
  </table>
  <ng-template #loading>
    Loading prices...
  </ng-template>
</ng-container>