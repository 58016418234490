<!-- eslint-disable @angular-eslint/template/alt-text -->
<div id="sidebar">
  <div class="logo-container">
        <a [routerLink]=" ['/account/home'] ">
            <picture>
                <source srcset="../assets/img/legitscript-logo-icon-white.svg" media="(max-width: {{smallIconBreakpoint}})">
                <img class="logo-image" src="../assets/img/legitscript-logo-white.svg" />
            </picture>
        </a>
    </div>

    <div *ngIf="isNotCertificationUser; else showCertifications">

        <div class="nav-section">
            <div class="nav-header">ACCOUNT</div>
            <div class="nav-item"
                pTooltip="Dashboard" tooltipStyleClass="hide-tooltip"
                routerLink="/account/home"
                routerLinkActive="selected" [routerLinkActiveOptions]= "{exact: true}">
                <i class="fa fa-tachometer" aria-hidden="true"></i>
                <span>Dashboard</span>
            </div>
            <div *ngIf="isOwner" class="nav-item"
                pTooltip="Users" tooltipStyleClass="hide-tooltip"
                routerLink="/account/users"
                routerLinkActive="selected"
                [routerLinkActiveOptions]= "{exact: true}">
            <i class="fa fa-users" aria-hidden="true"></i>
            <span>Users</span>
            </div>
            <div class="nav-item"
                pTooltip="Billing" tooltipStyleClass="hide-tooltip"
                routerLink="/account/billing"
                routerLinkActive="selected"
                [routerLinkActiveOptions]= "{exact: true}">
                <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                <span>Billing</span>
            </div>
            <div class="nav-item"
                pTooltip="Settings" tooltipStyleClass="hide-tooltip"
                routerLink="/account/account-details"
                routerLinkActive="selected"
                [routerLinkActiveOptions]= "{exact: true}">
                <i class="fa fa-cogs" aria-hidden="true"></i>
                <span>Settings</span>
            </div>

        </div>
        <div class="nav-section">
            <div class="nav-header">SERVICES</div>
            <div class="nav-item" pTooltip="Data Lookup" tooltipStyleClass="hide-tooltip" routerLink="/services/lookups/data"
                routerLinkActive="selected">
                <i class="fa fa-search" aria-hidden="true"></i>
                <span>Data Lookup</span>
            </div>

            <div class="nav-item" *ngIf="productsApiEnabled" pTooltip="Products API" tooltipStyleClass="hide-tooltip" routerLink="/services/api/products"
                routerLinkActive="selected" [routerLinkActiveOptions]= "{exact: true}">
                <i class="fa fa-code" aria-hidden="true"></i>
                <span>Products API</span>
            </div>

            <div class="nav-item" *ngIf="hasPurchasedMonitoring" pTooltip="Monitoring" tooltipStyleClass="hide-tooltip" routerLink="/services/merchant-monitoring"
                routerLinkActive="selected" [routerLinkActiveOptions]= "{exact: true}">
                <i class="fa fa-desktop" aria-hidden="true"></i>
                <span>Monitoring</span>
            </div>
        </div>
    </div>
    <ng-template #showCertifications>
        <div class="nav-header">ACCOUNT</div>
        <div class="nav-item"
            pTooltip="Billing" tooltipStyleClass="hide-tooltip"
            routerLink="/account/billing"
            routerLinkActive="selected"
            [routerLinkActiveOptions]= "{exact: true}">
            <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
            <span>Billing</span>
        </div>
        <div class="nav-header">SERVICES</div>
        <div class="nav-item" pTooltip="Certifications" tooltipStyleClass="hide-tooltip" routerLink="/account/home"
             routerLinkActive="selected" [routerLinkActiveOptions]= "{exact: true}">
            <i class="fa fa-cubes" aria-hidden="true"></i>
            <span>Certifications</span>
        </div>
    </ng-template>
</div>
