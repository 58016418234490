import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  User,
  SalesforceAccountInfoResponse,
  CreateAccountRequest,
  CreateAccountResponse,
  ConfirmAccountResponse,
  PasswordResetRequest,
  ChangePasswordRequest,
  RequestPasswordResetRequest,
  BillingHistory,
} from '@ls/common-ts-models';
import { LocalStorageService } from '@ls/common-ng-components';
import { Certification, UserExistsRequest, UpdateAccountRequest } from '@ls/common-ts-models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface CreateAccountResult {
  user?: User;
  error?: string;
}

@Injectable()
export class AccountService {
  private readonly portalApiHost = environment.CONFIG.portalApiHost;
  private readonly certificationApiHost = environment.CONFIG.certificationApiHost;
  private readonly paymentServiceHost = environment.CONFIG.paymentServiceHost;
  private _userCreationModel: CreateAccountRequest = { address: {} } as CreateAccountRequest;
  constructor(
    private http: HttpClient,
    private localStorageSvc: LocalStorageService,
  ) {}

  public firstCreateFormComplete(): boolean {
    return this.userCreationModel && this.userCreationModel.email !== undefined;
  }

  public secondCreateFormComplete(): boolean {
    return this.userCreationModel && this.userCreationModel.companyName !== undefined;
  }

  public get portalCustomerEndpoint(): string {
    return `${this.portalApiHost}/v1/customer`;
  }

  public get selectableAccountEndpoint(): string {
    return `${this.portalApiHost}/v1/accounts`;
  }

  public get lsConfirmAccountUrl(): string {
    return `${this.portalApiHost}/v1/confirm`;
  }

  public get getBillingHistoryUrl(): string {
    return `${this.paymentServiceHost}/v1/getStripeBillingHistory`;
  }

  public set userCreationModel(model: CreateAccountRequest) {
    this._userCreationModel = Object.assign({}, this._userCreationModel || {}, model);
  }

  public get userCreationModel(): CreateAccountRequest {
    return this._userCreationModel;
  }

  public userExists(request: UserExistsRequest): Observable<boolean> {
    return this.http
      .post(this.portalCustomerEndpoint + '/exists', request, {
        headers: new HttpHeaders({
          'content-type': 'application/json',
        }),
      })
      .pipe(map((result) => result as boolean));
  }

  public createAccount(model: CreateAccountRequest): Observable<CreateAccountResult> {
    this.userCreationModel = model;

    // Make sure we are sending only meaningful properties to backend.
    if (this.userCreationModel.audienceCategoryOther) {
      delete this.userCreationModel.audienceCategoryOther;
    }

    const args = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
      }),
    };

    return this.http
      .post<CreateAccountResponse>(this.portalCustomerEndpoint, JSON.stringify(this.userCreationModel), args)
      .pipe(
        map((creds: CreateAccountResponse) => {
          const result: CreateAccountResult = {
            user: {
              lsUserId: creds.lsUserId,
              lsAccountId: creds.lsAccountId,
              name: this.userCreationModel.firstName + ' ' + this.userCreationModel.lastName,
              sfContactId: creds.sfContactId,
              sfAccountId: creds.sfAccountId,
              email: this.userCreationModel.email,
            },
          };
          this._userCreationModel = { address: {} } as CreateAccountRequest;
          return result;
        }),
      );
  }

  public confirmAccount(confirmationToken: string): Observable<ConfirmAccountResponse> {
    return this.http
      .post(this.lsConfirmAccountUrl, { token: confirmationToken })
      .pipe(map((res: any) => ({ name: res.user.name, success: true }) as ConfirmAccountResponse));
  }

  public getAccountInfoByLegitScriptAccountId(): Observable<SalesforceAccountInfoResponse> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(this.portalCustomerEndpoint, options).pipe(
      tap((res: any) => {
        this.localStorageSvc.setAccount(res.account);
        return res;
      }),
    );
  }

  public requestPasswordReset(request: RequestPasswordResetRequest): Observable<any> {
    return this.http.post(this.portalCustomerEndpoint + '/password/request', request);
  }

  public resetPassword(request: PasswordResetRequest): Observable<any> {
    return this.http.post(this.portalCustomerEndpoint + '/password/reset', request);
  }

  public changePassword(request: ChangePasswordRequest): Observable<any> {
    return this.http.post(this.portalCustomerEndpoint + '/password', request, {
      headers: new HttpHeaders({ Authorization: this.localStorageSvc.getToken() }),
    });
  }

  public getCertificationsForAccount() {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(`${this.certificationApiHost}/v1/account/certifications`, options) as Observable<
      Certification[]
    >;
  }

  public updateContact(user: User) {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(`${this.portalCustomerEndpoint}/update`, user, options);
  }

  public updateAccount(updateRequest: UpdateAccountRequest) {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(`${this.portalApiHost}/v1/account/update`, updateRequest, options);
  }

  public listHelpFiles() {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<[{ name: string; url: string }]>(`${this.portalApiHost}/v1/list_help_files`, options);
  }

  public refreshApiKey(productId: string) {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<{ value: string }>(
      `${this.portalApiHost}/v1/account/api-key/refresh`,
      { productId },
      options,
    );
  }

  public getBillingHistory(lastRetrieved?: string): Observable<BillingHistory[]> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };
    let historyUrl = this.getBillingHistoryUrl;
    if (lastRetrieved) {
      historyUrl += `?lastRetrieved=${lastRetrieved}`;
    }

    return this.http.get<BillingHistory[]>(historyUrl, options);
  }

  public parseTokenFromUrl(url: string, tokenName: string): string {
    const tokenArr = url.split(`?${tokenName}=`); // https://legitscript.atlassian.net/browse/LS-15654
    return tokenArr[tokenArr.length - 1];
  }

  public getAccountsForUser(): Observable<Array<{ id: number; name: string }>> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.localStorageSvc.getToken(),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<Array<{ id: number; name: string }>>(this.selectableAccountEndpoint, options);
  }
}
