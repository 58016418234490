import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import {
  AUDIENCE_CATEGORIES,
  AUDIENCE_CATEGORIES_OTHER,
  CreateAccountRequest,
  PRODUCTS_OF_INSTEREST,
} from '@ls/common-ts-models';

import { NgForm } from '@angular/forms';
import { AccountService } from '../../../services';
import countryCodes from '../../../../assets/countries.json';

@Component({
  selector: 'create-account-info',
  templateUrl: './create-account-info.component.html',
  styleUrls: ['../create-account.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateAccountInfoComponent implements OnInit {
  public model: CreateAccountRequest;
  public countries = countryCodes;
  public audienceCategories: object = AUDIENCE_CATEGORIES;
  public audienceCategoriesOther: object = AUDIENCE_CATEGORIES_OTHER;
  public productsOfInterest: object = PRODUCTS_OF_INSTEREST;
  private cachedOtherAudienceCategory: string;

  @ViewChild('form') private form: NgForm;

  constructor(
    private accountService: AccountService,
    private router: Router,
  ) {}

  public ngOnInit() {
    if (!this.accountService.firstCreateFormComplete()) {
      this.router.navigate(['create-account']);
    }
    this.model = this.accountService.userCreationModel;
  }

  public onOtherAudienceCategorySelectionChange(e: string): void {
    if (e) {
      this.cachedOtherAudienceCategory = e;
    }
  }

  public onAudienceCategorySelectionChange(): void {
    this.cachedOtherAudienceCategory = null;
  }

  public onProductOfInterestSelectionChange(event): void {
    this.model.productOfInterest = event;
  }

  private handleOtherAudienceCategoryFormValue(): void {
    if (this.cachedOtherAudienceCategory) {
      this.model.audienceCategory = this.cachedOtherAudienceCategory;
    }
  }

  public back() {
    this.router.navigate(['create-account']);
  }

  public submit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((controlKey) => {
        this.form.form.controls[controlKey].markAsTouched();
      });
      this.form.form.updateValueAndValidity();
      return;
    }
    this.handleOtherAudienceCategoryFormValue();
    this.accountService.userCreationModel = this.model;
    this.router.navigate(['create-account', 'password']);
  }
}
