import { createAction, createReducer, on, props } from '@ngrx/store';
import { CreateTransactionRequest, CreateTransaction201Response } from '../angular-client';

export const TestTransactionsRequestNewAction = createAction(
  '[Test Transactions] Get Request New',
  props<{ transaction: CreateTransactionRequest; files: File[] }>(),
);
export const TestTransactionsRequestNewSuccessAction = createAction(
  '[Test Transactions] Get Request New Success',
  props<CreateTransaction201Response>(),
);
export const TestTransactionsRequestNewErrorAction = createAction(
  '[Test Transactions] Get Request New Error',
  props<{ errorText: string; errorType: number }>(),
);

export interface TestTranasctionsRequestNewState {
  response?: CreateTransaction201Response;
  pending: boolean;
  error?: {
    errorText;
    errorType;
  };
  requesting: number;
}

export const TestTransactionsRequestNewReducer = createReducer(
  { pending: false, requesting: 0 } as TestTranasctionsRequestNewState,

  on(TestTransactionsRequestNewAction, (state) => ({
    ...state,
    pending: true,
    requesting: state.requesting + 1,
  })),

  on(TestTransactionsRequestNewSuccessAction, (state, props) => ({
    ...state,
    pending: false,
    response: props,
    requesting: state.requesting - 1,
  })),

  on(TestTransactionsRequestNewErrorAction, (state, props) => ({
    ...state,
    pending: false,
    error: props,
    requesting: state.requesting - 1,
  })),
);
