import { Routes } from '@angular/router';
import {
  Auth0RedirectGuard,
  AuthenticationGuard,
  EnsurePricingInfoGuard,
  OwnersOnlyGuard,
  EnforceTermsGuard,
  EntitlementsGuard,
  EnrollGuard,
  DisabledFeatureGuard,
  MaintenanceModeGuard,
  MaintenanceModeEnabledGuard,
  CanDeactivateGuard,
  MMDocsGuard,
  SignOutGuard,
  testTransactionGuard,
} from './guards';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login';
import { ForgotPasswordComponent } from './pages/forgot-password';
import { ResetPasswordComponent } from './pages/reset-password';
import {
  VerifyAccountComponent,
  AccountCreatedComponent,
  CreateAccountBeginComponent,
  NotFoundComponent,
  CreateAccountInfoComponent,
  CreateAccountPasswordComponent,
  UpdateInfoComponent,
  CreatePasswordComponent,
  HelpComponent,
  MaintenanceModeComponent,
  V3DocsComponent,
  V4DocsComponent,
  AccessDeniedComponent,
} from './pages';
import { AccountDetailsWrapperComponent } from './pages/account-details-wrapper/account-details-wrapper.component';
import { PaymentPlanComponent } from './pages/payment-plan/payment-plan.component';
import { PaymentPlanInvoiceComponent } from './pages/payment-plan-invoice/payment-plan-invoice.component';
import { BillingComponent } from './pages/billing/billing.component';
import { UsersComponent } from './pages/users/users.component';

import {
  CertificationCompleteComponent,
  CertificationSubmittedComponent,
  CertificationPricingUpdateComponent,
  MerchantCertificationComponent,
  CertificationTypeSelectComponent,
  CertificationQuestionnaireWrapperComponent,
} from './modules/certifications';
import {
  ProductLookupsDetailComponent,
  ProductLookupsListComponent,
  WebsiteLookupsSearchComponent,
  WebsiteLookupsDetailComponent,
  ProductsApiEnrollComponent,
  ProductsApiDocsComponent,
  DataLookupSelectComponent,
  DataLookupWrapperComponent,
  ProductLookupsSelectedComponent,
} from './modules/lookups';
import {
  MMDashboardComponent,
  MMEnrollComponent,
  MMEnrolledGuard,
  MMNoOpportunityExistsGuard,
  MMUploadComponent,
  MMUploadHistoryComponent,
} from './modules';
import { LoadingComponent } from './pages/loading/loading.component';
import { ProductFamily } from '@ls/common-ts-models';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { MMMerchantListComponent } from './modules/merchant-monitoring/list/merchant-list.component';
import { MMMerchantDetailComponent } from './modules/merchant-monitoring/detail/merchant-detail.component';
import { DocsComponent } from './pages/docs/docs.component';
import { environment } from 'src/environments/environment';
import { TestTransactionsComponent } from './modules/test-transactions/pages/test-transactions/test-transactions.component';
import { LayoutComponent } from './modules/merchant-monitoring-v2/pages/layout/layout.component';
export const ROUTES: Routes = [
  { path: '', redirectTo: '/loading', pathMatch: 'full' },
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [environment.MAINTENANCE_MODE ? MaintenanceModeGuard : AuthenticationGuard],
    children: [
      {
        path: 'account',
        data: { breadcrumb: 'Account' },
        canActivateChild: [EnforceTermsGuard],
        children: [
          {
            path: 'account-details',
            component: AccountDetailsWrapperComponent,
            data: { breadcrumb: 'Settings' },
          },
          {
            path: 'billing',
            component: BillingComponent,
            data: { breadcrumb: 'Billing' },
          },
          { path: 'home', component: HomeComponent, data: { breadcrumb: 'Home' } },
          {
            path: 'users',
            component: UsersComponent,
            canActivate: [OwnersOnlyGuard],
            data: { breadcrumb: 'Users' },
          },
          {
            path: 'docs',
            component: DocsComponent,
            data: { breadcrumb: 'Docs' },
            canActivate: [MMDocsGuard],
          },
          {
            path: 'v3-docs',
            component: V3DocsComponent,
            data: { breadcrumb: 'V3 Docs' },
            canActivate: [MMDocsGuard],
          },
          {
            path: 'v4-docs',
            component: V4DocsComponent,
            data: { breadcrumb: 'V4 Docs' },
            canActivate: [...(!environment.CONFIG.mmv4ApiEnabled ? [DisabledFeatureGuard] : []), MMDocsGuard],
          },
        ],
      },
      { path: 'loading', component: LoadingComponent },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [EnforceTermsGuard],
        data: { breadcrumb: 'Help' },
      },
      {
        path: 'payment',
        component: PaymentPlanComponent,
        canActivate: [EnforceTermsGuard],
        data: { breadcrumb: 'Payment' },
      },
      {
        path: 'services',
        data: { breadcrumb: 'Services' },
        canActivateChild: [EnforceTermsGuard],
        children: [
          {
            path: 'lookups',
            data: { breadcrumb: 'Interactive Lookups' },
            children: [
              {
                path: 'data',
                component: DataLookupWrapperComponent,
                canActivate: [EnrollGuard],
                data: {
                  breadcrumb: 'Data Lookup',
                  productFamily: ProductFamily.PRODUCT_LOOKUP,
                  altPath: '/services/lookups/data/select',
                },
              },
              {
                path: 'data/select',
                component: DataLookupSelectComponent,
                canActivate: [EntitlementsGuard],
                data: {
                  breadcrumb: '',
                  productFamily: ProductFamily.PRODUCT_LOOKUP,
                  altPath: '/services/lookups/data',
                },
                children: [
                  {
                    path: 'product',
                    component: ProductLookupsSelectedComponent,
                    canActivate: [EntitlementsGuard],
                    data: {
                      breadcrumb: 'Product Search',
                      productFamily: ProductFamily.PRODUCT_LOOKUP,
                      altPath: '/services/lookups/data',
                    },
                    children: [
                      {
                        path: 'list',
                        component: ProductLookupsListComponent,
                        canActivate: [EntitlementsGuard],
                        data: {
                          breadcrumb: 'List',
                          productFamily: ProductFamily.PRODUCT_LOOKUP,
                          altPath: '/services/lookups/data',
                        },
                      },
                      {
                        path: 'info/:productId',
                        component: ProductLookupsDetailComponent,
                        canActivate: [EntitlementsGuard],
                        data: {
                          breadcrumb: 'Details',
                          productFamily: ProductFamily.PRODUCT_LOOKUP,
                          altPath: '/services/lookups/data',
                        },
                      },
                    ],
                  },
                  {
                    path: 'website',
                    component: WebsiteLookupsSearchComponent,
                    canActivate: [EntitlementsGuard],
                    data: {
                      breadcrumb: 'Website Search',
                      productFamily: ProductFamily.WEBSITE_LOOKUP,
                      altPath: '/services/lookups/data',
                    },
                    children: [
                      {
                        path: 'info',
                        component: WebsiteLookupsDetailComponent,
                        canActivate: [EntitlementsGuard],
                        data: {
                          breadcrumb: 'Details',
                          productFamily: ProductFamily.WEBSITE_LOOKUP,
                          altPath: '/services/lookups/data',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'api',
            data: { breadcrumb: 'APIs' },
            children: [
              {
                path: 'products',
                pathMatch: 'full',
                component: ProductsApiEnrollComponent,
                data: {
                  breadcrumb: 'Healthcare Products Database',
                  productFamily: ProductFamily.PRODUCT_API,
                  altPath: '/services/api/products/docs',
                },
                canActivate: [...(!environment.CONFIG.productsApiEnabled ? [DisabledFeatureGuard] : []), EnrollGuard],
              },
              {
                path: 'products/docs',
                component: ProductsApiDocsComponent,
                data: {
                  breadcrumb: 'Healthcare Products Database',
                  productFamily: ProductFamily.PRODUCT_API,
                  altPath: '/services/api/products',
                },
                canActivate: [
                  ...(!environment.CONFIG.productsApiEnabled ? [DisabledFeatureGuard] : []),
                  EntitlementsGuard,
                ],
              },
            ],
          },
          {
            path: 'select-certification-type',
            data: { breadcrumb: 'Certifications (Select)' },
            component: CertificationTypeSelectComponent,
          },
          {
            path: 'merchant-certification',
            data: { breadcrumb: 'Certifications' },
            children: [
              {
                path: '',
                component: MerchantCertificationComponent,
              },
              {
                path: 'payment',
                component: PaymentPlanComponent,
                data: { breadcrumb: 'Payment' },
                canActivate: [EnsurePricingInfoGuard],
                canDeactivate: [CanDeactivateGuard],
              },
              {
                path: 'pricing-update/:certificationId',
                component: CertificationPricingUpdateComponent,
                data: { breadcrumb: 'Pricing Update' },
                canActivate: [OwnersOnlyGuard],
              },
              {
                path: 'payment-invoice',
                component: PaymentPlanInvoiceComponent,
                data: { breadcrumb: 'Payment - Invoice' },
                canActivate: [EnsurePricingInfoGuard],
                canDeactivate: [CanDeactivateGuard],
              },
            ],
          },
          {
            path: 'merchant-monitoring',
            data: {
              breadcrumb: 'Merchant Monitoring',
              productFamily: ProductFamily.MERCHANT_MONITORING,
            },
            children: [
              {
                path: '',
                redirectTo: '/merchant-monitoring/merchant-list',
                pathMatch: 'full',
              },
              {
                path: 'detail/:correlationId',
                redirectTo: '/merchant-monitoring/merchant-list',
              },
              {
                path: 'enroll',
                component: MMEnrollComponent,
                canActivate: [MMNoOpportunityExistsGuard],
                data: { breadcrumb: 'Enroll' },
              },
              {
                path: 'list',
                redirectTo: '/merchant-monitoring/merchant-list',
              },
              {
                path: 'upload',
                redirectTo: '/merchant-monitoring/merchant-list',
              },
              {
                path: 'upload-history',
                redirectTo: '/merchant-monitoring/merchant-list',
              },
            ],
          },
          {
            path: 'payment',
            component: PaymentPlanComponent,
            data: { breadcrumb: 'Payment' },
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: 'test-transactions',
            component: TestTransactionsComponent,
            canActivate: [MaintenanceModeGuard, testTransactionGuard],
          },
        ],
      },
      {
        path: 'questionnaire/:certificationId/question/:questionId',
        component: CertificationQuestionnaireWrapperComponent,
        canActivate: [EnforceTermsGuard],
        data: { breadcrumb: 'Question' },
      },
      {
        path: 'questionnaire-complete/:certificationId',
        component: CertificationCompleteComponent,
        canActivate: [EnforceTermsGuard],
        data: { breadcrumb: 'Questionnaire Complete' },
      },
      {
        path: 'questionnaire-submitted/:certificationId',
        component: CertificationSubmittedComponent,
        canActivate: [EnforceTermsGuard],
        data: { breadcrumb: 'Questionnaire Successfully Submitted' },
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [MaintenanceModeGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LoginComponent,
        canActivate: [Auth0RedirectGuard],
      },
      {
        path: 'auth0-return',
        component: LoadingComponent,
        canActivate: [Auth0RedirectGuard],
      },
    ],
  },
  {
    path: 'create-account',
    canActivate: [MaintenanceModeGuard],
    canActivateChild: [MaintenanceModeGuard],
    children: [
      {
        path: '',
        canActivate: [SignOutGuard],
        component: CreateAccountBeginComponent,
      },
      {
        path: 'info',
        component: CreateAccountInfoComponent,
      },
      {
        path: 'password',
        component: CreateAccountPasswordComponent,
      },
    ],
  },
  {
    path: 'invited-user-login',
    canActivate: [MaintenanceModeGuard],
    canActivateChild: [MaintenanceModeGuard],
    children: [
      {
        path: '',
        component: CreatePasswordComponent,
      },
      {
        path: 'update-info',
        component: UpdateInfoComponent,
        canActivate: [AuthenticationGuard],
      },
    ],
  },
  {
    path: 'verify-account',
    component: VerifyAccountComponent,
    canActivate: [MaintenanceModeGuard],
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    canActivate: [MaintenanceModeGuard],
  },
  {
    path: 'account-created',
    component: AccountCreatedComponent,
    canActivate: [MaintenanceModeGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [MaintenanceModeGuard],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [MaintenanceModeGuard],
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    canActivate: [MaintenanceModeGuard],
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'maintenance-in-progress',
    component: MaintenanceModeComponent,
    pathMatch: 'full',
    canActivate: [MaintenanceModeEnabledGuard],
  },
  {
    path: 'merchant-monitoring',
    canActivate: [AuthenticationGuard, MMEnrolledGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./modules/merchant-monitoring-v2/merchant-monitoring.module').then((m) => m.MerchantMonitoringModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
  },
];
