import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { LocalStorageService } from '@ls/common-ng-components';
import { Roles } from '@ls/common-ts-models';

export const testTransactionGuard: CanActivateFn = (_route, _state) => {
  const localStorageService = inject(LocalStorageService);
  const authUser = localStorageService.getAuthenticatedUser();
  return (
    (authUser.roles ?? []).includes(Roles.testTransactionAnalyst) ||
    (authUser.roles ?? []).includes(Roles.testTransactionUser)
  );
};
