import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { HistoryUploadSetVisibleAction } from 'src/app/modules/merchant-monitoring/reducers';

@Injectable({
  providedIn: 'root',
})
export class UploadHistoryGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.store.dispatch(HistoryUploadSetVisibleAction({ visible: true }));
    return true;
  }
}
