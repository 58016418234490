<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<loading-spinner *ngIf="processing" loadingText="Processing Your Request"></loading-spinner>
<div class="widget" *ngIf="!processing">
  <div class="widget-header">
    <div>
      <i class="fa fa-users"></i><h1>Users</h1>
    </div>
    <a *ngIf="manageUsers" (click)="showInviteUser()" style="font-size:2em; float: right;">+</a>
  </div>
  <div class="widget-body centered">
    <loading-spinner *ngIf="(!users && !error) || loading" loadingText="Loading Users"></loading-spinner>
    <div id="user-table-widget">
      <p-table
      *ngIf="users && !loading"
      class="user-list"
      styleClass="p-datatable-striped"
      [responsive]="true"
      responsiveLayout="stack"
      [value]="users"
      dataKey="email"
      (selectionChange)="log($event)"
      selectionMode="single">
      <ng-template pTemplate="header" >
        <tr>
          <th>NAME</th>
          <th>EMAIL</th>
          <th>ROLE</th>
          <th>PHONE</th>
          <th>STATUS</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user let-rowData>
        <tr class="user-table" #userrow>
          <td class="user-table-column">
            <span class="p-column-title">Name</span>
            {{user.firstName}} {{user.lastName}}
          </td>
          <td class="user-table-column">
            <span class="p-column-title">Email</span>
            <span>{{user.email}}</span>
          </td>
          <td class="user-table-column">
            <span class="p-column-title">Role</span>
            <span>
              {{user.role}}
              <i class="fa fa-check-circle" *ngIf="user.role === 'Account Owner'"></i>
            </span>
          </td>
          <td class="user-table-column">
            <span class="p-column-title">Phone</span>
            <span>{{user.phone}}</span>
          </td>
          <td #active class="user-table-column">
            <!-- Will likely need to update when additional statuses are available!-->
            <span class="p-column-title">Status</span>
            <span>
              {{user.status}}
              <i *ngIf="manageUsers" #ellipsis class="fa fa-ellipsis-h" style="float: right" aria-hidden="true" (click)="menu.toggle($event);getItems(user)"></i>
            </span>
            <p-menu #menu [popup]=true [model]="items" appendTo="body"></p-menu>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  </div>
  <edit-user [model]="model" (submitted)="submit()"></edit-user>
  <update-email></update-email>
</div>

<legitscript-modal class="no-footer" #confirmNewOwnerModal [draggable]=false [resizable]=false [responsive]=true [closable]="closable"
[modal]=true autoAlign=true [width]=800 height=auto>
  <div class="dialog-body" style="overflow:auto; text-align: left;" *ngIf="newOwner">
    <h1>Are you sure you want to make {{newOwner.firstName}} {{newOwner.lastName}} the new account owner?</h1>
    <p>
      They will gain account owner permissions and your account will be changed to have normal user permissions.
    </p>
    <p>
      An email notification will be sent to {{newOwner.email}} regarding this change.
    </p>
    <p style="font-weight:400;">
      This action cannot be undone.
    </p>
    <p>
      <button type="button" (click)="changeAccountOwner()">Confirm</button>
      <button type="button" class="btn-no-bg" (click)="closeNewOwnerModal()">Cancel</button>
    </p>
  </div>
</legitscript-modal>
