import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  AfterViewChecked,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AnsweredQuestion,
  Question,
  QuestionInput,
  INPUT_TYPE,
  US_STATES_AND_TERRITORIES,
} from '@ls/common-ts-models/';
import { Store } from '@ngrx/store';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { CertificationService } from '../services/';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { UrlRegEx } from '@ls/common-ng-components';
import {
  AppState,
  CertificationsState,
  QuestionnaireState,
  fnCertificationsState,
  fnQuestionnaireState,
} from 'src/app/reducers';

@Component({
  selector: 'certifiable-item',
  templateUrl: 'certifiable-item.component.html',
  styleUrls: ['./certifiable-item.component.scss'],
})
export class CertifiableItemQuestionComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  public certItemForm: FormGroup = new FormGroup({});
  public isValidAfterUpdate: boolean | undefined = false;
  public newItemSubmit = false;
  public filesToUpload: { [id: string]: any[] } = {};
  public addItemClicked: boolean;
  public clearFiles = false;
  public entryToReview: { title: string; entries: any[] } = { title: '', entries: [] };
  public currentAnswers;
  public certItemsAppliedFor: number;
  public certItemsSubmitted = 0;
  public updatingItems = false;
  public multipleItemSubmissionEnabled = true;
  public enrollUrl = '';
  public domainQuestion: QuestionInput;
  public multiInputOtherQuestions: any[] = [];
  public showTextArea = {};
  public otherInputs = {};
  public destroyed$: Subject<boolean> = new Subject();
  @Input() public question: Question;
  @Input() public answer: AnsweredQuestion;
  @Input() public serverError: string | null;
  @Input() public pending: boolean;
  @Input() public outerForm: FormGroup;
  @Input() public saveClicked: boolean;
  @Input() public fileSelected;
  @Input() public tileTitleId = '';
  @Output() public handleChanges = new EventEmitter<object>();
  @Output() public filesAdded = new EventEmitter<object>();
  @ViewChildren('input') public inputList: any;

  constructor(
    private cdr: ChangeDetectorRef,
    public store: Store<AppState>,
    public sanitizer: DomSanitizer,
    private certificationService: CertificationService,
    public route: ActivatedRoute,
  ) {}

  public ngOnInit() {
    const { certificationId } = this.route.snapshot.params;
    this.outerForm.removeControl('parentForm');
    this.certItemForm.setParent(this.outerForm);
    this.outerForm.addControl('parentForm', this.certItemForm);

    combineLatest([this.store.select(fnQuestionnaireState), this.store.select(fnCertificationsState)])
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe(([quesionnaireState, certificationState]: [QuestionnaireState, CertificationsState]) => {
        this.currentAnswers = quesionnaireState.answers;
        const currentCert = certificationState.list.find((c) => c.id === certificationId);
        if (currentCert) {
          this.certItemsAppliedFor = currentCert.enrolledItemCount;

          // url submitted in the enroll questionnaire should be primaryUrl, but if something went wrong with private site communication, we may have to pull it out of the CertifiableItem created on enroll instead
          this.enrollUrl = currentCert.primaryUrl;
          if (!currentCert.primaryUrl && currentCert.certifiableItems && currentCert.certifiableItems.length) {
            const enrollCertItem = currentCert.certifiableItems.find((ci: any) => ci.created_by === 'enrollment');
            if (enrollCertItem && enrollCertItem.data) {
              this.enrollUrl = enrollCertItem.data.domain;
            }
          }
        } else {
          this.certItemsAppliedFor = 0;
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.pending) {
      return;
    }

    const locationQuestions = this.question.inputs.filter((q) => q.input_type === INPUT_TYPE.MULTI_SELECT_LOCATION);
    if (locationQuestions) {
      locationQuestions.forEach((lq) => {
        lq.input_list_items = US_STATES_AND_TERRITORIES;
      });
    }

    if (!this.answer && this.question) {
      this.answer = this.createNewAnswerObj();
    }

    const multiRowQuestions = this.question.inputs.filter((q) => q.input_type === INPUT_TYPE.MULTI_ROW);
    multiRowQuestions.forEach((q) => {
      if (!q['answer']) {
        q['answer'] = this.createNewAnswerObj(q.id, 'multi_row');
      }
    });

    // only set up the form if a new question is passed
    if (changes['question']) {
      this.setUpForm();
    }

    // notify wrapper of validity on load
    this.hasItems(this.answer)
      ? this.handleQuestionChanges(this.answer, true)
      : this.handleQuestionChanges(this.answer, false);

    if (
      this.hasItems(this.answer) &&
      changes['saveClicked'] &&
      changes['saveClicked'].currentValue &&
      this.certItemsDoesNotEqualEnrollNumber()
    ) {
      // TODO: this should probably be a confirm dialog instead of a toast: confirm that this situation is ok with the user before proceeding to the next question
      const totalSubmitted = this.totalAnsweredItems(this.answer);
      this.store.dispatch(
        GenericNotificationAction({
          severity: SeverityOptions.WARN,
          summary: 'Certifiable Item Difference',
          detail: `You submitted ${totalSubmitted} item${totalSubmitted > 1 ? 's' : ''}, but enrolled to certify ${
            this.certItemsAppliedFor
          } item${
            this.certItemsAppliedFor > 1 ? 's' : ''
          }.  You may edit this question before submission, but if the difference remains, a pricing change may be applied upon review.`,
          sticky: true,
          blocking: false,
        }),
      );
    }
  }

  public ngAfterViewChecked(): void {
    if (this.multiInputOtherQuestions.length) {
      this.multiInputOtherQuestions.forEach((q) => {
        const answerArrayForMultiOther: string[] = this.certItemForm.value[q.id][q.id];
        if (answerArrayForMultiOther) {
          const otherControl = this.certItemForm.get(`${q.id}:other`);
          this.showTextArea[q.id] = answerArrayForMultiOther.find((a) => /other/gi.test(a));
          this.showTextArea[q.id] ? otherControl.enable() : otherControl.disable();
        } else {
          this.showTextArea[q.id] = false;
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.handleQuestionChanges(this.answer, true);

    const multiRowQuestions = this.question.inputs.filter((q) => q.input_type === INPUT_TYPE.MULTI_ROW);
    if (multiRowQuestions.length) {
      multiRowQuestions.forEach((q) => delete q['answer']);
    }
  }

  public handleQuestionChanges(answer: any, valid?: boolean, resetSaveClicked?: boolean) {
    if (answer.answer && answer.answer.input_type === INPUT_TYPE.MULTI_ROW && this.hasItems(answer.answer)) {
      const multiRowUpdate = Object.keys(this.certItemForm.controls).find((k) => k === answer.answer.question_id);
      if (multiRowUpdate) {
        this.certItemForm.controls[multiRowUpdate].setValue({
          [multiRowUpdate]: answer.answer.answered_inputs[0].answer_rows,
        });
      }
    }
    this.handleChanges.emit({ answer: this.answer, valid, resetSaveClicked });
  }

  public addNewCertifiableItem(): void {
    this.newItemSubmit = true;
    this.addItemClicked = true;

    if (!this.certItemForm.valid) {
      this.store.dispatch(
        GenericNotificationAction({
          severity: SeverityOptions.WARN,
          summary: 'Form Incomplete',
          detail: 'Please ensure the fields outlined in red have values and try adding the item again',
          sticky: false,
          blocking: false,
        }),
      );
      return;
    }

    this.updatingItems = true;
    this.addDataToAnswerRow();

    this.certificationService
      .saveCertItem(this.currentAnswers, this.answer)
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe(
        (response) => {
          if (!response || !response.answers) {
            this.updatingItems = false;
            this.store.dispatch(
              GenericNotificationAction({
                severity: SeverityOptions.ERROR,
                summary: 'Error Saving Item',
                detail: 'Please try again - if the problem persists, please use the Help button at top right',
                sticky: false,
                blocking: false,
              }),
            );
            return;
          }

          this.answer = response.answers.answeredQuestions.find((aq) => aq.question_id === this.answer.question_id);
          this.certItemForm.reset();
          this.newItemSubmit = false;
          this.handleQuestionChanges(this.answer, true, true);
          this.filesToUpload = {};
          this.clearFiles = true;
          this.cdr.detectChanges();

          this.question.inputs
            .filter((q) => q.input_type === INPUT_TYPE.MULTI_ROW)
            .forEach((q) => {
              q['answer'] = this.createNewAnswerObj(q.id, 'multi_row');
            });

          this.updatingItems = false;

          // when user adds new item, focus on box one again (if available)
          if (this.inputList && this.inputList.first && this.inputList.first.nativeElement) {
            this.inputList.first.nativeElement.querySelector('input').focus();
          }

          if (response.uploadError) {
            this.store.dispatch(
              GenericNotificationAction({
                severity: SeverityOptions.WARN,
                summary: 'File(s) Not Uploaded',
                detail:
                  'Sorry--it looks like an error occurred while uploading one or more of your files related to this item.  The rest of your info was successfully saved.',
                sticky: false,
                blocking: false,
              }),
            );
          } else {
            this.store.dispatch(
              GenericNotificationAction({
                severity: SeverityOptions.SUCCESS,
                summary: 'Item Successfully Saved',
                detail:
                  'Click the Save & Next button at bottom right when you are ready to move on to the next question',
                sticky: false,
                blocking: false,
              }),
            );
          }

          this.clearFiles = false;
          this.cdr.detectChanges();
        },
        () => {
          this.updatingItems = false;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Error Saving Item',
              detail: 'Please try again - if the problem persists, please use the Help button at top right',
              sticky: false,
              blocking: false,
            }),
          );
        },
      );
  }

  public addDataToAnswerRow(): void {
    const answerRowToAdd: any = {
      row_input_answers: [],
    };
    let answerObjToAdd: any = {};

    if (!this.answer.answered_inputs || this.answer.answered_inputs.length < 1) {
      this.answer.answered_inputs = [
        {
          id: crypto.randomUUID(),
          input_type: this.question.question_type,
          answer_rows: [],
        },
      ];
    }

    if (!this.answer.answered_inputs[0].answer_rows) {
      this.answer.answered_inputs[0].answer_rows = [];
    }

    // take each element from the form and create an object in the correct format to insert into row_input_answers
    Object.keys(this.certItemForm.value)
      .filter((key: string) => key !== 'parentForm')
      .forEach((key: string) => {
        let inputType = '';
        let multiInputOtherResponse;
        const thisQuestionInputObj: any = this.question.inputs.find((input: any) => input.id === key);
        if (thisQuestionInputObj && !key.includes('other')) {
          inputType = thisQuestionInputObj.input_type;
          answerObjToAdd = {
            id: crypto.randomUUID(),
            input_id: key,
            input_type: inputType,
          };
        } else {
          multiInputOtherResponse = this.certItemForm.value[key][key];
        }

        if (inputType === INPUT_TYPE.MULTI_ROW) {
          if (this.certItemForm.value[key]) {
            answerObjToAdd.answer_rows = this.certItemForm.value[key][key];
          } else {
            // if a multi row question is NOT required and no value is entered, provide default row
            answerObjToAdd.answer_rows = [
              {
                row_input_answers: [
                  ...thisQuestionInputObj.inputs.map(({ id, input_type }) => {
                    return {
                      id: crypto.randomUUID(),
                      input_id: id,
                      input_type,
                      answer_text: 'N/A',
                    };
                  }),
                ],
              },
            ];
          }
        } else {
          if (inputType === INPUT_TYPE.FILE_UPLOAD) {
            answerObjToAdd.files = this.filesToUpload[thisQuestionInputObj.id]
              ? [...this.filesToUpload[thisQuestionInputObj.id]]
              : [];
          }

          if (key.includes('other')) {
            const originalKey = key.split(':')[0];
            const otherParentAnswer = answerRowToAdd.row_input_answers.find((a) => a.input_id === originalKey);
            if (otherParentAnswer) {
              const otherAnswerIndex = otherParentAnswer.answer_text.findIndex(
                (textAnswer) => textAnswer.toLowerCase() === 'other',
              );
              otherParentAnswer.answer_text[
                otherAnswerIndex
              ] = `${otherParentAnswer.answer_text[otherAnswerIndex]} (${multiInputOtherResponse})`;
            }
          } else {
            answerObjToAdd.answer_text = this.certItemForm.value[key][key];
          }
        }

        if (thisQuestionInputObj && thisQuestionInputObj.productColumnTitle === this.tileTitleId) {
          answerRowToAdd.tileTitle = answerObjToAdd.answer_text;
        }

        if (!key.includes('other')) {
          answerRowToAdd.row_input_answers.push(answerObjToAdd);
        }
      });

    this.answer.answered_inputs[0].answer_rows.push(answerRowToAdd);
  }

  public handleRemoveItem(answer): void {
    this.updatingItems = true;

    this.certificationService
      .saveCertItem(this.currentAnswers, answer, true)
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe(
        (response) => {
          if (response && response.answers) {
            this.answer =
              response.answers.answeredQuestions.find((aq) => aq.question_id === this.answer.question_id) ||
              this.createNewAnswerObj();
          }
          const valid = this.hasItems(this.answer);
          this.handleQuestionChanges(this.answer, valid, true);
          this.updatingItems = false;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.SUCCESS,
              summary: 'Item Successfully Removed',
              detail:
                'Remember to click the Save & Next button at bottom right when you are ready to move on to the next question',
              sticky: false,
              blocking: false,
            }),
          );
        },
        () => {
          this.updatingItems = false;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Error Removing Item',
              detail:
                'Please try again - if the problem persists, please use the Help button at top right to report this issue',
              sticky: false,
              blocking: false,
            }),
          );
        },
      );
  }

  public handleFileSelection(event, question) {
    this.filesToUpload[question.id] = [...event];
    this.cdr.detectChanges();
  }

  public formErrorsExist(): boolean {
    return this.saveClicked && !this.hasItems(this.answer);
  }

  public setFieldValidity(id: string, borderType = 'errorBorder') {
    const { invalid, dirty } = this.certItemForm.controls[id];
    return {
      [borderType]:
        (invalid && dirty) ||
        (this.newItemSubmit && invalid) ||
        (this.saveClicked && !this.hasItems(this.answer) && invalid),
    };
  }

  public getCertItemDifference(): string {
    const currentlySubmitted = this.answer.answered_inputs[0].answer_rows.length;
    return currentlySubmitted > this.certItemsAppliedFor
      ? `${currentlySubmitted}`
      : `${currentlySubmitted} of ${this.certItemsAppliedFor}`;
  }

  private createNewAnswerObj(questionId?: string, inputType?: string): AnsweredQuestion {
    const currentAnswer = {
      id: crypto.randomUUID(),
      question_id: questionId || this.question.id,
      input_type: inputType || this.question.question_type,
      answered_inputs: [],
      not_applicable: false,
    } as AnsweredQuestion;
    return currentAnswer;
  }

  private setUpForm(): void {
    // reset everything
    this.newItemSubmit = false;
    this.certItemForm = new FormGroup({});

    if (!this.answer) {
      // no answer exists yet for this question, create a new object
      this.answer = this.createNewAnswerObj();
    }

    // create the question inputs
    for (const input of this.question.inputs) {
      this.createFormInput(input);
    }

    this.multiInputOtherQuestions = this.question.inputs.filter((q) => q.input_type === INPUT_TYPE.MULTI_INPUT_OTHER);
    if (this.multiInputOtherQuestions.length) {
      this.multiInputOtherQuestions.forEach((q) => {
        this.otherInputs[q.id] = {
          id: `${q.id}:other`,
          input_type: 'text',
          label_text: 'Other: Please specify',
          required: true,
          input_list_items: [],
        };
        this.createFormInput(this.otherInputs[q.id]);
      });
    }

    // update parent form bindings
    this.outerForm.removeControl('parentForm');
    this.certItemForm.setParent(this.outerForm);
    this.outerForm.addControl('parentForm', this.certItemForm);
    this.cdr.detectChanges();
  }

  private createFormInput(inputObj: QuestionInput): void {
    // this will create an input element with the correct properties for each item in the inputs array
    const inputItem: FormControl = new FormControl();
    if (inputObj.required) {
      inputItem.setValidators(Validators.required);
    }

    if (inputObj.input_type === 'url') {
      inputObj.required
        ? inputItem.setValidators([Validators.pattern(UrlRegEx()), Validators.required])
        : inputItem.setValidators(Validators.pattern(UrlRegEx()));
    }

    this.certItemForm.addControl(inputObj.id, inputItem);
  }

  private certItemsDoesNotEqualEnrollNumber(): boolean {
    return this.saveClicked && this.certItemsAppliedFor !== this.totalAnsweredItems(this.answer);
  }

  private totalAnsweredItems(answer): number {
    return this.hasItems(answer) ? answer.answered_inputs[0].answer_rows.length : 0;
  }

  private hasItems(answer): boolean {
    return (
      (answer &&
        answer.answered_inputs &&
        answer.answered_inputs[0] &&
        answer.answered_inputs[0].answer_rows &&
        answer.answered_inputs[0].answer_rows.length > 0) ||
      false
    );
  }
}
