import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UsersService } from '../../../services/users.service';
import { CreateAccountError, User } from '@ls/common-ts-models/';
import { Store } from '@ngrx/store';
import { AppState, AccountLoadAction } from '../../../reducers';
import {
  LocalStorageService,
  GenericNotificationAction,
  SeverityOptions,
  LegitScriptModalComponent,
} from '@ls/common-ng-components';

import { DataLayerService } from 'src/app/services/data-layer.service';

@Component({
  selector: 'edit-user',
  styleUrls: ['./edit-user.component.scss'],
  templateUrl: './edit-user.component.html',
})
export class EditUserComponent {
  // these are all from salesforce documentation
  public MAX_PHONE_LENGTH = 40;
  public MAX_EMAIL_LENGTH = 80;
  public MAX_FIRST_NAME_LENGTH = 40;
  public MAX_LAST_NAME_LENGTH = 80;
  public MAX_POSITION_LENGTH = 128;

  @Input() public model: User = {};
  public companyName = '';

  public originalUser: User = {};
  public action: string;

  @Output() public submitted = new EventEmitter<boolean>();
  public processing = false;

  @ViewChild(LegitScriptModalComponent) private editUserModal: LegitScriptModalComponent;
  constructor(
    private usersService: UsersService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private _dataLayerService: DataLayerService,
  ) {
    this.companyName = localStorageService.getAccount().name;
  }

  public submit(formRef: NgForm) {
    Object.keys(formRef.form.controls).forEach((control) => formRef.form.controls[control].markAsTouched());

    if (formRef.form.valid) {
      const { lsUserId, lsAccountId, email, firstName, lastName, phone, position } = this.model;
      const update: User = {
        lsUserId,
        lsAccountId,
        ...(this.action === 'invite' && { email }),
        ...((firstName !== this.originalUser.firstName || lastName !== this.originalUser.lastName) && {
          firstName,
          lastName,
        }),
        ...(phone !== this.originalUser.phone && { phone }),
        ...(position !== this.originalUser.position && { position }),
      };

      if (!(update.email || update.lastName || update.firstName || update.phone || update.position)) {
        this.submitted.emit(true);
        this.clearFormAndHide(formRef, {});
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.INFO,
            summary: 'No update',
            detail: 'No user information was changed',
            sticky: false,
            blocking: false,
          }), // is this a weird user experience...?
        );
        return;
      }

      this.processing = true;
      this.usersService.upsertUser(update, this.action, this.originalUser).subscribe(
        () => {
          this.submitted.emit(true);
          this.clearFormAndHide(formRef, {});
          this.processing = false;

          let msg = '';
          if (this.action === 'edit') {
            msg = `The ${email} user account has been successfully updated`;
            this._dataLayerService.logEditUserEvent();
          }
          else {
            msg = `The new user has been created.  A sign up email was sent to ${email}`;
            this._dataLayerService.logAddUserEvent();
          }
          
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.SUCCESS,
              summary: 'Update Complete',
              detail: msg,
              sticky: false,
              blocking: false,
            }),
          );
          this.store.dispatch(AccountLoadAction());
        },
        (error) => {
          let messageToUser = 'An error occured attempting to submit your update. Please try again.';
          try {
            const { message, type } = error.error;
            if (type === CreateAccountError.UserHasAccount) {
              messageToUser = `An error occurred while inviting this user, please reach out to your account manager or the LegitScript Support Team.`;
            } else if (message.includes('Duplicate data found')) {
              // Salesforce does not allow same name + phone as another user
              messageToUser = message;
            }
          } catch (e) {
            // default is already set above
          }

          this.processing = false;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Failed to submit',
              detail: messageToUser,
              sticky: false,
              blocking: false,
            }),
          );
        },
      );
    }
  }

  public clearFormAndHide(formRef: NgForm, resetModel?: User) {
    formRef.resetForm(resetModel);
    this.hideModal();
  }

  public cancel(formRef) {
    this.clearFormAndHide(formRef, this.originalUser);
    this.hideModal();
  }

  public showModal(action) {
    this.action = action;
    this.editUserModal.showDialog();
    this.originalUser = JSON.parse(JSON.stringify(this.model)); // deep-copying
  }

  public hideModal() {
    this.editUserModal.hideDialog();
  }
}
