<div class="widgets-container">
  <div class="widget">
    <div class="widget-header">
      <h1>Website Search</h1>
    </div>
    <div class="widget-body centered">
      <div class="entry-container entry-container-large">
        <div *ngIf="(entitlements$ | async)?.recentlyPurchased[productFamily]; else searchForm">
          <purchase-processing-notice [productName]="productFamily"></purchase-processing-notice>
        </div>
        <ng-template #searchForm>
          <form class="search" name="form" (ngSubmit)="f.form.valid && submit(f)" #f="ngForm" novalidate>
            <input type="text" placeholder="www.example.com" class="form-control" name="website" [(ngModel)]="website" #name="ngModel" required />
            <div class="form-footer">
              <button type="submit">
                <i class="fa fa-search"></i>Search
              </button>
              <button type="button" (click)="clearSearch(f)">
                <i class="fa fa-times"></i>Clear
              </button>
            </div>
          </form>
        </ng-template>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
  
