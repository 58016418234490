import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BillingService, LocalStorageService } from '@ls/common-ng-components';
import {
  Opportunity,
  OpportunityStageName,
  OpportunityTypes,
  MerchantMonitoringProductGuid,
  MAX_CC_AMOUNT_CERTIFICATION,
  Roles,
} from '@ls/common-ts-models';
import { AppState } from '../reducers';
import { environment } from 'src/environments/environment';

export class BaseMMOpportunityGuard {
  constructor(
    protected router: Router,
    protected localStorage: LocalStorageService,
    protected store: Store<AppState>,
    protected billingService: BillingService,
  ) {}

  protected navigateToRouteBasedOnOpportunity(opportunity: Opportunity, externalReferenceId?: string) {
    const status = opportunity ? opportunity.StageName : undefined;
    const navArray = ['services'];

    if (externalReferenceId === MerchantMonitoringProductGuid.ENTERPRISE) {
      // Supporting arbitrary stages for high tier MM as long as they are not Closed Lost
      // per https://legitscript.atlassian.net/browse/LS-14528
      if (opportunity.StageName !== OpportunityStageName.closedLost) {
        navArray.push('merchant-monitoring');
        this.router.navigate(navArray);
        return;
      } else {
        this.router.navigate(['/account/home']);
        return;
      }
    }

    switch (status) {
      case OpportunityStageName.contractStarted:
        // enrolled in <5k merchants tier, but has not yet paid, need to set payment info and route to payment view
        this.billingService.setPricingInfoWithProration(
          {
            billingItems: [
              {
                description: 'Merchant Monitoring',
                amount: opportunity.Amount,
                recurringAmount: opportunity.Amount,
                recurringPeriod: 'monthly',
                recurringDescription: 'Subscription',
                productId: MerchantMonitoringProductGuid.LOW_TIER,
                quantity: 1,
              },
            ],
            oppId: opportunity.Id,
            maxCCAmount: MAX_CC_AMOUNT_CERTIFICATION,
            oppType: OpportunityTypes.MERCHANT_MONITORING,
            successPage: '/merchant-monitoring',
          },
          new Date(),
        );
        navArray.push('payment');
        break;
      case OpportunityStageName.closedWon:
      case OpportunityStageName.validating:
        // signed up and paid (or in tier not requiring payment to access service), route to dashboard view
        navArray.push('merchant-monitoring');
        break;
      default:
        // not yet signed up
        navArray.push('merchant-monitoring', 'enroll');
        break;
    }

    this.router.navigate(navArray);
  }

  protected canActivateMerchantMonitoring() {
    // If merchant monitoring is enabled for all then always return true
    if (environment.CONFIG.merchantMonitoringEnabled) {
      return true;
    }
    const user = this.localStorage.getAuthenticatedUser();

    if (user && user.roles && user.roles.includes(Roles.cpv2MMUser)) {
      return true;
    }

    this.router.navigate(['/account/home']);
    return false;
  }

  protected getCanActivateFromOpportunity(opportunity: Opportunity, externalReferenceId: string): boolean {
    switch (externalReferenceId) {
      case MerchantMonitoringProductGuid.ENTERPRISE:
        return !!(opportunity?.StageName !== OpportunityStageName.closedLost);
      case MerchantMonitoringProductGuid.LOW_TIER:
        return !!(opportunity?.StageName === OpportunityStageName.closedWon);
      default:
        return false;
    }
  }
}
