<!-- eslint-disable @angular-eslint/template/alt-text -->
<h1>You’re one step closer to searching the world’s largest database of healthcare products, as well as rogue and certified merchant websites</h1>

<section class="overview">
  <picture class="mobile-hide">
    <img src="../assets/img/product-lookup.png">
  </picture>
  <section class="product-lookup-details">
    <div>
      <h2>Applying for Database Lookups is straightforward:</h2>
      <ul>
        <li>Provide information below</li>
        <li>We’ll review your application</li>
        <li>We’ll inform you of the outcome based on the information provided</li>
      </ul>
    </div>
    <div>
      <h2>With LegitScript’s Database Lookups, you can:</h2>
      <ul>
        <li>Search by product name for a specific branded drug or supplement</li>
        <li>Search by ingredient to learn about potential global regulations and restrictions</li>
        <li>Search by URL to see if a website is a known rogue internet pharmacy or certificated LegitScript business</li>
      </ul>
    </div>
  </section>
</section>

<div class="loading-data-services-wrapper" *ngIf="loading; else loaded">
  <p-progressSpinner [style]="{ width: '5em', height: '5em' }"></p-progressSpinner>
</div>
<ng-template #loaded>
  <div [ngSwitch]="display">
    <data-lookups-enroll *ngSwitchCase="'enroll'"></data-lookups-enroll>
    <approved-lookup [opportunity]="opportunity" *ngSwitchCase="'approved'"></approved-lookup>
    <pending-lookup *ngSwitchCase="'pending'"></pending-lookup>
    <div *ngSwitchCase="'denied'" class="data-lookup-status">
      <div>
        <i class="fa fa-ban" aria-hidden="true"></i>
      </div>
      <div>
        <h2>The review of your Data Lookup application is complete.</h2>
        Your request for Data Lookup access was not approved. Please contact <a href="mailto:support@legitscript.com">support@legitscript.com</a> for more information.
      </div>
    </div>
    <div *ngSwitchCase="'referred'" class="data-lookup-status">
      <div>
        <i class="fa fa-pause-circle" aria-hidden="true"></i>
      </div>
      <div>
        <h2>The review of your Data Lookup application is complete.</h2>
        Please apply to <a routerLink="/services/select-certification-type">LegitScript Certification</a>.  You will be granted complimentary Data Lookup access upon certification of your business.
      </div>
    </div>
    <div *ngSwitchCase="'canceled'" class="data-lookup-status">
      <div>
        <i class="fa fa-pause-circle" aria-hidden="true"></i>
      </div>
      <div>
        <h2>Data Lookup subscription canceled</h2>
        Your Data Lookup subscription has been canceled.
      </div>
    </div>
    <div *ngSwitchDefault>
      <h2>Unknown Status</h2>
      <div>Sorry, we were unable to retrieve the status of your request.  Please try again.  If the issue persists, please contact support</div>
    </div>
  </div>
</ng-template>