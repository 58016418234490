import { WBFilterDateRangeValueType } from '@ls/common-ts-models';
import { DateTime } from 'luxon';

export class DateRangeToFilter {
  public static convert(startDate: Date, endDate: Date): WBFilterDateRangeValueType {
    if (!startDate && !endDate) {
      return null;
    }
    const dateTime = DateTime.fromJSDate(endDate);
    const endOfLocalDay = dateTime.toLocal().endOf('day');
    const finalEndDate = endOfLocalDay.toJSDate();
    return [startDate, finalEndDate];
  }
}
