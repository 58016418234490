<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="entry-main add-padding">
  <div class="entry-container entry-container-large" >
      <loading-spinner *ngIf="loading" [loadingText]="loadingText"></loading-spinner>

    <main *ngIf="pricingInfo && !loading">
        <div *ngIf="paymentSuccess" class="payment-thanks">
          <i class="fa fa-check-circle success"></i>
          <h1>Thank You!</h1>
          <div *ngIf="pricingInfo.adjustedApplicationFee < MAX_CC_AMOUNT; else invoice"> 
            Your payment in the amount of {{pricingInfo.adjustedApplicationFee | currency:'USD':'symbol':'1.2-4' }} was successful.
          </div>
          <ng-template #invoice>
            You have successfully approved your adjusted certification pricing. You will be contacted by LegitScript with an invoice for {{pricingInfo.adjustedApplicationFee | currency:'USD':'symbol':'1.2-4' }} shortly.
          </ng-template>
        </div>
        <div *ngIf="!paymentSuccess">
            <p>Please approve the adjusted certification pricing.</p>
            <section class="pricing-update">
              <section>
                <div class="info-container">
                  <div class="display-line mb20">
                    <label>Initial number of {{pricingInfo.itemDisplayName}} applied for:</label>
                    <div>{{pricingInfo.initial.numItems}}</div>
                  </div>
                  <div class="display-line mb20">
                    <label>Initial application fee paid:</label>
                    <div>{{pricingInfo.initial.totalApplication | currency:'USD':'symbol':'1.2-4'}}</div>
                  </div>
                </div>
              </section>
              <section>        
                <div class="info-container">
                  <div class="display-line mb20">
                    <label>Updated number of {{pricingInfo.itemDisplayName}}</label>
                    <div>{{pricingInfo.new.numItems}}</div>
                  </div>
                  <div class="display-line mb20">
                    <label>Total application fee for updated number of {{pricingInfo.itemDisplayName}}:</label>
                    <div>{{pricingInfo.new.totalApplication | currency:'USD':'symbol':'1.2-4'}}</div>

                  </div>
                  <div *ngIf="pricingInfo.approvedCert" class="display-line mb20">
                    <label>Updated subscription fee due upon renewal:</label>
                    <div>
                      {{pricingInfo.new.totalSubscription | currency:'USD':'symbol':'1.2-4'}}
                      <span *ngIf="isProductType">*</span>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div class="info-container">
                  <div class="display-line mb20">
                    <label>Adjusted application fee due today:</label>
                    <div>{{pricingInfo.adjustedApplicationFee | currency:'USD':'symbol':'1.2-4' }}</div>
                  </div>
                  <div class="display-line mb20">                    
                    <span *ngIf="pricingInfo.approvedCert; else inReview">
                      <label>Subscription fee due upon approval:</label>
                      <div>
                        {{pricingInfo.adjustedSubscriptionFee | currency:'USD':'symbol':'1.2-4'}}
                        <span *ngIf="isProductType">*</span>
                      </div>
                    </span>
                    <ng-template #inReview>
                        <label>Total subscription fee due upon certification:</label>
                        <div>
                          {{pricingInfo.new.totalSubscription | currency:'USD':'symbol':'1.2-4'}}
                          <span *ngIf="isProductType">*</span>
                        </div>
                    </ng-template>
                  </div>
                  <p>Note: The application fee is non-refundable</p>
                </div>
              </section>
              <p *ngIf="pricingInfo.approvedCert && pricingInfo.initial.applicationFee !== pricingInfo.new.applicationFee" class="footnote">
                Note: With {{pricingInfo.new.numItems}} {{pricingInfo.itemDisplayName}}, your per-unit application fee has changed from 
                {{pricingInfo.initial.applicationFee | currency:'USD'}} to {{pricingInfo.new.applicationFee | currency:'USD'}}
                and your per-unit subscription fee has changed from {{pricingInfo.initial.subscriptionFee | currency:'USD'}} to {{pricingInfo.new.subscriptionFee | currency:'USD'}}. 
                This pricing adjustment will only be applied to {{pricingInfo.itemDisplayName}} added during this transaction.
              </p>
              <p *ngIf="isProductType" class="footnote">
                * <em>The subscription total is calculated per product that is approved for LegitScript Certification. Products that do not meet LegitScript Certification standards will not incur annual subscription fees.  The number here represents the maximum subscription fee you may be charged (assuming all products you submitted are approved for LegitScript Certification).</em>
              </p>
              <button type="button" class="btn btn-primary" (click)="approveUpdatedPricing()">Approve Updated Pricing</button>
    
            </section> 
          </div>

    </main>
    
      
  </div>
</div>
