import { createAction, createReducer, on, props } from '@ngrx/store';
import { MyLSMerchant } from '@ls/common-ts-models';

export const MERCHANT_DETAIL_GET = '[Merchant] Get';
export const MERCHANT_DETAIL_GET_SUCCESS = '[Merchant] Get Success';
export const MERCHANT_DETAIL_GET_ERROR = '[Merchant] Get Error';

export const MerchantDetailGetAction = createAction(MERCHANT_DETAIL_GET, props<{ correlationId: string }>());

export const MerchantDetailGetSuccessAction = createAction(
  MERCHANT_DETAIL_GET_SUCCESS,
  props<{ merchant: MyLSMerchant }>(),
);

export const MerchantDetailGetErrorAction = createAction(MERCHANT_DETAIL_GET_ERROR, props<{ err?: Error }>());

export interface MerchantDetailState {
  err: Error;
  merchant: MyLSMerchant;
  pending: boolean;
}

export const initialMerchantState: MerchantDetailState = {
  err: null,
  merchant: null,
  pending: true,
};

export const MerchantDetailReducer = createReducer(
  initialMerchantState,

  on(MerchantDetailGetAction, () => ({
    err: null,
    merchant: null,
    pending: true,
  })),

  on(MerchantDetailGetSuccessAction, (_state, { merchant }) => ({
    err: null,
    merchant,
    pending: false,
  })),

  on(MerchantDetailGetErrorAction, (_state, { err }) => ({
    err,
    merchant: null,
    pending: false,
  })),
);
