/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateAWebsiteRequest { 
    categoryId?: string;
    client?: string;
    websiteUrl?: string;
    network?: string;
    monitoringStatus?: UpdateAWebsiteRequest.MonitoringStatusEnum;
}
export namespace UpdateAWebsiteRequest {
    export type MonitoringStatusEnum = 'Weekly' | 'Bi-Weekly' | 'Monthly' | 'Bi-Monthly' | 'Quarterly' | 'None';
    export const MonitoringStatusEnum = {
        Weekly: 'Weekly' as MonitoringStatusEnum,
        Bi_Weekly: 'Bi-Weekly' as MonitoringStatusEnum,
        Monthly: 'Monthly' as MonitoringStatusEnum,
        Bi_Monthly: 'Bi-Monthly' as MonitoringStatusEnum,
        Quarterly: 'Quarterly' as MonitoringStatusEnum,
        None: 'None' as MonitoringStatusEnum
    };
}



