import { empty as observableEmpty, Subject } from 'rxjs';

import { takeUntil, take, catchError, skipWhile } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { UpdateAccountRequest, SFAccountErrors, Address } from '@ls/common-ts-models';

import { AccountState, AppState, AccountLoadSuccessAction } from '../../../reducers';
import { NgForm } from '@angular/forms';
import countryCodes from '../../../../assets/countries.json';
import { AccountService } from '../../../services/account.service';
import { ProductFamily } from '@ls/common-ts-models';
import { MMEnrollService } from '../../../modules/merchant-monitoring/services';
import { GenericNotificationAction, LegitScriptModalComponent, SeverityOptions } from '@ls/common-ng-components';

@Component({
  selector: 'edit-account-details',
  templateUrl: './edit-account-details.component.html',
  styleUrls: ['./edit-account-details.component.scss'],
})
export class EditAccountDetailsComponent implements OnDestroy, OnInit {
  public MAX_PHONE_LENGTH = 40;
  public MAX_ZIP_LENGTH = 20;
  public MAX_STATE_LENGTH = 20;
  public MAX_CITY_LENGTH = 40;
  public MAX_STREET_LENGTH = 125;
  public MAX_ACCOUNT_NAME_LENGTH = 255;
  @Input() public model: AccountState;
  public account: AccountState;
  public originalAccount: AccountState;
  public countries = countryCodes;
  public isMMCustomer = false;
  public loading = false;
  private destroyed$: Subject<boolean> = new Subject();
  @ViewChild('form') private form: NgForm;
  @ViewChild(LegitScriptModalComponent) private editAccountModal: LegitScriptModalComponent;

  constructor(
    private accountService: AccountService,
    private store: Store<AppState>,
    private mmService: MMEnrollService,
  ) {}

  public ngOnInit() {
    this.account = JSON.parse(JSON.stringify(this.model));
    // address must be defined for front-end binding.
    this.account.address = this.account.address ?? ({} as Address);
    this.store
      .select('entitlements')
      .pipe(
        skipWhile((entitlements) => entitlements.pending),
        takeUntil(this.destroyed$),
      )
      .subscribe((entitlements) => {
        this.isMMCustomer = !!(
          entitlements &&
          entitlements.products &&
          entitlements.products[ProductFamily.MERCHANT_MONITORING]
        );
      });
  }

  public submit() {
    this.loading = true;
    const update: UpdateAccountRequest = {
      name: {
        updated: this.originalAccount.name === this.account.name ? '' : this.account.name,
        previous: this.originalAccount.name,
      },
      phone: this.originalAccount.phone === this.account.phone ? '' : this.account.phone,
      address:
        JSON.stringify(this.originalAccount.address) === JSON.stringify(this.account.address)
          ? null
          : this.account.address,
    };

    if (!update.name.updated && !update.phone && !update.address) {
      this.loading = false;
      this.editAccountModal.hideDialog();
      return observableEmpty();
    }

    this.accountService
      .updateAccount(update)
      .pipe(
        catchError((err) => {
          this.loading = false;
          let errMsg = 'An error occured while trying to update account information. Please try again.';
          if (
            err.status === 409 ||
            (err.error && err.error.message === 'DUPLICATES_DETECTED: DUPLICATE ACCOUNT NAME DETECTED')
          ) {
            errMsg = SFAccountErrors.DUPLICATE_ACCOUNT_NAME;
          }
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Failed to update account information.',
              detail: errMsg,
              sticky: null,
            }),
          );
          return observableEmpty();
        }),
        take(1),
      )
      .subscribe(() => {
        if (this.isMMCustomer) {
          const { name, address, phone } = update;
          const mmUpdateObj: any = {
            account_svc_id: +this.account.lsAccountId,
            name: name.updated || name.previous,
            ...(!!phone && { phone }),
          };

          if (address) {
            mmUpdateObj.address1 = address.street1;
            mmUpdateObj.address2 = address.street2;
            mmUpdateObj.city = address.city;
            mmUpdateObj.state = address.province;
            mmUpdateObj.country = address.countryCode;
            mmUpdateObj.postal_code = address.postalCode;
          }

          this.mmService
            .upsertCustomer(mmUpdateObj)
            .pipe(take(1))
            .subscribe(
              () => this.handleSuccess(),
              () => this.handleMMUpsertError(),
            );
        } else {
          this.handleSuccess();
        }
      });
  }

  public showModal() {
    // this makes a deep copy of the account object to compare against
    this.originalAccount = JSON.parse(JSON.stringify(this.account));
    this.editAccountModal.showDialog();
  }

  public cancel() {
    this.editAccountModal.hideDialog();
    this.form.resetForm({
      companyName: this.model.name,
      street1: this.model.address.street1,
      street2: this.model.address.street2,
      city: this.model.address.city,
      state: this.model.address.province,
      postalCode: this.model.address.postalCode,
      country: this.model.address.countryCode,
      phone: this.model.phone,
    });
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private handleSuccess() {
    this.loading = false;
    this.editAccountModal.hideDialog();
    this.store.dispatch(AccountLoadSuccessAction({ payload: this.account })); // update store with latest data
  }

  private handleMMUpsertError() {
    this.handleSuccess();
    this.store.dispatch(
      GenericNotificationAction({
        severity: SeverityOptions.WARN,
        summary: 'Account information updated, but an error occurred while updating your Merchant Monitoring service',
        detail: 'Please contact our support team so they may investigate and resolve the issue.',
        sticky: null,
      }),
    );
  }
}
