<h1>Pricing Information</h1>
<p><strong>Pricing is on a per-product basis.</strong> Our pricing comprises two parts: a one-time application fee and a recurring annual fee. For fees under $20,000, your credit card will be charged a nonrefundable application fee after completing this enrollment form. For fees more than $20,000, you will be issued an invoice for a bank ACH transfer for a nonrefundable application fee after completing this enrollment form. After that, the annual fee will be automatically charged on the date of approval of your application and will recur via credit card or invoice on an annual basis starting from the date of approval.</p>
<table class="pricing-modal-table">
  <tr>
    <th>Tier</th>
    <th>Number of Products</th>
    <th>Application fee per product</th>
    <th>Annual fee per product</th>
  </tr>
  <tr>
    <td>1</td>
    <td>1 to 5</td>
    <td>${{ pricing.ONE_TO_FIVE.APPLICATION }}</td>
    <td>${{ pricing.ONE_TO_FIVE.SUBSCRIPTION }}</td>
  </tr>
  <tr>
    <td>2</td>
    <td>6 to 10</td>
    <td>${{ pricing.SIX_TO_FIFTY.APPLICATION }}</td>
    <td>${{ pricing.SIX_TO_FIFTY.SUBSCRIPTION }}</td>
  </tr>
  <tr>
    <td>3</td>
    <td>11 to 20</td>
    <td>${{ pricing.FIFTYONE_TO_NINETYNINE.APPLICATION }}</td>
    <td>${{ pricing.FIFTYONE_TO_NINETYNINE.SUBSCRIPTION }}</td>
  </tr>
  <tr>
    <td>4</td>
    <td>21+</td>
    <td>${{ pricing.ONEHUNDRED_PLUS.APPLICATION }}</td>
    <td>${{ pricing.ONEHUNDRED_PLUS.SUBSCRIPTION }}</td>
  </tr>
</table>
<expedited-pricing-info [enabled]="expeditedEnabled"></expedited-pricing-info>