import { share, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CertificationService } from './';
import { LocalStorageService, BillingService } from '@ls/common-ng-components';
import {
  ProductFamily,
  CertEnrollRequest,
  HealthcareCertPricebookGuid,
  HealthcareCertProductGuid,
  AnsweredQuestion,
  DaatcPricebookGuid,
  DaatcProductGuid,
  CbdProductPricebookGuid,
  CbdWebsitePricebookGuid,
  CbdProductProductGuid,
  CbdWebsiteProductGuid,
  Certification,
  BillingItem,
  ExpeditedProcessingProductGuid,
} from '@ls/common-ts-models';

export const PriceBookGuid = {
  HEALTHCARE_A: HealthcareCertPricebookGuid.A,
  HEALTHCARE_B: HealthcareCertPricebookGuid.B,
  HEALTHCARE_C: HealthcareCertPricebookGuid.C,
  HEALTHCARE_A_PROBATIONARY: HealthcareCertPricebookGuid.A_PROBATIONARY,
  HEALTHCARE_B_PROBATIONARY: HealthcareCertPricebookGuid.B_PROBATIONARY,
  HEALTHCARE_C_PROBATIONARY: HealthcareCertPricebookGuid.C_PROBATIONARY,
  [HealthcareCertPricebookGuid.A]: 'HEALTHCARE_A',
  [HealthcareCertPricebookGuid.B]: 'HEALTHCARE_B',
  [HealthcareCertPricebookGuid.C]: 'HEALTHCARE_C',
  [HealthcareCertPricebookGuid.A_PROBATIONARY]: 'HEALTHCARE_A_PROBATIONARY',
  [HealthcareCertPricebookGuid.B_PROBATIONARY]: 'HEALTHCARE_B_PROBATIONARY',
  [HealthcareCertPricebookGuid.C_PROBATIONARY]: 'HEALTHCARE_C_PROBATIONARY',
  INDIVIDUAL_PRACTITIONER: DaatcPricebookGuid.INDIVIDUAL_PRACTITIONER,
  ONE_TO_NINE: DaatcPricebookGuid.ONE_TO_NINE,
  TEN_TO_TWENTYFOUR: DaatcPricebookGuid.TEN_TO_TWENTYFOUR,
  TWENTYFIVE_TO_FORTYNINE: DaatcPricebookGuid.TWENTYFIVE_TO_FORTYNINE,
  FIFTY_TO_NINETYNINE: DaatcPricebookGuid.FIFTY_TO_NINETYNINE,
  HUNDRED_PLUS: DaatcPricebookGuid.HUNDRED_PLUS,
  [DaatcPricebookGuid.INDIVIDUAL_PRACTITIONER]: 'INDIVIDUAL_PRACTITIONER',
  [DaatcPricebookGuid.ONE_TO_NINE]: 'ONE_TO_NINE',
  [DaatcPricebookGuid.TEN_TO_TWENTYFOUR]: 'TEN_TO_TWENTYFOUR',
  [DaatcPricebookGuid.TWENTYFIVE_TO_FORTYNINE]: 'TWENTYFIVE_TO_FORTYNINE',
  [DaatcPricebookGuid.FIFTY_TO_NINETYNINE]: 'FIFTY_TO_NINETYNINE',
  [DaatcPricebookGuid.HUNDRED_PLUS]: 'HUNDRED_PLUS',
  CBD_WEBSITE_A: CbdWebsitePricebookGuid.A,
  CBD_WEBSITE_A_PROBATIONARY: CbdWebsitePricebookGuid.A_PROBATIONARY,
  CBD_WEBSITE_B: CbdWebsitePricebookGuid.B,
  CBD_WEBSITE_B_PROBATIONARY: CbdWebsitePricebookGuid.B_PROBATIONARY,
  CBD_WEBSITE_C: CbdWebsitePricebookGuid.C,
  CBD_WEBSITE_C_PROBATIONARY: CbdWebsitePricebookGuid.C_PROBATIONARY,
  [CbdWebsitePricebookGuid.A]: 'CBD_WEBSITE_A',
  [CbdWebsitePricebookGuid.A_PROBATIONARY]: 'CBD_WEBSITE_A_PROBATIONARY',
  [CbdWebsitePricebookGuid.B]: 'CBD_WEBSITE_B',
  [CbdWebsitePricebookGuid.B_PROBATIONARY]: 'CBD_WEBSITE_B_PROBATIONARY',
  [CbdWebsitePricebookGuid.C]: 'CBD_WEBSITE_C',
  [CbdWebsitePricebookGuid.C_PROBATIONARY]: 'CBD_WEBSITE_C_PROBATIONARY',
  ONE_TO_FIVE: CbdProductPricebookGuid.ONE_TO_FIVE,
  SIX_TO_FIFTY: CbdProductPricebookGuid.SIX_TO_FIFTY,
  FIFTYONE_TO_NINETYNINE: CbdProductPricebookGuid.FIFTYONE_TO_NINETYNINE,
  ONEHUNDRED_PLUS: CbdProductPricebookGuid.ONEHUNDRED_PLUS,
  [CbdProductPricebookGuid.ONE_TO_FIVE]: 'ONE_TO_FIVE',
  [CbdProductPricebookGuid.SIX_TO_FIFTY]: 'SIX_TO_FIFTY',
  [CbdProductPricebookGuid.FIFTYONE_TO_NINETYNINE]: 'FIFTYONE_TO_NINETYNINE',
  [CbdProductPricebookGuid.ONEHUNDRED_PLUS]: 'ONEHUNDRED_PLUS',
};

export const ProductGuid = {
  HEALTHCARE_APPLICATION: HealthcareCertProductGuid.APPLICATION,
  HEALTHCARE_SUBSCRIPTION: HealthcareCertProductGuid.SUBSCRIPTION,
  [HealthcareCertProductGuid.APPLICATION]: 'HEALTHCARE_APPLICATION',
  [HealthcareCertProductGuid.SUBSCRIPTION]: 'HEALTHCARE_SUBSCRIPTION',
  DAATC_APPLICATION: DaatcProductGuid.APPLICATION,
  DAATC_SUBSCRIPTION: DaatcProductGuid.SUBSCRIPTION,
  [DaatcProductGuid.APPLICATION]: 'DAATC_APPLICATION',
  [DaatcProductGuid.SUBSCRIPTION]: 'DAATC_SUBSCRIPTION',
  CBD_PRODUCT_APPLICATION: CbdProductProductGuid.APPLICATION,
  CBD_PRODUCT_SUBSCRIPTION: CbdProductProductGuid.SUBSCRIPTION,
  [CbdProductProductGuid.APPLICATION]: 'CBD_PRODUCT_APPLICATION',
  [CbdProductProductGuid.SUBSCRIPTION]: 'CBD_PRODUCT_SUBSCRIPTION',
  CBD_WEBSITE_APPLICATION: CbdWebsiteProductGuid.APPLICATION,
  CBD_WEBSITE_SUBSCRIPTION: CbdWebsiteProductGuid.SUBSCRIPTION,
  [CbdWebsiteProductGuid.APPLICATION]: 'CBD_WEBSITE_APPLICATION',
  [CbdWebsiteProductGuid.SUBSCRIPTION]: 'CBD_WEBSITE_SUBSCRIPTION',
};

export interface QuestionnaireInfo {
  typeId: string;
  pricingTierSku: string;
  questionnaireTypeName: string;
  certificationType: string;
  answeredQuestions: AnsweredQuestion[];
  questions: any[];
  productId: string;
  enrolledItemCount?: number;
  nonprofit?: boolean;
}

export interface ProductPrice {
  SUBSCRIPTION: number;
  APPLICATION: number;
}
export interface HealthcarePricing {
  HEALTHCARE_A: ProductPrice;
  HEALTHCARE_B: ProductPrice;
  HEALTHCARE_C: ProductPrice;
  HEALTHCARE_A_PROBATIONARY: ProductPrice;
  HEALTHCARE_B_PROBATIONARY: ProductPrice;
  HEALTHCARE_C_PROBATIONARY: ProductPrice;
}

export interface CbdWebsitePricing {
  CBD_WEBSITE_A: ProductPrice;
  CBD_WEBSITE_B: ProductPrice;
  CBD_WEBSITE_C: ProductPrice;
  CBD_WEBSITE_A_PROBATIONARY: ProductPrice;
  CBD_WEBSITE_B_PROBATIONARY: ProductPrice;
  CBD_WEBSITE_C_PROBATIONARY: ProductPrice;
}

export interface CbdProductPricing {
  ONE_TO_FIVE: ProductPrice;
  SIX_TO_FIFTY: ProductPrice;
  FIFTYONE_TO_NINETYNINE: ProductPrice;
  ONEHUNDRED_PLUS: ProductPrice;
}

export interface DAATCPricing {
  INDIVIDUAL_PRACTITIONER: ProductPrice;
  ONE_TO_NINE: ProductPrice;
  TEN_TO_TWENTYFOUR: ProductPrice;
  TWENTYFIVE_TO_FORTYNINE: ProductPrice;
  FIFTY_TO_NINETYNINE: ProductPrice;
  HUNDRED_PLUS: ProductPrice;
}

export interface AddonPricing {
  EXPEDITED_PROCESSING: number;
}

@Injectable()
export class EnrollService {
  constructor(
    private billingSvc: BillingService,
    private http: HttpClient,
    private certSvc: CertificationService,
    private localStorageSvc: LocalStorageService,
  ) {}

  public getEnrollmentQuestions(questionnaireType: string): Observable<any> {
    return this.http
      .get(
        `${this.certSvc.certificationApiUrl}questionnaire/${questionnaireType}`,
        this.localStorageSvc.getJWTAndSetOptions(),
      )
      .pipe(share());
  }

  public getAddonPricing(): Observable<AddonPricing> {
    return this.billingSvc.getProductForFamily(ProductFamily.CERT_ADDONS).pipe(
      map((r: any) => {
        /* this mapping will be inadequate if/when we reach a position where
         * add-on pricing is handled differently on a per-pricebook basis.
         * however, _all_ of this pricing code in the front end is trash and
         * needs to be reworked */
        // tslint:disable-next-line
        let price = undefined;
        for (const pricebook of r) {
          const matchingProduct = pricebook.products.find(
            (product) => product.externalId === ExpeditedProcessingProductGuid,
          );
          if (matchingProduct) {
            price = matchingProduct.price;
            break;
          }
        }
        return {
          EXPEDITED_PROCESSING: price,
        } as AddonPricing;
      }),
    );
  }

  public getPricing(
    productFamily: string,
  ): Observable<HealthcarePricing | DAATCPricing | CbdWebsitePricing | CbdProductPricing> {
    return this.billingSvc.getProductForFamily(productFamily).pipe(
      map((r: any) => {
        if (r.length === 0) {
          throw new Error('No prices returned from server.');
        }

        let prices: HealthcarePricing | DAATCPricing | CbdProductPricing | CbdWebsitePricing;
        switch (productFamily) {
          case ProductFamily.RX_CERT:
            prices = {
              HEALTHCARE_A: {},
              HEALTHCARE_B: {},
              HEALTHCARE_C: {},
              HEALTHCARE_A_PROBATIONARY: {},
              HEALTHCARE_B_PROBATIONARY: {},
              HEALTHCARE_C_PROBATIONARY: {},
            } as HealthcarePricing;
            break;
          case ProductFamily.DAATC:
            prices = {
              INDIVIDUAL_PRACTITIONER: {},
              ONE_TO_NINE: {},
              TEN_TO_TWENTYFOUR: {},
              TWENTYFIVE_TO_FORTYNINE: {},
              FIFTY_TO_NINETYNINE: {},
              HUNDRED_PLUS: {},
            } as DAATCPricing;
            break;
          case ProductFamily.CBD_PRODUCT_CERT:
            prices = {
              ONE_TO_FIVE: {},
              SIX_TO_FIFTY: {},
              FIFTYONE_TO_NINETYNINE: {},
              ONEHUNDRED_PLUS: {},
            } as CbdProductPricing;
            break;
          case ProductFamily.CBD_WEBSITE_CERT:
            prices = {
              CBD_WEBSITE_A: {},
              CBD_WEBSITE_A_PROBATIONARY: {},
              CBD_WEBSITE_B: {},
              CBD_WEBSITE_B_PROBATIONARY: {},
              CBD_WEBSITE_C: {},
              CBD_WEBSITE_C_PROBATIONARY: {},
            } as CbdWebsitePricing;
            break;
        }
        r.forEach((pricebook) => {
          // take advantage of enum reverse lookups to dereference the guid
          const tierIdentifier = PriceBookGuid[pricebook.externalReferenceId];

          pricebook.products.forEach((product) => {
            const productIdentifier = ProductGuid[product.externalId];
            const productType =
              productIdentifier && productIdentifier.includes('APPLICATION') ? 'APPLICATION' : 'SUBSCRIPTION';

            if (tierIdentifier && productType) {
              prices[tierIdentifier][productType] = product.price;
            }
          });
        });
        return prices;
      }),
    ); // this one is not shared!
  }

  public getPricingInfo(productFamily: string, tierGuid: string, enrolledItemCount: number) {
    const tierName = PriceBookGuid[tierGuid];
    return this.getPricing(productFamily).pipe(
      map((pricing) => {
        const billingMap = {
          [ProductFamily.RX_CERT]: {
            applicationFee: pricing[tierName].APPLICATION * enrolledItemCount,
            subscriptionFee: pricing[tierName].SUBSCRIPTION * enrolledItemCount,
            productId: HealthcareCertProductGuid.APPLICATION,
          },
          [ProductFamily.DAATC]: {
            applicationFee: pricing[tierName].APPLICATION * enrolledItemCount,
            subscriptionFee: pricing[tierName].SUBSCRIPTION * enrolledItemCount,
            productId: DaatcProductGuid.APPLICATION,
          },
          [ProductFamily.CBD_WEBSITE_CERT]: {
            applicationFee: pricing[tierName].APPLICATION * enrolledItemCount,
            subscriptionFee: pricing[tierName].SUBSCRIPTION * enrolledItemCount,
            productId: CbdWebsiteProductGuid.APPLICATION,
          },
          [ProductFamily.CBD_PRODUCT_CERT]: {
            applicationFee: pricing[tierName].APPLICATION * enrolledItemCount,
            subscriptionFee: pricing[tierName].SUBSCRIPTION * enrolledItemCount,
            productId: CbdProductProductGuid.APPLICATION,
          },
        };

        return billingMap[productFamily];
      }),
    );
  }

  public getApplicationBillingInfo(productFamily: string, cert: Certification): Observable<BillingItem> {
    return this.getPricingInfo(productFamily, cert.tier, cert.enrolledItemCount).pipe(
      map((pricingInfo) => {
        return {
          description: 'Application',
          amount: pricingInfo.applicationFee,
          recurringDescription: 'Subscription',
          recurringAmount: pricingInfo.subscriptionFee,
          recurringOnApproval: true,
          recurringPeriod: 'yearly',
          productId: pricingInfo.productId,
          quantity: cert.enrolledItemCount ? cert.enrolledItemCount : 1,
          discount: 0,
        } as BillingItem;
      }),
    );
  }

  public submit(form: CertEnrollRequest) {
    return this.http
      .post(`${this.certSvc.certificationApiUrl}certification`, form, this.localStorageSvc.getJWTAndSetOptions())
      .pipe(share());
  }

  public buildAnsweredQuestion(inputType: string, questionId?: string, answerText?: string): AnsweredQuestion {
    return {
      id: crypto.randomUUID(),
      question_id: questionId || '',
      input_type: inputType,
      answered_inputs: [
        {
          id: crypto.randomUUID(),
          input_id: crypto.randomUUID(),
          input_type: inputType,
          answer_text: answerText || '',
        },
      ],
    } as AnsweredQuestion;
  }
}
