<div class="widgets-container">
  <div class="widget full-widget">
    <div class="widget-header">
      <span class="no-icon">
        <h1>Monitoring Services</h1>
      </span>
      <div *ngIf="isServiceAccessEnabled">
        <refresh-api-key [mmProduct]="refreshApiInput"></refresh-api-key>
      </div>
    </div>
    <div class="widget-body centered">
      <loading-spinner *ngIf="!monitoringServices || isLoading" loadingText="Loading Monitoring Services"></loading-spinner>
      <p-table
      *ngIf="monitoringServices && !isLoading"
      class="prime-table"
      [responsive]="true"
      responsiveLayout="stack"
      [value]="monitoringServices"
      (onRowSelect)="handleRowClick($event)"
      selectionMode="single">
        <ng-template pTemplate="header">
          <tr>
            <th>Company</th>
            <th>Primary URL</th>
            <th>Monitoring Type</th>
            <th>Status</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-service let-rowData>
          <tr [pSelectableRow]="rowData">
            <td>
              <span class="p-column-title">Company</span>
              {{service.companyName}}
            </td>
            <td>
              <span class="p-column-title">Primary URL</span>
              <a *ngIf="service.primaryUrl" href={{service.primaryUrl}} target="_blank">{{service.primaryUrl}}</a>
            </td>
            <td>
              <span class="p-column-title">Monitoring Type</span>
              {{service.serviceType}}
            </td>
            <td>
              <span class="p-column-title">Status</span>
              {{service.status}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="5">
                No monitoring services.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <legitscript-modal class="no-footer" #purchasePendingNotice [draggable]=false [resizable]=false [responsive]=true
  [modal]=true autoAlign=true [width]=800 height=auto>
    <div class="dialog-body" style="overflow:auto; text-align: left;">
      <purchase-processing-notice [productName]="selectedPendingServiceItem?.serviceType"></purchase-processing-notice>
      <div class="form-footer">
        <button type="button" (click)="purchasePendingNotice.hideDialog()" label="Close">Close</button>
      </div>
    </div>
  </legitscript-modal>
</div>
