import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { AccountService } from '../../services';
import { AppState, AuthenticationState, UserConfirmAction, fnAuthenticationState } from '../../reducers';

@Component({
  selector: 'verify-account',
  templateUrl: 'verify-account.component.html',
  styleUrls: ['verify-account.component.scss'],
})
export class VerifyAccountComponent implements OnInit, OnDestroy {
  public verificationToken: string;
  public verification$: Observable<AuthenticationState>;
  public destroyed$: Subject<boolean> = new Subject();

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private accountService: AccountService,
  ) {}

  public ngOnInit() {
    this.verificationToken = this.accountService.parseTokenFromUrl(this.router.url, 'verification_token');

    this.store.dispatch(UserConfirmAction({ lsUserId: '', verificationToken: this.verificationToken }));
    this.verification$ = this.store.select(fnAuthenticationState).pipe(takeUntil(this.destroyed$.asObservable()));
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public contactSupport() {
    window.open('https://help.legitscript.com', '_blank');
  }
}
