import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  GenericNotificationAction,
  SeverityOptions,
  BillingService,
  LegitScriptModalComponent,
} from '@ls/common-ng-components';
import { ProductsAPILookupService } from '../../services';
import { AppState } from '../../reducers';

@Component({
  selector: 'products-api-enroll',
  styleUrls: ['./products-api-enroll.scss', '../../styles/range.scss', '../../styles/enroll.scss'],
  templateUrl: './products-api-enroll.html',
  providers: [ProductsAPILookupService],
})
export class ProductsApiEnrollComponent implements OnInit, OnDestroy {
  public pricing$: Subscription;
  public loadingPricing = true;
  public products: any[];
  public redFlagProducts: any[];
  public yellowFlagProducts: any[];
  public productChoice: any;
  public productChoiceIndex = 0;
  public searchType = 'all';
  public sfPricebookId: string;
  public hasScrolledToBottom = false;
  public termsSelected = false;

  @ViewChild('termsModal') private termsModal: LegitScriptModalComponent;

  constructor(
    public router: Router,
    private service: ProductsAPILookupService,
    private billingSvc: BillingService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit() {
    // TODO: if user has already purchased products API access, redirect:
    // this.router.navigate(['services', 'lookups', 'products-api', 'docs']);

    this.pricing$ = this.service.getPricing().subscribe(
      (res) => {
        this.products = res.products.sort((a, b) => a.price - b.price);
        this.yellowFlagProducts = this.products.filter((product) => product.name.includes('Yellow Flag'));
        this.redFlagProducts = this.products.filter((product) => product.name.includes('Red Flag'));
        this.sfPricebookId = res.externalReferenceId;
        this.updateProductChoice();
        this.loadingPricing = false;
      },
      (err) => {
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Error getting pricing information',
            detail: err,
            sticky: false,
            blocking: false,
          }),
        );
        this.loadingPricing = false;
      },
    );
  }

  public ngOnDestroy() {
    if (this.pricing$) {
      this.pricing$.unsubscribe();
    }
  }

  public handleTypeChange() {
    this.productChoiceIndex = 0;
    this.updateProductChoice();
  }

  public goToPayment() {
    this.billingSvc.setPricingInfo({
      billingItems: [
        {
          description: this.productChoice.name,
          amount: Number(this.billingSvc.prorateRemainderOfMonth(new Date(), this.productChoice.price)),
          productId: this.productChoice.externalId,
          recurringAmount: this.productChoice.price,
          recurringPeriod: 'monthly',
          recurringDescription: 'Subscription',
          prorated: true,
          quantity: 1,
        },
      ],
      sfPricebookId: this.sfPricebookId,
      oppType: 'API Subscription',
      successPage: '/services/api/products/docs',
      maxCCAmount: 10000,
      invoiceMessage:
        'Need more than 10,000 monthly API requests? <a href="https://www.legitscript.com/contact-api/" target="_blank">Contact us</a> for volume subscription orders.',
    });
    this.router.navigate(['services', 'payment']);
  }

  public updateProductChoice() {
    if (this.searchType === 'all') {
      this.productChoice = this.yellowFlagProducts[this.productChoiceIndex];
    } else {
      this.productChoice = this.redFlagProducts[this.productChoiceIndex];
    }
  }

  // T/Cs
  public onDialogScroll(event: any) {
    if (event.isWindowEvent === false && event.isReachingBottom === true) {
      this.hasScrolledToBottom = true;
    }
  }
  public onTermsChecked(e: Event) {
    const checkbox: HTMLInputElement = e.target as any;
    if (checkbox.checked === true && this.hasScrolledToBottom === false) {
      this.termsModal.showDialog();
      setTimeout(() => {
        this.termsSelected = false;
      });
    }
  }
  public onAgreeToTerms() {
    this.termsSelected = true;
    this.termsModal.hideDialog();
  }
}
