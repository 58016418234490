<div class="widget">
  <div class="widget-header">
    <div>
      <i class="fa fa-cubes"></i><h1>Active Products</h1>
    </div>
  </div>
  <div class="widget-body centered">
    <loading-spinner *ngIf="(!activeProducts && !error) || loading" loadingText="Loading Active Products"></loading-spinner>
    <div id="active-product-table-widget">
      <div *ngIf="!activeProducts && error">
        <span class="error">Error loading products.</span>
      </div>
      <p-table
      *ngIf="activeProducts && !loading"
      class="active-product-list"
      [responsive]="true"
      responsiveLayout="stack"
      [value]="activeProducts"
      selectionMode="single">
      <ng-template pTemplate="header" >
        <tr>
          <th>NAME</th>
          <th>PLAN</th>
          <th>USAGE</th>
          <th>EXPIRATION</th>
          <th>ACTIONS</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-productListItem>
        <tr class="active-product-table" #productrow>
          <td class="active-product-table-column">
            <span class="p-column-title">NAME</span>
            {{productListItem.name}}
          </td>
          <td class="active-product-table-column">
            <span class="p-column-title">PLAN</span>
            {{productListItem.plan || "N/A"}}
          </td>
          <td class="active-product-table-column">
            <span class="p-column-title">USAGE</span>
            {{productListItem.usageString || "N/A"}}
          </td>
          <td class="active-product-table-column">
            <span class="p-column-title">EXPIRATION</span>
            <span>{{(productListItem.expiration | date: 'shortDate') || "N/A"}}</span>
          </td>
          <td class="active-product-table-column">
            <div *ngIf="productListItem.menuItems && productListItem.productType === activeProductTypes.MONITORING; else noActions">
              <span class="p-column-title">ACTIONS</span>
              <i #ellipsis class="fa fa-ellipsis-h" (click)="menu.toggle($event);" aria-hidden="true"></i>
              <p-menu #menu [popup]="true" [model]="productListItem.menuItems" appendTo="body"></p-menu>
            </div>
            <ng-template #noActions>
              <span class="p-column-title">ACTIONS</span>
              <span>N/A</span>
            </ng-template>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="5">
                No active products.
            </td>
        </tr>
    </ng-template>
    </p-table>
  </div>
  </div>
</div>

<legitscript-modal class="no-footer" #confirmAPIKeyRefreshModal [draggable]=false [resizable]=false [responsive]=true
[modal]=true autoAlign=true [width]=800 height=auto>
  <div class="dialog-body" style="overflow:auto; text-align: left;">
    <div class="modal-height-match" *ngIf="!refreshSubmitting && !refreshComplete; else refreshing">
      <h1>Are you sure you want to generate a new API Key?</h1>
      <p>
        Your current key will stop working immediately. It will take a few moments to generate your new API key, which will be displayed here as soon as it is ready to use.
      </p>
      <p>
        For security reasons this is the only time you will be able to see this key, so please be prepared to store it somewhere safe.
      </p>
      <p>
        <button type="button" (click)="performRefreshAPIKey()">Refresh Key</button>
        <button type="button" class="btn-no-bg" (click)="confirmAPIKeyRefreshModal.hideDialog()">Cancel</button>
      </p>
    </div>
    <ng-template #refreshing>
      <div class="small-spinner-wrapper {{refreshSubmitting ? 'show' : ''}}">
        <p-progressSpinner [style]="{ width: '3em', height: '3em' }"></p-progressSpinner>
        <h3>Refreshing your API key, please wait...</h3>
      </div>
      <!-- <loading-spinner *ngIf="refreshSubmitting; else showNewKey" loadingText="Refreshing your API key"></loading-spinner> -->
      <div class="modal-height-match">
        <h1>Your new API key is: <pre>{{newApiKey}}</pre></h1>
        <p>Remember: This key will <em>not</em> be available again after you close this modal, so be sure to store it somewhere safe!</p>
        <p>
          <button type="button" (click)="hideRefreshModal()">Close Modal</button>
        </p>
      </div>
    </ng-template>
  </div>
</legitscript-modal>
