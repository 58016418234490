<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="filters">
  <div class="grid">
    <div class="column">
      <div class="item">
        <label>Merchant Name</label>
        <input type="text" pInputText [(ngModel)]="nameControl">
      </div>
      <div class="item">
        <label>Authoritative URL</label>
        <input type="text" pInputText [(ngModel)]="URLControl">
      </div>
      <div class="item" *ngIf="bulkEnabled; else nonBulk">
        <label>MID (multiple accepted)</label>
        <multi-line-input [(inputArray)]="MIDControl"></multi-line-input>
      </div>
      <ng-template #nonBulk>
        <div class="item">
          <label>MID</label>
          <input type="text" [(ngModel)]="MIDControl">
        </div>
      </ng-template>
      <div class="item">
        <label>LS actions</label>
        <p-multiSelect [options]="lsActions" [(ngModel)]="lsActionsControl" [filter]="false"
          [defaultLabel]="optionPlaceholder">
          <ng-template let-action pTemplate="item">
            <div>{{action.label}}</div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
    <div class="column">
      <div class="item" *ngIf="bulkEnabled; else nonBulk">
        <label>LS tags (multiple accepted)</label>
        <multi-line-input [(inputArray)]="lsTagControl"></multi-line-input>
      </div>
      <ng-template #nonBulk>
        <div class="item">
          <label>LS Tags</label>
          <input type="text" [(ngModel)]="lsTagControl">
        </div>
      </ng-template>
      <div class="item">
        <label>Status</label>
        <p-multiSelect [options]="externalStates" [(ngModel)]="statusControl" [filter]="false"
          [defaultLabel]="optionPlaceholder">
          <ng-template let-status pTemplate="item">
            <div>{{status.label}}</div>
          </ng-template>
        </p-multiSelect>
      </div>
      <div class="item">
        <label>ICA/BIN</label>
        <p-multiSelect [options]="icas" [(ngModel)]="ICAControl" [filter]="false" [defaultLabel]="optionPlaceholder">
          <ng-template let-ica pTemplate="item">
            <div>{{ica.label}}</div>
          </ng-template>
        </p-multiSelect>
      </div>
      <div class="item">
        <label>Content Label</label>
        <p-dropdown [options]="contentLabels" [(ngModel)]="contentLabelControl" [style]="{'width':'100%'}">
        </p-dropdown>
      </div>
    </div>
    <div class="column">
      <div class="item">
        <label>Merchant Country</label>
        <p-dropdown [options]="countries" [(ngModel)]="countryControl" [style]="{'width':'100%'}">
        </p-dropdown>
      </div>
      <div class="item" *ngIf="bulkEnabled; else nonBulk">
        <label>Tags (multiple accepted)</label>
        <multi-line-input [(inputArray)]="customerTagControl"></multi-line-input>
      </div>
      <ng-template #nonBulk>
        <div class="item">
          <label>Tags</label>
          <input type="text" [(ngModel)]="customerTagControl">
        </div>
      </ng-template>
      <div class="item">
        <label>Your Action</label>
        <p-multiSelect [options]="customerActions" [(ngModel)]="yourActionControl" [filter]="false"
          [defaultLabel]="optionPlaceholder">
          <ng-template let-cusAction pTemplate="item">
            <div>{{cusAction.label}}</div>
          </ng-template>
        </p-multiSelect>
      </div>
      <div class="item">
        <label>Needs Action</label>
        <input type="checkbox" [(ngModel)]="needsActionControl">
      </div>
    </div>
    <div class="column">
      <div class="item shows-errors">
        <label>Submission Date</label>
        <p-calendar selectionMode="range" [yearNavigator]="true" yearRange="2000:2030" [monthNavigator]="true"
          [(ngModel)]="submittedDateControl" [showIcon]="true"></p-calendar>
        <span *ngIf="!submittedDateValid" class="error">Date must be in range format: MM/DD/YY - MM/DD/YY</span>
      </div>
      <div class="item shows-errors">
        <label>Last Updated By LegitScript On</label>
        <p-calendar selectionMode="range" [yearNavigator]="true" yearRange="2000:2030" [monthNavigator]="true"
          [(ngModel)]="lastLSUpdateControl" [showIcon]="true"></p-calendar>
        <span *ngIf="!lastLSUpdatedValid" class="error">Date must be in range format: MM/DD/YY - MM/DD/YY</span>
      </div>
      <div class="item shows-errors">
        <label>Your Action Date</label>
        <p-calendar selectionMode="range" [yearNavigator]="true" yearRange="2000:2030" [monthNavigator]="true"
          [(ngModel)]="lastCustomerActionControl" [showIcon]="true"></p-calendar>
        <span *ngIf="!lastCustomerValid" class="error">Date must be in range format: MM/DD/YY - MM/DD/YY</span>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button (click)="onSubmit()">Search</button>
    <button (click)="onReset()" class="btn-no-bg-bordered">Reset</button>
  </div>
</div>