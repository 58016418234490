// TODO: NotificationState just came through on a merge. Should we be storing State interfaces inside common-ng-components or common-ts-models?
import { AccountState, AuthenticationState, CertificationsState, QuestionnaireState } from '../reducers';
import { NotificationState, EntitlementsState, UsageState } from '@ls/common-ng-components';
import { MMAppState } from '../modules/merchant-monitoring/reducers/app.state';
import { PushNotificationState } from './push-notification.reducer';
import { SelectableAccountState } from './selectable-account.reducer';
import { createSelector } from '@ngrx/store';
import { TestTransactionState } from '../modules/test-transactions/reducers';
import { MerchantOnboardingAppState } from '../modules/merchant-onboarding/reducers/app.state';

// Pro-tip: When adding a new state make sure the keys match with APP_REDUCERS
export interface AppState {
  authentication: AuthenticationState;
  account: AccountState;
  selectableAccount: SelectableAccountState;
  notifications: NotificationState;
  merchantMonitoring: MMAppState;
  merchantOnboarding: MerchantOnboardingAppState;
  entitlements: EntitlementsState;
  usage: UsageState;
  pushNotifications: PushNotificationState;
  questionnaire: QuestionnaireState;
  certifications: CertificationsState;
  testTransactions: TestTransactionState;
}

// Memoized Selectors
export const fnAuthenticationState = createSelector(
  (state: AppState) => state.authentication,
  (authenticationState) => authenticationState,
);
export const fnAccountState = createSelector(
  (state: AppState) => state.account,
  (accountState) => accountState,
);
export const fnSelectableAccountState = createSelector(
  (state: AppState) => state.selectableAccount,
  (selectableAccountState) => selectableAccountState,
);
export const fnNotificationsState = createSelector(
  (state: AppState) => state.notifications,
  (notificationsState) => notificationsState,
);
export const fnMerchantMonitoringState = createSelector(
  (state: AppState) => state.merchantMonitoring,
  (merchantMonitoringState) => merchantMonitoringState,
);

export const fnMerchantOnboardingState = createSelector(
  (state: AppState) => state.merchantOnboarding,
  (merchantOnboardingState) => merchantOnboardingState,
);

export const fnEntitlementsState = createSelector(
  (state: AppState) => state.entitlements,
  (entitlementsState) => entitlementsState,
);
export const fnUsageState = createSelector(
  (state: AppState) => state.usage,
  (usageState) => usageState,
);
export const fnPushNotificationsState = createSelector(
  (state: AppState) => state.pushNotifications,
  (pushNotificationsState) => pushNotificationsState,
);
export const fnQuestionnaireState = createSelector(
  (state: AppState) => state.questionnaire,
  (questionnaireState) => questionnaireState,
);
export const fnCertificationsState = createSelector(
  (state: AppState) => state.certifications,
  (certificationsState) => certificationsState,
);

export const fnTestTransactionsState = createSelector(
  (state: AppState) => state.testTransactions,
  (testTransactionsState) => testTransactionsState,
);
