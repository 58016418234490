import { MerchantListReducer } from './merchant-list.reducer';
import { MerchantDetailReducer } from './merchant-detail.reducer';
import { DirtyMerchantsReducer } from './dirty-merchants.reducer';
import { MerchantListCheckboxReducer } from './checkbox-merchants.reducer';
import { MerchantSummaryReducer } from '../../merchant-monitoring-v2/reducers/merchant-summary.reducer';
import { HistoryUploadListReducer } from './history-upload.reducer';
import { ContentLabelsReducer } from '../../merchant-monitoring-v2/reducers/content-labels.reducer';
import { TagsReducer } from '../../merchant-monitoring-v2/reducers/tags.reducer';
import { LsTagsReducer } from '../../merchant-monitoring-v2/reducers/lsTags.reducer';
import { IcasReducer } from '../../merchant-monitoring-v2/reducers/icas.reducer';
import { TableFiltersReducer } from '../../merchant-monitoring-v2/reducers/tableFilters.reducer';
import { MerchantMonitoringBulkUploadReducer } from './bulk-upload.reducer';
import { FilterSetsReducer } from '../../merchant-monitoring-v2/reducers/filter-sets.reducer';

export const APP_REDUCERS = {
  contentLabels: ContentLabelsReducer,
  merchantList: MerchantListReducer,
  merchantDetail: MerchantDetailReducer,
  merchantCheckbox: MerchantListCheckboxReducer,
  merchantSummary: MerchantSummaryReducer,
  dirtyMerchants: DirtyMerchantsReducer,
  historyUploadList: HistoryUploadListReducer,
  tags: TagsReducer,
  lsTags: LsTagsReducer,
  icas: IcasReducer,
  tableFilters: TableFiltersReducer,
  savedFilters: FilterSetsReducer,
  bulkUpload: MerchantMonitoringBulkUploadReducer,
};

export * from './app.state';
export * from './checkbox-merchants.reducer';
export * from './dirty-merchants.reducer';
export * from './merchant-detail.reducer';
export * from './merchant-list.reducer';
export * from './history-upload.reducer';
