import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  EnrollService,
  DAATCPricing,
  QuestionnaireInfo,
  HealthcarePricing,
  CbdProductPricing,
  CbdWebsitePricing,
} from '../../services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription, ReplaySubject } from 'rxjs';
import { ProductFamily, DaatcPricebookGuid, DaatcProductGuid, CertificationType } from '@ls/common-ts-models';
import { LegitScriptModalComponent } from '@ls/common-ng-components';

@Component({
  selector: 'daatc-enroll',
  templateUrl: './daatc-enroll.component.html',
  styleUrls: ['./daatc-enroll.component.scss'],
})
export class DaatcEnrollComponent implements OnInit, OnDestroy {
  @ViewChild('pricingModal') public pricingModal: LegitScriptModalComponent;
  public questions: Observable<any>;
  public daatcFormGroup: FormGroup;
  public pricingSubscription: Subscription;
  public questionnaireSubscription: Subscription;
  public pricingSubject: ReplaySubject<DAATCPricing>;
  public apiError = false;
  public recoveryResidence: FormControl = new FormControl('', Validators.required);
  public numFacilities: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[0-9]*'),
    Validators.max(1000),
    Validators.min(1),
  ]);
  public nonProfit: FormControl = new FormControl('', Validators.required);
  public hasThreeOrFewerPractitioners: FormControl = new FormControl('', Validators.required);
  public hasMoreThanOneWebsite: FormControl = new FormControl('', Validators.required);
  public arePractitionersAffiliated: FormControl = new FormControl('', Validators.required);
  public firstTry = true;
  public pricing;
  public pricingInfo = {};
  public recoveryResidenceQuestionText: string;
  public nonProfitQuestionText: string;
  public numFacilitiesQuestionText: string;
  public hasThreeOrFewerPractitionersQuestionText: string;
  public hasMoreThanOneWebsiteQuestionText: string;
  public arePractitionersAffiliatedQuestionText: string;

  private tier;
  private recoveryResidenceQuestion = this.enrollSvc.buildAnsweredQuestion('yes_no');
  private nonProfitQuestion = this.enrollSvc.buildAnsweredQuestion('yes_no');
  private numFacilitiesQuestion = this.enrollSvc.buildAnsweredQuestion('text');
  private hasThreeOrFewerPractitionersQuestion = this.enrollSvc.buildAnsweredQuestion('yes_no');
  private hasMoreThanOneWebsiteQuestion = this.enrollSvc.buildAnsweredQuestion('yes_no');
  private arePractitionersAffiliatedQuestion = this.enrollSvc.buildAnsweredQuestion('yes_no');
  private questionnaireTypeName = 'daatc_enrollment';

  // tslint:disable-next-line: member-ordering
  public questionnaireInfo: QuestionnaireInfo = {
    answeredQuestions: [
      this.recoveryResidenceQuestion,
      this.nonProfitQuestion,
      this.numFacilitiesQuestion,
      this.hasThreeOrFewerPractitionersQuestion,
      this.hasMoreThanOneWebsiteQuestion,
      this.arePractitionersAffiliatedQuestion,
    ],
    questions: [],
    pricingTierSku: '',
    productId: '',
    certificationType: CertificationType.daatc,
    questionnaireTypeName: this.questionnaireTypeName,
  } as QuestionnaireInfo;

  constructor(
    private router: Router,
    private enrollSvc: EnrollService,
  ) {}

  public ngOnInit() {
    this.questions = this.enrollSvc.getEnrollmentQuestions(this.questionnaireTypeName);

    this.questionnaireSubscription = this.questions.subscribe(
      (res) => {
        this.questionnaireInfo.questions = res.questions;
        const recoveryResidenceQ = res.questions.find((question) => question.name === 'recoveryResidence');
        this.recoveryResidenceQuestion.question_id = recoveryResidenceQ.id;
        this.recoveryResidenceQuestionText = recoveryResidenceQ.question_text;
        const nonProfitQ = res.questions.find((question) => question.name === 'nonprofit');
        this.nonProfitQuestion.question_id = nonProfitQ.id;
        this.nonProfitQuestionText = nonProfitQ.question_text;
        const numFacilitiesQuestion = res.questions.find((question) => question.name === 'numberFacilities');
        this.numFacilitiesQuestion.question_id = numFacilitiesQuestion.id;
        this.numFacilitiesQuestionText = numFacilitiesQuestion.question_text;
        const hasThreeOrFewerPractitioners = res.questions.find(
          (question) => question.name === 'hasThreeOrFewerPractitioners',
        );
        this.hasThreeOrFewerPractitionersQuestion.question_id = hasThreeOrFewerPractitioners.id;
        this.hasThreeOrFewerPractitionersQuestionText = hasThreeOrFewerPractitioners.question_text;
        const hasMoreThanOneWebsite = res.questions.find((question) => question.name === 'hasMoreThanOneWebsite');
        this.hasMoreThanOneWebsiteQuestion.question_id = hasMoreThanOneWebsite.id;
        this.hasMoreThanOneWebsiteQuestionText = hasMoreThanOneWebsite.question_text;
        const arePractitionersAffiliatedQuestion = res.questions.find(
          (question) => question.name === 'arePractitionersAffiliatedWithOtherPrograms',
        );
        this.arePractitionersAffiliatedQuestion.question_id = arePractitionersAffiliatedQuestion.id;
        this.arePractitionersAffiliatedQuestionText = arePractitionersAffiliatedQuestion.question_text;
      },
      () => {
        this.apiError = true;
      },
    );

    this.daatcFormGroup = new FormGroup({
      recoveryResidence: this.recoveryResidence,
      nonProfit: this.nonProfit,
      numFacilities: this.numFacilities,
      hasThreeOrFewerPractitioners: this.hasThreeOrFewerPractitioners,
      hasMoreThanOneWebsite: this.hasMoreThanOneWebsite,
      arePractitionersAffiliated: this.arePractitionersAffiliated,
    });

    this.pricingSubject = new ReplaySubject<DAATCPricing>();
    this.pricingSubscription = this.enrollSvc.getPricing(ProductFamily.DAATC).subscribe(
      (pricing: HealthcarePricing | DAATCPricing | CbdProductPricing | CbdWebsitePricing) => {
        const r = pricing as DAATCPricing;
        this.pricingSubject.next(r);
        this.pricing = r;
      },
      () => {
        this.apiError = true;
      },
    );
  }

  public ngOnDestroy() {
    this.pricingSubject.unsubscribe();
    this.pricingSubscription.unsubscribe();
    this.questionnaireSubscription.unsubscribe();
  }

  //  Calculate the pricing for this enrollment based on the current answers of the enrollment form
  //  ADDITIONALLY, update the form question answers so that they get properly progagated to WB, smh
  public calculatePricing(): void {
    this.setTier();

    this.pricingSubject.subscribe((pricing: DAATCPricing) => {
      if (this.invalidForm()) {
        return;
      }
      if (this.tier) {
        this.questionnaireInfo.pricingTierSku = DaatcPricebookGuid[this.tier];
        this.pricingInfo = {
          subscriptionFee: pricing[this.tier].SUBSCRIPTION,
          applicationFee: pricing[this.tier].APPLICATION,
          productId: DaatcProductGuid.APPLICATION,
          subscriptionProductId: DaatcProductGuid.SUBSCRIPTION,
          billingAmount: pricing[this.tier].APPLICATION * this.numFacilities.value,
          recurringAmount: pricing[this.tier].SUBSCRIPTION * this.numFacilities.value,
          perItem: 'facility',
          quantity: this.numFacilities.value,
        };
      }
    });

    //  This hack exists because "calculatePricing" is actually triggered whenever the enrollment form is updated,
    //  so it's the easiest way to ensure that the form's answers are properly updated and submitted when this form is submitted
    this.nonProfitQuestion.answered_inputs[0].answer_text = this.nonProfit.value;
    this.recoveryResidenceQuestion.answered_inputs[0].answer_text = this.recoveryResidence.value;
    this.numFacilitiesQuestion.answered_inputs[0].answer_text = this.numFacilities.value;
    this.hasThreeOrFewerPractitionersQuestion.answered_inputs[0].answer_text = this.hasThreeOrFewerPractitioners.value;
    this.hasMoreThanOneWebsiteQuestion.answered_inputs[0].answer_text = this.hasMoreThanOneWebsite.value;
    this.arePractitionersAffiliatedQuestion.answered_inputs[0].answer_text = this.arePractitionersAffiliated.value;

    this.questionnaireInfo.enrolledItemCount = this.numFacilities.value;
    this.questionnaireInfo.nonprofit = this.nonProfit.value === 'yes';
  }

  public validate(firstTry) {
    this.firstTry = firstTry;
  }

  private invalidForm() {
    return (
      this.recoveryResidence.invalid ||
      this.nonProfit.invalid ||
      this.numFacilities.invalid ||
      this.hasThreeOrFewerPractitioners.invalid ||
      this.hasMoreThanOneWebsite.invalid ||
      this.arePractitionersAffiliated.invalid
    );
  }

  private setTier() {
    const numFacilities = this.numFacilities.value;

    if (!numFacilities) {
      return;
    }

    const qualifiesForIndividualPractitioner =
      this.hasThreeOrFewerPractitioners.value === 'yes' &&
      this.hasMoreThanOneWebsite.value === 'no' &&
      this.arePractitionersAffiliated.value === 'no';

    if (numFacilities === 1 && qualifiesForIndividualPractitioner) {
      this.tier = 'INDIVIDUAL_PRACTITIONER';
      return;
    }

    switch (true) {
      case numFacilities <= 9:
        this.tier = 'ONE_TO_NINE';
        break;
      case numFacilities <= 24:
        this.tier = 'TEN_TO_TWENTYFOUR';
        break;
      case numFacilities <= 49:
        this.tier = 'TWENTYFIVE_TO_FORTYNINE';
        break;
      case numFacilities <= 99:
        this.tier = 'FIFTY_TO_NINETYNINE';
        break;
      case numFacilities >= 100:
        this.tier = 'HUNDRED_PLUS';
        break;
      default:
      // throw an error? this would be a weird case
    }
  }
}
