import { filter, skipWhile, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CertificationsState } from '../../modules/certifications/reducers';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { EntitlementsState, BillingService, LocalStorageService } from '@ls/common-ng-components';
import {
  ProductFamily,
  CertificationType,
  Opportunity,
  Roles,
  inMyLS,
  firstECTry,
  WorkflowStatus,
} from '@ls/common-ts-models';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/reducers';
import { fnCertificationsState, fnEntitlementsState } from 'src/app/reducers/app.state';
import { TestTransactionStatusState } from 'src/app/modules/test-transactions/reducers';

import { DataLayerService } from 'src/app/services/data-layer.service';

@Component({
  selector: 'home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
  public productsApiEnabled: boolean = environment.CONFIG.productsApiEnabled || false;
  public merchantMonitoringEnabled: boolean;
  public hasDaatcCerts = false;
  public hasHealthcareCerts = false;
  public hasCbdCerts = false;
  public mmOpportunity: Opportunity;
  public entitlements$: Observable<EntitlementsState>;
  public certifications$: Observable<CertificationsState>;
  public testTransactionsEnabled = false;
  public testTransactions$: Observable<TestTransactionStatusState>;
  public productLookupPurchased = false;

  public isMerchantOnboardingEnabled = false;

  public products: ProductDisplay[] = [
    {
      logo: 'assets/svg/icons/dataLookup.svg',
      title: 'Database Lookups',
      description: `Ideal for acquirers, payment providers, and internet platforms
                  Confidently identify high risk activity by accessing LegitScript’s product and website classifications, including rogue pharmaceutical websites and LegitScript certified addiction treatment, CBD, and healthcare merchant websites.`,
      productFamily: ProductFamily.PRODUCT_LOOKUP,
      isLoaded: false,
      isPurchased: this.productLookupPurchased,
      click: () => this.navProductLookup(),
    },
    {
      logo: 'assets/svg/icons/merchantMonitoring.svg',
      title: 'Merchant Monitoring',
      description:
        'For acquirers, payment providers, and ISOs. Accurately assess merchant risk so you can take action before it results in fines, damages your reputation, or threatens public safety.',
      productFamily: ProductFamily.MERCHANT_MONITORING,
      isLoaded: false,
      isPurchased: false,
      click: () => this.navMerchantMonitoring(),
    },
    {
      logo: 'assets/svg/icons/merchant-onboarding-blue.svg',
      title: 'Merchant Onboarding',
      description:
        'With our Merchant Onboarding solution, you’ll get real-time merchant risk assessments in under 15 minutes. We detect card brand risk, MCC classification, and perform entity matching, plus we provide enhanced KYB data.',
      productFamily: ProductFamily.MERCHANT_ONBOARDING,
      isLoaded: true,
      isPurchased: this.isMerchantOnboardingEnabled,
      click: () => this.navMerchantOnboarding(),
    },
  ];

  public FAMILY = {
    PRODUCT_LOOKUP: ProductFamily.PRODUCT_LOOKUP,
    WEBSITE_LOOKUP: ProductFamily.WEBSITE_LOOKUP,
    PRODUCT_API: ProductFamily.PRODUCT_API,
    MERCHANT_MONITORING: ProductFamily.MERCHANT_MONITORING,
  };

  public currentRenewalDate: Date;

  private destroyed$: Subject<boolean> = new Subject();
  public isNotCertificationUser = true;
  public showLegacyText = false;
  public newMyLSPortalURL = environment.CONFIG.experienceCloudURL;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private billingService: BillingService,
    private localStorageSvc: LocalStorageService,
    private _dataLayerService: DataLayerService,
  ) {}

  public ngOnInit() {
    const authUser = this.localStorageSvc.getAuthenticatedUser();
    if (environment.CONFIG.merchantMonitoringEnabled) {
      this.merchantMonitoringEnabled = true;
    } else {
      this.merchantMonitoringEnabled = authUser.roles && authUser.roles.includes(Roles.cpv2MMUser);
    }

    // If the localstorage properties inMyLS & firstECTry are not present, that means this is the
    // first time the use hits Home Component after login, from here we're valdiating if the user
    // has Salesforce role, if it does, we're setting the properties and we're redireting them
    // to Salesforce Experience Cloud
    if (!localStorage.getItem(inMyLS) || !localStorage.getItem(firstECTry)) {
      if (authUser.roles && authUser.roles.includes(Roles.cpv2AccountOwnerSF)) {
        localStorage.setItem(inMyLS, 'false');
        localStorage.setItem(firstECTry, 'true');
        window.location.href = this.newMyLSPortalURL;
      }
    } else {
      localStorage.setItem(inMyLS, 'true');
    }

    // all certification users should have either the cpv2UserSF or cpv2AccountOwnerSF role
    if (
      authUser.roles &&
      (authUser.roles.includes(Roles.cpv2AccountOwnerSF) || authUser.roles.includes(Roles.cpv2UserSF))
    ) {
      this.isNotCertificationUser = false;
    }

    this.entitlements$ = this.store.select(fnEntitlementsState).pipe(
      skipWhile((entitlements) => entitlements.pending),
      takeUntil(this.destroyed$),
    );

    this.entitlements$.subscribe((entitlements) => {
      if (entitlements && entitlements.products && entitlements.products[ProductFamily.MERCHANT_MONITORING]) {
        this.mmOpportunity = entitlements.products[ProductFamily.MERCHANT_MONITORING].opportunity;
      }

      if (entitlements && entitlements.products && entitlements.products[ProductFamily.MERCHANT_ONBOARDING]) {
        this.isMerchantOnboardingEnabled = true;
      }

      this.products
        .filter((product) => product.title === 'Database Lookups' || product.title === 'Merchant Monitoring')
        .forEach((product) => {
          product.isLoaded = true;
          product.isPurchased = !!entitlements.products[product.productFamily];
        });

      if (entitlements?.products?.[ProductFamily.TEST_TRANSACTIONS]) {
        if (
          (authUser.roles ?? []).includes(Roles.testTransactionAnalyst) ||
          (authUser.roles ?? []).includes(Roles.testTransactionUser)
        ) {
          if (!this.products.find((p) => p.title === 'Test Transactions')) {
            this.products.push({
              logo: 'assets/svg/icons/billing.svg',
              title: 'Test Transactions',
              description:
                'Test transactions are designed to monitor and request transactions from specified URLs. It operates by continuously scanning these URLs for any new or updated transaction data, ensuring real-time tracking and reporting.',
              productFamily: ProductFamily.TEST_TRANSACTIONS,
              isPurchased: true,
              isLoaded: true,
              click: () => this.navTestTransactions(),
              buttonText: 'Open',
            });
          }
        }
      }
    });

    this.certifications$ = this.store.select(fnCertificationsState).pipe(
      skipWhile((certs) => certs.pending),
      filter((certs) => !!certs),
      takeUntil(this.destroyed$),
    );

    this.certifications$.subscribe((certs) => {
      this.hasDaatcCerts = certs.list?.some((cert) => cert.type === CertificationType.daatc);
      this.hasHealthcareCerts = certs.list?.some((cert) => cert.type === CertificationType.healthcare);
      this.hasCbdCerts = certs.list?.some(
        (cert) => cert.type === CertificationType.cbdProduct || cert.type === CertificationType.cbdWebsite,
      );
      // filter the list of certifications to remove any certifications that are not renewing and have been migrated
      const legacyCertifications = (certs.list ?? []).filter((cert) => {
        if (cert.workflowStatus === WorkflowStatus.Renewing && !cert.migratedDate) {
          return cert;
        }
      });
      // if there are any certifications that are not renewing and have not been migrated, show the legacy text
      this.showLegacyText = legacyCertifications.length > 0;
    });
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public navCerts(type?: string) {
    const navArray = ['services', 'merchant-certification'];

    if (!this.hasDaatcCerts && type === 'daatc') {
      navArray.push('enroll', 'daatc');
    } else if (!this.hasHealthcareCerts && type === 'healthcare') {
      navArray.push('enroll', 'healthcare');
    } else if (!this.hasCbdCerts && type === 'cbd') {
      navArray.push('enroll', 'cbd');
    }

    this.router.navigate(navArray);
  }

  public navProductAPI() {
    this.router.navigate(['services', 'api', 'products']);
  }

  public navProductLookup() {
    this.router.navigate(['services', 'lookups', 'data']);
  }

  public navMerchantMonitoring() {
    this.router.navigate(['/merchant-monitoring/merchant-list']);
  }

  public navMerchantOnboarding() {
    this.router.navigate(['/merchant-onboarding/']);
  }

  public navTestTransactions() {
    this.router.navigate(['services', 'test-transactions']);
  }
}

interface ProductDisplay {
  logo: string;
  title: string;
  productFamily: ProductFamily;
  description: string;
  isLoaded: boolean;
  isPurchased: boolean;
  click: () => void;
  buttonText?: string;
}
