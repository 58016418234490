import { Component } from '@angular/core';

import { ValidationResult } from '../../SharedCustomTypes/validation-result';
import { EditableFieldComponent } from '../abstract/editable-field.component';
import { UrlRegEx } from '@ls/common-ng-components';

@Component({
  selector: '../abstract/editable-field-url',
  templateUrl: '../abstract/editable-field.component.html',
  styleUrls: ['../abstract/editable-field.component.scss'],
})
export class EditableFieldUrlComponent extends EditableFieldComponent {
  /**
   * Does the url follow our valid url syntax? i.e. example.com not example
   * @param url The url to validate
   */
  public static IsValidUrl(url: string): ValidationResult {
    const urlValidationError = new ValidationResult(false, 'Invalid URL');
    return UrlRegEx().test(url) ? ValidationResult.Success : urlValidationError;
  }
  /**
   * Does the url start with a http or https prefix?
   * @param url The url to validate
   */
  public static IsHyperText(url: string): ValidationResult {
    const hyperTextFormatFailure = new ValidationResult(false, 'Must begin with http:// or https://');
    const hyperTextRegex = new RegExp('^https?://', 'i');
    const isHyperText = hyperTextRegex.test(url);

    return isHyperText ? ValidationResult.Success : hyperTextFormatFailure;
  }

  public validate(): ValidationResult[] {
    const validationResults = new Array<ValidationResult>();

    const optional = EditableFieldComponent.IsEmptyOption(this.editableField, this.required);
    if (optional.success) {
      return validationResults;
    }

    validationResults.push(EditableFieldComponent.IsFilledOut(this.editableField, this.required));
    validationResults.push(EditableFieldComponent.IsWithinFieldLength(this.editableField, this.maxLength));
    validationResults.push(EditableFieldUrlComponent.IsValidUrl(this.editableField));
    validationResults.push(EditableFieldUrlComponent.IsHyperText(this.editableField));
    return validationResults;
  }
}
