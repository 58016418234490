<div class="entry-main payment">
  <div class="entry-container entry-container-large payment-thanks" *ngIf="invoiceSuccess && !processingPayment">
    <i class="fa fa-check-circle success"></i>
    <h1>Thank You!</h1>
    <div>
      <p>You will shortly receive an invoice via email for 
        <span *ngIf="!paymentPlan.billingSummary.discountedDueToday; else dueTodayDiscount">
          {{ paymentPlan.billingSummary.dueToday | currency:'USD':'symbol':'1.2-4' }}
        </span>
        <ng-template #dueTodayDiscount>
          <span>{{ paymentPlan.billingSummary.discountedDueToday | currency:'USD':'symbol':'1.2-4' }}</span>
        </ng-template>. Please follow the instructions in the email to make payment via Stripe within the next 15 days.
      </p>
    </div>
    <div class="continue-wrapper">
      <button [disabled]="loading" (click)="continueToQuestionnaire()">
        Continue
      </button>
      <p-progressSpinner
      *ngIf="loading"
      [style]="{ width: '50px', height: '50px', position: 'absolute', left: '0', right: '0' }"
      ></p-progressSpinner>
    </div>
  </div>

  <div class="entry-container entry-container-large">
    <div class="paymentPage">
      <div class="payment-form-column">

        <div class="payment-header">
          <h1 class="span6">Payment</h1>
        </div>

        <p>The application fee for certifying the number of items you provided is above the limit we allow for credit card payment.</p>        

        <form id="payment-method-form">
          <p *ngIf="processingPayment">We are processing your application invoice. Please do not navigate away from this page until the transaction is complete.</p>
          <div class="form-container">
            <div class="form-actions span1 flex-row">
              <button (click)="submit()" [disabled]="processingPayment">
                {{processingPayment ? "Processing..." : " Pay by Invoice"}}
              </button>
              <p-progressSpinner *ngIf="processingPayment" [style]="{ width: '2em', height: '2em' }"></p-progressSpinner>
            </div>
          </div>
        </form>
      </div>

      <amount-due-panel [paymentPlan]="paymentPlan" [pricingInfo]="pricingInfo" [invoiceAccount]="true"></amount-due-panel>
    </div>
  </div>
</div>
