<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div>
  <a class="header-link" (click)="refreshClick()">
    {{ mmProduct.apiKeyExists ? 'Regenerate' : 'Generate' }} API Key
  </a>
</div>
<legitscript-modal class="no-footer" #confirmAPIKeyRefreshModal [draggable]=false [resizable]=false [responsive]=true
[modal]=true autoAlign=true [width]=800 height=auto>
  <div class="dialog-body" style="overflow:auto; text-align: left;" *ngIf="mmProduct.apiKeyExists; else firstKey">
    <div class="modal-height-match" *ngIf="!refreshSubmitting && !refreshComplete; else refreshing">
      <h1>Regenerate API Key</h1>

      <p>
        HITTING THE REGENERATE KEY BUTTON WILL IMMEDIATELY INVALIDATE YOUR API KEY.
      </p>
      <p>
        This action will invalidate your existing API key. If you have active integrations, they will not be able to authenticate to the API until they are re-configured with the new key.
      </p>
      <p>
        This action cannot be reversed.
      </p>
      <p>
        <button type="button" (click)="performRefreshAPIKey()">Regenerate Key</button>
        <button type="button" class="btn-no-bg" (click)="confirmAPIKeyRefreshModal.hideDialog()">Cancel</button>
      </p>
    </div>
    <ng-template #refreshing>
      <div class="small-spinner-wrapper {{refreshSubmitting ? 'show' : ''}}">
        <p-progressSpinner [style]="{ width: '3em', height: '3em' }"></p-progressSpinner>
        <h3>Refreshing your API key, please wait...</h3>
      </div>
      <div class="modal-height-match">
        <h1>Your new API key is: <pre>{{newApiKey}}</pre></h1>
        <p>Remember: This key will <em>not</em> be available again after you close this modal, so be sure to store it somewhere safe!</p>
        <p>
          <button type="button" (click)="hideRefreshModal()">Close Modal</button>
        </p>
      </div>
    </ng-template>
  </div>
  <ng-template #firstKey>
    <div class="dialog-body" style="overflow:auto; text-align: left;">
      <div class="modal-height-match" *ngIf="!refreshSubmitting && !refreshComplete; else refreshing">
        <h1>Generate API Key</h1>

        <p>
          Created: N/A
        </p>

        <p>
          To generate an API Key hit the Generate Key button.
        </p>
        <p>
          <button type="button" (click)="performRefreshAPIKey()">Generate Key</button>
          <button type="button" class="btn-no-bg" (click)="confirmAPIKeyRefreshModal.hideDialog()">Cancel</button>
        </p>
      </div>
      <ng-template #refreshing>
        <div class="small-spinner-wrapper {{refreshSubmitting ? 'show' : ''}}">
          <p-progressSpinner [style]="{ width: '3em', height: '3em' }"></p-progressSpinner>
          <h3>Generating your API key, please wait...</h3>
        </div>
        <div class="modal-height-match">
          <h1>Your new API key is: <pre>{{newApiKey}}</pre></h1>
          <p>Remember: This key will <em>not</em> be available again after you close this modal, so be sure to store it somewhere safe!</p>
          <p>
            <button type="button" (click)="hideRefreshModal()">Close Modal</button>
          </p>
        </div>
      </ng-template>
    </div>
  </ng-template>
</legitscript-modal>