    <div class="products-api-docs" style="padding: 30px;">
        <section class="widget">
            <div class="widget-header">
                <i class="fa fa-book"></i>
                <h1>Documentation</h1>
            </div>
            <div class="widget-body" id="docs-container">
<!-- START OF GENERATED SWAGGER HTML -->
<!-- DO NOT REFORMAT!!! -->
<nav id="nav" role="navigation">
  <h5>Topics</h5>
  <a href="#introduction">Introduction</a>
  <a href="#authentication">Authentication</a>
  <a href="#ratelimit">Rate Limits</a>
  <h5>Paths</h5>
  <a href="#operation--v3-merchant-post"> POST /v3/merchant </a>
  <a href="#operation--v3-merchant--merchantId--put"> PUT /v3/merchant/{{'{'}}merchantId{{'}'}} </a>
  <a href="#operation--v3-merchant--merchantId--get"> GET /v3/merchant/{{'{'}}merchantId{{'}'}} </a>
  <a href="#operation-v3-merchant--merchantId--action-post"> POST v3/merchant/{{'{'}}merchantId{{'}'}}/action </a>
  <a href="#operation-v3-merchants-get"> GET v3/merchants </a>
  <h5>Schema Definitions</h5>
  <a href="#definition-CustomerAction"> CustomerAction </a>
  <a href="#definition-MerchantCreate"> MerchantCreate </a>
  <a href="#definition-MerchantUpdate"> MerchantUpdate </a>
  <a href="#definition-MerchantGet"> MerchantGet </a>
  <a href="#definition-MerchantsGet"> MerchantsGet </a>
</nav>
<article>
  <h1 class="doc-title">LegitScript Merchant Monitoring API
    <span>Reference</span>
  </h1>
  <section id="introduction">
    <p>LegitScript&#39;s Merchant Monitoring API allows you to interact with the Merchant Monitoring services through your own client-side platforms. Based on the principles of REST, it is easy to use and test. You can upload merchant data for our analysts to review, check on the status of currently monitored merchants, view currently monitored merchants, and more. All responses are delivered in JSON format. To ensure privacy, the API is served over HTTPS, and unencrypted HTTP is not supported.</p>
    <dl>
      <dt>API Endpoint</dt>
      <dd class="code">https://api.legitscript.com/payments/v3</dd>
      <dt>Response Content-Types:</dt>
      <dd>application/json</dd>
      <dt>Schemes:</dt>
      <dd>https</dd>
      <dt>Version:</dt>
      <dd>3.0.0</dd>
    </dl>
  </section>
  <section id="authentication">
    <h1 id="authentication">Authentication</h1>
    <section>
      <h3>api_key</h3>
      <dl>
        <dt>type</dt>
        <dd>apiKey</dd>
        <dt>in</dt>
        <dd>header</dd>
        <dt>name</dt>
        <dd>x-api-key</dd>
      </dl>
    </section>
    <section id="ratelimit">
      <h1 id="ratelimit">Rate Limits</h1>
      <section>
        <ul>
          <li>Merchant creates per day per API key : 250,000</li>
          <li>Merchant updates per day per API key: 400,000</li>
          <li>No more than one update per merchant every 15 minutes.</li>
        </ul>
      </section>
    </section>
  </section>
  <v3-migration-guide></v3-migration-guide>
  <h1>Paths</h1>
  <span id="path--v3-merchant"></span>
  <section id="operations">
    <h2 class="operation-title">
      <span class="operation-name">
        <span class="operation-name">POST</span>
        <span class="operation-path">/v3/merchant</span>
      </span>
    </h2>
    <section class="swagger-operation-description">
      <p>Create a merchant application for our analysts to review.</p>
    </section>
    <section id="swagger-request-params">
      <table>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Provided-As</th>
          <th class="mobile-hide">Description</th>
        </tr>
        <tr>
          <td>X-Api-Key</td>
          <td>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td>Yes</td>
          <td>header</td>
          <td class="mobile-hide">
            <p>Your Customer API Key</p>
          </td>
        </tr>
      </table>
    </section>
    <section class="swagger-request-body">
      <div class="prop-row">
        <div class="prop-name">
          <div class="swagger-request-model"> Request body schema:
            <span class=""> MerchantCreate </span>
          </div>
        </div>
        <div class="prop-value columns small-6">
          <!-- <div class="swagger-request-description"> -->Request body description: The merchant to create.
          <!-- </div> -->
        </div>
      </div>
    </section>
    <div class="doc-examples">
      <br>
      <section>
        <h5>Request Content-Types:
          <span>application/json</span>
        </h5>
        <h5>Request Body Example</h5>
        <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;merchant_id&quot;</span>: <span class="hljs-string">&quot;abc123&quot;</span>,
  <span class="hljs-attr">&quot;merchant_url&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;merchant_category_code&quot;</span>: <span class="hljs-number">4789</span>,
  <span class="hljs-attr">&quot;merchant_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;merchant_descriptor&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
  <span class="hljs-attr">&quot;merchant_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchant 123 LLC&quot;</span>,
  <span class="hljs-attr">&quot;merchant_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;merchant_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;merchant_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;merchant_region&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;merchant_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;merchant_email&quot;</span>: <span class="hljs-string">&quot;john.doe@fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;additional_merchant_urls&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.com, https://www.fakemerchant123.net&quot;</span>,
  <span class="hljs-attr">&quot;associated_email_addresses&quot;</span>: <span class="hljs-string">&quot;jane.doe@fakemerchant123.biz, jesse.doe@fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_name&quot;</span>: <span class="hljs-string">&quot;John Doe&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;dba_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchants LLC&quot;</span>,
  <span class="hljs-attr">&quot;dba_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;dba_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;dba_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;dba_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;dba_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;dba_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;ica&quot;</span>: <span class="hljs-string">&quot;55555, 666666&quot;</span>,
  <span class="hljs-attr">&quot;acquiring_bank&quot;</span>: <span class="hljs-string">&quot;Fake Bank&quot;</span>,
  <span class="hljs-attr">&quot;iso&quot;</span>: <span class="hljs-string">&quot;Anderson and Fake&quot;</span>,
  <span class="hljs-attr">&quot;sub_iso&quot;</span>: <span class="hljs-string">&quot;Durgan, Schmeler and Fake&quot;</span>,
  <span class="hljs-attr">&quot;agent&quot;</span>: <span class="hljs-string">&quot;Fake Agent Inc&quot;</span>
{{'}'}}
</code></pre>
        <!-- </div> -->
      </section>
    </div>
    <section id="responses">
      <table>
        <tr>
          <th>Response code</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>202 Accepted</td>
          <td>
            <p>Your request was accepted--further processing may be required.</p>
          </td>
        </tr>
        <tr>
          <td>400 Bad Request</td>
          <td>
            <p>You are missing a required parameter or one of the arguments you provided was invalid. A validation message will accompany this error. Please check your arguments and try again.</p>
          </td>
        </tr>
        <tr>
          <td>403 Forbidden</td>
          <td>
            <p>Either there is an invalid or missing API key, or, the merchant is locked (status is either Terminated or Inactive). If the merchant is locked, a validation message will accompany this error.</p>
          </td>
        </tr>
        <tr>
          <td>429 Too Many Requests</td>
          <td>
            <ul>
              <li>This API key has a limit of 250,000 merchant create requests per API key per day. Please try again tomorrow.</li>
              <li>This API key has a limit of 400,000 merchant update requests per API key per day. Please try again tomorrow.</li>
              <li>Merchant updated too frequently. Wait 15 minutes and try your request again.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>500 Internal Server Error</td>
          <td>
            <p>Something went wrong on our end. We have been notified and will look into the error. For help, please contact
              <a href="mailto:merchant.monitoring@legitscript.com">merchant.monitoring@legitscript.com</a>.</p>
          </td>
        </tr>
      </table>
      <div class="doc-examples">
        <h5>Response Content-Types:
          <span>application/json</span>
        </h5>
        <section>
          <h5>Response Example
            <span>(202 Accepted)</span>
          </h5>
          <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;correlation_id&quot;</span>: <span class="hljs-string">&quot;89d4dcbb-5381-4e89-975c-a805e9f0b6d6&quot;</span>,
  <span class="hljs-attr">&quot;message&quot;</span>: <span class="hljs-string">&quot;Merchant application accepted.&quot;</span>
{{'}'}}
</code></pre>
          <!-- </div> -->
        </section>
      </div>
    </section>
  </section>
  <span id="path--v3-merchant--merchantId-"></span>
  <section id="operations">
    <h2 class="operation-title">
      <span class="operation-name">
        <span class="operation-name">PUT</span>
        <span class="operation-path">/v3/merchant/{{'{'}}merchantId{{'}'}}</span>
      </span>
    </h2>
    <section class="swagger-operation-description">
      <p>Make changes to a merchant application you submitted to LegitScript.</p>
    </section>
    <section id="swagger-request-params">
      <table>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Provided-As</th>
          <th class="mobile-hide">Description</th>
        </tr>
        <tr>
          <td>X-Api-Key</td>
          <td>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td>Yes</td>
          <td>header</td>
          <td class="mobile-hide">
            <p>Your Customer API Key</p>
          </td>
        </tr>
        <tr>
          <td>merchantId</td>
          <td>
            <span class="json-property-type">integer</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td>Yes</td>
          <td>path</td>
          <td class="mobile-hide">
            <p>The ID of the merchant record.</p>
          </td>
        </tr>
        <tr>
          <td>idType</td>
          <td>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td></td>
          <td>query</td>
          <td class="mobile-hide">
            <p>The type of ID you are passing in. Can be lsdb, external or correlation.</p>
          </td>
        </tr>
      </table>
    </section>
    <section class="swagger-request-body">
      <div class="prop-row">
        <div class="prop-name">
          <div class="swagger-request-model"> Request body schema (required):
            <span class=""> MerchantUpdate </span>
          </div>
        </div>
        <div class="prop-value columns small-6">
          <!-- <div class="swagger-request-description"> -->Request body description: The merchant to update.
          <!-- </div> -->
        </div>
      </div>
    </section>
    <div class="doc-examples">
      <br>
      <section>
        <h5>Request Content-Types:
          <span>application/json</span>
        </h5>
        <h5>Request Body Example</h5>
        <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;merchant_id&quot;</span>: <span class="hljs-string">&quot;abc123&quot;</span>,
  <span class="hljs-attr">&quot;merchant_url&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;merchant_category_code&quot;</span>: <span class="hljs-number">4789</span>,
  <span class="hljs-attr">&quot;merchant_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;merchant_descriptor&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
  <span class="hljs-attr">&quot;merchant_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchant 123 LLC&quot;</span>,
  <span class="hljs-attr">&quot;merchant_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;merchant_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;merchant_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;merchant_region&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;merchant_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;merchant_email&quot;</span>: <span class="hljs-string">&quot;john.doe@fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;additional_merchant_urls&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.com, https://www.fakemerchant123.net&quot;</span>,
  <span class="hljs-attr">&quot;associated_email_addresses&quot;</span>: <span class="hljs-string">&quot;jane.doe@fakemerchant123.biz, jesse.doe@fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_name&quot;</span>: <span class="hljs-string">&quot;John Doe&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;dba_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchants LLC&quot;</span>,
  <span class="hljs-attr">&quot;dba_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;dba_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;dba_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;dba_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;dba_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;dba_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;ica&quot;</span>: <span class="hljs-string">&quot;55555, 666666&quot;</span>,
  <span class="hljs-attr">&quot;acquiring_bank&quot;</span>: <span class="hljs-string">&quot;Fake Bank&quot;</span>,
  <span class="hljs-attr">&quot;iso&quot;</span>: <span class="hljs-string">&quot;Anderson and Fake&quot;</span>,
  <span class="hljs-attr">&quot;sub_iso&quot;</span>: <span class="hljs-string">&quot;Durgan, Schmeler and Fake&quot;</span>,
  <span class="hljs-attr">&quot;agent&quot;</span>: <span class="hljs-string">&quot;Fake Agent Inc&quot;</span>
{{'}'}}
</code></pre>
        <!-- </div> -->
      </section>
    </div>
    <section id="responses">
      <table>
        <tr>
          <th>Response code</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>202 Accepted</td>
          <td>
            <p>Your request was accepted--further processing may be required.</p>
          </td>
        </tr>
        <tr>
          <td>400 Bad Request</td>
          <td>
            <p>You are missing a required parameter or one of the arguments you provided was invalid. A validation message will accompany this error. Please check your arguments and try again.</p>
          </td>
        </tr>
        <tr>
          <td>403 Forbidden</td>
          <td>
            <p>Either there is an invalid or missing API key, or, the merchant is locked (status is either Terminated or Inactive). If the merchant is locked, a validation message will accompany this error.</p>
          </td>
        </tr>
        <tr>
          <td>429 Too Many Requests</td>
          <td>
            <ul>
              <li>This API key has a limit of 250,000 merchant create requests per API key per day. Please try again tomorrow.</li>
              <li>This API key has a limit of 400,000 merchant update requests per API key per day. Please try again tomorrow.</li>
              <li>Merchant updated too frequently. Wait 15 minutes and try your request again.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>500 Internal Server Error</td>
          <td>
            <p>Something went wrong on our end. We have been notified and will look into the error. For help, please contact
              <a href="mailto:merchant.monitoring@legitscript.com">merchant.monitoring@legitscript.com</a>.</p>
          </td>
        </tr>
      </table>
      <div class="doc-examples">
        <h5>Response Content-Types:
          <span>application/json</span>
        </h5>
        <section>
          <h5>Response Example
            <span>(202 Accepted)</span>
          </h5>
          <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;correlation_id&quot;</span>: <span class="hljs-string">&quot;89d4dcbb-5381-4e89-975c-a805e9f0b6d6&quot;</span>,
  <span class="hljs-attr">&quot;message&quot;</span>: <span class="hljs-string">&quot;Merchant update accepted.&quot;</span>
{{'}'}}
</code></pre>
          <!-- </div> -->
        </section>
      </div>
    </section>
  </section>
  <section id="operations">
    <h2 class="operation-title">
      <span class="operation-name">
        <span class="operation-name">GET</span>
        <span class="operation-path">/v3/merchant/{{'{'}}merchantId{{'}'}}</span>
      </span>
    </h2>
    <section class="swagger-operation-description">
      <p>Get a merchant analysis record.</p>
    </section>
    <section id="swagger-request-params">
      <table>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Provided-As</th>
          <th class="mobile-hide">Description</th>
        </tr>
        <tr>
          <td>X-Api-Key</td>
          <td>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td>Yes</td>
          <td>header</td>
          <td class="mobile-hide">
            <p>Your Customer API Key</p>
          </td>
        </tr>
        <tr>
          <td>merchantId</td>
          <td>
            <span class="json-property-type">integer</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td>Yes</td>
          <td>path</td>
          <td class="mobile-hide">
            <p>The ID of the merchant record.</p>
          </td>
        </tr>
        <tr>
          <td>idType</td>
          <td>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td></td>
          <td>query</td>
          <td class="mobile-hide">
            <p>The type of ID you are passing in. Can be lsdb, external or correlation.</p>
          </td>
        </tr>
      </table>
    </section>
    <div class="doc-examples"></div>
    <section id="responses">
      <table>
        <tr>
          <th>Response code</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>200 OK</td>
          <td>
            <p>The request was successful</p>
          </td>
        </tr>
        <tr>
          <td>400 Bad Request</td>
          <td>
            <p>You are missing a required parameter or one of the arguments you provided was invalid. A validation message will accompany this error. Please check your arguments and try again.</p>
          </td>
        </tr>
        <tr>
          <td>403 Forbidden</td>
          <td>
            <p>Invalid or missing API key.</p>
          </td>
        </tr>
        <tr>
          <td>500 Internal Server Error</td>
          <td>
            <p>Something went wrong on our end. We have been notified and will look into the error. For help, please contact
              <a href="mailto:merchant.monitoring@legitscript.com">merchant.monitoring@legitscript.com</a>.</p>
          </td>
        </tr>
      </table>
      <div class="doc-examples">
        <h5>Response Content-Types:
          <span>application/json</span>
        </h5>
        <section>
          <h5>Response Example
            <span>(200 OK)</span>
          </h5>
          <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;created_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>,
  <span class="hljs-attr">&quot;customer_action&quot;</span>: {{'{'}}
    <span class="hljs-attr">&quot;action&quot;</span>: <span class="hljs-string">&quot;cleared&quot;</span>,
    <span class="hljs-attr">&quot;comment&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
    <span class="hljs-attr">&quot;created_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>
  {{'}'}},
  <span class="hljs-attr">&quot;id&quot;</span>: <span class="hljs-number">123</span>,
  <span class="hljs-attr">&quot;ls_action&quot;</span>: {{'{'}}
    <span class="hljs-attr">&quot;published_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>,
    <span class="hljs-attr">&quot;actions&quot;</span>: [
      <span class="hljs-string">&quot;moderate_risk&quot;</span>,
      <span class="hljs-string">&quot;tl_confirmed&quot;</span>
    ],
    <span class="hljs-attr">&quot;content_labels&quot;</span>: [
      {{'{'}}
        <span class="hljs-attr">&quot;id&quot;</span>: <span class="hljs-number">1</span>,
        <span class="hljs-attr">&quot;label&quot;</span>: <span class="hljs-string">&quot;Pharmaceuticals&quot;</span>
      {{'}'}}
    ],
    <span class="hljs-attr">&quot;reason&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>
  {{'}'}},
  <span class="hljs-attr">&quot;merchant_details&quot;</span>: {{'{'}}
    <span class="hljs-attr">&quot;acquiring_bank&quot;</span>: <span class="hljs-string">&quot;Fake Bank&quot;</span>,
    <span class="hljs-attr">&quot;additional_merchant_urls&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.com, https://www.fakemerchant123.net&quot;</span>,
    <span class="hljs-attr">&quot;agent&quot;</span>: <span class="hljs-string">&quot;Fake Agent Inc&quot;</span>,
    <span class="hljs-attr">&quot;associated_email_addresses&quot;</span>: <span class="hljs-string">&quot;jane.doe@fakemerchant123.biz, jesse.doe@fakemerchant123.biz&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_name&quot;</span>: <span class="hljs-string">&quot;John Doe&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
    <span class="hljs-attr">&quot;dba_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
    <span class="hljs-attr">&quot;dba_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
    <span class="hljs-attr">&quot;dba_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchants LLC&quot;</span>,
    <span class="hljs-attr">&quot;dba_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
    <span class="hljs-attr">&quot;dba_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
    <span class="hljs-attr">&quot;dba_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
    <span class="hljs-attr">&quot;dba_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
    <span class="hljs-attr">&quot;ica&quot;</span>: <span class="hljs-string">&quot;55555, 666666&quot;</span>,
    <span class="hljs-attr">&quot;iso&quot;</span>: <span class="hljs-string">&quot;Anderson and Fake&quot;</span>,
    <span class="hljs-attr">&quot;merchant_category_code&quot;</span>: <span class="hljs-number">4789</span>,
    <span class="hljs-attr">&quot;merchant_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
    <span class="hljs-attr">&quot;merchant_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
    <span class="hljs-attr">&quot;merchant_descriptor&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
    <span class="hljs-attr">&quot;merchant_email&quot;</span>: <span class="hljs-string">&quot;john.doe@fakemerchant123.biz&quot;</span>,
    <span class="hljs-attr">&quot;merchant_id&quot;</span>: <span class="hljs-string">&quot;abc123&quot;</span>,
    <span class="hljs-attr">&quot;merchant_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchant 123 LLC&quot;</span>,
    <span class="hljs-attr">&quot;merchant_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
    <span class="hljs-attr">&quot;merchant_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
    <span class="hljs-attr">&quot;merchant_region&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
    <span class="hljs-attr">&quot;merchant_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
    <span class="hljs-attr">&quot;merchant_url&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.biz&quot;</span>,
    <span class="hljs-attr">&quot;status&quot;</span>: <span class="hljs-string">&quot;monitored&quot;</span>,
    <span class="hljs-attr">&quot;sub_iso&quot;</span>: <span class="hljs-string">&quot;Durgan, Schmeler and Fake&quot;</span>
  {{'}'}},
  <span class="hljs-attr">&quot;website_registrar&quot;</span>: {{'{'}}
    <span class="hljs-attr">&quot;iana_number&quot;</span>: <span class="hljs-number">1408</span>,
    <span class="hljs-attr">&quot;name&quot;</span>: <span class="hljs-string">&quot;Discount Domains LTD&quot;</span>,
    <span class="hljs-attr">&quot;risk_level&quot;</span>: <span class="hljs-number">3</span>
  {{'}'}}
{{'}'}}
</code></pre>
          <!-- </div> -->
        </section>
      </div>
    </section>
  </section>
  <span id="path-v3-merchant--merchantId--action"></span>
  <section id="operations">
    <h2 class="operation-title">
      <span class="operation-name">
        <span class="operation-name">POST</span>
        <span class="operation-path">v3/merchant/{{'{'}}merchantId{{'}'}}/action</span>
      </span>
    </h2>
    <section class="swagger-operation-description">
      <p>Notify LegitScript of the action you have taken on a merchant. The id path parameter is LegitScript’s internal ID for the merchant.</p>
    </section>
    <section id="swagger-request-params">
      <table>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Provided-As</th>
          <th class="mobile-hide">Description</th>
        </tr>
        <tr>
          <td>X-Api-Key</td>
          <td>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td>Yes</td>
          <td>header</td>
          <td class="mobile-hide">
            <p>Your Customer API Key</p>
          </td>
        </tr>
        <tr>
          <td>merchantId</td>
          <td>
            <span class="json-property-type">integer</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td>Yes</td>
          <td>path</td>
          <td class="mobile-hide">
            <p>The ID of the merchant record.</p>
          </td>
        </tr>
        <tr>
          <td>idType</td>
          <td>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td></td>
          <td>query</td>
          <td class="mobile-hide">
            <p>The type of ID you are passing in. Can be lsdb, external or correlation.</p>
          </td>
        </tr>
      </table>
    </section>
    <section class="swagger-request-body">
      <div class="prop-row">
        <div class="prop-name">
          <div class="swagger-request-model"> Request body schema (required):
            <span class=""> CustomerAction </span>
          </div>
        </div>
        <div class="prop-value columns small-6">
          <!-- <div class="swagger-request-description"> -->Request body description: The action taken by the customer.
          <!-- </div> -->
        </div>
      </div>
    </section>
    <div class="doc-examples">
      <br>
      <section>
        <h5>Request Content-Types:
          <span>application/json</span>
        </h5>
        <h5>Request Body Example</h5>
        <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;action&quot;</span>: <span class="hljs-string">&quot;cleared&quot;</span>,
  <span class="hljs-attr">&quot;comment&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>
{{'}'}}
</code></pre>
        <!-- </div> -->
      </section>
    </div>
    <section id="responses">
      <table>
        <tr>
          <th>Response code</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>202 Accepted</td>
          <td>
            <p>The customer action was accepted for the merchant</p>
          </td>
        </tr>
        <tr>
          <td>400 Bad Request</td>
          <td>
            <p>You are missing a required parameter or one of the arguments you provided was invalid. A validation message will accompany this error. Please check your arguments and try again.</p>
          </td>
        </tr>
        <tr>
          <td>403 Forbidden</td>
          <td>
            <p>Either there is an invalid or missing API key, or, the merchant is locked (status is either Terminated or Inactive). If the merchant is locked, a validation message will accompany this error.</p>
          </td>
        </tr>
        <tr>
          <td>429 Too Many Requests</td>
          <td>
            <ul>
              <li>This API key has a limit of 250,000 merchant create requests per API key per day. Please try again tomorrow.</li>
              <li>This API key has a limit of 400,000 merchant update requests per API key per day. Please try again tomorrow.</li>
              <li>Merchant updated too frequently. Wait 15 minutes and try your request again.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>500 Internal Server Error</td>
          <td>
            <p>Something went wrong on our end. We have been notified and will look into the error. For help, please contact
              <a href="mailto:merchant.monitoring@legitscript.com">merchant.monitoring@legitscript.com</a>.</p>
          </td>
        </tr>
      </table>
      <div class="doc-examples">
        <h5>Response Content-Types:
          <span>application/json</span>
        </h5>
        <section>
          <h5>Response Example
            <span>(202 Accepted)</span>
          </h5>
          <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;message&quot;</span>: <span class="hljs-string">&quot;Customer action accepted for merchant 27955dd1-2a73...&quot;</span>
{{'}'}}
</code></pre>
          <!-- </div> -->
        </section>
      </div>
    </section>
  </section>
  <span id="path-v3-merchants"></span>
  <section id="operations">
    <h2 class="operation-title">
      <span class="operation-name">
        <span class="operation-name">GET</span>
        <span class="operation-path">v3/merchants</span>
      </span>
    </h2>
    <section class="swagger-operation-description">
      <p>Retrieves a list of merchants filtered by a set of query parameters.</p>
    </section>
    <section id="swagger-request-params">
      <table>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Provided-As</th>
          <th class="mobile-hide">Description</th>
        </tr>
        <tr>
          <td>X-Api-Key</td>
          <td>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td>Yes</td>
          <td>header</td>
          <td class="mobile-hide">
            <p>Your Customer API Key</p>
          </td>
        </tr>
        <tr>
          <td>page</td>
          <td>
            <span class="json-property-type">integer</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td></td>
          <td>query</td>
          <td class="mobile-hide">
            <p>The page of results to request.</p>
          </td>
        </tr>
        <tr>
          <td>itemsPerPage</td>
          <td>
            <span class="json-property-type">integer</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td></td>
          <td>query</td>
          <td class="mobile-hide">
            <p>The number of items to return per page</p>
          </td>
        </tr>
        <tr>
          <td>updated_earliest</td>
          <td>
            <span class="json-property-type">string</span>
            <span class="json-property-format">(date-time)</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td></td>
          <td>query</td>
          <td class="mobile-hide">
            <p>Get all merchants that were updated after this date. ISO 8601 format. UTC Timezone.</p>
          </td>
        </tr>
        <tr>
          <td>updated_latest</td>
          <td>
            <span class="json-property-type">string</span>
            <span class="json-property-format">(date-time)</span>
            <span class="json-property-range" title="Value limits"></span>
          </td>
          <td></td>
          <td>query</td>
          <td class="mobile-hide">
            <p>Get all merchants that were updated before this date. ISO 8601 format. UTC Timezone.</p>
          </td>
        </tr>
      </table>
    </section>
    <div class="doc-examples"></div>
    <section id="responses">
      <table>
        <tr>
          <th>Response code</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>200 OK</td>
          <td>
            <p>The request was successful.</p>
          </td>
        </tr>
        <tr>
          <td>400 Bad Request</td>
          <td>
            <p>You are missing a required parameter or one of the arguments you provided was invalid. A validation message will accompany this error. Please check your arguments and try again.</p>
          </td>
        </tr>
        <tr>
          <td>403 Forbidden</td>
          <td>
            <p>Invalid or missing API key.</p>
          </td>
        </tr>
        <tr>
          <td>500 Internal Server Error</td>
          <td>
            <p>Something went wrong on our end. We have been notified and will look into the error. For help, please contact
              <a href="mailto:merchant.monitoring@legitscript.com">merchant.monitoring@legitscript.com</a>.</p>
          </td>
        </tr>
      </table>
      <div class="doc-examples">
        <h5>Response Content-Types:
          <span>application/json</span>
        </h5>
        <section>
          <h5>Response Example
            <span>(200 OK)</span>
          </h5>
          <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;pagination&quot;</span>: {{'{'}}
    <span class="hljs-attr">&quot;totalRecords&quot;</span>: <span class="hljs-number">1000</span>,
    <span class="hljs-attr">&quot;totalPages&quot;</span>: <span class="hljs-number">50</span>,
    <span class="hljs-attr">&quot;pageNumber&quot;</span>: <span class="hljs-number">1</span>,
    <span class="hljs-attr">&quot;startPosition&quot;</span>: <span class="hljs-number">1</span>,
    <span class="hljs-attr">&quot;endPosition&quot;</span>: <span class="hljs-number">20</span>
  {{'}'}},
  <span class="hljs-attr">&quot;merchants&quot;</span>: [
    {{'{'}}
      <span class="hljs-attr">&quot;created_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>,
      <span class="hljs-attr">&quot;customer_action&quot;</span>: {{'{'}}
        <span class="hljs-attr">&quot;action&quot;</span>: <span class="hljs-string">&quot;cleared&quot;</span>,
        <span class="hljs-attr">&quot;comment&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
        <span class="hljs-attr">&quot;created_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>
      {{'}'}},
      <span class="hljs-attr">&quot;id&quot;</span>: <span class="hljs-number">123</span>,
      <span class="hljs-attr">&quot;ls_action&quot;</span>: {{'{'}}
        <span class="hljs-attr">&quot;published_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>,
        <span class="hljs-attr">&quot;actions&quot;</span>: [
          <span class="hljs-string">&quot;moderate_risk&quot;</span>,
          <span class="hljs-string">&quot;tl_confirmed&quot;</span>
        ],
        <span class="hljs-attr">&quot;content_labels&quot;</span>: [
          {{'{'}}
            <span class="hljs-attr">&quot;id&quot;</span>: <span class="hljs-number">1</span>,
            <span class="hljs-attr">&quot;label&quot;</span>: <span class="hljs-string">&quot;Pharmaceuticals&quot;</span>
          {{'}'}}
        ],
        <span class="hljs-attr">&quot;reason&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>
      {{'}'}},
      <span class="hljs-attr">&quot;merchant_details&quot;</span>: {{'{'}}
        <span class="hljs-attr">&quot;acquiring_bank&quot;</span>: <span class="hljs-string">&quot;Fake Bank&quot;</span>,
        <span class="hljs-attr">&quot;additional_merchant_urls&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.com, https://www.fakemerchant123.net&quot;</span>,
        <span class="hljs-attr">&quot;agent&quot;</span>: <span class="hljs-string">&quot;Fake Agent Inc&quot;</span>,
        <span class="hljs-attr">&quot;associated_email_addresses&quot;</span>: <span class="hljs-string">&quot;jane.doe@fakemerchant123.biz, jesse.doe@fakemerchant123.biz&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_name&quot;</span>: <span class="hljs-string">&quot;John Doe&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
        <span class="hljs-attr">&quot;dba_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
        <span class="hljs-attr">&quot;dba_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
        <span class="hljs-attr">&quot;dba_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchants LLC&quot;</span>,
        <span class="hljs-attr">&quot;dba_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
        <span class="hljs-attr">&quot;dba_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
        <span class="hljs-attr">&quot;dba_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
        <span class="hljs-attr">&quot;dba_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
        <span class="hljs-attr">&quot;ica&quot;</span>: <span class="hljs-string">&quot;55555, 666666&quot;</span>,
        <span class="hljs-attr">&quot;iso&quot;</span>: <span class="hljs-string">&quot;Anderson and Fake&quot;</span>,
        <span class="hljs-attr">&quot;merchant_category_code&quot;</span>: <span class="hljs-number">4789</span>,
        <span class="hljs-attr">&quot;merchant_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
        <span class="hljs-attr">&quot;merchant_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
        <span class="hljs-attr">&quot;merchant_descriptor&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
        <span class="hljs-attr">&quot;merchant_email&quot;</span>: <span class="hljs-string">&quot;john.doe@fakemerchant123.biz&quot;</span>,
        <span class="hljs-attr">&quot;merchant_id&quot;</span>: <span class="hljs-string">&quot;abc123&quot;</span>,
        <span class="hljs-attr">&quot;merchant_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchant 123 LLC&quot;</span>,
        <span class="hljs-attr">&quot;merchant_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
        <span class="hljs-attr">&quot;merchant_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
        <span class="hljs-attr">&quot;merchant_region&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
        <span class="hljs-attr">&quot;merchant_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
        <span class="hljs-attr">&quot;merchant_url&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.biz&quot;</span>,
        <span class="hljs-attr">&quot;status&quot;</span>: <span class="hljs-string">&quot;monitored&quot;</span>,
        <span class="hljs-attr">&quot;sub_iso&quot;</span>: <span class="hljs-string">&quot;Durgan, Schmeler and Fake&quot;</span>
      {{'}'}},
      <span class="hljs-attr">&quot;website_registrar&quot;</span>: {{'{'}}
        <span class="hljs-attr">&quot;iana_number&quot;</span>: <span class="hljs-number">1408</span>,
        <span class="hljs-attr">&quot;name&quot;</span>: <span class="hljs-string">&quot;Discount Domains LTD&quot;</span>,
        <span class="hljs-attr">&quot;risk_level&quot;</span>: <span class="hljs-number">3</span>
      {{'}'}}
    {{'}'}}
  ]
{{'}'}}
</code></pre>
          <!-- </div> -->
        </section>
      </div>
    </section>
  </section>
  <h1>Schema Definitions</h1>
  <section id="definition-CustomerAction" class="definition panel">
    <h2 class="panel-title"> CustomerAction: object
      <!-- <span class="json-property-type"><span class="json-property-type">object</span>
      <span class="json-property-range" title="Value limits"></span>
      
      
      </span> -->
    </h2>
    <div class="doc-row">
      <div class="doc-copy">
        <dl>
          <dt data-property-name="action">
            <span class="json-property-name">action:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-enum" title="Possible values">
              <span class="json-property-enum-item">cleared</span>,
              <span class="json-property-enum-item">inactive</span>,
              <span class="json-property-enum-item">first_warning</span>,
              <span class="json-property-enum-item">immediate_termination</span>,
              <span class="json-property-enum-item">reset</span>,
              <span class="json-property-enum-item">second_warning</span>,
              <span class="json-property-enum-item">termination_notice_fifteen</span>,
              <span class="json-property-enum-item">termination_notice_ten</span>,
              <span class="json-property-enum-item">termination_notice_thirty</span>,
              <span class="json-property-enum-item">third_warning</span>
            </span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-required"></span>
          </dt>
          <dd> </dd>
          <dt data-property-name="comment">
            <span class="json-property-name">comment:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-required"></span>
          </dt>
          <dd> </dd>
        </dl>
      </div>
      <div class="doc-examples">
        <section>
          <h5>Example</h5>
          <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;action&quot;</span>: <span class="hljs-string">&quot;cleared&quot;</span>,
  <span class="hljs-attr">&quot;comment&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>
{{'}'}}
</code></pre>
          <!-- </div> -->
        </section>
      </div>
    </div>
  </section>
  <section id="definition-MerchantCreate" class="definition panel">
    <h2 class="panel-title"> MerchantCreate: object
      <!-- <span class="json-property-type"><span class="json-property-type">object</span>
      <span class="json-property-range" title="Value limits"></span>
      
      
      </span> -->
    </h2>
    <div class="doc-row">
      <div class="doc-copy">
        <dl>
          <dt data-property-name="merchant_id" class="has-description">
            <span class="json-property-name">merchant_id:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (1 to 50 chars) </span>
            <span class="json-property-required"></span>
          </dt>
          <dd> </dd>
          <dt data-property-name="merchant_url" class="has-description">
            <span class="json-property-name">merchant_url:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (at least 1 chars) </span>
          </dt>
          <dd> URL of the merchant’s website. </dd>
          <dt data-property-name="merchant_category_code" class="has-description">
            <span class="json-property-name">merchant_category_code:</span>
            <span class="json-property-type">integer</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd> Merchant category code for the merchant’s business. </dd>
          <dt data-property-name="merchant_country" class="has-description">
            <span class="json-property-name">merchant_country:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (1 to 255 chars) </span>
            <span class="json-property-required"></span>
          </dt>
          <dd> Merchant’s legal business country. Must be a valid ISO or ISO3 country code. </dd>
          <dt data-property-name="merchant_descriptor" class="has-description">
            <span class="json-property-name">merchant_descriptor:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 500 chars) </span>
          </dt>
          <dd> Merchant descriptor included in the billing statement for a transaction. </dd>
          <dt data-property-name="merchant_name" class="has-description">
            <span class="json-property-name">merchant_name:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 255 chars) </span>
          </dt>
          <dd> Merchant’s legal business name. </dd>
          <dt data-property-name="merchant_phone" class="has-description">
            <span class="json-property-name">merchant_phone:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
          </dt>
          <dd> Merchant’s legal business phone number. </dd>
          <dt data-property-name="merchant_street" class="has-description">
            <span class="json-property-name">merchant_street:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 150 chars) </span>
          </dt>
          <dd> Merchant’s legal business street name and number. </dd>
          <dt data-property-name="merchant_city" class="has-description">
            <span class="json-property-name">merchant_city:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 150 chars) </span>
          </dt>
          <dd> Merchant’s legal business city. </dd>
          <dt data-property-name="merchant_region" class="has-description">
            <span class="json-property-name">merchant_region:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 50 chars) </span>
          </dt>
          <dd> Merchant’s legal business state or province. </dd>
          <dt data-property-name="merchant_postal_code" class="has-description">
            <span class="json-property-name">merchant_postal_code:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
          </dt>
          <dd> Merchant’s legal business postal code. </dd>
          <dt data-property-name="merchant_email" class="has-description">
            <span class="json-property-name">merchant_email:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 150 chars) </span>
          </dt>
          <dd> Merchant's email address. </dd>
          <dt data-property-name="additional_merchant_urls" class="has-description">
            <span class="json-property-name">additional_merchant_urls:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd> Other URLs associated with the merchant. </dd>
          <dt data-property-name="associated_email_addresses" class="has-description">
            <span class="json-property-name">associated_email_addresses:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 500 chars) </span>
          </dt>
          <dd> Other email addresses associated with the merchant. </dd>
          <dt data-property-name="business_owner_name" class="has-description">
            <span class="json-property-name">business_owner_name:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s name. </dd>
          <dt data-property-name="business_owner_phone" class="has-description">
            <span class="json-property-name">business_owner_phone:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s phone number. </dd>
          <dt data-property-name="business_owner_street" class="has-description">
            <span class="json-property-name">business_owner_street:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s street name and number. </dd>
          <dt data-property-name="business_owner_city" class="has-description">
            <span class="json-property-name">business_owner_city:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s city. </dd>
          <dt data-property-name="business_owner_state" class="has-description">
            <span class="json-property-name">business_owner_state:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s state or province. </dd>
          <dt data-property-name="business_owner_postal_code" class="has-description">
            <span class="json-property-name">business_owner_postal_code:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
          </dt>
          <dd> Business owner’s postal code. </dd>
          <dt data-property-name="business_owner_country" class="has-description">
            <span class="json-property-name">business_owner_country:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s country. </dd>
          <dt data-property-name="dba_name" class="has-description">
            <span class="json-property-name">dba_name:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" name for the merchant. </dd>
          <dt data-property-name="dba_street" class="has-description">
            <span class="json-property-name">dba_street:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" street name and number for the merchant. </dd>
          <dt data-property-name="dba_city" class="has-description">
            <span class="json-property-name">dba_city:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" city for the merchant. </dd>
          <dt data-property-name="dba_state" class="has-description">
            <span class="json-property-name">dba_state:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" state or province for the merchant. </dd>
          <dt data-property-name="dba_postal_code" class="has-description">
            <span class="json-property-name">dba_postal_code:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
          </dt>
          <dd> "doing business as" postal code for the merchant. </dd>
          <dt data-property-name="dba_country" class="has-description">
            <span class="json-property-name">dba_country:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" country for the merchant. </dd>
          <dt data-property-name="dba_phone" class="has-description">
            <span class="json-property-name">dba_phone:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" phone number for the merchant. </dd>
          <dt data-property-name="ica" class="has-description">
            <span class="json-property-name">ica:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> ICA associated with the merchant record. BIN (optional) can be added after the ICA if separated by a comma. </dd>
          <dt data-property-name="acquiring_bank" class="has-description">
            <span class="json-property-name">acquiring_bank:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Acquiring bank for the merchant. </dd>
          <dt data-property-name="iso" class="has-description">
            <span class="json-property-name">iso:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> ISO for the merchant. </dd>
          <dt data-property-name="sub_iso" class="has-description">
            <span class="json-property-name">sub_iso:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Sub-ISO for the merchant. </dd>
          <dt data-property-name="agent" class="has-description">
            <span class="json-property-name">agent:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Agent for the merchant. </dd>
        </dl>
      </div>
      <div class="doc-examples">
        <section>
          <h5>Example</h5>
          <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;merchant_id&quot;</span>: <span class="hljs-string">&quot;abc123&quot;</span>,
  <span class="hljs-attr">&quot;merchant_url&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;merchant_category_code&quot;</span>: <span class="hljs-number">4789</span>,
  <span class="hljs-attr">&quot;merchant_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;merchant_descriptor&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
  <span class="hljs-attr">&quot;merchant_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchant 123 LLC&quot;</span>,
  <span class="hljs-attr">&quot;merchant_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;merchant_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;merchant_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;merchant_region&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;merchant_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;merchant_email&quot;</span>: <span class="hljs-string">&quot;john.doe@fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;additional_merchant_urls&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.com, https://www.fakemerchant123.net&quot;</span>,
  <span class="hljs-attr">&quot;associated_email_addresses&quot;</span>: <span class="hljs-string">&quot;jane.doe@fakemerchant123.biz, jesse.doe@fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_name&quot;</span>: <span class="hljs-string">&quot;John Doe&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;dba_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchants LLC&quot;</span>,
  <span class="hljs-attr">&quot;dba_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;dba_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;dba_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;dba_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;dba_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;dba_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;ica&quot;</span>: <span class="hljs-string">&quot;55555, 666666&quot;</span>,
  <span class="hljs-attr">&quot;acquiring_bank&quot;</span>: <span class="hljs-string">&quot;Fake Bank&quot;</span>,
  <span class="hljs-attr">&quot;iso&quot;</span>: <span class="hljs-string">&quot;Anderson and Fake&quot;</span>,
  <span class="hljs-attr">&quot;sub_iso&quot;</span>: <span class="hljs-string">&quot;Durgan, Schmeler and Fake&quot;</span>,
  <span class="hljs-attr">&quot;agent&quot;</span>: <span class="hljs-string">&quot;Fake Agent Inc&quot;</span>
{{'}'}}
</code></pre>
          <!-- </div> -->
        </section>
      </div>
    </div>
  </section>
  <section id="definition-MerchantUpdate" class="definition panel">
    <h2 class="panel-title"> MerchantUpdate: object
      <!-- <span class="json-property-type"><span class="json-property-type">object</span>
      <span class="json-property-range" title="Value limits"></span>
      
      
      </span> -->
    </h2>
    <div class="doc-row">
      <div class="doc-copy">
        <dl>
          <dt data-property-name="merchant_id" class="has-description">
            <span class="json-property-name">merchant_id:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (1 to 50 chars) </span>
          </dt>
          <dd> ID of the merchant record (different from the LSDB ID). </dd>
          <dt data-property-name="merchant_url" class="has-description">
            <span class="json-property-name">merchant_url:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (at least 1 chars) </span>
          </dt>
          <dd> URL of the merchant’s website. </dd>
          <dt data-property-name="merchant_category_code" class="has-description">
            <span class="json-property-name">merchant_category_code:</span>
            <span class="json-property-type">integer</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd> Merchant category code for the merchant’s business. </dd>
          <dt data-property-name="merchant_country" class="has-description">
            <span class="json-property-name">merchant_country:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (1 to 255 chars) </span>
          </dt>
          <dd> Merchant’s legal business country. Must be a valid ISO or ISO3 country code. </dd>
          <dt data-property-name="merchant_descriptor" class="has-description">
            <span class="json-property-name">merchant_descriptor:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 500 chars) </span>
          </dt>
          <dd> Merchant descriptor included in the billing statement for a transaction. </dd>
          <dt data-property-name="merchant_name" class="has-description">
            <span class="json-property-name">merchant_name:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 255 chars) </span>
          </dt>
          <dd> Merchant’s legal business name. </dd>
          <dt data-property-name="merchant_phone" class="has-description">
            <span class="json-property-name">merchant_phone:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 255 chars) </span>
          </dt>
          <dd> Merchant’s legal business name. </dd>
          <dt data-property-name="merchant_street" class="has-description">
            <span class="json-property-name">merchant_street:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 150 chars) </span>
          </dt>
          <dd> Merchant’s legal business street name and number. </dd>
          <dt data-property-name="merchant_city" class="has-description">
            <span class="json-property-name">merchant_city:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 150 chars) </span>
          </dt>
          <dd> Merchant’s legal business city. </dd>
          <dt data-property-name="merchant_region" class="has-description">
            <span class="json-property-name">merchant_region:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 50 chars) </span>
          </dt>
          <dd> Merchant’s legal business state or province. </dd>
          <dt data-property-name="merchant_postal_code" class="has-description">
            <span class="json-property-name">merchant_postal_code:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
          </dt>
          <dd> Merchant’s legal business postal code. </dd>
          <dt data-property-name="merchant_email" class="has-description">
            <span class="json-property-name">merchant_email:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 150 chars) </span>
          </dt>
          <dd> Merchant's email address. </dd>
          <dt data-property-name="additional_merchant_urls" class="has-description">
            <span class="json-property-name">additional_merchant_urls:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd> Other URLs associated with the merchant. </dd>
          <dt data-property-name="associated_email_addresses" class="has-description">
            <span class="json-property-name">associated_email_addresses:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 500 chars) </span>
          </dt>
          <dd> Other email addresses associated with the merchant. </dd>
          <dt data-property-name="business_owner_name" class="has-description">
            <span class="json-property-name">business_owner_name:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s name. </dd>
          <dt data-property-name="business_owner_phone" class="has-description">
            <span class="json-property-name">business_owner_phone:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s phone number. </dd>
          <dt data-property-name="business_owner_street" class="has-description">
            <span class="json-property-name">business_owner_street:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s street name and number. </dd>
          <dt data-property-name="business_owner_city" class="has-description">
            <span class="json-property-name">business_owner_city:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s city. </dd>
          <dt data-property-name="business_owner_state" class="has-description">
            <span class="json-property-name">business_owner_state:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s state or province. </dd>
          <dt data-property-name="business_owner_postal_code" class="has-description">
            <span class="json-property-name">business_owner_postal_code:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
          </dt>
          <dd> Business owner’s postal code. </dd>
          <dt data-property-name="business_owner_country" class="has-description">
            <span class="json-property-name">business_owner_country:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Business owner’s country. </dd>
          <dt data-property-name="dba_name" class="has-description">
            <span class="json-property-name">dba_name:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" name for the merchant. </dd>
          <dt data-property-name="dba_street" class="has-description">
            <span class="json-property-name">dba_street:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" street name and number for the merchant. </dd>
          <dt data-property-name="dba_city" class="has-description">
            <span class="json-property-name">dba_city:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" city for the merchant. </dd>
          <dt data-property-name="dba_state" class="has-description">
            <span class="json-property-name">dba_state:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" state or province for the merchant. </dd>
          <dt data-property-name="dba_postal_code" class="has-description">
            <span class="json-property-name">dba_postal_code:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
          </dt>
          <dd> "doing business as" postal code for the merchant. </dd>
          <dt data-property-name="dba_country" class="has-description">
            <span class="json-property-name">dba_country:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" country for the merchant. </dd>
          <dt data-property-name="dba_phone" class="has-description">
            <span class="json-property-name">dba_phone:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> "doing business as" phone number for the merchant. </dd>
          <dt data-property-name="ica" class="has-description">
            <span class="json-property-name">ica:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> ICA associated with the merchant record. BIN (optional) can be added after the ICA if separated by a comma. </dd>
          <dt data-property-name="acquiring_bank" class="has-description">
            <span class="json-property-name">acquiring_bank:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Acquiring bank for the merchant. </dd>
          <dt data-property-name="iso" class="has-description">
            <span class="json-property-name">iso:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> ISO for the merchant. </dd>
          <dt data-property-name="sub_iso" class="has-description">
            <span class="json-property-name">sub_iso:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Sub-ISO for the merchant. </dd>
          <dt data-property-name="agent" class="has-description">
            <span class="json-property-name">agent:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-range" title="Value limits"></span>
            <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
          </dt>
          <dd> Agent for the merchant. </dd>
        </dl>
      </div>
      <div class="doc-examples">
        <section>
          <h5>Example</h5>
          <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;merchant_id&quot;</span>: <span class="hljs-string">&quot;abc123&quot;</span>,
  <span class="hljs-attr">&quot;merchant_url&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;merchant_category_code&quot;</span>: <span class="hljs-number">4789</span>,
  <span class="hljs-attr">&quot;merchant_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;merchant_descriptor&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
  <span class="hljs-attr">&quot;merchant_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchant 123 LLC&quot;</span>,
  <span class="hljs-attr">&quot;merchant_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;merchant_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;merchant_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;merchant_region&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;merchant_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;merchant_email&quot;</span>: <span class="hljs-string">&quot;john.doe@fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;additional_merchant_urls&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.com, https://www.fakemerchant123.net&quot;</span>,
  <span class="hljs-attr">&quot;associated_email_addresses&quot;</span>: <span class="hljs-string">&quot;jane.doe@fakemerchant123.biz, jesse.doe@fakemerchant123.biz&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_name&quot;</span>: <span class="hljs-string">&quot;John Doe&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;business_owner_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;dba_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchants LLC&quot;</span>,
  <span class="hljs-attr">&quot;dba_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
  <span class="hljs-attr">&quot;dba_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
  <span class="hljs-attr">&quot;dba_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
  <span class="hljs-attr">&quot;dba_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
  <span class="hljs-attr">&quot;dba_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
  <span class="hljs-attr">&quot;dba_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
  <span class="hljs-attr">&quot;ica&quot;</span>: <span class="hljs-string">&quot;55555, 666666&quot;</span>,
  <span class="hljs-attr">&quot;acquiring_bank&quot;</span>: <span class="hljs-string">&quot;Fake Bank&quot;</span>,
  <span class="hljs-attr">&quot;iso&quot;</span>: <span class="hljs-string">&quot;Anderson and Fake&quot;</span>,
  <span class="hljs-attr">&quot;sub_iso&quot;</span>: <span class="hljs-string">&quot;Durgan, Schmeler and Fake&quot;</span>,
  <span class="hljs-attr">&quot;agent&quot;</span>: <span class="hljs-string">&quot;Fake Agent Inc&quot;</span>
{{'}'}}
</code></pre>
          <!-- </div> -->
        </section>
      </div>
    </div>
  </section>
  <section id="definition-MerchantGet" class="definition panel">
    <h2 class="panel-title"> MerchantGet: object
      <!-- <span class="json-property-type"><span class="json-property-type">object</span>
      <span class="json-property-range" title="Value limits"></span>
      
      
      </span> -->
    </h2>
    <div class="doc-row">
      <div class="doc-copy">
        <dl>
          <dt data-property-name="created_at" class="has-description">
            <span class="json-property-name">created_at:</span>
            <span class="json-property-type">string</span>
            <span class="json-property-format">(date-time)</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd> Date and time at which you submitted the merchant details to LegitScript. </dd>
          <dt data-property-name="customer_action" class="has-description">
            <span class="json-property-name">customer_action:</span>
            <span class="json-property-type">object</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd> Contains the latest information you have reported about your actions related to the merchant.
            <dl>
              <dt data-property-name="action">
                <span class="json-property-name">action:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-enum" title="Possible values">
                  <span class="json-property-enum-item">cleared</span>,
                  <span class="json-property-enum-item">inactive</span>,
                  <span class="json-property-enum-item">reset</span>,
                  <span class="json-property-enum-item">immediate_termination</span>,
                  <span class="json-property-enum-item">termination_notice_ten</span>,
                  <span class="json-property-enum-item">termination_notice_fifteen</span>,
                  <span class="json-property-enum-item">termination_notice_thirty</span>,
                  <span class="json-property-enum-item">first_warning</span>,
                  <span class="json-property-enum-item">second_warning</span>,
                  <span class="json-property-enum-item">third_warning</span>
                </span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
              <dt data-property-name="comment">
                <span class="json-property-name">comment:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
              <dt data-property-name="created_at">
                <span class="json-property-name">created_at:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-format">(date-time)</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
            </dl>
          </dd>
          <dt data-property-name="id" class="has-description">
            <span class="json-property-name">id:</span>
            <span class="json-property-type">integer</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd> LegitScript internal ID of the merchant (LSDB ID). </dd>
          <dt data-property-name="ls_action" class="has-description">
            <span class="json-property-name">ls_action:</span>
            <span class="json-property-type">object</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd> Contains information on LegitScript’s latest determination regarding the merchant.
            <dl>
              <dt data-property-name="published_at" class="has-description">
                <span class="json-property-name">published_at:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-format">(date-time)</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> Date and time at which the merchant status is set, or when LegitScript analysts record their determination. </dd>
              <dt data-property-name="actions" class="has-description">
                <span class="json-property-name">actions:</span>
                <span class="json-property-type">array</span>
                <span class="json-property-enum" title="Possible values">
                  <span class="json-property-enum-item">bram</span>,
                  <span class="json-property-enum-item">gbpp</span>,
                  <span class="json-property-enum-item">high_risk</span>,
                  <span class="json-property-enum-item">low_risk</span>,
                  <span class="json-property-enum-item">moderate_risk</span>,
                  <span class="json-property-enum-item">offline_or_parked</span>,
                  <span class="json-property-enum-item">rogue</span>,
                  <span class="json-property-enum-item">terms</span>,
                  <span class="json-property-enum-item">tl_confirmed</span>,
                  <span class="json-property-enum-item">tl_suspected</span>
                </span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> Determinations made by LegitScript. Possible values include one or more of:
                <br>bram — LegitScript has found this merchant to be in violation of BRAM
                <br>gbpp — LegitScript has found this merchant to be in violation of GBPP
                <br>high_risk — the merchant appears to be engaged in high-risk commercial activity;
                <br>low_risk — The merchant appears to make only compliant, or nearly-compliant claims, and appears to be low risk
                <br>moderate_risk — This merchant presents reduced, but not negligible, risk of regulatory scrutiny due to the types of products offered or health care claims
                <br>offline_or_parked — The merchant’s website is offline, parked (i.e., displaying only placeholder content), or is otherwise inoperable
                <br>rogue — LegitScript has found this merchant to be rogue
                <br>terms — LegitScript has found this merchant to be in violation of your company’s terms
                <br>tl_confirmed — LegitScript has found that this merchant is engaged in transaction laundering
                <br>tl_suspected — LegitScript believes there is a risk that this merchant is engaged in transaction laundering </dd>
              <dt data-property-name="content_labels" class="has-description">
                <span class="json-property-name">content_labels:</span>
                <span class="json-property-type">object[]</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> Content labels describe the content found on a merchant URL or the merchant’s dedicated business activities.
                <section class="json-schema-array-items">
                  <span class="json-property-type">object</span>
                  <span class="json-property-range" title="Value limits"></span>
                  <div class="json-inner-schema">
                    <dl>
                      <dt data-property-name="id">
                        <span class="json-property-name">id:</span>
                        <span class="json-property-type">integer</span>
                        <span class="json-property-range" title="Value limits"></span>
                      </dt>
                      <dd> </dd>
                      <dt data-property-name="label">
                        <span class="json-property-name">label:</span>
                        <span class="json-property-type">string</span>
                        <span class="json-property-range" title="Value limits"></span>
                      </dt>
                      <dd> </dd>
                    </dl>
                  </div>
                </section>
              </dd>
              <dt data-property-name="reason" class="has-description">
                <span class="json-property-name">reason:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> A brief explanation of the decision for this LegitScript action. </dd>
            </dl>
          </dd>
          <dt data-property-name="merchant_details" class="has-description">
            <span class="json-property-name">merchant_details:</span>
            <span class="json-property-type">object</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd> All the details you submitted to LegitScript for this merchant.
            <dl>
              <dt data-property-name="acquiring_bank" class="has-description">
                <span class="json-property-name">acquiring_bank:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> Acquiring bank for the merchant. </dd>
              <dt data-property-name="additional_merchant_urls" class="has-description">
                <span class="json-property-name">additional_merchant_urls:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> Other URLs associated with the merchant. </dd>
              <dt data-property-name="agent" class="has-description">
                <span class="json-property-name">agent:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> Agent for the merchant. </dd>
              <dt data-property-name="associated_email_addresses" class="has-description">
                <span class="json-property-name">associated_email_addresses:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 500 chars) </span>
              </dt>
              <dd> Other email addresses associated with the merchant. </dd>
              <dt data-property-name="business_owner_city" class="has-description">
                <span class="json-property-name">business_owner_city:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> Business owner’s city. </dd>
              <dt data-property-name="business_owner_country" class="has-description">
                <span class="json-property-name">business_owner_country:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> Business owner’s country. </dd>
              <dt data-property-name="business_owner_name" class="has-description">
                <span class="json-property-name">business_owner_name:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> Business owner’s name. </dd>
              <dt data-property-name="business_owner_phone" class="has-description">
                <span class="json-property-name">business_owner_phone:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> Business owner’s phone number. </dd>
              <dt data-property-name="business_owner_postal_code" class="has-description">
                <span class="json-property-name">business_owner_postal_code:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
              </dt>
              <dd> Business owner’s postal code. </dd>
              <dt data-property-name="business_owner_state" class="has-description">
                <span class="json-property-name">business_owner_state:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> Business owner’s state or province. </dd>
              <dt data-property-name="business_owner_street" class="has-description">
                <span class="json-property-name">business_owner_street:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> Business owner’s street name and number. </dd>
              <dt data-property-name="dba_city" class="has-description">
                <span class="json-property-name">dba_city:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> "doing business as" city for the merchant. </dd>
              <dt data-property-name="dba_country" class="has-description">
                <span class="json-property-name">dba_country:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> "doing business as" country for the merchant. </dd>
              <dt data-property-name="dba_name" class="has-description">
                <span class="json-property-name">dba_name:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> "doing business as" name for the merchant. </dd>
              <dt data-property-name="dba_phone" class="has-description">
                <span class="json-property-name">dba_phone:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> "doing business as" phone number for the merchant. </dd>
              <dt data-property-name="dba_postal_code" class="has-description">
                <span class="json-property-name">dba_postal_code:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
              </dt>
              <dd> "doing business as" postal code for the merchant. </dd>
              <dt data-property-name="dba_state" class="has-description">
                <span class="json-property-name">dba_state:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> "doing business as" state or province for the merchant. </dd>
              <dt data-property-name="dba_street" class="has-description">
                <span class="json-property-name">dba_street:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> "doing business as" street name and number for the merchant. </dd>
              <dt data-property-name="ica" class="has-description">
                <span class="json-property-name">ica:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> ICA associated with the merchant record. BIN (optional) can be added after the ICA if separated by a comma. </dd>
              <dt data-property-name="iso" class="has-description">
                <span class="json-property-name">iso:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> ISO for the merchant. </dd>
              <dt data-property-name="merchant_category_code" class="has-description">
                <span class="json-property-name">merchant_category_code:</span>
                <span class="json-property-type">integer</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> Merchant category code for the merchant’s business. </dd>
              <dt data-property-name="merchant_city" class="has-description">
                <span class="json-property-name">merchant_city:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 150 chars) </span>
              </dt>
              <dd> Merchant’s legal business city. </dd>
              <dt data-property-name="merchant_country" class="has-description">
                <span class="json-property-name">merchant_country:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (1 to 255 chars) </span>
              </dt>
              <dd> Merchant’s legal business country. Must be a valid ISO or ISO3 country code. </dd>
              <dt data-property-name="merchant_descriptor" class="has-description">
                <span class="json-property-name">merchant_descriptor:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 500 chars) </span>
              </dt>
              <dd> Merchant descriptor included in the billing statement for a transaction. </dd>
              <dt data-property-name="merchant_email" class="has-description">
                <span class="json-property-name">merchant_email:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 150 chars) </span>
              </dt>
              <dd> Merchant's email address. </dd>
              <dt data-property-name="merchant_id" class="has-description">
                <span class="json-property-name">merchant_id:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (1 to 50 chars) </span>
              </dt>
              <dd> ID of the merchant record (different from the LSDB ID). </dd>
              <dt data-property-name="merchant_name" class="has-description">
                <span class="json-property-name">merchant_name:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 255 chars) </span>
              </dt>
              <dd> Merchant’s legal business name. </dd>
              <dt data-property-name="merchant_phone" class="has-description">
                <span class="json-property-name">merchant_phone:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
              </dt>
              <dd> Merchant’s legal business phone number. </dd>
              <dt data-property-name="merchant_postal_code" class="has-description">
                <span class="json-property-name">merchant_postal_code:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 20 chars) </span>
              </dt>
              <dd> Merchant’s legal business postal code. </dd>
              <dt data-property-name="merchant_region" class="has-description">
                <span class="json-property-name">merchant_region:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 50 chars) </span>
              </dt>
              <dd> Merchant’s legal business state or province. </dd>
              <dt data-property-name="merchant_street" class="has-description">
                <span class="json-property-name">merchant_street:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 150 chars) </span>
              </dt>
              <dd> Merchant’s legal business street name and number. </dd>
              <dt data-property-name="merchant_url" class="has-description">
                <span class="json-property-name">merchant_url:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (at least 1 chars) </span>
              </dt>
              <dd> URL of the merchant’s website. </dd>
              <dt data-property-name="status" class="has-description">
                <span class="json-property-name">status:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-enum" title="Possible values">
                  <span class="json-property-enum-item">pending</span>,
                  <span class="json-property-enum-item">published</span>,
                  <span class="json-property-enum-item">monitored</span>,
                  <span class="json-property-enum-item">terminated</span>,
                  <span class="json-property-enum-item">inactive</span>
                </span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> Merchant's analysis status </dd>
              <dt data-property-name="sub_iso" class="has-description">
                <span class="json-property-name">sub_iso:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
                <span class="json-property-range" title="String length limits"> (up to 100 chars) </span>
              </dt>
              <dd> Sub-ISO for the merchant. </dd>
            </dl>
          </dd>
          <dt data-property-name="website_registrar" class="has-description">
            <span class="json-property-name">website_registrar:</span>
            <span class="json-property-type">object</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd> Contains details about the domain name registrar of the merchant’s website.
            <dl>
              <dt data-property-name="iana_number">
                <span class="json-property-name">iana_number:</span>
                <span class="json-property-type">integer</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
              <dt data-property-name="name">
                <span class="json-property-name">name:</span>
                <span class="json-property-type">string</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
              <dt data-property-name="risk_level">
                <span class="json-property-name">risk_level:</span>
                <span class="json-property-type">integer</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
            </dl>
          </dd>
        </dl>
      </div>
      <div class="doc-examples">
        <section>
          <h5>Example</h5>
          <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;created_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>,
  <span class="hljs-attr">&quot;customer_action&quot;</span>: {{'{'}}
    <span class="hljs-attr">&quot;action&quot;</span>: <span class="hljs-string">&quot;cleared&quot;</span>,
    <span class="hljs-attr">&quot;comment&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
    <span class="hljs-attr">&quot;created_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>
  {{'}'}},
  <span class="hljs-attr">&quot;id&quot;</span>: <span class="hljs-number">123</span>,
  <span class="hljs-attr">&quot;ls_action&quot;</span>: {{'{'}}
    <span class="hljs-attr">&quot;published_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>,
    <span class="hljs-attr">&quot;actions&quot;</span>: [
      <span class="hljs-string">&quot;moderate_risk&quot;</span>,
      <span class="hljs-string">&quot;tl_confirmed&quot;</span>
    ],
    <span class="hljs-attr">&quot;content_labels&quot;</span>: [
      {{'{'}}
        <span class="hljs-attr">&quot;id&quot;</span>: <span class="hljs-number">1</span>,
        <span class="hljs-attr">&quot;label&quot;</span>: <span class="hljs-string">&quot;Pharmaceuticals&quot;</span>
      {{'}'}}
    ],
    <span class="hljs-attr">&quot;reason&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>
  {{'}'}},
  <span class="hljs-attr">&quot;merchant_details&quot;</span>: {{'{'}}
    <span class="hljs-attr">&quot;acquiring_bank&quot;</span>: <span class="hljs-string">&quot;Fake Bank&quot;</span>,
    <span class="hljs-attr">&quot;additional_merchant_urls&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.com, https://www.fakemerchant123.net&quot;</span>,
    <span class="hljs-attr">&quot;agent&quot;</span>: <span class="hljs-string">&quot;Fake Agent Inc&quot;</span>,
    <span class="hljs-attr">&quot;associated_email_addresses&quot;</span>: <span class="hljs-string">&quot;jane.doe@fakemerchant123.biz, jesse.doe@fakemerchant123.biz&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_name&quot;</span>: <span class="hljs-string">&quot;John Doe&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
    <span class="hljs-attr">&quot;business_owner_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
    <span class="hljs-attr">&quot;dba_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
    <span class="hljs-attr">&quot;dba_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
    <span class="hljs-attr">&quot;dba_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchants LLC&quot;</span>,
    <span class="hljs-attr">&quot;dba_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
    <span class="hljs-attr">&quot;dba_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
    <span class="hljs-attr">&quot;dba_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
    <span class="hljs-attr">&quot;dba_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
    <span class="hljs-attr">&quot;ica&quot;</span>: <span class="hljs-string">&quot;55555, 666666&quot;</span>,
    <span class="hljs-attr">&quot;iso&quot;</span>: <span class="hljs-string">&quot;Anderson and Fake&quot;</span>,
    <span class="hljs-attr">&quot;merchant_category_code&quot;</span>: <span class="hljs-number">4789</span>,
    <span class="hljs-attr">&quot;merchant_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
    <span class="hljs-attr">&quot;merchant_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
    <span class="hljs-attr">&quot;merchant_descriptor&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
    <span class="hljs-attr">&quot;merchant_email&quot;</span>: <span class="hljs-string">&quot;john.doe@fakemerchant123.biz&quot;</span>,
    <span class="hljs-attr">&quot;merchant_id&quot;</span>: <span class="hljs-string">&quot;abc123&quot;</span>,
    <span class="hljs-attr">&quot;merchant_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchant 123 LLC&quot;</span>,
    <span class="hljs-attr">&quot;merchant_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
    <span class="hljs-attr">&quot;merchant_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
    <span class="hljs-attr">&quot;merchant_region&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
    <span class="hljs-attr">&quot;merchant_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
    <span class="hljs-attr">&quot;merchant_url&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.biz&quot;</span>,
    <span class="hljs-attr">&quot;status&quot;</span>: <span class="hljs-string">&quot;monitored&quot;</span>,
    <span class="hljs-attr">&quot;sub_iso&quot;</span>: <span class="hljs-string">&quot;Durgan, Schmeler and Fake&quot;</span>
  {{'}'}},
  <span class="hljs-attr">&quot;website_registrar&quot;</span>: {{'{'}}
    <span class="hljs-attr">&quot;iana_number&quot;</span>: <span class="hljs-number">1408</span>,
    <span class="hljs-attr">&quot;name&quot;</span>: <span class="hljs-string">&quot;Discount Domains LTD&quot;</span>,
    <span class="hljs-attr">&quot;risk_level&quot;</span>: <span class="hljs-number">3</span>
  {{'}'}}
{{'}'}}
</code></pre>
          <!-- </div> -->
        </section>
      </div>
    </div>
  </section>
  <section id="definition-MerchantsGet" class="definition panel">
    <h2 class="panel-title"> MerchantsGet: object
      <!-- <span class="json-property-type"><span class="json-property-type">object</span>
      <span class="json-property-range" title="Value limits"></span>
      
      
      </span> -->
    </h2>
    <div class="doc-row">
      <div class="doc-copy">
        <dl>
          <dt data-property-name="pagination">
            <span class="json-property-name">pagination:</span>
            <span class="json-property-type">object</span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd>
            <dl>
              <dt data-property-name="totalRecords">
                <span class="json-property-name">totalRecords:</span>
                <span class="json-property-type">integer</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
              <dt data-property-name="totalPages">
                <span class="json-property-name">totalPages:</span>
                <span class="json-property-type">integer</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
              <dt data-property-name="pageNumber">
                <span class="json-property-name">pageNumber:</span>
                <span class="json-property-type">integer</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
              <dt data-property-name="startPosition">
                <span class="json-property-name">startPosition:</span>
                <span class="json-property-type">integer</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
              <dt data-property-name="endPosition">
                <span class="json-property-name">endPosition:</span>
                <span class="json-property-type">integer</span>
                <span class="json-property-range" title="Value limits"></span>
              </dt>
              <dd> </dd>
            </dl>
          </dd>
          <dt data-property-name="merchants">
            <span class="json-property-name">merchants:</span>
            <span class="json-property-type">
              <span class="json-schema-ref-array"> MerchantGet </span>
            </span>
            <span class="json-property-range" title="Value limits"></span>
          </dt>
          <dd>
            <section class="json-schema-array-items">
              <span class="json-property-type">
                <span class=""> MerchantGet </span>
              </span>
              <span class="json-property-range" title="Value limits"></span>
              <div class="json-inner-schema"> </div>
            </section>
          </dd>
        </dl>
      </div>
      <div class="doc-examples">
        <section>
          <h5>Example</h5>
          <!-- <div class="hljs"> --><pre><code class="hljs lang-json">{{'{'}}
  <span class="hljs-attr">&quot;pagination&quot;</span>: {{'{'}}
    <span class="hljs-attr">&quot;totalRecords&quot;</span>: <span class="hljs-number">1000</span>,
    <span class="hljs-attr">&quot;totalPages&quot;</span>: <span class="hljs-number">50</span>,
    <span class="hljs-attr">&quot;pageNumber&quot;</span>: <span class="hljs-number">1</span>,
    <span class="hljs-attr">&quot;startPosition&quot;</span>: <span class="hljs-number">1</span>,
    <span class="hljs-attr">&quot;endPosition&quot;</span>: <span class="hljs-number">20</span>
  {{'}'}},
  <span class="hljs-attr">&quot;merchants&quot;</span>: [
    {{'{'}}
      <span class="hljs-attr">&quot;created_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>,
      <span class="hljs-attr">&quot;customer_action&quot;</span>: {{'{'}}
        <span class="hljs-attr">&quot;action&quot;</span>: <span class="hljs-string">&quot;cleared&quot;</span>,
        <span class="hljs-attr">&quot;comment&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
        <span class="hljs-attr">&quot;created_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>
      {{'}'}},
      <span class="hljs-attr">&quot;id&quot;</span>: <span class="hljs-number">123</span>,
      <span class="hljs-attr">&quot;ls_action&quot;</span>: {{'{'}}
        <span class="hljs-attr">&quot;published_at&quot;</span>: <span class="hljs-string">&quot;2019-01-01T20:20:20.000Z&quot;</span>,
        <span class="hljs-attr">&quot;actions&quot;</span>: [
          <span class="hljs-string">&quot;moderate_risk&quot;</span>,
          <span class="hljs-string">&quot;tl_confirmed&quot;</span>
        ],
        <span class="hljs-attr">&quot;content_labels&quot;</span>: [
          {{'{'}}
            <span class="hljs-attr">&quot;id&quot;</span>: <span class="hljs-number">1</span>,
            <span class="hljs-attr">&quot;label&quot;</span>: <span class="hljs-string">&quot;Pharmaceuticals&quot;</span>
          {{'}'}}
        ],
        <span class="hljs-attr">&quot;reason&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>
      {{'}'}},
      <span class="hljs-attr">&quot;merchant_details&quot;</span>: {{'{'}}
        <span class="hljs-attr">&quot;acquiring_bank&quot;</span>: <span class="hljs-string">&quot;Fake Bank&quot;</span>,
        <span class="hljs-attr">&quot;additional_merchant_urls&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.com, https://www.fakemerchant123.net&quot;</span>,
        <span class="hljs-attr">&quot;agent&quot;</span>: <span class="hljs-string">&quot;Fake Agent Inc&quot;</span>,
        <span class="hljs-attr">&quot;associated_email_addresses&quot;</span>: <span class="hljs-string">&quot;jane.doe@fakemerchant123.biz, jesse.doe@fakemerchant123.biz&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_name&quot;</span>: <span class="hljs-string">&quot;John Doe&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
        <span class="hljs-attr">&quot;business_owner_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
        <span class="hljs-attr">&quot;dba_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
        <span class="hljs-attr">&quot;dba_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
        <span class="hljs-attr">&quot;dba_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchants LLC&quot;</span>,
        <span class="hljs-attr">&quot;dba_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
        <span class="hljs-attr">&quot;dba_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
        <span class="hljs-attr">&quot;dba_state&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
        <span class="hljs-attr">&quot;dba_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
        <span class="hljs-attr">&quot;ica&quot;</span>: <span class="hljs-string">&quot;55555, 666666&quot;</span>,
        <span class="hljs-attr">&quot;iso&quot;</span>: <span class="hljs-string">&quot;Anderson and Fake&quot;</span>,
        <span class="hljs-attr">&quot;merchant_category_code&quot;</span>: <span class="hljs-number">4789</span>,
        <span class="hljs-attr">&quot;merchant_city&quot;</span>: <span class="hljs-string">&quot;Anytown&quot;</span>,
        <span class="hljs-attr">&quot;merchant_country&quot;</span>: <span class="hljs-string">&quot;US&quot;</span>,
        <span class="hljs-attr">&quot;merchant_descriptor&quot;</span>: <span class="hljs-string">&quot;Lorem ipsum dolor sit amet&quot;</span>,
        <span class="hljs-attr">&quot;merchant_email&quot;</span>: <span class="hljs-string">&quot;john.doe@fakemerchant123.biz&quot;</span>,
        <span class="hljs-attr">&quot;merchant_id&quot;</span>: <span class="hljs-string">&quot;abc123&quot;</span>,
        <span class="hljs-attr">&quot;merchant_name&quot;</span>: <span class="hljs-string">&quot;Fake Merchant 123 LLC&quot;</span>,
        <span class="hljs-attr">&quot;merchant_phone&quot;</span>: <span class="hljs-string">&quot;+1 555-555-5555&quot;</span>,
        <span class="hljs-attr">&quot;merchant_postal_code&quot;</span>: <span class="hljs-string">&quot;90210&quot;</span>,
        <span class="hljs-attr">&quot;merchant_region&quot;</span>: <span class="hljs-string">&quot;CA&quot;</span>,
        <span class="hljs-attr">&quot;merchant_street&quot;</span>: <span class="hljs-string">&quot;1 Main St&quot;</span>,
        <span class="hljs-attr">&quot;merchant_url&quot;</span>: <span class="hljs-string">&quot;https://www.fakemerchant123.biz&quot;</span>,
        <span class="hljs-attr">&quot;status&quot;</span>: <span class="hljs-string">&quot;monitored&quot;</span>,
        <span class="hljs-attr">&quot;sub_iso&quot;</span>: <span class="hljs-string">&quot;Durgan, Schmeler and Fake&quot;</span>
      {{'}'}},
      <span class="hljs-attr">&quot;website_registrar&quot;</span>: {{'{'}}
        <span class="hljs-attr">&quot;iana_number&quot;</span>: <span class="hljs-number">1408</span>,
        <span class="hljs-attr">&quot;name&quot;</span>: <span class="hljs-string">&quot;Discount Domains LTD&quot;</span>,
        <span class="hljs-attr">&quot;risk_level&quot;</span>: <span class="hljs-number">3</span>
      {{'}'}}
    {{'}'}}
  ]
{{'}'}}
</code></pre>
          <!-- </div> -->
        </section>
      </div>
    </div>
  </section>
</article>
<!-- END OF GENERATED SWAGGER HTML -->
<!-- DO NOT REFORMAT!!! -->
            </div>
        </section>
    </div>