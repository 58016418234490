import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { LsError } from '@ls/common-ts-models';
import { ProductLookupService } from '../../../services';

@Component({
  selector: 'product-lookup-list',
  styleUrls: ['./product-lookup-list.scss', '../../../styles/list-detail.scss'],
  templateUrl: './product-lookup-list.html',
})
export class ProductLookupsListComponent implements OnInit, OnDestroy {
  public searchTerm = '';
  public page = '1';
  public products = [];
  public pagination: any = {};
  public loading = false;
  public error: LsError | undefined;
  public products$: Subscription;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public service: ProductLookupService,
  ) {}

  public ngOnInit() {
    // A bug in Angular (https://github.com/angular/angular/issues/17609) keeps
    // this from being automatic.
    this.route.queryParams.subscribe((queryParams) => {
      this.searchTerm = queryParams['product'];
      this.page = queryParams['page'];
      this.setPage();
    });
  }

  public setPage() {
    this.error = undefined;
    this.loading = true;
    const initialPage = this.page ? Math.floor(Number(this.page)) : undefined;

    this.products$ = this.service.search(this.searchTerm, initialPage).subscribe(
      (info) => {
        this.products = info.products;
        this.pagination = info.pagination;
        this.loading = false;
      },
      (err) => {
        this.error = LsError.Default;
        if (err.status === 429) {
          this.error = LsError.ProductLookupOverQuota;
        }
        this.loading = false;
      },
    );
  }

  public ngOnDestroy() {
    if (this.products$) {
      this.products$.unsubscribe();
    }
  }
}
