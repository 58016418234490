import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, skipWhile, map } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  EntitlementsGetAction,
  EntitlementsState,
  LegitScriptModalComponent,
  LocalStorageService,
} from '@ls/common-ng-components';
import {
  MonitoringServiceTypes,
  MonitoringServiceStatuses,
  MonitoringServicesListItem,
  Opportunity,
  ProductFamily,
  MyLSCustomerUpsert,
  Account,
} from '@ls/common-ts-models';
import { AppState } from '../../reducers';
import { MMCustomerService } from './services';

@Component({
  selector: 'mm-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class MMDashboardComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public mmProduct: Opportunity;
  public refreshApiInput: { externalReferenceId: boolean };

  public entitlements$: Observable<EntitlementsState>;
  public customer$: Observable<any>;
  public monitoringServices: MonitoringServicesListItem[] = [];
  public selectedPendingServiceItem: MonitoringServicesListItem;
  public account: Account;
  public isServiceAccessEnabled = false;
  @ViewChild(LegitScriptModalComponent) public purchasePendingNotice: LegitScriptModalComponent;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    public router: Router,
    private route: ActivatedRoute,
    private mmCustomerService: MMCustomerService,
  ) {}

  public ngOnInit() {
    this.store.dispatch(EntitlementsGetAction());

    this.entitlements$ = this.store.select('entitlements').pipe(
      skipWhile((entitlements: EntitlementsState) => entitlements.pending),
      takeUntil(this.destroyed$),
    );

    this.account = this.localStorageService.getAccount();

    this.customer$ = this.mmCustomerService.getCustomer(this.account.lsAccountId);

    combineLatest([this.entitlements$, this.customer$])
      .pipe(map(([entitlements, customer]) => ({ entitlements, customer })))
      .subscribe(({ entitlements, customer }) => {
        this.isLoading = false;

        const mmProduct = entitlements.products[ProductFamily.MERCHANT_MONITORING];

        if (mmProduct) {
          this.isServiceAccessEnabled = mmProduct.serviceAccessEnabled;
          this.mmProduct = mmProduct;
          this.refreshApiInput = { externalReferenceId: mmProduct.externalReferenceId };
        }

        this.monitoringServices = this.mapToServiceListItems(customer, entitlements);
      });
  }

  // Might have to add more logic here if we start supporting more than one MM Service
  public handleRowClick(event: any) {
    const serviceListItem: MonitoringServicesListItem = event.data;

    if (serviceListItem.status === MonitoringServiceStatuses.PENDING) {
      this.selectedPendingServiceItem = serviceListItem;
      this.purchasePendingNotice.showDialog();
    } else {
      this.router.navigate(['list'], { relativeTo: this.route });
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private mapToServiceListItems(
    customer: MyLSCustomerUpsert,
    entitlements: EntitlementsState,
  ): MonitoringServicesListItem[] {
    const companyName = this.account ? this.account.name : 'N/A';

    return Object.entries(MonitoringServiceTypes).map(([serviceType, serviceName]) => {
      const product = entitlements.products[serviceName];
      const pendingProduct = entitlements.recentlyPurchased[serviceName];

      if (!product && !pendingProduct) {
        // if we don't have either an existing product or one that is "pending", there's nothing more to do here
        return;
      }

      let status = MonitoringServiceStatuses.INACTIVE;
      if (product && product.serviceAccessEnabled) {
        status = MonitoringServiceStatuses.ACTIVE;
      } else if (pendingProduct) {
        // upon initial purchase, we put a "recentlyPurchased" property in the entitlements, which shows a notice that their purchase is processing (auto-removed after a short timeout)
        // however, if we first check to see if their product already has serviceAccessEnabled, we know all purchase-related operations are complete and they can just go straight to the service
        status = MonitoringServiceStatuses.PENDING;
      }

      return {
        companyName,
        serviceType: MonitoringServiceTypes[serviceType],
        primaryUrl: customer.url,
        status,
      };
    });
  }
}
