import { ComponentsModule } from './components/components.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ApplicationRef, NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ToastModule } from 'primeng/toast';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { MerchantMonitoringModule, LookupsModule, CertificationsModule } from './modules';

import {
  Auth0Service,
  BillingService,
  LocalStorageService,
  EntitlementsService,
  AUTH0_HANDLER,
  ACCOUNT_SELECTOR_HANDLER,
  SelectedAccountInterceptor,
  Auth0Interceptor,
} from '@ls/common-ng-components';

import { Auth0RedirectGuard, GuardsModule, AuthenticationGuard } from './guards';
import { APP_REDUCERS } from './reducers';
import { APP_EFFECTS } from './effects';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { AppState, InternalStateType } from './app.service';
import {
  AccountService,
  AccountSelectorHandlerService,
  Auth0HandlerService,
  AuthenticationService,
  PushNotificationService,
  ServicesModule,
} from './services';
import { VerifyAccountComponent, AccountCreatedComponent, MaintenanceModeComponent } from './pages';
import { JwtModule } from '@auth0/angular-jwt';
import { MessageService } from 'primeng/api';
import { createInputTransfer, createNewHosts, removeNgStyles } from '@angularclass/hmr';
import { TestTransactionsModule } from './modules/test-transactions';
import { DataLayerService } from './services/data-layer.service';

const APP_PROVIDERS = [
  { provide: AUTH0_HANDLER, useClass: Auth0HandlerService },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SelectedAccountInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: Auth0Interceptor, multi: true },
  {
    provide: ACCOUNT_SELECTOR_HANDLER,
    useClass: AccountSelectorHandlerService,
  },
  Auth0RedirectGuard,
  Auth0Service,
  AuthenticationService,
  AuthenticationGuard,
  AccountService,
  LocalStorageService,
  AppState,
  BillingService,
  EntitlementsService,
  MessageService,
  PushNotificationService,
  AccountSelectorHandlerService,
  DataLayerService,
];

interface StoreType {
  state: InternalStateType;
  restoreInputValues: () => void;
  disposeOldHosts: () => void;
}

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, VerifyAccountComponent, AccountCreatedComponent, MaintenanceModeComponent],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    EffectsModule.forRoot(APP_EFFECTS),
    StoreModule.forRoot(APP_REDUCERS),
    ServicesModule,
    CertificationsModule,
    LookupsModule,
    MerchantMonitoringModule,
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    TestTransactionsModule,
    ComponentsModule,
    GuardsModule,
    ToastModule,
    InputTextareaModule,

    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
      },
    }),
  ],
  providers: [APP_PROVIDERS],
})
export class AppModule {
  constructor(
    public appRef: ApplicationRef,
    public appState: AppState,
  ) {
    if (!localStorage.getItem('useAuth0')) {
      localStorage.setItem('useAuth0', 'true');
    }
  }

  public hmrOnInit(store: StoreType) {
    if (!store || !store.state) {
      return;
    }
    /**
     * Set state
     */
    this.appState._state = store.state;
    /**
     * Set input values
     */
    if ('restoreInputValues' in store) {
      const restoreInputValues = store.restoreInputValues;
      setTimeout(restoreInputValues);
    }
    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }
  public hmrOnDestroy(store: StoreType) {
    const cmpLocation = this.appRef.components.map((cmp) => cmp.location.nativeElement);
    /**
     * Save state
     */
    const state = this.appState._state;
    store.state = state;
    /**
     * Recreate root elements
     */
    store.disposeOldHosts = createNewHosts(cmpLocation);
    /**
     * Save input values
     */
    store.restoreInputValues = createInputTransfer();
    /**
     * Remove styles
     */
    removeNgStyles();
  }
  public hmrAfterDestroy(store: StoreType) {
    /**
     * Display new elements
     */
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
