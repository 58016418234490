import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LsError } from '@ls/common-ts-models';
import { WebsiteLookupService } from '../../../services';

@Component({
  selector: 'website-lookup-detail',
  styleUrls: ['./website-lookup-detail.scss', '../../../styles/list-detail.scss'],
  templateUrl: './website-lookup-detail.html',
  providers: [WebsiteLookupService],
})
export class WebsiteLookupsDetailComponent implements OnInit {
  public loading = false;
  public website: any;
  public searchTerm: string;
  public error: LsError | undefined;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public service: WebsiteLookupService,
  ) {}

  public ngOnInit() {
    this.route.queryParams.subscribe((queryParams) => {
      this.searchTerm = queryParams['website'];
      this.getWebsite();
    });
  }

  public getWebsite() {
    this.error = undefined;
    this.loading = true;
    this.service.get(this.searchTerm).subscribe(
      (websiteDetail) => {
        this.loading = false;
        this.website = websiteDetail[0];
      },
      (err) => {
        this.website = undefined;
        this.error = LsError.Default;
        if (err.status === 429) {
          this.error = LsError.WebsiteLookupOverQuota;
        }
        this.loading = false;
      },
    );
  }
}
