<!-- eslint-disable @angular-eslint/template/alt-text -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div id="header" class="login-header">
  <div class="logo-container">
    <img class="logo-image" src="../../../../assets/img/legitscript-logo.svg" />
  </div>
  <div class="header-tool-container">
    <div class="header-tool" (click)="openSupport()">
      <i class="fa fa-life-ring" aria-hidden="true"></i>Support
    </div>
  </div>
</div>