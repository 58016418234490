<div class="widgets-container products-api-docs">

  <section *ngIf="(entitlements$ | async)?.recentlyPurchased[productFamily]; else ready" class="widget">
    <div class="widget-header">
      <i class="fa fa-info-circle"></i><h1>Processing</h1>
    </div>
    <div class="widget-body centered">
      <purchase-processing-notice [productName]="productFamily"></purchase-processing-notice>
    </div>
  </section>

  <ng-template #ready>
    <section class="widget" id="info-widget">
      <div class="widget-header">
        <i class="fa fa-info-circle"></i><h1>Information</h1>
      </div>
      <div class="widget-body">
        Provision an API Key and view your usage information in <a routerLink="/account/billing">Billing</a>
      </div>
    </section>

    <section class="widget">
      <div class="widget-header">
        <i class="fa fa-book"></i><h1>Documentation</h1>
      </div>
      <div class="widget-body" id="docs-container" *ngIf="(docHtml$ | async) as docHtml; else loading" [innerHtml]="docHtml"></div>
      <ng-template #loading>
        <loading-spinner loadingText="Loading Documentation"></loading-spinner>
      </ng-template>
    </section>
  </ng-template>

</div>
<div class="too-small">
  Your device is too narrow to view this documentation. Rotate it to the landscape position or use a device with a wider display.
</div>