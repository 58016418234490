<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="login-container">
  <login-header></login-header>

  <div class="entry-main">
    <div class="entry-container entry-container-small forgot-pw-main" [ngClass]="{ 'success': done}">
      <ng-container *ngIf="!done; else sent">
        <h1 class="mb20">Forgot Password?</h1>
        <div class="subtext mb20">Enter your email address and we will send you a link to reset your password</div>
        <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
          <div id="forgot-form">
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
              <label>Email</label>
              <input type="text" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" placeholder="my.name@legitscript.com" required email />
              <field-validator [control]="email"></field-validator>
            </div>
            <div class="form-group">
              <re-captcha #captcha (resolved)="resolvedCaptcha($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
            </div>
            <div class="form-actions">
              <button *ngIf="!sending" class="btn btn-primary" [disabled]="f.invalid || !model.captchaToken">Request Reset</button>
              <div class="loading-container" [hidden]="!sending">
                <p-progressSpinner [style]="{ width: '35px', height: '35px' }"></p-progressSpinner>
              </div>
              <br />
            </div>
          </div>
        </form>
      </ng-container>
      <ng-template #sent>
        <i class="fa fa-check-circle success"></i><br><br>
        <h1 class="mb40">Email Sent</h1><br>
        <div class="subtext mb40">Please check your email for a link to reset your password</div>
      </ng-template>
    </div>
  </div>
</div>
