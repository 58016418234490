import { MyLSMerchant } from '@ls/common-ts-models';
import { createAction, createReducer, on, props } from '@ngrx/store';
import { AppState } from 'src/app/reducers';

export const MARK_MERCHANT_DIRTY = '[Dirty Merchants] Mark';
export const MARK_BULK_MERCHANTS_DIRTY = '[Dirty Merchants] Marks Bulk';
export const GET_DIRTY_MERCHANTS = '[Dirty Merchants] Get';
export const GET_DIRTY_MERCHANTS_SUCCESS = '[Dirty Merchants] Get Success';
export const MERCHANTS_CLEANED = '[Dirty Merchants] Cleaned';

export const MerchantDirtiedAction = createAction(
  MARK_MERCHANT_DIRTY,
  props<{ correlationId: string; previousUpdateDate: string; updateRequestTime: number }>(),
);

export const MerchantsCleanedAction = createAction(MERCHANTS_CLEANED, props<{ correlationIds: string[] }>());

export const SetDirtyMerchantsAction = createAction(
  MARK_BULK_MERCHANTS_DIRTY,
  props<{ merchantsToMark: MyLSMerchant[]; updateRequestTime: number }>(),
);

export const GetDirtyMerchantsAction = createAction(GET_DIRTY_MERCHANTS);

export const GetDirtyMerchantsSuccessAction = createAction(
  GET_DIRTY_MERCHANTS_SUCCESS,
  props<{ merchants: DirtyMerchantState[] }>(),
);

export interface DirtyMerchantState {
  correlationId: string;
  updateRequestTime: number; // millisec since dirty mark applied
  previousUpdateDate: string;
}
export type DirtyMerchantsState = DirtyMerchantState[];

const initialDirtyMerchantState: DirtyMerchantsState = [];

export const DirtyMerchantsReducer = createReducer(
  initialDirtyMerchantState,

  on(MerchantDirtiedAction, (state, { correlationId, updateRequestTime, previousUpdateDate }) => {
    const dirtyMerchant: DirtyMerchantState = {
      correlationId,
      updateRequestTime,
      previousUpdateDate,
    };

    return [...state, dirtyMerchant];
  }),

  on(SetDirtyMerchantsAction, (state, { merchantsToMark, updateRequestTime }) => {
    const merchantsMarked: DirtyMerchantState[] = merchantsToMark.map((merchantMarked) => {
      return {
        correlationId: merchantMarked.correlation_id,
        updateRequestTime,
        previousUpdateDate: merchantMarked.update_date,
      };
    });
    return [...state, ...merchantsMarked];
  }),

  on(GetDirtyMerchantsSuccessAction, (state, { merchants }) => {
    return [...merchants];
  }),

  on(MerchantsCleanedAction, (state, { correlationIds }) => {
    return state.filter((stateMerchant) => !correlationIds.includes(stateMerchant.correlationId));
  }),
);

export const fnDirtyMerchantState = (state: AppState) => state.merchantMonitoring.dirtyMerchants;
