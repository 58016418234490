<ng-container *ngIf="type=='create-account'">
  <form [formGroup]="confirmEmailForm">
    <div class="form-group" [ngClass]="{ 'has-error': !confirmEmailForm.controls['email'].valid }">
      <field-validator class="right" [control]="confirmEmailForm.controls['email']"></field-validator>
      <div class="label-wrapper">
          <input id="confirmEmailForm-email" type="text" class="form-control" name="email" formControlName="email" placeholder=" " autofocus checkWhitespace />
          <label for="confirmEmailForm-email">Email</label>
      </div>
    </div>
    <div class="form-group" [ngClass]="{ 'has-error': !confirmEmailForm.controls['confirmEmail'].valid }">
      <field-validator class="right" [control]="confirmEmailForm.controls['confirmEmail']"></field-validator>
      <div class="label-wrapper">
          <input id="confirmEmailForm-confirmEmail" type="text" class="form-control" name="confirmEmail" formControlName="confirmEmail" placeholder=" " checkWhitespace />
          <label for="confirmEmailForm-confirmEmail">Confirm Email</label>
      </div>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="type!='create-account'">
  <form [formGroup]="confirmEmailForm">
    <div class="form-group" [ngClass]="{ 'has-error': !confirmEmailForm.controls['email'].valid }">
      <div class="label-wrapper">
          <label for="email">Email</label>
          <field-validator class="right" [control]="confirmEmailForm.controls['email']"></field-validator>
      </div>
      <input type="text" class="form-control" name="email" formControlName="email" placeholder="you@example.com" autofocus checkWhitespace/>
    </div>
    <div class="form-group" [ngClass]="{ 'has-error': !confirmEmailForm.controls['confirmEmail'].valid }">
      <div class="label-wrapper">
          <label for="confirmEmail">Confirm Email</label>
          <field-validator class="right" [control]="confirmEmailForm.controls['confirmEmail']"></field-validator>
      </div>
      <input type="text" class="form-control" name="confirmEmail" formControlName="confirmEmail" placeholder="you@example.com" checkWhitespace />
    </div>
  </form>
</ng-container>