<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="widget">
  <div class="widget-header">
    <i class="fa fa-address-book"></i><h1>Account Details</h1>
    <a><i class="fa fa-ellipsis-h" style="float: right" aria-hidden="true" (click)="menu.toggle($event);"></i></a>
    <p-menu #menu [popup]=true [model]="items" ></p-menu>
  </div>
  <div class="widget-body centered" *ngIf="!error">
    <loading-spinner *ngIf="account.pending" loadingText="Loading Account Details"></loading-spinner>

    <div class="account-details-wrapper" *ngIf="!account.pending">
      <section>
        <div class="display-line mb20">
          <label>COMPANY NAME</label>
          <div>{{account.name}}</div>
        </div>
        <div class="display-line mb20">
          <label>ADDRESS</label>
          <div>{{account.address?.street1}}</div>
          <div *ngIf="account.address?.street2">{{account.address?.street2}}</div>
          <div>{{account.address?.city}}, {{account.address?.province}} {{account.address?.postalCode}}</div>
          <div>{{account.address?.countryCode}}</div>
        </div>
        <div class="display-line mb20">
          <label>COMPANY PHONE</label>
          <div>{{account.phone}}</div>
        </div>
      </section>
      <section>
        <div class="display-line mb20">
          <label>PRIMARY CONTACT</label>
          <div>{{account.primaryContact?.name}}</div>
        </div>
        <div class="display-line mb20">
          <label>PRIMARY CONTACT POSITION</label>
          <div>{{account.primaryContact?.position}}</div>
        </div>
        <div class="display-line mb20">
          <label>PRIMARY CONTACT PHONE</label>
          <div>{{account.primaryContact?.phone}}</div>
        </div>
        <div class="display-line mb20">
          <label>PRIMARY CONTACT EMAIL</label>
          <div>{{account.primaryContact?.email}}</div>
        </div>
      </section>
      <section *ngIf="contact?.lsUserId !== account?.primaryContact?.lsUserId">
        <div class="display-line mb20">
          <label>CURRENT USER</label>
          <div>{{contact.name}}</div>
        </div>
        <div class="display-line mb20">
          <label>CURRENT USER POSITION</label>
          <div>{{contact.position}}</div>
        </div>
        <div class="display-line mb20">
          <label>CURRENT USER PHONE</label>
          <div>{{contact.phone}}</div>
        </div>
        <div class="display-line mb20">
          <label>CURRENT USER EMAIL</label>
          <div>{{contact.email}}</div>
        </div>
      </section>
    </div>
    <div *ngIf="!account.pending">
      <edit-account-details [model]="account" ></edit-account-details>
      <edit-user [model]="editUserModel" (submitted)="submitUser()"></edit-user>
      <update-email></update-email>
    </div>
</div>
