<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="widgets-container">
  <div class="certifications-panel widget full-widget">
    <div class="widget-header">
      <span class="no-icon">
        <h1>Select Certification Type</h1>
      </span>
    </div>
    <div class="widget-body centered">
      <div class="entry-container entry-container-large">
        <h1>Looks like you don't currently have any certifications or certification applications in progress.</h1>
        To start the process, please choose the type of certification you'd like to apply for.
        <section class="icon-wrapper">
          <div [routerLink]="['/services/merchant-certification/enroll/healthcare']">
            <picture>
              <img src="../assets/img/rx-side-bar.png" />
            </picture>
            <span>Healthcare Merchant</span>
          </div>
          <div [routerLink]="['/services/merchant-certification/enroll/daatc']">
            <picture>
              <img src="../assets/img/pill-side-bar.png" />
            </picture>
            <span>Drug and Alcohol Addiction Treatment Provider</span>
          </div>
          <div [routerLink]="['/services/merchant-certification/enroll/cbd']">
            <picture>
              <img src="../assets/img/cbd-side-bar.png" />
            </picture>
            <span>CBD Product or Website</span>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
