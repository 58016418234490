import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { Store } from '@ngrx/store';

import { QuestionnaireLoadAndSetQuestionAction, CertificationsGetAction } from '../reducers';
import {
  Certification,
  WorkflowStatus,
  CertificationStatus,
  ProductFamily,
  CertificationType,
  CertificationBillingStatus,
  MAX_CC_AMOUNT_CERTIFICATION,
  BillingItem,
  ExpeditedProcessingProductGuid,
  HealthcareCertProductGuid,
  DaatcProductGuid,
  CbdProductProductGuid,
  CbdWebsiteProductGuid,
  Roles,
} from '@ls/common-ts-models';
import { BillingService, GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { EnrollService } from '../services';
import { Router } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AppState, fnAuthenticationState, fnCertificationsState } from 'src/app/reducers';

@Component({
  selector: 'cert-list',
  styleUrls: ['./certification-list.component.scss'],
  templateUrl: './certification-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CertificationListComponent implements OnInit, OnDestroy {
  public certs: Certification[];
  public loading = true;
  public loadingCert = false;
  public loadingText = 'Loading Your Certifications';
  public certMsgs = [];
  public certTypeSelectItems: MenuItem[];

  private pricingSubscription: any;
  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    public store: Store<AppState>,
    private router: Router,
    private billingSvc: BillingService,
    private enrollSvc: EnrollService,
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(CertificationsGetAction());

    this.certTypeSelectItems = [
      {
        label: 'Healthcare Merchant',
        command: () => this.router.navigate(['/services/merchant-certification/enroll/healthcare']),
      },
      {
        label: 'Drug and Alcohol Addiction Treatment Provider',
        command: () => this.router.navigate(['/services/merchant-certification/enroll/daatc']),
      },
      {
        label: 'CBD Certification',
        command: () => this.router.navigate(['/services/merchant-certification/enroll/cbd']),
      },
    ];

    combineLatest([this.store.select(fnCertificationsState), this.store.select(fnAuthenticationState)])
      .pipe(takeUntil(this.destroyed$.asObservable()))
      .subscribe({
        next: ([certifications, authentication]) => {
          this.certMsgs = [];
          if (!certifications.pending && !authentication.pending) {
            this.certs = certifications.list;
            this.loading = false;

            // only show this pop up for account owners
            if (this.certs && authentication.roles.includes(Roles.cpv2AccountOwner)) {
              const approvalNeededCerts = this.certs.filter((cert) => {
                return cert.billingStatus === CertificationBillingStatus.CustomerApprovalPending;
              });
              for (const approvalNeededCert of approvalNeededCerts) {
                // need to check if they are the account owner
                this.certMsgs.push({
                  severity: SeverityOptions.WARN,
                  detail: `Please click <a href="#/services/merchant-certification/pricing-update/${approvalNeededCert.id}">here</a> to approve adjusted pricing for ${approvalNeededCert.businessName} `,
                  key: 'certMsg',
                });
              }
            }
          }
        },
        error: () => {
          this.loading = false;
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Certifications Failed To Load',
              detail:
                'Sorry- it looks like we are having trouble displaying your certifications - please try again.  If this problem persists, please click the Support button at top right',
              sticky: false,
              blocking: false,
            }),
          );
        },
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    if (this.pricingSubscription) {
      this.pricingSubscription.unsubscribe();
    }
  }

  public getStatus(cert) {
    if (cert.suspended) {
      return CertificationStatus.Suspended;
    }

    if (
      cert.certificationStatus === CertificationStatus.NotCertified ||
      cert.workflowStatus === WorkflowStatus.Renewing
    ) {
      if (
        [
          WorkflowStatus.InReview.valueOf(),
          WorkflowStatus.FinalReview.valueOf(),
          WorkflowStatus.ResponseReceived.valueOf(),
        ].includes(cert.workflowStatus)
      ) {
        return 'In Progress';
      }

      if (cert.workflowStatus === WorkflowStatus.Waiting) {
        return 'Waiting for applicant response';
      }

      return cert.workflowStatus || 'Unknown Status';
    } else if (
      [
        CertificationStatus.Certified.valueOf(),
        CertificationStatus.Preliminary.valueOf(),
        CertificationStatus.Probation.valueOf(),
      ].includes(cert.certificationStatus) &&
      cert.billingStatus === CertificationBillingStatus.InvoicePending
    ) {
      return `${cert.certificationStatus} (Invoice Pending)`;
    }

    return cert.certificationStatus;
  }

  public getStatusColor(cert) {
    const status = cert.workflowStatus !== WorkflowStatus.Done ? cert.workflowStatus : cert.certificationStatus;

    if (cert.suspended) {
      return 'suspended';
    }

    if (
      [
        CertificationStatus.Certified.valueOf(),
        CertificationStatus.Preliminary.valueOf(),
        CertificationStatus.Probation.valueOf(),
      ].includes(status) &&
      cert.billingStatus === CertificationBillingStatus.InvoicePending
    ) {
      return 'yellow';
    }

    switch (status) {
      case WorkflowStatus.Unpaid:
      case WorkflowStatus.Renewing:
      case WorkflowStatus.Waiting:
        return 'yellow';
      case CertificationStatus.Certified:
      case CertificationStatus.Preliminary:
      case CertificationStatus.Probation:
        return 'green';
      case CertificationStatus.Cancelled:
      case CertificationStatus.Denied:
        return 'red';
      default:
        return 'blue';
    }
  }

  public handleRowClick(event) {
    const certification = event.data;
    this.loadingText = 'Loading Questionnaire Data';
    this.loadingCert = true;

    // if the certification is renewing and has been migrated, go to the questionnaire complete page
    if (this.getStatus(certification) === WorkflowStatus.Renewing && certification.migratedDate) {
      this.router.navigate([`/questionnaire-complete/${certification.id}`]);
      return;
    }

    switch (this.getStatus(certification)) {
      case WorkflowStatus.Applying:
      case WorkflowStatus.Submitted:
        this.store.dispatch(QuestionnaireLoadAndSetQuestionAction({ certificationId: certification.id }));
        break;
      case WorkflowStatus.Renewing:
        this.store.dispatch(
          QuestionnaireLoadAndSetQuestionAction({ certificationId: certification.id, renewing: true }),
        );
        break;
      case CertificationStatus.Suspended:
        if (certification.workflowStatus === WorkflowStatus.Renewing) {
          this.store.dispatch(
            QuestionnaireLoadAndSetQuestionAction({ certificationId: certification.id, renewing: true }),
          );
        }
        break;
      case WorkflowStatus.Unpaid:
        this.goToPayment(certification);
        break;
      default:
        this.loadingCert = false;
    }
  }

  public async goToPayment(cert) {
    let addOns: BillingItem;
    if (cert.expeditedRequested) {
      const addOnPricing = await this.enrollSvc.getAddonPricing().toPromise();
      addOns = {
        amount: addOnPricing.EXPEDITED_PROCESSING,
        description: 'Expedited Processing Service',
        quantity: 1,
        productId: ExpeditedProcessingProductGuid,
      };
    }

    const productFamilyMap = {
      [CertificationType.healthcare]: {
        family: ProductFamily.RX_CERT,
        subscriptionProductId: HealthcareCertProductGuid.SUBSCRIPTION,
      },
      [CertificationType.daatc]: {
        family: ProductFamily.DAATC,
        subscriptionProductId: DaatcProductGuid.SUBSCRIPTION,
      },
      [CertificationType.cbdProduct]: {
        family: ProductFamily.CBD_PRODUCT_CERT,
        subscriptionProductId: CbdProductProductGuid.SUBSCRIPTION,
      },
      [CertificationType.cbdWebsite]: {
        family: ProductFamily.CBD_WEBSITE_CERT,
        subscriptionProductId: CbdWebsiteProductGuid.SUBSCRIPTION,
      },
    };
    this.enrollSvc.getApplicationBillingInfo(productFamilyMap[cert.type].family, cert).subscribe(
      (billingInfo) => {
        billingInfo.subscriptionProductId = productFamilyMap[cert.type].subscriptionProductId;
        const billingTotal = billingInfo.amount + (addOns ? addOns.amount : 0);
        const invoiceCert = billingTotal > MAX_CC_AMOUNT_CERTIFICATION; // this hack sucks and we have to re-do all this billing stuff
        const paymentPageUrl = invoiceCert
          ? '/services/merchant-certification/payment-invoice'
          : '/services/merchant-certification/payment';
        if (invoiceCert && cert.billingStatus === CertificationBillingStatus.InvoicePending) {
          // this customer has been sent an invoice for the application fee- let them through to the full questionnaire
          this.store.dispatch(QuestionnaireLoadAndSetQuestionAction({ certificationId: cert.id }));
        } else {
          const basePriceInfo = {
            billingItems: addOns ? [billingInfo, addOns] : [billingInfo],
            certId: cert.id,
            sfPricebookId: cert.tier,
            oppType: 'Certification Application',
            maxCCAmount: MAX_CC_AMOUNT_CERTIFICATION,
            promoCode: null,
          };
          const localPricingInfo = this.billingSvc.getPricingInfo();
          if (localPricingInfo && localPricingInfo.promoCode && localPricingInfo.certId === cert.id) {
            basePriceInfo.promoCode = localPricingInfo.promoCode;
          }
          this.billingSvc.setPricingInfo(basePriceInfo);
          this.router.navigate([paymentPageUrl]);
        }
      },
      () => {
        this.loadingCert = false;
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Pricing Info Failed to Load',
            detail:
              "Sorry- it looks like you haven't paid for this certification yet, but we are having trouble loading your pricing info.  If this problem persists, please click the Support button at top right",
            sticky: false,
            blocking: false,
          }),
        );
      },
    );
  }

  public allowSelect(cert) {
    const selectableWorkflowStatus = [
      WorkflowStatus.Applying,
      WorkflowStatus.Submitted,
      WorkflowStatus.Unpaid,
      WorkflowStatus.Renewing,
    ].includes(this.getStatus(cert));

    if (cert.suspended) {
      // customer is allowed to complete renewal questionnaire even if they're suspended
      return cert.workflowStatus === WorkflowStatus.Renewing;
    }

    return selectableWorkflowStatus;
  }
}
