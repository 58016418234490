import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, forkJoin, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  PaginatedTestTransactionGetAction,
  PaginatedTestTransactionsGetErrorAction,
  PaginatedTestTransactionsGetSuccessAction,
  TestTransactionRequestScreenshotsAction,
  TestTransactionRequestScreenshotsErrorAction,
  TestTransactionRequestScreenshotsSuccessAction,
  TestTransactionDownloadAttachmentAction,
  TestTransactionDownloadAttachmentErrorAction,
  TestTransactionDownloadAttachmentSuccessAction,
  TestTransactionUniqueValuesNoOpAction,
  TestTransactionsDownloadTransactionsAction,
  TestTransactionsDownloadTransactionsErrorAction,
  TestTransactionsDownloadTransactionsSuccessAction,
  TestTransactionsFetchAction,
  TestTransactionsFetchErrorAction,
  TestTransactionsFetchSuccessAction,
  TestTransactionsGetSummaryAction,
  TestTransactionsGetSummaryErrorAction,
  TestTransactionsGetSummarySuccessAction,
  TestTransactionsRequestNewAction,
  TestTransactionsRequestNewErrorAction,
  TestTransactionsRequestNewSuccessAction,
  TestTransactionsUniqueValuesAction,
  TestTransactionsUniqueValuesErrorAction,
  TestTransactionsUniqueValuesSuccessAction,
} from '../reducers';
import {
  CategoryObject,
  DownloadTransactionsRequestParams,
  TagObject,
  TransactionsService,
  TransactionsSummaryRequestParams,
  UserSearchForTransactionsRequestParams,
} from '../angular-client';
import {
  FilterType,
  GenericNotificationAction,
  LocalStorageService,
  SeverityOptions,
  TableFilter,
} from '@ls/common-ng-components';
import { Store } from '@ngrx/store';
import { AppState, fnTestTransactionsState } from 'src/app/reducers';
import { S3Service } from '../services';
import { MetaColumns } from '../angular-client/model/metaColumns';
import { UserSearchForTransactions200Response } from '../angular-client/model/userSearchForTransactions200Response';

@Injectable()
export class TestTransactionsEffects {
  constructor(
    private actions$: Actions,
    @Inject(TransactionsService) private testTransactionService: TransactionsService,
    @Inject(LocalStorageService) private localStorageService: LocalStorageService,
    @Inject(S3Service) private s3Service: S3Service,
    public store$: Store<AppState>,
  ) {}

  public getPaginated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaginatedTestTransactionGetAction),
      switchMap((action) => {
        const filters = this.mapFilters(action.filters);
        const requestParams: UserSearchForTransactionsRequestParams = {
          ...filters,
          page: action.page,
          size: action.size,
          sortDirection: action.sortDirection,
          sortField: action.sortField,
          includeDeleted: false,
          uniqueWebsites: false,
          Authorization: this.authHeader(),
        };
        return this.testTransactionService.userSearchForTransactions(requestParams).pipe(
          map((response: UserSearchForTransactions200Response) => {
            const pageNumber = response.pagination.pageNumber;
            const startPosition = (response.pagination.pageNumber - 1) * response.pagination.pageSize + 1;
            const endPosition = Math.min(
              startPosition + response.pagination.pageSize - 1,
              response.pagination.totalRecords,
            );
            const pagination = {
              totalRecords: response.pagination.totalRecords,
              totalPages: response.pagination.totalPages,
              pageNumber,
              startPosition,
              endPosition,
            };
            return PaginatedTestTransactionsGetSuccessAction({
              pagination,
              transactions: response.results,
            });
          }),
          catchError((err: HttpErrorResponse) => {
            return of(
              PaginatedTestTransactionsGetErrorAction({ errorText: err.message, errorType: err['status'] ?? 0 }),
            );
          }),
        );
      }),
    ),
  );

  public getSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestTransactionsGetSummaryAction),
      switchMap((action) => {
        const filters = this.mapFilters(action.filters);

        const requestParms: TransactionsSummaryRequestParams = {
          ...filters,
          Authorization: this.authHeader(),
        };
        return this.testTransactionService.transactionsSummary(requestParms).pipe(
          map((response) => TestTransactionsGetSummarySuccessAction(response)),
          catchError((error: HttpErrorResponse) =>
            of(TestTransactionsGetSummaryErrorAction({ errorText: error.message, errorType: error['status'] ?? 0 })),
          ),
        );
      }),
    ),
  );

  public downloadTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestTransactionsDownloadTransactionsAction),
      switchMap((action) => {
        const filters = this.mapFilters(action.filters);

        const requestParams: DownloadTransactionsRequestParams = {
          ...filters,
          Authorization: this.authHeader(),
        };
        return this.testTransactionService.downloadTransactions(requestParams).pipe(
          map((response) => {
            const fileText = response.replaceAll('\\n', '\n').replaceAll('\\"', '"').slice(1, -1);

            return TestTransactionsDownloadTransactionsSuccessAction({
              data: new Blob([fileText], { type: 'text/csv', endings: 'native' }),
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              TestTransactionsDownloadTransactionsErrorAction({
                errorText: error.message,
                errorType: error['status'] ?? 0,
              }),
            ),
          ),
        );
      }),
    ),
  );

  public create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestTransactionsRequestNewAction),
      // using mergeMap here to account for multiple requests.
      mergeMap((action) =>
        this.testTransactionService
          .createTransaction({ Authorization: this.authHeader(), CreateTransactionRequest: action.transaction })
          .pipe(
            switchMap((transaction) => {
              if (action.files?.length) {
                // if files exist, then we need to do several request
                // After we create the transaction, we need to upload the file to s3.
                return this.s3Service.uploadFile(transaction.id, action.files[0], this.authHeader()).pipe(
                  // After both complete, then we need to confirm the upload
                  switchMap((s3Response) =>
                    this.testTransactionService
                      .confirmAttachmentUpload({
                        id: transaction.id,
                        attachmentId: s3Response.attachmentId,
                        Authorization: this.authHeader(),
                      })
                      .pipe(map(() => TestTransactionsRequestNewSuccessAction(transaction))),
                  ),
                );
              }
              // no file; we're done.
              return of(TestTransactionsRequestNewSuccessAction(transaction));
            }),
            catchError((error: HttpErrorResponse | Error) =>
              of(TestTransactionsRequestNewErrorAction({ errorText: error.message, errorType: error['status'] ?? 0 })),
            ),
          ),
      ),
    ),
  );

  public fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestTransactionsFetchAction),
      switchMap((action) =>
        this.testTransactionService.transactionDetailsForMyLs({ id: action.id, Authorization: this.authHeader() }).pipe(
          map((response) => TestTransactionsFetchSuccessAction(response)),
          catchError((error: HttpErrorResponse) =>
            of(TestTransactionsFetchErrorAction({ errorText: error.message, errorType: error['status'] ?? 0 })),
          ),
        ),
      ),
    ),
  );

  public getUnique$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestTransactionsUniqueValuesAction),
      withLatestFrom(this.store$.select(fnTestTransactionsState).pipe(map((s) => s.uniqueValues))),
      mergeMap(([action, existingColumns]) => {
        if (existingColumns.columns[action.column]) {
          return of(TestTransactionUniqueValuesNoOpAction());
        }
        // some values have a different endpoint.
        if (action.column === MetaColumns.tags) {
          return this.testTransactionService.listTags({ Authorization: this.authHeader() }).pipe(
            map((tags: TagObject[]) =>
              TestTransactionsUniqueValuesSuccessAction({
                column: action.column,
                values: tags.map((t) => t.name),
                tags,
              }),
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                TestTransactionsUniqueValuesErrorAction({ errorText: error.message, errorType: error['status'] ?? 0 }),
              ),
            ),
          );
        }
        if (action.column === 'category') {
          return this.testTransactionService.listAllCategories({ Authorization: this.authHeader() }).pipe(
            map((cat: CategoryObject[]) =>
              TestTransactionsUniqueValuesSuccessAction({
                column: action.column,
                values: cat.map((c) => c.name),
                categories: cat,
              }),
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                TestTransactionsUniqueValuesErrorAction({ errorText: error.message, errorType: error['status'] ?? 0 }),
              ),
            ),
          );
        }
        // if not a 'special' value, get all unique values.
        return this.testTransactionService
          .listUniqueValues({ column: action.column, Authorization: this.authHeader() })
          .pipe(
            map((response) =>
              TestTransactionsUniqueValuesSuccessAction({ column: response.column, values: response.values }),
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                TestTransactionsUniqueValuesErrorAction({ errorText: error.message, errorType: error['status'] ?? 0 }),
              ),
            ),
          );
      }),
    ),
  );

  public requestScreenshot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestTransactionRequestScreenshotsAction),
      switchMap((action) => {
        const requestParams = {
          Authorization: this.authHeader(),
          id: action.transactionId,
          RequestScreenshotsRequest: {
            id: action.transactionId,
            client: action.client,
            websiteUrl: action.websiteUrl,
          },
        };

        return this.testTransactionService.requestScreenshots(requestParams).pipe(
          map((responses) =>
            TestTransactionRequestScreenshotsSuccessAction({
              status: 'string',
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              TestTransactionRequestScreenshotsErrorAction({
                errorText: error.message,
                errorType: error['status'] ?? 0,
              }),
            ),
          ),
        );
      }),
    ),
  );

  public downloadAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestTransactionDownloadAttachmentAction),
      switchMap((action) => {
        const serviceCalls =
          action.downloadType === 'attachment'
            ? action.attachmentIds.map((attachmentId) =>
                this.testTransactionService.downloadAttachment({
                  Authorization: this.authHeader(),
                  id: action.transactionId,
                  attachmentId: attachmentId,
                }),
              )
            : action.attachmentIds.map((attachmentId) =>
                this.testTransactionService.downloadScreenshot({
                  Authorization: this.authHeader(),
                  id: action.transactionId,
                  screenshotId: attachmentId,
                }),
              );
        const results = forkJoin(serviceCalls);

        return results.pipe(
          map((responses) =>
            TestTransactionDownloadAttachmentSuccessAction({
              signedUrls: responses.map((response) => ({
                url: response.url,
                name: response.name,
                id: response.id,
                type: action.downloadType,
              })),
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              TestTransactionDownloadAttachmentErrorAction({
                errorText: error.message,
                errorType: error['status'] ?? 0,
              }),
            ),
          ),
        );
      }),
    ),
  );

  public detectErrors = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ...[
          PaginatedTestTransactionsGetErrorAction,
          TestTransactionDownloadAttachmentErrorAction,
          TestTransactionsDownloadTransactionsErrorAction,
          TestTransactionsFetchErrorAction,
          TestTransactionsGetSummaryErrorAction,
          TestTransactionsRequestNewErrorAction,
          TestTransactionsUniqueValuesErrorAction,
        ],
      ),
      map((errorAction) => {
        return GenericNotificationAction({
          severity: SeverityOptions.ERROR,
          blocking: false,
          sticky: false,
          summary: 'An error occured',
          detail: errorAction.errorText,
        });
      }),
    ),
  );

  private mapFilters(filters: TableFilter[]): SearchFilters {
    return (filters || []).reduce((filters, filter) => {
      const filterValueString =
        filter.filterType === FilterType.date_range
          ? filter.value.map((d) => d.toISOString()).join(',')
          : filter.filterType === FilterType.multi_select
          ? filter.value.map((v) => v.name).join(',')
          : filter.filterType === FilterType.numeric_range
          ? filter.value.map((n) => `${n}`).join(',')
          : filter.filterType === FilterType.single_line
          ? filter.value
          : filter.filterType === FilterType.multi_line
          ? filter.value.replaceAll('\n', ',')
          : '';
      filters[filter.propertyName] = filterValueString;
      return filters;
    }, {} as SearchFilters);
  }

  private authHeader = () => `Bearer ${this.localStorageService.getTokenRaw()}`;
}

interface SearchFilters {
  website?: string;
  category?: string;
  merchantDescriptor?: string;
  transactionDate?: string;
  amount?: number;
  ccLast4?: number;
  transactionStatus?: string;
  tags?: string;
  createDate?: string;
  source?: string;
  requestId?: string;
  cardAcceptor?: string;
  rightsHolder?: string;
  terminalId?: string;
  transactionId?: string;
}
