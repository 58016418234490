import { Injectable } from '@angular/core';
import { MyLSIoTConfig } from '@ls/common-ts-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  AppState,
  PushNotificationAuthenticateAction,
  PushNotificationAuthenticateErrorAction,
  PushNotificationAuthenticateSuccessAction,
} from '../reducers';
import { PushNotificationService } from '../services/push-notification.service';

@Injectable()
export class PushNotificationEffect {
  public processingComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushNotificationAuthenticateAction),
      switchMap(() => {
        return this.pushNotificationService.iotAuthorizer().pipe(
          map((res) => {
            const config: MyLSIoTConfig = JSON.parse(res);
            return PushNotificationAuthenticateSuccessAction({ config });
          }),
          catchError((err) => {
            return of(PushNotificationAuthenticateErrorAction(err));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private pushNotificationService: PushNotificationService,
  ) {}
}
