import { Label } from '@ls/common-ts-models';
import { createAction, createReducer, on, props } from '@ngrx/store';

export const GET_TAGS = '[Tags] Get';
export const GET_TAGS_SUCCESS = '[Tags] Get Success';
export const TAGS_ERROR = '[Tags] Tags Error';

export const GetTagsAction = createAction(GET_TAGS);

export const GetTagsSuccessAction = createAction(GET_TAGS_SUCCESS, props<{ tags: Label[] }>());

export const TagsErrorAction = createAction(TAGS_ERROR, props<{ err?: Error }>());

export interface TagsState {
  err?: Error;
  tags: Label[];
  pending: boolean;
}

export const initialTagsState: TagsState = {
  err: null,
  tags: [],
  pending: true,
};

export const TagsReducer = createReducer(
  initialTagsState,

  on(GetTagsSuccessAction, (state) => ({
    ...state,
    tags: [],
    err: null,
    pending: true,
  })),

  on(GetTagsSuccessAction, (state, { tags }) => ({
    ...state,
    err: null,
    tags: tags,
    pending: false,
  })),

  on(TagsErrorAction, (state, { err }) => ({
    ...state,
    err,
    pending: false,
  })),
);
