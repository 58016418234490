import { FilterCategory, FilterSet, TableFilter, isFilterEmpty } from '@ls/common-ng-components';
import { createAction, createReducer, on, props } from '@ngrx/store';

export const SET_ALL_FILTERS = '[Table Filters] Set All';
export const SHOW_FILTER = '[Table Filters] Show';
export const APPLY_FILTER = '[Table Filters] Apply Filter';
export const APPLY_FILTERS = '[Table Filters] Apply Filters';
export const REMOVE_FILTER = '[Table Filters] Remove';
export const CLEAR_ALL_FILTERS = '[Table Filters] Clear All';

export const SetAllFiltersAction = createAction(
  SET_ALL_FILTERS,
  props<{ filterMenu: FilterCategory[]; filtersByName: { [name: string]: TableFilter } }>(),
);
export const ShowFilterAction = createAction(SHOW_FILTER, props<{ filter: TableFilter }>());
export const ApplyFilterAction = createAction(APPLY_FILTER, props<{ filter: TableFilter }>());
export const ApplyFiltersAction = createAction(
  APPLY_FILTERS,
  props<{ filters: TableFilter[]; filterSet?: FilterSet }>(),
);
export const RemoveFilterAction = createAction(REMOVE_FILTER, props<{ filter: TableFilter }>());
export const ClearAllFiltersAction = createAction(CLEAR_ALL_FILTERS);

export interface TableFiltersState {
  filterMenu?: FilterCategory[];
  visibleFilters?: TableFilter[]; // Visible filters are displayed, but do not currently have a value
  appliedFilters?: TableFilter[]; // Applied filters are filters that are currently affecting the table's data
  filtersByName: { [name: string]: TableFilter };
}

export const initialTableFiltersState: TableFiltersState = {
  filtersByName: {},
};

export const TableFiltersReducer = createReducer(
  initialTableFiltersState,

  on(
    SetAllFiltersAction,
    (state, { filterMenu, filtersByName }) =>
      ({
        ...state,
        filterMenu,
        filtersByName,
      }) as TableFiltersState,
  ),

  on(
    ShowFilterAction,
    (state, { filter }) =>
      ({
        ...state,
        visibleFilters: [...state.visibleFilters, filter],
      }) as TableFiltersState,
  ),

  on(ApplyFilterAction, (state, { filter }) => {
    if (!isFilterEmpty(filter)) {
      // adding filter value
      return {
        ...state,
        appliedFilters: [...state.appliedFilters.filter((f) => f.displayName !== filter.displayName), filter],
        visibleFilters: [...state.visibleFilters.map((f) => (f.displayName === filter.displayName ? filter : f))],
      };
    } else {
      // removing filter value
      return {
        ...state,
        appliedFilters: [...state.appliedFilters.filter((f) => f.displayName !== filter.displayName)],
        visibleFilters: [...state.visibleFilters.map((f) => (f.displayName === filter.displayName ? filter : f))],
      };
    }
  }),

  on(ApplyFiltersAction, (state, { filters }) => {
    return {
      ...state,
      visibleFilters: [...filters],
      appliedFilters: [...filters.filter((f) => f.value !== undefined)],
    } as TableFiltersState;
  }),

  on(RemoveFilterAction, (state, { filter }) => {
    if (!isFilterEmpty(filter)) {
      // clear the filter if it has a value
      return {
        ...state,
        visibleFilters: state.visibleFilters.map((f) => {
          if (filter.displayName === f.displayName) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { value, ...clearedValue } = f;
            return clearedValue;
          }
          return f;
        }),
        appliedFilters: state.appliedFilters.filter((f) => f.displayName !== filter.displayName),
      } as TableFiltersState;
    } else {
      // remove the filter if it had no value
      return {
        ...state,
        visibleFilters: state.visibleFilters.filter((vf) => vf.displayName !== filter.displayName),
        appliedFilters: state.appliedFilters.filter((f) => f.displayName !== filter.displayName),
      } as TableFiltersState;
    }
  }),

  on(
    ClearAllFiltersAction,
    (state) =>
      ({
        ...state,
        appliedFilters: [],
        visibleFilters: [
          ...state.visibleFilters.map((f) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { value, ...clearedValues } = f;
            return clearedValues;
          }),
        ],
      }) as TableFiltersState,
  ),
);
