import { Component, Input } from '@angular/core';
import { EnrollService, AddonPricing } from '../../services';
import { Observable } from 'rxjs';

@Component({
  selector: 'expedited-pricing-info',
  templateUrl: './expedited-pricing-info.component.html'
})
export class ExpeditedPricingInfoComponent {
  @Input() public enabled = true;
  public addonPricing$: Observable<AddonPricing>;

  constructor(public enrollSvc: EnrollService) {
    this.addonPricing$ = this.enrollSvc.getAddonPricing();
  }
}
