import { createAction, createReducer, on, props } from '@ngrx/store';
import { AuthenticatedUser } from '@ls/common-ts-models';

//
// CONSTANTS
//
export const USER_AUTHENTICATE = '[User] Authenticate';
export const USER_AUTHENTICATE_SUCCESS = '[User] Authenticate Success';
export const USER_AUTHENTICATE_ERROR = '[User] Authenticate Error';
export const USER_CONFIRM = '[User] Confirm New';
export const USER_CONFIRM_SUCCESS = '[User] Confirm Success';
export const USER_CONFIRM_ERROR = '[User] Confirm Error';
export const USER_SIGN_OUT = '[User] Sign out';
export const USER_SIGN_OUT_SUCCESS = '[User] Sign out Success';

//
// ACTIONS
//
export const UserAuthenticateAction = createAction(USER_AUTHENTICATE, props<{ user: AuthenticatedUser }>());

export const UserAuthenticateSuccessAction = createAction(
  USER_AUTHENTICATE_SUCCESS,
  props<{ user: AuthenticatedUser; roles: string[] }>(),
);

export const UserAuthenticateErrorAction = createAction(USER_AUTHENTICATE_ERROR, props<{ errorText: string }>());

export const UserConfirmAction = createAction(USER_CONFIRM, props<{ lsUserId: string; verificationToken: string }>());

export const UserConfirmSuccessAction = createAction(USER_CONFIRM_SUCCESS, props<{ success: boolean; name: string }>());

export const UserConfirmErrorAction = createAction(
  USER_CONFIRM_ERROR,
  props<{ success: boolean; errorText: string }>(),
);

export const UserSignOutAction = createAction(USER_SIGN_OUT, props<{ location: string }>());

export const UserSignOutSuccessAction = createAction(USER_SIGN_OUT_SUCCESS);

//
// STATE
//
export interface AuthenticationState {
  user: AuthenticatedUser;
  roles: string[];
  pending: boolean;
  errorText: string;
  success: boolean;
  name: string;
}

export const initialAuthenticationState: AuthenticationState = {
  user: {} as AuthenticatedUser,
  roles: [],
  pending: false,
  errorText: undefined,
  success: false,
  name: '',
};

//
// REDUCER
//
export const AuthenticationReducer = createReducer(
  initialAuthenticationState,

  on(UserAuthenticateAction, (state, { user }) => ({
    ...state,
    user,
    pending: true,
    errorText: undefined,
  })),

  on(UserAuthenticateSuccessAction, (state, { user, roles }) => ({
    ...state,
    user,
    roles,
    pending: false,
    errorText: undefined,
  })),

  on(UserAuthenticateErrorAction, (state, { errorText }) => ({
    ...state,
    user: {} as AuthenticatedUser,
    roles: [],
    pending: false,
    errorText,
  })),

  on(UserConfirmAction, (state) => ({
    ...state,
    pending: true,
  })),

  on(UserConfirmSuccessAction, (state, { success, name }) => ({
    ...state,
    success,
    name,
    pending: false,
  })),

  on(UserConfirmErrorAction, (state, { success, errorText }) => ({
    ...state,
    success,
    errorText,
    pending: false,
  })),

  on(UserSignOutAction, () => initialAuthenticationState),
);
