import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NotificationStatus } from '../../models';
import { NotificationsState } from '../reducers';

export const notificationsFeatureKey = 'notifications-v2';

export const selectNotificationsState = createFeatureSelector<NotificationsState>(notificationsFeatureKey);

export const selectNotificationsData = createSelector(selectNotificationsState, (state) => ({
  notifications: state?.notifications || [],
  unreadNotificationsCount: (state?.notifications ?? []).filter((x) => x.status === NotificationStatus.unread).length,
}));
