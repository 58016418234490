import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { UsersService } from './users.service';
import { Auth0Service, AUTH0_HANDLER, BillingService, LocalStorageService } from '@ls/common-ng-components';
import { PaymentService } from './payment.service';
import { PushNotificationService } from './push-notification.service';
import { Auth0HandlerService } from './auth0-handler.service';

@NgModule({
  imports: [HttpClientModule],
  exports: [],
  providers: [
    { provide: AUTH0_HANDLER, useClass: Auth0HandlerService },
    { provide: 'Window', useValue: window },
    Auth0Service,
    AuthenticationService,
    LocalStorageService,
    BillingService,
    UsersService,
    PaymentService,
    PushNotificationService,
  ],
  declarations: [],
})
export class ServicesModule {}
