import { Observable, Subscription, combineLatest, of } from 'rxjs';

import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { APIProductInfo, CertificationProduct, MonitoringProduct, ProductFamily, Roles } from '@ls/common-ts-models';
import { AppState, fnCertificationsState } from '../../reducers';
import { CertificationsState } from '../../modules/certifications/reducers';
import { Store } from '@ngrx/store';
import {
  ActiveProductTypes,
  ActiveProductListItem,
  APIProductListItem,
  CertificationProductListItem,
  MonitoringProductListItem,
} from './list-items';
import {
  GenericNotificationAction,
  LegitScriptModalComponent,
  LocalStorageService,
  SeverityOptions,
  UsageGetAction,
  entitlementsStateSelector,
  usageStateSelector,
} from '@ls/common-ng-components';
import { AccountService } from 'src/app/services';
import { map, catchError, skipWhile, take, tap } from 'rxjs/operators';

@Component({
  selector: 'active-products',
  templateUrl: './active-products.component.html',
  styleUrls: ['./active-products.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveProductsComponent implements OnInit, OnDestroy {
  public activeProducts: ActiveProductListItem[] = [];
  public refreshSubmitting = false;
  public refreshComplete = false;
  public isOwner = false;
  public activeProductTypes = ActiveProductTypes;
  public loading = true;
  public products$: Subscription;
  public error;
  public productId: string;
  public newApiKey: string;

  @ViewChild(LegitScriptModalComponent) private confirmAPIKeyRefreshModal: LegitScriptModalComponent;

  constructor(
    public accountService: AccountService,
    private store: Store<AppState>,
    private localStorageSvc: LocalStorageService,
  ) {}

  public ngOnInit() {
    const authUser = this.localStorageSvc.getAuthenticatedUser();
    this.isOwner = authUser.roles && authUser.roles.indexOf(Roles.cpv2AccountOwner) !== -1;

    this.store.dispatch(UsageGetAction());

    // Combine certifications, products, and mm products into a single observable
    const sub: Observable<ActiveProductListItem[]> = combineLatest([
      this.getCerts(),
      this.getAPIProductInfo(),
      this.getMonitoringProductInfo(),
    ]).pipe(
      map(([certificationsProducts, apiProducts, monitoringProducts]) => {
        let products: ActiveProductListItem[] = [];

        if (certificationsProducts) {
          products = products.concat(
            certificationsProducts.map((product) => new CertificationProductListItem(this, product)),
          );
        }

        if (apiProducts) {
          products = products.concat(apiProducts.map((product) => new APIProductListItem(this, this.isOwner, product)));
        }

        if (monitoringProducts) {
          products = products.concat(
            monitoringProducts.map((product) => new MonitoringProductListItem(this, this.isOwner, product)),
          );
        }

        return products;
      }),
      catchError(() => {
        this.loading = false;
        this.error = true;
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Failed to retrieve active products',
            detail: 'An error occured attempting to fetch active products. Please try again.',
            sticky: false,
            blocking: false,
          }),
        );

        return of<ActiveProductListItem[]>();
      }),
    );

    this.products$ = sub.subscribe((values: ActiveProductListItem[]) => {
      if (values) {
        this.activeProducts = values;
        this.loading = false;
      }
    });
  }

  public ngOnDestroy() {
    if (this.products$) {
      this.products$.unsubscribe();
    }
  }

  public confirmRefreshAPIKey(productId) {
    this.productId = productId;
    this.confirmAPIKeyRefreshModal.showDialog();
  }

  public performRefreshAPIKey() {
    this.refreshSubmitting = true;

    this.accountService.refreshApiKey(this.productId).subscribe(
      (response) => {
        this.newApiKey = response.value;
        this.refreshSubmitting = false;
        this.refreshComplete = true;
      },
      () => {
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Error refreshing API key',
            detail:
              'An error occured while refreshing your API key.  The original key remains active and has not been changed.  Please try this update again later.  If this problem persists, please contact support',
            sticky: false,
            blocking: false,
          }),
        );
        this.hideRefreshModal();
      },
    );
  }

  public hideRefreshModal() {
    this.productId = null;
    this.confirmAPIKeyRefreshModal.hideDialog();
    this.newApiKey = null;
    this.refreshSubmitting = this.refreshComplete = false;
  }

  private getAPIProductInfo(): Observable<APIProductInfo[]> {
    return combineLatest([this.store.select(entitlementsStateSelector), this.store.select(usageStateSelector)]).pipe(
      map(([entitlements, usage]) => ({ entitlements, usage })),
      skipWhile(({ entitlements, usage }) => entitlements.pending || usage.pending),
      take(1),
      map((state) => {
        if (state.entitlements.errorText) {
          throw new Error(state.entitlements.errorText);
        }

        if (state.usage.errorText) {
          throw new Error(state.usage.errorText);
        }

        const workingState = JSON.parse(JSON.stringify(state));

        delete workingState.entitlements?.products?.['Healthcare Merchant Certification'];

        // monitoring products will be handled in getMonitoringProductInfo
        const apiProducts = workingState.entitlements?.products
          ? Object.keys(workingState.entitlements.products).filter((key) => key !== ProductFamily.MERCHANT_MONITORING)
          : [];

        return apiProducts.map((product) => {
          const usageRemaining = this.getUsageForAPIProduct(
            workingState.entitlements.products[product].externalReferenceId,
            workingState.usage.usages,
            workingState.entitlements.products[product].quotaLimit,
          );

          return {
            externalReferenceId: workingState.entitlements.products[product].externalReferenceId,
            name: product,
            plan: workingState.entitlements.products[product].name.includes('Red Flag') ? 'Red Flag' : 'All',
            quotaLimit: workingState.entitlements.products[product].quotaLimit,
            remainingLookups:
              usageRemaining !== -1 ? usageRemaining : workingState.entitlements.products[product].quotaLimit,
            expiration: workingState.entitlements.products[product].expiration,
            apiKeyRequired: workingState.entitlements.products[product].apiKeyRequired,
          };
        });
      }),
      catchError(() => {
        this.loading = false;
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Failed to retrieve active products',
            detail: 'An error occured attempting to fetch active product entitlements. Please try again.',
            sticky: false,
            blocking: false,
          }),
        );
        this.error = true;
        return of<APIProductInfo[]>();
      }),
    );
  }

  private getCerts(): Observable<CertificationProduct[]> {
    return this.store.select(fnCertificationsState).pipe(
      tap((certState) => {
        if (certState.errorText) {
          throw new Error(certState.errorText);
        }
      }),
      catchError(() => {
        this.loading = false;
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Failed to retrieve active products',
            detail: 'An error occured attempting to fetch active certifications. Please try again.',
            sticky: false,
            blocking: false,
          }),
        );
        this.error = true;
        return of();
      }),
      map((certs: CertificationsState) => {
        if (certs) {
          return certs.list.map((cert) => ({
            name: `${cert.certificationType} (${cert.businessName})`,
            expiration: cert.renewalDate ? new Date(cert.renewalDate) : null,
          }));
        }
        return new Array<CertificationProduct>();
      }),
    );
  }

  private getMonitoringProductInfo(): Observable<MonitoringProduct[]> {
    return combineLatest([this.store.select(entitlementsStateSelector), this.store.select(usageStateSelector)]).pipe(
      map(([entitlements, usage]) => ({ entitlements, usage })),
      skipWhile(({ entitlements, usage }) => entitlements.pending || usage.pending),
      take(1),
      map((state) => {
        if (state.entitlements.errorText) {
          throw new Error(state.entitlements.errorText);
        }

        if (state.usage.errorText) {
          throw new Error(state.usage.errorText);
        }

        const monitoringProduct = state.entitlements?.products?.[ProductFamily.MERCHANT_MONITORING];

        if (!monitoringProduct) {
          return [];
        }

        const monitoringUsage = (state.usage.usages as any[]).find(
          (usage) => usage.product?.external_reference_id === monitoringProduct.externalReferenceId,
        );

        // TODO: UsageEffect needs to determine itemsMonitored for people who have purchased monitoring
        const itemsMonitored = monitoringUsage && monitoringUsage.usage ? monitoringUsage.usage.itemsMonitored : 0;

        // wrap in a list for now, we may support additional monitoring products in the future
        return [
          {
            name: ProductFamily.MERCHANT_MONITORING,
            itemsMonitored,
            opportunity: monitoringProduct.opportunity,
          },
        ];
      }),
      catchError(() => {
        this.loading = false;
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Failed to retrieve active products',
            detail: 'An error occured attempting to fetch active product entitlements. Please try again.',
            sticky: false,
            blocking: false,
          }),
        );
        this.error = true;
        return of<MonitoringProduct[]>();
      }),
    );
  }

  private getUsageForAPIProduct(productId: string, usages: any[], quotaLimit: number): number {
    if (!usages || usages.length === 0) {
      return -1;
    }

    const res = usages[0].find((usage) => {
      return usage.product.external_reference_id === productId;
    });

    if (!res) {
      return -1; // should this be an error???
    }

    const offset = res.quota ? res.quota.offset : 0;
    const limit = res.quota ? res.quota.limit : quotaLimit;

    if (res.usage) {
      return res.usage[1] - offset; // subtract offset from remaining lookups
    } else {
      return limit - offset;
    }
  }
}
