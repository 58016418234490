import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TransactionDetailsForMyLs200Response } from '../../../angular-client';

@Component({
  selector: 'ls-test-transaction-documents',
  templateUrl: './test-transaction-documents.component.html',
  styleUrls: ['./test-transaction-documents.component.scss'],
})
export class TestTransactionDocumentsComponent {
  @Input() testTransaction: TransactionDetailsForMyLs200Response;

  @Output() downloadDocuments: EventEmitter<{
    documents: { id: string; order?: number }[];
    type: 'attachment';
    zip?: boolean;
    folderName?: string;
  }> = new EventEmitter();

  onDownloadAllClick() {
    const allAttachments = (this.getSortedAttachments() || []).map((s) => {
      return { id: s.id, order: s.order };
    });
    const folderName = `${this.testTransaction?.websiteUrl}-attachments`;
    this.downloadDocuments.next({ documents: allAttachments, type: 'attachment', zip: true, folderName });
  }

  onDownloadClick(attachmentId: string) {
    this.downloadDocuments.next({ documents: [{ id: attachmentId }], type: 'attachment' });
  }

  getSortedAttachments() {
    return [...(this.testTransaction?.attachments ?? [])].sort((a, b) => a.order - b.order);
  }
}
