import XLSX from 'xlsx';

import { MIME_TYPES } from '../../../constants';
import { ValidationResult } from '../SharedCustomTypes/validation-result';

type FileResult = ArrayBuffer | string;
type FileReadMethod = (blob: Blob, encoding?: string) => void;

export class SpreadSheetFileReader {
  public static readonly errorInvalidFile = new ValidationResult(false, 'Error: No file was given');

  public static readonly errorInvalidFileType = new ValidationResult(false, 'Error: Cannot parse this file type');

  public static async getWorkbook(file: File): Promise<XLSX.WorkBook> {
    const fileData = await this.getDataFromFile(file);
    const parsingOption = this.parsingOptionsByType[file.type];
    const workbook = XLSX.read(fileData, parsingOption);
    return workbook;
  }

  private static parsingOptionsByType: Record<string, XLSX.ParsingOptions> = {
    [MIME_TYPES.XLSX]: { type: 'buffer' },
    [MIME_TYPES.CSV]: { type: 'string' }
  };

  private static fileReadersByType: Record<string, FileReadMethod> = {
    [MIME_TYPES.XLSX]: FileReader.prototype.readAsArrayBuffer,
    [MIME_TYPES.CSV]: FileReader.prototype.readAsText
  };

  private static getDataFromFile(file: File): Promise<FileResult> {
    return new Promise((resolve, reject) => {
      const isKnownType = this.isKnownType(file);
      if (isKnownType.success !== true) {
        reject(isKnownType.validationError);
      }

      this.readFile(file, resolve, reject);
    });
  }

  private static readFile(file: File, resolve: (a1: FileResult) => void, reject: (a1: any) => void) {
    const reader = new FileReader();
    const readerMethod = this.fileReadersByType[file.type];
    const read = readerMethod.bind(reader);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    read(file);
  }

  private static isKnownType(file: File): ValidationResult {
    if (!file) {
      return this.errorInvalidFile;
    }
    if (this.isInvalidTypeToRead(file.type)) {
      return this.errorInvalidFileType;
    }

    return ValidationResult.Success;
  }

  private static isInvalidTypeToRead(fileType: string): boolean {
    return !this.fileReadersByType[fileType] || !this.parsingOptionsByType[fileType];
  }
}
