import { empty as observableEmpty, Observable, Subject } from 'rxjs';

import { takeUntil, catchError, tap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '@ls/common-ts-models';

import { AccountState, AppState, UserSignOutAction, fnAccountState } from '../../../reducers';
import { AccountService } from '../../../services';

import { GenericNotificationAction, LocalStorageService, SeverityOptions } from '@ls/common-ng-components';

@Component({
  selector: 'security-settings',
  styleUrls: ['./security-settings.component.scss'],
  templateUrl: './security-settings.component.html',
})
export class SecuritySettingsComponent implements OnDestroy, OnInit {
  public account$: Observable<AccountState>;
  public contact: User;
  public account: AccountState;
  public loading = false;
  public model: any = {};
  public changedSuccessfully = false;
  private destroyed$: Subject<boolean> = new Subject();
  public isAuth0 = false;

  constructor(
    private store: Store<AppState>,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
  ) {}

  public ngOnInit() {
    this.account$ = this.store.select(fnAccountState);
    this.store
      .select('account')
      .pipe(
        takeUntil(this.destroyed$.asObservable()),
        tap((state: AccountState) => {
          // as a side effect, grab the contact from local storage if it's different from the primary contact
          this.contact = state.user;
          // this.account = state;
        }),
      )
      .subscribe((account) => {
        this.account = account;
      });

    this.isAuth0 = this.localStorage.isAuth0Enabled();
  }

  public signOutClick() {
    this.store.dispatch(UserSignOutAction({ location: '/login' }));
  }

  public submit() {
    this.loading = true;
    this.accountService
      .changePassword({
        oldPassword: this.model.password,
        newPassword: this.model.newPassword,
      })
      .pipe(
        catchError((err) => {
          this.loading = false;
          let errMsg = 'An error occurred; please confirm that your current password is correct';
          if (err.status === 409) {
            errMsg = 'Company name already exists.';
          }
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Failed to update password',
              detail: errMsg,
              sticky: null,
            }),
          );
          return observableEmpty();
        }),
      )
      .subscribe(() => {
        this.loading = false;
        this.changedSuccessfully = true;
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
