<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="login-container">
  <login-header></login-header>

  <div class="entry-main">
    <div *ngIf="loading" class="entry-container entry-container-small loading">
        <div>Loading</div>
        <p-progressSpinner [style]="{ width: '35px', height: '35px' }"></p-progressSpinner>
    </div>
    <div *ngIf="!loading" class="entry-container entry-container-small">
      <h1 class="mb20">Welcome {{model.firstName}}!</h1>
      <form name="form" (ngSubmit)="f.form.valid && submit(f.form.value)" #f="ngForm" novalidate>
        <div id="reset-form">
          <div class="subtext mb20">This is the basic information we have for your account.  If anything looks incorrect, please edit below.</div>
          <div class="form-group-outer">
              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !firstName.valid }">
                  <div class="label-wrapper">
                      <label>First Name</label>
                      <div *ngIf="f.submitted && !firstName.valid" class="help-block">*First name is required</div>
                  </div>
                  <input type="text" class="form-control" name="firstName" [(ngModel)]="model.firstName" #firstName="ngModel" required maxlength="40" checkWhitespace checkInvalidCharacter />
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !lastName.valid }">
                  <div class="label-wrapper">
                      <label>Last Name</label>
                      <div *ngIf="f.submitted && !lastName.valid" class="help-block">*Last name is required</div>
                  </div>
                  <input type="text" class="form-control" name="lastName" [(ngModel)]="model.lastName" #lastName="ngModel" required maxlength="80" checkWhitespace checkInvalidCharacter />
                  
              </div>
          </div>
          <div class="form-group-outer">
              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !position.valid }">
                  <div class="label-wrapper">
                      <label>Title/Position</label>
                      <div *ngIf="f.submitted && !position.valid" class="help-block">*Title/Position is required</div>
                  </div>
                  <input type="text" class="form-control" name="position" [(ngModel)]="model.position" #position="ngModel" required maxlength="80" checkWhitespace />
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !phone.valid }">
                  <div class="label-wrapper">
                    <label>Phone</label>
                    <div *ngIf="f.submitted && !phone.valid" class="help-block">*Phone is required</div>
                  </div>
                  <input type="text" class="form-control" name="phone" [(ngModel)]="model.phone" #phone="ngModel" required maxlength="40" checkWhitespace />
              </div>
          </div>
          <div class="form-actions">
            <button [disabled]="saving" class="btn btn-primary">
                {{ saving ? 'Saving...' : 'Save and Continue'}}
            </button>
            <span *ngIf="saving">
                <p-progressSpinner [style]="{ width: '35px', height: '35px' }"></p-progressSpinner>
            </span>
            <br />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
