<div class="widget">
  <div class="widget-header">
    <div *ngIf="loading; else resultsHeader">
      <h1>Loading results for {{searchTerm}}</h1>
    </div>
    <ng-template #resultsHeader>
      <h1 class="error" *ngIf="error; else noErrorHeader">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{error}}
      </h1>
      <ng-template #noErrorHeader>
        <h1 *ngIf="!products.length">0 results found for “{{searchTerm}}”</h1>
        <h1 *ngIf="products.length">{{pagination.total_records}} result{{pagination.total_records > 1 ? 's' : ''}} found for “{{searchTerm}}”</h1>
      </ng-template>
    </ng-template>
  </div>
  <div class="widget-body centered">
    <div class="entry-container entry-container-large">
      <loading-spinner *ngIf="loading; else resultsBody" [loadingText]="'Loading'"></loading-spinner>
      <ng-template #resultsBody>
        <error-notice *ngIf="error; else noErrorBody" [type]="error"></error-notice>
        <ng-template #noErrorBody>
          <div *ngIf="!products || !products.length">
            <h2>No products found for “{{searchTerm}}”</h2>
          </div>
          <div *ngIf="products?.length">
            <ul class="product-list">
              <li *ngFor="let product of products">
                  <a routerLink="../info/{{product.id}}">
                    {{product.name}} {{product.dosage}}
                    <span *ngIf="product.manufacturer">({{product.manufacturer}})</span>
                  </a>
              </li>
            </ul>
            <div class="pagination" *ngIf="pagination.total_pages > 1">
              <ul>
                <li *ngIf="pagination.page_number - 1 > 0">
                  <a [routerLink]="['./']" [queryParams]="{page: pagination.page_number - 1}" queryParamsHandling="merge">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>Previous
                  </a>
                </li>
                <li>Page {{pagination.page_number}} of {{pagination.total_pages}}</li>
                <li *ngIf="pagination.page_number + 1 <= pagination.total_pages">
                  <a [routerLink]="['./']" [queryParams]="{page: pagination.page_number + 1}" queryParamsHandling="merge">
                    Next<i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>